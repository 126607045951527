import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetCategoryTypeByIdQuery } from "../../../services/category-type";
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { BackButton } from "../..";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const CategoryTypeItemPage = () => {
  const { categoryTypeItemId } = useParams();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const {
    data: categoryType,
    error,
    isLoading,
  } = useGetCategoryTypeByIdQuery(categoryTypeItemId, {
    skip: !categoryTypeItemId,
  });

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (isLoading)
    return (
      <div className="loading-spinner">
        <CircularProgress size={24} />
      </div>
    );
  if (!categoryType) {
    return (
      <Typography variant="h4" gutterBottom>
        No Category Type Found
      </Typography>
    );
  }

  if (error) return <div>Error: {error?.data?.msg}</div>;

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <BackButton />
      </Box>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Category Type Name: {categoryType?.name || ""}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Path: {categoryType?.path || ""}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Icon: {categoryType?.icon || ""}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                tier: {categoryType?.tier?.level || ""}
              </Typography>
            </Grid>
            {categoryType?.isGlobal && (
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  isGlobal
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Container>
  );
};

export default CategoryTypeItemPage;

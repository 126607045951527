import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateTeamMutation } from "../../../services/team";
import { useFetchAllUsersQuery } from "../../../services/user";
import { AuthContext } from "../../../context/AuthContext";
import { useGetAllLevelsBrokerageProfilesQuery } from "../../../services/brokerage";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const CreateTeamDialog = ({ open, onClose, refetchTeams }) => {
  const { accountType, userId, userRole } = useContext(AuthContext);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [createTeam] = useCreateTeamMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const { data: brokerages } = useGetAllLevelsBrokerageProfilesQuery(userId, {
    skip:
      !userId && (userRole !== "reosadmin" || userRole !== "reossuperadmin"),
  });

  const { data: users } = useFetchAllUsersQuery(accountType, {
    skip: accountType !== "reosadmin",
  });
  const [selectedBrokerageId, setSelectedBrokerageId] = useState("");
  const [selectedMemberId, setSelectedMemberId] = useState("");
  const [inputmembervalue, setInputmembervalue] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedMemberId || !name) {
      setNotification({
        open: true,
        message: "Fields are not filled.",
      });
      return;
    }

    const result = await createTeam({
      name,
      memberId: selectedMemberId?.member?._id,
      brokerageId: selectedBrokerageId._id,
    });
    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        setName("");
        setSelectedMemberId("");
        refetchTeams?.();
        onClose();
        navigate(`/team/${result?.data?.newTeam?._id}`);
      }, 1000);
    } else {
      setNotification({
        open: true,
        message: `Error creating team: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create Team</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Autocomplete
            value={selectedBrokerageId}
            onChange={(event, newValue) => {
              setSelectedBrokerageId(newValue);
            }}
            options={brokerages || []}
            getOptionLabel={(option) =>
              option
                ? `${option.officeName} ${option.officeShortName} (${
                    option &&
                    option?.isLeadBrokerage &&
                    option?.leadBrokerageTier
                      ? "is lead brokerage"
                      : ""
                  })`
                : ""
            }
            isOptionEqualToValue={(option, value) => option?._id === value._id}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Brokerage"
                variant="outlined"
                margin="normal"
                required
              />
            )}
          />

          <Autocomplete
            value={selectedMemberId}
            onChange={(event, newValue) => {
              setSelectedMemberId(newValue);
            }}
            inputmembervalue={inputmembervalue}
            onInputChange={(event, newInputValue) => {
              setInputmembervalue(newInputValue);
            }}
            options={users || []}
            getOptionLabel={(option) =>
              option
                ? `${option?.firstName} ${option?.lastName} (${option?.email})`
                : ""
            }
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Team Lead"
                variant="outlined"
                margin="normal"
                required
              />
            )}
          />
          <TextField
            variant="outlined"
            margin="normal"
            label="Team Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Create Team
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default CreateTeamDialog;

import React, { useState, memo } from "react";
import { useGetUnacknowledgedUsersByPostQuery } from "../../services/post";
import { AvatarStyledBadge } from "../helpers/utils";
import {
  Link,
  Tooltip,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Button,
} from "@mui/material";

function AcknowledgementTable({ acknowledgeList = [], onMemberClick, postId }) {
  const { data: unacknowledgedMembers = [] } =
    useGetUnacknowledgedUsersByPostQuery(postId, {
      skip: !postId,
    });

  const [sortConfig, setSortConfig] = useState({
    field: "name",
    direction: "ascending",
  });

  const [sortConfigUnAck, setSortConfigUnAck] = useState({
    field: "name",
    direction: "ascending",
  });

  const [showAckTable, setShowAckTable] = useState(false);
  const [showUnAckTable, setShowUnAckTable] = useState(false);

  const toggleAckTable = () => setShowAckTable(!showAckTable);
  const toggleUnAckTable = () => setShowUnAckTable(!showUnAckTable);

  const handleMemberClick = (memberId, event) => {
    event.stopPropagation(); // Stop event propagation
    if (onMemberClick) {
      onMemberClick(memberId);
    }
  };

  const handleSort = (field) => {
    const isAscending =
      sortConfig.field === field && sortConfig.direction === "ascending";
    setSortConfig({
      field,
      direction: isAscending ? "descending" : "ascending",
    });
  };

  const handleSortUnAck = (field) => {
    const isAscending =
      sortConfigUnAck.field === field &&
      sortConfigUnAck.direction === "ascending";
    setSortConfigUnAck({
      field,
      direction: isAscending ? "descending" : "ascending",
    });
  };

  const sortedAcknowledgeList = [...acknowledgeList]?.sort((a, b) => {
    let aField = a?.user ? `${a?.user?.firstName} ${a?.user?.lastName}` : "";
    let bField = b?.user ? `${b?.user?.firstName} ${b?.user?.lastName}` : "";

    if (sortConfig.field === "acknowledgedAt") {
      aField = a?.acknowledgedAt;
      bField = b?.acknowledgedAt;
    }

    if (sortConfig.direction === "ascending") {
      return aField.localeCompare(bField);
    } else {
      return bField.localeCompare(aField);
    }
  });

  const sortedUnAcknowledgeList = [...unacknowledgedMembers]?.sort((a, b) => {
    let aField = a?.user ? `${a?.user?.firstName} ${a?.user?.lastName}` : "";
    let bField = b?.user ? `${b?.user?.firstName} ${b?.user?.lastName}` : "";

    if (sortConfigUnAck.direction === "ascending") {
      return aField.localeCompare(bField);
    } else {
      return bField.localeCompare(aField);
    }
  });

  return (
    <Box sx={{ border: "1px solid #ddd", padding: 2 }}>
      <Typography
        variant="h6"
        align="center"
        sx={{ marginTop: "10px", marginBottom: "10px" }}
        color="red"
      >
        Brokerage Admin View Only
      </Typography>
      <Typography variant="h6" align="center" sx={{ marginBottom: "10px" }}>
        Acknowledged: {sortedAcknowledgeList.length} | Unacknowledged:{" "}
        {sortedUnAcknowledgeList.length}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          marginBottom: 2,
        }}
      >
        <Button onClick={toggleAckTable}>
          {showAckTable ? "Hide" : "Show"} Acknowledged
        </Button>
        <Button onClick={toggleUnAckTable}>
          {showUnAckTable ? "Hide" : "Show"} Unacknowledged
        </Button>
      </Box>

      <TableContainer component={Paper}>
        {showAckTable && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: "#333",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSort("name")}
                >
                  Name
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#333",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSort("acknowledgedAt")}
                >
                  Date Acknowledged
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedAcknowledgeList &&
                sortedAcknowledgeList?.map((acknowledgment, index) => (
                  <TableRow
                    key={acknowledgment?._id}
                    style={{ backgroundColor: index % 2 ? "#f5f5f5" : "#fff" }}
                  >
                    <TableCell>
                      <Tooltip title="Member Profile link">
                        <AvatarStyledBadge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          variant="dot"
                          color="success"
                          invisible={!acknowledgment?.user?.isOnline}
                          width="5px"
                          height="5px"
                        >
                          <Avatar
                            className="avatar"
                            src={acknowledgment?.user?.image?.url}
                            onClick={(event) => {
                              handleMemberClick(
                                acknowledgment?.user?._id,
                                event
                              );
                            }}
                          >
                            R
                          </Avatar>
                        </AvatarStyledBadge>
                      </Tooltip>
                      <Tooltip title="Member Profile link">
                        <Link
                          sx={{ marginLeft: "10px" }}
                          component="button"
                          onClick={(event) =>
                            handleMemberClick(acknowledgment?.user?._id, event)
                          }
                          className="avatar-name"
                        >
                          {acknowledgment?.user?.firstName}{" "}
                          {acknowledgment?.user?.lastName}
                        </Link>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      {new Date(acknowledgment?.acknowledgedAt)?.toLocaleString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "2-digit",
                          hour12: true,
                        }
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
        {showUnAckTable && (
          <Table>
            <TableBody>
              {sortedUnAcknowledgeList &&
                sortedUnAcknowledgeList.length > 0 && (
                  <>
                    <TableRow
                      style={{
                        backgroundColor: "red",
                        color: "#000",
                        cursor: "pointer",
                      }}
                    >
                      <TableCell colSpan={2} style={{ textAlign: "center" }}>
                        <Typography
                          style={{ color: "white", fontWeight: "bolder" }}
                          className="appbar-title"
                        >
                          Unacknowledged Members List
                        </Typography>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          backgroundColor: "#333",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                        onClick={() => handleSortUnAck("name")}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#333",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                        // onClick={() => handleSortUnAck("acknowledgedAt")}
                      >
                        Date Acknowledged
                      </TableCell>
                    </TableRow>
                  </>
                )}
              {sortedUnAcknowledgeList &&
                sortedUnAcknowledgeList?.length > 0 &&
                sortedUnAcknowledgeList?.map((member, index) => (
                  <TableRow
                    key={member?._id}
                    style={{ backgroundColor: index % 2 ? "#f5f5f5" : "#fff" }}
                  >
                    <TableCell>
                      <Tooltip title="Member Profile link">
                        <AvatarStyledBadge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          variant="dot"
                          color="success"
                          invisible={!member?.user?.isOnline}
                          width="5px"
                          height="5px"
                        >
                          <Avatar
                            className="avatar"
                            src={member?.user?.image?.url}
                            onClick={(event) => {
                              handleMemberClick(member?.user?._id, event);
                            }}
                          >
                            R
                          </Avatar>
                        </AvatarStyledBadge>
                      </Tooltip>
                      <Tooltip title="Member Profile link">
                        <Link
                          sx={{ marginLeft: "10px" }}
                          component="button"
                          onClick={(event) =>
                            handleMemberClick(member?.user?._id, event)
                          }
                          className="avatar-name"
                        >
                          {member?.user?.firstName} {member?.user?.lastName}
                        </Link>
                      </Tooltip>
                    </TableCell>
                    <TableCell>Unacknowledged</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Box>
  );
}

export default memo(AcknowledgementTable);

import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@mui/material";
import VisaLogo from "../../../assets/imgs/billing/visa.png"; // Import Visa logo image
import MastercardLogo from "../../../assets/imgs/billing/mclogo.svg"; // Import Mastercard logo image
import DeleteIcon from "@mui/icons-material/Delete"; // Import Delete icon

const PaymentCard = ({
  method,
  activeStripePaymentMethodId,
  handleSetActive,
  handleDelete,
}) => {
  const isActive = method.id === activeStripePaymentMethodId;

  // Get today's date
  const today = new Date();
  const expiryMonth = method.card.exp_month;
  const expiryYear = method.card.exp_year;
  const expiryDate = new Date(expiryYear, expiryMonth - 1); // Months are 0-indexed in JavaScript
  const isExpired = expiryDate < today;
  const isExpiringSoon =
    !isExpired && expiryDate - today < 30 * 24 * 60 * 60 * 1000; // Less than 30 days

  let cardBackgroundColor;
  let warningMessage = "";

  if (isExpired) {
    cardBackgroundColor = "rgba(255, 0, 0, 0.2)"; // Red background for expired cards
    warningMessage = "Card Expired";
  } else if (isExpiringSoon) {
    cardBackgroundColor = "rgba(255, 255, 0, 0.2)"; // Yellow background for expiring soon
    warningMessage = "Expires Soon";
  } else {
    cardBackgroundColor = isActive
      ? "rgba(0, 255, 0, 0.2)"
      : "rgba(255, 255, 255, 0.8)";
  }

  return (
    <Card
      sx={{
        width: "320px", // Standard credit card width
        height: "200px", // Standard credit card height
        backgroundColor: cardBackgroundColor,
        color: isActive ? "black" : "black",
        boxShadow: 3,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 1,
        border: `2px solid ${isActive ? "green" : "#ccc"}`, // Border to make it look modern
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Visa/Mastercard logo */}
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          display: "flex",
          gap: "5px",
        }}
      >
        {method.card.brand === "visa" && (
          <img
            src={VisaLogo}
            alt="Visa"
            style={{ width: "40px", height: "auto" }}
          />
        )}
        {method.card.brand === "mastercard" && (
          <img
            src={MastercardLogo}
            alt="Mastercard"
            style={{ width: "40px", height: "auto" }}
          />
        )}
      </div>
      {/* Delete Icon */}
      {!isActive && (
        <IconButton
          onClick={() => handleDelete(method.id)}
          sx={{
            position: "absolute",
            bottom: 8,
            right: 8,
            color: "red",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            "&:hover": {
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            },
          }}
        >
          <DeleteIcon />
        </IconButton>
      )}
      <CardContent sx={{ mb: warningMessage ? -2 : 0, textAlign: "center" }}>
        <Typography variant="h6">{method.card.brand.toUpperCase()}</Typography>
        <Typography variant="body2">
          **** **** **** {method.card.last4}
        </Typography>
        <Typography variant="body2">
          Exp: {method.card.exp_month}/{method.card.exp_year}
        </Typography>
        <Typography variant="body2">
          Postal Code: {method.billing_details.address.postal_code}
        </Typography>
        {warningMessage && (
          <Typography
            variant="body2"
            sx={{
              color: isExpired ? "red" : "orange",
              fontWeight: "bold",
              mt: 1,
            }}
          >
            {warningMessage}
          </Typography>
        )}
      </CardContent>
      <CardActions sx={{ mt: 0, justifyContent: "center" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isActive}
              onChange={() => handleSetActive(method.id)}
              color="primary"
            />
          }
          label={
            <Typography
              variant="body2"
              sx={{
                color: isActive ? "green" : "black",
                fontSize: ".85rem", // Adjust font size
                fontWeight: isActive ? "bolder" : "normal",
              }}
            >
              {isActive ? "Active" : "Make Card Active"}
            </Typography>
          }
        />
      </CardActions>
    </Card>
  );
};

export default PaymentCard;

import React from "react";
import { Grid, Paper, Typography } from "@mui/material";

const DutyCard = ({ duty }) => {
  if (!duty) {
    return;
  }

  return (
    <Paper className="team-paper">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography id="duty-dialog-description" variant="body1" gutterBottom>
            <strong>Title:</strong> {duty?.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            <strong>Start:</strong> {new Date(duty.start).toLocaleString()}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            <strong>End:</strong> {new Date(duty?.end).toLocaleString()}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {duty?.member ? (
            <Typography variant="body1" gutterBottom>
              <strong>Member:</strong> {duty?.member?.user?.firstName}{" "}
              {duty?.member?.user?.lastName}
            </Typography>
          ) : (
            <Typography variant="body1" gutterBottom>
              "No Member - is Available"
            </Typography>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DutyCard;

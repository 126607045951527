import React, { useState, memo } from "react";
import {
  useResendInvitationToBrokerageMemberMutation,
  useSendSetupPasswordEmailToMemberMutation,
} from "../../../services/invitation";
import {
  Select,
  InputLabel,
  Table,
  TextField,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  TableSortLabel,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { MoreVert, Close as CloseIcon } from "@mui/icons-material/";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../helpers/utils";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import NotificationSnackbar from "../../helpers/notification-snackbar";

dayjs.extend(dayjsPluginUTC);

function MembersList({ members = [], tierId }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [
    resendInvitationToBrokerageMember,
    { isLoading: isResendInvitationToBrokerageMemberLoading },
  ] = useResendInvitationToBrokerageMemberMutation();
  const [
    sendSetupPasswordEmailToMember,
    { isLoading: isSendSetupPasswordEmailToMemberLoading },
  ] = useSendSetupPasswordEmailToMemberMutation();
  const [currentMemberIdClicked, setCurrentMemberIdClicked] = useState(null);
  const [currentMemberClicked, setCurrentMemberClicked] = useState(null);
  const [sortField, setSortField] = useState("email");
  const [sortDirection, setSortDirection] = useState("asc");
  const [filterText, setFilterText] = useState("");
  const [timeFilter, setTimeFilter] = useState("ever");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleRowClick = (event, memberUserId, memberUser) => {
    if (
      event.target.closest(".menu-button") ||
      event.target.closest(".view-details-button")
    ) {
      setCurrentMemberClicked(memberUser);
      setCurrentMemberIdClicked(memberUserId);
      return;
    }
    navigate(`/member-profile/${memberUserId}`);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    if (event) event.stopPropagation();
    setAnchorEl(null);
    setCurrentMemberClicked(null);
    setCurrentMemberIdClicked(null);
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  const handleFilterChange = (event) => {
    setFilterText(event.target.value.toLowerCase());
  };

  const sortedMembers = [...members]?.sort((a, b) => {
    // Get values for the field being sorted
    const fields = sortField.split(".");
    const aValue = fields.reduce((acc, curr) => acc[curr], a);
    const bValue = fields.reduce((acc, curr) => acc[curr], b);

    // Specific logic for sorting by 'lastOnlineTime'
    if (sortField === "user.lastOnlineTime") {
      const defaultDate = new Date(1970, 0, 1); // Default date for never logged in
      const aDate = a.user.lastOnlineTime
        ? new Date(a.user.lastOnlineTime)
        : defaultDate;
      const bDate = b.user.lastOnlineTime
        ? new Date(b.user.lastOnlineTime)
        : defaultDate;
      return sortDirection === "asc" ? aDate - bDate : bDate - aDate;
    }

    // General sorting logic for other fields
    if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
    if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
    return 0;
  });

  const filteredMembers = sortedMembers.filter((member) => {
    const filterTextLower = filterText.toLowerCase(); // Convert filter text to lower case once

    // Combine first and last name into a single string for full name searching
    const fullName =
      `${member.user.firstName} ${member.user.lastName}`.toLowerCase();

    const emailMatch = member.user.email.toLowerCase().includes(filterText);
    const firstNameMatch = member.user.firstName
      .toLowerCase()
      .includes(filterText);
    const lastNameMatch = member.user.lastName
      .toLowerCase()
      .includes(filterText);
    const fullNameMatch = fullName.includes(filterTextLower); // Check if the full name includes the filter text
    const nameMatch =
      emailMatch || firstNameMatch || lastNameMatch || fullNameMatch;

    if (!nameMatch) return false; // If none of the name filters match, don't include the member.

    const lastOnlineTime = member.user.lastOnlineTime
      ? new Date(member.user.lastOnlineTime)
      : null;

    if (timeFilter === "ever") {
      return true;
    }

    // Handling different time filters
    switch (timeFilter) {
      case "now":
        return (
          lastOnlineTime &&
          dayjs(lastOnlineTime).isAfter(dayjs().subtract(5, "minutes"))
        );
      case "15m":
        return (
          lastOnlineTime &&
          dayjs(lastOnlineTime).isAfter(dayjs().subtract(15, "minutes"))
        );
      case "30m":
        return (
          lastOnlineTime &&
          dayjs(lastOnlineTime).isAfter(dayjs().subtract(30, "minutes"))
        );
      case "1h":
        return (
          lastOnlineTime &&
          dayjs(lastOnlineTime).isAfter(dayjs().subtract(1, "hour"))
        );
      case "4h":
        return (
          lastOnlineTime &&
          dayjs(lastOnlineTime).isAfter(dayjs().subtract(4, "hours"))
        );
      case "12h":
        return (
          lastOnlineTime &&
          dayjs(lastOnlineTime).isAfter(dayjs().subtract(12, "hours"))
        );
      case "today":
        return lastOnlineTime && dayjs(lastOnlineTime).isSame(dayjs(), "day");
      case "week":
        return lastOnlineTime && dayjs(lastOnlineTime).isSame(dayjs(), "week");
      case "month":
        return lastOnlineTime && dayjs(lastOnlineTime).isSame(dayjs(), "month");
      case "never":
        return !lastOnlineTime;
      case "ever":
      default:
        return true; // No condition; return true for all records
    }
  });

  const clearFilters = () => {
    setFilterText("");
    // Reset other related state if necessary, like sortDirection or timeFilter
  };

  const handleSendPasswordChange = async (event, userId) => {
    if (!currentMemberIdClicked || !tierId) {
      setNotification({
        open: true,
        message: "There are no user or tier id.",
      });
      return;
    }
    const result = await sendSetupPasswordEmailToMember({
      tierId,
      userId: currentMemberIdClicked,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        handleMenuClose(event);
        setAnchorEl(null);
      }, 2500);
    } else {
      setNotification({
        open: true,
        message: `Failed: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleSendInviteEmail = async (event) => {
    if (!currentMemberClicked?.email || !tierId) {
      setNotification({
        open: true,
        message: "There are no user email or tier id.",
      });
      return;
    }
    const result = await resendInvitationToBrokerageMember({
      tierId,
      userEmail: currentMemberClicked?.email,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        handleMenuClose(event);
        setAnchorEl(null);
      }, 2500);
    } else {
      setNotification({
        open: true,
        message: `Failed: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
        <TextField
          label="Filter Users"
          variant="outlined"
          value={filterText}
          onChange={handleFilterChange}
          sx={{ flex: "35%" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {filterText && (
                  <IconButton onClick={clearFilters} edge="end">
                    <CloseIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        <Typography variant="h6" sx={{ textAlign: "center", flex: "30%" }}>
          User Count: {filteredMembers?.length}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", flex: "35%" }}>
          <InputLabel sx={{ mb: 0 }} id="time-filter-label">
            Online Since
          </InputLabel>
          <Select
            labelId="time-filter-label"
            value={timeFilter}
            onChange={(e) => setTimeFilter(e.target.value)}
            label="Online Since"
            sx={{ mt: 0 }}
          >
            <MenuItem value="now">Online Now</MenuItem>
            <MenuItem value="15m">Last 15 Minutes</MenuItem>
            <MenuItem value="30m">Last 30 Minutes</MenuItem>
            <MenuItem value="1h">Last Hour</MenuItem>
            <MenuItem value="4h">Last 4 Hours</MenuItem>
            <MenuItem value="12h">Last 12 Hours</MenuItem>
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="week">This Week</MenuItem>
            <MenuItem value="month">This Month</MenuItem>
            <MenuItem value="ever">Show All</MenuItem>
            <MenuItem value="never">Never Logged In</MenuItem>
          </Select>
        </Box>
      </Box>

      {members?.length === 0 || filteredMembers.length === 0 ? (
        <Typography variant="h5" m="2rem 0.1rem 0.5rem 5rem">
          No members available
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table className="user-table">
            <TableHead
              sx={{
                color: "var(--text-color)",
                backgroundColor: "var(--bg-color)",
              }}
            >
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "user.firstName"}
                    direction={sortDirection}
                    onClick={() => handleSort("user.firstName")}
                    title="Sort by First Name"
                  >
                    <sup>First</sup>
                  </TableSortLabel>
                  <TableSortLabel
                    onClick={() => handleSort("user.lastName")}
                    active={sortField === "user.lastName"}
                    direction={sortDirection}
                    title="Sort by Last Name"
                  >
                    <sup>Last</sup>
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ fontWeight: "bolder" }}>
                  <TableSortLabel
                    active={sortField === "user.email"}
                    direction={sortDirection}
                    onClick={() => handleSort("user.email")}
                  >
                    Email
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "user.accountType"}
                    direction={sortDirection}
                    onClick={() => handleSort("user.accountType")}
                  >
                    Account Type
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "user.lastOnlineTime"}
                    direction={sortDirection}
                    onClick={() => handleSort("user.lastOnlineTime")}
                  >
                    Last Online
                  </TableSortLabel>
                </TableCell>
                <TableCell>Actions </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(sortedMembers) &&
                filteredMembers?.map((member) => (
                  <TableRow
                    key={member?._id}
                    onClick={(event) =>
                      handleRowClick(event, member?.user?._id, member?.user)
                    }
                    style={{ cursor: "pointer" }}
                    className="table-row"
                  >
                    <TableCell>
                      {capitalizeFirstLetter(member?.user?.firstName)}{" "}
                      {capitalizeFirstLetter(member?.user?.lastName)}
                    </TableCell>
                    <TableCell>{member?.user?.email}</TableCell>
                    <TableCell>{member?.user?.accountType}</TableCell>
                    <TableCell>
                      {member?.user?.lastOnlineTime ? (
                        dayjs(member?.user?.lastOnlineTime).fromNow()
                      ) : (
                        <span style={{ color: "red" }}>Never</span>
                      )}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          className="menu-button"
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(event) =>
                            handleMenuOpen(event, member?.user?._id)
                          }
                        >
                          <MoreVert />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleMenuClose}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <MenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(
                                `/member-profile/${currentMemberClicked?._id}`
                              );
                              handleMenuClose(e);
                            }}
                          >
                            <Typography
                              variant="body1"
                              className="view-details-button"
                            >
                              Open Profile
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={(event) => {
                              handleSendPasswordChange(
                                event,
                                member?.user?._id
                              );
                            }}
                          >
                            <Typography variant="body1">
                              {isSendSetupPasswordEmailToMemberLoading ? (
                                <CircularProgress size={24} />
                              ) : (
                                "(Re) Send Password Change"
                              )}
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={(event) => {
                              handleSendInviteEmail(event);
                            }}
                          >
                            <Typography variant="body1">
                              {isResendInvitationToBrokerageMemberLoading ? (
                                <CircularProgress size={24} />
                              ) : (
                                "(Re) Send Invitation"
                              )}
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
}

export default memo(MembersList);

import React, { useState, useContext, useEffect, memo } from "react";
import { useInviteBrokerageMemberMutation } from "../../../services/invitation";
import { AuthContext } from "../../../context/AuthContext";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const InviteNewMemberToBrokerageDialog = ({
  open,
  onClose,
  brokerageId,
  tierId,
  brokerageName,
}) => {
  const { userId } = useContext(AuthContext);
  const [memberEmail, setMemberEmail] = useState("");
  const [inviteMember] = useInviteBrokerageMemberMutation();
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await inviteMember({
      memberEmail,
      userId,
      brokerageId,
      tierId,
    });

    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error sending the member invitation link. ${result?.error?.data?.msg}`,
      });
    }
  };

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(memberEmail));
  }, [memberEmail]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Invite New Member {brokerageName}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <TextField
                variant="outlined"
                margin="normal"
                label="Member Email"
                name="Member Email"
                value={memberEmail}
                onChange={(e) => setMemberEmail(e.target.value.toLowerCase())}
                fullWidth
                required
                onCopy={(e) => e.preventDefault()}
                // onPaste={(e) => e.preventDefault()}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={!isEmailValid}
        >
          Invite New Member
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default memo(InviteNewMemberToBrokerageDialog);

import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { formatMoney } from "../../../helpers/utils";

const ProductTable = ({
  products,
  order,
  orderBy,
  page,
  rowsPerPage,
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleEditClick,
  handleDeleteClick,
}) => {
  // State to track which delete icon is being hovered
  const [hoveredDeleteId, setHoveredDeleteId] = useState(null);
  const rowColour = "#ffffff"; // Alternate row color
  const rowColourAlt = "#f6f6f6"; // Alternate row color
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === "productCode"}
                direction={orderBy === "productCode" ? order : "asc"}
                onClick={() => handleRequestSort("productCode")}
              >
                Product Code
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "name"}
                direction={orderBy === "name" ? order : "asc"}
                onClick={() => handleRequestSort("name")}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Per User</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Subscription Type</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((product, index) => (
              <TableRow
                key={product._id}
                sx={{
                  backgroundColor: index % 2 === 0 ? rowColour : rowColourAlt, // Alternates row colors
                }}
              >
                <TableCell>{product.productCode}</TableCell>
                <TableCell>{product.name}</TableCell>
                <TableCell>${formatMoney(product.price)}</TableCell>
                <TableCell>{product.perUser ? "Yes" : "No"}</TableCell>
                <TableCell>{product.clientType}</TableCell>
                <TableCell>
                  {product.subscriptionType === "recurring"
                    ? `Recurring - ${product.recurringFrequency}`
                    : product.subscriptionType}
                </TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton onClick={() => handleEditClick(product)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => handleDeleteClick(product)}
                      onMouseEnter={() => setHoveredDeleteId(product._id)}
                      onMouseLeave={() => setHoveredDeleteId(null)}
                    >
                      <Delete
                        sx={{
                          color:
                            hoveredDeleteId === product._id ? "red" : "inherit",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={products.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default ProductTable;

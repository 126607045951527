import React from "react";
import { Container, Typography, Paper } from "@mui/material";

const PostManagementEmptyTab = () => {
  return (
    <Container className="management-page">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Post's Management Empty Tab... In development
        </Typography>
      </Paper>
    </Container>
  );
};

export default PostManagementEmptyTab;

import React, { useState, useEffect } from "react";
import {
  useCreateUserRoleNameEnumMutation,
  useUpdateUserRoleNameEnumMutation,
} from "../../../services/user-role-name-enum";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const CreateEditUserRoleNameEnumForm = ({
  open,
  onClose,
  userRoleNameEnum,
  mode = "edit",
}) => {
  const [newUserRoleName, setNewUserRoleName] = useState("");
  const [createUserRoleNameEnum, { isLoading: isCreating }] =
    useCreateUserRoleNameEnumMutation();
  const [updateUserRoleNameEnum, { isLoading: isUpdating }] =
    useUpdateUserRoleNameEnumMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (userRoleNameEnum) {
      setNewUserRoleName(userRoleNameEnum.userRoleName);
    }
  }, [userRoleNameEnum]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newUserRoleName.trim()) {
      setNotification({
        open: true,
        message: "Please fill in required field.",
      });
      return;
    }

    let result;
    if (mode === "create") {
      result = await createUserRoleNameEnum({
        userRoleName: newUserRoleName,
      });
    } else if (mode === "edit" || mode === "editOne") {
      result = await updateUserRoleNameEnum({
        userRoleNameEnumId: userRoleNameEnum?._id,
        userRoleName: newUserRoleName,
      });
    }
    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        if (mode === "create") {
          setNewUserRoleName("");
        }
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error saving role: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      className="create-edit-dialog"
    >
      <DialogTitle>
        <Typography className="dialog-title">
          {mode === "create"
            ? "Create New Role Name Enum"
            : "Edit Role Name Enum"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} className="sub-items-list">
          <TextField
            className="sub-items-list-title"
            placeholder="Name"
            name="name"
            value={newUserRoleName}
            onChange={(event) => {
              setNewUserRoleName(event.target.value);
            }}
            fullWidth
            required
            margin="normal"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" type="submit" fullWidth>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
          disabled={isCreating || isUpdating}
        >
          {isCreating || isUpdating ? (
            <CircularProgress size={24} />
          ) : mode === "create" ? (
            "Create"
          ) : (
            "Save"
          )}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default CreateEditUserRoleNameEnumForm;

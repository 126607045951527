import React, { useState, useEffect } from "react";
import {
  useUpdateGroupMutation,
  useGetGroupByIdQuery,
} from "../../../services/group";
import { useGetAllTierMembersByBrokerageQuery } from "../../../services/member";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const UpdateGroupDialog = ({ open, onClose, groupId, brokerageId }) => {
  const [name, setName] = useState("");
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [inputMemberValue, setInputMemberValue] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const { data: members } = useGetAllTierMembersByBrokerageQuery(brokerageId, {
    skip: !brokerageId,
  });

  const { data: group, isLoading: isGroupLoading } = useGetGroupByIdQuery(
    groupId,
    {
      skip: !groupId,
    }
  );

  const [updateGroup, { isLoading }] = useUpdateGroupMutation();

  useEffect(() => {
    if (group) {
      setName(group.name);
      setSelectedMemberId(group.groupLead.member);
    }
  }, [group]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const result = await updateGroup({
      groupId,
      name,
      memberId: selectedMemberId?._id,
    });

    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        onClose();
      }, 1000);
    } else {
      setNotification({
        open: true,
        message: `Error updating group: ${
          result?.error?.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (isGroupLoading) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Update Group</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Autocomplete
            value={selectedMemberId}
            onChange={(event, newValue) => {
              setSelectedMemberId(newValue);
            }}
            inputValue={inputMemberValue}
            onInputChange={(event, newInputValue) => {
              setInputMemberValue(newInputValue);
            }}
            options={members || []}
            getOptionLabel={(option) =>
              option
                ? `${option?.user?.firstName} ${option?.user?.lastName} (${option?.user?.email})`
                : ""
            }
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Group Lead"
                variant="outlined"
                margin="normal"
                required
              />
            )}
          />
          <TextField
            variant="outlined"
            margin="normal"
            label="Group Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : "Update Group"}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default UpdateGroupDialog;

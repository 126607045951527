import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useFetchUserDetailsQuery } from "../../../services/user";
import { Paper, Box, CircularProgress, Tabs, Tab } from "@mui/material";
import { BackButton } from "../..";
import RoleManager from "./RoleManager";
import LogsManager from "./LogsManager";
import UserAccount from "./UserAccount";
import { TabPanel } from "../../helpers/utils";
import MemberProfile from "./MemberProfile";

function UserProfile() {
  const { userId } = useParams();
  const {
    data: user,
    isLoading,
    isError,
    error,
    refetch,
  } = useFetchUserDetailsQuery(userId);
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (isLoading)
    return (
      <div className="loading-spinner">
        <CircularProgress size={24} />
      </div>
    );
  if (isError || !user)
    return <div>Error loading user details {error?.data?.msg}</div>;
  console.log(user);
  return (
    <Box className="management-page">
      <BackButton />
      <Paper className="user-profile-management">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="user profile tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label="User Account"
            id="simple-tab-0"
            aria-controls="simple-tabpanel-0"
          />
          <Tab
            label="Role"
            id="simple-tab-1"
            aria-controls="simple-tabpanel-1"
          />
          <Tab
            label="Logs"
            id="simple-tab-2"
            aria-controls="simple-tabpanel-2"
          />
          {user?.accountType !== "reosadmin" && (
            <Tab
              label="Member Profile"
              id="simple-tab-3"
              aria-controls="simple-tabpanel-3"
            />
          )}
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <UserAccount
            user={user}
            refetch={refetch}
            isLoading={isLoading}
            isError={isError}
            error={error}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <RoleManager
            userId={userId}
            accountType={user?.accountType}
            userRole={user?.role}
            refetch={refetch}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <LogsManager logs={user?.logs} />
        </TabPanel>
        {user?.accountType !== "reosadmin" && (
          <TabPanel value={tabValue} index={3}>
            <MemberProfile
              userMemberId={userId}
              userRole={user?.role}
              refetch={refetch}
            />
          </TabPanel>
        )}
      </Paper>
    </Box>
  );
}

export default UserProfile;

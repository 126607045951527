import React, { useState } from "react";
import {
  useGetAllInvitationQuery,
  useRemoveInvitationMutation,
} from "../../../services/invitation";
import {
  Table,
  TextField,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  TableSortLabel,
  FormControl,
  InputLabel,
  Select,
  Container,
} from "@mui/material";
import { MoreVert, DeleteForever } from "@mui/icons-material/";
import { ConfirmationDialog } from "../..";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../helpers/utils";

function InvitationList() {
  const navigate = useNavigate();
  const { data: invitations } = useGetAllInvitationQuery();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [invitationIdToRemove, setInvitationIdToRemove] = useState(null);
  const [currentInvitationClicked, setCurrentInvitationClick] = useState(null);
  const [removeInvitation] = useRemoveInvitationMutation();

  const [sortField, setSortField] = useState("userEmail");
  const [sortDirection, setSortDirection] = useState("asc");

  const [statusFilter, setStatusFilter] = useState("");
  const [accountTypeFilter, setAccountTypeFilter] = useState("");
  const [textFilter, setTextFilter] = useState(""); // State for text filter

  const handleRowClick = (event, invitationId) => {
    if (
      event.target.closest(".menu-button") ||
      event.target.closest(".view-details-button")
    ) {
      setCurrentInvitationClick(invitationId);
      return;
    }
    navigate(`/invitation/${invitationId}`);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = (event, invitationId) => {
    event.stopPropagation();
    setInvitationIdToRemove(invitationId);
    setDialogOpen(true);
  };

  const handleDialogClose = (event) => {
    event && event.stopPropagation();
    setDialogOpen(false);
    setInvitationIdToRemove(null);
  };

  const handleDialogConfirm = async (event) => {
    event && event.stopPropagation();
    await removeInvitation(invitationIdToRemove);
    handleDialogClose();
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  if (!invitations || invitations?.length === 0) {
    return <div>No invitations available</div>;
  }

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleAccountTypeFilterChange = (event) => {
    setAccountTypeFilter(event.target.value);
  };

  const handleTextFilterChange = (event) => {
    setTextFilter(event.target.value.toLowerCase()); // Update text filter state
  };

  let filteredInvitations = [...invitations];

  if (statusFilter) {
    filteredInvitations = filteredInvitations?.filter(
      (invitation) => invitation?.status === statusFilter
    );
  }
  if (accountTypeFilter) {
    filteredInvitations = filteredInvitations?.filter(
      (invitation) => invitation?.accountType === accountTypeFilter
    );
  }
  if (textFilter) {
    filteredInvitations = filteredInvitations?.filter((invitation) =>
      // Filter based on user email
      invitation?.userEmail.toLowerCase().includes(textFilter)
    );
  }

  const sortedInvitations = filteredInvitations?.sort((a, b) => {
    if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
    if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
    return 0;
  });

  // if (statusFilter) {
  //   filteredInvitations = filteredInvitations?.filter(
  //     (invitation) => invitation?.status === statusFilter
  //   );
  // }

  // if (accountTypeFilter) {
  //   filteredInvitations = filteredInvitations?.filter(
  //     (invitation) => invitation?.accountType === accountTypeFilter
  //   );
  // }

  return (
    <Container className="management-page">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Invitation Management
        </Typography>
      </Paper>

      <Box sx={{ marginBottom: "10px", display: "flex", gap: "10px" }}>
        <TextField
          label="Filter By"
          variant="outlined"
          size="small"
          fullWidth
          value={textFilter}
          onChange={handleTextFilterChange}
          style={{ marginBottom: "10px" }}
        />
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          sx={{ minWidth: 120 }}
        >
          <InputLabel id="status-filter-label">Filter by Status</InputLabel>
          <Select
            labelId="status-filter-label"
            value={statusFilter}
            onChange={handleStatusFilterChange}
            label="Filter by Status"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="active">Accepted</MenuItem>
            <MenuItem value="active">Declined</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          sx={{ minWidth: 120 }}
        >
          <InputLabel id="account-type-filter-label">
            Filter by Account Type
          </InputLabel>
          <Select
            labelId="account-type-filter-label"
            value={accountTypeFilter}
            onChange={handleAccountTypeFilterChange}
            label="Filter by Account Type"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="realtyadmin">RealtyOS Admin</MenuItem>
            <MenuItem value="realtor">Realtor</MenuItem>
            <MenuItem value="client">Client</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortField === "userEmail"}
                  direction={sortDirection}
                  onClick={() => handleSort("userEmail")}
                >
                  User Email
                </TableSortLabel>
              </TableCell>

              <TableCell>
                <TableSortLabel
                  active={sortField === "accountType"}
                  direction={sortDirection}
                  onClick={() => handleSort("accountType")}
                >
                  Account Type
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "tier.level"}
                  direction={sortDirection}
                  onClick={() => handleSort("tier.level")}
                >
                  Tier
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "status"}
                  direction={sortDirection}
                  onClick={() => handleSort("status")}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "sender"}
                  direction={sortDirection}
                  onClick={() => handleSort("sender")}
                >
                  Sender Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "sentAt"}
                  direction={sortDirection}
                  onClick={() => handleSort("sentAt")}
                >
                  Sent at
                </TableSortLabel>
              </TableCell>

              <TableCell>Actions </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {Array.isArray(sortedInvitations) &&
              sortedInvitations?.map((invitation) => (
                <TableRow
                  key={invitation?._id}
                  onClick={(event) => handleRowClick(event, invitation?._id)}
                  style={{ cursor: "pointer" }}
                  className="table-row"
                >
                  <TableCell>{invitation?.userEmail}</TableCell>
                  <TableCell>{invitation?.accountType}</TableCell>
                  <TableCell>{invitation?.tier?.level}</TableCell>
                  <TableCell>{invitation?.status}</TableCell>
                  <TableCell>
                    {capitalizeFirstLetter(invitation?.sender?.firstName)}{" "}
                    {capitalizeFirstLetter(invitation?.sender?.lastName)}
                  </TableCell>
                  <TableCell>
                    {new Date(invitation?.sentAt)?.toLocaleString()}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        aria-label="delete"
                        onClick={(event) =>
                          handleDeleteClick(event, invitation?._id)
                        }
                      >
                        <DeleteForever />
                      </IconButton>
                      <IconButton
                        className="menu-button"
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(event) =>
                          handleMenuOpen(event, invitation?._id)
                        }
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                      >
                        <MenuItem>
                          <Typography
                            variant="body1"
                            className="view-details-button"
                            onClick={() =>
                              navigate(
                                `/invitation/${currentInvitationClicked}`
                              )
                            }
                          >
                            View Details
                          </Typography>
                        </MenuItem>
                      </Menu>
                      <ConfirmationDialog
                        open={dialogOpen}
                        onClose={handleDialogClose}
                        onConfirm={handleDialogConfirm}
                        title="Confirm Deleting"
                        description="Are you sure you want to delete this invitation?"
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default InvitationList;

import React, { useState } from "react";
import { useGetAllMembersQuery } from "../../../../services/member";
import { useAddExistingMemberMutation } from "../../../../services/group";
import { useGetAllGroupRolesQuery } from "../../../../services/group-role";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  TextField,
  Autocomplete,
} from "@mui/material";
import NotificationSnackbar from "../../../helpers/notification-snackbar";

const AddExistingMember = ({ groupId, open, onClose, refetch }) => {
  const [addExistingMember] = useAddExistingMemberMutation();
  const [memberId, setMemberId] = useState("");
  const [inputmembervalue, setInputmembervalue] = useState("");
  const [roleId, setRoleId] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const { data: groupRoles, isLoading: loadingGroupRoles } =
    useGetAllGroupRolesQuery(groupId, {
      skip: !groupId,
    });
  const { data: members } = useGetAllMembersQuery();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addExistingMember({
      groupId,
      memberId: memberId?._id,
      roleId,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      refetch();
      setTimeout(() => {
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error adding member. ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Add Member</DialogTitle>
      <DialogContent>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Autocomplete
              value={memberId}
              onChange={(event, newValue) => {
                setMemberId(newValue);
              }}
              inputValue={inputmembervalue}
              onInputChange={(event, newInputValue) => {
                setInputmembervalue(newInputValue);
              }}
              options={members || []}
              getOptionLabel={(option) =>
                option
                  ? `${option?.user?.firstName} ${option?.user?.lastName} (${option?.user?.email})`
                  : ""
              }
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select an existing Member"
                  variant="outlined"
                  margin="normal"
                  required
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item>
          <FormControl fullWidth margin="normal">
            <InputLabel id="role-label">Group Role</InputLabel>
            <Select
              labelId="role-label"
              id="roleId"
              value={roleId}
              onChange={(e) => setRoleId(e.target.value)}
              fullWidth
            >
              {loadingGroupRoles ? (
                <MenuItem value="">
                  <div className="loading-spinner">
                    <CircularProgress size={24} />
                  </div>
                </MenuItem>
              ) : (
                groupRoles?.map((role) => (
                  <MenuItem key={role?._id} value={role?._id}>
                    {role?.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Add
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default AddExistingMember;

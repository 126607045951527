import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Button,
  TextField,
  Autocomplete,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AuthContext } from "../../../context/AuthContext";
import { useInviteBrokerageMutation } from "../../../services/invitation";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import realEstateBoardsList from "../../helpers/realEstateBoardsList";

const InviteBrokerageDialog = ({ open, onClose }) => {
  const { userId } = useContext(AuthContext);
  const [selectedRealEstateBoardName, setSelectedRealEstateBoardName] =
    useState("");
  const [inputValue, setInputValue] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [inviteBrokerage] = useInviteBrokerageMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const result = await inviteBrokerage({
        senderId: userId,
        brokerageEmail: userEmail,
        realEstateBoardName: selectedRealEstateBoardName,
      });
      if (result?.data) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        setTimeout(() => {
          onClose();
        }, 1500);
      } else {
        setNotification({
          open: true,
          message: `Error sending the brokerage invitation link. ${result?.error?.data?.msg}`,
        });
      }
    },
    [selectedRealEstateBoardName, userId, userEmail, inviteBrokerage, onClose]
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(userEmail));
  }, [userEmail]);

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>Invite Brokerage</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} id="invite-form">
            <Grid container spacing={3} direction="column">
              <Grid item>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label="Brokerage Email"
                  name="brokerageEmail"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value.toLowerCase())}
                  onCopy={(e) => e.preventDefault()}
                  // onPaste={(e) => e.preventDefault()}
                  required
                  error={!isEmailValid && userEmail !== ""}
                  helperText={
                    !isEmailValid && userEmail !== ""
                      ? "Please enter a valid email."
                      : ""
                  }
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  value={selectedRealEstateBoardName}
                  onChange={(event, newValue) => {
                    setSelectedRealEstateBoardName(newValue);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  options={realEstateBoardsList}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select a Real Estate Board"
                      variant="outlined"
                      margin="normal"
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!isEmailValid || !selectedRealEstateBoardName}
          >
            Invite Brokerage
          </Button>
        </DialogActions>
      </Dialog>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
};

export default InviteBrokerageDialog;

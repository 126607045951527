import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import { useGetAllDataEntryLogsQuery } from "../../../services/data-entry-logs";
import { AuthContext } from "../../../context/AuthContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Divider,
  Box,
  TableSortLabel,
  CircularProgress,
} from "@mui/material";
import useInfiniteScroll from "../../../services/custom-hooks/useInfiniteScrollToBottom";

function DataEntryLogsList() {
  const loaderRef = useRef(null);
  const { accountType, userId } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [allLogs, setAllLogs] = useState([]);

  const { data, isFetching } = useGetAllDataEntryLogsQuery(
    { accountType, page, limit: 10 },
    {
      skip: !userId && accountType !== "reosadmin",
    }
  );

  useEffect(() => {
    if (data?.logs) {
      setAllLogs((prev) => [...prev, ...data.logs]);
      setHasMore(data?.logs.length > 0);
    }
  }, [data]);

  useInfiniteScroll(
    loaderRef,
    () => {
      if (!isFetching) {
        setPage((prevPage) => prevPage + 1);
      }
    },
    hasMore
  );

  const [sortField, setSortField] = useState("timestamp");
  const [sortDirection, setSortDirection] = useState("desc");

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection((prevDirection) =>
        prevDirection === "asc" ? "desc" : "asc"
      );
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const sortedlogs = useMemo(() => {
    if (!Array.isArray(allLogs)) return [];
    return [...allLogs].sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];
      if (sortDirection === "asc") {
        return aValue < bValue ? -1 : 1;
      }
      return aValue > bValue ? -1 : 1;
    });
  }, [allLogs, sortField, sortDirection]);

  if (!allLogs || allLogs?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 20,
        }}
      >
        <Typography variant="h5" gutterBottom>
          No Data Entry Logs available
        </Typography>
      </Box>
    );
  }

  return (
    <div ref={loaderRef} className="management-page-body">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortField === "user?.firstName"}
                  direction={sortDirection}
                  onClick={() => handleSort("user?.firstName")}
                >
                  User Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "actionType"}
                  direction={sortDirection}
                  onClick={() => handleSort("actionType")}
                >
                  Action Type
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "actionDetails"}
                  direction={sortDirection}
                  onClick={() => handleSort("actionDetails")}
                >
                  Action Details
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "result"}
                  direction={sortDirection}
                  onClick={() => handleSort("result")}
                >
                  result
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "timestamp"}
                  direction={sortDirection}
                  onClick={() => handleSort("timestamp")}
                >
                  timestamp
                </TableSortLabel>
              </TableCell>
              <TableCell>ip</TableCell>
              <TableCell>User Agent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedlogs?.map((dataEntryLog, i) => (
              <TableRow key={`${dataEntryLog?._id}-${i}`} className="table-row">
                <TableCell>
                  {dataEntryLog?.user?.firstName} {dataEntryLog?.user?.lastName}
                </TableCell>
                <TableCell>{dataEntryLog?.actionType}</TableCell>
                <TableCell>{dataEntryLog?.actionDetails}</TableCell>
                <TableCell>{dataEntryLog?.result}</TableCell>
                <TableCell>
                  {new Date(dataEntryLog?.timestamp).toLocaleString()}
                </TableCell>
                <TableCell>{dataEntryLog?.ip}</TableCell>
                <TableCell>{dataEntryLog?.userAgent}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isFetching && (
        <div className="loading-spinner">
          <CircularProgress size={20} />
        </div>
      )}
      <Divider style={{ margin: "20px 0" }} />
    </div>
  );
}

export default DataEntryLogsList;

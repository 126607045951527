import React, { useState, useContext, memo } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { formattedUrl, formatPhoneNumber } from "../../helpers/utils";
import {
  useAddRegBrokerageToLeadBrokerageItSelfMutation,
  useCreateBrokerageInitialCategoryMutation,
  useCreateInitialLeadBrokerageCategoriesMutation,
  useMakeBrokerageLeadMutation,
  useDemoteBrokerageLeadMutation,
  useUpdateBrokerageStatusMutation,
  useSyncLeadBrokerageDataMutation,
} from "../../../services/brokerage";
import { useCleanUpCategoriesAndGroupsByTierMutation } from "../../../services/clean-up";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card,
  Link,
  CardContent,
  Typography,
  CardHeader,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  CircularProgress,
} from "@mui/material";
import { MoreVert, Edit } from "@mui/icons-material/";
import BrokerageEditDrawer from "./BrokerageEditDrawer";
import BrokerageAssignUserForm from "./BrokerageAssignUserForm";
import AddRemoveAssignUnassignToFromLeadBrokerageDialog from "../../reosadmin/brokerages/AddRemoveAssignUnassignToFromLeadBrokerageDialog";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import { ConfirmationDialog } from "../..";

const BrokerageCard = ({ brokerage, refetchBrokerage }) => {
  const { userRole, accountType } = useContext(AuthContext);
  const isAdminRole = [
    "reosadmin",
    "brokeragesuperadmin",
    "reossuperadmin",
  ].includes(userRole);
  const isREOSAdminRole = ["reosadmin", "reossuperadmin"].includes(userRole);
  const isAdmin = [
    "reosadmin",
    "reossuperadmin",
    "brokerage",
    "brokerageadmin",
    "brokeragesuperadmin",
  ].includes(userRole);
  const [
    createLeadBrokerageInitialCategory,
    { isLoading: isLeadBrokerageInitialCategoriesLoading },
  ] = useCreateInitialLeadBrokerageCategoriesMutation();
  const [
    createBrokerageInitialCategory,
    { isLoading: isBrokerageCreateInitialCategoriesLoading },
  ] = useCreateBrokerageInitialCategoryMutation();
  const [makeBrokerageLead, { isLoading: isMakeBrokerageLeadLoading }] =
    useMakeBrokerageLeadMutation();
  const [
    addRegBrokerageToLeadBrokerageItSelf,
    { isLoading: isAddRegBrokerageToLeadBrokerageItSelfLoading },
  ] = useAddRegBrokerageToLeadBrokerageItSelfMutation();

  const [demoteBrokerageLead, { isLoading: isDemoteBrokerageLeadLoading }] =
    useDemoteBrokerageLeadMutation();
  const [
    cleanUpCategoriesAndGroupsBrokerageTier,
    { isLoading: isCleanUpCategoriesAndGroupsBrokerageTierLoading },
  ] = useCleanUpCategoriesAndGroupsByTierMutation();
  const [
    cleanUpCategoriesAndGroupsLeadBrokerageTier,
    { isLoading: isCleanUpCategoriesAndGroupsLeadBrokerageTierLoading },
  ] = useCleanUpCategoriesAndGroupsByTierMutation();
  const [updateBrokerageStatus, { isUpdateBrokerageStatusLoading }] =
    useUpdateBrokerageStatusMutation();
  const [syncLeadBrokerageData, { isLoading: isSyncLeadBrokerageDataLoading }] =
    useSyncLeadBrokerageDataMutation();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isBrokerageEditDrawerOpen, setIsBrokerageEditDrawerOpen] =
    useState(false);
  const [action, setAction] = useState("");
  const [isLeadBrokerageDialogOpen, setIsLeadBrokerageDialogOpen] =
    useState(false);
  const [
    isAssignUserToBrokerageDialogOpen,
    setIsAssignUserToBrokerageDialogOpen,
  ] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [
    openSyncLeadBrokerageDataConfirmationDialog,
    setOpenSyncLeadBrokerageDataConfirmationDialog,
  ] = useState(false);

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleBrokerageEditDrawerOpen = () => {
    setMenuAnchorEl(null);
    setIsBrokerageEditDrawerOpen(true);
  };

  const handleBrokerageEditDrawerClose = () => {
    setIsBrokerageEditDrawerOpen(false);
    refetchBrokerage();
  };

  const handleLeadBrokerageDialogOpen = (actionType) => {
    setAction(actionType);
    setMenuAnchorEl(null);
    setIsLeadBrokerageDialogOpen(true);
  };

  const handleLeadBrokerageDialogClose = () => {
    setIsLeadBrokerageDialogOpen(false);
  };

  const handleAssignUserToBrokerageDialogOpen = () => {
    setMenuAnchorEl(null);
    setIsAssignUserToBrokerageDialogOpen(true);
  };

  const handleAssignUserToBrokerageDialogClose = () => {
    setIsAssignUserToBrokerageDialogOpen(false);
  };

  const handleCreateBrokerageInitialCategories = async () => {
    if (brokerage?.tier?.level !== "brokerage") {
      setNotification({
        openNotification: true,
        message: "Please select a tier with the brokerage level first.",
      });
      return;
    }
    const result = await createBrokerageInitialCategory({
      tierId: brokerage?.tier?._id,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `Failed to create Brokerage initial categories ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleCreateLeadBrokerageInitialCategories = async () => {
    if (
      brokerage?.tier?.level !== "brokerage" &&
      !brokerage?.leadBrokerage &&
      !brokerage?.isLeadBrokerage
    ) {
      setNotification({
        open: true,
        message: "The tier isn't the Lead Brokerage.",
      });
      return;
    }
    const result = await createLeadBrokerageInitialCategory({
      leadBrokerageId: brokerage?._id,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `Failed to create initial categories ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleAddRegBrokerageToLeadBrokerageItSelf = async () => {
    if (brokerage?.brokerages?.includes(brokerage?._id)) {
      setNotification({
        open: true,
        message:
          "The Lead Brokerage tier already includes the Reg Brokerage tier.",
      });
      return;
    }
    const result = await addRegBrokerageToLeadBrokerageItSelf({
      brokerageId: brokerage?._id,
      leadBrokerageId: brokerage?._id,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `Failed: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleMakeBrokerageLead = async () => {
    if (brokerage?.tier?.level !== "brokerage" && brokerage?.isLeadBrokerage) {
      setNotification({
        open: true,
        message: "The tier isn't Brokerage or already the Lead Brokerage.",
      });
      return;
    }
    const result = await makeBrokerageLead({
      brokerageId: brokerage?._id,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `Failed: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleDemoteBrokerageLead = async () => {
    if (brokerage?.tier?.level !== "brokerage" && !brokerage?.isLeadBrokerage) {
      setNotification({
        open: true,
        message: "The tier isn't Brokerage or isn't the Lead Brokerage.",
      });
      return;
    }
    const result = await demoteBrokerageLead({
      brokerageId: brokerage?._id,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `Failed: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleCleanUpCategoriesAndGroupsBrokerageTier = async () => {
    if (brokerage?.tier?.level !== "brokerage" && !brokerage?.tier?._id) {
      setNotification({
        open: true,
        message: "The tier isn't Brokerage or there is no tier id.",
      });
      return;
    }
    const result = await cleanUpCategoriesAndGroupsBrokerageTier({
      tierId: brokerage?.tier?._id,
      accountType,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `Failed: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleCleanUpCategoriesAndGroupsLeadBrokerageTier = async () => {
    if (
      brokerage?.leadBrokerageTier?.level !== "brokerage" &&
      !brokerage?.leadBrokerageTier?._id
    ) {
      setNotification({
        open: true,
        message:
          "The tier isn't Lead Brokerage or there is no lead brokerage tier id.",
      });
      return;
    }
    const result = await cleanUpCategoriesAndGroupsLeadBrokerageTier({
      tierId: brokerage?.leadBrokerageTier?._id,
      accountType,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `Failed: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleUpdateBrokerageStatus = async () => {
    if (!brokerage) {
      setNotification({
        open: true,
        message: "The brokerage id is not provided",
      });
      return;
    }
    const newStatus = brokerage?.status === "active" ? "pending" : "active";
    if (brokerage?._id) {
      const result = await updateBrokerageStatus({
        brokerageId: brokerage._id,
        status: newStatus,
      });

      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        setMenuAnchorEl(null);
      } else {
        setNotification({
          open: true,
          message: `Error: ${result?.error?.data?.msg}`,
        });
      }
    }
  };

  const handleSyncLeadBrokerageData = async () => {
    if (brokerage?.tier?.level !== "brokerage" && !brokerage?.isLeadBrokerage) {
      setNotification({
        open: true,
        message: "The Office isn't the Lead Brokerage.",
      });
      return;
    }
    handleCloseSyncLeadBrokerageDataDialog();
    const result = await syncLeadBrokerageData({
      leadBrokerageId: brokerage?._id,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `Failed: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleOpenUpdateChatParticipantsForTierDialog = () => {
    setOpenSyncLeadBrokerageDataConfirmationDialog(true);
  };

  const handleCloseSyncLeadBrokerageDataDialog = () => {
    setOpenSyncLeadBrokerageDataConfirmationDialog(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (!brokerage) return;

  return (
    <Card className="brokerage-card">
      <CardHeader
        className="brokerage-card-header"
        avatar={
          <CardMedia
            component="img"
            className="brokerage-card-header-image"
            image={brokerage?.image?.url}
            alt={`${brokerage?.officeName} name` || "Brokerage Name"}
          />
        }
        title={
          <>
            <Typography className="brokerage-card-header-title">
              {`${brokerage?.officeName}`}
            </Typography>

            <Typography className="brokerage-card-header-subtitle">
              {` ${brokerage?.isLeadBrokerage ? " Lead Brokerage" : ""}` ||
                "Brokerage Name"}
            </Typography>
          </>
        }
        action={
          <>
            <IconButton
              aria-label="settings"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleMenuOpen}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                onClick={() => {
                  handleBrokerageEditDrawerOpen();
                }}
              >
                <Edit fontSize="small" />
                <Typography ml={1}>Edit</Typography>
              </MenuItem>
              {userRole.includes("reos") && (
                <MenuItem onClick={handleUpdateBrokerageStatus}>
                  <Typography ml={1}>
                    {isUpdateBrokerageStatusLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      `Update Brokerage Status to: ${
                        brokerage?.status === "active"
                          ? "pending"
                          : brokerage?.status === "pending"
                          ? "active"
                          : "no status"
                      }`
                    )}
                  </Typography>
                </MenuItem>
              )}
              {userRole.includes("reos") && (
                <MenuItem
                  onClick={
                    !brokerage?.isLeadBrokerage
                      ? handleMakeBrokerageLead
                      : handleDemoteBrokerageLead
                  }
                >
                  <Typography ml={1}>
                    {(!brokerage?.isLeadBrokerage &&
                      isMakeBrokerageLeadLoading) ||
                    (brokerage?.isLeadBrokerage &&
                      isDemoteBrokerageLeadLoading) ? (
                      <CircularProgress size={24} />
                    ) : (
                      `${
                        brokerage?.isLeadBrokerage ? "Demote" : "Make"
                      } Brokerage Lead`
                    )}
                  </Typography>
                </MenuItem>
              )}
              {userRole.includes("reos") && brokerage?.isLeadBrokerage && (
                <MenuItem onClick={handleAddRegBrokerageToLeadBrokerageItSelf}>
                  <Typography ml={1}>
                    {isAddRegBrokerageToLeadBrokerageItSelfLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      `Add Reg Brokerage To Lead Brokerage It Self`
                    )}
                  </Typography>
                </MenuItem>
              )}
              {userRole.includes("reos") && brokerage?.isLeadBrokerage && (
                <MenuItem
                  onClick={handleOpenUpdateChatParticipantsForTierDialog}
                >
                  <Typography ml={1}>
                    {isSyncLeadBrokerageDataLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      `Sync Lead Brokerage Data`
                    )}
                  </Typography>
                </MenuItem>
              )}
              {userRole.includes("reos") && (
                <MenuItem
                  onClick={handleCleanUpCategoriesAndGroupsBrokerageTier}
                >
                  <Typography ml={1}>
                    {isCleanUpCategoriesAndGroupsBrokerageTierLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Clean Up Categories And Groups For Brokerage Tier"
                    )}
                  </Typography>
                </MenuItem>
              )}
              {userRole.includes("reos") && (
                <MenuItem
                  onClick={handleCleanUpCategoriesAndGroupsLeadBrokerageTier}
                >
                  <Typography ml={1}>
                    {isCleanUpCategoriesAndGroupsLeadBrokerageTierLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Clean Up Categories And Groups For Lead Brokerage Tier"
                    )}
                  </Typography>
                </MenuItem>
              )}
              {userRole.includes("reos") && (
                <MenuItem onClick={handleAssignUserToBrokerageDialogOpen}>
                  <Typography ml={1}>Assign User To Brokerage </Typography>
                </MenuItem>
              )}
              {(userRole.includes("brokerage") || userRole.includes("reos")) &&
                brokerage?.isLeadBrokerage && (
                  <MenuItem
                    onClick={handleCreateLeadBrokerageInitialCategories}
                  >
                    <Typography ml={1}>
                      {isLeadBrokerageInitialCategoriesLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Create Initial Lead Brokerage Categories"
                      )}
                    </Typography>
                  </MenuItem>
                )}
              {(userRole.includes("brokerage") ||
                userRole.includes("reos")) && (
                <MenuItem onClick={handleCreateBrokerageInitialCategories}>
                  <Typography ml={1}>
                    {isBrokerageCreateInitialCategoriesLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Create Brokerage Initial Categories"
                    )}
                  </Typography>
                </MenuItem>
              )}
              {userRole.includes("reos") && (
                <MenuItem
                  onClick={(event) => {
                    event.stopPropagation();
                    handleLeadBrokerageDialogOpen("addToLeadBrokerage");
                  }}
                >
                  <Typography ml={1}>Add To Lead Brokerage</Typography>
                </MenuItem>
              )}
              {userRole.includes("reos") && (
                <MenuItem
                  onClick={(event) => {
                    event.stopPropagation();
                    handleLeadBrokerageDialogOpen("removeFromLeadBrokerage");
                  }}
                >
                  <Typography ml={1}>Remove From Lead Brokerage</Typography>
                </MenuItem>
              )}
            </Menu>
          </>
        }
      />
      <CardContent>
        <Table className="brokerage-table">
          <TableBody>
            {/* Lead Brokerage */}
            {brokerage?.isLeadBrokerage && (
              <TableRow className="brokerage-row">
                <TableCell
                  component="th"
                  scope="row"
                  className="brokerage-cell-header"
                >
                  <Typography variant="subtitle2" className="header-strong">
                    Lead Brokerage:
                  </Typography>
                </TableCell>
                <TableCell className="brokerage-cell">
                  <Typography variant="subtitle2">Yes</Typography>
                </TableCell>
              </TableRow>
            )}
            {/* Brokerage Status */}
            <TableRow className="brokerage-row">
              <TableCell
                component="th"
                scope="row"
                className="brokerage-cell-header"
              >
                <Typography variant="subtitle2" className="header-strong">
                  Brokerage Status:
                </Typography>
              </TableCell>
              <TableCell className="brokerage-cell">
                <Typography variant="subtitle2">{brokerage?.status}</Typography>
              </TableCell>
            </TableRow>
            {/* SubTitle */}
            <TableRow className="brokerage-row">
              <TableCell
                component="th"
                scope="row"
                className="brokerage-cell-header"
              >
                <Typography variant="subtitle2" className="header-strong">
                  SubTitle:
                </Typography>
              </TableCell>
              <TableCell className="brokerage-cell">
                <Typography variant="subtitle2">
                  {brokerage?.subTitle}
                </Typography>
              </TableCell>
            </TableRow>
            {/* Real Estate Board */}
            <TableRow className="brokerage-row">
              <TableCell
                component="th"
                scope="row"
                className="brokerage-cell-header"
              >
                <Typography variant="subtitle2" className="header-strong">
                  Short Name:
                </Typography>
              </TableCell>
              <TableCell className="brokerage-cell">
                <Typography variant="subtitle2">
                  {brokerage?.officeShortName}
                </Typography>
              </TableCell>
            </TableRow>
            {/* Real Estate Board */}
            <TableRow className="brokerage-row">
              <TableCell
                component="th"
                scope="row"
                className="brokerage-cell-header"
              >
                <Typography variant="subtitle2" className="header-strong">
                  Real Estate Board:
                </Typography>
              </TableCell>
              <TableCell className="brokerage-cell">
                <Typography variant="subtitle2">
                  {brokerage?.realEstateBoard?.rebName}
                </Typography>
              </TableCell>
            </TableRow>

            {/* Address Information */}
            <TableRow className="brokerage-row">
              <TableCell
                component="th"
                scope="row"
                className="brokerage-cell-header"
              >
                <Typography variant="subtitle2" className="header-strong">
                  Address Information:
                </Typography>
              </TableCell>
              <TableCell className="brokerage-cell">
                <Typography variant="subtitle2">
                  {`${brokerage?.address?.street}, ${brokerage?.address?.city}, 
                ${brokerage?.address?.state}, ${brokerage?.address?.zip}, ${brokerage?.address?.country}`}
                </Typography>
              </TableCell>
            </TableRow>

            {/* Website */}
            <TableRow className="brokerage-row">
              <TableCell
                component="th"
                scope="row"
                className="brokerage-cell-header"
              >
                <Typography variant="subtitle2" className="header-strong">
                  Website:
                </Typography>
              </TableCell>
              <TableCell className="brokerage-cell">
                <Typography variant="subtitle2">
                  {brokerage?.website ? (
                    <Link
                      href={formattedUrl(brokerage.website)} // Ensure the URL is correct
                      target="_blank" // Opens the link in a new tab
                      rel="noopener noreferrer" // Security measure for opening links in a new tab
                      underline="hover" // Only show underline on hover
                      color="primary" // Use the theme's primary color
                      sx={{
                        cursor: "pointer", // Change cursor to pointer
                        "&:hover": {
                          textDecoration: "none", // Optionally remove underline on hover
                          color: "secondary.main", // Change color on hover to secondary color
                        },
                      }}
                    >
                      {brokerage.website}
                    </Link>
                  ) : (
                    "No website available"
                  )}
                </Typography>
              </TableCell>
            </TableRow>

            {/* Billing Email */}
            <TableRow className="brokerage-row">
              <TableCell
                component="th"
                scope="row"
                className="brokerage-cell-header"
              >
                <Typography variant="subtitle2" className="header-strong">
                  Billing Email:
                </Typography>
              </TableCell>
              <TableCell className="brokerage-cell">
                <Typography variant="subtitle2">
                  {brokerage?.billingEmail ? (
                    <Link
                      href={brokerage.billingEmail} // Ensure the URL is correct
                      target="_blank" // Opens the link in a new tab
                      rel="noopener noreferrer" // Security measure for opening links in a new tab
                      underline="hover" // Only show underline on hover
                      color="primary" // Use the theme's primary color
                      sx={{
                        cursor: "pointer", // Change cursor to pointer
                        "&:hover": {
                          textDecoration: "none", // Optionally remove underline on hover
                          color: "secondary.main", // Change color on hover to secondary color
                        },
                      }}
                    >
                      {brokerage.billingEmail}
                    </Link>
                  ) : (
                    "No Billing Email available"
                  )}
                </Typography>
              </TableCell>
            </TableRow>

            {/* Stripe Customer ID */}
            {brokerage?.stripeCustomerId && (
              <>
                <TableRow className="brokerage-row">
                  <TableCell
                    component="th"
                    scope="row"
                    className="brokerage-cell-header"
                  >
                    <Typography variant="subtitle2" className="header-strong">
                      Stripe Customer ID{" "}
                    </Typography>
                  </TableCell>
                  <TableCell className="brokerage-cell">
                    <Typography variant="subtitle2">
                      {brokerage?.stripeCustomerId}
                    </Typography>
                  </TableCell>
                </TableRow>

                {/* Stripe Payment Method */}
                <TableRow className="brokerage-row">
                  <TableCell
                    component="th"
                    scope="row"
                    className="brokerage-cell-header"
                  >
                    <Typography variant="subtitle2" className="header-strong">
                      Stripe Payment Method Id{" "}
                    </Typography>
                  </TableCell>
                  <TableCell className="brokerage-cell">
                    <Typography variant="subtitle2">
                      {brokerage?.stripePaymentMethodId}
                    </Typography>
                  </TableCell>
                </TableRow>
              </>
            )}
            {/* Phone Numbers */}
            <TableRow className="brokerage-row">
              <TableCell
                component="th"
                scope="row"
                className="brokerage-cell-header"
              >
                <Typography variant="subtitle2" className="header-strong">
                  Phone Numbers:
                </Typography>
              </TableCell>
              <TableCell className="brokerage-cell">
                <Typography variant="subtitle2">
                  {brokerage?.phoneNumbers && brokerage.phoneNumbers.length > 0
                    ? brokerage.phoneNumbers.map((number, index) => (
                        <React.Fragment key={index}>
                          <Link
                            href={`tel:${number.replace(/\D/g, "")}`}
                            underline="hover" // Only show underline on hover
                            color="primary" // Use the theme's primary color
                            sx={{
                              cursor: "pointer", // Change cursor to pointer
                              marginRight: 0.5, // Add space between the comma and the next number
                              "&:hover": {
                                textDecoration: "none", // Optionally remove underline on hover
                                color: "secondary.main", // Change color on hover to secondary color
                              },
                            }}
                          >
                            {formatPhoneNumber(number)}
                          </Link>
                          {index < brokerage.phoneNumbers.length - 1
                            ? ", "
                            : ""}
                        </React.Fragment>
                      ))
                    : "No phone number available"}
                </Typography>
              </TableCell>
            </TableRow>

            {/* Nested Brokerages */}
            {brokerage?.isLeadBrokerage &&
              brokerage?.brokerages &&
              brokerage?.brokerages.length > 0 && (
                <>
                  <TableRow className="brokerage-row nested-brokerages">
                    <TableCell colSpan={2}>
                      <Divider />
                      <Typography variant="subtitle2" className="header-strong">
                        Nested Brokerages:
                      </Typography>
                      {brokerage?.brokerages.map((brok, i) => (
                        <Typography variant="subtitle2" sx={{ ml: 2 }} key={i}>
                          {i > 0 && ""}
                          {brok?.officeName}
                        </Typography>
                      ))}
                    </TableCell>
                  </TableRow>
                </>
              )}
          </TableBody>
        </Table>
      </CardContent>
      <BrokerageAssignUserForm
        open={isAssignUserToBrokerageDialogOpen}
        onClose={handleAssignUserToBrokerageDialogClose}
        brokerage={brokerage}
      />
      <AddRemoveAssignUnassignToFromLeadBrokerageDialog
        open={isLeadBrokerageDialogOpen}
        onClose={handleLeadBrokerageDialogClose}
        action={action}
        selectedBrokerageObj={brokerage}
      />
      <BrokerageEditDrawer
        brokerage={brokerage}
        refetchBrokerage={refetchBrokerage}
        open={isBrokerageEditDrawerOpen}
        onClose={handleBrokerageEditDrawerClose}
        mode="edit"
      />
      <ConfirmationDialog
        open={openSyncLeadBrokerageDataConfirmationDialog}
        onClose={handleCloseSyncLeadBrokerageDataDialog}
        onConfirm={handleSyncLeadBrokerageData}
        title="Confirm Synchronizing Lead Brokerage Data"
        description="Are you sure you want to Sync Lead Brokerage Data for this Office?"
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Card>
  );
};

export default memo(BrokerageCard);

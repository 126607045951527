import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const groupRoleApi = createApi({
  reducerPath: "group-role",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/group-roles/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["GroupRole"],
  endpoints: (builder) => ({
    createGroupRole: builder.mutation({
      query: ({ groupId, name, permissions }) => ({
        url: "create-one",
        method: "POST",
        body: { groupId, name, permissions },
      }),
      invalidatesTags: [{ type: "GroupRole", id: "LIST" }],
    }),
    getAllGroupRoles: builder.query({
      query: (groupId) => ({
        url: `get-all/${groupId}`,
        method: "GET",
      }),
      providesTags: [{ type: "GroupRole", id: "LIST" }],
    }),
    editGroupRole: builder.mutation({
      query: ({ groupRoleId, name, permissions }) => ({
        url: `edit-one/${groupRoleId}`,
        method: "POST",
        body: { name, permissions },
      }),
      invalidatesTags: [{ type: "GroupRole", id: "LIST" }],
    }),
    assignGroupRole: builder.mutation({
      query: ({ groupId, memberId, groupRoleId }) => ({
        url: "assign-group-role",
        method: "POST",
        body: { groupId, memberId, groupRoleId },
      }),
      invalidatesTags: [{ type: "GroupRole", id: "LIST" }],
    }),
    deleteGroupRole: builder.mutation({
      query: ({ groupRoleId }) => ({
        url: `delete-one/${groupRoleId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "GroupRole", id: "LIST" }],
    }),
    addPermissionToRole: builder.mutation({
      query: ({ groupRoleId, permission }) => ({
        url: `add-permission-to-role`,
        method: "POST",
        body: { groupRoleId, permission },
      }),
      invalidatesTags: [{ type: "GroupRole", id: "LIST" }],
    }),
    removePermissionFromRole: builder.mutation({
      query: ({ groupRoleId, permission }) => ({
        url: `remove-permission-from-role`,
        method: "DELETE",
        body: { groupRoleId, permission },
      }),
      invalidatesTags: [{ type: "GroupRole", id: "LIST" }],
    }),
  }),
});

export const {
  useGetAllGroupRolesQuery,
  useCreateGroupRoleMutation,
  useEditGroupRoleMutation,
  useAssignGroupRoleMutation,
  useDeleteGroupRoleMutation,
  useAddPermissionToRoleMutation,
  useRemovePermissionFromRoleMutation,
} = groupRoleApi;

export default groupRoleApi;
export { groupRoleApi };

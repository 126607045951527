import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  TextField,
  Typography,
  Grid,
  Snackbar,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useSignupUserMutation } from "../../services/auth";

function Signup() {
  const [signupUser, { isLoading }] = useSignupUserMutation();
  const navigate = useNavigate();

  const [notification, setNotification] = useState({
    message: "",
    severity: "info",
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    accountType: "reosadmin",
    jwtSecret: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    accountType: "",
    jwtSecret: "",
  });

  const isValidEmail = (email) => {
    const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return re.test(String(email).toLowerCase());
  };

  const isValidPassword = (password) => {
    return password.length >= 8;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = true;
    let newErrors = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      accountType: "",
      jwtSecret: "",
    };

    const trimmedEmail = formData?.email.trim();
    if (!isValidEmail(trimmedEmail)) {
      newErrors.email = "Invalid email format";
      isValid = false;
    }

    if (!isValidPassword(formData?.password)) {
      newErrors.password = "Password must be at least 8 characters long";
      isValid = false;
    }

    if (!isValid) {
      setErrors(newErrors);
      return;
    }

    try {
      await signupUser({
        ...formData,
        email: trimmedEmail,
      }).unwrap();
      setNotification({ message: "Signup successful!", severity: "success" });
      setTimeout(() => {
        navigate("/login");
      }, 500);
    } catch (err) {
      console.error("Failed to signup:", err);
      setNotification({ message: err?.data?.msg, severity: "error" });
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Signup as REOS Admin
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              variant="outlined"
              margin="normal"
              value={formData?.firstName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              variant="outlined"
              margin="normal"
              value={formData?.lastName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              type="email"
              label="Email"
              name="email"
              variant="outlined"
              margin="normal"
              error={Boolean(errors?.email)}
              helperText={errors?.email}
              value={formData?.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              type={showPassword ? "text" : "password"}
              label="Password"
              name="password"
              variant="outlined"
              margin="normal"
              error={Boolean(errors?.password)}
              helperText={errors?.password}
              value={formData?.password}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    size="small"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              label="Account Type"
              name="accountType"
              variant="outlined"
              margin="normal"
              value={formData?.accountType}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              type="text"
              label="Secret"
              name="jwtSecret"
              variant="outlined"
              margin="normal"
              error={Boolean(errors?.jwtSecret)}
              helperText={errors?.jwtSecret}
              value={formData?.jwtSecret}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={isLoading}
            >
              Signup
            </Button>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={Boolean(notification.message)}
        autoHideDuration={3000}
        message={notification?.message || "An error occurred"}
        onClose={() => setNotification({ message: "", severity: "info" })}
      />
    </Container>
  );
}

export default Signup;

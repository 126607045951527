import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const categorySettingsApi = createApi({
  reducerPath: "category-settings",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/category-settings/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["CategorySettings"],
  endpoints: (builder) => ({
    getAllCategorySettings: builder.query({
      query: () => ({
        url: "get-all",
        method: "GET",
      }),
      skip: (userId) => !userId,
      providesTags: [{ type: "CategorySettings", id: "LIST" }],
    }),
    createCategorySettings: builder.mutation({
      query: ({
        categoryType,
        isREOSOnly,
        isWidgetable,
        isEvent,
        isMemberList,
        isHomePage,
        isChat,
        isFileSystem,
        isListings,
        allowTags,
        useWYSIWYG,
        hasFeaturePicture,
        allowAcknowledgements,
        allowAttachments,
        allowComments,
        allowLikes,
      }) => ({
        url: "create-one",
        method: "POST",
        body: {
          categoryType,
          isREOSOnly,
          isWidgetable,
          isEvent,
          isMemberList,
          isHomePage,
          isChat,
          isFileSystem,
          isListings,
          allowTags,
          useWYSIWYG,
          hasFeaturePicture,
          allowAcknowledgements,
          allowAttachments,
          allowComments,
          allowLikes,
        },
      }),
      skip: (userId) => !userId,
      invalidatesTags: [{ type: "CategorySettings", id: "LIST" }],
    }),
    updateCategorySettings: builder.mutation({
      query: ({
        categorySettingsId,
        isREOSOnly,
        isWidgetable,
        isEvent,
        isMemberList,
        isHomePage,
        isChat,
        isFileSystem,
        isListings,
        allowTags,
        useWYSIWYG,
        hasFeaturePicture,
        allowAcknowledgements,
        allowAttachments,
        allowComments,
        allowLikes,
      }) => ({
        url: `update-one/${categorySettingsId}`,
        method: "PUT",
        body: {
          isREOSOnly,
          isWidgetable,
          isEvent,
          isMemberList,
          isHomePage,
          isChat,
          isFileSystem,
          isListings,
          allowTags,
          useWYSIWYG,
          hasFeaturePicture,
          allowAcknowledgements,
          allowAttachments,
          allowComments,
          allowLikes,
        },
      }),
      invalidatesTags: [{ type: "CategorySettings", id: "LIST" }],
    }),
    getCategorySettingsById: builder.query({
      query: (categorySettingsId) => ({
        url: `get-one-by-id/${categorySettingsId}`,
        method: "GET",
      }),
      skip: (categorySettingsId) => !categorySettingsId,
      providesTags: (result, error, categorySettingsId) => [
        { type: "CategorySettings", id: categorySettingsId },
      ],
    }),
    deleteCategorySettings: builder.mutation({
      query: ({ categorySettingsId }) => ({
        url: `delete-one/${categorySettingsId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "CategorySettings", id: "LIST" }],
    }),
  }),
});

export const {
  useGetAllCategorySettingsQuery,
  useCreateCategorySettingsMutation,
  useUpdateCategorySettingsMutation,
  useGetCategorySettingsByIdQuery,
  useDeleteCategorySettingsMutation,
} = categorySettingsApi;

export default categorySettingsApi;
export { categorySettingsApi };

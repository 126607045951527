import React, { useState, useEffect, useContext, memo } from "react";
import { AuthContext } from "../../../context/AuthContext";
import {
  useFetchAllUsersByPaginationQuery,
  useArchiveUserMutation,
} from "../../../services/user";
import {
  Container,
  Typography,
  Paper,
  Tabs,
  Tab,
  List,
  ListItem,
  Divider,
  Box,
  Grid,
  TextField,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import InviteBrokerageDialog from "./InviteBrokerageDialog";
import UserList from "./UserList";
import ArchiveUserList from "./ArchiveUserList";
import MembersToManageTierList from "./MembersToManageTierList";
import InviteNewMemberDialog from "./InviteNewMemberDialog";
import InviteMultipleMembersDialog from "../../brokerage/members/InviteMultipleMembersDialog";
import AssignDefaultBrokerageToMemberDialog from "./AssignDefaultBrokerageToMemberDialog";
import { TabPanel } from "../../helpers/utils";

const UserManagement = () => {
  const { accountType } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [allUsers, setAllUsers] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [statusFilter, setStatusFilter] = useState("");
  const [accountTypeFilter, setAccountTypeFilter] = useState("");
  const [lastOnlineFilter, setLastOnlineFilter] = useState("");
  const [brokerageFilter, setBrokerageFilter] = useState("");
  const [textFilter, setTextFilter] = useState("");

  const { data, refetch } = useFetchAllUsersByPaginationQuery(
    {
      accountType,
      page,
      limit,
      statusFilter,
      accountTypeFilter,
      lastOnlineFilter,
      brokerageFilter,
      textFilter,
    },
    {
      skip: accountType !== "reosadmin",
    }
  );

  useEffect(() => {
    if (data?.users && page === 1) {
      setAllUsers(data.users);
    } else if (data?.users && page > 1) {
      setAllUsers((prev) => [...prev, ...data.users]);
    }
    setHasMore(data?.users?.length > 0);
  }, [data, textFilter]);

  const [archiveUser] = useArchiveUserMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [openInviteDialog, setOpenInviteDialog] = useState(false);
  const [openInviteMemberDialog, setOpenInviteMemberDialog] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTextFilterChange = (event) => {
    setTextFilter(event.target.value.toLowerCase());
  };

  const [
    openAssignBrokerageToMemberDialog,
    setOpenAssignBrokerageToMemberDialog,
  ] = useState(false);
  const [openInviteMultipleMembersDialog, setOpenInviteMultipleMembersDialog] =
    useState(false);

  const handleOpenInviteDialog = () => {
    setOpenInviteDialog(true);
  };

  const handleCloseInviteDialog = () => {
    setOpenInviteDialog(false);
  };

  const handleOpenInviteMemberDialog = () => {
    setOpenInviteMemberDialog(true);
  };

  const handleCloseInviteMemberDialog = () => {
    setOpenInviteMemberDialog(false);
  };

  const handleOpenInviteMultipleMembersDialog = () => {
    setOpenInviteMultipleMembersDialog(true);
  };

  const handleCloseInviteMultipleMembersDialog = () => {
    setOpenInviteMultipleMembersDialog(false);
  };

  const handleOpenAssignBrokerageToMemberDialog = () => {
    setOpenAssignBrokerageToMemberDialog(true);
  };

  const handleCloseAssignBrokerageToMemberDialog = () => {
    setOpenAssignBrokerageToMemberDialog(false);
  };

  const handleArchiveUser = async (id) => {
    const result = await archiveUser(id);
    if (result.error) {
      setNotification({
        open: true,
        message: `Error archiving user. ${result?.error?.data?.msg}`,
      });
    } else {
      setNotification({ open: true, message: "User archived successfully." });
      refetch();
    }
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
    setPage(1);
  };

  const handleAccountTypeFilterChange = (event) => {
    setAccountTypeFilter(event.target.value);
    setPage(1);
  };

  const handleLastOnlineFilterChange = (event) => {
    setLastOnlineFilter(event.target.value);
    setPage(1);
  };

  const handleBrokerageFilterChange = (event) => {
    setBrokerageFilter(event.target.value);
    setPage(1);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (accountType !== "reosadmin") {
    return (
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        You are not allowed for this page
      </Typography>
    );
  }

  return (
    <Container
      className="management-page"
      style={{ margin: "auto", textAlign: "left", width: "100%" }}
    >
      <Paper
        sx={{
          p: 3,
          mb: 5,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          User Management
        </Typography>

        <Grid container>
          <Grid item xs={6} sx={{ p: 2 }}>
            <div>
              <Typography variant="h5" mb={1}>
                Assignments:
              </Typography>
            </div>
            <Box sx={{ display: "flex", flexDirection: "column", p: 0.5 }}>
              <List style={{ width: "100%", marginRight: "0.1rem" }}>
                <ListItem
                  onClick={handleOpenAssignBrokerageToMemberDialog}
                  className="user-management-item"
                >
                  Assign Brokerage to a Member
                </ListItem>
                <Divider />
              </List>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ p: 2 }}>
            <div>
              <Typography variant="h5" mb={1}>
                Invitations:
              </Typography>
            </div>
            <Box sx={{ display: "flex", flexDirection: "column", p: 0.5 }}>
              <List style={{ width: "100%", marginRight: "0.1rem" }}>
                <ListItem
                  onClick={handleOpenInviteDialog}
                  className="user-management-item"
                >
                  {`Invite Brokerage (Office)`}
                </ListItem>
                <Divider />
                <ListItem
                  onClick={handleOpenInviteMemberDialog}
                  className="user-management-item"
                >
                  Invite Member
                </ListItem>
                <Divider />
                <ListItem
                  onClick={handleOpenInviteMultipleMembersDialog}
                  className="user-management-item"
                >
                  Add Members
                </ListItem>
                <Divider />
              </List>
            </Box>
          </Grid>
        </Grid>
        <AssignDefaultBrokerageToMemberDialog
          open={openAssignBrokerageToMemberDialog}
          onClose={handleCloseAssignBrokerageToMemberDialog}
        />
        <InviteBrokerageDialog
          open={openInviteDialog}
          onClose={handleCloseInviteDialog}
        />
        <InviteNewMemberDialog
          open={openInviteMemberDialog}
          onClose={handleCloseInviteMemberDialog}
        />
        <InviteMultipleMembersDialog
          open={openInviteMultipleMembersDialog}
          onClose={handleCloseInviteMultipleMembersDialog}
        />
        <NotificationSnackbar
          open={notification.open}
          message={notification.message}
          onClose={handleClose}
        />
      </Paper>
      <TextField
        label="Filter Users"
        variant="outlined"
        value={textFilter}
        onChange={handleTextFilterChange}
        fullWidth
        sx={{ mb: 2 }}
      />
      <Paper>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="roles tabs"
          variant="scrollable"
          scrollButtons="auto"
          className="tabs-paper"
        >
          <Tab
            label="User List"
            id="simple-tab-0"
            aria-controls="simple-tabpanel-0"
          />
          <Tab
            label="Manage Multiple Users"
            id="simple-tab-1"
            aria-controls="simple-tabpanel-1"
          />
          <Tab
            label="Archive User List"
            id="simple-tab-2"
            aria-controls="simple-tabpanel-2"
          />
        </Tabs>
        <TabPanel value={tabValue} index={0} className="tabs-paper userlist">
          <UserList
            users={allUsers || []}
            onArchiveUser={handleArchiveUser}
            fetchNextPage={() => setPage((prevPage) => prevPage + 1)}
            hasMore={hasMore}
            statusFilter={statusFilter}
            accountTypeFilter={accountTypeFilter}
            lastOnlineFilter={lastOnlineFilter}
            brokerageFilter={brokerageFilter}
            handleStatusFilterChange={handleStatusFilterChange}
            handleAccountTypeFilterChange={handleAccountTypeFilterChange}
            handleLastOnlineFilterChange={handleLastOnlineFilterChange}
            handleBrokerageFilterChange={handleBrokerageFilterChange}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1} className="tabs-paper">
          <MembersToManageTierList />
        </TabPanel>
        <TabPanel value={tabValue} index={2} className="tabs-paper">
          <ArchiveUserList />
        </TabPanel>
      </Paper>
    </Container>
  );
};

export default memo(UserManagement);

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const categoryTypesApi = createApi({
  reducerPath: "category-type",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/category-types/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["CategoryType"],
  endpoints: (builder) => ({
    createCategoryType: builder.mutation({
      query: ({
        tierId,
        categoryTypeName,
        categoryTypePath,
        categoryTypeIcon,
        categoryTypePermissions,
      }) => ({
        url: `create-category-type/`,
        method: "POST",
        body: {
          tierId,
          categoryTypeName,
          categoryTypePath,
          categoryTypeIcon,
          categoryTypePermissions,
        },
      }),
      invalidatesTags: [{ type: "CategoryType", id: "LIST" }],
    }),
    updateCategoryType: builder.mutation({
      query: ({
        categoryTypeId,
        categoryTypeName,
        categoryTypePath,
        categoryTypeIcon,
        categoryTypePermissions,
      }) => ({
        url: `update-category-type/${categoryTypeId}`,
        method: "PUT",
        body: {
          categoryTypeName,
          categoryTypePath,
          categoryTypeIcon,
          categoryTypePermissions,
        },
      }),
      invalidatesTags: [{ type: "CategoryType", id: "LIST" }],
    }),
    getAllCategoryTypes: builder.query({
      query: () => ({
        url: `all-category-types/`,
        method: "GET",
      }),
      skip: (brokerageId) => !brokerageId,
      providesTags: [{ type: "CategoryType", id: "LIST" }],
    }),
    getCategoryTypeById: builder.query({
      query: (categoryTypeId) => ({
        url: `get-category-type-by-id/${categoryTypeId}/`,
        method: "GET",
      }),
      skip: (categoryTypeId) => !categoryTypeId,
      providesTags: [{ type: "CategoryType", id: "LIST" }],
    }),
    getAllCategoryTypesByBrokerage: builder.query({
      query: (brokerageId) => ({
        url: `all-category-types-by-brokerage/${brokerageId}`,
        method: "GET",
      }),
      skip: (brokerageId) => !brokerageId,
      providesTags: [{ type: "CategoryType", id: "LIST" }],
    }),
    getAllCategoryTypesByTier: builder.query({
      query: (tierId) => ({
        url: `all-category-types-by-tier/${tierId}`,
        method: "GET",
      }),
      skip: (tier) => !tier,
      providesTags: [{ type: "CategoryType", id: "LIST" }],
    }),
    deleteCategoryType: builder.mutation({
      query: ({ userId, categoryTypeId }) => ({
        url: `delete-category-type/${userId}/${categoryTypeId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "CategoryType", id: "LIST" }],
    }),
  }),
});

export const {
  useCreateCategoryTypeMutation,
  useGetAllCategoryTypesQuery,
  useGetCategoryTypeByIdQuery,
  useGetAllCategoryTypesByBrokerageQuery,
  useGetAllCategoryTypesByTierQuery,
  useUpdateCategoryTypeMutation,
  useDeleteCategoryTypeMutation,
} = categoryTypesApi;

export default categoryTypesApi;
export { categoryTypesApi };

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const transactionApi = createApi({
  reducerPath: "transaction",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/billing/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Transaction"],
  endpoints: (builder) => ({
    // Transaction Endpoints
    fetchTransactions: builder.query({
      query: () => "transactions",
      providesTags: ["Transaction"],
    }),
    fetchTransactionById: builder.query({
      query: (id) => `transactions/${id}`,
      providesTags: ["Transaction"],
    }),
    createTransaction: builder.mutation({
      query: (transactionData) => ({
        url: "transactions",
        method: "POST",
        body: transactionData,
      }),
      invalidatesTags: ["Transaction"],
    }),
    updateTransaction: builder.mutation({
      query: ({ _id, ...transactionData }) => ({
        url: `transactions/${_id}`,
        method: "PUT",
        body: transactionData,
      }),
      invalidatesTags: (result, error, { id }) => ["Transaction"],
    }),
    deleteTransaction: builder.mutation({
      query: (id) => ({
        url: `transactions/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Transaction"],
    }),
  }),
});

export const {
  // Transaction Hooks
  useFetchTransactionsQuery,
  useFetchTransactionByIdQuery,
  useCreateTransactionMutation,
  useUpdateTransactionMutation,
  useDeleteTransactionMutation,
} = transactionApi;

export default transactionApi;

import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { formatMoney } from "../../../helpers/utils";

const CollectionTable = ({
  collections,
  order,
  orderBy,
  page,
  rowsPerPage,
  handleRequestSort,
  handleEditClick,
  handleDeleteClick,
}) => {
  const [hoveredCollectionId, setHoveredCollectionId] = useState(null);
  const rowColour = "#ffffff"; // Alternate row color
  const rowColourAlt = "#f6f6f6"; // Alternate row color
  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    handleRequestSort(property, isAscending ? "desc" : "asc");
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === "productCollectionCode"}
                direction={orderBy === "productCollectionCode" ? order : "asc"}
                onClick={() => handleSort("productCollectionCode")}
              >
                Code
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "name"}
                direction={orderBy === "name" ? order : "asc"}
                onClick={() => handleSort("name")}
              >
                Name
              </TableSortLabel>
            </TableCell>

            <TableCell>Billing Frequency</TableCell>
            <TableCell>Valid From/To</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Products</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {collections
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((collection, index) => (
              <React.Fragment key={collection._id}>
                <TableRow
                  sx={{
                    backgroundColor: index % 2 === 0 ? rowColour : rowColourAlt, // Alternates row colors
                  }}
                >
                  <TableCell>{collection.productCollectionCode}</TableCell>
                  <TableCell>{collection.name}</TableCell>
                  <TableCell>{collection.frequency}</TableCell>
                  <TableCell>
                    {collection.validFrom
                      ? new Date(collection.validFrom).toLocaleDateString()
                      : ""}
                    <br />
                    {collection.validTo
                      ? new Date(collection.validTo).toLocaleDateString()
                      : ""}
                  </TableCell>
                  <TableCell>{collection.status}</TableCell>
                  <TableCell>
                    <ul style={{ padding: "0px 0px 0px 15px" }}>
                      {collection.products && collection.products.length > 0
                        ? collection.products.map((product, ind) => (
                            <li key={ind}>
                              <div key={product._id}>
                                ${formatMoney(product.price)} - {product.name}
                              </div>
                            </li>
                          ))
                        : "No Products"}
                    </ul>
                  </TableCell>
                  <TableCell rowSpan={"2"}>
                    <IconButton
                      onClick={() => handleEditClick(collection, "collection")}
                    >
                      <Edit />
                    </IconButton>
                    <Tooltip title="Delete">
                      <IconButton
                        color="error"
                        onClick={() =>
                          handleDeleteClick(collection, "collection")
                        }
                        onMouseEnter={() =>
                          setHoveredCollectionId(collection._id)
                        }
                        onMouseLeave={() => setHoveredCollectionId(null)}
                        sx={{
                          color:
                            hoveredCollectionId === collection._id
                              ? "red"
                              : "inherit",
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <TableRow
                  key={`${collection._id}-description`}
                  sx={{
                    backgroundColor: index % 2 === 0 ? rowColour : rowColourAlt, // Alternates row colors
                  }}
                >
                  <TableCell colSpan={"6"}>
                    <b>Product Collection Description: </b>
                    {collection.description}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CollectionTable;

import React, { useState } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { daysOfWeek } from "../../../helpers/utils";
import TimeSlotSelector from "../../../account-profiles/TimeSlotSelector";
import NotificationSnackbar from "../../../helpers/notification-snackbar";

function MembersDefaultDutyPreferencesDialog({
  open,
  onClose,
  tierId,
  setupMembersDefaultDutyPreferences,
  isLoading,
  dutyScheduleOfficeLocation,
}) {
  const [availability, setAvailability] = useState({
    days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    timeSlots: [{ startTime: "09:00", endTime: "17:00" }],
  });
  const [officeLocations, setOfficeLocations] = useState([
    dutyScheduleOfficeLocation,
  ]);
  const [willingToDoDuty, setWillingToDoDuty] = useState(true);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleDaysChange = (event) => {
    const { value } = event.target;
    setAvailability((prev) => ({
      ...prev,
      days: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleAddTimeSlot = (slot) => {
    setAvailability((prev) => ({
      ...prev,
      timeSlots: [...prev.timeSlots, slot],
    }));
  };

  const handleRemoveTimeSlot = (index) => {
    setAvailability((prev) => ({
      ...prev,
      timeSlots: prev.timeSlots.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async () => {
    if (
      !tierId ||
      !availability.days ||
      !availability.timeSlots ||
      !officeLocations ||
      !willingToDoDuty
    ) {
      setNotification({
        open: true,
        message: "Please fill in all required fields.",
      });
      return;
    }
    const result = await setupMembersDefaultDutyPreferences({
      tierId,
      body: { availability, willingToDoDuty, officeLocations },
    });

    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        setAvailability({ days: [], timeSlots: [] });
        setOfficeLocations([]);
        setWillingToDoDuty(false);
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error setting members default duty preferences: ${
          result?.error?.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Members Default Duty Preferences</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item xs={12}>
            <Typography component="div">Adjust Available Days</Typography>
            <FormControl fullWidth>
              <InputLabel>Available Days</InputLabel>
              <Select
                multiple
                label="Available Days"
                value={availability.days}
                onChange={handleDaysChange}
                renderValue={(selected) => selected.join(", ")}
              >
                {daysOfWeek?.map((day) => (
                  <MenuItem key={day} value={day}>
                    <Checkbox checked={availability.days.includes(day)} />
                    <ListItemText primary={day} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography component="div">Choose Available Time Slots</Typography>
            <TimeSlotSelector
              onAddTimeSlot={handleAddTimeSlot}
              onRemoveTimeSlot={handleRemoveTimeSlot}
              timeSlots={availability.timeSlots}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={
            isLoading ||
            !tierId ||
            !availability.timeSlots ||
            !availability.days ||
            !officeLocations
          }
        >
          {isLoading ? <CircularProgress size={24} /> : "Update Preferences"}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
}

export default MembersDefaultDutyPreferencesDialog;

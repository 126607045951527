import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { isTokenExpired } from "../custom-hooks/helpUtils";

const RedirectBasedOnRole = () => {
  const { token, user } = useContext(AuthContext);
  const isAuthenticated = token && !isTokenExpired(token);

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  switch (user?.accountType) {
    case "reosadmin":
      return <Navigate to="/user-management" replace />;
    case "brokerage":
      return <Navigate to="/brokerage-ecosystem-management" replace />;
    default:
      return <Navigate to="/home" replace />;
  }
};

export default RedirectBasedOnRole;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const categoryChatApi = createApi({
  reducerPath: "category-chat",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/category-chats/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["CategoryChat"],
  endpoints: (builder) => ({
    getCategoryChatsForTier: builder.query({
      query: (tierId) => ({
        url: `/get-category-chat-by-tier/${tierId}`,
        method: "GET",
      }),
      skip: (tierId) => !tierId,
      providesTags: ["CategoryChat"],
    }),
    getCategoryChatByCategory: builder.query({
      query: (categoryId) => ({
        url: `/get-category-chat-by-category/${categoryId}`,
        method: "GET",
      }),
      skip: (categoryId) => !categoryId,
      providesTags: ["CategoryChat"],
    }),
    getMessagesByChatId: builder.query({
      query: ({ chatId, page = 1, limit = 10 }) => {
        const queryParams = `page=${page}&limit=${limit}`;
        return { url: `/messages/${chatId}?${queryParams}`, method: "GET" };
      },
      providesTags: (result) =>
        result ? [{ type: "CategoryChat", id: "LIST" }] : ["CategoryChat"],
    }),
    postMessage: builder.mutation({
      query: ({ userId, senderId, chatId, content, tierId }) => ({
        url: `post-message/${chatId}/${userId}/${tierId}`,
        method: "POST",
        body: { senderId, content },
      }),
      invalidatesTags: ["CategoryChat"],
    }),
    addNewCategoryChatMessage: builder.mutation({
      queryFn: (message) => {
        return { data: message };
      },
    }),
    markAsReadMessages: builder.mutation({
      query: ({ chatId, userId }) => ({
        url: `mark-as-read-messages/${chatId}/${userId}`,
        method: "PUT",
      }),
      invalidatesTags: ["CategoryChat"],
    }),
    deleteMessage: builder.mutation({
      query: ({ messageId }) => ({
        url: `delete-message/${messageId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CategoryChat"],
    }),
  }),
});

export const {
  useGetCategoryChatsForTierQuery,
  useGetCategoryChatByCategoryQuery,
  useGetMessagesByChatIdQuery,
  useMarkAsReadMessagesMutation,
  usePostMessageMutation,
  useAddNewCategoryChatMessageMutation,
  useDeleteMessageMutation,
} = categoryChatApi;

export default categoryChatApi;
export { categoryChatApi };

import React, { useEffect, useState, memo, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlooorDutySchedulingManager from "./duty-scheduling-handling/FlooorDutySchedulingManager";
import MembersFloorDutyPreferencesManager from "./members-duty-preferences-and-handliing/MembersFloorDutyPreferencesManager";
import { Paper, Tabs, Tab, Typography } from "@mui/material";
import { TabPanel } from "../../helpers/utils";

const FlooorDutyTabs = ({ tier }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const initialTab = parseInt(queryParams.get("fdtab")) || 0;
  const [fdtabValue, setFdtabValue] = useState(initialTab);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set("fdtab", fdtabValue);
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [fdtabValue, navigate, location.pathname, location.search]);

  const handleTabChange = (event, newValue) => {
    setFdtabValue(newValue);
  };

  return (
    <Paper
      sx={{
        p: 1,
        mb: 3,
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Typography variant="h5" fontWeight={700}>
        Flooor Duty Management
      </Typography>
      <Tabs
        value={fdtabValue}
        onChange={handleTabChange}
        aria-label="flooor duty tabs"
        variant="scrollable"
        scrollButtons="auto"
        className="tabs-paper"
      >
        <Tab
          label="Duty Scheduling Manager"
          id="fd-tab-0"
          aria-controls="fd-tabpanel-0"
        />
        <Tab
          label="Members Duty Preferences Manager"
          id="fd-tab-1"
          aria-controls="fd-tabpanel-1"
        />
      </Tabs>
      <TabPanel value={fdtabValue} index={0} className="tabs-paper">
        <FlooorDutySchedulingManager tier={tier} />
      </TabPanel>
      <TabPanel value={fdtabValue} index={1} className="tabs-paper">
        <MembersFloorDutyPreferencesManager tier={tier} />
      </TabPanel>
    </Paper>
  );
};

export default memo(FlooorDutyTabs);

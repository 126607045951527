import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const subscriptionApi = createApi({
  reducerPath: "subscription",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/billing/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Subscription"],
  endpoints: (builder) => ({
    // Subscription Endpoints
    fetchSubscriptions: builder.query({
      query: () => "subscriptions",
      providesTags: ["Subscription"],
    }),
    fetchSubscriptionById: builder.query({
      query: (id) => `subscriptions/${id}`,
      providesTags: ["Subscription"],
    }),
    createSubscription: builder.mutation({
      query: (subscriptionData) => ({
        url: "subscriptions",
        method: "POST",
        body: subscriptionData,
      }),
      invalidatesTags: ["Subscription"],
    }),
    updateSubscription: builder.mutation({
      query: ({ _id, ...subscriptionData }) => ({
        url: `subscriptions/${_id}`,
        method: "PUT",
        body: subscriptionData,
      }),
      invalidatesTags: ["Subscription"],
    }),
    deleteSubscription: builder.mutation({
      query: (_id) => ({
        url: `subscriptions/${_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Subscription"],
    }),
  }),
});
export const {
  // Subscription Hooks
  useFetchSubscriptionsQuery,
  useFetchSubscriptionByIdQuery,
  useCreateSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useDeleteSubscriptionMutation,
} = subscriptionApi;

export default subscriptionApi;

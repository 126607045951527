import React, { useState, useContext } from "react";
import {
  Grid,
  Typography,
  CircularProgress,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import BillingSetup from "../../brokerage/billing/BillingSetup";
import Invoices from "../../reosadmin/billing/invoice/Invoices"; // Import your Invoices component
import BilingList from "../../brokerage/billing/BillingList"; // Import your BillingList component
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51PkRsc1zSF6d4NVL7a1UOTPfEvy8wIDGsMXKxl1sEUhvEbPZJk1leMbhK5nB2BxsaN2i0L3ouObsClBrMOLfdrgI00bGYbmPJS"
);

const MemberBillingTab = ({ member, sidebarOpen }) => {
  const [selectedTab, setSelectedTab] = useState(0); // State to manage selected tab
  const memberId = member?.user || null;

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="member-billing-tab">
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="billing tabs"
            sx={{ mb: 2 }}
          >
            <Tab label="Billing Settings" />
            <Tab label="Invoices" />
          </Tabs>
          <Box sx={{ p: 3 }}>
            {selectedTab === 0 && (
              <Grid container className="member-billing-tab-body">
                <BillingSetup accountId={memberId} sidebarOpen={sidebarOpen} />
              </Grid>
            )}
            {selectedTab === 1 && (
              <Grid container className="member-billing-tab-body">
                <BilingList accountId={memberId} />
              </Grid>
            )}
          </Box>
        </Box>
      </div>
    </Elements>
  );
};

export default MemberBillingTab;

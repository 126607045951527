import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const messagesApi = createApi({
  reducerPath: "messages",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/messages/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Messages"],
  endpoints: (builder) => ({
    getAllChats: builder.query({
      query: (userId) => ({ url: `/get-all-chats/${userId}`, method: "GET" }),
      skip: (userId) => !userId,
      providesTags: (result) =>
        result ? [{ type: "Messages", id: "LIST" }] : ["Messages"],
    }),
    getAllChatUsers: builder.query({
      query: (userId) => ({ url: `/all-chat-users/${userId}`, method: "GET" }),
      providesTags: (result) =>
        result ? [{ type: "Messages", id: "LIST" }] : ["Messages"],
    }),
    getUserDetails: builder.query({
      query: (userId) => `get-user-details/${userId}`,
      providesTags: (result) =>
        result ? [{ type: "Messages", id: "LIST" }] : ["Messages"],
    }),
    getMessagesByChatId: builder.query({
      query: ({ chatId, page = 1, limit = 10 }) => {
        const queryParams = `page=${page}&limit=${limit}`;
        return { url: `/messages/${chatId}?${queryParams}`, method: "GET" };
      },
      providesTags: (result) =>
        result ? [{ type: "Messages", id: "LIST" }] : ["Messages"],
    }),
    createChat: builder.mutation({
      query: ({ senderId, receiverId, content }) => ({
        url: `create-chat`,
        method: "POST",
        body: { senderId, receiverId, content },
      }),
      invalidatesTags: ["Messages"],
    }),
    postMessage: builder.mutation({
      query: ({ userId, senderId, chatId, content }) => ({
        url: `post-message/${userId}`,
        method: "POST",
        body: { senderId, chatId, content },
      }),
      invalidatesTags: ["Messages"],
    }),
    addNewMessage: builder.mutation({
      queryFn: (message) => {
        return { data: message };
      },
    }),
    markAsReadMessages: builder.mutation({
      query: ({ chatId, userId }) => ({
        url: `mark-as-read-messages/${chatId}/${userId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Messages"],
    }),
    deleteChat: builder.mutation({
      query: ({ userId, chatId }) => ({
        url: `delete-chat/${chatId}/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Messages"],
    }),
    deleteMessage: builder.mutation({
      query: ({ messageId }) => ({
        url: `delete-message/${messageId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Messages"],
    }),
  }),
});

export const {
  useGetAllChatsQuery,
  useGetAllChatUsersQuery,
  useGetUserDetailsQuery,
  useGetMessagesByChatIdQuery,
  useMarkAsReadMessagesMutation,
  useCreateChatMutation,
  usePostMessageMutation,
  useDeleteChatMutation,
  useDeleteMessageMutation,
  useAddNewMessageMutation,
} = messagesApi;

export default messagesApi;
export { messagesApi };

import React, { useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import {
  useGetAllTierPermissionsQuery,
  useRemoveTierPermissionMutation,
} from "../../../services/tier-permission";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import { DeleteForever } from "@mui/icons-material/";
import { ConfirmationDialog } from "../..";
import NotificationSnackbar from "../../helpers/notification-snackbar";

function TierPermissionsList() {
  const { accountType, userId } = useContext(AuthContext);
  const { data: tierPermissions } = useGetAllTierPermissionsQuery(accountType, {
    skip: !userId && accountType !== "reosadmin",
  });
  const [deleteTier] = useRemoveTierPermissionMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [currentTier, setCurrentTier] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const handleDeleteTierClick = (event, tierId) => {
    event.stopPropagation();
    setCurrentTier({ ...currentTier, _id: tierId });
    console.log(currentTier);
    setConfirmationDialogOpen(true);
  };
  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleConfirmationDialogConfirm = async () => {
    if (currentTier?._id) {
      const result = await deleteTier({
        tierPermId: currentTier._id,
      });

      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        setConfirmationDialogOpen(false);
      } else {
        setNotification({
          open: true,
          message: `Error deleting Tier. ${result?.error?.data?.msg}`,
        });
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (!tierPermissions || tierPermissions?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 20,
        }}
      >
        <Typography variant="h5" gutterBottom>
          No Tier Permissions available
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User Role & Name</TableCell>
                <TableCell>Tier Permission</TableCell>
                <TableCell>Permissions</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(tierPermissions) &&
                tierPermissions?.map((tierPerm) => (
                  <TableRow
                    key={tierPerm?._id}
                    style={{ cursor: "pointer" }}
                    className="table-row"
                  >
                    <TableCell>
                      {`${tierPerm?.role?.user?.firstName} ${tierPerm?.role?.user?.lastName} - ${tierPerm?.role?.name}`}
                    </TableCell>
                    <TableCell>
                      {`${tierPerm?.tier?.level} ${
                        tierPerm?.tier?.brokerage ||
                        tierPerm?.tier?.team ||
                        tierPerm?.tier?.group
                          ? tierPerm?.tier?.brokerage?.officeName ||
                            tierPerm?.tier?.team?.name ||
                            tierPerm?.tier?.group?.name
                          : ""
                      } ${
                        tierPerm?.tier?.level === "system" ||
                        tierPerm?.tier?.level === "global"
                          ? " - REACirc"
                          : ""
                      } ${
                        tierPerm?.tier?._id ===
                        tierPerm?.tier?.brokerage?.leadBrokerageTier
                          ? " - (Lead Brokerage Office)"
                          : ""
                      } ${
                        tierPerm?.tier?._id === tierPerm?.tier?.brokerage?.tier
                          ? " - (Office)"
                          : ""
                      }`}
                    </TableCell>
                    <TableCell>
                      {tierPerm?.permissions &&
                        tierPerm?.permissions?.map((tpi) => (
                          <span
                            key={`perm-${tpi?._id}`}
                            className="tier-perm-row"
                          >
                            <small>{` ${tpi?.perm};`}</small>
                          </span>
                        ))}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          aria-label="delete"
                          onClick={(event) =>
                            handleDeleteTierClick(event, tierPerm?._id)
                          }
                        >
                          <DeleteForever />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider style={{ margin: "20px 0" }} />
      </div>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmationDialogConfirm}
        title="Confirm Deleting the Tier Permission"
        description="Are you sure you want to delete this Tier Permission permanently?"
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
}

export default TierPermissionsList;

import React from "react";
import { SvgIcon } from "@mui/material";

export function NinjaIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        // fill="#ba8748"
        d="m13.58,3.95c.3-.15-.3-2.24-1.5-2.24-.6,0-1.2.15-1.64.45s-.9.6-1.35.9c-.45.3-1.35.75-1.94.75.15-.45.75-1.05.6-1.64,0-.75-.9-1.79-1.64-2.09-.15,0-.6-.15-1.05,0-.6.3,0,1.05.15,1.5.15.45-.15,1.05-.6,1.35-2.24,2.24-2.09,3.59-1.94,3.89.3.3,5.83-.9,10.92-2.84Zm-8.22,5.23c-1.05.15-1.05.75-1.05,1.2,2.54-.3,2.99-1.5,5.23-2.09.9-.3,2.69-.6,2.09-1.35-1.5-1.94-4.64,1.2-7.18,1.05,0,.75.9.45,1.05,1.05l-.15.15Zm14.21,14.5c-.3-4.64-.9-7.18-2.54-8.67v.3c.9,6.43-.75,5.83-.9,5.83-4.04-.15-3.74-7.92-1.35-10.17-.15-1.35-1.5-2.54-2.09-2.24-3.29,1.5-7.78,3.44-11.36,3.89.45,3.59,4.19,1.79,6.13.3-.15.75.3.6.3,1.05-.45.9-.9,1.79-1.79,2.39-.9-.15-2.69-.6-2.84-.3-.3,1.2,1.05,1.35,1.64,2.09-.9.9-1.35,2.09-2.69,2.69-.15-.6-.15-1.35-.6-1.64-1.05,1.79-1.94,3.59-1.2,4.04,1.79,1.05,3.89-1.5,5.83-3.74,1.35.45,1.35,2.99,2.99,2.39,1.5-1.05-.15-3.89-1.35-4.64.6-.75,1.79-1.5,2.24-2.24.3-1.2-.6-1.94-1.5-2.54.9-.6,2.39-1.05,3.14-1.2-1.35,6.43.6,12.71,5.23,13.61.45.15.9.15,1.35,0,.15,0,.3,0,.45-.15.45-.15.9-.45.9-1.05Z"
      />
      <path
        // fill="#ba8748"
        d="m21.36,23.69c0,.3-.15.6-.45.6s-.6-.3-.6-.6.3-.6.6-.6.45.3.45.6Zm-.9,0c0,.3.15.45.45.45.15,0,.45-.15.45-.45s-.3-.45-.45-.45c-.3,0-.45.15-.45.45Zm.6-.15l-.15-.15c-.15,0-.3.15-.3.3s.15.3.3.3h.15-.15c-.15,0-.3-.15-.3-.3s.15-.3.3-.3h.15v.15Z"
      />
    </SvgIcon>
  );
}

import React, { useContext, useState } from "react";
import {
  useGetAllDutiesQuery,
  useDeleteAllDutyByTierMutation,
  useDeleteAllDutiesMutation,
} from "../../../services/duties";
import { useGetAllTiersQuery } from "../../../services/tier";
import { AuthContext } from "../../../context/AuthContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Divider,
  Box,
  Autocomplete,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";

function DutiesList() {
  const { accountType, userId } = useContext(AuthContext);
  const { data: tiers } = useGetAllTiersQuery(accountType, {
    skip: !userId && accountType !== "reosadmin",
  });
  const { data: duties } = useGetAllDutiesQuery();
  const [selectedTier, setSelectedTier] = useState(null);
  const [deleteAllDutyByTier, { isLoading: isDeleteAllDutyByTierLoading }] =
    useDeleteAllDutyByTierMutation();
  const [deleteAllDuties, { isLoading: isDeleteAllDutiesLoading }] =
    useDeleteAllDutiesMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleDeleteAllDutyByTier = async () => {
    if (selectedTier) {
      const result = await deleteAllDutyByTier({ tierId: selectedTier?._id });
      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
      } else {
        setNotification({
          open: true,
          message: `Error deleting duties. ${result?.error?.data?.msg}`,
        });
      }
    }
  };

  const handleDeleteAllDuties = async () => {
    const result = await deleteAllDuties();
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `Error deleting duties. ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <div className="management-page-body">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteAllDutyByTier}
            disabled={!selectedTier || isDeleteAllDutyByTierLoading}
          >
            {isDeleteAllDutyByTierLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Delete All Duties by Tier"
            )}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteAllDuties}
            disabled={
              !duties || duties?.length === 0 || isDeleteAllDutiesLoading
            }
          >
            {isDeleteAllDutiesLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Delete All Duties"
            )}
          </Button>
        </Box>
      </Paper>
      <Box>
        <Autocomplete
          value={selectedTier}
          onChange={(event, newValue) => {
            setSelectedTier(newValue);
          }}
          options={tiers || []}
          getOptionLabel={(option) =>
            option
              ? `${option?.level} ${
                  option?.brokerage || option?.team || option?.group
                    ? option?.brokerage?.officeName ||
                      option?.team?.name ||
                      option?.group?.name
                    : ""
                } ${
                  option?.level === "system" || option?.level === "global"
                    ? " - REACirc"
                    : ""
                } ${
                  option?._id === option?.brokerage?.leadBrokerageTier
                    ? " - (Lead Brokerage Office)"
                    : ""
                } ${
                  option?._id === option?.brokerage?.tier ? " - (Office)" : ""
                }`
              : ""
          }
          isOptionEqualToValue={(option, value) => option?._id === value._id}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a Tier"
              variant="outlined"
              margin="normal"
              required
            />
          )}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Start</TableCell>
              <TableCell>End</TableCell>
              <TableCell>Member</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {duties &&
              duties.length > 0 &&
              duties?.map((duty, index) => (
                <TableRow key={`${duty._id}-${index}`}>
                  <TableCell>{duty?.title}</TableCell>
                  <TableCell>{new Date(duty.start).toLocaleString()}</TableCell>
                  <TableCell>{new Date(duty.end).toLocaleString()}</TableCell>
                  <TableCell>
                    {duty?.member?.user?.firstName}{" "}
                    {duty?.member?.user?.lastName}{" "}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {(!duties || duties?.length === 0) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            pt: 20,
          }}
        >
          <Typography variant="h5" gutterBottom>
            No Duties available
          </Typography>
        </Box>
      )}
      <Divider style={{ margin: "20px 0" }} />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </div>
  );
}

export default DutiesList;

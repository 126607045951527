import { useEffect } from "react";

function useInfiniteScrollToBottom(scrollRef, callback, hasMore) {
  useEffect(() => {
    const handleScroll = () => {
      if (!scrollRef.current) return;
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollHeight - scrollTop - clientHeight < 50 && hasMore) {
        callback();
      }
    };
    const element = scrollRef.current;
    if (element) {
      element.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollRef, callback, hasMore]);
}

export default useInfiniteScrollToBottom;

import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  Tooltip,
  TextField,
  Autocomplete,
  IconButton,
} from "@mui/material";
import { Send } from "@mui/icons-material/";
import { useGetAllUsersGroupByAccountTypeQuery } from "../../services/user";
import { useGetAllMembersGroupedByTierQuery } from "../../services/member";
import { useCreateChatMutation } from "../../services/messages";
import NotificationSnackbar from "../helpers/notification-snackbar";

const CreateChatDialog = ({ open, onClose }) => {
  const { userId, accountType } = useContext(AuthContext);
  const { data: groupedMembers } = useGetAllMembersGroupedByTierQuery();
  const { data: groupedUsers } = useGetAllUsersGroupByAccountTypeQuery(
    accountType,
    {
      skip: accountType !== "reosadmin",
    }
  );
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [participants, setParticipants] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (accountType === "reosadmin") {
      const usersList = [];
      Object.entries(groupedUsers || {}).forEach(([accountType, users]) => {
        users.forEach((user) => {
          if (user._id !== userId) {
            usersList.push({ ...user, accountType });
          }
        });
      });
      setParticipants(usersList);
    } else {
      const membersList = [];
      Object.entries(groupedMembers || {}).forEach(([tierName, members]) => {
        members.forEach((member) => {
          if (member._id !== userId) {
            membersList.push({ ...member, tierName });
          }
        });
      });
      setParticipants(membersList);
    }
  }, [accountType, groupedUsers, groupedMembers, userId]);

  const [createChat] = useCreateChatMutation();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [inputmembervalue, setInputmembervalue] = useState("");

  const handleCreateChat = async () => {
    if (selectedUserId && message.trim()) {
      try {
        const result = await createChat({
          senderId: userId,
          receiverId: selectedUserId?._id,
          content: message,
        });

        if (result?.data) {
          setNotification({
            open: true,
            message: "Chat created successfully",
          });
        } else if (result?.error) {
          setNotification({
            open: true,
            message: `Error: ${
              result?.error?.data?.msg || "Failed to create chat"
            }`,
          });
        }
      } catch (error) {
        setNotification({
          open: true,
          message: `Exception: ${error.message || "Unknown error"}`,
        });
      }
      setSelectedUserId(null);
      setMessage("");
      onClose();
    } else {
      setNotification({
        open: true,
        message: "Please select a user and enter a message.",
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const tooltipTitle = selectedUserId
    ? "Create Chat"
    : "Disabled, the Member hasn't been chosen";

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleCreateChat();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Select Member to Chat With</DialogTitle>
      <DialogContent className="member-message">
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Autocomplete
              value={selectedUserId}
              onChange={(event, newValue) => {
                setSelectedUserId(newValue);
              }}
              inputValue={inputmembervalue}
              onInputChange={(event, newInputValue) => {
                setInputmembervalue(newInputValue);
              }}
              options={participants}
              groupBy={(option) =>
                accountType === "reosadmin"
                  ? option.accountType
                  : option.tierName
              }
              getOptionLabel={(option) =>
                option
                  ? `${option.firstName} ${option.lastName} (${option.email})`
                  : ""
              }
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={`${
                    accountType === "reosadmin"
                      ? option.accountType
                      : option.tierName
                  }-${option._id}`}
                >
                  {" "}
                  {`${option.firstName} ${option.lastName} (${option.email})`}
                </li>
              )}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a Member"
                  variant="outlined"
                  margin="normal"
                  required
                />
              )}
            />
          </Grid>
          <Grid item>
            <div className="message-input">
              <TextField
                className="message-input-textarea"
                placeholder="New Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                fullWidth
                onKeyDown={handleKeyDown}
                multiline
                rows={4}
              />
              <Button onClick={onClose} className="message-cancel-button">
                Cancel
              </Button>
              <Tooltip title={tooltipTitle}>
                <IconButton
                  className="message-send-button"
                  onClick={handleCreateChat}
                  disabled={!selectedUserId}
                >
                  <Send />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default CreateChatDialog;

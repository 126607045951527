import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const cleanupApi = createApi({
  reducerPath: "clean-up",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/clean-up/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["CleanUp"],
  endpoints: (builder) => ({
    cleanUpCategoriesAndGroupsByTier: builder.mutation({
      query: ({ accountType, tierId }) => {
        return {
          url: `clean-up-categories-and-groups-by-tier/${tierId}`,
          method: "POST",
        };
      },
      skip: (accountType) => accountType !== "reosadmin",
      invalidatesTags: ["CleanUp"],
    }),
  }),
});

export const { useCleanUpCategoriesAndGroupsByTierMutation } = cleanupApi;

export default cleanupApi;
export { cleanupApi };

import React, { useState } from "react";
import { useGetAllBrokerageProfilesQuery } from "../../../services/brokerage";
import {
  useAssignTierToBrokerageMutation,
  useGetAllTiersQuery,
} from "../../../services/tier";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const AssignTierToBrokerageDialog = ({ open, onClose }) => {
  const { data: tiers } = useGetAllTiersQuery();
  const { data: brokerages } = useGetAllBrokerageProfilesQuery();
  const [assignTierToBrokerage] = useAssignTierToBrokerageMutation();
  const [selectedTierId, setSelectedTierId] = useState(null);
  const [selectedBrokerageId, setSelectedBrokerageId] = useState(null);
  const [inputtiervalue, setInputtiervalue] = useState("");
  const [inputbrokeragevalue, setInputbrokeragevalue] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await assignTierToBrokerage({
      brokerageId: selectedBrokerageId?._id,
      tierId: selectedTierId?._id,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        onClose();
        setInputbrokeragevalue();
        setInputtiervalue();
        setSelectedBrokerageId(null);
        setSelectedTierId(null);
      }, 2000);
    } else {
      setNotification({
        open: true,
        message: `Error assigning Role to the User. ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Assign Tier to Role</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Autocomplete
                value={selectedTierId}
                onChange={(event, newValue) => {
                  setSelectedTierId(newValue);
                }}
                inputValue={inputtiervalue}
                onInputChange={(event, newInputValue) => {
                  setInputtiervalue(newInputValue);
                }}
                options={tiers || []}
                getOptionLabel={(option) =>
                  option
                    ? `${option?.level} ${
                        option?.brokerage || option?.team || option?.group
                          ? option?.brokerage?.officeName ||
                            option?.team?.name ||
                            option?.group?.name
                          : ""
                      } ${
                        option?.level === "system" || option?.level === "global"
                          ? " - REACirc"
                          : ""
                      } ${
                        option?._id === option?.brokerage?.leadBrokerageTier
                          ? " - (Lead Brokerage Office)"
                          : ""
                      } ${
                        option?._id === option?.brokerage?.tier
                          ? " - (Office)"
                          : ""
                      }`
                    : ""
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Tier"
                    variant="outlined"
                    margin="normal"
                    required
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                value={selectedBrokerageId}
                onChange={(event, newValue) => {
                  setSelectedBrokerageId(newValue);
                }}
                inputValue={inputbrokeragevalue}
                onInputChange={(event, newInputValue) => {
                  setInputbrokeragevalue(newInputValue);
                }}
                options={brokerages || []}
                getOptionLabel={(option) =>
                  option
                    ? `${option?.user?.firstName} ${option?.user?.lastName} (${option?.user?.email})`
                    : ""
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Brokerage"
                    variant="outlined"
                    margin="normal"
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Assign Tier to Brokerage
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default AssignTierToBrokerageDialog;

import React, { useState, memo } from "react";
import { Paper, Tabs, Tab } from "@mui/material";
import { TabPanel } from "../../helpers/utils";
import DutiesList from "./DutiesList";
import DutiesChangesList from "./DutiesChangesList";
import DutyScheduleList from "./DutyScheduleList";

function DutiesManagementTabs() {
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper className="tabs-paper-brokerage-ecosys">
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="brokerage tabs"
        variant="scrollable"
        scrollButtons="auto"
        className="tabs-paper"
      >
        <Tab
          label="Duties"
          id="simple-tab-0"
          aria-controls="simple-tabpanel-0"
        />
        <Tab
          label="Duties Changes"
          id="simple-tab-1"
          aria-controls="simple-tabpanel-1"
        />
        <Tab
          label="Duty Schedules"
          id="simple-tab-2"
          aria-controls="simple-tabpanel-2"
        />
      </Tabs>
      <TabPanel value={tabValue} index={0} className="brokerage-tabs-paper">
        <DutiesList />
      </TabPanel>
      <TabPanel value={tabValue} index={1} className="brokerage-tabs-paper">
        <DutiesChangesList />
      </TabPanel>
      <TabPanel value={tabValue} index={2} className="brokerage-tabs-paper">
        <DutyScheduleList />
      </TabPanel>
    </Paper>
  );
}

export default memo(DutiesManagementTabs);

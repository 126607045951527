import React, { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Divider,
  Box,
  TableSortLabel,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

function ErrorLogsList({ errorLogs }) {
  const [sortField, setSortField] = useState("service");
  const [sortDirection, setSortDirection] = useState("asc");
  const [expandedInfo, setExpandedInfo] = useState({});

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection((prevDirection) =>
        prevDirection === "asc" ? "desc" : "asc"
      );
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const sortedErrorLogs = useMemo(() => {
    if (!errorLogs) return [];
    const comparator = (a, b) => {
      if (sortDirection === "asc") {
        return a[sortField] < b[sortField] ? -1 : 1;
      } else {
        return a[sortField] > b[sortField] ? -1 : 1;
      }
    };
    return [...errorLogs].sort(comparator);
  }, [errorLogs, sortField, sortDirection]);

  const toggleExpandInfo = (id) => {
    setExpandedInfo((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const renderAdditionalInfo = (info, id) => {
    if (typeof info === "object") {
      const infoString = JSON.stringify(info, null, 2);
      return renderExpandableContent(infoString, id);
    } else if (typeof info === "string") {
      return renderExpandableContent(info, id);
    } else {
      return "N/A";
    }
  };

  const renderExpandableContent = (content, id) => {
    const previewLength = 50;
    const isExpanded = expandedInfo[id];
    if (content.length <= previewLength) {
      return <Typography>{content}</Typography>;
    }
    return (
      <>
        <Typography>
          {isExpanded ? content : `${content.substring(0, previewLength)}...`}
        </Typography>
        <IconButton onClick={() => toggleExpandInfo(id)}>
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </>
    );
  };

  if (!errorLogs || errorLogs?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 20,
        }}
      >
        <Typography variant="h5" gutterBottom>
          No Error Logs available
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "service"}
                    direction={sortDirection}
                    onClick={() => handleSort("service")}
                  >
                    service
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "function"}
                    direction={sortDirection}
                    onClick={() => handleSort("function")}
                  >
                    function
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "error"}
                    direction={sortDirection}
                    onClick={() => handleSort("error")}
                  >
                    error
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "timestamp"}
                    direction={sortDirection}
                    onClick={() => handleSort("timestamp")}
                  >
                    timestamp
                  </TableSortLabel>
                </TableCell>
                <TableCell>Additional Info</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(sortedErrorLogs) &&
                sortedErrorLogs?.map((errorLog) => (
                  <TableRow key={errorLog?._id} className="table-row">
                    <TableCell>{errorLog?.service}</TableCell>
                    <TableCell>{errorLog?.function}</TableCell>
                    <TableCell>{errorLog?.error}</TableCell>
                    <TableCell>{errorLog?.timestamp}</TableCell>
                    <TableCell>
                      {renderAdditionalInfo(
                        errorLog?.additionalInfo,
                        errorLog?._id
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider style={{ margin: "20px 0" }} />
      </div>
    </>
  );
}

export default ErrorLogsList;

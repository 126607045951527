import React, { useState } from "react";
import { useGetTeamByBrokerageQuery } from "../../../services/team";
import GroupManagementMenu from "./GroupManagementMenu";
import {
  Container,
  Typography,
  Box,
  Paper,
  CircularProgress,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import CreateBrokerageTeamDialog from "./CreateBrokerageTeamDialog";
import CreateBrokerageGroupDialog from "./CreateBrokerageGroupDialog";
import UpdateGroupDialog from "./UpdateGroupDialog";
import GroupsList from "./GroupsList";

const TeamAndGroupsManager = ({
  brokerageId,
  tier,
  setSelectedGroup,
  setTgtabValue,
}) => {
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const { data: team, isLoading } = useGetTeamByBrokerageQuery(brokerageId, {
    skip: !brokerageId,
  });

  const [openCreateTeamDialog, setOpenCreateTeamDialog] = useState(false);
  const [openCreateGroupialog, setOpenCreateGroupDialog] = useState(false);
  const [openUpdateGroupDialog, setOpenUpdateGroupDialog] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  const handleOpenCreateGroupDialog = () => {
    setOpenCreateGroupDialog(true);
  };

  const handleCloseCreateGroupDialog = () => {
    setOpenCreateGroupDialog(false);
  };

  const handleOpenCreateTeamDialog = () => {
    setOpenCreateTeamDialog(true);
  };

  const handleCloseCreateTeamDialog = () => {
    setOpenCreateTeamDialog(false);
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleGroupClick = (groupId) => {
    setSelectedGroup(groupId);
    setTgtabValue(1);
  };

  const handleOpenUpdateGroupDialog = (groupId) => {
    setSelectedGroupId(groupId);
    setOpenUpdateGroupDialog(true);
  };

  const handleCloseUpdateGroupDialog = () => {
    setOpenUpdateGroupDialog(false);
    setSelectedGroupId(null);
  };

  if (isLoading)
    return (
      <div className="loading-spinner">
        <CircularProgress size={24} />
      </div>
    );

  return (
    <>
      <Container maxWidth="md">
        <Paper sx={{ p: 2, mb: 3, display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row", // Set to row to align items horizontally
              alignItems: "center", // Align items vertically in the center
              justifyContent: "space-between", // Add space between the elements
              width: "100%", // Take full width to spread items across
              p: 1, // Padding around the Box
            }}
          >
            <Typography variant="h5" fontWeight={700}>
              Team and Group Management
            </Typography>
            <GroupManagementMenu
              team={team}
              handleOpenCreateTeamDialog={handleOpenCreateTeamDialog}
              handleOpenCreateGroupDialog={handleOpenCreateGroupDialog}
            />
          </Box>
          <GroupsList
            teamId={team?._id}
            onGroupClick={handleGroupClick}
            onUpdateGroupClick={handleOpenUpdateGroupDialog}
          />
          <CreateBrokerageGroupDialog
            teamId={team?._id}
            tierId={tier?._id}
            brokerageId={brokerageId}
            open={openCreateGroupialog}
            onClose={handleCloseCreateGroupDialog}
          />
          <CreateBrokerageTeamDialog
            brokerageId={brokerageId}
            tierId={tier?._id}
            open={openCreateTeamDialog}
            onClose={handleCloseCreateTeamDialog}
            onGroupClick={handleGroupClick}
          />
          <UpdateGroupDialog
            groupId={selectedGroupId}
            brokerageId={brokerageId}
            open={openUpdateGroupDialog}
            onClose={handleCloseUpdateGroupDialog}
          />
          <NotificationSnackbar
            open={notification.open}
            message={notification.message}
            onClose={handleClose}
          />
        </Paper>
      </Container>
    </>
  );
};

export default TeamAndGroupsManager;

import React, { useState, useEffect, memo, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Paper, Tabs, Tab, Typography } from "@mui/material";
import MembersToInviteList from "./MembersToInviteList";
import InvitationList from "./InvitationList";
import { TabPanel } from "../../helpers/utils";

function InvitationTabs({ tierId }) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const initialTab = parseInt(queryParams.get("invtab")) || 0;
  const [invtabValue, setInvtabValue] = useState(initialTab);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set("invtab", invtabValue);
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [invtabValue, navigate, location.pathname, location.search]);

  const handleTabChange = (event, newValue) => {
    setInvtabValue(newValue);
  };

  return (
    <Paper className="tabs-paper-brokerage-ecosys">
      <Typography gutterBottom className="main-title">
        Brokerage Management
      </Typography>
      <Tabs
        value={invtabValue}
        onChange={handleTabChange}
        aria-label="invitations tabs"
        variant="scrollable"
        scrollButtons="auto"
        className="tabs-paper"
      >
        <Tab
          label="Invitations Sent"
          id="inv-tab-0"
          aria-controls="inv-tabpanel-0"
        />
        <Tab
          label="Send To Selected Members"
          id="inv-tab-1"
          aria-controls="inv-tabpanel-1"
        />
      </Tabs>
      <TabPanel
        value={invtabValue}
        index={0}
        className="invitations-tabs-paper"
      >
        <InvitationList tierId={tierId} />
      </TabPanel>
      <TabPanel
        value={invtabValue}
        index={1}
        className="invitations-tabs-paper"
      >
        <MembersToInviteList tierId={tierId} />
      </TabPanel>
    </Paper>
  );
}

export default memo(InvitationTabs);

import React, { useState, useMemo, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { categoryIcon } from "../../sidebar/sidebarHelpers";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Divider,
  Box,
  TableSortLabel,
  Checkbox,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  useDeleteCategoryMutation,
  useDeleteAllCategoriesMutation,
  useGetCategoriesByTierQuery,
} from "../../../services/category";
import {
  MoreVert,
  DeleteForever,
  Edit,
  ContentCopyOutlined,
  ScreenShare,
  StopScreenShare,
} from "@mui/icons-material/";
import { ConfirmationDialog } from "../..";
import { useNavigate } from "react-router-dom";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import CreateEditCategoryForm from "./CreateEditCategoryForm";
import MigrateCategoryForm from "./MigrateCategoryForm";
import AssignAllOfficeCategoiesToLocalOfficeDialog from "./AssignAllOfficeCategoiesToLocalOfficeDialog";
import AssignGlobalCategoryToLocalOfficeDialog from "../../brokerage/categories/AssignGlobalCategoryToLocalOfficeDialog";

function CategoriesList({
  selectedTier,
  isLBTab = false,
  handleOpenAssignAllOfficeCategoryToLocalOfficeDialog,
  handleOpenRemoveAllOfficeCategoryToLocalOfficeDialog,
}) {
  const { userId, userRole } = useContext(AuthContext);
  const navigate = useNavigate();
  const [deleteCategory] = useDeleteCategoryMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {
    data: categories,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetCategoriesByTierQuery(
    { tierId: selectedTier?._id },
    {
      skip: !selectedTier?._id,
    }
  );

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [deleteAllCategories] = useDeleteAllCategoriesMutation();
  const [selectAll, setSelectAll] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [currentCategoryClicked, setCurrentCategoryClick] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [sortField, setSortField] = useState("order");
  const [sortDirection, setSortDirection] = useState("asc");
  const [openEditCategoryDialog, setOpenEditCategoryDialog] = useState(false);
  const [openMigrateCategoryDialog, setOpenMigrateCategoryDialog] =
    useState(false);
  const [
    openAssignAllOfficeCategoriesToLocalOfficeDialog,
    setOpenAssignAllOfficeCategoriesToLocalOfficeDialog,
  ] = useState(false);
  const [assignAllOfficeCategoriesMode, setAssignAllOfficeCategoriesMode] =
    useState("");
  const [
    openAssignGlobalCategoryToLocalOfficeDialog,
    setOpenAssignGlobalCategoryToLocalOfficeDialog,
  ] = useState(false);
  const [assignGlobalCategoryMode, setAssignGlobalCategoryMode] = useState("");

  const handleRowClick = (event, categoryItemId, categoryItem) => {
    if (
      event.target.closest(".menu-button") ||
      event.target.closest(".view-details-button")
    ) {
      setCurrentCategoryClick(categoryItemId);
      setCurrentCategory(categoryItem);
      return;
    }
    navigate(`/category/${categoryItemId}`);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenEditCategoryDialog = (event) => {
    event.stopPropagation();
    setOpenEditCategoryDialog(true);
    setAnchorEl(null);
  };

  const handleCloseEditCategoryDialog = () => {
    setOpenEditCategoryDialog(false);
  };

  const handleOpenMigrateCategoryDialog = (event) => {
    event.stopPropagation();
    setOpenMigrateCategoryDialog(true);
    setAnchorEl(null);
  };

  const handleCloseMigrateCategoryDialog = () => {
    setOpenMigrateCategoryDialog(false);
  };

  const handleDeleteCategoryClick = (event, categoryId) => {
    event.stopPropagation();
    setCurrentCategory({ ...currentCategory, _id: categoryId });
    setConfirmationDialogOpen(true);
  };
  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleConfirmationDialogConfirm = async () => {
    if (currentCategory?._id) {
      const result = await deleteCategory({
        userId,
        categoryId: currentCategory._id,
      });

      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        refetch();
        setConfirmationDialogOpen(false);
      } else {
        setNotification({
          open: true,
          message: `Error deleting Category. ${result?.error?.data?.msg}`,
        });
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  const handleSelectCategory = (event, categoryId) => {
    event && event.stopPropagation();

    const selectedIndex = selectedCategories.indexOf(categoryId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCategories, categoryId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCategories.slice(1));
    } else if (selectedIndex === selectedCategories.length - 1) {
      newSelected = newSelected.concat(selectedCategories.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCategories.slice(0, selectedIndex),
        selectedCategories.slice(selectedIndex + 1)
      );
    }

    setSelectedCategories(newSelected);
  };

  const handleSelectAll = (event) => {
    event.stopPropagation();

    setSelectAll(event.target.checked);
    if (event.target.checked) {
      const allCategoryIds = categories.map((category) => category._id);
      setSelectedCategories(allCategoryIds);
    } else {
      setSelectedCategories([]);
    }
  };

  const handleDeleteSelected = async () => {
    if (selectedCategories) {
      const result = await deleteAllCategories({
        userId,
        categoryIds: selectedCategories,
      });
      if (result?.data) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        setTimeout(() => {
          setSelectedCategories([]);
          setSelectAll(false);
          refetch();
        }, 2000);
      } else {
        setNotification({
          open: true,
          message: `Error deleting categories: ${
            result.error.data?.msg || "Unknown error"
          }`,
        });
      }
    }
  };

  const handleOpenAssignAllOfficeCategoriesDialog = (mode) => {
    setAssignAllOfficeCategoriesMode(mode);
    setOpenAssignAllOfficeCategoriesToLocalOfficeDialog(true);
  };

  const handleCloseAssignAllOfficeCategoriesDialog = () => {
    setOpenAssignAllOfficeCategoriesToLocalOfficeDialog(false);
  };

  const handleOpenAssignGlobalCategoryMirroringDialog = (category) => {
    setAssignGlobalCategoryMode("assign");
    setCurrentCategory(category);
    setOpenAssignGlobalCategoryToLocalOfficeDialog(true);
  };

  const handleOpenRemoveGlobalCategoryMirroringDialog = (category) => {
    setAssignGlobalCategoryMode("remove");
    setCurrentCategory(category);
    setOpenAssignGlobalCategoryToLocalOfficeDialog(true);
  };

  const handleCloseGlobalCategoryMirroringDialog = () => {
    setOpenAssignGlobalCategoryToLocalOfficeDialog(false);
    setAssignGlobalCategoryMode("");
    setCurrentCategory(null);
  };

  const groupedCategories = useMemo(() => {
    const sortedCategories = [...(categories || [])]?.sort((a, b) => {
      if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
      if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
      return 0;
    });

    return sortedCategories?.reduce((acc, category) => {
      const group = category?.group?.name || "Uncategorized";
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(category);
      return acc;
    }, {});
  }, [categories, sortField, sortDirection]);

  if (isLoading) {
    return (
      <CircularProgress style={{ display: "block", margin: "20px auto" }} />
    );
  }

  if (!categories || categories.length === 0) {
    return (
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        No categories available
      </Typography>
    );
  }

  if (isError) {
    return (
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        {isError && error?.data?.msg === "No categories found for this tier"
          ? error?.data?.msg
          : isError && error?.data?.msg !== "No categories found for this tier"
          ? "Error loading categories"
          : "No categories available"}
      </Typography>
    );
  }

  const isREOSAdmin = userRole.includes("reos");

  return (
    <>
      <Box>
        <Typography
          variant="h6"
          style={{ textAlign: "center", marginBottom: "1rem" }}
        >
          Categories List
        </Typography>
        <Divider
          variant="fullWidth"
          style={{ textAlign: "center", marginBottom: "1rem" }}
        />
      </Box>
      {categories?.length > 0 &&
        Object.entries(groupedCategories)?.map(([name, categories]) => (
          <div key={name}>
            <Box
              className="category-type-header"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography variant="h6" style={{ margin: "20px 0" }}>
                {name}
              </Typography>
              <Box>
                {isLBTab && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      handleOpenAssignAllOfficeCategoriesDialog("assign")
                    }
                    disabled={selectedCategories.length === 0}
                  >
                    Mirror Selected
                  </Button>
                )}
                {(isLBTab || isREOSAdmin) && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                      handleOpenAssignAllOfficeCategoriesDialog("remove")
                    }
                    disabled={selectedCategories.length === 0}
                  >
                    Stop Mirroring Selected
                  </Button>
                )}
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleDeleteSelected}
                  disabled={selectedCategories.length === 0}
                >
                  Delete Selected
                </Button>
              </Box>
            </Box>
            <TableContainer component={Paper}>
              <Table className="user-table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "var(--header-bg)" }}>
                    <TableCell sx={{ fontWeight: 700 }}>
                      <Checkbox
                        checked={selectAll}
                        onChange={(event) => {
                          event.stopPropagation();
                          handleSelectAll(event);
                        }}
                        inputProps={{ "aria-label": "Select all categories" }}
                      />
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>
                      <TableSortLabel
                        active={sortField === "order"}
                        direction={sortDirection}
                        onClick={() => handleSort("order")}
                      >
                        #
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>
                      <TableSortLabel
                        active={sortField === "name"}
                        direction={sortDirection}
                        onClick={() => handleSort("name")}
                      >
                        Category Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>
                      <TableSortLabel
                        active={sortField === "path"}
                        direction={sortDirection}
                        onClick={() => handleSort("path")}
                      >
                        Category Path
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700 }}>
                      <TableSortLabel
                        active={sortField === "type"}
                        direction={sortDirection}
                        onClick={() => handleSort("type")}
                      >
                        Category Type
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700 }} colSpan={2}>
                      Icon
                    </TableCell>
                    {(isLBTab || isREOSAdmin) && (
                      <TableCell>is Mirroring</TableCell>
                    )}
                    <TableCell sx={{ fontWeight: 700 }}>Actions </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(categories) &&
                    categories?.map((category, index) => (
                      <TableRow
                        key={category?._id}
                        onClick={(event) =>
                          handleRowClick(event, category?._id, category)
                        }
                        style={{ cursor: "pointer" }}
                        className="table-row"
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            className="menu-button"
                            checked={selectedCategories.includes(category?._id)}
                            onChange={(event) => {
                              handleSelectCategory(event, category?._id);
                            }}
                            inputProps={{
                              "aria-labelledby": `checkbox-list-label-${index}`,
                            }}
                          />
                        </TableCell>
                        <TableCell>{category?.order}</TableCell>
                        <TableCell>{category?.name}</TableCell>
                        <TableCell>{category?.path}</TableCell>
                        <TableCell>{category?.type}</TableCell>
                        <TableCell>
                          {" "}
                          {category?.icon && (
                            <>{categoryIcon(category?.icon)}</>
                          )}
                        </TableCell>
                        <TableCell>{category?.icon}</TableCell>
                        {(isLBTab || isREOSAdmin) && (
                          <TableCell>
                            {category?.mirroringTo &&
                            category?.mirroringTo?.length > 0 ? (
                              category?.mirroringTo?.map((office, index) => (
                                <Box
                                  key={index}
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <ScreenShare />
                                  <Typography
                                    variant="body2"
                                    ml={1}
                                    sx={{ fontSize: "inherit" }}
                                  >
                                    {(office?.brokerage &&
                                      office?.brokerage?.officeShortName) ||
                                      (office?.brokerage &&
                                        office?.brokerage?.officeName) ||
                                      office?.shortName}
                                  </Typography>
                                </Box>
                              ))
                            ) : (
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <StopScreenShare />
                              </Box>
                            )}
                          </TableCell>
                        )}
                        <TableCell>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {((category?.type !== "common" &&
                              category?.type !== "chat") ||
                              isREOSAdmin) && (
                              <IconButton
                                aria-label="delete"
                                onClick={(event) =>
                                  handleDeleteCategoryClick(
                                    event,
                                    category?._id
                                  )
                                }
                              >
                                <DeleteForever />
                              </IconButton>
                            )}
                            <IconButton
                              className="menu-button"
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              onClick={(event) =>
                                handleMenuOpen(event, category?._id)
                              }
                            >
                              <MoreVert />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleMenuClose}
                            >
                              {((currentCategory?.type !== "common" &&
                                currentCategory?.type !== "chat") ||
                                isREOSAdmin) && (
                                <MenuItem
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleOpenEditCategoryDialog(event);
                                  }}
                                >
                                  <Edit />
                                  <Typography ml={1}>Edit Category</Typography>
                                </MenuItem>
                              )}
                              {((currentCategory?.type !== "common" &&
                                currentCategory?.type !== "chat") ||
                                isREOSAdmin) && (
                                <MenuItem
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleOpenMigrateCategoryDialog(event);
                                  }}
                                >
                                  <ContentCopyOutlined />
                                  <Typography ml={1}>
                                    Migrate Category
                                  </Typography>
                                </MenuItem>
                              )}
                              {isLBTab &&
                                currentCategory?.type !== "common" &&
                                currentCategory?.type !== "announcements" &&
                                currentCategory?.type !== "chat" && (
                                  <MenuItem
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleOpenAssignAllOfficeCategoryToLocalOfficeDialog(
                                        currentCategory
                                      );
                                      handleMenuClose();
                                    }}
                                  >
                                    <ContentCopyOutlined />
                                    <Typography ml={1}>
                                      Mirror Category to
                                    </Typography>
                                  </MenuItem>
                                )}
                              {isLBTab &&
                                currentCategory?.type !== "common" &&
                                currentCategory?.type !== "announcements" &&
                                currentCategory?.type !== "chat" && (
                                  <MenuItem
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleOpenRemoveAllOfficeCategoryToLocalOfficeDialog(
                                        currentCategory
                                      );
                                      handleMenuClose();
                                    }}
                                  >
                                    <ContentCopyOutlined />
                                    <Typography ml={1}>
                                      Stop Mirror Category to
                                    </Typography>
                                  </MenuItem>
                                )}
                              {isREOSAdmin &&
                                currentCategory?.type !== "common" &&
                                currentCategory?.type !== "announcements" &&
                                currentCategory?.type !== "chat" && (
                                  <MenuItem
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleOpenAssignGlobalCategoryMirroringDialog(
                                        currentCategory
                                      );
                                      handleMenuClose();
                                    }}
                                  >
                                    <ContentCopyOutlined />
                                    <Typography ml={1}>
                                      Mirror Global Category to
                                    </Typography>
                                  </MenuItem>
                                )}
                              {isREOSAdmin &&
                                currentCategory?.type !== "common" &&
                                currentCategory?.type !== "announcements" &&
                                currentCategory?.type !== "chat" && (
                                  <MenuItem
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      handleOpenRemoveGlobalCategoryMirroringDialog(
                                        currentCategory
                                      );
                                      handleMenuClose();
                                    }}
                                  >
                                    <ContentCopyOutlined />
                                    <Typography ml={1}>
                                      Stop Mirror Global Category to
                                    </Typography>
                                  </MenuItem>
                                )}
                              <MenuItem>
                                <Typography
                                  variant="body1"
                                  className="view-details-button"
                                  onClick={() =>
                                    navigate(
                                      `/category/${currentCategoryClicked}`
                                    )
                                  }
                                >
                                  View Details
                                </Typography>
                              </MenuItem>
                            </Menu>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider style={{ margin: "20px 0" }} />
          </div>
        ))}
      <CreateEditCategoryForm
        mode="edit"
        refetchCategory={refetch}
        category={currentCategory}
        open={openEditCategoryDialog}
        onClose={handleCloseEditCategoryDialog}
      />
      <MigrateCategoryForm
        category={currentCategory}
        open={openMigrateCategoryDialog}
        onClose={handleCloseMigrateCategoryDialog}
      />
      <AssignAllOfficeCategoiesToLocalOfficeDialog
        open={openAssignAllOfficeCategoriesToLocalOfficeDialog}
        onClose={handleCloseAssignAllOfficeCategoriesDialog}
        selectedCategories={selectedCategories || []}
        mode={assignAllOfficeCategoriesMode}
      />
      <AssignGlobalCategoryToLocalOfficeDialog
        mode={assignGlobalCategoryMode}
        open={openAssignGlobalCategoryToLocalOfficeDialog}
        preselectedCategory={currentCategory}
        onClose={handleCloseGlobalCategoryMirroringDialog}
      />
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmationDialogConfirm}
        title="Confirm Deleting the Category"
        description="Are you sure you want to delete this Category permanently?"
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
}

export default CategoriesList;

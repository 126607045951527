import React, { useState, useEffect, memo, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Paper, Tabs, Tab, Typography } from "@mui/material";
import GroupManager from "../team-groups/GroupManager";
import TeamAndGroupsManager from "../team-groups/TeamAndGroupsManager";
import { TabPanel } from "../../helpers/utils";

const TeamAndGroupsManagerTabs = ({ brokerageId, tier }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const initialTab = parseInt(queryParams.get("tgtab")) || 0;
  const [tgtabValue, setTgtabValue] = useState(initialTab);
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set("tgtab", tgtabValue);

    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [tgtabValue, navigate, location.pathname, location.search]);

  const handleTabChange = (event, newValue) => {
    setTgtabValue(newValue);
  };

  return (
    <Paper className="tabs-paper-brokerage-ecosys">
      <Tabs
        value={tgtabValue}
        onChange={handleTabChange}
        aria-label="team and groups manager tabs"
        variant="scrollable"
        scrollButtons="auto"
        className="tabs-paper"
      >
        <Tab
          label="Groups List"
          id="simple-tab-0"
          aria-controls="simple-tabpanel-0"
        />
        <Tab
          label="Group Card"
          id="simple-tab-1"
          aria-controls="simple-tabpanel-1"
        />
      </Tabs>
      <TabPanel value={tgtabValue} index={0} className="tabs-paper">
        <TeamAndGroupsManager
          brokerageId={brokerageId}
          tier={tier}
          setSelectedGroup={setSelectedGroup}
          setTgtabValue={setTgtabValue}
        />
      </TabPanel>
      <TabPanel value={tgtabValue} index={1} className="tabs-paper">
        {selectedGroup ? (
          <GroupManager groupId={selectedGroup} />
        ) : (
          <Typography>Select a group</Typography>
        )}
      </TabPanel>
    </Paper>
  );
};

export default memo(TeamAndGroupsManagerTabs);

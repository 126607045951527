import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const RegenerateDutySchedulingDialog = ({ open, onClose, onConfirm }) => {
  const [selectedMonth, setSelectedMonth] = useState("");
  const [isCustom, setIsCustom] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  useEffect(() => {
    // Set the current month as default
    const currentMonth = dayjs().format("YYYY-MM");
    setSelectedMonth(currentMonth);
    // Set initial start and end times
    setStartTime(dayjs(currentMonth).startOf("month"));
    setEndTime(dayjs(currentMonth).endOf("month"));
  }, []);

  const monthOptions = Array.from({ length: 5 }, (_, i) => {
    const date = dayjs().add(i, "month");
    return {
      value: date.format("YYYY-MM"),
      label: date.format("MMMM YYYY"),
    };
  });

  const handleMonthChange = (event) => {
    const value = event.target.value;
    setSelectedMonth(value);
    setIsCustom(value === "custom");

    if (value !== "custom") {
      const start = dayjs(value).startOf("month");
      const end = dayjs(value).endOf("month");
      setStartTime(start);
      setEndTime(end);
    }
  };

  const handleStartDateChange = (newValue) => {
    setStartTime(newValue);
    if (endTime && newValue.isAfter(endTime)) {
      setEndTime(newValue);
    }
  };

  const handleConfirm = () => {
    onConfirm(startTime, endTime);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ backgroundColor: "#1976d2", color: "white" }}>
        Schedule Generation
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="subtitle1"
          sx={{ marginTop: "1rem", marginBottom: "1rem" }}
        >
          Creating a new schedule for the selected period
        </Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel>Select Month</InputLabel>
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            label="Select Month"
          >
            {monthOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            <MenuItem value="custom">Custom</MenuItem>
          </Select>
        </FormControl>
        {selectedMonth === dayjs().format('YYYY-MM') && (
          <Typography
            variant="body2"
            color="warning.main"
            sx={{ mt: 1, mb: 1 }}
          >
            Note: For the current month, the schedule will be set from today onwards.
          </Typography>
        )}
        {isCustom && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DatePicker
                  label="Start Date"
                  value={startTime}
                  onChange={handleStartDateChange}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  label="End Date"
                  value={endTime}
                  onChange={(newValue) => setEndTime(newValue)}
                  slotProps={{ textField: { fullWidth: true } }}
                  minDate={startTime}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RegenerateDutySchedulingDialog;

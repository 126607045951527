import React, {
  useState,
  useEffect,
  useContext,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  DialogContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  Grid,
  Checkbox,
  ListItemText,
} from "@mui/material";
import {
  useCreateProductCollectionMutation,
  useUpdateProductCollectionMutation,
} from "../../../../services/billing/productCollections";
import { useFetchProductsQuery } from "../../../../services/billing/products";
import { AuthContext } from "../../../../context/AuthContext";
import { formatDateSelector } from "../../../helpers/utils";

const ProductCollectionForm = forwardRef(
  ({ collection, onClose, onSubmit }, ref) => {
    const [productCollectionCode, setProductCollectionCode] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [, setPrice] = useState("");
    const [frequency, setFrequency] = useState("monthly");
    const [validFrom, setValidFrom] = useState("");
    const [validTo, setValidTo] = useState("");
    const [status, setStatus] = useState("active");
    const [discount, setDiscount] = useState("");
    const [promotionCode, setPromotionCode] = useState("");
    const [metadata, setMetadata] = useState("");
    const [selectedProducts, setSelectedProducts] = useState([]);
    const { userId } = useContext(AuthContext);

    const { data: products = [] } = useFetchProductsQuery();
    const [createCollection] = useCreateProductCollectionMutation();
    const [updateCollection] = useUpdateProductCollectionMutation();

    useEffect(() => {
      if (collection) {
        setProductCollectionCode(collection.productCollectionCode || "");
        setName(collection.name || "");
        setDescription(collection.description || "");
        setPrice(collection.price || "");
        setFrequency(collection.frequency || "monthly");
        setValidFrom(formatDateSelector(collection.validFrom));
        setValidTo(formatDateSelector(collection.validTo));
        setStatus(collection.status || "active");
        setDiscount(collection.discount || "");
        setPromotionCode(collection.promotionCode || "");
        setMetadata(
          collection.metadata ? JSON.stringify(collection.metadata) : ""
        );
        setSelectedProducts(collection.products || []);
      } else {
        const today = new Date().toISOString().split("T")[0];
        setValidFrom(today);
      }
    }, [collection]);

    useImperativeHandle(ref, () => ({
      submit: handleSubmit,
    }));

    const handleSubmit = async () => {
      const collectionData = {
        productCollectionCode,
        name,
        description,
        frequency,
        validFrom: validFrom || null,
        validTo: validTo || null,
        status,
        discount: parseFloat(discount),
        promotionCode,
        metadata: metadata ? JSON.parse(metadata) : {},
        createdBy: userId,
        updatedBy: userId,
        products: selectedProducts,
      };

      try {
        if (collection) {
          await updateCollection({
            id: collection._id,
            ...collectionData,
          }).unwrap();
        } else {
          await createCollection(collectionData).unwrap();
        }
        onClose();
      } catch (error) {
        console.error("Failed to submit collection:", error);
      }
    };

    // Map selected products to an array of IDs
    const selectedProductIds = new Set(
      selectedProducts.map((product) => product._id)
    );

    return (
      <form>
        <DialogContent>
          <Grid container spacing={2}>
            {/* Product Collection Code */}
            <Grid item xs={12}>
              <TextField
                label="Product Collection Code - Auto-generated"
                variant="outlined"
                fullWidth
                value={productCollectionCode}
                onChange={(e) => setProductCollectionCode(e.target.value)}
                disabled
              />
            </Grid>

            {/* Name */}
            <Grid item xs={12}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            {/* Description */}
            <Grid item xs={12}>
              <TextField
                label="Description"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>

            {/* Products */}
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel>Products</InputLabel>
                <Select
                  multiple
                  value={selectedProducts.map((product) => product._id)}
                  onChange={(e) => {
                    const selectedValues = e.target.value;
                    setSelectedProducts(
                      products.filter((product) =>
                        selectedValues.includes(product._id)
                      )
                    );
                  }}
                  renderValue={(selected) => (
                    <div>
                      {selected.map((id) => {
                        const product = products.find((p) => p._id === id);
                        return (
                          <div key={id}>
                            {product
                              ? `$ ${product.price} - ${product.name}`
                              : ""}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  label="Products"
                >
                  {products.map((product) => (
                    <MenuItem key={product._id} value={product._id}>
                      <Checkbox checked={selectedProductIds.has(product._id)} />
                      <ListItemText
                        primary={`$${product.price} - ${product.name} - ${product.clientType} - ${product.subscriptionType}`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Price and Frequency */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Billing Frequency</InputLabel>
                    <Select
                      value={frequency}
                      onChange={(e) => setFrequency(e.target.value)}
                      label="Billing Frequency"
                    >
                      <MenuItem value="monthly">Monthly</MenuItem>
                      <MenuItem value="yearly">Yearly</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* Valid From and Valid To */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Valid From"
                    variant="outlined"
                    type="datetime-local"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={validFrom}
                    onChange={(e) => setValidFrom(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Valid To"
                    variant="outlined"
                    type="datetime-local"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={validTo}
                    onChange={(e) => setValidTo(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Status, Discount, and Promotion Code */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      label="Status"
                    >
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="inactive">Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Discount"
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Promotion Code"
                    variant="outlined"
                    fullWidth
                    value={promotionCode}
                    onChange={(e) => setPromotionCode(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Metadata */}
            <Grid item xs={12}>
              <TextField
                label="Metadata"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={metadata}
                onChange={(e) => setMetadata(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </form>
    );
  }
);

export default ProductCollectionForm;

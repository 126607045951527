import React, { useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import {
  Container,
  Typography,
  Paper,
  List,
  ListItem,
  Divider,
  Grid,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import CategorySettingsList from "./CategorySettingsList";
import CreateEditCategorySettingsForm from "./CreateEditCategorySettingsForm";

const CategorySettingsManagement = () => {
  const { accountType } = useContext(AuthContext);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [
    openCreateCategorySettingsDialog,
    setOpenCreateCategorySettingsDialog,
  ] = useState(false);

  const handleOpenCreateCategorySettingsDialog = () => {
    setOpenCreateCategorySettingsDialog(true);
  };

  const handleCloseCreateCategorySettingsDialog = () => {
    setOpenCreateCategorySettingsDialog(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (accountType !== "reosadmin") {
    return (
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        You are not allowed for this page
      </Typography>
    );
  }

  return (
    <Container className="management-page">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Category Settings Management
        </Typography>
        <Grid container>
          <Grid item xs={6} sx={{ p: 2 }}>
            <List style={{ width: "100%", marginRight: "0.1rem" }}>
              <ListItem
                onClick={handleOpenCreateCategorySettingsDialog}
                className="user-management-item"
              >
                Create new Category Settings
              </ListItem>
              <Divider />
            </List>
          </Grid>
        </Grid>
        <CreateEditCategorySettingsForm
          mode="create"
          open={openCreateCategorySettingsDialog}
          onClose={handleCloseCreateCategorySettingsDialog}
        />
        <NotificationSnackbar
          open={notification.open}
          message={notification.message}
          onClose={handleClose}
        />
      </Paper>
      <CategorySettingsList />
    </Container>
  );
};

export default CategorySettingsManagement;

import React, { useEffect, useContext, memo, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { TierContext } from "../../context/TierContext";
import FileUploader from "../fileStore/FileUploader";
import { Card, Box } from "@mui/material";
import Navbar from "../GenericNavbar";
import FileList from "../fileStore/FileList";

const FilePostCard = ({ post, isPostHomePageList = false }) => {
  const { userId, accountType } = useContext(AuthContext);
  const [, setNotification] = useState({
    open: false,
    message: "",
  });
  const { currentTierId } = useContext(TierContext);
  const [currentPath, setCurrentPath] = useState("");
  const [pathStack, setPathStack] = useState([]);
  const [fullPath, setFullPath] = useState("");
  useEffect(() => {
    const newFullPath = pathStack.map((item) => item.name).join("/");
    setFullPath(newFullPath);
  }, [pathStack]);

  const isAdmin = accountType === "reosadmin" || accountType === "brokerage";

  return (
    <>
      <Navbar navBarName="File System" />
      <div className="members-page">
        <Box sx={{ display: "flex", justifyContent: "center", mt: "10px" }}>
          <Card
            sx={{ width: "80%", mt: "10px" }}
            className="post-card margin-bottom"
          >
            {isPostHomePageList &&
              post?.tier &&
              post?.tier?.brokerage?.officeName && (
                <Box className="post-card-tier-label">
                  {post?.tier?.brokerage?.officeName || "Unknown Office"}
                </Box>
              )}
            <FileList
              currentPath={currentPath}
              setCurrentPath={setCurrentPath}
              isAdmin={isAdmin}
              pathStack={pathStack}
              setPathStack={setPathStack}
              setNotification={setNotification}
              render={({ refetch }) => (
                <FileUploader
                  userId={userId}
                  isAdmin={isAdmin}
                  tierId={currentTierId}
                  currentPath={currentPath}
                  fullPath={fullPath}
                  refetch={refetch}
                />
              )}
            />
          </Card>
        </Box>
      </div>
    </>
  );
};

export default memo(FilePostCard);

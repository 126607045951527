import React from "react";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { capitalizeFirstLetter } from "../../helpers/utils";

function InvitationDetailsInfo({ invitation }) {
  const formattedFirstName =
    invitation?.sender && invitation?.sender?.firstName
      ? capitalizeFirstLetter(invitation?.sender?.firstName)
      : "";
  const formattedLastName =
    invitation?.sender && invitation?.sender?.lastName
      ? capitalizeFirstLetter(invitation?.sender?.lastName)
      : "";

  return (
    <Table size="small" aria-label="invitation details">
      <TableBody>
        <TableRow className="detail-row">
          <TableCell className="detail-label">Sender:</TableCell>
          <TableCell>{formattedFirstName} {formattedLastName}</TableCell>
        </TableRow>
        <TableRow className="detail-row">
          <TableCell className="detail-label">Sender Email:</TableCell>
          <TableCell>{invitation?.userEmail}</TableCell>
        </TableRow>
        <TableRow className="detail-row">
          <TableCell className="detail-label">Account Type:</TableCell>
          <TableCell>{invitation?.accountType}</TableCell>
        </TableRow>
        <TableRow className="detail-row">
          <TableCell className="detail-label">Tier:</TableCell>
          <TableCell>{invitation?.tier?.level}</TableCell>
        </TableRow>
        <TableRow className="detail-row">
          <TableCell className="detail-label">Invitation Token:</TableCell>
          <TableCell>{invitation?.invitationToken ? "Exists" : "Not available"}</TableCell>
        </TableRow>
        <TableRow className="detail-row">
          <TableCell className="detail-label">Status:</TableCell>
          <TableCell>{invitation?.status}</TableCell>
        </TableRow>
        <TableRow className="detail-row">
          <TableCell className="detail-label">Sent at:</TableCell>
          <TableCell>{new Date(invitation?.sentAt).toLocaleString()}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default InvitationDetailsInfo;

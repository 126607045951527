import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const fileApi = createApi({
  reducerPath: "fileApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/files/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Files"],
  endpoints: (builder) => ({
    getAllFiles: builder.query({
      query: ({ userId, tierId }) => `get-all-files/${tierId}/${userId}`,
      skip: (tierId) => !tierId || tierId === "undefined" || tierId === null,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Files", id })),
              { type: "Files", id: "LIST" },
            ]
          : [{ type: "Files", id: "LIST" }],
    }),

    uploadFile: builder.mutation({
      query: (formData) => ({
        url: "upload-files",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: [{ type: "Files", id: "LIST" }],
    }),

    downloadFile: builder.mutation({
      query: (fileId) => ({
        url: `download-file/${fileId}`,
        method: "GET",
      }),
    }),

    deleteFile: builder.mutation({
      query: (fileId) => ({
        url: `delete-file/${fileId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Files", id: "LIST" }],
    }),

    renameFile: builder.mutation({
      query: ({ fileId, newFileName, newFileDescription }) => ({
        url: `rename-file/${fileId}`,
        method: "PUT",
        body: { newFileName, newFileDescription },
      }),
      invalidatesTags: [{ type: "Files", id: "LIST" }],
    }),
    generatePresignedUrl: builder.query({
      query: (storageLocation) => ({
        url: `generate-presigned-url`,
        params: { storageLocation },
        method: "GET",
      }),
      skip: (storageLocation) => !storageLocation,
    }),
  }),
});
export const {
  useUploadFileMutation,
  useGetAllFilesQuery,
  useDownloadFileMutation,
  useRenameFileMutation,
  useDeleteFileMutation,
  useGeneratePresignedUrlQuery,
} = fileApi;

export default fileApi;

import React, { useState, useContext, useEffect, useMemo, memo } from "react";
import { titleCase } from "title-case";
import { useInviteMultipleMembersMutation } from "../../../services/invitation";
import { useGetAllTiersQuery } from "../../../services/tier";
import { useGetAllUserRoleNameEnumsQuery } from "../../../services/user-role-name-enum";
import { AuthContext } from "../../../context/AuthContext";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const InviteMultipleMembersDialog = ({ open, onClose, preselectedTier }) => {
  const { accountType } = useContext(AuthContext);
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [inviteMultipleMembers, { isLoading }] =
    useInviteMultipleMembersMutation();
  const { data: tiers } = useGetAllTiersQuery(accountType, {
    skip: accountType !== "reosadmin",
  });

  const { data: userRoleNameEnums } = useGetAllUserRoleNameEnumsQuery();
  const memberRowDirection = isSmallDevice ? "column" : "row";
  const [sendEmailToEveryone, setSendEmailToEveryone] = useState(false);
  // const [isEmailPrivate, setIsEmailPrivate] = useState(false);

  const [isEmailValid, setIsEmailValid] = useState(false);
  const [inputtiervalue, setInputtiervalue] = useState("");
  const [selectedTierId, setSelectedTierId] = useState(null);
  const [selectedTier, setSelectedTier] = useState(null);
  const [members, setMembers] = useState([
    {
      email: "",
      emailPublic: "",
      isEmailPrivate: false,
      firstName: "",
      lastName: "",
      imageUrl: "",
      officeExt: "",
      bio: "",
      website: "",
      phone: "",
      userRoleNameEnumId: "",
    },
  ]);
  const [notAdminUserRoleNameEnums, setNotAdminUserRoleNameEnums] = useState(
    []
  );

  useEffect(() => {
    if (tiers && preselectedTier) {
      const matchingTier = tiers.find(
        (tier) => tier._id === preselectedTier._id
      );
      if (matchingTier) {
        setSelectedTier(matchingTier);
      }
    }
  }, [tiers, preselectedTier]);

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (userRoleNameEnums) {
      const filteredUserRoleNameEnums = userRoleNameEnums?.filter(
        (userRoleNameEnum) => {
          return (
            userRoleNameEnum.userRoleName &&
            !userRoleNameEnum.userRoleName?.includes("reos")
          );
        }
      );

      setNotAdminUserRoleNameEnums(filteredUserRoleNameEnums);
    }
  }, [userRoleNameEnums]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const result = await inviteMultipleMembers({
      members,
      sendEmailToEveryone,
      tierId: preselectedTier ? preselectedTier?._id : selectedTierId?._id,
    });

    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        onClose();
      }, 2500);
    } else {
      setNotification({
        open: true,
        message: `Error: ${result?.error?.data?.msg}`,
      });
    }
  };

  useEffect(() => {
    if (members) {
      setIsEmailValid(
        members?.every((member) =>
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(member?.email)
        )
      );
    }
  }, [members]);

  const handleSendEmailToEveryone = (event) => {
    setSendEmailToEveryone(event.target.checked);
  };
  // const handleIsEmailPrivate = (event) => {
  //   setIsEmailPrivate(event.target.checked);
  // };

  const handleChange = (index, e) => {
    const { name, value, checked, type } = e.target;
    let newValue = type === "checkbox" ? checked : value;
    // Check if the input change is for the phone number
    if (name === "phone") {
      // Remove non-numeric characters
      newValue = value.replace(/\D/g, "");
    }
    const newMembers = [...members];
    newMembers[index][name] = newValue;
    setMembers(newMembers);
  };

  const handleAddMember = () => {
    const newMember = {
      email: "",
      emailPublic: "",
      isEmailPrivate: false,
      firstName: "",
      lastName: "",
      imageUrl: "",
      officeExt: "",
      bio: "",
      website: "",
      phone: "",
      userRoleNameEnumId: "",
    };
    setMembers([...members, newMember]);
  };

  const handleRemoveMember = (index) => {
    const newMembers = [...members];
    newMembers.splice(index, 1);
    setMembers(newMembers);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const sortOptions = (a, b) => {
    // First, compare by level
    const levelA = a.level.toUpperCase(); // case-insensitive comparison
    const levelB = b.level.toUpperCase();
    if (levelA !== levelB) {
      return levelA.localeCompare(levelB);
    }

    // If levels are the same, then sort by name
    const nameA = (
      a.brokerage?.officeName ||
      a.team?.name ||
      a.group?.name ||
      ""
    ).toUpperCase();
    const nameB = (
      b.brokerage?.officeName ||
      b.team?.name ||
      b.group?.name ||
      ""
    ).toUpperCase();
    return nameA.localeCompare(nameB);
  };
  const sortedOptions = useMemo(() => {
    return [...(tiers || [])].sort(sortOptions);
  }, [tiers]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle variant="h5">
        Invite New Member(s) - {selectedTier?.brokerage?.officeName}{" "}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1} direction="column">
            <Grid item>
              {preselectedTier ? (
                <TextField
                  label="Selected Tier"
                  variant="outlined"
                  margin="normal"
                  value={preselectedTier?.level}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ) : (
                <Autocomplete
                  value={selectedTierId}
                  onChange={(event, newValue) => {
                    setSelectedTierId(newValue);
                  }}
                  inputValue={inputtiervalue}
                  onInputChange={(event, newInputValue) => {
                    setInputtiervalue(newInputValue);
                  }}
                  options={sortedOptions}
                  getOptionLabel={(option) =>
                    option
                      ? `${titleCase(option?.level)} ${
                          option?.brokerage || option?.team || option?.group
                            ? option?.brokerage?.officeName ||
                              option?.team?.name ||
                              option?.group?.name
                            : ""
                        } ${
                          option?.level === "system" ||
                          option?.level === "global"
                            ? " - REACirc"
                            : ""
                        } ${
                          option?._id === option?.brokerage?.leadBrokerageTier
                            ? " - (Lead Brokerage Office)"
                            : ""
                        } ${
                          option?._id === option?.brokerage?.tier
                            ? " - (Office)"
                            : ""
                        }`
                      : ""
                  }
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select a Tier"
                      variant="outlined"
                      margin="normal"
                      required
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sendEmailToEveryone}
                    onChange={handleSendEmailToEveryone}
                    name="sendEmailToEveryone"
                    color="primary"
                  />
                }
                label="Send Email To Everyone"
              />
            </Grid>

            {members?.map((member, index) => (
              <div key={index}>
                <Divider variant="fullWidth">
                  {`${member?.firstName || "Member "} ${
                    member?.lastName || "Name"
                  }`}
                </Divider>
                <Grid container spacing={2} direction={memberRowDirection}>
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      value={
                        members[index].userRoleNameEnumId
                          ? notAdminUserRoleNameEnums.find(
                              (role) =>
                                role._id === members[index].userRoleNameEnumId
                            )
                          : null
                      }
                      onChange={(event, newValue) => {
                        handleChange(index, {
                          target: {
                            name: "userRoleNameEnumId",
                            value: newValue?._id,
                          },
                        });
                      }}
                      options={notAdminUserRoleNameEnums || []}
                      getOptionLabel={(option) =>
                        option ? `${option.userRoleName || ""}` : ""
                      }
                      isOptionEqualToValue={(option, value) =>
                        option?._id === value._id
                      }
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select a Member Role"
                          variant="outlined"
                          margin="normal"
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      variant="outlined"
                      margin="normal"
                      value={member?.email || ""}
                      onChange={(e) => handleChange(index, e)}
                      required
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={member.isEmailPrivate}
                          onChange={(e) =>
                            handleChange(index, {
                              target: {
                                name: "isEmailPrivate",
                                value: e.target.checked,
                              },
                            })
                          }
                          name="isEmailPrivate"
                          color="primary"
                        />
                      }
                      label="Keep Email Private"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {member?.isEmailPrivate && (
                      <TextField
                        fullWidth
                        label="Public Email"
                        name="emailPublic"
                        variant="outlined"
                        margin="normal"
                        value={member?.emailPublic || ""}
                        onChange={(e) => handleChange(index, e)}
                        required
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="First Name"
                      name="firstName"
                      variant="outlined"
                      margin="normal"
                      value={member?.firstName || ""}
                      onChange={(e) => handleChange(index, e)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      name="lastName"
                      variant="outlined"
                      margin="normal"
                      value={member?.lastName || ""}
                      onChange={(e) => handleChange(index, e)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      fullWidth
                      label="Image Url"
                      name="imageUrl"
                      variant="outlined"
                      margin="normal"
                      value={member?.imageUrl || ""}
                      onChange={(e) => handleChange(index, e)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      label="Office Extension"
                      name="officeExt"
                      variant="outlined"
                      margin="normal"
                      value={member?.officeExt || ""}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label="Bio"
                      name="bio"
                      variant="outlined"
                      margin="normal"
                      value={member?.bio || ""}
                      onChange={(e) => handleChange(index, e)}
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Website"
                      name="website"
                      variant="outlined"
                      margin="normal"
                      value={member?.website || ""}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Phone"
                      name="phone"
                      variant="outlined"
                      margin="normal"
                      value={member?.phone || ""}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Button
                      onClick={() => handleRemoveMember(index)}
                      color="secondary"
                    >
                      Remove
                    </Button>
                  </Grid>
                </Grid>
                <Divider variant="fullWidth" />
              </div>
            ))}
            <Button onClick={handleAddMember} color="primary">
              Add Another Member
            </Button>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={!isEmailValid || isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : "Invite/Signup Members"}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default memo(InviteMultipleMembersDialog);

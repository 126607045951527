import React from "react";
import { SvgIcon } from "@mui/material";
import "./rlpIconStyles.css"; // make sure to import the CSS

export function RLPIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 923.33 290.67">
      <polygon
        className="cls-1"
        points="452.33 0 0 0 0 67.67 452.33 67.67 452.33 0"
      />
      <polygon
        className="cls-2"
        points="914.67 0 914.67 67.67 923.33 67.67 923.33 0 914.67 0"
      />
      <polygon
        className="cls-2"
        points="474.67 0 474.67 67.67 483.33 67.67 483.33 0 474.67 0"
      />
      <polygon
        className="cls-2"
        points="495.67 0 495.67 67.67 504.33 67.67 504.33 0 495.67 0"
      />
      <polygon
        className="cls-2"
        points="516.67 0 516.67 67.67 525.33 67.67 525.33 0 516.67 0"
      />
      <polygon
        className="cls-2"
        points="537.67 0 537.67 67.67 546.33 67.67 546.33 0 537.67 0"
      />
      <polygon
        className="cls-2"
        points="558.67 0 558.67 67.67 567 67.67 567 0 558.67 0"
      />
      <polygon
        className="cls-2"
        points="579.67 0 579.67 67.67 588 67.67 588 0 579.67 0"
      />
      <polygon
        className="cls-2"
        points="600.33 0 600.33 67.67 609 67.67 609 0 600.33 0"
      />
      <polygon
        className="cls-2"
        points="621.33 0 621.33 67.67 630 67.67 630 0 621.33 0"
      />
      <polygon
        className="cls-2"
        points="642.33 0 642.33 67.67 651 67.67 651 0 642.33 0"
      />
      <polygon
        className="cls-2"
        points="663.33 0 663.33 67.67 672 67.67 672 0 663.33 0"
      />
      <polygon
        className="cls-2"
        points="684.33 0 684.33 67.67 692.67 67.67 692.67 0 684.33 0"
      />
      <polygon
        className="cls-2"
        points="705.33 0 705.33 67.67 713.67 67.67 713.67 0 705.33 0"
      />
      <polygon
        className="cls-2"
        points="726 0 726 67.67 734.67 67.67 734.67 0 726 0"
      />
      <polygon
        className="cls-2"
        points="747 0 747 67.67 755.67 67.67 755.67 0 747 0"
      />
      <polygon
        className="cls-2"
        points="768 0 768 67.67 776.67 67.67 776.67 0 768 0"
      />
      <polygon
        className="cls-2"
        points="789 0 789 67.67 797.67 67.67 797.67 0 789 0"
      />
      <polygon
        className="cls-2"
        points="810 0 810 67.67 818.33 67.67 818.33 0 810 0"
      />
      <polygon
        className="cls-2"
        points="830.67 0 830.67 67.67 839.33 67.67 839.33 0 830.67 0"
      />
      <polygon
        className="cls-2"
        points="851.67 0 851.67 67.67 860.33 67.67 860.33 0 851.67 0"
      />
      <polygon
        className="cls-2"
        points="872.67 0 872.67 67.67 881.33 67.67 881.33 0 872.67 0"
      />
      <polygon
        className="cls-2"
        points="893.67 0 893.67 67.67 902.33 67.67 902.33 0 893.67 0"
      />
      <polygon
        className="cls-1"
        points="452.33 223 0 223 0 290.67 452.33 290.67 452.33 223"
      />
      <polygon
        className="cls-2"
        points="914.67 223 914.67 290.67 923.33 290.67 923.33 223 914.67 223"
      />
      <polygon
        className="cls-2"
        points="474.67 223 474.67 290.67 483.33 290.67 483.33 223 474.67 223"
      />
      <polygon
        className="cls-2"
        points="495.67 223 495.67 290.67 504.33 290.67 504.33 223 495.67 223"
      />
      <polygon
        className="cls-2"
        points="516.67 223 516.67 290.67 525.33 290.67 525.33 223 516.67 223"
      />
      <polygon
        className="cls-2"
        points="537.67 223 537.67 290.67 546.33 290.67 546.33 223 537.67 223"
      />
      <polygon
        className="cls-2"
        points="558.67 223 558.67 290.67 567 290.67 567 223 558.67 223"
      />
      <polygon
        className="cls-2"
        points="579.67 223 579.67 290.67 588 290.67 588 223 579.67 223"
      />
      <polygon
        className="cls-2"
        points="600.33 223 600.33 290.67 609 290.67 609 223 600.33 223"
      />
      <polygon
        className="cls-2"
        points="621.33 223 621.33 290.67 630 290.67 630 223 621.33 223"
      />
      <polygon
        className="cls-2"
        points="642.33 223 642.33 290.67 651 290.67 651 223 642.33 223"
      />
      <polygon
        className="cls-2"
        points="663.33 223 663.33 290.67 672 290.67 672 223 663.33 223"
      />
      <polygon
        className="cls-2"
        points="684.33 223 684.33 290.67 692.67 290.67 692.67 223 684.33 223"
      />
      <polygon
        className="cls-2"
        points="705.33 223 705.33 290.67 713.67 290.67 713.67 223 705.33 223"
      />
      <polygon
        className="cls-2"
        points="726 223 726 290.67 734.67 290.67 734.67 223 726 223"
      />
      <polygon
        className="cls-2"
        points="747 223 747 290.67 755.67 290.67 755.67 223 747 223"
      />
      <polygon
        className="cls-2"
        points="768 223 768 290.67 776.67 290.67 776.67 223 768 223"
      />
      <polygon
        className="cls-2"
        points="789 223 789 290.67 797.67 290.67 797.67 223 789 223"
      />
      <polygon
        className="cls-2"
        points="810 223 810 290.67 818.33 290.67 818.33 223 810 223"
      />
      <polygon
        className="cls-2"
        points="830.67 223 830.67 290.67 839.33 290.67 839.33 223 830.67 223"
      />
      <polygon
        className="cls-2"
        points="851.67 223 851.67 290.67 860.33 290.67 860.33 223 851.67 223"
      />
      <polygon
        className="cls-2"
        points="872.67 223 872.67 290.67 881.33 290.67 881.33 223 872.67 223"
      />
      <polygon
        className="cls-2"
        points="893.67 223 893.67 290.67 902.33 290.67 902.33 223 893.67 223"
      />
      <path
        className="cls-2"
        d="m21,139h11c5.67,0,11.33-2.33,11.33-9.33,0-6-4-9.33-11.67-9.33h-10.33v18.67h-.33Zm16.33-33.67c17,0,27,11.33,27,23.67,0,9-4.67,16-14,20,8.33,4.33,11,14.33,22.67,38.67h-22.67c-8.67-17.67-12-33.33-23.33-33.33h-6v33.33H.33v-82.33h37Z"
      />
      <path
        className="cls-2"
        d="m121.67,121.33c-15.67,0-26,10-26,25s10.33,25,26,25,26-10,26-25-10.33-25-26-25h0Zm0,67.33c-30.33,0-47.33-21.33-47.33-42.33s16-42.33,47.33-42.33,47,22.33,47,42.33c0,21.33-17.33,42.33-47,42.33h0Z"
      />
      <polygon
        className="cls-2"
        points="197.67 149 165 105.33 190 105.33 208 131 226 105.33 250.67 105.33 218 149 218 187.67 197.67 187.67 197.67 149"
      />
      <path
        className="cls-2"
        d="m280.67,125l-12.33,32h25.33l-13-32h0Zm48,62.67h-22l-6.67-16.33h-37l-6,16.33h-22l33.33-82.33h23.67l36.67,82.33h0Z"
      />
      <polygon
        className="cls-2"
        points="402.67 187.67 339.67 187.67 339.67 105.33 360.33 105.33 360.33 172.33 402.67 172.33 402.67 187.67"
      />
      <polygon
        className="cls-2"
        points="503 187.67 440 187.67 440 105.33 460.67 105.33 460.67 172.33 503 172.33 503 187.67"
      />
      <polygon
        className="cls-2"
        points="563.33 187.67 515.33 187.67 515.33 122.33 562.67 122.33 562.67 134.33 531.33 134.33 531.33 148 561.33 148 561.33 160 531.33 160 531.33 175.67 563.33 175.67 563.33 187.67"
      />
      <path
        className="cls-2"
        d="m599.33,139.33h9.67c9.33,0,12.67-3.33,12.67-9.67,0-7-4-9.67-15-9.67h-7.33v19.33h0Zm16-34c18.67,0,27.33,13.33,27.33,24.33s-8.33,25-27,25h-16.33v33h-20.67v-82.33h36.67Z"
      />
      <path
        className="cls-2"
        d="m680.33,125l-12,32h25.33l-13.33-32h0Zm48,62.67h-22l-6.67-16.33h-37l-6.33,16.33h-21.67l33.33-82.33h23.67l36.67,82.33h0Z"
      />
      <path
        className="cls-2"
        d="m810,129c-10.33-5-20.67-7.67-30.67-7.67-22.33,0-30.33,13.67-30.33,26,0,14.67,11.33,24.33,28.67,24.33,5,0,7.67-.67,13.67-3v-9.67h-12v-15h32.67v33.67c-6.33,6.33-21.33,11-34.67,11-28.67,0-49.33-17.67-49.33-42.33s20.33-42.33,49.67-42.33c12.33,0,24.33,2.67,32.67,6.67v18.33h-.33Z"
      />
      <polygon
        className="cls-2"
        points="887 187.67 826.33 187.67 826.33 105.33 887 105.33 887 120.33 846.67 120.33 846.67 137.67 884.33 137.67 884.33 152.67 846.67 152.67 846.67 172.33 887 172.33 887 187.67"
      />
      <path
        className="cls-2"
        d="m893.67,114c0-2.67.67-5.33,2-7.67,1.33-2.33,3-4.33,5.33-5.67,2.33-1.33,4.67-2,7.33-2s5,.67,7.33,2c2.33,1.33,4,3.33,5.33,5.67,1.33,2.33,2,5,2,7.67s-.67,5.33-2,7.67c-1.33,2.33-3,4.33-5.33,5.67-2.33,1.33-4.67,2-7.33,2s-5-.67-7.33-2c-2.33-1.33-4-3.33-5.33-5.67-1.33-2-2-4.67-2-7.67h0Zm2.67,0c0,2.33.33,4.33,1.67,6.33,1,2,2.67,3.67,4.33,4.67,1.67,1,4,1.67,6.33,1.67s4.33-.67,6.33-1.67c2-1,3.33-2.67,4.33-4.67,1-2,1.67-4,1.67-6.33s-.67-4.33-1.67-6.33c-1-2-2.67-3.67-4.33-4.67-2-1-4-1.67-6.33-1.67s-4.33.67-6,1.67c-1.67,1-3.33,2.67-4.33,4.67s-2,4.33-2,6.33h0Zm9.33,1.67v7h-3v-17.67h6c2,0,3.67.67,5,1.33,1.33,1,1.67,2.33,1.67,4s-.67,2.67-2.33,3.67c.67.33,1.33,1,1.67,1.67s.33,1.67.33,3v2.33c0,.33,0,1,.33,1v.33h-3.33c0-.33-.33-1.67-.33-4,0-1-.33-1.67-.67-2.33-.33-.33-1.33-.67-2.33-.67l-3,.33h0Zm0-2.67h2.67c1,0,2-.33,2.67-.67.67-.33,1-1,1-1.67,0-1-.33-1.67-.67-2-.33-.33-1.67-.67-3-.67h-3v5h.33Z"
      />
    </SvgIcon>
  );
}

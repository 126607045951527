import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const dataEntryLogsApi = createApi({
  reducerPath: "data-entry-log",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/data-entry-logs/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["DataEntryLog"],
  endpoints: (builder) => ({
    getAllDataEntryLogs: builder.query({
      query: ({ accountType, page = 1, limit = 10 }) => ({
        url: `get-all?page=${page}&limit=${limit}`,
        method: "GET",
      }),

      skip: (accountType) => accountType !== "reosadmin",
      providesTags: (result, error, { page }) => [
        { type: "DataEntryLog", id: "LIST" },
        { type: "DataEntryLog", id: `page-${page}` },
      ],
    }),
  }),
});

export const { useGetAllDataEntryLogsQuery } = dataEntryLogsApi;

export default dataEntryLogsApi;
export { dataEntryLogsApi };

import React, { useState, memo } from "react";
import {
  Grid,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { useEditReplyMutation } from "../../services/post";
import NotificationSnackbar from "../helpers/notification-snackbar";
import { modules } from "../helpers/utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function EditReply({ open, onClose, reply, refetchPosts }) {
  const [editorContent, setEditorContent] = useState(reply?.content);
  const [editReply, { isLoading }] = useEditReplyMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const handleEditSubmit = async () => {
    if (!editorContent?.trim()) {
      setNotification({
        open: true,
        message: "Please fill the content field.",
      });
      return;
    }

    const result = await editReply({
      replyId: reply?._id,
      content: editorContent,
    });

    if (result.data) {
      setNotification({
        open: true,
        message: "Reply edited successfully.",
      });
      setTimeout(() => {
        refetchPosts?.();
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error editing reply: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth className="edit-comment">
      <DialogTitle>
        <Typography>Edit Reply</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          className="edit-comment-form"
          spacing={3}
          direction="column"
        >
          <Grid item>
            <ReactQuill
              value={editorContent}
              onChange={handleEditorChange}
              modules={modules}
              placeholder="Enter your reply content below..."
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Grid item>
            <Button
              onClick={onClose}
              disabled={isLoading}
              color="primary"
              type="submit"
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleEditSubmit}
              disabled={isLoading}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
            >
              {isLoading ? <CircularProgress size={24} /> : "Save"}
            </Button>
          </Grid>
        </DialogActions>
      </DialogContent>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
}

export default memo(EditReply);

import React, { useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Snackbar,
  Grid,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import PaymentCard from "./paymentCard";
import ConfirmationDialog from "../../helpers/ConfirmationDialog";
import {
  useCreateStripeCustomerMutation,
  useAttachPaymentMethodMutation,
  useFetchPaymentMethodsQuery,
  useSetActivePaymentMethodMutation,
  useDeletePaymentMethodMutation,
} from "../../../services/billing/payment";
import { useFetchUserDetailsQuery } from "../../../services/user";
import { useGetTierByIdQuery } from "../../../services/tier";

const BillingSetup = ({ tierId, accountId, sidebarOpen }) => {
  const [isAddNewPaymentDialogOpen, setIsAddNewPaymentDialogOpen] =
    useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [addPaymentMethodActive, setAddPaymentMethodActive] = useState(false);
  const [stripeIsSetup, setStripeIsSetup] = useState(false);
  const [activeStripePaymentMethodId, setActiveStripePaymentMethodId] =
    useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [paymentMethodIdToDelete, setPaymentMethodIdToDelete] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const [createStripeCustomer] = useCreateStripeCustomerMutation();
  const [attachPaymentMethod] = useAttachPaymentMethodMutation();
  const [setActivePaymentMethod] = useSetActivePaymentMethodMutation();
  const [deletePaymentMethod] = useDeletePaymentMethodMutation();

  // Only fetch tier data if tierId is defined
  const {
    data: tier,
    error: tierError,
    isFetching: isFetchingTier,
  } = useGetTierByIdQuery(tierId, {
    skip: !tierId,
  });

  const {
    data: account,
    isLoading,
    isError,
    error,
    refetch,
  } = useFetchUserDetailsQuery(accountId);

  // Fetch payment methods when stripeIsSetup is set
  const {
    data: paymentMethodsResponse,
    refetch: paymentMethodsRefetch,
    error: fetchError,
  } = useFetchPaymentMethodsQuery(
    {
      brokerageId: tier?.brokerage?._id,
      accountId,
    },
    { skip: !stripeIsSetup || (!tierId && !accountId) }
  );

  useEffect(() => {
    let scid = null;
    let paymentMethod = null;
    let billingEmail = null;
    let name = null;
    let phone = null;

    if (account) {
      scid = account.stripeCustomerId;
      billingEmail = account.email;
      name = account.firstName + " " + account.lastName;
      phone = account.member?.phone;
      paymentMethod = account.stripePaymentMethodId;
    }

    if (tier) {
      scid = tier.brokerage?.stripeIsSetup;
      billingEmail = tier.brokerage?.billingEmail;
      name = tier.brokerage?.officeName;
      phone = tier.brokerage?.phoneNumbers[0];
      paymentMethod = tier.brokerage?.stripePaymentMethodId;
    }

    if (!billingEmail) {
      setAddPaymentMethodActive(false);
      return;
    }

    if (!scid) {
      const createCustomer = async () => {
        try {
          const response = await createStripeCustomer({
            email: billingEmail,
            name: name,
            phone: phone,
            brokerageId: tier?.brokerage?._id,
            accountId: account?._id,
          }).unwrap();

          setStripeIsSetup(true);
        } catch (error) {
          setSnackbarMessage("Failed to create Stripe customer.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      };
      createCustomer();
    } else {
      setStripeIsSetup(scid);
      setActiveStripePaymentMethodId(paymentMethod);
    }
    setAddPaymentMethodActive(true);
  }, [tier, createStripeCustomer, account, setActiveStripePaymentMethodId]);

  useEffect(() => {
    if (paymentMethodsResponse) {
      setPaymentMethods(paymentMethodsResponse.paymentMethods || []);
      setPaymentMethods(paymentMethodsResponse);
    }
  }, [paymentMethodsResponse, fetchError]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripeIsSetup) {
      setSnackbarMessage("Stripe customer ID is not set.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    if (!stripe || !elements) {
      setSnackbarMessage("Stripe.js has not yet loaded.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      setSnackbarMessage(`Error: ${error.message}`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    try {
      await attachPaymentMethod({
        paymentMethodId: paymentMethod.id,
        brokerageId: tier?.brokerage?._id,
        accountId: account?._id,
      }).unwrap();

      setSnackbarMessage("Payment method attached successfully.");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setIsAddNewPaymentDialogOpen(false);
      paymentMethodsRefetch();
    } catch (error) {
      setSnackbarMessage(`Failed to attach payment method.${error}`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSetActive = async (paymentMethodId) => {
    try {
      await setActivePaymentMethod({
        brokerageId: tier?.brokerage?._id,
        accountId: account?._id,
        paymentMethodId,
      }).unwrap();

      setActiveStripePaymentMethodId(paymentMethodId);

      setSnackbarMessage("Payment method set as active.");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Failed to set payment method as active.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  const handleDeleteClick = (paymentMethodId) => {
    setPaymentMethodIdToDelete(paymentMethodId);
    setConfirmationDialogOpen(true);
  };

  const handleDeletePaymentMethod = async (paymentMethodId) => {
    try {
      await deletePaymentMethod({
        customerId: stripeIsSetup,
        paymentMethodId,
      }).unwrap();

      // Remove deleted payment method from the list
      setPaymentMethods((prevMethods) =>
        prevMethods.filter((method) => method.id !== paymentMethodId)
      );

      setSnackbarMessage("Payment method deleted successfully.");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      paymentMethodsRefetch();
    } catch (error) {
      setSnackbarMessage("Failed to delete payment method.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };
  const handleDelete = () => {
    handleDeletePaymentMethod(paymentMethodIdToDelete);
    setConfirmationDialogOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsAddNewPaymentDialogOpen(true)}
        disabled={!addPaymentMethodActive}
        sx={{ mb: 2 }}
      >
        Add Payment Method
      </Button>
      {!addPaymentMethodActive && (
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ textAlign: "center" }}
        >
          Please set billing email in Brokerage Details to add a payment method.
        </Typography>
      )}

      <Grid container spacing={2}>
        {paymentMethods?.length > 0 ? (
          paymentMethods.map((method, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={sidebarOpen ? 12 : 4}
              key={index}
            >
              <PaymentCard
                method={method}
                activeStripePaymentMethodId={activeStripePaymentMethodId}
                handleSetActive={handleSetActive}
                handleDelete={handleDeleteClick}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography
              sx={{ m: 2, textAlign: "center" }}
              variant="body1"
              color="textSecondary"
            >
              No payment methods found.
            </Typography>
          </Grid>
        )}
      </Grid>

      <Dialog
        open={isAddNewPaymentDialogOpen}
        onClose={() => setIsAddNewPaymentDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add a New Payment Method</DialogTitle>
        <DialogContent>
          {stripeIsSetup ? (
            <form
              onSubmit={handleSubmit}
              style={{ display: "flex", flexDirection: "column", gap: "16px" }}
            >
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!stripe}
                style={{ alignSelf: "flex-start" }}
              >
                Save Payment Method
              </Button>
            </form>
          ) : (
            <Typography variant="body1" color="textSecondary">
              Creating Stripe customer account...
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsAddNewPaymentDialogOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleDelete}
        title="Delete Payment Method"
        description="Are you sure you want to delete this payment method?"
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {" "}
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BillingSetup;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const reosadminApi = createApi({
  reducerPath: "reosadmin",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/reosadmins/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Reosadmin"],
  endpoints: (builder) => ({
    getAllReosAdminUserProfiles: builder.query({
      query: () => ({
        url: "reosadmin-user-profiles",
        method: "GET",
      }),
    }),
    getAllReosAdminProfiles: builder.query({
      query: (accountType) => ({
        url: "reosadmin-profiles",
        method: "GET",
      }),
      skip: (accountType) => !accountType,
    }),
  }),
});

export const {
  useGetAllReosAdminUserProfilesQuery,
  useGetAllReosAdminProfilesQuery,
} = reosadminApi;

export default reosadminApi;
export { reosadminApi };

const stopWords = [
  "i",
  "me",
  "my",
  "myself",
  "we",
  "our",
  "ours",
  "ourselves",
  "you",
  "your",
  "yours",
  "yourself",
  "yourselves",
  "he",
  "him",
  "his",
  "himself",
  "she",
  "her",
  "hers",
  "herself",
  "it",
  "its",
  "itself",
  "they",
  "them",
  "their",
  "theirs",
  "themselves",
  "what",
  "which",
  "who",
  "whom",
  "this",
  "that",
  "these",
  "those",
  "am",
  "is",
  "are",
  "was",
  "were",
  "be",
  "been",
  "being",
  "have",
  "has",
  "had",
  "having",
  "do",
  "does",
  "did",
  "doing",
  "a",
  "an",
  "the",
  "and",
  "but",
  "if",
  "or",
  "because",
  "as",
  "until",
  "while",
  "of",
  "at",
  "by",
  "for",
  "with",
  "about",
  "against",
  "between",
  "into",
  "through",
  "during",
  "before",
  "after",
  "above",
  "below",
  "to",
  "from",
  "up",
  "down",
  "in",
  "out",
  "on",
  "off",
  "over",
  "under",
  "again",
  "further",
  "then",
  "once",
  "here",
  "there",
  "when",
  "where",
  "why",
  "how",
  "all",
  "any",
  "both",
  "each",
  "few",
  "more",
  "most",
  "other",
  "some",
  "such",
  "no",
  "nor",
  "not",
  "only",
  "own",
  "same",
  "so",
  "than",
  "too",
  "very",
  "s",
  "t",
  "can",
  "will",
  "just",
  "don",
  "should",
  "now",
  "http",
  "hi",
  "note",
  "time",
  "pre",
  "post",
  "amhello",
  "let",
  "shares",
  "mean",
  "means",
  "world",
  "works",
  "hello",
  "ask",
  "clients",
  "life",
  "people",
  "would",
  "say",
  "economy",
  "interest",
  "amgood",
  "things",
  "thing",
  "morning",
  "happy",
  "new",
  "year",
  "ninja",
  "nation",
  "years",
  "falls",
  "take",
  "little",
  "makes",
  "fall",
  "seen",
  "day",
  "month",
  "period",
  "heard",
  "good",
  "coming",
  "centered",
  "around",
  "generally",
  "last",
  "week",
  "suggested",
  "long",
  "relative",
  "visiting",
  "including",
  "include",
  "also",
  "known",
  "learned",
  "points",
  "knowning",
  "allow",
  "hear",
  "allows",
  "sounds",
  "great",
  "thank",
  "usual",
  "today",
  "larry",
  "look",
  "kendall",
  "minute",
  "hour",
  "second",
  "way",
  "loud",
  "necessity",
  "drum",
  "beaten",
  "use",
  "sentence",
  "puts",
  "really",
  "simple",
  "pretty",
  "similar",
  "getting",
  "sitting",
  "mid",
  "turned",
  "half",
  "appropriate",
  "first",
  "choose",
  "right",
  "hard",
  "deny",
  "normally",
  "give",
  "think",
  "tie",
  "believe",
  "actually",
  "one",
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
  "ten",
  "ago",
  "exact",
  "exactly",
  "used",
  "tell",
  "weekly",
  "explore",
  "explored",
  "gotten",
  "couple",
  "full",
  "always",
  "quite",
  "runway",
  "say",
  "says",
  "big",
  "talk",
  "talked",
  "look",
  "looked",
  "fabulous",
  "expect",
  "wow",
  "totally",
  "unexpected",
  "best",
  "high",
  "happen",
  "tags",
  "sourcecategory",
  "happen",
  "stay",
  "cool",
  "posted",
  "word",
  "stay",




  "ninjas",
  // Days of the week
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
  // Months of the year
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
  "each",
  "either",
  "enough",
  "especially",
  "even",
  "ever",
  "every",
  "everybody",
  "everyone",
  "everything",
  "everywhere",
  "except",
  "few",
  "finally",
  "for",
  "from",
  "further",
  "furthermore",
  "get",
  "go",
  "had",
  "has",
  "have",
  "he",
  "hence",
  "her",
  "here",
  "hers",
  "herself",
  "him",
  "himself",
  "his",
  "how",
  "however",
  "i",
  "if",
  "in",
  "indeed",
  "into",
  "is",
  "it",
  "its",
  "itself",
  "just",
  "like",
  "make",
  "many",
  "may",
  "me",
  "meanwhile",
  "might",
  "mine",
  "more",
  "moreover",
  "most",
  "much",
  "must",
  "my",
  "myself",
  "namely",
  "neither",
  "never",
  "nevertheless",
  "next",
  "no",
  "nobody",
  "none",
  "nor",
  "not",
  "nothing",
  "now",
  "nowhere",
  "of",
  "off",
  "often",
  "on",
  "once",
  "one",
  "only",
  "onto",
  "or",
  "other",
  "others",
  "otherwise",
  "our",
  "ours",
  "ourselves",
  "out",
  "over",
  "own",
  "per",
  "perhaps",
  "rather",
  "re",
  "same",
  "see",
  "seem",
  "seemed",
  "seeming",
  "seems",
  "she",
  "should",
  "since",
  "so",
  "some",
  "somebody",
  "somehow",
  "someone",
  "something",
  "sometime",
  "sometimes",
  "somewhat",
  "somewhere",
  "such",
  "than",
  "that",
  "the",
  "their",
  "theirs",
  "them",
  "themselves",
  "then",
  "thence",
  "there",
  "thereafter",
  "thereby",
  "therefore",
  "therein",
  "thereupon",
  "these",
  "they",
  "this",
  "those",
  "though",
  "through",
  "throughout",
  "thru",
  "thus",
  "to",
  "together",
  "too",
  "toward",
  "towards",
  "under",
  "until",
  "up",
  "upon",
  "us",
  "very",
  "was",
  "we",
  "well",
  "were",
  "what",
  "whatever",
  "when",
  "whence",
  "whenever",
  "where",
  "whereafter",
  "whereas",
  "whereby",
  "wherein",
  "whereupon",
  "wherever",
  "whether",
  "which",
  "while",
  "whither",
  "who",
  "whoever",
  "whole",
  "whom",
  "whose",
  "why",
  "will",
  "with",
  "within",
  "llc",
  "ca",
  "mon",
  "tue",
  "wed",
  "thu",
  "fri",
  "sat",
  "sun",
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
  "submitting",
  "pmhttps",
  "www",
  "please",
  "attached",
  "find",
  "help",
  "agree",
  "using",
  "need",
  "know",
  "provides",
];

export const generateTags = (title = "", content = "") => {
  const cleanText = (text) => {
    if (typeof text !== "string") {
      console.error("Expected a string for cleanText, got: ", text);
      return [];
    }
    return text
      .replace(/<\/?[^>]+(>|$)/g, "") // Remove HTML tags
      .replace(/&nbsp;/gi, " ") // Replace non-breaking spaces with regular spaces
      .replace(/&amp;/gi, "&") // Replace &amp; with &
      .replace(/&lt;/gi, "<") // Replace &lt; with <
      .replace(/&gt;/gi, ">") // Replace &gt; with >
      .replace(/&quot;/gi, '"') // Replace &quot; with "
      .replace(/&#39;/g, "'") // Replace &#39; with '
      .replace(/\d+/g, "") // Remove all numbers
      .toLowerCase(); // Convert text to lowercase
  };
  const tokenize = (text) => {
    // const cleanedText = cleanText(text);
    return cleanText(text).match(/\b(\w+)\b/g) || [];
  };

  const titleTokens = tokenize(title);
  const contentTokens = tokenize(content);

  const allTokens = [...new Set([...titleTokens, ...contentTokens])];
  const filteredTokens = allTokens.filter(
    (token) => !stopWords.includes(token) && token.length > 1
  );

  const frequencyMap = {};
  filteredTokens.forEach((token) => {
    frequencyMap[token] = (frequencyMap[token] || 0) + 1;
  });

  const sortedTokens = Object.entries(frequencyMap)
    .sort((a, b) => b[1] - a[1])
    .map((entry) => entry[0]);

  return sortedTokens.slice(0, 10);
};

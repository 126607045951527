import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { useAssignTeamRoleMutation } from "../../../../services/team-role";
import NotificationSnackbar from "../../../helpers/notification-snackbar";

const AssignTeamRoleDialog = ({
  teamId,
  open,
  onClose,
  preselectedTeamRole,
  preselectedTeamMember,
  teamRoles,
  teamMembers,
  action = "",
}) => {
  const [selectedTeamRoleId, setSelectedTeamRoleId] = useState(
    action === "thisRoleToMember" && preselectedTeamRole
      ? preselectedTeamRole?._id
      : ""
  );
  const [selectedMemberId, setSelectedMemberId] = useState(
    action === "thisMemberToTeamRole" && preselectedTeamMember
      ? preselectedTeamMember
      : ""
  );
  const [assignTeamRole, { isLoading }] = useAssignTeamRoleMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (action === "thisRoleToMember" && preselectedTeamRole) {
      setSelectedTeamRoleId(preselectedTeamRole?._id);
    }
  }, [action, preselectedTeamRole]);

  const handleAssign = async () => {
    if (!selectedTeamRoleId || !selectedMemberId) {
      setNotification({
        openNotification: true,
        message: "Please, choose the team role and/or select the member.",
      });

      return;
    }

    const result = await assignTeamRole({
      teamId,
      memberId: selectedMemberId,
      teamRoleId: selectedTeamRoleId,
    }).unwrap();
    console.log(result);
    if (result) {
      setNotification({
        openNotification: true,
        message: result.msg,
      });
      setTimeout(() => {
        setSelectedTeamRoleId("");
        setSelectedMemberId("");
        onClose();
      }, 1500);
    } else {
      setNotification({
        openNotification: true,
        message: `Error assigning the team role: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, openNotification: false });
  };

  const isTeamRoleId = selectedTeamRoleId || preselectedTeamRole;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Assign Team Role</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel id="member-label">Member</InputLabel>
          <Select
            labelId="member-label"
            id="selectedMemberId"
            value={selectedMemberId}
            onChange={(e) => setSelectedMemberId(e.target.value)}
            fullWidth
            required
          >
            {teamMembers &&
              teamMembers.length > 0 &&
              teamMembers.map((teamMember) => (
                <MenuItem key={teamMember?._id} value={teamMember?.member?._id}>
                  {teamMember?.member?.user?.firstName}{" "}
                  {teamMember?.member?.user?.lastName}{" "}
                  {teamMember?.member?.user?.email}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {preselectedTeamRole ? (
          <TextField
            fullWidth
            label="Selected Team Role"
            variant="outlined"
            margin="normal"
            value={`${preselectedTeamRole?.name}`}
            InputProps={{
              readOnly: true,
            }}
          />
        ) : (
          <FormControl fullWidth>
            <InputLabel>Role</InputLabel>
            <Select
              value={selectedTeamRoleId}
              label="Role"
              onChange={(e) => setSelectedTeamRoleId(e.target.value)}
              fullWidth
              required
            >
              {teamRoles &&
                teamRoles.length > 0 &&
                teamRoles?.map((teamRole) => (
                  <MenuItem key={teamRole?._id} value={teamRole?._id}>
                    {teamRole?.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleAssign}
          disabled={isLoading || !isTeamRoleId || !selectedMemberId}
        >
          {isLoading ? "Assigning..." : "Assign"}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default AssignTeamRoleDialog;

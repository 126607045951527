import React, { useState } from "react";
import { useGetDutyScheduleOfficeLocationsByTierQuery } from "../../../../services/duty-schedule";
import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { daysOfWeek } from "../../../helpers/utils";
import TimeSlotSelector from "../../../account-profiles/TimeSlotSelector";
import UnavailablePeriodSelector from "../../../account-profiles/UnavailablePeriodSelector";

function MembersDutyPreferencesForm({
  selectedMembersUserIds = [],
  tierId,
  onSubmit,
}) {
  const { data: dutyScheduleOfficeLocations } =
    useGetDutyScheduleOfficeLocationsByTierQuery(tierId, {
      skip: !tierId,
    });

  const [availability, setAvailability] = useState({ days: [], timeSlots: [] });
  const [officeLocations, setOfficeLocations] = useState([]);
  const [willingToDoDuty, setWillingToDoDuty] = useState(true);
  const [unavailablePeriods, setUnavailablePeriods] = useState([]);

  const handleDaysChange = (event) => {
    const { value } = event.target;
    setAvailability((prev) => ({
      ...prev,
      days: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleAddTimeSlot = (slot) => {
    setAvailability((prev) => ({
      ...prev,
      timeSlots: [...prev.timeSlots, slot],
    }));
  };

  const handleRemoveTimeSlot = (index) => {
    setAvailability((prev) => ({
      ...prev,
      timeSlots: prev.timeSlots.filter((_, i) => i !== index),
    }));
  };

  const handleOfficeChange = (event) => {
    const { value } = event.target;
    setOfficeLocations(value);
  };

  const handleUnavailablePeriodChange = (periods) => {
    setUnavailablePeriods(periods);
  };

  const handleSubmit = () => {
    onSubmit({
      availability,
      officeLocations,
      willingToDoDuty,
      unavailablePeriods,
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography component="div">Choose Available Days</Typography>
      <FormControl fullWidth>
        <InputLabel>Available Days</InputLabel>
        <Select
          multiple
          label="Available Days"
          value={availability.days}
          onChange={handleDaysChange}
          renderValue={(selected) => selected.join(", ")}
        >
          {daysOfWeek?.map((day) => (
            <MenuItem key={day} value={day}>
              <Checkbox checked={availability.days.includes(day)} />
              <ListItemText primary={day} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Typography component="div">Choose Available Time Slots</Typography>
      <TimeSlotSelector
        onAddTimeSlot={handleAddTimeSlot}
        onRemoveTimeSlot={handleRemoveTimeSlot}
        timeSlots={availability.timeSlots}
      />
      <UnavailablePeriodSelector
        onChange={handleUnavailablePeriodChange}
        unavailablePeriods={unavailablePeriods}
      />
      <Typography component="div">Choose Office Locations</Typography>
      <FormControl fullWidth>
        <InputLabel>Office Locations</InputLabel>
        <Select
          multiple
          value={officeLocations?.map((office) => office.officeName)}
          onChange={handleOfficeChange}
          renderValue={(selected) => selected.join(", ")}
          required
        >
          {dutyScheduleOfficeLocations?.map((office) => (
            <MenuItem key={office?.officeName} value={office}>
              <Checkbox
                checked={officeLocations.some(
                  (loc) => loc.officeName === office?.officeName
                )}
              />
              <ListItemText primary={office?.officeName} />
              <ListItemText primary={office?.location} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl component="fieldset">
        <RadioGroup
          row
          value={willingToDoDuty ? "yes" : "no"}
          onChange={(e) => setWillingToDoDuty(e.target.value === "yes")}
          required
        >
          <FormControlLabel
            value="yes"
            control={<Radio />}
            label="Willing to Do Duty"
          />
          <FormControlLabel
            value="no"
            control={<Radio />}
            label="Not Willing to Do Duty"
          />
        </RadioGroup>
      </FormControl>
      <Button
        variant="contained"
        onClick={handleSubmit}
        disabled={
          !selectedMembersUserIds?.length ||
          selectedMembersUserIds?.length === 0
        }
      >
        Save Preferences
      </Button>
    </Box>
  );
}

export default MembersDutyPreferencesForm;

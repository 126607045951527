import React, { useState, memo } from "react";
import { useGetAllTierMembersQuery } from "../../../../services/member";
import { Typography, Paper } from "@mui/material";
import NotificationSnackbar from "../../../helpers/notification-snackbar";
import MembersDutyPreferencesList from "./MembersDutyPreferencesList";

const MembersFloorDutyPreferencesManager = ({ tier }) => {
  const { data: members } = useGetAllTierMembersQuery(
    { tierId: tier?._id },
    {
      skip: !tier?._id,
    }
  );

  const [notification, setNotification] = useState({
    openNotification: false,
    message: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, openNotification: false });
  };

  return (
    <>
      <Paper sx={{ p: 2, mb: 2, display: "flex", flexDirection: "column" }}>
        <Typography variant="h5" fontWeight={700}>
          Members Floor Duty Preferences Manager
        </Typography>
      </Paper>
      {Array.isArray(members) ? (
        <MembersDutyPreferencesList members={members} tierId={tier?._id} />
      ) : (
        <p>{members?.msg}</p>
      )}
      <NotificationSnackbar
        open={notification.openNotification}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
};

export default memo(MembersFloorDutyPreferencesManager);

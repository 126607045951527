import React, { useState } from "react";
import { useDeleteUserRoleNameEnumMutation } from "../../../services/user-role-name-enum";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Divider,
  Box,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { DeleteForever, Edit } from "@mui/icons-material/";
import { ConfirmationDialog } from "../..";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import CreateEditUserRoleNameEnumForm from "./CreateEditUserRoleNameEnumForm";

function RoleNameEnumsList({ userRoleNameEnums }) {
  const [deleteRoleNameEnum] = useDeleteUserRoleNameEnumMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [currentRoleNameEnum, setCurrentRoleNameEnum] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [sortField, setSortField] = useState("userRoleName");
  const [sortDirection, setSortDirection] = useState("asc");
  const [openEditRoleNameEnumDialog, setOpenEditRoleNameEnumDialog] =
    useState(false);

  const handleRowClick = (event, userRoleNameEnum) => {
    if (
      event.target.closest(".menu-button") ||
      event.target.closest(".view-details-button")
    ) {
      setCurrentRoleNameEnum(userRoleNameEnum);
      return;
    }
  };

  const handleOpenEditRoleNameEnumDialog = (event, userRoleNameEnum) => {
    event.stopPropagation();
    setCurrentRoleNameEnum(userRoleNameEnum);
    setOpenEditRoleNameEnumDialog(true);
  };

  const handleCloseEditRoleNameEnumDialog = () => {
    setOpenEditRoleNameEnumDialog(false);
  };

  const handleDeleteRoleNameEnumClick = (event, userRoleNameEnumId) => {
    event.stopPropagation();
    setCurrentRoleNameEnum({ ...currentRoleNameEnum, _id: userRoleNameEnumId });
    setConfirmationDialogOpen(true);
  };
  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleConfirmationDialogConfirm = async () => {
    if (currentRoleNameEnum?._id) {
      const result = await deleteRoleNameEnum({
        userRoleNameEnumId: currentRoleNameEnum._id,
      });

      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        setConfirmationDialogOpen(false);
      } else {
        setNotification({
          open: true,
          message: `Error deleting Role Name Enum. ${result?.error?.data?.msg}`,
        });
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  if (!userRoleNameEnums || userRoleNameEnums?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 20,
        }}
      >
        <Typography variant="h5" gutterBottom>
          No role name enums available
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "userRoleName"}
                    direction={sortDirection}
                    onClick={() => handleSort("userRoleName")}
                  >
                    User Role Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>Actions </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(userRoleNameEnums) &&
                userRoleNameEnums?.map((userRoleNameEnum) => (
                  <TableRow
                    key={userRoleNameEnum?._id}
                    onClick={(event) => handleRowClick(event, userRoleNameEnum)}
                    style={{ cursor: "pointer" }}
                    className="table-row"
                  >
                    <TableCell>
                      {userRoleNameEnum?.userRoleName || ""}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title="Delete Role Name Enum">
                          <IconButton
                            aria-label="delete"
                            onClick={(event) =>
                              handleDeleteRoleNameEnumClick(
                                event,
                                userRoleNameEnum?._id
                              )
                            }
                          >
                            <DeleteForever />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Role Name Enum">
                          <IconButton
                            className="menu-button"
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(event) => {
                              event.stopPropagation();
                              handleOpenEditRoleNameEnumDialog(
                                event,
                                userRoleNameEnum
                              );
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider style={{ margin: "20px 0" }} />
      </div>
      <CreateEditUserRoleNameEnumForm
        mode="edit"
        userRoleNameEnum={currentRoleNameEnum}
        open={openEditRoleNameEnumDialog}
        onClose={handleCloseEditRoleNameEnumDialog}
      />
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmationDialogConfirm}
        title="Confirm Deleting the User Role Name Enum"
        description="Are you sure you want to delete this User Role Name Enum permanently?"
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
}

export default RoleNameEnumsList;

import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { isTokenExpired } from "../custom-hooks/helpUtils";

function ProtectedRoute({ children }) {
  const { token } = useContext(AuthContext);

  if (!token || isTokenExpired(token)) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

export default ProtectedRoute;

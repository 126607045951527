import React, { memo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

function ConfirmationDialog({ open, onClose, onConfirm, title, description }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ backgroundColor: "#1976d2", color: "white" }}>
        {title}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Typography
            variant="subtitle1"
            sx={{ marginTop: "1rem", marginBottom: "1rem" }}
          >
            {description}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(ConfirmationDialog);

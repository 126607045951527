import React, { useState, useEffect } from "react";
import { useUpdateTierPermissionToRoleMutation } from "../../../services/tier-permission";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import { defaultPermissions } from "./DefaultPermissions";
import { Delete } from "@mui/icons-material";

const UpdateTierPermissionToRoleDialog = ({
  open,
  onClose,
  roleId,
  tierId,
  existingPermissions = [],
}) => {
  const [updateTierPermissionToRole] = useUpdateTierPermissionToRoleMutation();
  const [permissions, setPermissions] = useState([]);
  const [newPermission, setNewPermission] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (existingPermissions) {
      setPermissions(existingPermissions.map((perm) => perm.perm));
    }
  }, [existingPermissions]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!roleId || !tierId) {
      setNotification({
        open: true,
        message: "There are no role or tier id.",
      });
      return;
    }

    if (permissions && permissions?.length === 0) {
      setNotification({
        open: true,
        message: "There are no permissions.",
      });
      return;
    }

    const result = await updateTierPermissionToRole({
      roleId,
      tierId,
      permissions,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        onClose();
        setPermissions([]);
      }, 2000);
    } else {
      setNotification({
        open: true,
        message: `Error assigning Role to the User. ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleAddPermission = () => {
    if (newPermission && !permissions.includes(newPermission)) {
      setPermissions([...permissions, newPermission]);
      setNewPermission("");
    }
  };

  const handleRemovePermission = (permission) => {
    setPermissions(permissions.filter((perm) => perm !== permission));
  };

  const toggleDefaultPermissions = () => {
    const currentPermissions = new Set(permissions);
    if (defaultPermissions.every((perm) => currentPermissions.has(perm))) {
      setPermissions(
        permissions.filter((perm) => !defaultPermissions.includes(perm))
      );
    } else {
      setPermissions([...new Set([...permissions, ...defaultPermissions])]);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Update Tier Permisison for Role</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} direction="column">
            <Grid item xs={12}>
              <TextField
                label="New Permission"
                variant="outlined"
                value={newPermission}
                onChange={(e) => setNewPermission(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && handleAddPermission()}
                fullWidth
              />
              <Button
                onClick={handleAddPermission}
                color="primary"
                style={{ marginTop: "8px" }}
              >
                Add Permission
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={toggleDefaultPermissions}
                åå
                color="secondary"
                style={{ marginBottom: "8px" }}
              >
                Toggle Default Permissions
              </Button>
            </Grid>
            <Grid item xs={12}>
              <List dense>
                {permissions?.map((perm, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={perm} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleRemovePermission(perm)}
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Update Tier Permission for Role
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default UpdateTierPermissionToRoleDialog;

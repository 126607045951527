import React, { useState } from "react";
import {
  useDeleteTierMutation,
  useUpdateTierStatusMutation,
} from "../../../services/tier";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Divider,
  Box,
  TableSortLabel,
  CircularProgress,
} from "@mui/material";
import { MoreVert, DeleteForever, Edit } from "@mui/icons-material/";
import { ConfirmationDialog } from "../..";
import { useNavigate } from "react-router-dom";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import CreateEditTierForm from "./CreateEditTierForm";
import { titleCase } from "title-case";

function TiersList({ tiers, refetchTiers }) {
  const navigate = useNavigate();
  const [deleteTier] = useDeleteTierMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [updateTierStatus, { isUpdateTierStatusLoading }] =
    useUpdateTierStatusMutation();

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [currentTierClicked, setCurrentTierClicked] = useState(null);
  const [currentTier, setCurrentTier] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [sortField, setSortField] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [openEditTierDialog, setOpenEditTierDialog] = useState(false);

  const handleRowClick = (event, tierItemId, tierItem) => {
    if (
      event.target.closest(".menu-button") ||
      event.target.closest(".view-details-button")
    ) {
      setCurrentTierClicked(tierItemId);
      setCurrentTier(tierItem);

      return;
    }
    if (tierItemId) {
      navigate(`/tier/${tierItemId}`);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenEditTierDialog = (event) => {
    event.stopPropagation();
    setOpenEditTierDialog(true);
    setAnchorEl(null);
  };

  const handleCloseEditTierDialog = () => {
    setOpenEditTierDialog(false);
  };

  const handleDeleteTierClick = (event, tierId) => {
    event.stopPropagation();
    setCurrentTier({ ...currentTier, _id: tierId });
    setConfirmationDialogOpen(true);
  };
  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleConfirmationDialogConfirm = async () => {
    if (currentTier?._id) {
      const result = await deleteTier({
        tierId: currentTier._id,
      });

      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        refetchTiers?.();
        setConfirmationDialogOpen(false);
      } else {
        setNotification({
          open: true,
          message: `Error deleting Tier. ${result?.error?.data?.msg}`,
        });
      }
    }
  };

  const handleUpdateTierStatus = async () => {
    if (!currentTier) {
      setNotification({
        open: true,
        message: "The Tier id is not provided",
      });
      return;
    }
    const newStatus = currentTier?.status === "active" ? "pending" : "active";
    if (currentTier?._id) {
      const result = await updateTierStatus({
        tierId: currentTier._id,
        status: newStatus,
      });

      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        refetchTiers?.();
        setAnchorEl(null);
      } else {
        setNotification({
          open: true,
          message: `Error: ${result?.error?.data?.msg}`,
        });
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  if (!tiers || tiers?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 20,
        }}
      >
        <Typography variant="h5" gutterBottom>
          No Tiers available
        </Typography>
        <Link to="/tier-management">Go to Create Tier</Link>
      </Box>
    );
  }

  return (
    <>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Short Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Level</TableCell>
                <TableCell>Features</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "reacircleadmin"}
                    direction={sortDirection}
                    onClick={() => handleSort("reacircleadmin")}
                  >
                    Created by REOS Admin
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "brokerage"}
                    direction={sortDirection}
                    onClick={() => handleSort("brokerage")}
                  >
                    Tier brokerage
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "team"}
                    direction={sortDirection}
                    onClick={() => handleSort("team")}
                  >
                    Tier team
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "group"}
                    direction={sortDirection}
                    onClick={() => handleSort("group")}
                  >
                    Tier group
                  </TableSortLabel>
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(tiers) &&
                tiers?.map((tier) => (
                  <TableRow
                    key={tier?._id}
                    onClick={(event) => handleRowClick(event, tier?._id, tier)}
                    style={{ cursor: "pointer" }}
                    className="table-row"
                  >
                    <TableCell>{tier?.name}</TableCell>
                    <TableCell>{tier?.shortName}</TableCell>
                    <TableCell>{tier?.type}</TableCell>
                    <TableCell>{tier?.level}</TableCell>
                    <TableCell>{tier?.features}</TableCell>
                    <TableCell>{tier?.status}</TableCell>
                    <TableCell>
                      {tier?.reosadmin
                        ? `${tier?.reosadmin?.user?.firstName} ${tier?.reosadmin?.user?.lastName}`
                        : ""}
                    </TableCell>
                    <TableCell>
                      {`${titleCase(tier?.level)} ${
                        tier?.brokerage ? tier?.brokerage?.officeName : ""
                      } ${
                        tier?._id === tier?.brokerage?.leadBrokerageTier
                          ? " - All Offices"
                          : ""
                      } ${tier?._id === tier?.brokerage?.tier ? "" : ""}`}
                    </TableCell>
                    <TableCell>{tier?.team?.name}</TableCell>
                    <TableCell>{tier?.group?.name}</TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          aria-label="delete"
                          onClick={(event) =>
                            handleDeleteTierClick(event, tier?._id)
                          }
                        >
                          <DeleteForever />
                        </IconButton>
                        <IconButton
                          className="menu-button"
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(event) => handleMenuOpen(event, tier?._id)}
                        >
                          <MoreVert />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleMenuClose}
                        >
                          <MenuItem
                            onClick={(event) => {
                              event.stopPropagation();
                              handleOpenEditTierDialog(event);
                            }}
                          >
                            <Edit />
                            <Typography ml={1}>Edit Tier</Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={(event) => {
                              event.stopPropagation();
                              handleUpdateTierStatus();
                            }}
                          >
                            <Typography ml={1}>
                              {isUpdateTierStatusLoading ? (
                                <CircularProgress size={24} />
                              ) : (
                                `Update Tier Status to: ${
                                  currentTier?.status === "active"
                                    ? "pending"
                                    : currentTier?.status === "pending"
                                    ? "active"
                                    : "no status"
                                }`
                              )}
                            </Typography>
                          </MenuItem>
                          <MenuItem>
                            <Typography
                              variant="body1"
                              className="view-details-button"
                              onClick={() =>
                                navigate(`/tier/${currentTierClicked}`)
                              }
                            >
                              View Details
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider style={{ margin: "20px 0" }} />
      </div>
      <CreateEditTierForm
        mode="edit"
        refetchTiers={refetchTiers}
        tier={currentTier}
        open={openEditTierDialog}
        onClose={handleCloseEditTierDialog}
      />
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmationDialogConfirm}
        title="Confirm Deleting the Tier"
        description="Are you sure you want to delete this Tier permanently?"
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
}

export default TiersList;

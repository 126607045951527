// PDFPreview.js
import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const PDFPreview = ({ src }) => {
  if (!src) {
    return <div>No PDF available</div>;
  }

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.8.165/build/pdf.worker.min.js">
      <div
        style={{
          border: "2px solid #ddd", // Light gray border
          borderRadius: "8px", // Rounded corners
          padding: "10px", // Padding inside the border
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for a 3D effect
          width: "800px", // Full width of the container
           height: "800px", // Adjust height as needed
          overflow: "auto", // Allow scroll if content overflows
        }}
      >
        <Viewer fileUrl={src} />
      </div>
    </Worker>
  );
};

export default PDFPreview;

import React, { useState, useContext, memo } from "react";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { AuthContext } from "../../context/AuthContext";
import EditIcon from "@mui/icons-material/Edit"; // For a pencil/edit icon
import { ConfirmationDialog } from "../";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  Divider,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Badge,
  TextField,
} from "@mui/material";
import {
  MoreVert,
  Edit,
  DeleteForever,
  ThumbUpAlt,
  ThumbUpOffAlt,
  Comment,
  ExpandMore,
  Send,
} from "@mui/icons-material/";
import {
  useLikeCommentMutation,
  useUnlikeCommentMutation,
  useReplyToCommentMutation,
  useDeleteCommentMutation,
} from "../../services/post";
import { AvatarStyledBadge } from "../helpers/utils";
import EditComment from "./EditComment";
import NotificationSnackbar from "../helpers/notification-snackbar";
import ReplyCard from "./ReplyCard";

const ExpandReplies = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  transform: expand ? "rotate(180deg)" : "rotate(0deg)",
}));

const CommentCard = ({
  comment,
  post,
  onMemberClick,
  refetchPosts,
  isPostsLoading,
  isPostsError,
  isListingPostCard = false,
  categoryPath = "",
}) => {
  const { userId, accountType, userImage, userName } = useContext(AuthContext);
  const currentUserId = userId;
  const [likeComment] = useLikeCommentMutation();
  const [unlikeComment] = useUnlikeCommentMutation();
  const [replyToComment] = useReplyToCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();
  const [commentMenuAnchorEl, setCommentMenuAnchorEl] = useState({});
  const [openEditCommentDialog, setOpenEditCommentDialog] = useState(false);
  const [isReply, setIsReply] = useState("");
  const [newReply, setNewReply] = useState("");
  const [expandedReplies, setExpandedReplies] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const isLikedByCurrentUser = comment?.likes?.some(
    (like) => like.author?._id === currentUserId
  );

  const handleDeleteComment = async () => {
    console.log(comment?._id);
    const result = await deleteComment({
      commentId: comment?._id,
    });

    if (result) {
      setNotification({ open: true, message: "Comment deleted successfully." });
      handleDeleteDialogClose();
      // navigate(-1);
    } else {
      setNotification({
        open: true,
        message: `Error deleting comment. ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleDeleteDialogOpen = (event, commentId) => {
    event.stopPropagation();

    setDeleteDialogOpen(true);
    setOpenEditCommentDialog(false);
    handleMenuClose(commentId);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleMenuOpen = (event, commentId) => {
    event.stopPropagation();
    setCommentMenuAnchorEl({
      ...commentMenuAnchorEl,
      [commentId]: event.currentTarget,
    });
  };

  const handleMenuClose = (commentId) => {
    setCommentMenuAnchorEl({ ...commentMenuAnchorEl, [commentId]: null });
  };

  const handleLikeComment = async () => {
    const result = await likeComment({
      userId: userId,
      commentId: comment?._id,
      tierId: post?.tier?._id,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `The error occured ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleUnlikeComment = async () => {
    const result = await unlikeComment({
      userId: currentUserId,
      commentId: comment?._id,
    });
    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `The error occured ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleOpenEditCommentDialog = (event) => {
    event.stopPropagation();
    setOpenEditCommentDialog(true);
  };

  const handleCloseEditCommentDialog = () => {
    setOpenEditCommentDialog(false);
    handleMenuClose(comment?._id);
  };

  const isAuthor = comment?.author?._id === currentUserId;
  const isAuthorOrAdmin = isAuthor || accountType === "reosadmin";

  const additionalLikes =
    comment?.likes?.length > 2 ? comment?.likes?.length - 2 : 0;

  const renderLikeAvatars = () => {
    const avatarsToShow =
      comment?.likes &&
      comment?.likes?.slice(0, 2)?.map((like, index) => (
        <Tooltip key={index} title="Member Profile link">
          <Avatar
            src={like.author?.image?.url}
            alt={`${like.author?.firstName} ${like.author?.lastName}`}
            sx={{
              width: 24,
              height: 24,
              marginRight: -0.75,
              cursor: "pointer",
            }}
          />
        </Tooltip>
      ));

    return <div className="likes-avatars">{avatarsToShow}</div>;
  };

  const handleExpandRepliesClick = () => {
    setExpandedReplies(!expandedReplies);
  };

  const handleReplyInputClick = (event) => {
    event.stopPropagation();
  };

  const handleReplySubmit = async (event) => {
    event && event?.stopPropagation();
    const result = await replyToComment({
      userId: currentUserId,
      commentId: comment?._id,
      content: newReply,
      tierId: post?.tier?._id,
    });

    if (result) {
      setNotification({
        open: true,
        message: `Replied to Comment successfully`,
      });
      setNewReply("");
    } else {
      setNotification({
        open: true,
        message: `Error submitting Reply ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleReplySubmit();
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const sanitizedContent = DOMPurify.sanitize(comment?.content);

  return (
    <>
      <Card
        sx={{ marginBottom: 2 }}
        key={comment?._id}
        className="post-comment-card"
      >
        <CardHeader
          className="post-comment-card-header"
          avatar={
            <Tooltip title="Member Profile link">
              <AvatarStyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
                color="success"
                invisible={!comment?.author?.isOnline}
              >
                <Avatar
                  className="avatar"
                  src={comment?.author?.image?.url}
                  onClick={(event) => {
                    event.stopPropagation();
                    onMemberClick?.(comment?.author?._id);
                  }}
                >
                  R
                </Avatar>
              </AvatarStyledBadge>
            </Tooltip>
          }
          title={
            <div className="comment-header-block">
              <Tooltip title="Member Profile link">
                <Link
                  onClick={(event) => {
                    event.stopPropagation();
                    onMemberClick?.(comment?.author?._id);
                  }}
                  className="avatar-name"
                >
                  {comment?.author?.firstName} {comment?.author?.lastName}
                </Link>
              </Tooltip>
              <Typography className="additional-text">
                {" commented "}
                {comment?.author?._id === post?.author?._id
                  ? " - by Author"
                  : ""}
              </Typography>
            </div>
          }
          subheader={
            <Typography className="date-text">
              {new Date(comment?.createdAt).toLocaleString()}
            </Typography>
          }
          action={
            <>
              <IconButton
                className="menu-button"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(event) => handleMenuOpen(event, comment?._id)}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id={`menu-${comment?._id}`}
                anchorEl={commentMenuAnchorEl[comment?._id]}
                open={Boolean(commentMenuAnchorEl[comment?._id])}
                onClose={() => handleMenuClose(comment?._id)}
              >
                {isAuthorOrAdmin && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      handleOpenEditCommentDialog(event);
                    }}
                  >
                    <Edit />
                    <Typography ml={1}>Edit Comment</Typography>
                  </MenuItem>
                )}
                {isAuthorOrAdmin && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDeleteDialogOpen(event, comment?._id);
                    }}
                  >
                    <DeleteForever />
                    <Typography ml={1}>Delete Comment</Typography>
                  </MenuItem>
                )}
                {!(accountType === "reosadmin") && !isAuthorOrAdmin && (
                  <MenuItem>
                    <Typography ml={1}>No actions</Typography>
                  </MenuItem>
                )}
              </Menu>
            </>
          }
        />
        <CardContent className="comment-content">
          {/* <Tooltip title="Follow the link"> */}
          <div
            className="replaced-link"
            dangerouslySetInnerHTML={{ __html: sanitizedContent }}
          />
          {/* </Tooltip> */}
          {comment?.lastEdited && (
            <Typography
              variant="caption"
              color="textSecondary"
              textAlign="right"
            >
              <EditIcon sx={{ verticalAlign: "middle", mr: 0.5 }} />
              Last edited on {new Date(comment?.lastEdited)?.toLocaleString()}
            </Typography>
          )}
          {!isListingPostCard && categoryPath !== "legally-speaking" && (
            <div className="likes-block">
              <Tooltip
                title={additionalLikes > 0 ? `+ ${additionalLikes} more` : ""}
              >
                <Badge
                  badgeContent={
                    additionalLikes > 0 ? `+${additionalLikes}` : ""
                  }
                  className="likes-block-avatars"
                >
                  {renderLikeAvatars()}
                </Badge>
              </Tooltip>
            </div>
          )}
        </CardContent>
        <Divider />
        <CardActions className="comment-card-actions-block">
          <div className="comment-card-actions">
            {!isListingPostCard && (
              <div className="likes-block">
                {categoryPath !== "legally-speaking" && (
                  <Tooltip
                    title={isLikedByCurrentUser ? "Unlike it" : "Like it"}
                  >
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        isLikedByCurrentUser
                          ? handleUnlikeComment()
                          : handleLikeComment();
                      }}
                    >
                      {isLikedByCurrentUser ? (
                        <ThumbUpAlt />
                      ) : (
                        <ThumbUpOffAlt />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title={"Reply to Comment"}>
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      setIsReply(!isReply);
                    }}
                  >
                    <Comment />
                  </IconButton>
                </Tooltip>
              </div>
            )}
            {!isListingPostCard && (
              <div className="replies-block">
                {comment?.replies && comment?.replies?.length > 0 ? (
                  <>
                    <span className="replies-block-span">{`${
                      comment?.replies &&
                      comment?.replies?.length > 0 &&
                      (comment?.replies?.length === 1
                        ? "1 reply"
                        : `${comment?.replies?.length} replies`)
                    }`}</span>
                    <ExpandReplies
                      expand={expandedReplies}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleExpandRepliesClick();
                      }}
                      aria-expanded={expandedReplies}
                      aria-label="show more"
                    >
                      <ExpandMore />
                    </ExpandReplies>
                  </>
                ) : (
                  <span className="replies-block-span">no replies</span>
                )}
              </div>
            )}
          </div>
        </CardActions>
        {!isListingPostCard && (
          <Collapse in={expandedReplies} timeout="auto" unmountOnExit>
            <CardContent>
              {comment?.replies && comment?.replies?.length > 0 ? (
                comment?.replies?.map((reply) => (
                  <ReplyCard
                    key={reply?._id}
                    reply={reply}
                    comment={comment}
                    onMemberClick={onMemberClick}
                    refetchPosts={refetchPosts}
                    isPostsError={isPostsError}
                    isPostsLoading={isPostsLoading}
                    categoryPath={categoryPath}
                    tierId={post?.tier?._id}
                  />
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No replies yet
                </Typography>
              )}
              {expandedReplies && (
                <div
                  className="comment-reply-block"
                  onClick={handleReplyInputClick}
                >
                  <Avatar
                    height="100"
                    width="100"
                    src={userImage || ""}
                    alt={userName || "UN"}
                  />
                  <div className="reply-input">
                    <TextField
                      className="reply-input-textarea"
                      placeholder="New Reply"
                      value={newReply}
                      onChange={(e) => {
                        e.stopPropagation();
                        setNewReply(e.target.value);
                      }}
                      fullWidth
                      onKeyDown={handleKeyDown}
                      multiline
                      rows={3}
                      onClick={handleReplyInputClick}
                    />
                    <Tooltip title="Reply to Comment">
                      <IconButton
                        className="reply-input-button"
                        onClick={handleReplySubmit}
                      >
                        <Send />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              )}
            </CardContent>
          </Collapse>
        )}
        {isReply && !expandedReplies && (
          <div className="comment-reply-block" onClick={handleReplyInputClick}>
            <Avatar
              height="100"
              width="100"
              src={userImage || ""}
              alt={userName || "UN"}
            />
            <div className="reply-input">
              <TextField
                className="reply-input-textarea"
                placeholder="New reply"
                value={newReply}
                onChange={(e) => {
                  e.stopPropagation();
                  setNewReply(e.target.value);
                }}
                fullWidth
                onKeyDown={handleKeyDown}
                multiline
                rows={3}
                onClick={handleReplyInputClick}
              />
              <Tooltip title="Reply to Comment">
                <IconButton
                  className="reply-input-button"
                  onClick={handleReplySubmit}
                >
                  <Send />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
        <EditComment
          refetchPosts={refetchPosts}
          comment={comment}
          open={openEditCommentDialog}
          onClose={() => {
            handleCloseEditCommentDialog();
            handleMenuClose(comment?._id);
          }}
        />
        <NotificationSnackbar
          open={notification.open}
          message={notification.message}
          onClose={handleClose}
        />
      </Card>
      <ConfirmationDialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onConfirm={handleDeleteComment}
        title="Confirm Deleting"
        description="Are you sure you want to delete this comment?"
      />
    </>
  );
};

export default memo(CommentCard);

import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetTeamByIdQuery,
  useDeleteMemberMutation,
} from "../../../services/team";
import { AuthContext } from "../../../context/AuthContext";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { DeleteForever, AssignmentTurnedIn } from "@mui/icons-material/";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import AddExistingMember from "./AddExistingMember";
import RemoveMemberDialog from "./RemoveMemberDialog";
import TeamRoles from "./team-roles/TeamRoles";
import AssignTierToTeamDialog from "./AssignTierToTeamDialog";
import InviteNewMemberToTeamDialog from "./InviteNewMemberToTeamDialog";

const TeamPage = () => {
  const { userId, userRole } = useContext(AuthContext);
  const { teamId } = useParams();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const {
    data: team,
    error,
    isLoading,
    refetch,
  } = useGetTeamByIdQuery(teamId, {
    skip: !teamId,
  });

  const [deleteMember] = useDeleteMemberMutation();
  const [isAddMemberOpen, setIsAddMemberOpen] = useState(false);
  const [isInviteNewMemberOpen, setIsInviteNewMemberOpen] = useState(false);
  const [isRemoveMemberOpen, setIsRemoveMemberOpen] = useState(false);
  const [memberIdToRemove, setMemberIdToRemove] = useState(null);
  const [currentTeamId, setCurrentTeamId] = useState(null);
  const [openAssignTierToTeamDialog, setOpenAssignTierToTeamDialog] =
    useState(false);

  const handleAddMemberOpen = (teamId) => {
    setCurrentTeamId(teamId);
    setIsAddMemberOpen(true);
  };

  const handleAddMemberClose = () => {
    setIsAddMemberOpen(false);
  };

  const handleInviteNewMemberOpen = (teamId) => {
    setCurrentTeamId(teamId);
    setIsInviteNewMemberOpen(true);
  };

  const handleInviteNewMemberClose = () => {
    setIsInviteNewMemberOpen(false);
  };

  const handleRemoveMemberOpen = (teamId, memberId) => {
    setCurrentTeamId(teamId);
    setMemberIdToRemove(memberId);
    setIsRemoveMemberOpen(true);
  };

  const handleRemoveMemberClose = () => {
    setIsRemoveMemberOpen(false);
    setCurrentTeamId(null);
    setMemberIdToRemove(null);
  };

  const handleRemoveMemberConfirm = async () => {
    const result = await deleteMember({
      teamId: currentTeamId,
      memberId: memberIdToRemove,
    });

    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      handleRemoveMemberClose();
      refetch();
    } else {
      setNotification({
        open: true,
        message: `Error deleting Member. ${result?.error?.data?.msg}`,
      });
      refetch();
    }
  };

  const handleOpenAssignTierToTeamDialog = (teamId) => {
    setCurrentTeamId(teamId);
    setOpenAssignTierToTeamDialog(true);
  };

  const handleCloseAssignTierToTeamDialog = () => {
    setOpenAssignTierToTeamDialog(false);
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (isLoading)
    return (
      <div className="loading-spinner">
        <CircularProgress size={24} />
      </div>
    );
  if (!team) {
    return (
      <Typography variant="h4" gutterBottom>
        No Team Found
      </Typography>
    );
  }

  if (error) return <div>Error: {error?.data?.msg}</div>;

  return (
    <Container className="team-paper">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Tooltip title="Assign Tier to Team" placement="left">
          <IconButton
            edge="end"
            aria-label="assign-tier-to-team"
            onClick={() => handleOpenAssignTierToTeamDialog(team?._id)}
          >
            <AssignmentTurnedIn />
            <Typography ml={1}>Assign Tier to Team</Typography>
          </IconButton>
        </Tooltip>
      </Box>
      <Card className="team-paper">
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Team Name: {team?.name}
              </Typography>
            </Grid>
            {team?.brokerage && (
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  <strong>Brokerage:</strong> {team?.brokerage?.officeName}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                <strong>Team Lead: </strong>
                {team?.teamLead?.member?.user?.firstName}{" "}
                {team?.teamLead?.member?.user?.lastName} -{" "}
                {team?.teamLead?.member?.user?.email}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" gutterBottom>
                Members:
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleInviteNewMemberOpen(team?._id)}
              >
                Invite New Member
              </Button>
              {userRole === "reosadmin" ||
                (userRole === "reossuperadmin" && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAddMemberOpen(team?._id)}
                  >
                    Add Existing Member
                  </Button>
                ))}
            </Grid>
            <TableContainer sx={{ m: 5 }} component={Paper}>
              <Table className="user-table">
                <TableHead>
                  <TableRow
                    sx={{
                      color: "var(--text-color)",
                      backgroundColor: "var(--bg-color)",
                    }}
                  >
                    <TableCell>Name (Email)</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {team?.members &&
                    team?.members?.length > 0 &&
                    team?.members?.map((teamMember) => (
                      <TableRow className="table-row" key={teamMember?._id}>
                        <TableCell>
                          {`${teamMember?.member?.user?.firstName} ${teamMember?.member?.user?.lastName} (${teamMember?.member?.user?.email})`}
                        </TableCell>
                        <TableCell>{teamMember?.role?.name}</TableCell>
                        <TableCell>
                          {(team?.teamLead?.member?.user?._id === userId ||
                            userRole === "reossuperadmin" ||
                            userRole === "reosadmin") && (
                            <IconButton
                              aria-label="delete"
                              onClick={() =>
                                handleRemoveMemberOpen(
                                  team?._id,
                                  teamMember?.member?._id
                                )
                              }
                            >
                              <DeleteForever />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </CardContent>
      </Card>
      <TeamRoles teamId={teamId} teamMembers={team?.members} />
      <InviteNewMemberToTeamDialog
        open={isInviteNewMemberOpen}
        teamId={teamId}
        tierId={team?.tier?._id}
        teamName={team?.name}
        onClose={handleInviteNewMemberClose}
        refetch={refetch}
      />
      <AddExistingMember
        open={isAddMemberOpen}
        teamId={currentTeamId}
        onClose={handleAddMemberClose}
        refetch={refetch}
      />
      <AssignTierToTeamDialog
        preselectedTeam={team}
        open={openAssignTierToTeamDialog}
        onClose={handleCloseAssignTierToTeamDialog}
      />
      <RemoveMemberDialog
        open={isRemoveMemberOpen}
        onClose={handleRemoveMemberClose}
        onConfirm={handleRemoveMemberConfirm}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Container>
  );
};

export default TeamPage;

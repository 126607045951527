import React, { useState, memo } from "react";
import { useGetAllTierMembersQuery } from "../../../../services/member";
import {
  TextField,
  IconButton,
  Typography,
  Box,
  InputAdornment,
  Select,
  MenuItem,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material/";
import NotificationSnackbar from "../../../helpers/notification-snackbar";
import DutySchedulingMembersTable from "./DutySchedulingMembersTable";

function DutySchedulingMembersList({ tierId }) {
  const { data: members = [] } = useGetAllTierMembersQuery(
    { tierId },
    {
      skip: !tierId,
    }
  );

  const [sortField, setSortField] = useState("email");
  const [sortDirection, setSortDirection] = useState("asc");
  const [filterText, setFilterText] = useState("");
  const [dutyFilter, setDutyFilter] = useState("all");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  const handleFilterChange = (event) => {
    setFilterText(event.target.value.toLowerCase());
  };

  const sortedMembers =
    members &&
    members?.length > 0 &&
    [...members]?.sort((a, b) => {
      const fields = sortField.split(".");
      const aValue = fields.reduce((acc, curr) => acc[curr], a);
      const bValue = fields.reduce((acc, curr) => acc[curr], b);

      if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
      if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
      return 0;
    });

  const filteredMembers =
    sortedMembers &&
    sortedMembers?.length > 0 &&
    sortedMembers?.filter((member) => {
      const filterTextLower = filterText.toLowerCase();

      const fullName =
        `${member.user.firstName} ${member.user.lastName}`.toLowerCase();

      const emailMatch = member.user.email.toLowerCase().includes(filterText);
      const firstNameMatch = member.user.firstName
        .toLowerCase()
        .includes(filterText);
      const lastNameMatch = member.user.lastName
        .toLowerCase()
        .includes(filterText);
      const fullNameMatch = fullName.includes(filterTextLower);
      const nameMatch =
        emailMatch || firstNameMatch || lastNameMatch || fullNameMatch;

      if (!nameMatch) return false;
      if (dutyFilter === "willing") return member?.willingToDoDuty;
      if (dutyFilter === "notWilling") return !member?.willingToDoDuty;

      return true;
    });

  const clearFilters = () => {
    setFilterText("");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          gap: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            gap: 2,
            mb: 2,
          }}
        >
          <TextField
            label="Filter Users"
            variant="outlined"
            value={filterText}
            onChange={handleFilterChange}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {filterText && (
                    <IconButton onClick={clearFilters} edge="end">
                      <CloseIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Select
            value={dutyFilter}
            onChange={(e) => setDutyFilter(e.target.value)}
            fullWidth
          >
            <MenuItem value="all">All Members</MenuItem>
            <MenuItem value="willing">Willing to Do Duty</MenuItem>
            <MenuItem value="notWilling">Not Willing to Do Duty</MenuItem>
          </Select>
          <Typography variant="h6" sx={{ textAlign: "center", width: "100%" }}>
            User Count: {filteredMembers?.length}
          </Typography>
        </Box>
      </Box>

      {members?.length === 0 || filteredMembers?.length === 0 ? (
        <Typography variant="h5" m="2rem 0.1rem 0.5rem 5rem">
          No members available
        </Typography>
      ) : (
        <DutySchedulingMembersTable
          filteredMembers={filteredMembers}
          sortField={sortField}
          sortDirection={sortDirection}
          handleSort={handleSort}
        />
      )}
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
}

export default memo(DutySchedulingMembersList);

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const searchApi = createApi({
  reducerPath: "search",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/searches/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Search"],
  endpoints: (builder) => ({
    getGlobalSearch: builder.query({
      query: ({ userId, query }) => ({
        url: `global-search`,
        method: "GET",
        params: { query },
      }),
      skip: (userId, query) => !userId || !query,
      providesTags: ["Search"],
    }),
  }),
});

export const { useGetGlobalSearchQuery } = searchApi;

export default searchApi;
export { searchApi };

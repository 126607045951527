import React, { useState } from "react";
import { useCreateTeamMutation } from "../../../services/team";
import { useGetAllTierMembersByBrokerageQuery } from "../../../services/member";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const CreateBrokerageTeamDialog = ({ open, onClose, brokerageId }) => {
  const [name, setName] = useState("");
  const [createTeam] = useCreateTeamMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const { data: members } = useGetAllTierMembersByBrokerageQuery(brokerageId, {
    skip: !brokerageId,
  });
  const [selectedMemberId, setSelectedMemberId] = useState("");
  const [inputmembervalue, setInputmembervalue] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!brokerageId || !name || !selectedMemberId) {
      setNotification({
        open: true,
        message: "The all fields have to be chosen.",
      });
      return;
    }

    const result = await createTeam({
      name,
      memberId: selectedMemberId?._id,
      brokerageId,
    });
    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        setName("");
        setSelectedMemberId("");
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error creating team: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create Team</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Autocomplete
            value={selectedMemberId}
            onChange={(event, newValue) => {
              setSelectedMemberId(newValue);
            }}
            inputValue={inputmembervalue}
            onInputChange={(event, newInputValue) => {
              setInputmembervalue(newInputValue);
            }}
            options={members || []}
            getOptionLabel={(option) =>
              option
                ? `${option?.user?.firstName} ${option?.user?.lastName} (${option?.user?.email})`
                : ""
            }
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Team Lead"
                variant="outlined"
                margin="normal"
                required
              />
            )}
          />
          <TextField
            variant="outlined"
            margin="normal"
            label="Team Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Create Team
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default CreateBrokerageTeamDialog;

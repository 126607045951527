const canadianRealEstateBoards = [
  "",
  "Abitibi-Temiscamingue",
  "Alberta West",
  "Alberta Real Estate Association",
  "Association of Saskatchewan REALTORS®",
  "Bancroft",
  "Barrie",
  "BC Northern",
  "BCREA",
  "Brampton",
  "Brandon",
  "Brantford",
  "Brooks (South Central Alberta)",
  "Calgary",
  "Cambridge",
  "Centre du Quebec",
  "Chatham Kent",
  "Chilliwack",
  "Cornwall",
  "CREA",
  "Durham",
  "Edmonton",
  "Estrie",
  "FCIQ",
  "Fort McMurray",
  "Fredericton",
  "Grande Prairie",
  "Greater Vancouver",
  "Grey Bruce Owen Sound",
  "Guelph",
  "Hamilton-Burlington",
  "Haute-Yamaska",
  "Huron Perth",
  "Kamloops",
  "Kawartha Lakes",
  "Kingston",
  "Kitchener-Waterloo",
  "Kootenay",
  "Lanaudiere",
  "Laurentides",
  "Lethbridge",
  "Lloydminster",
  "London and St. Thomas",
  "Maricie",
  "Medicine Hat",
  "Mississauga",
  "Moncton",
  "Montreal",
  "MREA",
  "NBREA",
  "Newfoundland and Labrador",
  "Niagara",
  "North Bay",
  "Northumberland Hills",
  "NSAR",
  "Oakville-Milton",
  "Okanagan-Mainline",
  "OREA",
  "Ottawa",
  "Outauais",
  "Parry Sound",
  "PEIA",
  "Peterborough",
  "Portage",
  "Powell River",
  "Prince Albert",
  "Quebec",
  "Quinte",
  "Red Deer (Central Alberta)",
  "Regina",
  "Renfrew County",
  "Rideau St. Lawrence",
  "Saguenay-Lac St-Jean",
  "Saint John",
  "Sarnia",
  "Saskatoon",
  "Sault Ste. Marie",
  "Simcoe",
  "South Okanagan",
  "Southern Georgian Bay",
  "St-Hyacinthe",
  "Sudbury",
  "The Lakelands",
  "Thunder Bay",
  "Tilsonburg",
  "Timmins",
  "Toronto",
  "Vancouver Island",
  "Victoria",
  "Winnipeg",
  "Woodstock",
  "Yellowknife",
  "Yukon",
];

export default canadianRealEstateBoards;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const paymentApi = createApi({
  reducerPath: "payment",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/billing/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Payment"],
  endpoints: (builder) => ({
    //Payments
    makeStripePayment: builder.mutation({
      query: (paymentData) => {
        return {
          url: "payments/makePayment",
          method: "POST",
          body: paymentData,
        };
      },
    }),
    // Stripe Customer Endpoint
    createStripeCustomer: builder.mutation({
      query: (customerData) => ({
        url: "payment/AddCustomer",
        method: "POST",
        body: customerData,
      }),
    }),
    // New Stripe Payment Method Endpoint
    createStripePaymentMethod: builder.mutation({
      query: (paymentMethodData) => ({
        url: "payment/CreatePaymentMethod",
        method: "POST",
        body: paymentMethodData,
      }),
      invalidatesTags: ["Payment"],
    }),

    // endpoint for attaching payment method to customer
    attachPaymentMethod: builder.mutation({
      query: ({ paymentMethodId, brokerageId, accountId }) => ({
        url: `payment/attachPaymentMethod`,
        method: "POST",
        body: { paymentMethodId, brokerageId, accountId },
      }),
    }),

    // Endpoint for fetching payment methods with POST request
    fetchPaymentMethods: builder.query({
      query: ({ brokerageId, accountId }) => ({
        url: "payment/paymentMethods",
        method: "POST",
        body: { brokerageId: brokerageId, accountId: accountId },
      }),
      providesTags: ["Payment"],
    }),

    // endpoint for setting active payment method
    setActivePaymentMethod: builder.mutation({
      query: ({ customerId, paymentMethodId, brokerageId, accountId }) => ({
        url: `payment/SetActivePaymentMethod`,
        method: "POST",
        body: { customerId, paymentMethodId, brokerageId, accountId },
      }),
      invalidatesTags: ["Payment"],
    }),

    // endpoint for deleting payment method
    deletePaymentMethod: builder.mutation({
      query: ({ customerId, paymentMethodId }) => ({
        url: `payment/deletePaymentMethods/${customerId}/${paymentMethodId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Payment"],
    }),
  }),
});

export const {
  // Invoice Hooks
  useMakeStripePaymentMutation,
  useCreateStripeCustomerMutation,
  useCreateStripePaymentMethodMutation,
  useAttachPaymentMethodMutation,
  useSetActivePaymentMethodMutation,
  useFetchPaymentMethodsQuery,
  useDeletePaymentMethodMutation,
} = paymentApi;

export default paymentApi;

import React, { useState, useRef } from "react";
import {
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Snackbar,
  Tabs,
  Tab,
  TextField,
  Alert,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import {
  useFetchProductCollectionsQuery,
} from "../../../../services/billing/productCollections";

import {
  useFetchSubscriptionsQuery,
  useDeleteSubscriptionMutation,
} from "../../../../services/billing/subscriptions";
import {
  useFetchSubscriptionPlansQuery,
  useDeleteSubscriptionPlanMutation,
} from "../../../../services/billing/subscriptionPlans";

import SubscriptionForm from "./SubscriptionForm";
import SubscriptionPlanForm from "./SubscriptionPlanForm";
import SubscriptionTable from "./SubscriptionTable";
import SubscriptionPlanTable from "./SubscriptionPlanTable";
import ConfirmationDialog from "../ConfirmationDialog";

// Utility function for sorting
const getComparator = (order, orderBy) => {
  return (a, b) => {
    if (orderBy === "subscriptionCode") {
      if (a.subscriptionCode < b.subscriptionCode)
        return order === "asc" ? -1 : 1;
      if (a.subscriptionCode > b.subscriptionCode)
        return order === "asc" ? 1 : -1;
    }
    if (orderBy === "name") {
      if (a.name < b.name) return order === "asc" ? -1 : 1;
      if (a.name > b.name) return order === "asc" ? 1 : -1;
    }
    return 0;
  };
};

const Subscriptions = () => {
  // Load the active tab from localStorage or default to 0
  const [activeSubTab, setActiveSubTab] = useState(
    parseInt(localStorage.getItem("ast"), 10) || 0
  );

  const {
    data: subscriptions,
    error: subscriptionError,
    isLoading: subscriptionLoading,
    refetch: refetchSubscriptions,
  } = useFetchSubscriptionsQuery();
  const {
    data: Plans,
    error: PlanError,
    isLoading: PlanLoading,
    refetch: refetchPlans,
  } = useFetchSubscriptionPlansQuery();
  const { data: productCollections } = useFetchProductCollectionsQuery();
  const [deleteSubscription] = useDeleteSubscriptionMutation();
  const [deleteSubscriptionPlan] = useDeleteSubscriptionPlanMutation();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("subscriptionCode");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filter, setFilter] = useState("");
  const [isSubscriptionFormOpen, setIsSubscriptionFormOpen] = useState(false);
  const [isPlanFormOpen, setIsPlanFormOpen] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [confirmationTask, setConfirmationTask] = useState("");
  const [confirmationTitle, setConfirmationTitle] = useState("");

  const PlanFormRef = useRef();
  const subscriptionFormRef = useRef();
  if (subscriptionLoading || PlanLoading) return <p>Loading...</p>;
  if (subscriptionError) return <p>Error fetching subscriptions.</p>;
  if (PlanError) return <p>Error fetching Plans.</p>;

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredSubscriptions = subscriptions.filter((subscription) =>
    subscription.description?.toLowerCase().includes(filter.toLowerCase())
  );

  const filteredPlans = Plans.filter(
    (Plan) =>
      Plan.name?.toLowerCase().includes(filter.toLowerCase()) ||
      Plan.description?.toLowerCase().includes(filter.toLowerCase())
  );

  const sortedSubscriptions = filteredSubscriptions
    .sort(getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const sortedPlans = filteredPlans
    .sort(getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleEditClick = (item, type) => {
    if (type === "subscription") {
      setCurrentSubscription(item);
      setIsSubscriptionFormOpen(true);
    } else {
      setCurrentPlan(item);
      setIsPlanFormOpen(true);
    }
  };

  const handleDeleteClick = (item, type) => {
    setItemToDelete({ ...item, type });
    setConfirmationMessage("Are you sure you want to delete this item?");
    setConfirmationTitle("Delete Item");
    setConfirmationTask("Delete");
    setIsConfirmOpen(true);
  };

  const handleConfirm = async () => {
    try {
      if (itemToDelete.type === "subscription") {
        await deleteSubscription(itemToDelete._id).unwrap();
        refetchSubscriptions();
        setSnackbarMessage("Subscription deleted successfully.");
        setSnackbarOpen(true);
      } else {
        await deleteSubscriptionPlan(itemToDelete._id).unwrap();
        refetchPlans();
        setSnackbarMessage("Plan deleted successfully.");
        setSnackbarOpen(true);
      }
      setIsConfirmOpen(false);
      setItemToDelete(null);
    } catch (error) {
      setSnackbarMessage(`Error deleting item: ${error}`);
      setSnackbarOpen(true);
    }
  };

  const handleConfirmationCancel = () => {
    setIsConfirmOpen(false);
    setItemToDelete(null);
  };

  const handleCloseSubscriptionForm = () => {
    setIsSubscriptionFormOpen(false);
    setCurrentSubscription(null);
  };

  const handleClosePlanForm = () => {
    setIsPlanFormOpen(false);
    setCurrentPlan(null);
  };

  const handleTabChange = (event, newValue) => {
    setActiveSubTab(newValue);
    localStorage.setItem("ast", newValue); // Save the active tab index to localStorage
  };

  const handleSavePlan = async () => {
    if (PlanFormRef.current) {
      await PlanFormRef.current.submit();
      refetchPlans();
      setSnackbarMessage("Plan created successfully.");
      setSnackbarMessage(
        currentPlan
          ? "Plan updated successfully."
          : "Plan created successfully."
      );

      setSnackbarOpen(true);
    }
  };

  const handleSaveSubscription = async () => {
    if (subscriptionFormRef.current) {
      await subscriptionFormRef.current.submit();
      refetchSubscriptions();
      setSnackbarMessage(
        currentSubscription
          ? "Subscription updated successfully."
          : "Subscription created successfully."
      );
      setSnackbarOpen(true);
    }
  };

  return (
    <Paper className="subscription-list">
      <Tabs value={activeSubTab} onChange={handleTabChange}>
        <Tab label="Subscriptions" />
        <Tab label="Subscription Plans" />
      </Tabs>
      <TextField
        label="Search"
        variant="outlined"
        margin="normal"
        fullWidth
        onChange={(e) => setFilter(e.target.value)}
      />
      {activeSubTab === 0 && ( // Subscriptions
        <>
          <Button
            sx={{ mb: 2 }}
            variant="contained"
            color="primary"
            onClick={() => {
              setCurrentSubscription(null); // Set subscription to null for creating new
              setIsSubscriptionFormOpen(true);
            }}
            startIcon={<Add />}
          >
            Create Subscription
          </Button>
          <SubscriptionTable
            subscriptions={sortedSubscriptions}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            handleRequestSort={handleRequestSort}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleEditClick={(item) => handleEditClick(item, "subscription")}
            handleDeleteClick={(item) =>
              handleDeleteClick(item, "subscription")
            }
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
          />
        </>
      )}
      {activeSubTab === 1 && ( // Subscription Plans
        <>
          <Button
            sx={{ mb: 2 }}
            variant="contained"
            color="primary"
            onClick={() => {
              setCurrentPlan(null); // Set Plan to null for creating new
              setIsPlanFormOpen(true);
            }}
            startIcon={<Add />}
          >
            Create Subscription Plan
          </Button>
          <SubscriptionPlanTable
            subscriptionPlans={sortedPlans}
            productCollections={productCollections}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            handleRequestSort={handleRequestSort}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleEditClick={(item) => handleEditClick(item, "Plan")}
            handleDeleteClick={(item) => handleDeleteClick(item, "Plan")}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
          />
        </>
      )}

      <Dialog
        open={isSubscriptionFormOpen}
        onClose={handleCloseSubscriptionForm}
      >
        <DialogTitle>
          {currentSubscription ? "Edit Subscription" : "Create Subscription"}
        </DialogTitle>
        <DialogContent>
          <SubscriptionForm
            subscription={currentSubscription}
            ref={subscriptionFormRef}
            onClose={handleCloseSubscriptionForm}
            onCreate={handleSaveSubscription}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSubscriptionForm}>Cancel</Button>
          <Button onClick={handleSaveSubscription} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isPlanFormOpen} onClose={handleClosePlanForm}>
        <DialogTitle>
          {currentPlan ? "Edit Subscription Plan" : "Create Subscription Plan"}
        </DialogTitle>
        <DialogContent>
          <SubscriptionPlanForm
            subscriptionPlan={currentPlan}
            ref={PlanFormRef}
            onClose={handleClosePlanForm}
            onCreate={handleSavePlan}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePlanForm}>Cancel</Button>
          <Button onClick={handleSavePlan} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={isConfirmOpen}
        onConfirm={handleConfirm}
        onClose={handleConfirmationCancel}
        title={confirmationTitle}
        message={confirmationMessage}
        task={confirmationTask}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default Subscriptions;

import React, { useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useCreateBrokerageInitialCategoryMutation } from "../../../services/brokerage";
import {
  List,
  ListItem,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import NotificationSnackbar from "../../helpers/notification-snackbar";

function CategoryManagementMenu({
  handleOpenCreateCategoryDialog,
  handleOpenCreateCategoryGroupDialog,
  handleOpenAssignGlobalCategoryMirroringDialog,
  handleOpenRemoveGlobalCategoryMirroringDialog,
  tier,
  isHasCategories,
  isLBTab = false,
}) {
  const { userRole } = useContext(AuthContext);
  const [
    createBrokerageInitialCategory,
    { isLoading: isBrokerageCreateInitialCategoriesLoading },
  ] = useCreateBrokerageInitialCategoryMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const isAdmin = [
    "reosadmin",
    "reossuperadmin",
    "brokerage",
    "brokerageadmin",
    "brokeragesuperadmin",
    "teamsuperadmin",
  ].includes(userRole);

  const handleCreateBrokerageInitialCategories = async () => {
    if (tier?.level !== "brokerage") {
      setNotification({
        openNotification: true,
        message: "Please select a tier with the brokerage level first.",
      });
      return;
    }
    const result = await createBrokerageInitialCategory({
      tierId: tier?._id,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `Failed to create Brokerage initial categories ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Box className="user-management-box">
      <List className="user-management-list">
        <ListItem
          button
          onClick={handleOpenCreateCategoryDialog}
          className="user-management-item"
        >
          <AddCircleOutlineIcon className="icon" /> Create new Category
        </ListItem>
        <ListItem
          button
          onClick={handleOpenCreateCategoryGroupDialog}
          className="user-management-item"
        >
          <GroupAddIcon className="icon" />
          Create new Category Group
        </ListItem>
        {isAdmin && !isHasCategories && (
          <ListItem
            button
            onClick={handleCreateBrokerageInitialCategories}
            className="user-management-item"
          >
            {isBrokerageCreateInitialCategoriesLoading ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <AddCircleOutlineIcon className="icon" />
                {"Create Brokerage Initial Categories"}
              </>
            )}
          </ListItem>
        )}
        {isLBTab && (
          <ListItem
            button
            onClick={handleOpenAssignGlobalCategoryMirroringDialog}
            className="user-management-item"
          >
            <>
              <ScreenShareIcon className="icon" />
              <Typography>Open Mirror Global Category To</Typography>
            </>
          </ListItem>
        )}
        {isLBTab && (
          <ListItem
            button
            onClick={handleOpenRemoveGlobalCategoryMirroringDialog}
            className="user-management-item"
          >
            <>
              <ScreenShareIcon className="icon" />
              <Typography>Open Stop Mirroring Global Category To</Typography>
            </>
          </ListItem>
        )}
      </List>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Box>
  );
}
export default CategoryManagementMenu;

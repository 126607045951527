import React, { memo } from "react";
import { ListItem, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { useGetNewPostsAmountByCategoryQuery } from "../../services/post";
import { categoryIcon, truncateText } from "./sidebarHelpers";
import { CategoryItemStyledBadge } from "../helpers/utils";

function CategoryItem({
  category,
  handleItemClick,
  isActive,
  isSmallDevice,
  isSmallestDevice,
  sidebarOpen,
}) {
  const { data: newPostsAmount } = useGetNewPostsAmountByCategoryQuery(
    { categoryId: category?._id },
    {
      skip: !category?._id,
      // refetchOnWindowFocus: true,
    }
  );

  return (
    <Tooltip key={category?._id} title={category?.name} placement="right">
      <CategoryItemStyledBadge
        badgeContent={newPostsAmount}
        color="success"
        invisible={newPostsAmount === 0}
        max={6}
        className="category-item"
      >
        <ListItem
          button
          component={Link}
          to={
            category?.path === "home"
              ? "/home"
              : category?.path === "members"
              ? `/members/${category.name}`
              : category?.path === "file-share"
              ? "/files"
              : `/category/${category?.path}/${category?._id}`
          }
          onClick={() =>
            handleItemClick(
              category?.path === "home"
                ? "/home"
                : category?.path === "members"
                ? "/members/"
                : category?.path === "file-share"
                ? "/files"
                : `/category/${category?.path}/${category?._id}`,
              category
            )
          }
          className={`${isActive ? "active" : "nav-link"}`}
        >
          <ListItemIcon className="item-icon">
            {categoryIcon(category?.icon)}
          </ListItemIcon>
          {!isSmallDevice && sidebarOpen && (
            <ListItemText className="item-text" primary={category?.name} />
          )}
          {isSmallDevice && sidebarOpen && (
            <ListItemText className="item-text" primary={category?.name} />
          )}
          {isSmallDevice && isSmallestDevice && !sidebarOpen && (
            <ListItemText
              className="item-text truncated-text"
              primary={truncateText(category?.name, 3)}
            />
          )}
          {isSmallDevice && !isSmallestDevice && !sidebarOpen && (
            <ListItemText
              className="item-text truncated-text"
              primary={truncateText(category?.name, 3)}
            />
          )}
          {!isSmallDevice && isSmallestDevice && !sidebarOpen && (
            <ListItemText
              className="item-text truncated-text"
              primary={truncateText(category?.name, 3)}
            />
          )}
        </ListItem>
      </CategoryItemStyledBadge>
    </Tooltip>
  );
}

export default memo(CategoryItem);

import React, { useState, memo } from "react";
import { useLazyDownloadAttachmentQuery } from "../../services/post";
import {
  Typography,
  Box,
  Link as LinkComponent,
  IconButton,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";
import { Download, GetApp, Visibility } from "@mui/icons-material/";
import { generateGoogleDriveViewerUrl } from "../helpers/utils";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { printPlugin } from "@react-pdf-viewer/print";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const Attachments = ({ attachments = [] }) => {
  const [, setSelectedAttachmentId] = useState("");
  const [trigger] = useLazyDownloadAttachmentQuery();
  const [open, setOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const handleDownloadClick = async (attachment) => {
    if (attachment.url.includes("cloudinary")) {
      const urlParts = attachment.url.split("/");
      const fileName = decodeURIComponent(urlParts[urlParts.length - 1]);
      const a = document.createElement("a");
      a.href = attachment.url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      setSelectedAttachmentId(attachment._id);
      const result = await trigger({
        attachmentId: attachment._id,
        disposition: "attachment",
      }).unwrap();

      if (result?.url) {
        try {
          const response = await fetch(result.url);
          if (!response.ok) throw new Error("Network response was not ok");

          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = attachment.url.split("/").pop();
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error("Failed to download file:", error);
        }
      }
    }
  };

  const handlePreviewClick = async (attachment) => {
    if (attachment.url.includes("cloudinary")) {
      window.open(attachment.url, "_blank");
    } else {
      const fileExtension = attachment.url.split(".").pop().toLowerCase();
      const previewableExtensions = [
        "pdf",
        "jpg",
        "jpeg",
        "png",
        "gif",
        "webm",
        "mp4",
      ];

      if (fileExtension === "pptx") {
        setSelectedAttachmentId(attachment._id);

        const result = await trigger({
          attachmentId: attachment._id,
          disposition: "inline",
        }).unwrap();

        if (result?.url) {
          const viewerUrl = generateGoogleDriveViewerUrl(result.url);
          window.open(viewerUrl, "_blank");
        }
      } else if (fileExtension === "pdf") {
        setSelectedAttachmentId(attachment._id);
        const result = await trigger({
          attachmentId: attachment._id,
          disposition: "inline",
        }).unwrap();

        if (result?.url) {
          setPdfUrl(result.url);
          setOpen(true);
        }
      } else if (previewableExtensions.includes(fileExtension)) {
        const result = await trigger({
          attachmentId: attachment._id,
          disposition: "inline",
        }).unwrap();

        if (result?.url) {
          window.open(result.url, "_blank");
        }
      } else {
        setSelectedAttachmentId(attachment._id);
        const result = await trigger({
          attachmentId: attachment._id,
          disposition: "attachment",
        }).unwrap();

        if (result?.url) {
          window.open(result.url, "_blank");
        }
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setPdfUrl(""); // Ensure pdfUrl is cleared
  };

  const toolbarPluginInstance = toolbarPlugin();
  const zoomPluginInstance = zoomPlugin();
  const printPluginInstance = printPlugin();
  const getFilePluginInstance = getFilePlugin();
  const { Toolbar } = toolbarPluginInstance;
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { PrintButton } = printPluginInstance;
  // const { DownloadButton } = getFilePluginInstance;

  // Custom Download Button Function
  const CustomDownloadButton = (props) => {
    return (
      <button
        onClick={() => {
          if (pdfUrl) {
            const a = document.createElement("a");
            a.href = pdfUrl;
            a.download = "custom-file-name.pdf"; // Set your custom file name here
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        }}
        {...props}
        style={{
          padding: "8px",
          cursor: "pointer",
          border: "none",
          background: "none",
        }}
      >
        <Download />
      </button>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: "var(--bg-color)",
        p: 1,
        mt: 0,
        mb: 3,
        borderRadius: 1,
      }}
    >
      <Typography variant="h6" sx={{ mb: 1 }}>
        Attachments:
      </Typography>
      <Box>
        {attachments?.map((attachment, index) => {
          const urlParts = attachment && attachment?.url?.split("/");
          const fileName =
            urlParts && decodeURIComponent(urlParts[urlParts?.length - 1]);

          return (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", gap: 1 }}
            >
              <LinkComponent
                onClick={() => handlePreviewClick(attachment)}
                underline="hover"
                color="primary"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  cursor: "pointer",
                }}
              >
                <Visibility />
                {fileName}
              </LinkComponent>
              <IconButton onClick={() => handleDownloadClick(attachment)}>
                <GetApp />
              </IconButton>
            </Box>
          );
        })}
      </Box>

      <Modal
        open={open && !!pdfUrl} // Ensure pdfUrl is valid before opening modal
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 1,
              maxHeight: "90%",
              overflowY: "auto",
            }}
          >
            {pdfUrl && (
              <Worker workerUrl={pdfjsWorker}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Toolbar>
                    {(props) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        <ZoomOutButton {...props} />
                        <ZoomPopover {...props} />
                        <ZoomInButton {...props} />
                        <PrintButton {...props} />
                        <CustomDownloadButton {...props} />
                      </div>
                    )}
                  </Toolbar>
                  <Viewer
                    fileUrl={pdfUrl}
                    plugins={[
                      toolbarPluginInstance,
                      zoomPluginInstance,
                      printPluginInstance,
                      getFilePluginInstance,
                    ]}
                    defaultScale={SpecialZoomLevel.PageWidth}
                  />
                </div>
              </Worker>
            )}
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default memo(Attachments);

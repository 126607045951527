import React from "react";
import { Typography } from "@mui/material";
import { capitalizeFirstLetter } from "../../helpers/utils";

function InvitationDetailsInfo({ invitation }) {
  const formattedFirstName =
    invitation?.sender && invitation?.sender?.firstName
      ? capitalizeFirstLetter(invitation?.sender?.firstName)
      : "";
  const formattedLastName =
    invitation?.sender && invitation?.sender?.lastName
      ? capitalizeFirstLetter(invitation?.sender?.lastName)
      : "";

  return (
    <div>
      <Typography variant="body1" sx={{ p: 2 }}>
        Sender: {formattedFirstName} {formattedLastName}
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Sender Email: {invitation?.userEmail}
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Account Type: {invitation?.accountType}
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Tier: {invitation?.tier?.level}
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Invitation Token: {invitation?.invitationToken && "exist"}
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Status: {invitation?.status}
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Sent at: {new Date(invitation?.sentAt).toLocaleString()}
      </Typography>
    </div>
  );
}

export default InvitationDetailsInfo;

import React, { memo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";
import { Verified, NotInterested } from "@mui/icons-material/";
import { capitalizeFirstLetter } from "../../../helpers/utils";

function DutySchedulingMembersTable({
  filteredMembers = [],
  sortField,
  sortDirection,
  handleSort,
}) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: "560px", // Adjust this value as needed
        overflow: "auto",
      }}
    >
      <Table className="user-table" stickyHeader>
        <TableHead
          sx={{
            color: "var(--text-color)",
            backgroundColor: "var(--bg-color)",
          }}
        >
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={sortField === "user.firstName"}
                direction={sortDirection}
                onClick={() => handleSort("user.firstName")}
                title="Sort by First Name"
              >
                <sup>First</sup>
              </TableSortLabel>
              <TableSortLabel
                onClick={() => handleSort("user.lastName")}
                active={sortField === "user.lastName"}
                direction={sortDirection}
                title="Sort by Last Name"
              >
                <sup>Last</sup>
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                width: "1rem",
                p: "0",
                m: "0",
                textAlign: "center",
                whiteSpace: "normal",
              }}
            >
              <TableSortLabel
                active={sortField === "willingToDoDuty"}
                direction={sortDirection}
                onClick={() => handleSort("willingToDoDuty")}
              >
                Willingness
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(filteredMembers) &&
            filteredMembers?.map((member) => (
              <TableRow
                key={member?._id}
                draggable
                onDragStart={(e) => {
                  e.dataTransfer.setData("member", JSON.stringify(member));
                }}
                style={{ cursor: "pointer" }}
                className="table-row"
              >
                <TableCell>
                  {capitalizeFirstLetter(member?.user?.firstName)}{" "}
                  {capitalizeFirstLetter(member?.user?.lastName)}
                </TableCell>
                <TableCell>
                  {member?.willingToDoDuty ? <Verified /> : <NotInterested />}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default memo(DutySchedulingMembersTable);

import React, { useState, useContext, useEffect, memo } from "react";
import {
  Tooltip,
  IconButton,
  Typography,
  List,
  Stack,
  Avatar,
} from "@mui/material";
import { ArrowBack, Close, AddCircle } from "@mui/icons-material/";
import { useDeleteChatMutation } from "../../services/messages";
import { AuthContext } from "../../context/AuthContext";
import { useActiveChat } from "../../context/ActiveChatContext";
import CreateChatDialog from "./CreateChatDialog";
import ChatBlock from "./ChatBlock";
import { ConfirmationDialog } from "../../components";
import { getInitialsFromUserName } from "../helpers/utils";
import NotificationSnackbar from "../helpers/notification-snackbar";
import ChatCard from "./ChatCard";

const Messaging = ({ chats, refetch, isSuccess, handleClose }) => {
  const { userId, isTokenReady, userName, brokerageId } =
    useContext(AuthContext);
  const { setActiveChatId } = useActiveChat();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isOnlyUser, setIsOnlyUser] = useState(false);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [isCreateChatOpen, setIsCreateChatOpen] = useState(false);
  const [deleteChat] = useDeleteChatMutation();
  const [selectedChatDetails, setSelectedChatDetails] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (userId && isSuccess && isTokenReady) {
      refetch();
    }
  }, [userId, refetch, isSuccess, isTokenReady]);

  const openChatView = async (event, chatId) => {
    if (
      event.target.closest(".chat-menu-button") ||
      event.target.closest(".delete-chat-button")
    ) {
      return;
    }

    const chat = chats.find((c) => c._id === chatId);
    if (
      chat?.participants?.length === 1 &&
      chat?.participants[0]?._id.toString() === userId
    ) {
      setIsOnlyUser(true);
    }

    setSelectedChatDetails(chat);
    setSelectedChatId(chatId);
    setActiveChatId(chatId);
  };

  const handleBackToChats = () => {
    setActiveChatId(null);
    setSelectedChatId(null);
    setSelectedChatDetails(null);
    setIsOnlyUser(false);
  };

  const handleDeleteChatClick = async (chatId) => {
    setSelectedChatId(chatId);
    setDialogOpen(true);
  };

  const handleDialogClose = (event) => {
    event && event.stopPropagation();
    setDialogOpen(false);
    setSelectedChatId(null);
  };

  const handleDialogConfirm = async (event) => {
    event.stopPropagation();
    const result = await deleteChat({ chatId: selectedChatId, userId: userId });
    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        handleDialogClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error sending the realtor invitation link. ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <div className="chat-window">
      <div className="chat-header">
        {selectedChatId ? (
          <Tooltip title="Back to Chats Window">
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleBackToChats}
            >
              <ArrowBack />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Close Chats Window">
            <IconButton edge="start" color="inherit" onClick={handleClose}>
              <Close />
            </IconButton>
          </Tooltip>
        )}
        <span className="chat-header-text">
          {selectedChatDetails
            ? `Chat with ${
                selectedChatDetails?.participants?.find(
                  (p) => p?._id !== userId
                )?.firstName || " User Removed Chat"
              } ${
                selectedChatDetails?.participants?.find(
                  (p) => p?._id !== userId
                )?.lastName || ""
              }`
            : "Messages"}
        </span>
        {selectedChatDetails && (
          <Stack sx={{ p: "0.5rem 1rem" }}>
            <Avatar
              alt={userName}
              src={
                selectedChatDetails?.participants?.find(
                  (p) => p?._id !== userId
                )?.image?.url || ""
              }
            >
              {getInitialsFromUserName(
                selectedChatDetails?.participants?.find(
                  (p) => p?._id !== userId
                )?.firstName
              ) || "N/A"}
              {getInitialsFromUserName(
                selectedChatDetails?.participants?.find(
                  (p) => p?._id !== userId
                )?.lastName
              ) || ""}
            </Avatar>
          </Stack>
        )}
        {!selectedChatId && (
          <Tooltip title="Create Chat">
            <IconButton
              className="create-chat-button"
              aria-label="create-chat"
              onClick={() => setIsCreateChatOpen(true)}
              size="large"
            >
              <AddCircle />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className="chat-body">
        {!selectedChatId && chats && chats?.length > 0 && (
          <List className="chat-list">
            {chats?.map((chat, index) => (
              <ChatCard
                key={index}
                chat={chat}
                onChatClick={openChatView}
                onDeleteChatClick={handleDeleteChatClick}
              />
            ))}
          </List>
        )}
        {!selectedChatId && chats && chats?.length === 0 && (
          <Typography variant="subtitle1" sx={{ p: 12.5, textAlign: "center" }}>
            There are no chats yet. Start a new chat.
          </Typography>
        )}
        {selectedChatId && (
          <ChatBlock
            chatId={selectedChatId}
            userId={userId}
            userName={userName}
            isOnlyUser={isOnlyUser}
          />
        )}
      </div>
      <CreateChatDialog
        open={isCreateChatOpen}
        onClose={() => setIsCreateChatOpen(false)}
        userId={userId}
        brokerageId={brokerageId}
      />
      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
        title="Confirm Chat Removing"
        description="Are you sure you want to delete this chat?"
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleNotificationClose}
      />
    </div>
  );
};

export default memo(Messaging);

import React, {
  useState,
  useEffect,
  useContext,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  DialogContent,
  TextField,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  FormControl,
  FormLabel,
  InputLabel,
} from "@mui/material";
import NotificationSnackbar from "../../../helpers/notification-snackbar";
import { AuthContext } from "../../../../context/AuthContext";
import {
  useCreateSubscriptionPlanMutation,
  useUpdateSubscriptionPlanMutation,
} from "../../../../services/billing/subscriptionPlans"; 
import {
  useFetchProductCollectionsQuery,
} from "../../../../services/billing/productCollections"; 

const SubscriptionPlanForm = forwardRef(
  ({ subscriptionPlan = null, onClose }, ref) => {
    const { userId } = useContext(AuthContext);

    const billingCycles = ["Monthly", "Annually"];
    const currencies = ["CAD", "USD"];
    const clientTypes = ["individual", "brokerage", "conference"];

    // State for Product Collections
    const [productCollections, setProductCollections] = useState([]);
    const [selectedProductCollections, setSelectedProductCollections] =
      useState([]);

    const [createSubscriptionPlan, { isLoading: isCreating }] =
      useCreateSubscriptionPlanMutation();
    const [updateSubscriptionPlan, { isLoading: isUpdating }] =
      useUpdateSubscriptionPlanMutation();

    const { data: productCollectionsData, isLoading: isLoadingCollections } =
      useFetchProductCollectionsQuery(); // Adjust according to your service

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [currency, setCurrency] = useState("CAD");
    const [billingCycle, setBillingCycle] = useState("Monthly");
    const [status, setStatus] = useState("active");
    const [clientType, setClientType] = useState("any");

    const [notification, setNotification] = useState({
      open: false,
      message: "",
      type: "",
    });

    useEffect(() => {
      if (productCollectionsData) {
        setProductCollections(productCollectionsData); // Set product collections data
      }
    }, [productCollectionsData]);

    useEffect(() => {
      if (subscriptionPlan) {
        setName(subscriptionPlan.name || "");
        setDescription(subscriptionPlan.description || "");
        setCurrency(subscriptionPlan.currency || "CAD");
        setBillingCycle(subscriptionPlan.billingCycle || "Monthly");
        setStatus(subscriptionPlan.status || "active");
        setSelectedProductCollections(
          subscriptionPlan.productCollections || []
        ); // Set selected product collections
        setClientType(subscriptionPlan.clientType || "any");
      }
    }, [subscriptionPlan]);

    const handleSubmit = () => {
      if (name && currency && billingCycle) {
        const subscriptionPlanData = {
          name,
          description,
          currency,
          billingCycle,
          status,
          clientType,
          createdBy: userId,
          updatedBy: userId,
          productCollections: selectedProductCollections, // Add selected product collections
        };

        const action = subscriptionPlan
          ? updateSubscriptionPlan
          : createSubscriptionPlan;
        const actionData = subscriptionPlan
          ? { _id: subscriptionPlan._id, ...subscriptionPlanData }
          : subscriptionPlanData;

        action(actionData)
          .then(() => {
            setNotification({
              open: true,
              message: `Subscription Plan ${
                subscriptionPlan ? "updated" : "created"
              } successfully!`,
              type: "success",
            });
            onClose();
          })
          .catch((error) => {
            setNotification({
              open: true,
              message: `Failed to ${
                subscriptionPlan ? "update" : "create"
              } subscription plan: ${error.message}`,
              type: "error",
            });
          });
      } else {
        setNotification({
          open: true,
          message: "Please provide valid subscription plan details.",
          type: "warning",
        });
      }
    };

    useImperativeHandle(ref, () => ({
      submit: handleSubmit,
    }));

    return (
      <>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Account Type</InputLabel>
            <Select
              value={clientType}
              onChange={(e) => setClientType(e.target.value)}
              disabled={isCreating || isUpdating}
              variant="outlined"
              label="Client Type"
            >
              {clientTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormLabel>Name</FormLabel>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={isCreating || isUpdating}
              variant="outlined"
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <FormLabel>Description</FormLabel>
            <TextField
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={4}
              disabled={isCreating || isUpdating}
              variant="outlined"
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Product Collections</InputLabel>
            <Select
              multiple
              label="Product Collections"
              value={selectedProductCollections}
              onChange={(e) => setSelectedProductCollections(e.target.value)}
              renderValue={(selected) => (
                <div>
                  {selected.map((id) => {
                    // Find the collection name by ID
                    const collection = productCollections.find(
                      (col) => col._id === id
                    );
                    return collection ? (
                      <ListItemText key={id} primary={collection.name} />
                    ) : null;
                  })}
                </div>
              )}
              disabled={isCreating || isUpdating}
              variant="outlined"
            >
              {isLoadingCollections ? (
                <MenuItem disabled>Loading...</MenuItem>
              ) : (
                productCollections.map((collection) => (
                  <MenuItem key={collection._id} value={collection._id}>
                    <Checkbox
                      checked={selectedProductCollections.includes(
                        collection._id
                      )}
                    />
                    <ListItemText primary={collection.name} />
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Currency</InputLabel>
            <Select
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              disabled={isCreating || isUpdating}
              variant="outlined"
              label="Currency"
            >
              {currencies.map((cur) => (
                <MenuItem key={cur} value={cur}>
                  {cur}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Billing Cycle</InputLabel>
            <Select
              value={billingCycle}
              onChange={(e) => setBillingCycle(e.target.value)}
              disabled={isCreating || isUpdating}
              variant="outlined"
              label="Billing Cycle"
            >
              {billingCycles.map((cycle) => (
                <MenuItem key={cycle} value={cycle}>
                  {cycle}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <Select
              value={status}
              label="Status"
              onChange={(e) => setStatus(e.target.value)}
              disabled={isCreating || isUpdating}
              variant="outlined"
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>

        <NotificationSnackbar
          open={notification.open}
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification({ ...notification, open: false })}
        />
      </>
    );
  }
);

export default SubscriptionPlanForm;

import React, { useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useGetAllTeamsQuery } from "../../../services/team";
import {
  useAssignTierToTeamMutation,
  useGetAllTiersQuery,
} from "../../../services/tier";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const AssignTierToTeamDialog = ({ open, onClose, preselectedTeam }) => {
  const { userRole } = useContext(AuthContext);
  const { data: tiers } = useGetAllTiersQuery();
  const { data: teams } = useGetAllTeamsQuery();
  const [assignTierToTeam] = useAssignTierToTeamMutation();
  const [selectedTierId, setSelectedTierId] = useState(null);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [inputtiervalue, setInputtiervalue] = useState("");
  const [inputteamvalue, setInputteamvalue] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      preselectedTeam &&
      preselectedTeam?.tier &&
      userRole !== "reossuperadmin"
    ) {
      setNotification({
        open: true,
        message: "The team already has assigned Tier",
      });

      return;
    }
    const result = await assignTierToTeam({
      teamId: preselectedTeam ? preselectedTeam?._id : selectedTeamId?._id,
      tierId: selectedTierId?._id,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        onClose();
        setInputteamvalue();
        setInputtiervalue();
        setSelectedTeamId(null);
        setSelectedTierId(null);
      }, 2000);
    } else {
      setNotification({
        open: true,
        message: `Error assigning Tier to the Team. ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Assign Tier to Team</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Autocomplete
                value={selectedTierId}
                onChange={(event, newValue) => {
                  setSelectedTierId(newValue);
                }}
                inputValue={inputtiervalue}
                onInputChange={(event, newInputValue) => {
                  setInputtiervalue(newInputValue);
                }}
                options={tiers || []}
                getOptionLabel={(option) =>
                  option
                    ? `${option?.level} ${
                        option?.brokerage || option?.team || option?.group
                          ? option?.brokerage?.user?.firstName ||
                            option?.team?.name ||
                            option?.group?.name
                          : ""
                      } ${
                        option?.brokerage
                          ? option?.brokerage?.user?.lastName
                          : ""
                      } (${
                        option?.brokerage || option?.team || option?.group
                          ? option?.brokerage?.user?.email ||
                            option?.team?.teamUrlName ||
                            option?.group?.groupUrlName
                          : ""
                      })`
                    : ""
                }
                fullWidth
                renderOption={(props, option) => (
                  <li {...props}>
                    <Tooltip
                      title={
                        option?.team && option?.team?.name
                          ? "This tier has a team assigned"
                          : option?.brokerage && option?.brokerage?.officeName
                          ? "This tier has a brokerage assigned"
                          : option?.group && option?.group?.name
                          ? "This tier has a group assigned"
                          : `This is a ${option?.level} level`
                      }
                      placement="left"
                    >
                      <div>
                        {`${option?.level} 
                        ${
                          option?.brokerage || option?.team || option?.group
                            ? option?.brokerage?.user?.firstName ||
                              option?.team?.name ||
                              option?.group?.name
                            : ""
                        } 
                        ${
                          option?.brokerage
                            ? option?.brokerage?.user?.lastName
                            : ""
                        } 
                        (${
                          option?.brokerage || option?.team || option?.group
                            ? option?.brokerage?.user?.email ||
                              option?.team?.teamUrlName ||
                              option?.group?.groupUrlName
                            : "no brokerage or team or group assigned"
                        })`}
                      </div>
                    </Tooltip>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Tier"
                    variant="outlined"
                    margin="normal"
                    required
                  />
                )}
              />
            </Grid>
            <Grid item>
              {preselectedTeam ? (
                <TextField
                  label="Selected Team"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={`${preselectedTeam?.name} ${preselectedTeam?.teamUrlName}`}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ) : (
                <Autocomplete
                  value={selectedTeamId}
                  onChange={(event, newValue) => {
                    setSelectedTeamId(newValue);
                  }}
                  inputValue={inputteamvalue}
                  onInputChange={(event, newInputValue) => {
                    setInputteamvalue(newInputValue);
                  }}
                  options={teams || []}
                  getOptionLabel={(option) =>
                    option
                      ? `${option?.name} ${option?.teamUrlName}${
                          option?.tier ? option?.tier?.level : ""
                        }`
                      : ""
                  }
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select a Team"
                      variant="outlined"
                      margin="normal"
                      required
                    />
                  )}
                />
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Assign Tier to Team
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default AssignTierToTeamDialog;

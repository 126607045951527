import React, { useState, useRef } from "react";
import InvoiceForm from "../../reosadmin/billing/invoice/InvoiceForm";
import { formatMoney } from "../../helpers/utils";
import {
  useGetAllInvoicesByTierIdQuery,
  useGetAllInvoicesByAccountIdQuery,
} from "../../../services/billing/invoices";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  TextField,
  TableSortLabel,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TablePagination,
} from "@mui/material";
import { MoreVert, DeleteForever } from "@mui/icons-material/";
import { ConfirmationDialog } from "../..";
import { capitalizeFirstLetter } from "../../helpers/utils";

function BillingList({ tierId, accountId }) {
  const billingListRef = useRef(null);

  // Determine which query to use based on the presence of tierId or accountId
  const { data: tierInvoices = [], refetch: refetchTierInvoices } =
    useGetAllInvoicesByTierIdQuery(tierId, {
      skip: !tierId,
    });

  const { data: accountInvoices = [], refetch: refetchAccountInvoices } =
    useGetAllInvoicesByAccountIdQuery(accountId, {
      skip: !accountId,
    });

  const invoices = tierId ? tierInvoices : accountInvoices;
  const refetchInvoices = tierId ? refetchTierInvoices : refetchAccountInvoices;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [dialogOpen, setDeleteDialogOpen] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [invoiceIdToRemove, setInvoiceIdToRemove] = useState(null);
  const [sortField, setSortField] = useState("invoiceNumber");
  const [sortDirection, setSortDirection] = useState("asc");
  const [statusFilter, setStatusFilter] = useState("");
  const [accountTypeFilter, setAccountTypeFilter] = useState("");
  const [textFilter, setTextFilter] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [page, setPage] = useState(0);
  const [showAll, setShowAll] = useState(false);

  const handleOpenDialog = (invoice) => {
    setSelectedInvoice(invoice);
    setDialogOpen(true);
    handleMenuClose(); // This will close the menu
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleTextFilterChange = (event) => {
    setTextFilter(event.target.value?.toLowerCase());
  };

  let filteredInvoices = invoices.filter((invoice) => {
    return (
      invoice.invoiceNumber?.toLowerCase().includes(textFilter) ||
      invoice.userEmail?.toLowerCase().includes(textFilter) ||
      invoice.status?.toLowerCase().includes(textFilter) ||
      invoice.accountType?.toLowerCase().includes(textFilter)
    );
  });

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = (event, invoiceId) => {
    event.stopPropagation();
    setInvoiceIdToRemove(invoiceId);
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = (event) => {
    event && event.stopPropagation();
    setDeleteDialogOpen(false);
    setInvoiceIdToRemove(null);
  };

  const handleDialogConfirm = async (event) => {
    event && event.stopPropagation();
    await removeInvoice(invoiceIdToRemove); // Make sure you have this function implemented
    handleDialogClose();
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleAccountTypeFilterChange = (event) => {
    setAccountTypeFilter(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleShowAllToggle = () => {
    setShowAll(!showAll);
    setPage(0); // Reset page when toggling to show all
  };

  if (statusFilter) {
    filteredInvoices = filteredInvoices.filter(
      (invoice) => invoice.status === statusFilter
    );
  }
  if (accountTypeFilter) {
    filteredInvoices = filteredInvoices.filter(
      (invoice) => invoice.accountType === accountTypeFilter
    );
  }

  const sortedAndFilteredInvoices = filteredInvoices.sort((a, b) => {
    if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
    if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
    return 0;
  });

  // Calculate the total amount and transaction count
  const totalAmount = sortedAndFilteredInvoices.reduce(
    (sum, invoice) => sum + invoice.totalAmount,
    0
  );
  const transactionCount = sortedAndFilteredInvoices.length;

  // Paginate if not showing all
  const displayedInvoices = showAll
    ? sortedAndFilteredInvoices
    : sortedAndFilteredInvoices.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );

  return (
    <>
      <Box
        sx={{
          mt: 0,
          mb: 2,
          display: "flex",
          gap: "5px",
          flexDirection: "column",
        }}
        className="management-page"
      >
        <TextField
          label="Search invoices"
          variant="outlined"
          size="small"
          sx={{ mt: 2 }}
          fullWidth
          value={textFilter}
          onChange={handleTextFilterChange}
        />

        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          sx={{ mt: 2, minWidth: 120 }}
        >
          <InputLabel id="status-filter-label">Filter by status</InputLabel>
          <Select
            labelId="status-filter-label"
            value={statusFilter}
            onChange={handleStatusFilterChange}
            label="Filter by status"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="paid">Paid</MenuItem>
            <MenuItem value="unpaid">Unpaid</MenuItem>
            <MenuItem value="collections">Collections</MenuItem>
            <MenuItem value="partial">Partial</MenuItem>
            <MenuItem value="void">Void</MenuItem>
            <MenuItem value="overpaid">Overpaid</MenuItem>
          </Select>
        </FormControl>

        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">
            Total Amount: ${formatMoney(totalAmount)}
          </Typography>
          <Typography variant="h6">
            Number of Transactions: {transactionCount}
          </Typography>
        </Box>

        <Button variant="outlined" onClick={handleShowAllToggle} sx={{ mt: 2 }}>
          {showAll ? "Show Paginated View" : "Show All"}
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table className="user-table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortField === "invoiceNumber"}
                  direction={sortDirection}
                  onClick={() => handleSort("invoiceNumber")}
                >
                  Invoice Number
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "status"}
                  direction={sortDirection}
                  onClick={() => handleSort("status")}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "dateOfInvoice"}
                  direction={sortDirection}
                  onClick={() => handleSort("dateOfInvoice")}
                >
                  Date of Invoice
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "dueDate"}
                  direction={sortDirection}
                  onClick={() => handleSort("dueDate")}
                >
                  Due Date
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "totalAmount"}
                  direction={sortDirection}
                  onClick={() => handleSort("totalAmount")}
                >
                  Total Amount
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "currency"}
                  direction={sortDirection}
                  onClick={() => handleSort("currency")}
                >
                  Currency
                </TableSortLabel>
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedInvoices.map((invoice) => (
              <TableRow key={invoice._id}>
                <TableCell>{invoice.invoiceNumber}</TableCell>
                <TableCell>{capitalizeFirstLetter(invoice.status)}</TableCell>
                <TableCell>
                  {new Date(invoice.dateOfInvoice).toLocaleDateString()}
                </TableCell>
                <TableCell>
                  {new Date(invoice.dueDate).toLocaleDateString()}
                </TableCell>
                <TableCell>${formatMoney(invoice.totalAmount)}</TableCell>
                <TableCell>{invoice.currency}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="more"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleMenuOpen}
                  >
                    <MoreVert />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleOpenDialog(invoice)}>
                      View Details
                    </MenuItem>
                    {/* <MenuItem
                      onClick={(event) => handleDeleteClick(event, invoice._id)}
                    >
                      <DeleteForever sx={{ mr: 1 }} />
                      Delete
                    </MenuItem> */}
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {!showAll && (
        <TablePagination
          rowsPerPageOptions={[15, 25, 50, { label: "All", value: -1 }]}
          component="div"
          count={sortedAndFilteredInvoices.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}

      {selectedInvoice && (
        <Dialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          maxWidth="lg"
          PaperProps={{
            sx: {
              width: "60%",
              maxWidth: "none", // This prevents the dialog from being limited to a small max width
            },
          }}
        >
          <DialogTitle>Invoice Details</DialogTitle>
          <DialogContent>
            <InvoiceForm
              invoice={selectedInvoice}
              ref={billingListRef}
              onClose={handleCloseDialog}
              refetchInvoices={refetchInvoices}
              editable={false}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
        message="Are you sure you want to delete this invoice?"
      />
    </>
  );
}

export default BillingList;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const passwordApi = createApi({
  reducerPath: "password",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/password/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Password"],
  endpoints: (builder) => ({
    changePassword: builder.mutation({
      query: ({ oldPassword, newPassword, userId }) => ({
        url: `change-password/${userId}`,
        method: "POST",
        body: { oldPassword, newPassword },
      }),
    }),
    requestPasswordForgot: builder.mutation({
      query: (email) => ({
        url: `request-password-reset`,
        method: "POST",
        body: { email },
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ userId, token, newPassword }) => ({
        url: `reset-password/${userId}/${token}`,
        method: "POST",
        body: { newPassword },
      }),
    }),
  }),
});

export const {
  useChangePasswordMutation,
  useRequestPasswordForgotMutation,
  useResetPasswordMutation,
} = passwordApi;

export default passwordApi;

import React, { useState, useMemo, memo } from "react";
import { useResendInvitationToMultipleBrokerageMembersMutation } from "../../../services/invitation";
import { useGetAllTierMembersQuery } from "../../../services/member";
import {
  Select,
  InputLabel,
  Table,
  TextField,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  TableSortLabel,
  InputAdornment,
  CircularProgress,
  Button,
  Tooltip,
  Checkbox,
} from "@mui/material";
import { MoreVert, Close as CloseIcon } from "@mui/icons-material/";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../helpers/utils";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import NotificationSnackbar from "../../helpers/notification-snackbar";

dayjs.extend(dayjsPluginUTC);

function MembersToInviteList({ tierId }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { data: members } = useGetAllTierMembersQuery(
    { tierId },
    { skip: !tierId }
  );
  const [selectedMembers, setSelectedMembers] = useState([]);

  const [
    resendInvitationToMultipleBrokerageMembers,
    { isLoading: isResendInvitationToMultipleBrokerageMembersLoading },
  ] = useResendInvitationToMultipleBrokerageMembersMutation();

  const [currentMemberClicked, setCurrentMemberClicked] = useState(null);
  const [sortField, setSortField] = useState("email");
  const [sortDirection, setSortDirection] = useState("asc");
  const [filterText, setFilterText] = useState("");
  const [timeFilter, setTimeFilter] = useState("ever");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleRowClick = (event, memberUser) => {
    if (
      event.target.closest(".menu-button") ||
      event.target.closest(".view-details-button") ||
      event.target.closest(".select-checkbox")
    ) {
      setCurrentMemberClicked(memberUser);
      return;
    }
    navigate(`/member-profile/${memberUser?._id}`);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    if (event) event.stopPropagation();
    setAnchorEl(null);
    setCurrentMemberClicked(null);
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  const handleFilterChange = (event) => {
    setFilterText(event.target.value.toLowerCase());
  };

  const filteredMembers = useMemo(() => {
    if (!members || members?.length === 0) return [];

    const sortedMembers = [...members]?.sort((a, b) => {
      const fields = sortField.split(".");
      const aValue = fields.reduce((acc, curr) => {
        return acc[curr];
      }, a.user);
      const bValue = fields.reduce((acc, curr) => {
        return acc[curr];
      }, b.user);

      if (sortField === "lastOnlineTime") {
        const defaultDate = new Date(1970, 0, 1);
        const aDate = aValue ? new Date(aValue) : defaultDate;
        const bDate = bValue ? new Date(bValue) : defaultDate;
        return sortDirection === "asc" ? aDate - bDate : bDate - aDate;
      }

      return aValue > bValue
        ? sortDirection === "asc"
          ? 1
          : -1
        : aValue < bValue
        ? sortDirection === "asc"
          ? -1
          : 1
        : 0;
    });

    return sortedMembers?.filter((member) => {
      const filterTextLower = filterText?.toLowerCase();
      const fullName =
        `${member?.user?.firstName} ${member?.user?.lastName}`?.toLowerCase();

      const emailMatch = member.user.email.toLowerCase().includes(filterText);
      const firstNameMatch = member.user.firstName
        .toLowerCase()
        .includes(filterText);
      const lastNameMatch = member.user.lastName
        .toLowerCase()
        .includes(filterText);
      const fullNameMatch = fullName.includes(filterTextLower);
      const nameMatch =
        emailMatch || firstNameMatch || lastNameMatch || fullNameMatch;

      if (!nameMatch) return false;
      const lastOnlineTime = member.user.lastOnlineTime
        ? new Date(member.user.lastOnlineTime)
        : null;

      if (timeFilter === "ever") {
        return true;
      }

      switch (timeFilter) {
        case "now":
          return (
            lastOnlineTime &&
            dayjs(lastOnlineTime).isAfter(dayjs().subtract(5, "minutes"))
          );
        case "15m":
          return (
            lastOnlineTime &&
            dayjs(lastOnlineTime).isAfter(dayjs().subtract(15, "minutes"))
          );
        case "30m":
          return (
            lastOnlineTime &&
            dayjs(lastOnlineTime).isAfter(dayjs().subtract(30, "minutes"))
          );
        case "1h":
          return (
            lastOnlineTime &&
            dayjs(lastOnlineTime).isAfter(dayjs().subtract(1, "hour"))
          );
        case "4h":
          return (
            lastOnlineTime &&
            dayjs(lastOnlineTime).isAfter(dayjs().subtract(4, "hours"))
          );
        case "12h":
          return (
            lastOnlineTime &&
            dayjs(lastOnlineTime).isAfter(dayjs().subtract(12, "hours"))
          );
        case "today":
          return lastOnlineTime && dayjs(lastOnlineTime).isSame(dayjs(), "day");
        case "week":
          return (
            lastOnlineTime && dayjs(lastOnlineTime).isSame(dayjs(), "week")
          );
        case "month":
          return (
            lastOnlineTime && dayjs(lastOnlineTime).isSame(dayjs(), "month")
          );
        case "never":
          return !lastOnlineTime;
        case "ever":
        default:
          return true;
      }
    });
  }, [members, sortField, sortDirection, filterText, timeFilter]);

  const clearFilters = () => {
    setFilterText("");
    // Reset other related state if necessary, like sortDirection or timeFilter
  };

  const isAllSelected = useMemo(
    () =>
      filteredMembers?.length > 0 &&
      filteredMembers?.every((member) =>
        selectedMembers?.includes(member?.user?._id)
      ),
    [filteredMembers, selectedMembers]
  );

  const toggleSelectAll = () => {
    if (isAllSelected) {
      const filteredIds = filteredMembers?.map((member) => member?.user?._id);
      setSelectedMembers(
        selectedMembers?.filter((id) => !filteredIds?.includes(id))
      );
    } else {
      const newSelections = filteredMembers?.map((member) => member?.user?._id);
      setSelectedMembers([...new Set([...selectedMembers, ...newSelections])]);
    }
  };

  const handleCheckboxChange = (event, id) => {
    event.stopPropagation();
    setSelectedMembers((current) =>
      current.includes(id)
        ? current.filter((memberId) => memberId !== id)
        : [...current, id]
    );
  };

  const handleSendInvitations = async (event) => {
    console.log("selectedMembers", selectedMembers);
    const selectedEmails = members
      .filter((member) => selectedMembers.includes(member.user._id))
      .map((member) => member.user.email);
    console.log("selectedEmails", selectedEmails);

    if (!selectedEmails || !tierId) {
      setNotification({
        open: true,
        message: "There are no users emails or tier id.",
      });
      return;
    }
    const result = await resendInvitationToMultipleBrokerageMembers({
      tierId,
      members: selectedEmails,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        handleMenuClose(event);
        setAnchorEl(null);
      }, 2500);
    } else {
      setNotification({
        open: true,
        message: `Failed: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (!members || members?.length === 0) {
    return (
      <Typography variant="h6" m="2rem 0.1rem 0.5rem 5rem">
        No members available
      </Typography>
    );
  }

  return (
    <>
      <Box>
        <Tooltip
          title={
            selectedMembers.length === 0
              ? "Select members first"
              : "(Re) Send Invitations"
          }
        >
          <span>
            <Button
              onClick={handleSendInvitations}
              disabled={selectedMembers.length === 0}
            >
              {isResendInvitationToMultipleBrokerageMembersLoading ? (
                <CircularProgress size={24} />
              ) : (
                "(Re) Send Invitations"
              )}
            </Button>
          </span>
        </Tooltip>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
        <TextField
          label="Filter Users"
          variant="outlined"
          value={filterText}
          onChange={handleFilterChange}
          sx={{ flex: 1, mt: 2.8 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {filterText && (
                  <IconButton onClick={clearFilters} edge="end">
                    <CloseIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <InputLabel sx={{ mt: "0px" }} id="time-filter-label">
            Online Since
          </InputLabel>

          <Select
            labelId="time-filter-label"
            value={timeFilter}
            onChange={(e) => setTimeFilter(e.target.value)}
            label="Online Since"
            sx={{ mt: "0px" }}
          >
            <MenuItem value="now">Online Now</MenuItem>
            <MenuItem value="15m">Last 15 Minutes</MenuItem>
            <MenuItem value="30m">Last 30 Minutes</MenuItem>
            <MenuItem value="1h">Last Hour</MenuItem>
            <MenuItem value="4h">Last 4 Hours</MenuItem>
            <MenuItem value="12h">Last 12 Hours</MenuItem>
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="week">This Week</MenuItem>
            <MenuItem value="month">This Month</MenuItem>
            <MenuItem value="ever">Show All</MenuItem>
            <MenuItem value="never">Never Logged In</MenuItem>
          </Select>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table className="user-table">
          <TableHead
            sx={{
              color: "var(--text-color)",
              backgroundColor: "var(--bg-color)",
            }}
          >
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedMembers?.length > 0 &&
                    selectedMembers?.length < members?.length
                  }
                  checked={isAllSelected}
                  onChange={toggleSelectAll}
                />
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "user.firstName"}
                  direction={sortDirection}
                  onClick={() => handleSort("user.firstName")}
                  title="Sort by First Name"
                >
                  <sup>First</sup>
                </TableSortLabel>
                <TableSortLabel
                  onClick={() => handleSort("user.lastName")}
                  active={sortField === "user.lastName"}
                  direction={sortDirection}
                  title="Sort by Last Name"
                >
                  <sup>Last</sup>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ fontWeight: "bolder" }}>
                <TableSortLabel
                  active={sortField === "user.email"}
                  direction={sortDirection}
                  onClick={() => handleSort("user.email")}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "user.accountType"}
                  direction={sortDirection}
                  onClick={() => handleSort("user.accountType")}
                >
                  Account Type
                </TableSortLabel>
              </TableCell>
              {/* <TableCell>
                <TableSortLabel
                  active={sortField === "user.status"}
                  direction={sortDirection}
                  onClick={() => handleSort("user.status")}
                >
                  Status
                </TableSortLabel>
              </TableCell> */}

              <TableCell>
                <TableSortLabel
                  active={sortField === "user.lastOnlineTime"}
                  direction={sortDirection}
                  onClick={() => handleSort("user.lastOnlineTime")}
                >
                  Last Online
                </TableSortLabel>
              </TableCell>

              <TableCell>Actions </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMembers?.map((member) => (
              <TableRow
                key={member?._id}
                onClick={(event) =>
                  handleRowClick(event, member?.user?._id, member?.user)
                }
                style={{ cursor: "pointer" }}
                className="table-row"
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedMembers.includes(member?.user?._id)}
                    className="select-checkbox"
                    onChange={(event) => {
                      event.stopPropagation();
                      handleCheckboxChange(event, member?.user?._id);
                    }}
                  />
                </TableCell>
                <TableCell>
                  {capitalizeFirstLetter(member?.user?.firstName)}{" "}
                  {capitalizeFirstLetter(member?.user?.lastName)}
                </TableCell>
                <TableCell>{member?.user?.email}</TableCell>
                <TableCell>{member?.user?.accountType}</TableCell>
                <TableCell>
                  {member?.user?.lastOnlineTime ? (
                    dayjs(member?.user?.lastOnlineTime).fromNow()
                  ) : (
                    <span style={{ color: "red" }}>Never</span>
                  )}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      className="menu-button"
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(event) =>
                        handleMenuOpen(event, member?.user?._id)
                      }
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleMenuClose}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();

                          navigate(`/member-profile/${currentMemberClicked}`);
                          handleMenuClose(e);
                        }}
                      >
                        <Typography
                          variant="body1"
                          className="view-details-button"
                        >
                          Open Profile
                        </Typography>
                      </MenuItem>
                      {/* <MenuItem
                        onClick={(event) => {
                          handleSendInviteEmail(event);
                        }}
                      >
                        <Typography variant="body1">
                          {isResendInvitationToMultipleBrokerageMembersLoading ? (
                            <CircularProgress size={24} />
                          ) : (
                            "(Re) Send Invitations"
                          )}
                        </Typography>
                      </MenuItem> */}
                    </Menu>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
}

export default memo(MembersToInviteList);

import React, { useEffect, useContext, memo } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { TierContext } from "../../context/TierContext";
import { useGetCategoriesByUserRoleQuery } from "../../services/category";
import { useGetNewPostsAmountByTierQuery } from "../../services/post";
import { AppBar, Tabs, Tab } from "@mui/material";
import { TierTabItemStyledBadge } from "../helpers/utils";

function SidebarTiersItemAppBar() {
  const navigate = useNavigate();
  const { selectTierId, currentTierId } = useContext(TierContext);
  const { isTokenReady, userId } = useContext(AuthContext);
  const { data: categoriesByRole, refetch: refetchCategoriesByRole } =
    useGetCategoriesByUserRoleQuery(userId, {
      skip: !isTokenReady,
    });
  const { data: tierPostCounts } = useGetNewPostsAmountByTierQuery(userId);

  useEffect(() => {
    if (isTokenReady) {
      refetchCategoriesByRole();
    }
  }, [isTokenReady, refetchCategoriesByRole]);

  const navigateToCategory = (tierId) => {
    const tier = categoriesByRole?.find((t) => t?.tierId === tierId);
    if (tier) {
      let defaultCategory;

      defaultCategory = Object.values(tier.groups || {})
        .flatMap((group) => group)
        .find((category) => category.path === "home");
      if (defaultCategory && defaultCategory.path === "home") {
        navigate("/home");
        return;
      }

      if (!defaultCategory) {
        defaultCategory = Object.values(tier.groups || {})
          .flatMap((group) => group)
          .find((category) => category.type === "announcements");
      }

      if (!defaultCategory) {
        for (const group in tier.groups) {
          if (tier?.groups[group]?.length > 0) {
            defaultCategory = tier?.groups[group][0];
            break;
          }
        }
      }

      if (defaultCategory) {
        navigate(`/category/${defaultCategory?.path}/${defaultCategory?._id}`);
      }
    }
  };

  const handleChangeTier = (event, newTierId) => {
    selectTierId(newTierId);
    navigateToCategory(newTierId);
  };

  return (
    <AppBar className="tiers-app-bar">
      <Tabs
        value={currentTierId || false}
        onChange={handleChangeTier}
        indicatorColor="secondary"
        textColor="secondary"
        variant="scrollable"
        scrollButtons
        selectionFollowsFocus
        aria-label="scrollable auto tabs example"
        className="tier-tabs-paper"
        component={"div"}
      >
        {categoriesByRole?.map((tier) => {
          const tierLabel =
            (tier?.tierBrokerageShortName && tier?.tierBrokerageShortName) ||
            (tier?.tierBrokerageName && tier?.tierBrokerageName) ||
            (!tier?.tierBrokerageName &&
              !tier?.tierBrokerageName &&
              tier?.tierName &&
              tier?.tierName);

          return (
            <Tab
              key={tier?.tierId}
              label={
                <TierTabItemStyledBadge
                  badgeContent={
                    tierPostCounts ? `+${tierPostCounts[tier?.tierId]}` || 0 : 0
                  }
                  color="error"
                  invisible={
                    tierPostCounts && tierPostCounts[tier?.tierId] === 0
                  }
                  max={6}
                >
                  {tierLabel}
                </TierTabItemStyledBadge>
              }
              value={tier?.tierId}
            />
          );
        })}
      </Tabs>
    </AppBar>
  );
}

export default memo(SidebarTiersItemAppBar);

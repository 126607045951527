import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Tabs,
  Tab,
} from "@mui/material";
import Products from "./product/Products";
import Transactions from "./transaction/Transactions";
import Invoices from "./invoice/Invoices";
import Subscriptions from "./subscription/Subscriptions";

const BillingPage = () => {
  const [currentTab, setCurrentTab] = useState(
    parseInt(localStorage.getItem("abt"), 10) || 0
  );

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
    localStorage.setItem("abt", newValue); // Save the active tab index to localStorage
  };

  return (
    <Box
      className="billing-page"
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: "2.5rem",
          fontWeight: "bolder",
          mb: 3,
          textAlign: "center",
        }}
        gutterBottom
      >
        Billing Dashboard
      </Typography>

      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        aria-label="billing tabs"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        <Tab label="Products" />
        <Tab label="Subscriptions" />
        <Tab label="Invoices" />
        <Tab label="Transactions" />
      </Tabs>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          p: 2,
        }}
      >
        {currentTab === 0 && ( // Products
          <Paper elevation={3} sx={{ flex: 1, p: 2 }}>
            <Typography
              variant="h2"
              sx={{ fontSize: "1.5rem", fontWeight: "bolder" }}
              gutterBottom
            >
              Products
            </Typography>
            <Products />
          </Paper>
        )}

        {currentTab === 1 && ( // Subsciption
          <Paper elevation={3} sx={{ flex: 1, p: 2 }}>
            <Typography
              variant="h2"
              sx={{ fontSize: "1.5rem", fontWeight: "bolder" }}
              gutterBottom
            >
              Subscriptions
            </Typography>
            <Subscriptions />
          </Paper>
        )}

        {currentTab === 2 && ( // Invoices
          <Paper elevation={3} sx={{ flex: 1, p: 2 }}>
            <Typography
              variant="h2"
              sx={{ fontSize: "1.5rem", fontWeight: "bolder" }}
              gutterBottom
            >
              Invoices
            </Typography>
            <Invoices />
          </Paper>
        )}

        {currentTab === 3 && ( // Transactions
          <Paper elevation={3} sx={{ flex: 1, p: 2 }}>
            <Typography
              variant="h2"
              sx={{ fontSize: "1.5rem", fontWeight: "bolder" }}
              gutterBottom
            >
              Transactions
            </Typography>
            <Transactions />
          </Paper>
        )}

        
      </Box>
    </Box>
  );
};

export default BillingPage;

import React from "react";
import { Drawer, IconButton, Typography } from "@mui/material";
import EditMemberProfile from "../account-profiles/EditMemberProfile";
import { Close } from "@mui/icons-material/";

const MemberProfileEditForm = ({
  userMemberId,
  open,
  onClose,
  memberProfileEditFrom,
}) => {
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      className="member-profile-edit-drawer"
      PaperProps={{
        style: {
          height: `calc(100% - 5rem)`,
          top: "5rem",
        },
      }}
    >
      <div className="member-profile-edit-drawer-header">
        <Typography
          gutterBottom
          component="div"
          className="header-profile-title-name"
        >
          Member Profile Edit Form
        </Typography>
        <IconButton onClick={onClose} className="close-button">
          <Close />
        </IconButton>
      </div>
      <EditMemberProfile
        userMemberId={userMemberId}
        onClose={onClose}
        memberProfileEditFrom={memberProfileEditFrom}
        className="member-profile-edit-drawer-body"
      />
    </Drawer>
  );
};

export default MemberProfileEditForm;

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { useAddPermissionToRoleMutation } from "../../../../services/team-role";
import NotificationSnackbar from "../../../helpers/notification-snackbar";

const AddTeamRolePermissionDialog = ({
  open,
  onClose,
  teamRoleId,
  refetchRoles,
}) => {
  const [permission, setPermission] = useState("");
  const [addPermissionToRole, { isLoading }] = useAddPermissionToRoleMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleAdd = async () => {
    if (!teamRoleId || !permission.trim()) {
      setNotification({
        open: true,
        message: "The team Id and permission are required.",
      });
      return;
    }
    const result = await addPermissionToRole({ teamRoleId, permission });
    if (result?.data) {
      setNotification({
        openNotification: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        setPermission("");
        refetchRoles?.();
        onClose();
      }, 1500);
    } else {
      setNotification({
        openNotification: true,
        message: `Error adding the team role: ${result?.error?.data?.msg}`,
      });
      setPermission("");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, openNotification: false });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Permission</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Permission"
          type="text"
          fullWidth
          variant="outlined"
          value={permission}
          onChange={(e) => setPermission(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAdd} disabled={isLoading}>
          {isLoading ? "Adding..." : "Add"}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default AddTeamRolePermissionDialog;

import React, { useState, useRef } from "react";
import {
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Tabs,
  Tab,
  TextField,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import {
  useFetchProductsQuery,
  useDeleteProductMutation,
} from "../../../../services/billing/products";
import {
  useFetchProductCollectionsQuery,
  useDeleteProductCollectionMutation,
} from "../../../../services/billing/productCollections";
import ProductForm from "./ProductForm";
import ProductCollectionForm from "./ProductCollectionForm";
import CollectionTable from "./ProductCollectionTable";
import ProductTable from "./ProductTable";
import ConfirmationDialog from "../ConfirmationDialog";

// Utility function for sorting
const getComparator = (order, orderBy) => {
  return (a, b) => {
    if (orderBy === "code") {
      if (a.code < b.code) return order === "asc" ? -1 : 1;
      if (a.code > b.code) return order === "asc" ? 1 : -1;
    }
    if (orderBy === "name") {
      if (a.name < b.name) return order === "asc" ? -1 : 1;
      if (a.name > b.name) return order === "asc" ? 1 : -1;
    }
    return 0;
  };
};

const ProductList = () => {
  const [activeProdTab, setActiveProdTab] = useState(
    parseInt(localStorage.getItem("apt"), 10) || 0
  );
  const {
    data: products,
    error: productError,
    isLoading: productLoading,
    refetch: refetchProducts,
  } = useFetchProductsQuery();
  const {
    data: collections,
    error: collectionError,
    isLoading: collectionLoading,
    refetch: refetchCollections,
  } = useFetchProductCollectionsQuery();
  const [deleteProduct] = useDeleteProductMutation();
  const [deleteProductCollection] = useDeleteProductCollectionMutation();
  const [order, setOrder] = useState("asc");

  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [confirmationTask, setConfirmationTask] = useState("");
  const [confirmationTitle, setConfirmationTitle] = useState("");

  const [orderBy, setOrderBy] = useState("code");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filter, setFilter] = useState("");
  const [isProductFormOpen, setIsProductFormOpen] = useState(false);
  const [isCollectionFormOpen, setIsCollectionFormOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentCollection, setCurrentCollection] = useState(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const collectionFormRef = useRef();
  const productFormRef = useRef();

  if (productLoading || collectionLoading) return <p>Loading...</p>;
  if (productError) return <p>Error fetching products.</p>;
  if (collectionError) return <p>Error fetching collections.</p>;

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredProducts = products.filter(
    (product) =>
      product.name.toLowerCase().includes(filter.toLowerCase()) ||
      product.description.toLowerCase().includes(filter.toLowerCase())
  );

  const filteredCollections = collections.filter(
    (collection) =>
      collection.name.toLowerCase().includes(filter.toLowerCase()) ||
      collection.description.toLowerCase().includes(filter.toLowerCase())
  );

  const sortedProducts = filteredProducts
    .sort(getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const sortedCollections = filteredCollections
    .sort(getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleEditClick = (item, type) => {
    if (type === "product") {
      setCurrentProduct(item);
      setIsProductFormOpen(true);
    } else {
      setCurrentCollection(item);
      setIsCollectionFormOpen(true);
    }
  };

  const handleDeleteClick = (item, type) => {
    setItemToDelete({ ...item, type });
    setConfirmationMessage("Are you sure you want to delete this item?");
    setConfirmationTitle("Delete Item");
    setConfirmationTask("Delete");
    setIsConfirmOpen(true);
  };

  const handleConfirm = async () => {
    try {
      if (itemToDelete.type === "product") {
        await deleteProduct(itemToDelete._id).unwrap();
        refetchProducts();
      } else {
        await deleteProductCollection(itemToDelete._id).unwrap();
        refetchCollections();
      }
      setIsConfirmOpen(false);
      setItemToDelete(null);
    } catch (error) {
      // Handle error, show an error message
    }
  };

  const handleConfirmationCancel = () => {
    setIsConfirmOpen(false);
    setItemToDelete(null);
  };

  const handleCloseProductForm = () => {
    setIsProductFormOpen(false);
    setCurrentProduct(null);
  };

  const handleCloseCollectionForm = () => {
    setIsCollectionFormOpen(false);
    setCurrentCollection(null);
  };

  const handleTabChange = (event, newValue) => {
    setActiveProdTab(newValue);
    localStorage.setItem("apt", newValue); // Save the active tab index to localStorage
  };

  const handleCreateCollection = async () => {
    if (collectionFormRef.current) {
      await collectionFormRef.current.submit();
      refetchCollections();
    }
  };

  const handleCreateProduct = async () => {
    if (productFormRef.current) {
      await productFormRef.current.submit();
      refetchProducts();
    }
  };

  return (
    <Paper className="product-list">
      <Tabs value={activeProdTab} onChange={handleTabChange}>
        <Tab label="Product Collections" />
        <Tab label="Product Catelog" />
      </Tabs>
      <TextField
        label="Search"
        variant="outlined"
        margin="normal"
        fullWidth
        onChange={(e) => setFilter(e.target.value)}
      />
      {activeProdTab === 0 && (
        <>
          <Button
            sx={{ mb: 2 }}
            variant="contained"
            color="primary"
            onClick={() => {
              setCurrentCollection(null); // Set collection to null for creating new
              setIsCollectionFormOpen(true);
            }}
            startIcon={<Add />}
          >
            Create Collection
          </Button>
          <CollectionTable
            collections={sortedCollections}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            handleRequestSort={handleRequestSort}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleEditClick={(item) => handleEditClick(item, "collection")}
            handleDeleteClick={(item) => handleDeleteClick(item, "collection")}
          />
        </>
      )}
      {activeProdTab === 1 && (
        <>
          <Button
            sx={{ mb: 2 }}
            variant="contained"
            color="primary"
            onClick={() => {
              setCurrentProduct(null); // Set collection to null for creating new
              setIsProductFormOpen(true);
            }}
            startIcon={<Add />}
          >
            Create Product
          </Button>
          <ProductTable
            products={sortedProducts}
            order={order}
            orderBy={orderBy}
            page={page}
            rowsPerPage={rowsPerPage}
            handleRequestSort={handleRequestSort}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleEditClick={(item) => handleEditClick(item, "product")}
            handleDeleteClick={(item) => handleDeleteClick(item, "product")}
          />
        </>
      )}

      <Dialog open={isProductFormOpen} onClose={handleCloseProductForm}>
        <DialogTitle>
          {currentProduct ? "Edit Product" : "Add Product"}
        </DialogTitle>
        <DialogContent>
          <ProductForm
            ref={productFormRef}
            product={currentProduct}
            onClose={handleCloseProductForm}
            onSubmit={handleCreateProduct}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseProductForm} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreateProduct} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isCollectionFormOpen} onClose={handleCloseCollectionForm}>
        <DialogTitle>
          {currentCollection
            ? "Edit Product Collection"
            : "Add Product Collection"}
        </DialogTitle>
        <DialogContent>
          <ProductCollectionForm
            ref={collectionFormRef}
            collection={currentCollection}
            onClose={handleCloseCollectionForm}
            onSubmit={handleCreateCollection}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCollectionForm} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreateCollection} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        open={isConfirmOpen}
        onConfirm={handleConfirm}
        onClose={handleConfirmationCancel}
        title={confirmationTitle}
        message={confirmationMessage}
        task={confirmationTask}
      />
    </Paper>
  );
};

export default ProductList;

import React, { useEffect, useContext, useState, memo } from "react";
import {
  Typography,
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  Badge,
  Popover,
} from "@mui/material";
import {
  Notifications,
  CheckCircle,
  CheckCircleOutline,
  Delete,
  DeleteSweep,
  Close,
  DoneAll,
} from "@mui/icons-material";
import {
  useGetAllNotificationsQuery,
  useMarkAsReadNotificationMutation,
  useMarkAsUnreadNotificationMutation,
  useMarkAllAsReadMutation,
  useDeleteAllNotificationsMutation,
  useDeleteNotificationMutation,
  notificationsApi,
} from "../../services/notifications";
import { AuthContext } from "../../context/AuthContext";
import { useDispatch } from "react-redux";
import NotificationMessage from "../helpers/NotificationMessage";
import { formatDate } from "../helpers/utils";
import { ConfirmationDialog } from "../";

function NotificationMenu() {
  const { userId, isTokenReady } = useContext(AuthContext);
  const {
    data: notifications,
    refetch,
    isSuccess,
  } = useGetAllNotificationsQuery(userId, {
    skip: !isTokenReady,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const [markAsRead] = useMarkAsReadNotificationMutation();
  const [markAsUnread] = useMarkAsUnreadNotificationMutation();
  const [deleteNotification] = useDeleteNotificationMutation();
  const [markAllAsRead] = useMarkAllAsReadMutation(userId);
  const [deleteAllNotifications] = useDeleteAllNotificationsMutation(userId);
  const [deleteAllDialogOpen, setDeleteAllDialogOpen] = useState(false);

  const handleMarkAsRead = async (event, notificationId) => {
    event.stopPropagation();
    await markAsRead(notificationId);
    dispatch(notificationsApi.util.invalidateTags(["Notifications"]));
  };

  const handleMarkAsUnread = async (event, notificationId) => {
    event.stopPropagation();
    await markAsUnread(notificationId);
    dispatch(notificationsApi.util.invalidateTags(["Notifications"]));
  };

  const handleDeleteNotification = async (event, notificationId) => {
    if (event) event.stopPropagation();
    await deleteNotification(notificationId);
    dispatch(notificationsApi.util.invalidateTags(["Notifications"]));
  };

  const handleNotificationClick = async (notificationId) => {
    await markAsRead(notificationId);
    dispatch(notificationsApi.util.invalidateTags(["Notifications"]));
  };

  useEffect(() => {
    if (userId && isSuccess && isTokenReady) {
      refetch();
    }
  }, [userId, refetch, isSuccess, isTokenReady]);

  const sortedNotifications = notifications
    ? [...notifications].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
    : [];

  const handleNotificationIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAllAsRead = async () => {
    if (notifications && notifications?.length > 0) {
      await markAllAsRead(userId);
    }
  };

  const handleDeleteAllNotifications = async () => {
    await deleteAllNotifications(userId);
    handleDeleteAllDialogClose();
  };

  const handleDeleteAllDialogOpen = () => {
    if (notifications && notifications?.length > 0) {
      setDeleteAllDialogOpen(true);
    }
  };

  const handleDeleteAllDialogClose = () => {
    setDeleteAllDialogOpen(false);
  };

  const unreadCount = notifications
    ? notifications.filter((notif) => !notif.read).length
    : 0;

  return (
    <div className="">
      <Tooltip title="Notifications">
        <IconButton onClick={handleNotificationIconClick}>
          <Badge
            badgeContent={unreadCount}
            color="error"
            sx={{
              "& .MuiBadge-badge": {
                fontSize: {
                  xs: "0.5rem", // font size for extra small devices
                  sm: "0.75rem", // font size for small devices and up
                  md: "1rem", // font size for medium devices and up
                },
                height: {
                  xs: "16px", // height for extra small devices
                  sm: "20px", // height for small devices and up
                  md: "24px", // height for medium devices and up
                },
                minWidth: {
                  xs: "16px", // min width for extra small devices
                  sm: "20px", // min width for small devices and up
                  md: "24px", // min width for medium devices and up
                },
              },
            }}
          >
            <Notifications />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleNotificationClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className="notifications-body"
      >
        <div className="notifications-header">
          <IconButton onClick={handleNotificationClose}>
            <Close />
          </IconButton>
          <span className="notifications-header-text">Notifications</span>
          <div className="notifications-header-actions">
            <Tooltip
              title={`${
                notifications && notifications?.length > 0
                  ? "Mark All as Read"
                  : "There are no Notifications to mark"
              }`}
            >
              <IconButton
                onClick={handleMarkAllAsRead}
                disabled={!!!notifications && notifications?.length === 0}
              >
                <DoneAll />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={`${
                notifications && notifications?.length > 0
                  ? "Delete All Notifications"
                  : "There are no Notifications to delete"
              }`}
              className="notifications-header-actions-item"
            >
              <IconButton
                onClick={handleDeleteAllDialogOpen}
                disabled={!!!notifications && notifications?.length === 0}
              >
                <DeleteSweep />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <List className="notifications-list">
          {sortedNotifications && sortedNotifications?.length > 0 ? (
            sortedNotifications?.map((notification, index) => (
              <ListItem
                onClick={() => handleNotificationClick(notification?._id)}
                key={index}
                className={
                  notification?.read
                    ? "notifications-item-read"
                    : "notifications-item-unread"
                }
              >
                <NotificationMessage
                  message={notification?.message}
                  onLinkClick={handleNotificationClose}
                />
                <ListItemText
                  secondary={formatDate(notification?.createdAt)}
                  className={
                    notification?.read
                      ? "notifications-text-read"
                      : "notifications-text-unread"
                  }
                />
                {!notification?.read && (
                  <Badge
                    badgeContent="new"
                    className="notification-unread-badge"
                  />
                )}

                <Tooltip
                  title={notification.read ? "Mark as Unread" : "Mark as Read"}
                >
                  <IconButton
                    onClick={(event) =>
                      notification?.read
                        ? handleMarkAsUnread(event, notification?._id)
                        : handleMarkAsRead(event, notification?._id)
                    }
                  >
                    {notification?.read ? (
                      <CheckCircleOutline />
                    ) : (
                      <CheckCircle />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Notification">
                  <IconButton
                    onClick={(event) =>
                      handleDeleteNotification(event, notification?._id)
                    }
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))
          ) : (
            <Typography variant="body1" sx={{ p: ".5rem 2rem" }}>
              You don't have notifications
            </Typography>
          )}
        </List>
      </Popover>
      <ConfirmationDialog
        open={deleteAllDialogOpen}
        onClose={handleDeleteAllDialogClose}
        onConfirm={handleDeleteAllNotifications}
        title="Confirm Deleting All Notifications"
        description="Are you sure you want to delete all notifications?"
      />
    </div>
  );
}

export default memo(NotificationMenu);

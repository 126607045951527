import React, {
  useState,
  useEffect,
  useContext,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  DialogContent,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  useCreateProductMutation,
  useUpdateProductMutation,
} from "../../../../services/billing/products";
import NotificationSnackbar from "../../../helpers/notification-snackbar";
import { AuthContext } from "../../../../context/AuthContext";

const ProductForm = forwardRef(({ product = null, onClose, onSubmit }, ref) => {
  const { userId } = useContext(AuthContext);

  // eslint-disable-next-line
  const clientTypes = ["individual", "brokerage", "conference"];
  // eslint-disable-next-line
  const subscriptionTypes = ["one-time", "recurring"];
  const recurringFrequencyOptions = ["Weekly", "Monthly", "Yearly"];
  const [addProduct, { isLoading: isAddingProduct }] =
    useCreateProductMutation();
  const [updateProduct, { isLoading: isUpdatingProduct }] =
    useUpdateProductMutation();

  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [perUser, setPerUser] = useState(false);
  const [clientType, setClientType] = useState(clientTypes[0]);
  const [subscriptionType, setSubscriptionType] = useState(
    subscriptionTypes[0]
  );
  const [recurringFrequency, setRecurringFrequency] = useState("");
  const [description, setDescription] = useState("");

  const [notification, setNotification] = useState({
    open: false,
    message: "",
    type: "",
  });
  const [, setConfirmationOpen] = useState(false);

  useEffect(() => {
    if (product) {
      setProductName(product.name || "");
      setPrice(product.price || "");
      setPerUser(product.perUser || false);
      setClientType(product.clientType || clientTypes[0]);
      setSubscriptionType(product.subscriptionType || subscriptionTypes[0]);
      setRecurringFrequency(product.recurringFrequency || "");
      setDescription(product.description || "");
    } // eslint-disable-next-line
  }, [clientTypes, product, subscriptionTypes]);

  const handleProductSubmit = () => {
    if (productName && price) {
      const productData = {
        name: productName,
        price: parseFloat(price),
        perUser,
        clientType,
        subscriptionType,
        description,
        createdBy: userId,
        recurringFrequency:
          subscriptionType === "recurring" ? recurringFrequency : undefined,
      };

      const action = product ? updateProduct : addProduct;
      const actionData = product
        ? { _id: product._id, ...productData }
        : productData;

      action(actionData)
        .then(() => {
          setNotification({
            open: true,
            message: `Product ${product ? "updated" : "added"} successfully!`,
            type: "success",
          });
          onClose();
        })
        .catch((error) => {
          setNotification({
            open: true,
            message: `Failed to ${product ? "update" : "add"} product: ${
              error.message
            }`,
            type: "error",
          });
        });
    } else {
      setNotification({
        open: true,
        message: "Please provide valid product details.",
        type: "warning",
      });
    }
  };

  const handleOpenConfirmation = () => {
    setConfirmationOpen(true);
  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  useImperativeHandle(ref, () => ({
    submit: handleProductSubmit,
    openConfirmation: handleOpenConfirmation,
    closeConfirmation: handleCloseConfirmation,
  }));

  return (
    <>
      <DialogContent>
        <TextField
          label="Product Name"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          fullWidth
          margin="normal"
          disabled={isAddingProduct || isUpdatingProduct}
        />
        <TextField
          label="Price"
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          fullWidth
          margin="normal"
          disabled={isAddingProduct || isUpdatingProduct}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={perUser}
                onChange={(e) => setPerUser(e.target.checked)}
                color="primary"
                disabled={isAddingProduct || isUpdatingProduct}
              />
            }
            label="PerUser"
          />
        </FormGroup>
        <TextField
          select
          label="Client Type"
          value={clientType}
          onChange={(e) => setClientType(e.target.value)}
          fullWidth
          margin="normal"
          disabled={isAddingProduct || isUpdatingProduct}
        >
          {clientTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Subscription Type"
          value={subscriptionType}
          onChange={(e) => setSubscriptionType(e.target.value)}
          fullWidth
          margin="normal"
          disabled={isAddingProduct || isUpdatingProduct}
        >
          {subscriptionTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>
        {subscriptionType === "recurring" && (
          <TextField
            select
            label="Recurring Option"
            value={recurringFrequency}
            onChange={(e) => setRecurringFrequency(e.target.value)}
            fullWidth
            margin="normal"
            disabled={isAddingProduct || isUpdatingProduct}
          >
            {recurringFrequencyOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        )}
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={4}
          disabled={isAddingProduct || isUpdatingProduct}
        />
      </DialogContent>

      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        type={notification.type}
        onClose={() => setNotification({ ...notification, open: false })}
      />
    </>
  );
});

export default ProductForm;

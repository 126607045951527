import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetGroupByIdQuery,
  useDeleteMemberMutation,
} from "../../../../services/group";
import { AuthContext } from "../../../../context/AuthContext";
import {
  Container,
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";

import { DeleteForever, AssignmentTurnedIn } from "@mui/icons-material/";
import NotificationSnackbar from "../../../helpers/notification-snackbar";
import { BackButton } from "../../..";
import AddExistingMember from "./AddExistingMember";
import RemoveMemberDialog from "./RemoveMemberDialog";
import GroupRoles from "./group-roles/GroupRoles";
import AssignTierToGroupDialog from "./AssignTierToGroupDialog";
import InviteNewMemberToGroupDialog from "./InviteNewMemberToGroupDialog";

const GroupCard = () => {
  const { userId, userRole } = useContext(AuthContext);
  const { groupId } = useParams();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const {
    data: group,
    error,
    isLoading,
    refetch,
  } = useGetGroupByIdQuery(groupId, {
    skip: !groupId,
  });

  const [deleteMember] = useDeleteMemberMutation();
  const [isAddMemberOpen, setIsAddMemberOpen] = useState(false);
  const [isInviteNewMemberOpen, setIsInviteNewMemberOpen] = useState(false);
  const [isRemoveMemberOpen, setIsRemoveMemberOpen] = useState(false);
  const [memberIdToRemove, setMemberIdToRemove] = useState(null);
  const [currentGroupId, setCurrentGroupId] = useState(null);
  const [openAssignTierToGroupDialog, setOpenAssignTierToGroupDialog] =
    useState(false);

  const handleAddMemberOpen = (groupId) => {
    setCurrentGroupId(groupId);
    setIsAddMemberOpen(true);
  };

  const handleAddMemberClose = () => {
    setIsAddMemberOpen(false);
  };

  const handleInviteNewMemberOpen = (groupId) => {
    setCurrentGroupId(groupId);
    setIsInviteNewMemberOpen(true);
  };

  const handleInviteNewMemberClose = () => {
    setIsInviteNewMemberOpen(false);
  };

  const handleRemoveMemberOpen = (groupId, memberId) => {
    setCurrentGroupId(groupId);
    setMemberIdToRemove(memberId);
    setIsRemoveMemberOpen(true);
  };

  const handleRemoveMemberClose = () => {
    setIsRemoveMemberOpen(false);
    setCurrentGroupId(null);
    setMemberIdToRemove(null);
  };

  const handleRemoveMemberConfirm = async () => {
    const result = await deleteMember({
      groupId: currentGroupId,
      memberId: memberIdToRemove,
    });

    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      handleRemoveMemberClose();
      refetch();
    } else {
      setNotification({
        open: true,
        message: `Error deleting Member. ${result?.error?.data?.msg}`,
      });
      refetch();
    }
  };

  const handleOpenAssignTierToGroupDialog = (groupId) => {
    setCurrentGroupId(groupId);
    setOpenAssignTierToGroupDialog(true);
  };

  const handleCloseAssignTierToGroupDialog = () => {
    setOpenAssignTierToGroupDialog(false);
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (isLoading)
    return (
      <div className="loading-spinner">
        <CircularProgress size={24} />
      </div>
    );

  if (error) return <div>Error: {error?.data?.msg}</div>;

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <BackButton />
        <Tooltip title="Assign Tier to Group" placement="left">
          <IconButton
            edge="end"
            aria-label="assign-tier-to-group"
            onClick={() => handleOpenAssignTierToGroupDialog(group?._id)}
          >
            <AssignmentTurnedIn />
            <Typography ml={1}>Assign Tier to Group</Typography>
          </IconButton>
        </Tooltip>
      </Box>
      <Card>
        <CardContent>
          {!group && (
            <Typography variant="h4" gutterBottom>
              No Group Found
            </Typography>
          )}
          {group && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  Group Name: {group?.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  <strong>Group Lead:</strong>{" "}
                  {group?.groupLead?.member?.user?.firstName}{" "}
                  {group?.groupLead?.member?.user?.lastName} -{" "}
                  {group?.groupLead?.member?.user?.email}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Members:
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleInviteNewMemberOpen(group?._id)}
                >
                  Invite New Member
                </Button>
                {userRole === "reosadmin" ||
                  (userRole === "reossuperadmin" && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddMemberOpen(group?._id)}
                    >
                      Add Existing Member
                    </Button>
                  ))}
              </Grid>
              <TableContainer sx={{ m: 5 }} component={Paper}>
                <Table className="user-table">
                  <TableHead>
                    <TableRow
                      sx={{
                        color: "var(--text-color)",
                        backgroundColor: "var(--bg-color)",
                      }}
                    >
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {group?.members &&
                      group?.members?.length > 0 &&
                      group?.members?.map((groupMember) => (
                        <TableRow
                          style={{ cursor: "pointer" }}
                          className="table-row"
                          key={groupMember?._id}
                        >
                          <TableCell>
                            {groupMember?.member?.user?.firstName}{" "}
                            {groupMember?.member?.user?.lastName}
                          </TableCell>
                          <TableCell>
                            {groupMember?.member?.user?.email}
                          </TableCell>
                          <TableCell>{groupMember?.role?.name}</TableCell>
                          <TableCell>
                            {(group?.groupLead?.member?.user?._id === userId ||
                              userRole === "reossuperadmin" ||
                              userRole === "reosadmin") && (
                              <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  handleRemoveMemberOpen(
                                    group?._id,
                                    groupMember?.member?._id
                                  )
                                }
                              >
                                <DeleteForever />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </CardContent>
      </Card>
      <GroupRoles groupId={groupId} groupMembers={group?.members} />
      <InviteNewMemberToGroupDialog
        open={isInviteNewMemberOpen}
        groupId={groupId}
        groupName={group?.name}
        tierId={group?.tier?._id}
        onClose={handleInviteNewMemberClose}
        refetch={refetch}
      />
      <AddExistingMember
        open={isAddMemberOpen}
        groupId={currentGroupId}
        onClose={handleAddMemberClose}
        refetch={refetch}
      />
      <AssignTierToGroupDialog
        preselectedGroup={group}
        open={openAssignTierToGroupDialog}
        onClose={handleCloseAssignTierToGroupDialog}
      />
      <RemoveMemberDialog
        open={isRemoveMemberOpen}
        onClose={handleRemoveMemberClose}
        onConfirm={handleRemoveMemberConfirm}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Container>
  );
};

export default GroupCard;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const notificationsApi = createApi({
  reducerPath: "notifications",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/notifications/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Notifications"],
  endpoints: (builder) => ({
    getAllNotifications: builder.query({
      query: (userId) => ({ url: `/get-all-users/${userId}`, method: "GET" }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Notifications", id: _id })),
              "Notifications",
            ]
          : ["Notifications"],
    }),
    getOneNotification: builder.query({
      query: (notificationId) => ({
        url: `/get-one/${notificationId}`,
        method: "GET",
      }),
      providesTags: (result, error, notificationId) => [
        { type: "Notifications", id: notificationId },
      ],
    }),
    markAsReadNotification: builder.mutation({
      query: (notificationId) => ({
        url: `mark-as-read/${notificationId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Notifications"],
    }),
    markAsUnreadNotification: builder.mutation({
      query: (notificationId) => ({
        url: `mark-as-unread/${notificationId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Notifications"],
    }),
    markAllAsRead: builder.mutation({
      query: (userId) => ({
        url: `/mark-all-as-read/${userId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Notifications"],
    }),
    deleteAllNotifications: builder.mutation({
      query: (userId) => ({
        url: `/delete-all/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Notifications"],
    }),
    deleteNotification: builder.mutation({
      query: (notificationId) => ({
        url: `delete-one/${notificationId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Notifications"],
    }),
  }),
});

export const {
  useGetAllNotificationsQuery,
  useGetOneNotificationQuery,
  useMarkAsReadNotificationMutation,
  useMarkAsUnreadNotificationMutation,
  useMarkAllAsReadMutation,
  useDeleteAllNotificationsMutation,
  useDeleteNotificationMutation,
} = notificationsApi;

export default notificationsApi;
export { notificationsApi };

import React from "react";
import { Typography, List, ListItem } from "@mui/material";

function LogsManager({ logs }) {
  return (
    <div>
      <Typography variant="h6" gutterBottom sx={{ p: 2 }}>
        Login Logs:
      </Typography>
      <List sx={{ p: 2 }}>
        {logs?.map((log, index) => (
          <ListItem key={index} sx={{ mb: 2 }}>
            <div>
              <Typography variant="body2">
                Date: {new Date(log?.date).toLocaleString()}
              </Typography>
              <Typography variant="body2">Action: {log?.action}</Typography>
              <Typography variant="body2">Status: {log?.status}</Typography>
              {log?.details && (
                <Typography variant="body2">Details: {log?.details}</Typography>
              )}
              {log?.status === "Failed" &&
                ` - Failed Attempts: ${log?.failedAttempts}`}
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default LogsManager;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const ticketApi = createApi({
  reducerPath: "ticket",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/tickets/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Ticket"],
  endpoints: (builder) => ({
    createTicket: builder.mutation({
      query: ({ type, description }) => ({
        url: `create-one`,
        method: "POST",
        body: { type, description },
      }),
      skip: (accountType) => !accountType,
      invalidatesTags: ["Ticket"],
    }),
    getTickets: builder.query({
      query: ({ accountType, userId }) => ({
        url: "get-all",
        method: "GET",
      }),
      skip: (accountType, userId) => !userId || accountType !== "reosadmin",
      providesTags: ["Ticket"],
    }),
    getTicketById: builder.query({
      query: ({ ticketId }) => ({
        url: `get-one/${ticketId}`,
        method: "GET",
      }),
      skip: (ticketId) => !ticketId,
      providesTags: ["Ticket"],
    }),
    getTicketTypeEnums: builder.query({
      query: (userId) => ({
        url: `get-ticket-type-enums`,
        method: "GET",
      }),
      skip: (userId) => !userId,
      providesTags: ["Ticket"],
    }),
    updateTicket: builder.mutation({
      query: ({ ticketId, type, description }) => ({
        url: `update-one/${ticketId}`,
        method: "PUT",
        body: { type, description },
      }),
      invalidatesTags: ["Ticket"],
    }),
    responseToTicket: builder.mutation({
      query: ({ ticketId, responseText }) => ({
        url: `response-to-one/${ticketId}`,
        method: "PUT",
        body: { responseText },
      }),
      invalidatesTags: ["Ticket"],
    }),
    deleteTicket: builder.mutation({
      query: ({ ticketId }) => ({
        url: `delete-one/${ticketId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Ticket"],
    }),
  }),
});

export const {
  useCreateTicketMutation,
  useGetTicketsQuery,
  useGetTicketByIdQuery,
  useGetTicketTypeEnumsQuery,
  useUpdateTicketMutation,
  useResponseToTicketMutation,
  useDeleteTicketMutation,
} = ticketApi;

export default ticketApi;
export { ticketApi };

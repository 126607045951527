import React, { memo } from "react";
import { Snackbar, Button } from "@mui/material";

function NotificationSnackbar({
  open,
  message,
  onClose,
  notificationDuration = 2000,
}) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={notificationDuration}
      onClose={onClose}
      message={message}
      action={
        <Button color="secondary" size="small" onClick={onClose}>
          Close
        </Button>
      }
      className="notification-snackbar"
    />
  );
}

export default memo(NotificationSnackbar);

import React, { useState, useEffect } from "react";
import { useGetCategoryTypeEnumQuery } from "../../../services/category";
import {
  useCreateCategorySettingsMutation,
  useUpdateCategorySettingsMutation,
} from "../../../services/category-settings";
import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import FeatureSetting from "./FeatureSetting";

const CreateEditCategorySettingsForm = ({
  open,
  onClose,
  categorySettings,
  mode = "edit",
}) => {
  const [createCategorySettings, { isLoading: isCreateLoading }] =
    useCreateCategorySettingsMutation();
  const [updateCategorySettings, { isLoading }] =
    useUpdateCategorySettingsMutation();
  const { data: categoryTypes } = useGetCategoryTypeEnumQuery();

  const [categoryType, setCategoryType] = useState(
    categorySettings?.type || ""
  );
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [isREOSOnly, setIsREOSOnly] = useState({
    is: false,
    isNot: false,
    optional: false,
  });
  const [isWidgetable, setIsWidgetable] = useState({
    is: false,
    isNot: false,
    optional: false,
  });
  const [isEvent, setIsEvent] = useState({
    is: false,
    isNot: false,
    optional: false,
  });
  const [isMemberList, setIsMemberList] = useState({
    is: false,
    isNot: false,
    optional: false,
  });
  const [isHomePage, setIsHomePage] = useState({
    is: false,
    isNot: false,
    optional: false,
  });
  const [isChat, setIsChat] = useState({
    is: false,
    isNot: false,
    optional: false,
  });
  const [isListings, setIsListings] = useState({
    is: false,
    isNot: false,
    optional: false,
  });
  const [isFileSystem, setIsFileSystem] = useState({
    is: false,
    isNot: false,
    optional: false,
  });
  const [allowTags, setAllowTags] = useState({
    is: false,
    isNot: false,
    optional: false,
  });
  const [useWYSIWYG, setUseWYSIWYG] = useState({
    is: false,
    isNot: false,
    optional: false,
  });
  const [hasFeaturePicture, setHasFeaturePicture] = useState({
    is: false,
    isNot: false,
    optional: false,
  });
  const [allowAcknowledgements, setAllowAcknowledgements] = useState({
    is: false,
    isNot: false,
    optional: false,
  });
  const [allowAttachments, setAllowAttachments] = useState({
    is: false,
    isNot: false,
    optional: false,
  });
  const [allowComments, setAllowComments] = useState({
    is: false,
    isNot: false,
    optional: false,
  });
  const [allowLikes, setAllowLikes] = useState({
    is: false,
    isNot: false,
    optional: false,
  });

  useEffect(() => {
    if (categorySettings) {
      setCategoryType(categorySettings?.categoryType || "");
      setIsREOSOnly(
        categorySettings?.isREOSOnly || {
          is: false,
          isNot: false,
          optional: false,
        }
      );
      setIsWidgetable(
        categorySettings?.isWidgetable || {
          is: false,
          isNot: false,
          optional: false,
        }
      );
      setIsEvent(
        categorySettings?.isEvent || {
          is: false,
          isNot: false,
          optional: false,
        }
      );
      setIsMemberList(
        categorySettings?.isMemberList || {
          is: false,
          isNot: false,
          optional: false,
        }
      );
      setIsHomePage(
        categorySettings?.isHomePage || {
          is: false,
          isNot: false,
          optional: false,
        }
      );
      setIsChat(
        categorySettings?.isChat || { is: false, isNot: false, optional: false }
      );
      setIsFileSystem(
        categorySettings?.isFileSystem || {
          is: false,
          isNot: false,
          optional: false,
        }
      );
      setIsListings(
        categorySettings?.isListings || {
          is: false,
          isNot: false,
          optional: false,
        }
      );
      setAllowTags(
        categorySettings?.allowTags || {
          is: false,
          isNot: false,
          optional: false,
        }
      );
      setUseWYSIWYG(
        categorySettings?.useWYSIWYG || {
          is: false,
          isNot: false,
          optional: false,
        }
      );
      setHasFeaturePicture(
        categorySettings?.hasFeaturePicture || {
          is: false,
          isNot: false,
          optional: false,
        }
      );
      setAllowAcknowledgements(
        categorySettings?.allowAcknowledgements || {
          is: false,
          isNot: false,
          optional: false,
        }
      );
      setAllowAttachments(
        categorySettings?.allowAttachments || {
          is: false,
          isNot: false,
          optional: false,
        }
      );
      setAllowComments(
        categorySettings?.allowComments || {
          is: false,
          isNot: false,
          optional: false,
        }
      );
      setAllowLikes(
        categorySettings?.allowLikes || {
          is: false,
          isNot: false,
          optional: false,
        }
      );
    }
  }, [categorySettings]);

  const validateFeatureSettings = (feature) => {
    return feature.is || feature.isNot || feature.optional;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!categoryType.trim()) {
      setNotification({
        open: true,
        message: "Please fill in all required fields.",
      });
      return;
    }

    const allFeaturesValid =
      validateFeatureSettings(isREOSOnly) &&
      validateFeatureSettings(isWidgetable) &&
      validateFeatureSettings(isEvent) &&
      validateFeatureSettings(isMemberList) &&
      validateFeatureSettings(isHomePage) &&
      validateFeatureSettings(isChat) &&
      validateFeatureSettings(isFileSystem) &&
      validateFeatureSettings(isListings) &&
      validateFeatureSettings(allowTags) &&
      validateFeatureSettings(useWYSIWYG) &&
      validateFeatureSettings(hasFeaturePicture) &&
      validateFeatureSettings(allowAcknowledgements) &&
      validateFeatureSettings(allowAttachments) &&
      validateFeatureSettings(allowComments) &&
      validateFeatureSettings(allowLikes);

    if (!allFeaturesValid) {
      setNotification({
        open: true,
        message:
          "Please select at least one option (is, isNot, optional) for every property.",
      });
      return;
    }

    let result;
    const categorySettingsData = {
      categoryType,
      isREOSOnly,
      isWidgetable,
      isEvent,
      isMemberList,
      isHomePage,
      isChat,
      isFileSystem,
      isListings,
      allowTags,
      useWYSIWYG,
      hasFeaturePicture,
      allowAcknowledgements,
      allowAttachments,
      allowComments,
      allowLikes,
    };

    if (mode === "create") {
      result = await createCategorySettings(categorySettingsData);
    } else if (mode === "edit") {
      result = await updateCategorySettings({
        categorySettingsId: categorySettings?._id,
        ...categorySettingsData,
      });
    }

    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        if (mode === "create") {
          setIsREOSOnly({ is: false, isNot: false, optional: false });
          setIsWidgetable({ is: false, isNot: false, optional: false });
          setIsEvent({ is: false, isNot: false, optional: false });
          setIsMemberList({ is: false, isNot: false, optional: false });
          setIsHomePage({ is: false, isNot: false, optional: false });
          setIsChat({ is: false, isNot: false, optional: false });
          setIsFileSystem({ is: false, isNot: false, optional: false });
          setIsListings({ is: false, isNot: false, optional: false });
          setAllowTags({ is: false, isNot: false, optional: false });
          setUseWYSIWYG({ is: false, isNot: false, optional: false });
          setHasFeaturePicture({ is: false, isNot: false, optional: false });
          setAllowAcknowledgements({
            is: false,
            isNot: false,
            optional: false,
          });
          setAllowAttachments({ is: false, isNot: false, optional: false });
          setAllowComments({ is: false, isNot: false, optional: false });
          setAllowLikes({ is: false, isNot: false, optional: false });
        }
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error saving category settings: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleCheckboxChange = (event, settingName) => {
    const { name, checked } = event.target;
    const newSetting = {
      is: false,
      isNot: false,
      optional: false,
      [name]: checked,
    };

    switch (settingName) {
      case "isREOSOnly":
        setIsREOSOnly(newSetting);
        break;
      case "isWidgetable":
        setIsWidgetable(newSetting);
        break;
      case "isEvent":
        setIsEvent(newSetting);
        break;
      case "isMemberList":
        setIsMemberList(newSetting);
        break;
      case "isHomePage":
        setIsHomePage(newSetting);
        break;
      case "isChat":
        setIsChat(newSetting);
        break;
      case "isFileSystem":
        setIsFileSystem(newSetting);
        break;
      case "isListings":
        setIsListings(newSetting);
        break;
      case "allowTags":
        setAllowTags(newSetting);
        break;
      case "useWYSIWYG":
        setUseWYSIWYG(newSetting);
        break;
      case "hasFeaturePicture":
        setHasFeaturePicture(newSetting);
        break;
      case "allowAcknowledgements":
        setAllowAcknowledgements(newSetting);
        break;
      case "allowAttachments":
        setAllowAttachments(newSetting);
        break;
      case "allowComments":
        setAllowComments(newSetting);
        break;
      case "allowLikes":
        setAllowLikes(newSetting);
        break;
      default:
        break;
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Typography sx={{ fontWeight: 700, fontSize: "1.5rem" }}>
          {mode === "create"
            ? "Create New Category Settings"
            : "Edit Category Settings"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid item>
            <FormControl fullWidth margin="normal">
              <InputLabel id="tier-level-label">Category Type</InputLabel>
              <Select
                labelId="cat-type-level-label"
                id="catTypeId"
                label="Category Type"
                value={categoryType}
                onChange={(e) => setCategoryType(e.target.value)}
                fullWidth
                required
              >
                {categoryTypes &&
                  categoryTypes?.map((catType, i) => (
                    <MenuItem key={`${catType}-${i}`} value={catType}>
                      {catType}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box border={1} borderColor="grey.400" borderRadius={4} p={2}>
              <Typography
                sx={{
                  fontWeight: 700,
                  color: "var(--text-color)",
                  bgcolor: "var(--bg-color)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                variant="subtitle"
              >
                Settings for General Category Type
              </Typography>
              <Grid container spacing={2}>
                {/* "Is" Checkboxes Row */}
                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <FeatureSetting
                      settingName="isREOSOnly"
                      settingValue={isREOSOnly}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                    <FeatureSetting
                      settingName="isWidgetable"
                      settingValue={isWidgetable}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                    <FeatureSetting
                      settingName="isChat"
                      settingValue={isChat}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                    <FeatureSetting
                      settingName="isEvent"
                      settingValue={isEvent}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                    <FeatureSetting
                      settingName="isFileSystem"
                      settingValue={isFileSystem}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                    <FeatureSetting
                      settingName="isMemberList"
                      settingValue={isMemberList}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                    <FeatureSetting
                      settingName="isListings"
                      settingValue={isListings}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                    <FeatureSetting
                      settingName="isHomePage"
                      settingValue={isHomePage}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                  </Grid>
                </Grid>
                {/* Category Options */}
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: "var(--text-color)",
                      bgcolor: "var(--bg-color)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    variant="subtitle"
                  >
                    Category Settings Options
                  </Typography>
                  <Grid container spacing={2}>
                    <FeatureSetting
                      settingName="allowTags"
                      settingValue={allowTags}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                    <FeatureSetting
                      settingName="useWYSIWYG"
                      settingValue={useWYSIWYG}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                    <FeatureSetting
                      settingName="hasFeaturePicture"
                      settingValue={hasFeaturePicture}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                    <FeatureSetting
                      settingName="allowAcknowledgements"
                      settingValue={allowAcknowledgements}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                    <FeatureSetting
                      settingName="allowAttachments"
                      settingValue={allowAttachments}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                    <FeatureSetting
                      settingName="allowComments"
                      settingValue={allowComments}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                    <FeatureSetting
                      settingName="allowLikes"
                      settingValue={allowLikes}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid item>
          <Button onClick={onClose} color="secondary" type="submit" fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={
              mode === "create"
                ? isCreateLoading
                : mode === "edit"
                ? isLoading
                : isLoading
            }
          >
            {isLoading ? (
              <CircularProgress size={24} /> ? (
                mode === "create"
              ) : (
                "Create"
              )
            ) : (
              "Save"
            )}
          </Button>
        </Grid>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default CreateEditCategorySettingsForm;

import React, { createContext, useState, useEffect, useContext } from "react";
import { useGetCategoriesByUserRoleQuery } from "../services/category";
import { useGetTierByIdQuery } from "../services/tier";
import { AuthContext } from "./AuthContext";

export const TierContext = createContext();

export const useTier = () => useContext(TierContext);

export const TierProvider = ({ children }) => {
  const { isTokenReady, token, userId } = useContext(AuthContext);
  const { data: categoriesByRole, isFetching } =
    useGetCategoriesByUserRoleQuery(userId, { skip: !isTokenReady });

  const [currentTierId, setCurrentTierId] = useState(() =>
    localStorage.getItem("lastTierId")
  );
  const { data: tierById, isTierFetching } = useGetTierByIdQuery(
    currentTierId,
    {
      skip: !isTokenReady || !currentTierId,
    }
  );
  const [tierImage, setTierImage] = useState(() =>
    localStorage.getItem("tierImage")
  );
  const [isREACircAgents, setIsREACircAgents] = useState(false);
  const [tierSubTitle, setTierSubTitle] = useState(() =>
    localStorage.getItem("tierSubTitle")
  );
  const [tierType, setTierType] = useState(() =>
    localStorage.getItem("tierType")
  );

  const [openTiers, setOpenTiers] = useState(
    () => JSON.parse(localStorage.getItem("openTiers")) || {}
  );
  const [openCategoryTree, setOpenCategoryTree] = useState(
    () => JSON.parse(localStorage.getItem("openCategoryTree")) || {}
  );

  useEffect(() => {
    if (isTokenReady && categoriesByRole && categoriesByRole.length > 0) {
      const storedTiers = localStorage.getItem("openTiers");
      const storedCategoryTree = localStorage.getItem("openCategoryTree");
      const hasOpenTiers =
        storedTiers && Object.keys(JSON.parse(storedTiers)).length > 0;
      const hasOpenCategoryTree =
        storedCategoryTree &&
        Object.keys(JSON.parse(storedCategoryTree)).length > 0;

      if (!hasOpenTiers) {
        const initialTiersState = categoriesByRole?.reduce((acc, tier) => {
          acc[tier.tierId] = { isOpen: true };
          return acc;
        }, {});
        setOpenTiers(initialTiersState);
        localStorage.setItem("openTiers", JSON.stringify(initialTiersState));
      } else {
        setOpenTiers(JSON.parse(storedTiers));
      }

      if (!hasOpenCategoryTree) {
        const initialCategoryState = categoriesByRole.reduce((acc, tier) => {
          acc[tier.tierId] = { isOpen: true, groups: {} };
          Object.keys(tier.groups)?.forEach((groupName) => {
            acc[tier.tierId].groups[groupName] = true;
          });
          return acc;
        }, {});
        setOpenCategoryTree(initialCategoryState);
        localStorage.setItem(
          "openCategoryTree",
          JSON.stringify(initialCategoryState)
        );
      } else {
        setOpenCategoryTree(JSON.parse(storedCategoryTree));
      }
    }
  }, [isTokenReady, categoriesByRole]);

  useEffect(() => {
    if (isTokenReady && categoriesByRole && !isFetching) {
      const storedTierId = localStorage.getItem("lastTierId");
      const validTierId = categoriesByRole.some(
        (tier) => tier.tierId === storedTierId
      );

      if (storedTierId && validTierId) {
        setCurrentTierId(storedTierId);
      } else if (categoriesByRole.length > 0) {
        const defaultTierId = categoriesByRole[0].tierId;
        setCurrentTierId(defaultTierId);
        localStorage.setItem("lastTierId", defaultTierId);
      }
    }
  }, [isTokenReady, categoriesByRole, isFetching]);

  useEffect(() => {
    if (isTokenReady && tierById && !isTierFetching) {
      const storedTierType = localStorage.getItem("tierType");

      if (storedTierType !== tierById?.type) {
        setTierType(tierById.type);
      }
      localStorage.setItem("tierType", tierById.type);

      setTierImage(tierById?.brokerage?.image?.url);
      localStorage.setItem("tierImage", tierById?.brokerage?.image?.url);

      setTierSubTitle(tierById?.brokerage?.subTitle);
      localStorage.setItem("tierSubTitle", tierById?.brokerage?.subTitle);
    }
  }, [currentTierId, isTierFetching, isTokenReady, tierById]);

  useEffect(() => {
    localStorage.setItem("openTiers", JSON.stringify(openTiers));
    localStorage.setItem("openCategoryTree", JSON.stringify(openCategoryTree));
  }, [openTiers, openCategoryTree]);

  useEffect(() => {
    if (isTokenReady && tierById) {
      setIsREACircAgents(tierById?._id === "66ad4a4541ac23a7d1bb446b");
      localStorage.setItem(
        "isREACircAgent",
        tierById?._id === "66ad4a4541ac23a7d1bb446b"
      );
    } else {
      setIsREACircAgents(false);
      localStorage.setItem("isREACircAgent", false);
    }
  }, [isTokenReady, tierById]);

  const selectTierId = (tierId) => {
    setCurrentTierId(tierId);
    localStorage.setItem("lastTierId", tierId);
  };

  const toggleTierOpenState = (tierId) => {
    setOpenTiers((prevOpenTiers) => {
      const isCurrentlyOpen = prevOpenTiers[tierId]?.isOpen || false;
      const updatedOpenTiers = {
        ...prevOpenTiers,
        [tierId]: { isOpen: !isCurrentlyOpen },
      };
      localStorage.setItem("openTiers", JSON.stringify(updatedOpenTiers));
      return updatedOpenTiers;
    });
  };

  const toggleGroupOpenState = (tierId, groupName) => {
    const newState = {
      ...openCategoryTree,
      [tierId]: {
        ...openCategoryTree[tierId],
        groups: {
          ...openCategoryTree[tierId]?.groups,
          [groupName]: !openCategoryTree[tierId]?.groups[groupName],
        },
      },
    };
    setOpenCategoryTree(newState);
    localStorage.setItem("openCategoryTree", JSON.stringify(newState));
  };

  useEffect(() => {
    if (!token) {
      setCurrentTierId(null);
      setTierType(null);
      setTierImage(null);
      setTierSubTitle(null);
      setOpenTiers({});
      setOpenCategoryTree({});
      localStorage.removeItem("lastTierId");
      localStorage.removeItem("openTiers");
      localStorage.removeItem("openCategoryTree");
      localStorage.removeItem("tierType");
      localStorage.removeItem("tierImage");
      localStorage.removeItem("tierSubTitle");
      localStorage.removeItem("isREACircAgent");
    }
  }, [token]);

  return (
    <TierContext.Provider
      value={{
        currentTierId,
        tierType,
        tierImage,
        tierSubTitle,
        isREACircAgents,
        selectTierId,
        openTiers,
        openCategoryTree,
        toggleTierOpenState,
        toggleGroupOpenState,
      }}
    >
      {children}
    </TierContext.Provider>
  );
};

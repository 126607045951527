import React, { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Divider,
  Box,
  TableSortLabel,
} from "@mui/material";

function ErrorLogsList({ invitationLogs }) {
  const [sortField, setSortField] = useState("service");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection((prevDirection) =>
        prevDirection === "asc" ? "desc" : "asc"
      );
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const sortedInvitationLogs = useMemo(() => {
    if (!invitationLogs) return [];
    const comparator = (a, b) => {
      if (sortDirection === "asc") {
        return a[sortField] < b[sortField] ? -1 : 1;
      } else {
        return a[sortField] > b[sortField] ? -1 : 1;
      }
    };
    return [...invitationLogs].sort(comparator);
  }, [invitationLogs, sortField, sortDirection]);

  if (!invitationLogs || invitationLogs?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 20,
        }}
      >
        <Typography variant="h5" gutterBottom>
          No Invitation Logs available
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "user?.firstName"}
                    direction={sortDirection}
                    onClick={() => handleSort("user?.firstName")}
                  >
                    user name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "sender?.firstName"}
                    direction={sortDirection}
                    onClick={() => handleSort("sender?.firstName")}
                  >
                    sender name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "invitation?.status"}
                    direction={sortDirection}
                    onClick={() => handleSort("invitation?.status")}
                  >
                    status
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "timestamp"}
                    direction={sortDirection}
                    onClick={() => handleSort("timestamp")}
                  >
                    timestamp
                  </TableSortLabel>
                </TableCell>
                <TableCell>service</TableCell>
                <TableCell>function</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(sortedInvitationLogs) &&
                sortedInvitationLogs?.map((invitationLog) => (
                  <TableRow key={invitationLog?._id} className="table-row">
                    <TableCell>
                      {invitationLog?.user?.firstName}{" "}
                      {invitationLog?.user?.lastName}
                    </TableCell>
                    <TableCell>
                      {invitationLog?.sender?.firstName}{" "}
                      {invitationLog?.sender?.lastName}
                    </TableCell>
                    <TableCell>{invitationLog?.status}</TableCell>
                    <TableCell>
                      {new Date(invitationLog?.timestamp).toLocaleString()}
                    </TableCell>
                    <TableCell>{invitationLog?.service}</TableCell>
                    <TableCell>{invitationLog?.function}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider style={{ margin: "20px 0" }} />
      </div>
    </>
  );
}

export default ErrorLogsList;

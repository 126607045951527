import React, { useState } from "react";
import { Paper, Tabs, Tab } from "@mui/material";
import { TabPanel } from "../../helpers/utils";
import GroupRoles from "../../reosadmin/team-management//group/group-roles/GroupRoles";
import GroupCategoryManager from "./GroupCategoryManager";

function GroupManagerTabs({ groupId, groupMembers, groupTier }) {
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper className="tabs-paper-brokerage-ecosys">
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="brokerage tabs"
        variant="scrollable"
        scrollButtons="auto"
        className="tabs-paper"
      >
        <Tab
          label="Group Roles"
          id="simple-tab-0"
          aria-controls="simple-tabpanel-0"
        />
        <Tab
          label="Group Categories"
          id="simple-tab-1"
          aria-controls="simple-tabpanel-1"
        />
      </Tabs>
      <TabPanel value={tabValue} index={0} className="tabs-paper">
        <GroupRoles groupId={groupId} groupMembers={groupMembers} />
      </TabPanel>
      <TabPanel value={tabValue} index={1} className="tabs-paper">
        <GroupCategoryManager tier={groupTier} />
      </TabPanel>
    </Paper>
  );
}

export default GroupManagerTabs;

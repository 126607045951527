import React from "react";
import { Drawer, IconButton } from "@mui/material";
import EditProfileDataForm from "./EditProfileDataForm";
import { Close } from "@mui/icons-material/";

const EditProfilesDrawer = ({ userId, open, onClose }) => {
  if (!userId) return null;

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          height: `calc(100% - 5rem)`,
          top: "5rem",
        },
      }}
    >
      <div className="drawer-header">
        <IconButton onClick={onClose} className="close-button">
          <Close />
        </IconButton>
      </div>
      <EditProfileDataForm
        userMemberId={userId}
        isDrawer={true}
        onClose={onClose}
      />
    </Drawer>
  );
};

export default EditProfilesDrawer;

import React, { useState } from "react";
import { useDeleteTicketMutation } from "../../../services/ticket";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Divider,
  Box,
  TableSortLabel,
} from "@mui/material";
import { MoreVert, DeleteForever, Edit } from "@mui/icons-material/";
import { ConfirmationDialog } from "../..";
import { useNavigate } from "react-router-dom";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import CreateEditTicketDialog from "./CreateEditTicketDialog";

function TicketsList({ tickets, refetchTickets }) {
  const navigate = useNavigate();
  const [deleteTicket] = useDeleteTicketMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [currentTicketClicked, setCurrentTicketClicked] = useState(null);
  const [currentTicket, setCurrentTicket] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [sortField, setSortField] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [openEditTicketDialog, setOpenEditTicketDialog] = useState(false);

  const handleRowClick = (event, ticketItemId, ticketItem) => {
    if (
      event.target.closest(".menu-button") ||
      event.target.closest(".view-details-button")
    ) {
      setCurrentTicketClicked(ticketItemId);
      setCurrentTicket(ticketItem);
      console.log(currentTicket);

      return;
    }
    navigate(`/ticket/${ticketItemId}`);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenEditTicketDialog = (event) => {
    event.stopPropagation();
    setOpenEditTicketDialog(true);
    setAnchorEl(null);
  };

  const handleCloseEditTicketDialog = () => {
    setOpenEditTicketDialog(false);
  };

  const handleDeleteTicketClick = (event, ticketId) => {
    event.stopPropagation();
    setCurrentTicket({ ...currentTicket, _id: ticketId });
    setConfirmationDialogOpen(true);
  };
  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleConfirmationDialogConfirm = async () => {
    console.log(currentTicket?._id);
    if (currentTicket?._id) {
      const result = await deleteTicket({
        ticketId: currentTicket._id,
      });

      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        refetchTickets?.();
        setConfirmationDialogOpen(false);
      } else {
        setNotification({
          open: true,
          message: `Error: ${result?.error?.data?.msg}`,
        });
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  if (!tickets || tickets?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 20,
        }}
      >
        <Typography variant="h5" gutterBottom>
          No Tickets available
        </Typography>
        <Link to="/support-management">Go to Create Ticket</Link>
      </Box>
    );
  }

  return (
    <>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "type"}
                    direction={sortDirection}
                    onClick={() => handleSort("type")}
                  >
                    Type
                  </TableSortLabel>
                </TableCell>
                <TableCell>description</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "status"}
                    direction={sortDirection}
                    onClick={() => handleSort("status")}
                  >
                    status
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "priority"}
                    direction={sortDirection}
                    onClick={() => handleSort("priority")}
                  >
                    priority
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "createdAt"}
                    direction={sortDirection}
                    onClick={() => handleSort("createdAt")}
                  >
                    createdAt
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "updatedAt"}
                    direction={sortDirection}
                    onClick={() => handleSort("updatedAt")}
                  >
                    updatedAt
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(tickets) &&
                tickets?.map((ticket) => (
                  <TableRow
                    key={ticket?._id}
                    onClick={(event) =>
                      handleRowClick(event, ticket?._id, ticket)
                    }
                    style={{ cursor: "pointer" }}
                    className="table-row"
                  >
                    <TableCell>{ticket?.type}</TableCell>
                    <TableCell>{ticket?.description}</TableCell>
                    <TableCell>{ticket?.status}</TableCell>
                    <TableCell>{ticket?.priority}</TableCell>
                    <TableCell>{ticket?.createdAt}</TableCell>
                    <TableCell>{ticket?.updatedAt}</TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          aria-label="delete"
                          onClick={(event) =>
                            handleDeleteTicketClick(event, ticket?._id)
                          }
                        >
                          <DeleteForever />
                        </IconButton>
                        <IconButton
                          className="menu-button"
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(event) =>
                            handleMenuOpen(event, ticket?._id)
                          }
                        >
                          <MoreVert />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleMenuClose}
                        >
                          <MenuItem
                            onClick={(event) => {
                              event.stopPropagation();
                              handleOpenEditTicketDialog(event);
                            }}
                          >
                            <Edit />
                            <Typography ml={1}>Edit ticket</Typography>
                          </MenuItem>
                          <MenuItem>
                            <Typography
                              variant="body1"
                              className="view-details-button"
                              onClick={() =>
                                navigate(`/ticket/${currentTicketClicked}`)
                              }
                            >
                              View Details
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider style={{ margin: "20px 0" }} />
      </div>
      <CreateEditTicketDialog
        mode="edit"
        refetchTickets={refetchTickets}
        ticket={currentTicket}
        open={openEditTicketDialog}
        onClose={handleCloseEditTicketDialog}
      />
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmationDialogConfirm}
        title="Confirm Deleting the ticket"
        description="Are you sure you want to delete this ticket permanently?"
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
}

export default TicketsList;

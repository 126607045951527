import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  CardMedia,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Tooltip,
  IconButton,
  Autocomplete,
} from "@mui/material";
import {
  useCreateBrokerageMutation,
  useUpdateBrokerageMutation,
  useUploadImageMutation,
} from "../../../services/brokerage";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import { timezones } from "../../helpers/utils";
import realEstateBoardsList from "../../helpers/realEstateBoardsList";
import { AuthContext } from "../../../context/AuthContext";

const BrokerageCreateEditForm = ({
  refetchBrokerage,
  brokerage,
  onClose,
  mode = "edit",
}) => {
  const { userId, userRole, accountType } = useContext(AuthContext);
  const isAdminRole = [
    "reosadmin",
    "brokeragesuperadmin",
    "reossuperadmin",
  ].includes(userRole);
  const isREOSAdminRole = ["reosadmin", "reossuperadmin"].includes(userRole);
  const isAdmin = [
    "reosadmin",
    "reossuperadmin",
    "brokerage",
    "brokerageadmin",
    "brokeragesuperadmin",
  ].includes(userRole);

  const [createBrokerage, { isLoading: isCreateLoading }] =
    useCreateBrokerageMutation();
  const [updateBrokerage, { isLoading: isUpdateLoading }] =
    useUpdateBrokerageMutation();
  const [updateImage] = useUploadImageMutation();
  const [brokerageData, setBrokerageData] = useState({
    officeName: "",
    officeShortName: "",
    subTitle: "",
    customSystemName: "",
    isLeadBrokerage: false,
    realEstateBoard: "",
    address: {
      street: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    },
    website: "",
    billingEmail: "",
    ...(isREOSAdminRole
      ? {
          stripeCustomerId: "",
          stripePaymentMethodId: "",
        }
      : {}),
    phoneNumbers: [],
    timezone: "",
  });
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (brokerage && mode === "edit") {
      setBrokerageData((prevState) => ({
        ...prevState,
        isLeadBrokerage: brokerage.isLeadBrokerage || false,
        officeName: brokerage.officeName || "",
        officeShortName: brokerage.officeShortName || "",
        subTitle: brokerage.subTitle || "",
        customSystemName: brokerage.customSystemName || "",
        realEstateBoard: brokerage.realEstateBoard?.rebName || "",
        address: brokerage.address || {
          street: "",
          city: "",
          state: "",
          zip: "",
          country: "",
        },
        website: brokerage.website || "",
        billingEmail: brokerage.billingEmail || "",
        ...(isREOSAdminRole
          ? {
              stripeCustomerId: brokerage.stripeCustomerId || "",
              stripePaymentMethodId: brokerage.stripePaymentMethodId || "",
            }
          : {}),
        phoneNumbers: brokerage.phoneNumbers || [],
        timezone: brokerage.timezone || "",
      }));
    }
  }, [brokerage, mode]);

  const handleSubmit = async () => {
    if (
      mode === "create" &&
      (!brokerageData?.officeName || !brokerageData?.officeShortName)
    ) {
      setNotification({
        open: true,
        message: "Office Name and Short Name are required.",
      });
      return;
    }
    let result;
    if (mode === "create") {
      result = await createBrokerage({
        brokerageData,
      });
    } else if (mode === "edit") {
      result = await updateBrokerage({
        brokerageData,
        brokerageId: brokerage?._id,
      });
    }
    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        refetchBrokerage?.();
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error saving changes: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const response = await updateImage({
          brokerageId: brokerage?._id,
          brokerageImage: file,
        });
        if (response.error) {
          throw response.error;
        }
        if (response?.data) {
          setNotification({
            open: true,
            message: `${response?.data?.msg}`,
          });
          refetchBrokerage?.();
        }
      } catch (error) {
        setNotification({
          open: true,
          message: `Failed to update image: ${error?.data?.msg}`,
        });
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "isLeadBrokerage") {
      setBrokerageData((prevState) => ({
        ...prevState,
        [name]: value === "true",
      }));
    } else {
      setBrokerageData((prevState) => ({ ...prevState, [name]: value || "" }));
    }

    // setBrokerageData({ ...brokerageData, [name]: value || "" });
  };

  const handleArrayChange = (name, value) => {
    setBrokerageData({
      ...brokerageData,
      [name]: value.split(",").map((item) => item.trim()),
    });
  };

  const handleChangeAddress = (field, value) => {
    setBrokerageData({
      ...brokerageData,
      address: { ...brokerageData.address, [field]: value },
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <div className={"brokerage-edit-form"}>
      <Grid container className="brokerage-edit-form-body">
        <Grid item container className="brokerage-edit-form-header">
          <div className="header-image-block">
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="upload-image-file"
              type="file"
              onChange={handleImageUpload}
            />
            <label htmlFor="upload-image-file">
              <Tooltip title="Upload Photo" arrow>
                <IconButton color="primary" component="span">
                  {brokerage?.image?.url ? (
                    <CardMedia
                      id="officeName"
                      component="img"
                      className="brokerage-image"
                      image={brokerage?.image?.url}
                      alt={brokerageData?.officeName}
                    />
                  ) : (
                    <Typography
                      variant="h6"
                      sx={{ textAlign: "center", mr: 3 }}
                      color="textSecondary"
                    >
                      Brokerage Logo Here...
                    </Typography>
                  )}
                </IconButton>
              </Tooltip>
            </label>
            <Button
              variant="text"
              component="span"
              onClick={() =>
                document.getElementById("upload-image-file").click()
              }
            >
              {brokerage?.image?.url ? "Change Photo" : "Add Photo"}
            </Button>
          </div>
          <div className="header-profile-title">
            <Typography
              gutterBottom
              className="header-profile-title-name"
              component="div"
            >
              {brokerage?.officeName || brokerageData?.officeName}
            </Typography>
          </div>
        </Grid>
        <Grid container className="brokerage-edit-form-fields" spacing={1}>
          <Grid item xs={12}>
            <TextField
              label="Brokerage Name"
              name="officeName"
              value={brokerageData.officeName}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Office Short Name Used for Menus"
              name="officeShortName"
              value={brokerageData.officeShortName}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="SubTitle used for Customization"
              name="subTitle"
              value={brokerageData.subTitle}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Custom System Name used for Customization"
              name="customSystemName"
              value={brokerageData.customSystemName}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              name="realEstateBoard"
              value={brokerageData.realEstateBoard}
              onChange={(event, newValue) => {
                handleChange({
                  target: {
                    name: "realEstateBoard",
                    value: newValue,
                  },
                });
              }}
              options={realEstateBoardsList || []}
              getOptionLabel={(option) => (option ? option : "")}
              isOptionEqualToValue={(option, value) => option === value}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a Real Estate Board"
                  variant="outlined"
                  margin="normal"
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Website"
              name="website"
              value={brokerageData.website}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Billing Email"
              name="billingEmail"
              value={brokerageData.billingEmail}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          {isREOSAdminRole && (
            <>
              <Grid item xs={12}>
                <TextField
                  label="Stripe Customer Id"
                  name="stripeCustomerId"
                  value={brokerageData.stripeCustomerId}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Stripe Payment Method Id"
                  name="stripePaymentMethodId"
                  value={brokerageData.stripePaymentMethodId}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="div"
              color="text.secondary"
              gutterBottom
            >
              Brokerage Office Address
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Street"
              name="street"
              value={brokerageData.address.street}
              onChange={(e) => handleChangeAddress("street", e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="City"
              name="city"
              value={brokerageData.address.city}
              onChange={(e) => handleChangeAddress("city", e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="State"
              name="state"
              value={brokerageData.address.state}
              onChange={(e) => handleChangeAddress("state", e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Zip"
              name="zip"
              value={brokerageData.address.zip}
              onChange={(e) => handleChangeAddress("zip", e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Country"
              name="country"
              value={brokerageData.address.country}
              onChange={(e) => handleChangeAddress("country", e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Phone Numbers"
              name="phoneNumbers"
              value={brokerageData.phoneNumbers.join(", ")}
              onChange={(e) =>
                handleArrayChange("phoneNumbers", e.target.value)
              }
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Timezone</InputLabel>
              <Select
                name="timezone"
                value={brokerageData.timezone}
                onChange={handleChange}
                label="Timezone"
              >
                {timezones?.map((tz) => (
                  <MenuItem key={tz} value={tz}>
                    {tz}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container className="brokerage-edit-form-actions" spacing={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className="actions-button"
            disabled={
              mode === "create"
                ? isCreateLoading
                : mode === "edit"
                ? isUpdateLoading
                : isUpdateLoading
            }
          >
            {isUpdateLoading || isCreateLoading ? (
              <CircularProgress size={24} />
            ) : mode === "create" ? (
              "Create"
            ) : (
              "Save Changes"
            )}
          </Button>
        </Grid>
      </Grid>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </div>
  );
};

export default BrokerageCreateEditForm;

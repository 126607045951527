import React, { memo } from "react";
import { Drawer, IconButton, Typography } from "@mui/material";
import BrokerageCreateEditForm from "./BrokerageCreateEditForm";
import { Close } from "@mui/icons-material/";

const BrokerageEditDrawer = ({
  refetchBrokerage,
  brokerage,
  open,
  onClose,
  mode = "edit",
}) => {
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          height: `calc(100% - 5rem)`,
          top: "5rem",
        },
      }}
    >
      <div className="brokerage-edit-drawer-header">
        <Typography
          gutterBottom
          component="div"
          className="header-profile-title-name"
        >
          {`Brokerage ${mode === "create" ? "Create" : "Edit"} Form`}
        </Typography>
        <IconButton onClick={onClose} className="close-button">
          <Close />
        </IconButton>
      </div>
      <BrokerageCreateEditForm
        mode={mode}
        brokerage={brokerage}
        onClose={onClose}
        refetchBrokerage={refetchBrokerage}
      />
    </Drawer>
  );
};

export default memo(BrokerageEditDrawer);

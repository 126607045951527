import React, { memo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import PostContent from "../posts/PostContent";

const AnnouncementDialog = ({
  open,
  onClose,
  announcement,
  onAcknowledge,
  isPostPage,
}) => {
  if (!announcement) return;

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Announcement: {announcement?.postTitle}</DialogTitle>
      <DialogContent>
        <PostContent content={announcement?.content} isPostPage={isPostPage} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onAcknowledge(announcement?._id)}>
          Acknowledge
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(AnnouncementDialog);

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetTierByIdQuery } from "../../../services/tier";
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { BackButton } from "../..";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const TierItemPage = () => {
  const { tierId } = useParams();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const {
    data: tier,
    error,
    isLoading,
  } = useGetTierByIdQuery(tierId, {
    skip: !tierId,
  });

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (isLoading)
    return (
      <div className="loading-spinner">
        <CircularProgress size={24} />
      </div>
    );
  if (!tier) {
    return (
      <Typography variant="h4" gutterBottom>
        No Tier Found
      </Typography>
    );
  }

  if (error) return <div>Error: {error?.data?.msg}</div>;

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <BackButton />
      </Box>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Tier Type: {tier?.type || ""}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Tier Level: {tier?.level || ""}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Tier Features: {tier?.features || ""}
              </Typography>
            </Grid>
            {tier?.brokerage && (
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Brokerage: {tier?.brokerage?.user?.firstName || ""}{" "}
                  {tier?.brokerage?.user?.lastName || ""}{" "}
                  {tier?.brokerage?.user?.email || ""}
                </Typography>
              </Grid>
            )}
            {tier?.team && (
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  team name: {tier?.team?.name || ""}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  team Url Name: {tier?.team?.teamUrlName || ""}
                </Typography>
              </Grid>
            )}
            {tier?.group && (
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  group name: {tier?.group?.name || ""}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  group Url Name: {tier?.group?.groupUrlName || ""}
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Container>
  );
};

export default TierItemPage;

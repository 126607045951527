import React from "react";
import { Container, Typography, Paper, Divider } from "@mui/material";
import FeatureSettingsManagementTabs from "./FeatureSettingsManagementTabs";

const FeatureSettingsManagement = () => {
  return (
    <Container className="management-page">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" align="center" gutterBottom>
          FeatureSettings Management
        </Typography>
        <Divider />
      </Paper>
      <FeatureSettingsManagementTabs />
    </Container>
  );
};

export default FeatureSettingsManagement;

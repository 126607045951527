import React, { useState, useContext, useEffect, memo } from "react";
import { useInviteMultipleMembersMutation } from "../../../services/invitation";
import { useGetAllTiersQuery } from "../../../services/tier";
import { useGetAllUserRoleNameEnumsQuery } from "../../../services/user-role-name-enum";
import { AuthContext } from "../../../context/AuthContext";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Divider,
  Typography,
  Avatar,
  Autocomplete,
  // useMediaQuery,
  Alert,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
// import { useTheme } from "@mui/material/styles";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import Papa from "papaparse";

const InviteMembersCSVDialog = ({ open, onClose, preselectedTier }) => {
  const { accountType } = useContext(AuthContext);
  // const theme = useTheme();
  // const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [inviteMultipleMembers, { isLoading }] =
    useInviteMultipleMembersMutation();
  const { data: tiers } = useGetAllTiersQuery(accountType, {
    skip: accountType !== "reosadmin",
  });
  const { data: userRoleNameEnums } = useGetAllUserRoleNameEnumsQuery();
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [selectedTier, setSelectedTier] = useState(null);
  const [selectedUserRole, setSelectedUserRole] = useState(null);
  const [sendEmailToEveryone, setSendEmailToEveryone] = useState(false);
  const [csvData, setCsvData] = useState("");
  const [parsedMembers, setParsedMembers] = useState([]);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (tiers && preselectedTier) {
      const matchingTier = tiers.find(
        (tier) => tier._id === preselectedTier._id
      );
      if (matchingTier) {
        setSelectedTier(matchingTier);
      }
    }
  }, [tiers, preselectedTier]);

  const handleCsvChange = (event) => {
    const csvContent = event.target.value;
    setCsvData(csvContent);
    const parsedData = Papa.parse(csvContent, {
      header: true,
      skipEmptyLines: true,
      transformHeader: (header) => header.trim(),
      transform: (value) => value.trim(),
    });

    if (parsedData.errors.length === 0) {
      const members = parsedData.data.map((row) => ({
        email: row.email || "",
        emailPublic: row.emailPublic || "",
        isEmailPrivate: row.isEmailPrivate || false,
        firstName: row.firstName || "",
        lastName: row.lastName || "",
        imageUrl: row.imageUrl || "",
        officeExt: row.officeExt || "",
        bio: row.bio || "",
        website: row.website || "",
        phone: row.phone || "",
        userRoleNameEnumId: selectedUserRole?._id || "",
      }));
      setParsedMembers(members);
      setIsEmailValid(
        members.every((member) =>
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(member.email)
        )
      );
      setIsPhoneValid(
        members.every((member) => member.phone && member.phone.length === 10)
      );
    } else {
      setParsedMembers([]);
      setIsEmailValid(false);
      setIsPhoneValid(true);
    }
  };

  const handleMemberChange = (index, field, value) => {
    const updatedMembers = [...parsedMembers];
    updatedMembers[index][field] = value.trim();
    setParsedMembers(updatedMembers);
    if (field === "email") {
      setIsEmailValid(
        updatedMembers.every((member) =>
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(member.email)
        )
      );
    }
    if (field === "phone") {
      setIsPhoneValid(
        updatedMembers.every(
          (member) => member.phone && member.phone.length === 10
        )
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const allPhonesValid = parsedMembers.every(
      (member) => member.phone && member.phone.length === 10
    );
    if (!allPhonesValid) {
      setNotification({
        open: true,
        message: "One or more phone numbers are invalid. Must be 10 digits.",
      });
      return;
    }

    const result = await inviteMultipleMembers({
      members: parsedMembers,
      sendEmailToEveryone,
      tierId: preselectedTier ? preselectedTier?._id : selectedTier?._id,
    });

    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        onClose();
      }, 2500);
    } else {
      setNotification({
        open: true,
        message: `Error: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const getFieldError = (field, value) => {
    if (field === "email" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      return "Invalid email address.";
    }
    if (field === "phone" && value.length !== 10) {
      return "Phone number must be 10 digits.";
    }
    return "";
  };

  const handleSendEmailToEveryone = (event) => {
    setSendEmailToEveryone(event.target.checked);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle variant="h5">
        Bulk Loader - {selectedTier?.brokerage?.officeName}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Please paste the CSV content in the text area below. Ensure that the
          first row contains the column headers:
          <br />
          <strong>
            email, isEmailPrivate, emailPublic, firstName, lastName, imageUrl,
            officeExt, bio, website, phone, userRoleNameEnumId
          </strong>
          <br />
          <br />
          Example:
          <br />
          email, isEmailPrivate, emailPublic,
          firstName,lastName,imageUrl,officeExt,bio,website,phone,userRoleNameEnumId
          <br />
          john.doe@example.com,John,Doe,https://example.com/image.jpg,123,Bio
          text,https://example.com,1234567890,roleId123
        </Typography>
        <Autocomplete
          value={selectedUserRole}
          onChange={(event, newValue) => {
            setSelectedUserRole(newValue);
            // Update userRoleNameEnumId for all members when a new role is selected
            setParsedMembers((prevMembers) =>
              prevMembers.map((member) => ({
                ...member,
                userRoleNameEnumId: newValue?._id || "",
              }))
            );
          }}
          options={userRoleNameEnums || []}
          getOptionLabel={(option) => option.userRoleName || ""}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a User Role"
              variant="outlined"
              margin="normal"
              required
            />
          )}
        />
        <TextField
          label="CSV Data"
          variant="outlined"
          margin="normal"
          fullWidth
          multiline
          rows={10}
          value={csvData}
          onChange={handleCsvChange}
          placeholder="email,firstName,lastName,imageUrl,officeExt,bio,website,phone,userRoleNameEnumId"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={sendEmailToEveryone}
              onChange={handleSendEmailToEveryone}
              name="sendEmailToEveryone"
              color="primary"
            />
          }
          label="Send Email To Everyone"
        />
        {!isEmailValid && (
          <Alert severity="error" sx={{ mb: 2 }}>
            One or more email addresses are invalid. Please correct them to
            proceed.
          </Alert>
        )}
        {!isPhoneValid && (
          <Alert severity="error" sx={{ mb: 2 }}>
            One or more phone numbers are invalid. Must be 10 digits.
          </Alert>
        )}
        {parsedMembers.length > 0 && (
          <>
            <Typography variant="h6">
              Accounts to Load: {parsedMembers.length}
            </Typography>
            {parsedMembers.map((member, index) => (
              <div key={index}>
                <Divider variant="fullWidth" />
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={1}>
                    <Typography variant="subtitle1">{index + 1}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <Avatar alt="Member Image" src={member.imageUrl} />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      fullWidth
                      label="First Name"
                      name="firstName"
                      variant="outlined"
                      margin="normal"
                      value={member.firstName}
                      onChange={(e) =>
                        handleMemberChange(index, "firstName", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      name="lastName"
                      variant="outlined"
                      margin="normal"
                      value={member.lastName}
                      onChange={(e) =>
                        handleMemberChange(index, "lastName", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      variant="outlined"
                      margin="normal"
                      value={member.email}
                      onChange={(e) =>
                        handleMemberChange(index, "email", e.target.value)
                      }
                      error={!!getFieldError("email", member.email)}
                      helperText={getFieldError("email", member.email)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      variant="outlined"
                      margin="normal"
                      value={member?.email || ""}
                      onChange={(e) =>
                        handleMemberChange(index, {
                          target: {
                            name: "isEmailPrivate",
                            value: e.target.checked,
                          },
                        })
                      }
                      required
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={member.isEmailPrivate}
                          onChange={(e) =>
                            handleMemberChange(index, {
                              target: {
                                name: "isEmailPrivate",
                                value: e.target.checked,
                              },
                            })
                          }
                          name="isEmailPrivate"
                          color="primary"
                        />
                      }
                      label="Keep Email Private"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {member?.isEmailPrivate && (
                      <TextField
                        fullWidth
                        label="Public Email"
                        name="emailPublic"
                        variant="outlined"
                        margin="normal"
                        value={member?.emailPublic || ""}
                        onChange={(e) => handleMemberChange(index, e)}
                        required
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      fullWidth
                      label="Phone"
                      name="phone"
                      variant="outlined"
                      margin="normal"
                      value={member.phone}
                      onChange={(e) =>
                        handleMemberChange(index, "phone", e.target.value)
                      }
                      error={!!getFieldError("phone", member.phone)}
                      helperText={getFieldError("phone", member.phone)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>
                    <TextField
                      fullWidth
                      label="Office Ext"
                      name="officeExt"
                      variant="outlined"
                      margin="normal"
                      value={member.officeExt}
                      onChange={(e) =>
                        handleMemberChange(index, "officeExt", e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={
            !isEmailValid ||
            !isPhoneValid ||
            isLoading ||
            !selectedUserRole ||
            parsedMembers.length === 0
          }
        >
          {isLoading ? <CircularProgress size={24} /> : "Invite/Signup Members"}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default memo(InviteMembersCSVDialog);

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const teamRoleApi = createApi({
  reducerPath: "team-role",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/team-roles/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["TeamRole"],
  endpoints: (builder) => ({
    createTeamRole: builder.mutation({
      query: ({ teamId, name, permissions }) => ({
        url: "create-one",
        method: "POST",
        body: { teamId, name, permissions },
      }),
      invalidatesTags: [{ type: "TeamRole", id: "LIST" }],
    }),
    getAllTeamRoles: builder.query({
      query: (teamId) => ({
        url: `get-all/${teamId}`,
        method: "GET",
      }),
      providesTags: [{ type: "TeamRole", id: "LIST" }],
    }),
    editTeamRole: builder.mutation({
      query: ({ teamRoleId, name, permissions }) => ({
        url: `edit-one/${teamRoleId}`,
        method: "POST",
        body: { name, permissions },
      }),
      invalidatesTags: [{ type: "TeamRole", id: "LIST" }],
    }),
    assignTeamRole: builder.mutation({
      query: ({ teamId, memberId, teamRoleId }) => ({
        url: "assign-team-role",
        method: "POST",
        body: { teamId, memberId, teamRoleId },
      }),
      invalidatesTags: [{ type: "TeamRole", id: "LIST" }],
    }),
    deleteTeamRole: builder.mutation({
      query: ({ teamRoleId }) => ({
        url: `delete-one/${teamRoleId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "TeamRole", id: "LIST" }],
    }),
    addPermissionToRole: builder.mutation({
      query: ({ teamRoleId, permission }) => ({
        url: `add-permission-to-role`,
        method: "POST",
        body: { teamRoleId, permission },
      }),
      invalidatesTags: [{ type: "TeamRole", id: "LIST" }],
    }),
    removePermissionFromRole: builder.mutation({
      query: ({ teamRoleId, permission }) => ({
        url: `remove-permission-from-role`,
        method: "DELETE",
        body: { teamRoleId, permission },
      }),
      invalidatesTags: [{ type: "TeamRole", id: "LIST" }],
    }),
  }),
});

export const {
  useGetAllTeamRolesQuery,
  useCreateTeamRoleMutation,
  useEditTeamRoleMutation,
  useAssignTeamRoleMutation,
  useDeleteTeamRoleMutation,
  useAddPermissionToRoleMutation,
  useRemovePermissionFromRoleMutation,
} = teamRoleApi;

export default teamRoleApi;
export { teamRoleApi };

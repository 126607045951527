import React, { useState, useEffect } from "react";
import { useGetAllDutyScheduleByTierQuery } from "../../../../services/duty-schedule";
import { Tabs, Tab, Paper, Typography, Box } from "@mui/material";
import { TabPanel } from "../../../helpers/utils";
import DutyScheduleManager from "./DutyScheduleManager";

function DutyScheduleTabs({ tier }) {
  const { data: dutySchedules } = useGetAllDutyScheduleByTierQuery(tier?._id, {
    skip: !tier?._id,
  });
  const [selectDutyScheduleId, setSelectDutyScheduleId] = useState("");
  const [selectDutySchedule, setSelectDutySchedule] = useState(null);

  useEffect(() => {
    if (dutySchedules && dutySchedules.length > 0) {
      const initialScheduleId = dutySchedules[0]._id || "";
      setSelectDutyScheduleId(initialScheduleId);
      setSelectDutySchedule(dutySchedules[0]);
    }
  }, [dutySchedules]);

  const handleTabChange = (event, newValue) => {
    setSelectDutyScheduleId(newValue);
    const selectedSchedule = dutySchedules.find(
      (schedule) => schedule._id === newValue
    );
    setSelectDutySchedule(selectedSchedule);
  };

  if (!dutySchedules || dutySchedules.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 20,
          width: "100%",
          height: "100%",
        }}
      >
        <Typography variant="h5" gutterBottom>
          No Duty Schedules available
        </Typography>
      </Box>
    );
  }

  return (
    <div style={{ height: "100%", width: "100%", minWidth: "960px" }}>
      <Paper elevation={4} sx={{ p: "1rem" }}>
        <Tabs
          value={selectDutyScheduleId || dutySchedules[0]._id}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons
          selectionFollowsFocus
          aria-label="scrollable auto tabs example"
          className="tier-tabs-paper"
          component={"div"}
        >
          {dutySchedules?.map((dutySchedule, i) => {
            return (
              <Tab
                key={dutySchedule?._id}
                label={dutySchedule?.title}
                value={dutySchedule?._id}
                id={`tab-${i}`}
              />
            );
          })}
        </Tabs>
        {dutySchedules?.map((dutySchedule) => {
          return (
            <TabPanel
              key={dutySchedule._id}
              value={selectDutyScheduleId}
              index={dutySchedule._id}
              className="brokerage-tabs-paper"
            >
              {selectDutyScheduleId === dutySchedule._id && (
                <DutyScheduleManager
                  tier={tier}
                  dutySchedule={selectDutySchedule}
                />
              )}
            </TabPanel>
          );
        })}
      </Paper>
    </div>
  );
}

export default DutyScheduleTabs;

import React, { useContext } from "react";
import { useGetAllInvitaionLogsQuery } from "../../../services/invitaion-logs";
import { AuthContext } from "../../../context/AuthContext";
import { Container, Typography, Paper, Divider } from "@mui/material";
import InvitationLogsList from "./InvitationLogsList";

const InvitationLogsManagement = () => {
  const { accountType, userId } = useContext(AuthContext);
  const { data: invitationLogs } = useGetAllInvitaionLogsQuery(accountType, {
    skip: !userId && accountType !== "reosadmin",
  });

  return (
    <Container className="management-page">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Invitation Logs Management
        </Typography>
        <Divider />
      </Paper>
      <InvitationLogsList invitationLogs={invitationLogs} />
    </Container>
  );
};

export default InvitationLogsManagement;

import React, { createContext, useState, useContext } from "react";

export const MemberProfilePageContext = createContext({
  memberProfilePageActive: null,
  setMemberProfilePageActive: () => {},
});

export const useMemberProfilePageContext = () =>
  useContext(MemberProfilePageContext);

export const MemberProfilePageProvider = ({ children }) => {
  const [memberProfilePageActive, setMemberProfilePageActive] = useState(false);

  return (
    <MemberProfilePageContext.Provider
      value={{ memberProfilePageActive, setMemberProfilePageActive }}
    >
      {children}
    </MemberProfilePageContext.Provider>
  );
};

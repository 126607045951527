import React, { useContext } from "react";
import { TierContext } from "../../context/TierContext";
import { Drawer } from "@mui/material";
import MemberProfile from "./MemberProfile";

const MemberProfileDrawer = ({ userId, open, onClose }) => {
  const { currentTierId } = useContext(TierContext);

  if (!userId) return;

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      className="member-drawer"
      PaperProps={{
        style: { width: "30rem", transition: "width 0.8s ease-in-out" },
      }}
    >
      <MemberProfile
        userMemberId={userId}
        isDrawer={true}
        onClose={onClose}
        tierId={currentTierId}
      />
    </Drawer>
  );
};

export default MemberProfileDrawer;

import React, { useState, useContext } from "react";
import { titleCase } from "title-case";
import { AuthContext } from "../../../../context/AuthContext";
import { useGetAllGroupsQuery } from "../../../../services/group";
import {
  useAssignTierToGroupMutation,
  useGetAllTiersQuery,
} from "../../../../services/tier";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import NotificationSnackbar from "../../../helpers/notification-snackbar";

const AssignTierToGroupDialog = ({ open, onClose, preselectedGroup }) => {
  const { userRole } = useContext(AuthContext);
  const { data: tiers } = useGetAllTiersQuery();
  const { data: groups } = useGetAllGroupsQuery();
  const [assignTierToGroup] = useAssignTierToGroupMutation();
  const [selectedTierId, setSelectedTierId] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [inputtiervalue, setInputtiervalue] = useState("");
  const [inputgroupvalue, setInputgroupvalue] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      preselectedGroup &&
      preselectedGroup?.tier &&
      userRole !== "reossuperadmin"
    ) {
      setNotification({
        open: true,
        message: "The Group already has assigned Tier",
      });

      return;
    }
    const result = await assignTierToGroup({
      groupId: preselectedGroup ? preselectedGroup?._id : selectedGroupId?._id,
      tierId: selectedTierId?._id,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        onClose();
        setInputgroupvalue();
        setInputtiervalue();
        setSelectedGroupId(null);
        setSelectedTierId(null);
      }, 2000);
    } else {
      setNotification({
        open: true,
        message: `Error assigning Tier to the Group. ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Assign Tier to Group</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Autocomplete
                value={selectedTierId}
                onChange={(event, newValue) => {
                  setSelectedTierId(newValue);
                }}
                inputValue={inputtiervalue}
                onInputChange={(event, newInputValue) => {
                  setInputtiervalue(newInputValue);
                }}
                options={tiers || []}
                getOptionLabel={(option) =>
                  option
                    ? `${option?.level} ${
                        option?.brokerage || option?.team || option?.group
                          ? option?.brokerage?.user?.firstName ||
                            option?.team?.name ||
                            option?.group?.name
                          : ""
                      } ${
                        option?.brokerage
                          ? option?.brokerage?.user?.lastName
                          : ""
                      } (${
                        option?.brokerage || option?.team || option?.group
                          ? option?.brokerage?.user?.email ||
                            option?.team?.teamUrlName ||
                            option?.group?.groupUrlName
                          : ""
                      })`
                    : ""
                }
                fullWidth
                renderOption={(props, option) => (
                  <li {...props}>
                    <Tooltip
                      title={
                        option?.Group && option?.team?.name
                          ? "This tier has a team assigned"
                          : option?.brokerage && option?.brokerage?.officeName
                          ? "This tier has a brokerage assigned"
                          : option?.group && option?.group?.name
                          ? "This tier has a group assigned"
                          : `This is a ${option?.level} level`
                      }
                      placement="left"
                    >
                      <div>
                        {`${titleCase(option?.level)} - 
                        ${
                          option?.brokerage || option?.team || option?.group
                            ? option?.brokerage?.officeName ||
                              option?.team?.name ||
                              option?.group?.name
                            : ""
                        } 

                       
                      `}
                      </div>
                    </Tooltip>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Tier"
                    variant="outlined"
                    margin="normal"
                    required
                  />
                )}
              />
            </Grid>
            <Grid item>
              {preselectedGroup ? (
                <TextField
                  label="Selected Group"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={`${preselectedGroup?.name} ${preselectedGroup?.groupUrlName}`}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ) : (
                <Autocomplete
                  value={selectedGroupId}
                  onChange={(event, newValue) => {
                    setSelectedGroupId(newValue);
                  }}
                  inputValue={inputgroupvalue}
                  onInputChange={(event, newInputValue) => {
                    setInputgroupvalue(newInputValue);
                  }}
                  options={groups || []}
                  getOptionLabel={(option) =>
                    option
                      ? `${option?.name} ${option?.groupUrlName}${
                          option?.tier ? option?.tier?.level : ""
                        }`
                      : ""
                  }
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select a Group"
                      variant="outlined"
                      margin="normal"
                      required
                    />
                  )}
                />
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Assign Tier to Group
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default AssignTierToGroupDialog;

import React, { useState, memo } from "react";
import { TextField, Button, Grid, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const UnavailablePeriodSelector = ({
  onAddUnavailablePeriod,
  onRemoveUnavailablePeriod,
  unavailablePeriods,
}) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleAddUnavailablePeriod = () => {
    if (startDate && endDate) {
      onAddUnavailablePeriod({ startDate, endDate });
      setStartDate("");
      setEndDate("");
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        Add Unavailable Period to skip
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <DatePicker
              label="Start Date"
              value={startDate ? dayjs(startDate) : null}
              onChange={(newValue) => setStartDate(newValue)}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </Grid>
          <Grid item xs={5}>
            <DatePicker
              label="End Date"
              value={endDate ? dayjs(endDate) : null}
              onChange={(newValue) => setEndDate(newValue)}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddUnavailablePeriod}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
      <ul>
        {unavailablePeriods?.map((period, index) => (
          <li key={index}>
            {`${dayjs(period?.startDate)?.format(
              "dddd, MMMM D, YYYY"
            )} - ${dayjs(period?.endDate)?.format("dddd, MMMM D, YYYY")}`}
            <IconButton
              aria-label="delete"
              onClick={() => onRemoveUnavailablePeriod(index)}
              color="secondary"
            >
              <Delete />
            </IconButton>
          </li>
        ))}
      </ul>
    </>
  );
};

export default memo(UnavailablePeriodSelector);

import React from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function BackButton() {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <IconButton color="primary" aria-label="Back" onClick={handleBack}>
      <ArrowBackIcon />
    </IconButton>
  );
}

export default BackButton;

import React, { useState } from "react";
import { useDeleteRoleMutation } from "../../../services/role";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Divider,
  Box,
  TableSortLabel,
} from "@mui/material";
import {
  MoreVert,
  DeleteForever,
  Edit,
  NavigateNext,
} from "@mui/icons-material/";
import { ConfirmationDialog } from "../..";
import { useNavigate } from "react-router-dom";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import CreateEditRoleForm from "./CreateEditRoleForm";

function RolesList({ roles, refetchRoles }) {
  const navigate = useNavigate();
  const [deleteRole] = useDeleteRoleMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [currentRoleClicked, setCurrentRoleClicked] = useState(null);
  const [currentRole, setCurrentRole] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [sortField, setSortField] = useState("path");
  const [sortDirection, setSortDirection] = useState("asc");
  const [openEditRoleDialog, setOpenEditRoleDialog] = useState(false);
  const [expandedRole, setExpandedRole] = useState(null);

  const handleRowClick = (event, roleId, role) => {
    if (
      event.target.closest(".menu-button") ||
      event.target.closest(".view-details-button")
    ) {
      setCurrentRoleClicked(roleId);
      setCurrentRole(role);
      return;
    }
    // navigate(`/role/${roleId}`);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenEditRoleDialog = (event) => {
    event.stopPropagation();
    setOpenEditRoleDialog(true);
    setAnchorEl(null);
  };

  const handleCloseEditRoleDialog = () => {
    setOpenEditRoleDialog(false);
  };

  const handleDeleteRoleClick = (event, roleId) => {
    event.stopPropagation();
    setCurrentRole({ ...currentRole, _id: roleId });
    setConfirmationDialogOpen(true);
  };
  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleConfirmationDialogConfirm = async () => {
    if (currentRole?._id) {
      const result = await deleteRole({
        roleId: currentRole._id,
      });

      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        refetchRoles?.();
        setConfirmationDialogOpen(false);
      } else {
        setNotification({
          open: true,
          message: `Error deleting Role. ${result?.error?.data?.msg}`,
        });
      }
    }
  };

  const handleTogglePermissions = (roleId) => {
    setExpandedRole((prevExpandedRole) =>
      prevExpandedRole === roleId ? null : roleId
    );
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  if (!roles || roles?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 20,
        }}
      >
        <Typography variant="h5" gutterBottom>
          No roles available
        </Typography>
        <Link to="/category-management">Go to Create role</Link>
      </Box>
    );
  }

  return (
    <>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "user"}
                    direction={sortDirection}
                    onClick={() => handleSort("user")}
                  >
                    User Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "name"}
                    direction={sortDirection}
                    onClick={() => handleSort("name")}
                  >
                    Role Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>Permissions</TableCell>
                <TableCell>Actions </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(roles) &&
                roles?.map((role, index) => (
                  <TableRow
                    key={role?._id}
                    onClick={(event) => handleRowClick(event, role?._id, role)}
                    style={{ cursor: "pointer" }}
                    className="table-row"
                  >
                    <TableCell>
                      {`${role?.user?.firstName || ""} ${
                        role?.user?.lastName || ""
                      }
                     ${role?.user?.email || ""}
                     `}
                    </TableCell>
                    <TableCell>{role?.name}</TableCell>
                    <TableCell>
                      {role?.permissions
                        ?.slice(
                          0,
                          expandedRole === role?._id
                            ? role?.permissions?.length
                            : 2
                        )
                        .map((perm, permIndex) => (
                          <div key={permIndex}>
                            <Typography variant="body2">{`Action: ${perm?.action}`}</Typography>
                            <Typography variant="body2">{`Role: ${
                              perm?.role || "N/A"
                            }`}</Typography>
                            <Typography variant="body2">{`Description: ${
                              perm?.description || "N/A"
                            }`}</Typography>
                            {permIndex < role?.permissions?.length - 1 && (
                              <Divider />
                            )}
                          </div>
                        ))}
                      {role?.permissions?.length > 2 && (
                        <Typography
                          sx={{ cursor: "pointer", color: "primary.main" }}
                          onClick={() => handleTogglePermissions(role?._id)}
                        >
                          {expandedRole === role?._id
                            ? "See Less"
                            : `See More (${role?.permissions?.length - 2})`}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          aria-label="delete"
                          onClick={(event) =>
                            handleDeleteRoleClick(event, role?._id)
                          }
                        >
                          <DeleteForever />
                        </IconButton>
                        <IconButton
                          className="menu-button"
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(event) => handleMenuOpen(event, role?._id)}
                        >
                          <MoreVert />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleMenuClose}
                        >
                          <MenuItem
                            onClick={(event) => {
                              event.stopPropagation();
                              handleOpenEditRoleDialog(event);
                            }}
                          >
                            <Edit />
                            <Typography ml={1}>Edit Role</Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              navigate(`/role/${currentRoleClicked}`)
                            }
                          >
                            <NavigateNext />
                            <Typography
                              variant="body1"
                              className="view-details-button"
                            >
                              View Details
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider style={{ margin: "20px 0" }} />
      </div>
      <CreateEditRoleForm
        mode="edit"
        refetchRoles={refetchRoles}
        role={currentRole}
        open={openEditRoleDialog}
        onClose={handleCloseEditRoleDialog}
      />
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmationDialogConfirm}
        title="Confirm Deleting the Role"
        description="Are you sure you want to delete this Role permanently?"
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
}

export default RolesList;

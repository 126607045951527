import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Typography, Box, IconButton, Tooltip } from "@mui/material";
import { AddBox } from "@mui/icons-material/";
import NotificationSnackbar from "../../../helpers/notification-snackbar";
import CreateGroupDialog from "./CreateGroupDialog";
import GroupsList from "./GroupsList";

const GroupsPage = () => {
  const { teamId } = useParams();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [openCreateGroupialog, setOpenCreateGroupDialog] = useState(false);

  const handleOpenCreateGroupDialog = () => {
    setOpenCreateGroupDialog(true);
  };

  const handleCloseCreateGroupDialog = () => {
    setOpenCreateGroupDialog(false);
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Tooltip title="Create a New Group" placement="left">
          <IconButton
            edge="end"
            aria-label="assign-tier-to-group"
            onClick={handleOpenCreateGroupDialog}
          >
            <AddBox />
            <Typography ml={1}>Create Group</Typography>
          </IconButton>
        </Tooltip>
      </Box>
      <GroupsList teamId={teamId} />
      <CreateGroupDialog
        teamId={teamId}
        open={openCreateGroupialog}
        onClose={handleCloseCreateGroupDialog}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Container>
  );
};

export default GroupsPage;

import React, { useState, useEffect, useContext } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import {
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useGetCategoryTypeEnumQuery,
} from "../../../services/category";
import { useGetAllCategorySettingsQuery } from "../../../services/category-settings";
import { useGetTiersFromUserRoleTiersCollectionQuery } from "../../../services/tier";
import { useGetAllCategoryGroupsByTierQuery } from "../../../services/category-group";
import { AuthContext } from "../../../context/AuthContext";
import {
  Grid,
  Box,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  FormControlLabel,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import categoryIconList from "../../sidebar/categoryIconList";
import { categoryIcon as toCategoryIcon } from "../../sidebar/sidebarHelpers";
import { titleCase } from "title-case";

const CreateEditCategoryForm = ({
  selectedTier,
  open,
  onClose,
  category,
  mode = "edit",
  isLBTab = false,
}) => {
  const { userId, accountType } = useContext(AuthContext);
  const { data: tiers } = useGetTiersFromUserRoleTiersCollectionQuery(userId, {
    skip: !userId,
  });
  const [createCategory, { isLoading: isCreateLoading }] =
    useCreateCategoryMutation();
  const [updateCategory, { isLoading }] = useUpdateCategoryMutation();
  const { data: categorySettings } = useGetAllCategorySettingsQuery();
  const { data: categoryTypes } = useGetCategoryTypeEnumQuery();
  const [tierId, setTierId] = useState("");

  useEffect(() => {
    if (selectedTier || category) {
      setTierId(selectedTier?._id || category?.tier);
    }
  }, [selectedTier, category]);

  const { data: categoryGroups } = useGetAllCategoryGroupsByTierQuery(tierId, {
    skip: !tierId,
  });
  const [selectedTierMirrorTo, setSelectedTierMirrorTo] = useState(null);

  const [categoryGroup, setCategoryGroup] = useState(category?.group || null);
  const [inputcategorygroupvalue, setInputcategorygroupvalue] = useState(
    category?.group ? category.group.name : ""
  );
  const [categoryName, setCategoryName] = useState(category?.name || "");
  const [categoryPath, setCategoryPath] = useState(category?.path || "");
  const [categoryIcon, setCategoryIcon] = useState(category?.icon || "");
  const [categoryType, setCategoryType] = useState(category?.type || "");
  const [order, setOrder] = useState(category?.order || 0);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [iFrameURL, setIFrameURL] = useState(category?.iFrameURL || "");
  const [isWidgetable, setIsWidgetable] = useState(false);
  const [isEvent, setIsEvent] = useState(false);
  const [isMemberList, setIsMemberList] = useState(false);
  const [isHomePage, setIsHomePage] = useState(false);
  const [isChat, setIsChat] = useState(false);
  const [isListings, setIsListings] = useState(false);
  const [isFileSystem, setIsFileSystem] = useState(false);
  const [allowTags, setAllowTags] = useState(false);
  const [useWYSIWYG, setUseWYSIWYG] = useState(false);
  const [hasFeaturePicture, setHasFeaturePicture] = useState(false);
  const [allowAcknowledgements, setAllowAcknowledgements] = useState(false);
  const [allowAttachments, setAllowAttachments] = useState(false);
  const [allowComments, setAllowComments] = useState(false);
  const [allowLikes, setAllowLikes] = useState(false);
  const [categoryDescription, setCategoryDescription] = useState(
    category?.description || ""
  );

  useEffect(() => {
    if (category) {
      setCategoryName(category?.name || "");
      setCategoryPath(category?.path || "");
      setCategoryIcon(category?.icon || "");
      setCategoryType(category?.type || "");
      setCategoryDescription(category?.description || "");
      setCategoryGroup(category?.group || "");
      setOrder(category?.order || 0);
      setIFrameURL(category?.iFrameURL || "");
      setIsWidgetable(category?.isWidgetable || false);
      setIsEvent(category?.isEvent || false);
      setIsMemberList(category?.isMemberList || false);
      setIsHomePage(category?.isHomePage || false);
      setIsChat(category?.isChat || false);
      setIsFileSystem(category?.isFileSystem || false);
      setIsListings(category?.isListings || false);
      setAllowTags(category?.allowTags || false);
      setUseWYSIWYG(category?.useWYSIWYG || false);
      setHasFeaturePicture(category?.hasFeaturePicture || false);
      setAllowAcknowledgements(category?.allowAcknowledgements || false);
      setAllowAttachments(category?.allowAttachments || false);
      setAllowComments(category?.allowComments || false);
      setAllowLikes(category?.allowLikes || false);
    }
  }, [category]);

  useEffect(() => {
    if (categoryType && categorySettings) {
      const settings = categorySettings.find(
        (setting) => setting.categoryType === categoryType
      );
      if (settings) {
        setIsWidgetable(settings.isWidgetable?.is ?? false);
        setIsEvent(settings.isEvent?.is ?? false);
        setIsMemberList(settings.isMemberList?.is ?? false);
        setIsHomePage(settings.isHomePage?.is ?? false);
        setIsChat(settings.isChat?.is ?? false);
        setIsFileSystem(settings.isFileSystem?.is ?? false);
        setIsListings(settings.isListings?.is ?? false);
        setAllowTags(settings.allowTags?.is ?? false);
        setUseWYSIWYG(settings.useWYSIWYG?.is ?? false);
        setHasFeaturePicture(settings.hasFeaturePicture?.is ?? false);
        setAllowAcknowledgements(settings.allowAcknowledgements?.is ?? false);
        setAllowAttachments(settings.allowAttachments?.is ?? false);
        setAllowComments(settings.allowComments?.is ?? false);
        setAllowLikes(settings.allowLikes?.is ?? false);
      }
    }
  }, [categoryType, categorySettings]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      (categoryName && !categoryName.trim()) ||
      (categoryIcon && !categoryIcon.trim()) ||
      (categoryType && !categoryType.trim())
    ) {
      setNotification({
        open: true,
        message: "Please fill in all required fields.",
      });
      return;
    }
    let result;
    const categoryData = {
      userId,
      tierId: selectedTier?._id,
      tierMirrorToId: selectedTierMirrorTo?._id,
      categoryName,
      categoryPath,
      categoryIcon,
      categoryType,
      categoryDescription,
      categoryGroupId: categoryGroup?._id,
      order,
      // Additional options
      isWidgetable,
      isEvent,
      isMemberList,
      isHomePage,
      isChat,
      isFileSystem,
      isListings,
      allowTags,
      useWYSIWYG,
      hasFeaturePicture,
      allowAcknowledgements,
      allowAttachments,
      allowComments,
      allowLikes,
    };

    if (categoryType === "iframe") {
      categoryData.iFrameURL = iFrameURL;
    }

    if (mode === "create") {
      result = await createCategory(categoryData);
    } else if (mode === "edit") {
      result = await updateCategory({
        categoryId: category?._id,
        ...categoryData,
      });
    }

    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        if (mode === "create") {
          setCategoryName("");
          setCategoryPath("");
          setCategoryIcon("");
          setCategoryType("");
          setCategoryGroup("");
          setCategoryDescription("");
          setOrder(0);
          setIFrameURL("");
          setIsWidgetable(false);
          setIsEvent(false);
          setIsMemberList(false);
          setIsHomePage(false);
          setIsChat(false);
          setIsListings(false);
          setIsFileSystem(false);
          setAllowTags(false);
          setUseWYSIWYG(false);
          setHasFeaturePicture(false);
          setAllowAcknowledgements(false);
          setAllowAttachments(false);
          setAllowComments(false);
          setAllowLikes(false);
          if (selectedTierMirrorTo) {
            setSelectedTierMirrorTo(null);
          }
        }
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error saving category: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleCategoryNameChange = (event) => {
    const newCategoryName = event.target.value;

    const sanitizedCategoryName = newCategoryName.replace(
      /[^a-zA-Z0-9\s-]/g,
      ""
    );

    setCategoryName(sanitizedCategoryName);

    const formattedPath = sanitizedCategoryName
      .toLowerCase()
      .replace(/\s+/g, "-")
      .trim();
    setCategoryPath(formattedPath);
  };

  const isInvalidCategoryName = /[^a-zA-Z0-9\s-]/g.test(categoryName);

  return (
    <Dialog open={open} onClose={onClose} fullWidth className="edit-post">
      <DialogTitle>
        <Typography sx={{ fontWeight: 700, fontSize: "1.5rem" }}>
          {mode === "create" ? "Create New Category" : "Edit Category"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid item>
            <TextField
              placeholder="Category Name"
              name="name"
              label="Category Name"
              value={categoryName}
              onChange={handleCategoryNameChange}
              fullWidth
              required
              margin="normal"
              helperText={
                isInvalidCategoryName ? "Invalid characters used." : ""
              }
              error={isInvalidCategoryName}
            />
          </Grid>
          {isLBTab && mode === "create" && (
            <Grid item>
              <Autocomplete
                value={selectedTierMirrorTo}
                onChange={(event, newValue) => {
                  setSelectedTierMirrorTo(newValue);
                }}
                options={tiers || []}
                getOptionLabel={(option) =>
                  option
                    ? `${titleCase(option?.level)}${
                        option?.brokerage && option?.brokerage?.officeName
                          ? ` - ${option?.brokerage?.officeName}`
                          : ""
                      } ${
                        option?.level === "system" || option?.level === "global"
                          ? " - REACirc"
                          : ""
                      } ${
                        option?._id === option?.brokerage?.leadBrokerageTier
                          ? " - (Lead Brokerage Office)"
                          : ""
                      } ${
                        option?._id === option?.brokerage?.tier
                          ? " - (Office)"
                          : ""
                      }`
                    : ""
                }
                isOptionEqualToValue={(option, value) =>
                  option?._id === value._id
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select the Office Mirror To"
                    variant="outlined"
                    margin="normal"
                    required
                  />
                )}
              />
            </Grid>
          )}
          <Grid item>
            <FormControl fullWidth margin="normal">
              <InputLabel id="cat-icon-level-label">Category Icon</InputLabel>
              <Select
                labelId="cat-icon-level-label"
                id="catIconId"
                label="Category Icon"
                value={categoryIcon}
                onChange={(e) => setCategoryIcon(e.target.value)}
                fullWidth
                required
              >
                {categoryIconList &&
                  categoryIconList?.map((catIcon, i) => (
                    <MenuItem key={`${catIcon}-${i}`} value={catIcon}>
                      <Grid container alignItems="center">
                        <Grid item>{toCategoryIcon(catIcon)}</Grid>
                        <Grid item sx={{ ml: 2 }}>
                          {catIcon}
                        </Grid>
                      </Grid>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth margin="normal">
              <InputLabel id="tier-level-label">Category Type</InputLabel>
              <Select
                labelId="cat-type-level-label"
                id="catTypeId"
                label="Category Type"
                value={categoryType}
                onChange={(e) => setCategoryType(e.target.value)}
                fullWidth
                required
              >
                {categoryTypes &&
                  categoryTypes
                    ?.filter(
                      (catType) =>
                        accountType === "reosadmin" || catType !== "common"
                    )
                    ?.sort((a, b) => a.localeCompare(b)) // Sort in ascending order
                    ?.map((catType, i) => (
                      <MenuItem key={`${catType}-${i}`} value={catType}>
                        {catType}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>
          {categoryType === "iframe" && (
            <Grid item>
              <TextField
                placeholder="iFrame URL"
                name="iFrameURL"
                label="iFrame URL"
                value={iFrameURL}
                onChange={(event) => {
                  setIFrameURL(event.target.value);
                }}
                fullWidth
                margin="normal"
              />
            </Grid>
          )}
          <Grid item>
            <Autocomplete
              value={categoryGroup}
              onChange={(event, newValue) => {
                setCategoryGroup(newValue);
              }}
              inputValue={inputcategorygroupvalue}
              onInputChange={(event, newInputValue) => {
                setInputcategorygroupvalue(newInputValue);
              }}
              options={categoryGroups || []}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              getOptionLabel={(option) => (option ? `${option?.name}` : "")}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a Group"
                  variant="outlined"
                  margin="normal"
                  required
                />
              )}
            />
          </Grid>
          <Grid item>
            <TextField
              placeholder="Order"
              name="order"
              label="Order"
              type="number"
              value={order}
              onChange={(event) => {
                setOrder(event.target.value);
              }}
              fullWidth
              required
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: 7 }}>
            <Typography variant="subtitle1" sx={{ mt: 1, mb: 1 }}>
              Category Description
            </Typography>
            <ReactQuill
              value={categoryDescription}
              onChange={setCategoryDescription}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link", "image"],
                  ["clean"],
                ],
              }}
              formats={[
                "header",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
              ]}
              style={{ height: "200px", marginBottom: "2rem" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box border={1} borderColor="grey.400" borderRadius={4} p={2}>
              <Typography
                sx={{
                  fontWeight: 700,
                  color: "var(--text-color)",
                  bgcolor: "var(--bg-color)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                variant="subtitle"
              >
                General Category Type
              </Typography>
              <Grid container spacing={2}>
                {/* "Is" Checkboxes Row */}
                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isWidgetable}
                            onChange={(event) =>
                              setIsWidgetable(event.target.checked)
                            }
                            name="isWidgetable"
                            color="primary"
                            disabled={
                              categorySettings &&
                              (categorySettings?.find(
                                (setting) =>
                                  setting &&
                                  setting?.categoryType === categoryType
                              )?.isWidgetable?.is ||
                                categorySettings?.find(
                                  (setting) =>
                                    setting &&
                                    setting?.categoryType === categoryType
                                )?.isWidgetable?.isNot)
                            }
                          />
                        }
                        label="Is Widgetable"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isChat}
                            onChange={(event) =>
                              setIsChat(event.target.checked)
                            }
                            name="isChat"
                            color="primary"
                            disabled={
                              categorySettings &&
                              (categorySettings?.find(
                                (setting) =>
                                  setting &&
                                  setting?.categoryType === categoryType
                              )?.isChat?.is ||
                                categorySettings?.find(
                                  (setting) =>
                                    setting &&
                                    setting?.categoryType === categoryType
                                )?.isChat?.isNot)
                            }
                          />
                        }
                        label="Is Chat"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isEvent}
                            onChange={(event) =>
                              setIsEvent(event.target.checked)
                            }
                            name="isEvent"
                            color="primary"
                            disabled={
                              categorySettings &&
                              (categorySettings?.find(
                                (setting) =>
                                  setting &&
                                  setting?.categoryType === categoryType
                              )?.isEvent?.is ||
                                categorySettings?.find(
                                  (setting) =>
                                    setting &&
                                    setting?.categoryType === categoryType
                                )?.isEvent?.isNot)
                            }
                          />
                        }
                        label="Is Event"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isFileSystem}
                            onChange={(event) =>
                              setIsFileSystem(event.target.checked)
                            }
                            name="isFileSystem"
                            color="primary"
                            disabled={
                              categorySettings &&
                              (categorySettings?.find(
                                (setting) =>
                                  setting &&
                                  setting?.categoryType === categoryType
                              )?.isFileSystem?.is ||
                                categorySettings?.find(
                                  (setting) =>
                                    setting &&
                                    setting?.categoryType === categoryType
                                )?.isFileSystem?.isNot)
                            }
                          />
                        }
                        label="Is File System"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isMemberList}
                            onChange={(event) =>
                              setIsMemberList(event.target.checked)
                            }
                            name="isMemberList"
                            color="primary"
                            disabled={
                              categorySettings &&
                              (categorySettings?.find(
                                (setting) =>
                                  setting &&
                                  setting?.categoryType === categoryType
                              )?.isMemberList?.is ||
                                categorySettings?.find(
                                  (setting) =>
                                    setting &&
                                    setting?.categoryType === categoryType
                                )?.isMemberList?.isNot)
                            }
                          />
                        }
                        label="Is Member List"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isListings}
                            onChange={(event) =>
                              setIsListings(event.target.checked)
                            }
                            name="isListings"
                            color="primary"
                            disabled={
                              categorySettings &&
                              (categorySettings?.find(
                                (setting) =>
                                  setting &&
                                  setting?.categoryType === categoryType
                              )?.isListings?.is ||
                                categorySettings?.find(
                                  (setting) =>
                                    setting &&
                                    setting?.categoryType === categoryType
                                )?.isListings?.isNot)
                            }
                          />
                        }
                        label="Is Listings"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isHomePage}
                            onChange={(event) =>
                              setIsHomePage(event.target.checked)
                            }
                            name="isHomePage"
                            color="primary"
                            disabled={
                              categorySettings &&
                              (categorySettings?.find(
                                (setting) =>
                                  setting &&
                                  setting?.categoryType === categoryType
                              )?.isHomePage?.is ||
                                categorySettings?.find(
                                  (setting) =>
                                    setting &&
                                    setting?.categoryType === categoryType
                                )?.isHomePage?.isNot)
                            }
                          />
                        }
                        label="Is HomePage"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* Category Options */}
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: "var(--text-color)",
                      bgcolor: "var(--bg-color)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    variant="subtitle"
                  >
                    Category Options
                  </Typography>
                  <Grid container spacing={2}>
                    {/* First Column */}
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allowTags}
                            onChange={(event) =>
                              setAllowTags(event.target.checked)
                            }
                            name="allowTags"
                            color="primary"
                            disabled={
                              categorySettings &&
                              (categorySettings?.find(
                                (setting) =>
                                  setting &&
                                  setting?.categoryType === categoryType
                              )?.allowTags?.is ||
                                categorySettings?.find(
                                  (setting) =>
                                    setting &&
                                    setting?.categoryType === categoryType
                                )?.allowTags?.isNot)
                            }
                          />
                        }
                        label="Allow Tags"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={useWYSIWYG}
                            onChange={(event) =>
                              setUseWYSIWYG(event.target.checked)
                            }
                            name="useWYSIWYG"
                            color="primary"
                            disabled={
                              categorySettings &&
                              (categorySettings?.find(
                                (setting) =>
                                  setting &&
                                  setting?.categoryType === categoryType
                              )?.useWYSIWYG?.is ||
                                categorySettings?.find(
                                  (setting) =>
                                    setting &&
                                    setting?.categoryType === categoryType
                                )?.useWYSIWYG?.isNot)
                            }
                          />
                        }
                        label="Use WYSIWYG Editor"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={hasFeaturePicture}
                            onChange={(event) =>
                              setHasFeaturePicture(event.target.checked)
                            }
                            name="hasFeaturePicture"
                            color="primary"
                            disabled={
                              categorySettings &&
                              (categorySettings?.find(
                                (setting) =>
                                  setting &&
                                  setting?.categoryType === categoryType
                              )?.hasFeaturePicture?.is ||
                                categorySettings?.find(
                                  (setting) =>
                                    setting &&
                                    setting?.categoryType === categoryType
                                )?.hasFeaturePicture?.isNot)
                            }
                          />
                        }
                        label="Has Feature Picture"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allowAcknowledgements}
                            onChange={(event) =>
                              setAllowAcknowledgements(event.target.checked)
                            }
                            name="allowAcknowledgements"
                            color="primary"
                            disabled={
                              categorySettings &&
                              (categorySettings?.find(
                                (setting) =>
                                  setting &&
                                  setting?.categoryType === categoryType
                              )?.allowAcknowledgements?.is ||
                                categorySettings?.find(
                                  (setting) =>
                                    setting &&
                                    setting?.categoryType === categoryType
                                )?.allowAcknowledgements?.isNot)
                            }
                          />
                        }
                        label="Allow Acknowledgements"
                      />
                    </Grid>

                    {/* Second Column */}
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allowAttachments}
                            onChange={(event) =>
                              setAllowAttachments(event.target.checked)
                            }
                            name="allowAttachments"
                            color="primary"
                            disabled={
                              categorySettings &&
                              (categorySettings?.find(
                                (setting) =>
                                  setting &&
                                  setting?.categoryType === categoryType
                              )?.allowAttachments?.is ||
                                categorySettings?.find(
                                  (setting) =>
                                    setting &&
                                    setting?.categoryType === categoryType
                                )?.allowAttachments?.isNot)
                            }
                          />
                        }
                        label="Allow Attachments"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allowComments}
                            onChange={(event) =>
                              setAllowComments(event.target.checked)
                            }
                            name="allowComments"
                            color="primary"
                            disabled={
                              categorySettings &&
                              (categorySettings?.find(
                                (setting) =>
                                  setting &&
                                  setting?.categoryType === categoryType
                              )?.allowComments?.is ||
                                categorySettings?.find(
                                  (setting) =>
                                    setting &&
                                    setting?.categoryType === categoryType
                                )?.allowComments?.isNot)
                            }
                          />
                        }
                        label="Allow Comments"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allowLikes}
                            onChange={(event) =>
                              setAllowLikes(event.target.checked)
                            }
                            name="allowLikes"
                            color="primary"
                            disabled={
                              categorySettings &&
                              (categorySettings?.find(
                                (setting) =>
                                  setting &&
                                  setting?.categoryType === categoryType
                              )?.allowLikes?.is ||
                                categorySettings?.find(
                                  (setting) =>
                                    setting &&
                                    setting?.categoryType === categoryType
                                )?.allowLikes?.isNot)
                            }
                          />
                        }
                        label="Allow Likes"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid item>
          <Button onClick={onClose} color="secondary" type="submit" fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={
              mode === "create"
                ? isCreateLoading
                : mode === "edit"
                ? isLoading
                : isLoading
            }
          >
            {isLoading ? (
              <CircularProgress size={24} /> ? (
                mode === "create"
              ) : (
                "Create"
              )
            ) : (
              "Save"
            )}
          </Button>
        </Grid>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default CreateEditCategoryForm;

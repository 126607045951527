import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const authApi = createApi({
  reducerPath: "auth",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/auth/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Auth"],
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (credentials) => ({
        url: "login",
        method: "POST",
        body: credentials,
      }),
    }),
    signupUser: builder.mutation({
      query: (userDetails) => ({
        url: "reosadmin-signup",
        method: "POST",
        body: userDetails,
      }),
    }),
    loginUserWithToken: builder.mutation({
      query: ({ token, linkToGo }) => ({
        url: "login-via-token",
        method: "POST",
        body: { token, linkToGo },
      }),
    }),
  }),
});

export const {
  useLoginUserMutation,
  useSignupUserMutation,
  useLoginUserWithTokenMutation,
  endpoints: { loginUser },
} = authApi;

export default authApi;

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  useCreateTransactionMutation,
  useUpdateTransactionMutation,
} from "../../../../services/billing/transactions";
import { useFetchInvoicesQuery } from "../../../../services/billing/invoices";

const generateConfirmationNumber = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const dateStr = `${year}${month}${day}`;
  const randomNum = String(Math.floor(Math.random() * 10000)).padStart(4, "0");
  return `CONF-${dateStr}-${randomNum}`;
};

const TransactionForm = ({ open, onClose, transaction }) => {
  const [invoice, setInvoice] = useState("");
  const [amount, setAmount] = useState("");
  const [transactionDate, setTransactionDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [paymentStatus, setPaymentStatus] = useState("pending");
  const [paymentMethod, setPaymentMethod] = useState("credit_card");
  const [confirmationNumber, setConfirmationNumber] = useState(
    generateConfirmationNumber()
  );
  const [currency] = useState("CAD");
  const [notes, setNotes] = useState("");

  const { data: invoices = [] } = useFetchInvoicesQuery();

  const [createTransaction] = useCreateTransactionMutation();
  const [updateTransaction] = useUpdateTransactionMutation();

  useEffect(() => {
    if (transaction) {
      setInvoice(transaction.invoice || ""); // Add invoice handling
      setAmount(transaction.amount || "");
      setTransactionDate(
        transaction.transactionDate
          ? new Date(transaction.transactionDate).toISOString().split("T")[0]
          : new Date().toISOString().split("T")[0]
      );
      setPaymentStatus(transaction.paymentStatus || "pending");
      setPaymentMethod(transaction.paymentMethod || "credit_card");
      setConfirmationNumber(
        transaction.confirmationNumber || generateConfirmationNumber()
      );
      setNotes(transaction.notes || "");
    }
  }, [transaction]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const transactionData = {
        // invoice, // Include invoice in transaction data
        amount,
        transactionDate,
        paymentStatus,
        paymentMethod,
        confirmationNumber,
        currency,
        notes,
      };

      if (transaction) {
        await updateTransaction({
          ...transaction,
          ...transactionData,
        }).unwrap();
      } else {
        await createTransaction(transactionData).unwrap();
      }
      onClose();
    } catch (error) {
      // Handle error
      console.error("Transaction submission error:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {transaction ? "Edit Transaction" : "New Transaction"}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel>Invoice (Optional)</InputLabel>
          <Select
            value={invoice}
            onChange={(e) => setInvoice(e.target.value)}
            label="Invoice (Optional)"
          >
            <MenuItem value="">None</MenuItem>
            {invoices.map((inv) => (
              <MenuItem key={inv.id} value={inv.id}>
                {inv.invoiceNumber}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Amount"
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Transaction Date"
          type="date"
          value={transactionDate}
          onChange={(e) => setTransactionDate(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Payment Status</InputLabel>
          <Select
            value={paymentStatus}
            onChange={(e) => setPaymentStatus(e.target.value)}
            label="Payment Status"
          >
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="paid">Paid</MenuItem>
            <MenuItem value="failed">Failed</MenuItem>
            <MenuItem value="refunded">Refunded</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>Payment Method</InputLabel>
          <Select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            label="Payment Method"
          >
            <MenuItem value="credit_card">Credit Card</MenuItem>
            <MenuItem value="paypal">PayPal</MenuItem>
            <MenuItem value="bank_transfer">Bank Transfer</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Confirmation Number"
          value={confirmationNumber}
          onChange={(e) => setConfirmationNumber(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {transaction ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransactionForm;

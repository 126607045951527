import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const userRoleNameEnumsApi = createApi({
  reducerPath: "user-role-name-enums",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/user-role-name-enums/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["UserRoleNameEnums"],
  endpoints: (builder) => ({
    createUserRoleNameEnum: builder.mutation({
      query: ({ userRoleName }) => ({
        url: `create-one`,
        method: "POST",
        body: { userRoleName },
      }),
      skip: (accountType) => accountType !== "reosadmin",
      invalidatesTags: [{ type: "UserRoleNameEnums", id: "LIST" }],
    }),
    getAllUserRoleNameEnums: builder.query({
      query: () => {
        return {
          url: `get-all`,
          method: "GET",
        };
      },
      providesTags: [{ type: "UserRoleNameEnums", id: "LIST" }],
    }),
    getUserRoleNameEnumById: builder.query({
      query: (userRoleNameEnumId) => ({
        url: `get-one-by-id/${userRoleNameEnumId}`,
        method: "GET",
      }),
      providesTags: (result, error, userRoleNameEnumId) => [
        { type: "UserRoleNameEnums", id: userRoleNameEnumId },
      ],
    }),
    updateUserRoleNameEnum: builder.mutation({
      query: ({ userRoleNameEnumId, userRoleName }) => ({
        url: `update/${userRoleNameEnumId}`,
        method: "PUT",
        body: { userRoleName },
      }),
      invalidatesTags: [{ type: "UserRoleNameEnums", id: "LIST" }],
    }),
    deleteUserRoleNameEnum: builder.mutation({
      query: ({ userRoleNameEnumId }) => ({
        url: `delete-one/${userRoleNameEnumId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "UserRoleNameEnums", id: "LIST" }],
    }),
  }),
});

export const {
  useCreateUserRoleNameEnumMutation,
  useGetAllUserRoleNameEnumsQuery,
  useGetUserRoleNameEnumByIdQuery,
  useUpdateUserRoleNameEnumMutation,
  useDeleteUserRoleNameEnumMutation,
} = userRoleNameEnumsApi;

export default userRoleNameEnumsApi;
export { userRoleNameEnumsApi };

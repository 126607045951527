import React, { useState, useContext } from "react";
import { useGetAllTiersQuery } from "../../../services/tier";
import { AuthContext } from "../../../context/AuthContext";
import {
  Container,
  Typography,
  Paper,
  Tabs,
  Tab,
  List,
  ListItem,
  Divider,
  Grid,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import TiersList from "./TiersList";
import TierPermissionsList from "./TierPermissionsList";
import MembersToManageTierList from "./MembersToManageTierList";
import CreateEditTierForm from "./CreateEditTierForm";
import AssignTierToRoleDialog from "./AssignTierToRoleDialog";
import AssignTierToBrokerageDialog from "./AssignTierToBrokerageDialog";
import { TabPanel } from "../../helpers/utils";

const TierManagement = () => {
  const { accountType, userId } = useContext(AuthContext);
  const { data: tiers, refetch } = useGetAllTiersQuery(accountType, {
    skip: !userId && accountType !== "reosadmin",
  });
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [openCreateTierDialog, setOpenCreateTierDialog] = useState(false);
  const [openAssignTierToRoleDialog, setOpenAssignTierToRoleDialog] =
    useState(false);

  const [openAssignTierToBrokerageDialog, setOpenAssignTierToBrokerageDialog] =
    useState(false);

  const handleOpenCreateTierDialog = () => {
    setOpenCreateTierDialog(true);
  };

  const handleCloseCreateTierDialog = () => {
    setOpenCreateTierDialog(false);
  };

  const handleOpenAssignTierToRoleDialog = () => {
    setOpenAssignTierToRoleDialog(true);
  };

  const handleCloseAssignTierToRoleDialog = () => {
    setOpenAssignTierToRoleDialog(false);
  };

  const handleOpenAssignTierToBrokerageDialog = () => {
    setOpenAssignTierToBrokerageDialog(true);
  };

  const handleCloseAssignTierToBrokerageDialog = () => {
    setOpenAssignTierToBrokerageDialog(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (accountType !== "reosadmin") {
    return (
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        You are not allowed for this page
      </Typography>
    );
  }

  return (
    <Container className="management-page">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Tier Management
        </Typography>
        <Divider />
        <Grid container>
          <Grid item xs={6} sx={{ p: 2 }}>
            <List style={{ width: "100%", marginRight: "0.1rem" }}>
              <ListItem
                onClick={handleOpenCreateTierDialog}
                className="user-management-item"
              >
                Create new Tier
              </ListItem>
              <Divider />
              <ListItem
                onClick={handleOpenAssignTierToRoleDialog}
                className="user-management-item"
              >
                Assign Tier To Role
              </ListItem>
              <Divider />
              <ListItem
                onClick={handleOpenAssignTierToBrokerageDialog}
                className="user-management-item"
              >
                Assign Tier To Brokerage
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <CreateEditTierForm
          mode="create"
          open={openCreateTierDialog}
          onClose={handleCloseCreateTierDialog}
        />
        <AssignTierToRoleDialog
          open={openAssignTierToRoleDialog}
          onClose={handleCloseAssignTierToRoleDialog}
        />
        <AssignTierToBrokerageDialog
          open={openAssignTierToBrokerageDialog}
          onClose={handleCloseAssignTierToBrokerageDialog}
        />
        <NotificationSnackbar
          open={notification.open}
          message={notification.message}
          onClose={handleClose}
        />
      </Paper>
      <Paper>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="roles tabs"
          variant="scrollable"
          scrollButtons="auto"
          className="tabs-paper"
        >
          <Tab
            label="Tiers List"
            id="simple-tab-0"
            aria-controls="simple-tabpanel-0"
          />
          <Tab
            label="Members Tier Management"
            id="simple-tab-1"
            aria-controls="simple-tabpanel-1"
          />
          <Tab
            label="Tier Permisison List"
            id="simple-tab-1"
            aria-controls="simple-tabpanel-1"
          />
        </Tabs>
        <TabPanel value={tabValue} index={0} className="tabs-paper">
          <TiersList tiers={tiers} refetchTiers={refetch} />
        </TabPanel>
        <TabPanel value={tabValue} index={1} className="tabs-paper">
          <MembersToManageTierList tiers={tiers} />
        </TabPanel>
        <TabPanel value={tabValue} index={2} className="tabs-paper">
          <TierPermissionsList />
        </TabPanel>
      </Paper>
    </Container>
  );
};

export default TierManagement;

import React, { useState, useContext, memo, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useGetBrokerageProfileByIdQuery } from "../../../services/brokerage";
import {
  useGetAllBrokerageLevelTiersQuery,
  useGetAllBrokerageLevelRelatedToUserTiersQuery,
} from "../../../services/tier";
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import BrokerageCard from "./BrokerageCard";
import BrokerageTabs from "./BrokerageTabs";
import TierPosts from "../posts/TierPosts";

const BrokerageEcosystemManagement = () => {
  const { userId, userRole, accountType } = useContext(AuthContext);
  const isAdminRole = [
    "reosadmin",
    "brokeragesuperadmin",
    "reossuperadmin",
  ].includes(userRole);
  const isREOSAdminRole = ["reosadmin", "reossuperadmin"].includes(userRole);
  const isAdmin = [
    "reosadmin",
    "reossuperadmin",
    "brokerage",
    "brokerageadmin",
    "brokeragesuperadmin",
  ].includes(userRole);

  const [selectedBrokerageId, setSelectedBrokerageId] = useState("");
  const { data: brokerage, refetch } = useGetBrokerageProfileByIdQuery(
    selectedBrokerageId,
    {
      skip: !selectedBrokerageId,
    }
  );

  const [selectedTier, setSelectedTier] = useState(null);
  const [, setInputtiervalue] = useState("");
  const [showBrokerageDetails, setshowBrokerageDetails] = useState(false);
  const [showTierPosts, setShowTierPosts] = useState(false);

  const { data: tiers, refetch: refetchTiers } =
    useGetAllBrokerageLevelTiersQuery(
      { accountType, filteredByBrokerage: "brokerage" },
      {
        skip: !userId && !isAdminRole,
      }
    );

  const { data: relatedToUserTiers, refetch: refetchrelatedToUserTiers } =
    useGetAllBrokerageLevelRelatedToUserTiersQuery(
      { accountType },
      {
        skip: !userId && !isAdminRole,
      }
    );

  useEffect(() => {
    const initialTier = localStorage.getItem("selectedTier") || "";
    const storedTier = tiers?.find((tier) => tier._id === initialTier);
    if (storedTier) {
      setSelectedTier(storedTier);
      setInputtiervalue(storedTier.brokerage.officeName);
      setSelectedBrokerageId(storedTier.brokerage._id);
    }
  }, [tiers]);

  const handleSelectTierChange = (event, newValue) => {
    setSelectedTier(newValue);
    setInputtiervalue(newValue ? newValue?.brokerage?.officeName : "");
    localStorage.setItem("selectedTier", newValue ? newValue?._id : "");
    setSelectedBrokerageId(newValue ? newValue?.brokerage?._id : null);
  };

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (brokerage) {
      isREOSAdminRole ? refetchTiers() : refetchrelatedToUserTiers();
    }
  }, [brokerage, isREOSAdminRole, refetchTiers, refetchrelatedToUserTiers]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const toggleVisibility = () => {
    setshowBrokerageDetails(!showBrokerageDetails);
  };

  if (
    (accountType !== "brokerage" && accountType !== "reosadmin") ||
    !isAdminRole
  ) {
    return (
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        You are not allowed for this page
      </Typography>
    );
  }
  const toggleTierPosts = () => {
    setShowTierPosts(!showTierPosts);
  };

  return (
    <div className="brokerage-page">
      {(isREOSAdminRole || isAdmin) && (
        <Grid item>
          <Autocomplete
            value={selectedTier}
            onChange={handleSelectTierChange}
            options={isREOSAdminRole ? tiers || [] : relatedToUserTiers || []}
            getOptionLabel={(option) => {
              if (!option) return "";
              const { brokerage } = option;
              if (!brokerage) return "No brokerage defined";
              let label = brokerage?.officeName;
              if (
                brokerage?.leadBrokerageTier &&
                brokerage?.leadBrokerageTier === option?._id
              ) {
                label += " (All Offices)";
              } else if (brokerage?.tier && brokerage?.tier === option?._id) {
                label += " (Brokerage)";
              }

              return label;
            }}
            isOptionEqualToValue={(option, value) => option?._id === value._id}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Brokerage"
                variant="outlined"
                margin="normal"
                required
              />
            )}
          />
        </Grid>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {(accountType === "brokerage" || isAdminRole) && (
          <>
            <Box
              display="flex"
              flexDirection="row" // or "row" based on your layout
              gap={2} // Adds a gap between items
              m={2}
            >
              <Button
                variant="outlined"
                color={showBrokerageDetails ? "primary" : "secondary"}
                onClick={toggleVisibility}
              >
                {showBrokerageDetails
                  ? "Hide Brokerage Details"
                  : "Show Brokerage Details"}
              </Button>
              <Button
                variant="outlined"
                color={showTierPosts ? "primary" : "secondary"}
                onClick={toggleTierPosts}
              >
                {showTierPosts ? "Hide Tier Posts" : "Show Tier Posts"}
              </Button>
            </Box>

            {showBrokerageDetails && (
              <BrokerageCard refetchBrokerage={refetch} brokerage={brokerage} />
            )}
          </>
        )}
      </div>
      {selectedTier && (
        <BrokerageTabs
          selectedTier={
            (accountType === "reosadmin" || isAdminRole) &&
            (selectedTier?._id === brokerage?.tier?._id
              ? brokerage?.tier
              : selectedTier?._id === brokerage?.leadBrokerageTier?._id
              ? brokerage?.leadBrokerageTier
              : selectedTier)
          }
          brokerageId={
            (accountType === "reosadmin" || isAdminRole) && brokerage?._id
          }
          brokerageName={
            (accountType === "reosadmin" || isAdminRole) &&
            brokerage?.officeName
          }
          brokerage={brokerage}
        />
      )}
      {(accountType === "reosadmin" || isAdminRole) && (
        <>
          {showTierPosts && (
            <TierPosts
              selectedTierId={
                selectedTier?._id === brokerage?.tier?._id
                  ? brokerage?.tier?._id
                  : selectedTier?._id === brokerage?.leadBrokerageTier?._id
                  ? brokerage?.leadBrokerageTier?._id
                  : selectedTier?._id
              }
            />
          )}
        </>
      )}
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </div>
  );
};

export default memo(BrokerageEcosystemManagement);

import React, { useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Divider,
  Box,
  TableSortLabel,
  CircularProgress,
} from "@mui/material";
import {
  useDeleteCategoryGroupMutation,
  useGetAllCategoryGroupsByTierQuery,
} from "../../../services/category-group";
import { MoreVert, DeleteForever, Edit } from "@mui/icons-material/";
import { ConfirmationDialog } from "../..";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import CreateEditCategoryGroupForm from "./CreateEditCategoryGroupForm";

function CategoryGroupsList({ selectedTier }) {
  const { userId, userRole } = useContext(AuthContext);
  const [deleteCategoryGroup] = useDeleteCategoryGroupMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {
    data: categoryGroups,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetAllCategoryGroupsByTierQuery(selectedTier?._id, {
    skip: !selectedTier?._id,
  });
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [currentCategoryGroup, setCurrentCategoryGroup] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [sortField, setSortField] = useState("path");
  const [sortDirection, setSortDirection] = useState("asc");
  const [openEditCategoryGroupDialog, setOpenEditCategoryGroupDialog] =
    useState(false);

  const handleRowClick = (event, categoryGroupItem) => {
    if (
      event.target.closest(".menu-button") ||
      event.target.closest(".view-details-button")
    ) {
      setCurrentCategoryGroup(categoryGroupItem);
      return;
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenEditCategoryGroupDialog = (event) => {
    event.stopPropagation();
    setOpenEditCategoryGroupDialog(true);
    setAnchorEl(null);
  };

  const handleCloseEditCategoryGroupDialog = () => {
    setOpenEditCategoryGroupDialog(false);
  };

  const handleDeleteCategoryGroupClick = (event, categoryGroupId) => {
    event.stopPropagation();
    setCurrentCategoryGroup({ ...currentCategoryGroup, _id: categoryGroupId });
    setConfirmationDialogOpen(true);
  };
  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleConfirmationDialogConfirm = async () => {
    if (currentCategoryGroup?._id) {
      const result = await deleteCategoryGroup({
        userId,
        categoryGroupId: currentCategoryGroup._id,
      });

      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        refetch();
        setConfirmationDialogOpen(false);
      } else {
        setNotification({
          open: true,
          message: `Error deleting Category Group. ${result?.error?.data?.msg}`,
        });
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  const sortedCategoryGroups = [...(categoryGroups || [])]?.sort((a, b) => {
    if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
    if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
    return 0;
  });

  if (isLoading) {
    return (
      <CircularProgress style={{ display: "block", margin: "20px auto" }} />
    );
  }

  if (!categoryGroups || categoryGroups.length === 0) {
    return (
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        No category groups available
      </Typography>
    );
  }

  if (isError) {
    return (
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        {isError &&
        error?.data?.msg === "No category groups found for this tier"
          ? error?.data?.msg
          : isError &&
            error?.data?.msg !== "No category groups found for this tier"
          ? "Error loading category groups"
          : "No category groups available"}
      </Typography>
    );
  }

  const isREOSAdmin = userRole.includes("reos");

  return (
    <>
      <Box>
        <Typography
          variant="h6"
          style={{ textAlign: "center", marginBottom: "1rem" }}
        >
          Category Groups List
        </Typography>
        <Divider
          variant="fullWidth"
          style={{ textAlign: "center", marginBottom: "1rem" }}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table className="user-table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortField === "name"}
                  direction={sortDirection}
                  onClick={() => handleSort("name")}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "path"}
                  direction={sortDirection}
                  onClick={() => handleSort("path")}
                >
                  Path
                </TableSortLabel>
              </TableCell>
              <TableCell>Tier Level</TableCell>
              <TableCell>Actions </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(sortedCategoryGroups) &&
              sortedCategoryGroups?.map((categoryGroup, index) => (
                <TableRow
                  key={categoryGroup?._id}
                  onClick={(event) => handleRowClick(event, categoryGroup)}
                  style={{ cursor: "pointer" }}
                  className="table-row"
                >
                  <TableCell>{categoryGroup?.name}</TableCell>
                  <TableCell>{categoryGroup?.path}</TableCell>
                  <TableCell>{categoryGroup?.tier?.level}</TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {(categoryGroup?.path !== "common" || isREOSAdmin) && (
                        <IconButton
                          aria-label="delete"
                          onClick={(event) =>
                            handleDeleteCategoryGroupClick(
                              event,
                              categoryGroup?._id
                            )
                          }
                        >
                          <DeleteForever />
                        </IconButton>
                      )}
                      <IconButton
                        className="menu-button"
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(event) =>
                          handleMenuOpen(event, categoryGroup?._id)
                        }
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                      >
                        <MenuItem
                          onClick={(event) => {
                            event.stopPropagation();
                            handleOpenEditCategoryGroupDialog(event);
                          }}
                        >
                          <Edit />
                          <Typography ml={1}>Edit Category Group</Typography>
                        </MenuItem>
                      </Menu>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider style={{ margin: "20px 0" }} />
      <CreateEditCategoryGroupForm
        mode="edit"
        refetchCategoryGroup={refetch}
        categoryGroup={currentCategoryGroup}
        open={openEditCategoryGroupDialog}
        onClose={handleCloseEditCategoryGroupDialog}
      />
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmationDialogConfirm}
        title="Confirm Deleting the Category Group"
        description="Are you sure you want to delete this Category Group permanently?"
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
}

export default CategoryGroupsList;

import React, { useEffect, useState, memo, useMemo } from "react";
import { useUpdateMemberDutyPreferencesMutation } from "../../../../services/duty-schedule";
import { useSendCustomEmailNotificationMutation } from "../../../../services/duty-emails";
import {
  Paper,
  TextField,
  IconButton,
  Typography,
  Box,
  InputAdornment,
  Select,
  MenuItem,
  Tabs,
  Tab,
} from "@mui/material";
import { TabPanel } from "../../../helpers/utils";
import { Close as CloseIcon } from "@mui/icons-material/";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationSnackbar from "../../../helpers/notification-snackbar";
import MembersDutyPreferencesTable from "./MembersDutyPreferencesTable";
import MembersDutyPreferencesForm from "./MembersDutyPreferencesForm";
import MembersDutyPreferencesEmailService from "./MembersDutyPreferencesEmailService";

function MembersDutyPreferencesList({ members = [], tierId }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const initialTab = parseInt(queryParams.get("fdfetab")) || 0;
  const [fdfetabValue, setFdfetabValue] = useState(initialTab);
  const [
    updateMemberDutyPreferences,
    { isLoading: isUpdateMemberDutyPreferencesLoading },
  ] = useUpdateMemberDutyPreferencesMutation();
  const [sendCustomEmailNotification] =
    useSendCustomEmailNotificationMutation();
  const [, setCurrentMemberIdClicked] = useState(null);
  const [currentMemberClicked, setCurrentMemberClicked] = useState(null);
  const [sortField, setSortField] = useState("email");
  const [sortDirection, setSortDirection] = useState("asc");
  const [filterText, setFilterText] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedMembersUserIds, setSelectedMembersUserIds] = useState([]);
  const [dutyFilter, setDutyFilter] = useState("all");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleRowClick = (event, memberUserId, memberUser) => {
    if (
      event.target.closest(".checkbox") ||
      event.target.closest(".menu-button") ||
      event.target.closest(".view-details-button")
    ) {
      setCurrentMemberClicked(memberUser);
      setCurrentMemberIdClicked(memberUserId);
      return;
    }
    navigate(`/member-profile/${memberUserId}`);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    if (event) event.stopPropagation();
    setAnchorEl(null);
    setCurrentMemberClicked(null);
    setCurrentMemberIdClicked(null);
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  const handleFilterChange = (event) => {
    setFilterText(event.target.value.toLowerCase());
  };

  const sortedMembers = [...members]?.sort((a, b) => {
    const fields = sortField.split(".");
    const aValue = fields.reduce((acc, curr) => acc[curr], a);
    const bValue = fields.reduce((acc, curr) => acc[curr], b);

    if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
    if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
    return 0;
  });

  const filteredMembers = sortedMembers.filter((member) => {
    const filterTextLower = filterText.toLowerCase();

    const fullName =
      `${member.user.firstName} ${member.user.lastName}`.toLowerCase();

    const emailMatch = member.user.email.toLowerCase().includes(filterText);
    const firstNameMatch = member.user.firstName
      .toLowerCase()
      .includes(filterText);
    const lastNameMatch = member.user.lastName
      .toLowerCase()
      .includes(filterText);
    const fullNameMatch = fullName.includes(filterTextLower);
    const nameMatch =
      emailMatch || firstNameMatch || lastNameMatch || fullNameMatch;

    if (!nameMatch) return false;
    if (dutyFilter === "willing") return member?.willingToDoDuty;
    if (dutyFilter === "notWilling") return !member?.willingToDoDuty;

    return true;
  });

  const clearFilters = () => {
    setFilterText("");
  };

  const handleDutyPreferencesSubmit = async (updateData) => {
    if (!selectedMembers.length) {
      setNotification({
        open: true,
        message: "No members selected.",
      });
      return;
    }

    const result = await updateMemberDutyPreferences({
      userIds: selectedMembersUserIds,
      updateData,
    });
    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        setNotification({ open: false, message: "" });
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error saving changes: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleSendCustomEmailNotificationSubmit = async (formData) => {
    if (!selectedMembers.length) {
      setNotification({
        open: true,
        message: "No members selected.",
      });
      return;
    }

    const result = await sendCustomEmailNotification({
      formData,
    });
    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        setNotification({ open: false, message: "" });
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error saving changes: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set("fdfetab", fdfetabValue);
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [fdfetabValue, navigate, location.pathname, location.search]);

  const handleTabChange = (event, newValue) => {
    setFdfetabValue(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          gap: 2,
          mb: 2,
        }}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            m: 1,
            p: 1,
          }}
        >
          <Tabs
            value={fdfetabValue}
            onChange={handleTabChange}
            aria-label="flooor duty fe tabs"
            variant="scrollable"
            scrollButtons="auto"
            className="tabs-paper"
          >
            <Tab
              label="Duty Preferences Form"
              id="fdfe-tab-0"
              aria-controls="fdfe-tabpanel-0"
            />
            <Tab
              label="Members Email Service"
              id="fdfe-tab-1"
              aria-controls="fdfe-tabpanel-1"
            />
          </Tabs>
          <TabPanel value={fdfetabValue} index={0} className="tabs-paper">
            <MembersDutyPreferencesForm
              selectedMembersUserIds={selectedMembersUserIds}
              tierId={tierId}
              onSubmit={handleDutyPreferencesSubmit}
            />
          </TabPanel>
          <TabPanel value={fdfetabValue} index={1} className="tabs-paper">
            <MembersDutyPreferencesEmailService
              selectedMembersUserIds={selectedMembersUserIds}
              tierId={tierId}
              onSubmit={handleSendCustomEmailNotificationSubmit}
            />
          </TabPanel>
        </Paper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            gap: 2,
            mb: 2,
          }}
        >
          <TextField
            label="Filter Users"
            variant="outlined"
            value={filterText}
            onChange={handleFilterChange}
            sx={{ flex: "35%" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {filterText && (
                    <IconButton onClick={clearFilters} edge="end">
                      <CloseIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Select
            value={dutyFilter}
            onChange={(e) => setDutyFilter(e.target.value)}
            sx={{ flex: "20%" }}
          >
            <MenuItem value="all">All Members</MenuItem>
            <MenuItem value="willing">Willing to Do Duty</MenuItem>
            <MenuItem value="notWilling">Not Willing to Do Duty</MenuItem>
          </Select>
          <Typography variant="h6" sx={{ textAlign: "center", flex: "30%" }}>
            User Count: {filteredMembers?.length}
          </Typography>
        </Box>
      </Box>

      {members?.length === 0 || filteredMembers.length === 0 ? (
        <Typography variant="h5" m="2rem 0.1rem 0.5rem 5rem">
          No members available
        </Typography>
      ) : (
        <MembersDutyPreferencesTable
          selectedMembers={selectedMembers}
          selectedMembersUserIds={selectedMembersUserIds}
          filteredMembers={filteredMembers}
          sortField={sortField}
          sortDirection={sortDirection}
          handleSort={handleSort}
          handleRowClick={handleRowClick}
          handleMenuOpen={handleMenuOpen}
          handleMenuClose={handleMenuClose}
          anchorEl={anchorEl}
          open={open}
          currentMemberClicked={currentMemberClicked}
          setSelectedMembers={setSelectedMembers}
          setSelectedMembersUserIds={setSelectedMembersUserIds}
        />
      )}
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
}

export default memo(MembersDutyPreferencesList);

import React, { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Box, List, ListItem, CircularProgress } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import EmailIcon from "@mui/icons-material/Email";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { BuildCircle } from "@mui/icons-material";

function UserManagementMenu({
  handleOpenInviteNewMemberDialog,
  handleOpenInviteMultipleMembersDialog,
  handleOpenInviteMembersCSVDialog,
  handleSendSetupPasswordEmail,
  isSendSetupPasswordEmailLoading,
  handleOpenUpdateChatParticipantsForTierDialog,
  isUpdateChatParticipantsLoading,
}) {
  const { userRole } = useContext(AuthContext);
  const isAdminRole = [
    "reossuperadmin",
    "reossupportadmin",
    "reosadmin",
    "brokeragesuperadmin",
    "teamsuperadmin",
  ].includes(userRole);

  return (
    <Box className="user-management-box">
      <List className="user-management-list">
        <ListItem
          button
          onClick={handleOpenInviteNewMemberDialog}
          className="user-management-item"
        >
          <PersonAddIcon className="icon" />
          Invite New Member
        </ListItem>
        <ListItem
          button
          onClick={handleOpenInviteMultipleMembersDialog}
          className="user-management-item"
        >
          <AddCircleOutlineIcon className="icon" />
          Add Members
        </ListItem>
        <ListItem
          button
          onClick={handleOpenInviteMembersCSVDialog}
          className="user-management-item"
        >
          <ImportExportIcon className="icon" />
          Bulk Load
        </ListItem>
        <ListItem
          button
          onClick={handleSendSetupPasswordEmail}
          className="user-management-item"
        >
          {isSendSetupPasswordEmailLoading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <EmailIcon className="icon" />
              Send Setup Password Emails
            </>
          )}
        </ListItem>
        {isAdminRole && (
          <ListItem
            button
            onClick={handleOpenUpdateChatParticipantsForTierDialog}
            className="user-management-item"
          >
            {isUpdateChatParticipantsLoading ? (
              <CircularProgress size={24} />
            ) : (
              <>
                <BuildCircle className="icon" />
                Update Chat Participants for this Office
              </>
            )}
          </ListItem>
        )}
      </List>
    </Box>
  );
}
export default UserManagementMenu;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const dutySchedulingApi = createApi({
  reducerPath: "duty-scheduling",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/duty-scheduling/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["DutyScheduling", "Duty"],
  endpoints: (builder) => ({
    getDutyScheduleForTheOffice: builder.query({
      query: (tierId) => ({
        url: `get-for-the-office/${tierId}`,
        method: "GET",
      }),
      skip: (tierId) => !tierId,
      providesTags: [
        { type: "DutyScheduling", id: "LIST" },
        { type: "Duty", id: "LIST" },
      ],
    }),
    getDutySchedulingByTierId: builder.query({
      query: (tierId) => ({
        url: `get-all-by-tier/${tierId}`,
        method: "GET",
      }),
      skip: (tierId) => !tierId,
      providesTags: [{ type: "DutyScheduling", id: "LIST" }],
    }),
    getDutySchedulingByDutyScheduleId: builder.query({
      query: (dutyScheduleId) => ({
        url: `get-all-by-duty-schedule/${dutyScheduleId}`,
        method: "GET",
      }),
      skip: (dutyScheduleId) => !dutyScheduleId,
      providesTags: [
        { type: "DutyScheduling", id: "LIST" },
        { type: "Duty", id: "LIST" },
      ],
    }),
    getDutySchedulingTimeSlotsByDutyScheduleId: builder.query({
      query: ({ dutyScheduleId, startDate, endDate }) => ({
        url: `get-all-time-slots-by-duty-schedule/${dutyScheduleId}`,
        method: "GET",
        params: { startDate, endDate },
      }),
      skip: ({ dutyScheduleId }) => !dutyScheduleId,
      providesTags: [
        { type: "DutyScheduling", id: "LIST" },
        { type: "Duty", id: "LIST" },
      ],
    }),
    getDutySchedulingByMemberId: builder.query({
      query: (memberId) => ({
        url: `get-all-by-member/${memberId}`,
        method: "GET",
      }),
      skip: (memberId) => !memberId,
      providesTags: [
        { type: "DutyScheduling", id: "LIST" },
        { type: "Duty", id: "LIST" },
      ],
    }),
    swapDuties: builder.mutation({
      query: ({ sourceDutyId, targetDutyId }) => ({
        url: `swap-duties`,
        method: "POST",
        body: { sourceDutyId, targetDutyId },
      }),
      invalidatesTags: [{ type: "DutyScheduling", id: "LIST" }],
    }),
    confirmSwap: builder.mutation({
      query: ({ sourceDutyId, targetDutyId }) => ({
        url: `confirm-swap/${sourceDutyId}/${targetDutyId}`,
        method: "POST",
      }),
      invalidatesTags: [
        { type: "DutyScheduling", id: "LIST" },
        { type: "Duty", id: "LIST" },
      ],
    }),
    regenerateDutySchedule: builder.mutation({
      query: ({ tierId, dutyScheduleId, body }) => ({
        url: `regenerate/${tierId}/${dutyScheduleId}`,
        method: "POST",
        body: { body },
      }),
      invalidatesTags: [{ type: "DutyScheduling", id: "LIST" }],
    }),
    updateDutyByDnD: builder.mutation({
      query: ({ dutyId, updatedDutyData, memberId }) => ({
        url: `update-duty-by-dnd/${dutyId}`,
        method: "POST",
        body: { updatedDutyData, memberId },
      }),
      skip: (dutyId) => !dutyId,
      invalidatesTags: [
        { type: "DutyScheduling", id: "LIST" },
        { type: "Duty", id: "LIST" },
      ],
    }),
    createDutyByDnD: builder.mutation({
      query: ({ dutyScheduleId, memberId, dutyData }) => ({
        url: `create-duty-by-dnd/${dutyScheduleId}/${memberId}`,
        method: "POST",
        body: { dutyData },
      }),
      skip: (dutyId) => !dutyId,
      invalidatesTags: [
        { type: "DutyScheduling", id: "LIST" },
        { type: "Duty", id: "LIST" },
      ],
    }),
    checkAvailableTimeSlots: builder.query({
      query: ({ tierId, dutyScheduleId }) => ({
        url: `check-available-time-slots/${tierId}/${dutyScheduleId}`,
        method: "GET",
      }),
      skip: (tierId, dutyScheduleId) => !tierId || !dutyScheduleId,
      providesTags: [
        { type: "DutyScheduling", id: "LIST" },
        { type: "Duty", id: "LIST" },
      ],
    }),
    confirmAvailableDuty: builder.mutation({
      query: (dutyId) => ({
        url: `confirm-available-duty/${dutyId}`,
        method: "POST",
      }),
      skip: (dutyId) => !dutyId,
      providesTags: [
        { type: "DutyScheduling", id: "LIST" },
        { type: "Duty", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetDutyScheduleForTheOfficeQuery,
  useGetDutySchedulingByTierIdQuery,
  useGetDutySchedulingByDutyScheduleIdQuery,
  useGetDutySchedulingTimeSlotsByDutyScheduleIdQuery,
  useGetDutySchedulingByMemberIdQuery,
  useSwapDutiesMutation,
  useConfirmSwapMutation,
  useRegenerateDutyScheduleMutation,
  useUpdateDutyByDnDMutation,
  useCreateDutyByDnDMutation,
  useCheckAvailableTimeSlotsQuery,
  useConfirmAvailableDutyMutation,
} = dutySchedulingApi;

export default dutySchedulingApi;
export { dutySchedulingApi };

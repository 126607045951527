import React, { useState, useEffect } from "react";
import {
  useCreateEventTypeMutation,
  useUpdateEventTypeMutation,
} from "../../../../services/event-type";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import NotificationSnackbar from "../../../helpers/notification-snackbar";

function CreateEditEventTypeDialog({ open, onClose, eventType, dialogMode }) {
  const [createEventType, { isLoading }] = useCreateEventTypeMutation();
  const [updateEventType, { isLoading: isUpdateEventTypeLoading }] =
    useUpdateEventTypeMutation();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (eventType) {
      setName(eventType.name);
      setDescription(eventType.description);
    }
  }, [eventType]);

  const submitEventType = async (e) => {
    e.preventDefault();
    let result;
    if (dialogMode === "create") {
      result = await createEventType({
        name: name?.toLowerCase(),
        description,
      });
    } else if (dialogMode === "edit") {
      result = await updateEventType({
        eventTypeId: eventType?._id,
        name: name?.toLowerCase(),
        description,
      });
    }
    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        onClose();
        if (dialogMode === "create") {
          setName("");
          setDescription("");
        }
      }, 2500);
    } else {
      setNotification({
        open: true,
        message: `Error: ${result.error.data?.msg || "Unknown error"}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {dialogMode === "create" ? "Create Event Type" : "Update Event Type"}
      </DialogTitle>
      <DialogContent>
        <Grid item>
          <TextField
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={10}
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={submitEventType}
          color="primary"
          disabled={
            dialogMode === "create"
              ? isLoading
              : dialogMode === "edit"
              ? isUpdateEventTypeLoading
              : isLoading
          }
        >
          {isLoading || isUpdateEventTypeLoading ? (
            <CircularProgress size={24} />
          ) : dialogMode === "create" ? (
            "Create"
          ) : (
            "Update"
          )}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
}

export default CreateEditEventTypeDialog;

import React, { useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import {
  useAssignAllOfficeCategoryToLocalOfficeMutation,
  useUnassignAllOfficeCategoryFromLocalOfficeMutation,
  useGetAllOfficeCategoriesFromUserRoleTiersCollectionQuery,
} from "../../../services/category";
import { useGetTiersFromUserRoleTiersCollectionQuery } from "../../../services/tier";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import { titleCase } from "title-case";

const AssignAllOfficeCategoryToLocalOfficeDialog = ({
  open,
  onClose,
  preselectedCategory,
  mode = "assign",
}) => {
  const { userId } = useContext(AuthContext);
  const { data: categories } =
    useGetAllOfficeCategoriesFromUserRoleTiersCollectionQuery(userId);
  const { data: tiers } = useGetTiersFromUserRoleTiersCollectionQuery(userId, {
    skip: !userId,
  });

  const [
    assignAllOfficeCategoryToLocalOffice,
    { isLoading: isAssignAllOfficeCategoryLoading },
  ] = useAssignAllOfficeCategoryToLocalOfficeMutation();
  const [
    unassignAllOfficeCategoryToLocalOffice,
    { isLoading: isUnassignAllOfficeCategoryLoading },
  ] = useUnassignAllOfficeCategoryFromLocalOfficeMutation();

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTier, setSelectedTier] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!(selectedCategory?._id || preselectedCategory) || !selectedTier?._id) {
      setNotification({
        open: true,
        message: "There are no selected categories or tier id.",
      });
      return;
    }

    let result;
    if (mode === "assign") {
      result = await assignAllOfficeCategoryToLocalOffice({
        tierId: selectedTier?._id,
        categoryId: preselectedCategory?._id
          ? preselectedCategory?._id
          : selectedCategory?._id,
      });
    } else if (mode === "remove") {
      result = await unassignAllOfficeCategoryToLocalOffice({
        tierId: selectedTier?._id,
        categoryId: preselectedCategory?._id
          ? preselectedCategory?._id
          : selectedCategory?._id,
      });
    }

    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        if (mode === "assign") {
          setSelectedCategory(null);
          setSelectedTier(null);
        }
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error: ${result?.error?.data?.msg || "Unknown error"}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth className="edit-post">
      <DialogTitle>
        <Typography sx={{ fontWeight: 700, fontSize: "1.5rem" }}>
          {mode === "assign"
            ? "Assign All Office Category To Local Office"
            : "Unnassign All Office Category From Local Office"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid item>
            {preselectedCategory ? (
              <TextField
                label="Selected Category"
                variant="outlined"
                margin="normal"
                fullWidth
                value={titleCase(preselectedCategory?.name)}
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                value={selectedCategory}
                onChange={(event, newValue) => {
                  setSelectedCategory(newValue);
                }}
                options={categories || []}
                getOptionLabel={(option) => option?.name || ""}
                isOptionEqualToValue={(option, value) =>
                  option?._id === value._id
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Category"
                    variant="outlined"
                    margin="normal"
                    required
                  />
                )}
              />
            )}
          </Grid>
          <Grid item>
            <Autocomplete
              value={selectedTier}
              onChange={(event, newValue) => {
                setSelectedTier(newValue);
              }}
              options={tiers || []}
              getOptionLabel={(option) =>
                option
                  ? `${titleCase(option?.level)}${
                      (option?.brokerage || option?.team || option?.group) &&
                      (option?.brokerage?.officeName ||
                        option?.team?.name ||
                        option?.group?.name)
                        ? ` - ${
                            option?.brokerage?.officeName ||
                            option?.team?.name ||
                            option?.group?.name
                          }`
                        : ""
                    } ${
                      option?.level === "system" || option?.level === "global"
                        ? " - REACirc"
                        : ""
                    } ${
                      option?._id === option?.brokerage?.leadBrokerageTier
                        ? " - (Lead Brokerage Office)"
                        : ""
                    } ${
                      option?._id === option?.brokerage?.tier
                        ? " - (Office)"
                        : ""
                    }`
                  : ""
              }
              isOptionEqualToValue={(option, value) =>
                option?._id === value._id
              }
              fullWidth
              renderOption={(props, option) => {
                const isMirrored = preselectedCategory?.mirroringTo?.some(
                  (mirroredTier) =>
                    mirroredTier?._id?.toString() === option?._id?.toString()
                );

                return (
                  <li
                    {...props}
                    style={{
                      backgroundColor: isMirrored ? "lightblue" : "inherit",
                    }}
                  >
                    {titleCase(option?.level)} -{" "}
                    {`${
                      option?.brokerage?.officeName ||
                      option?.team?.name ||
                      option?.group?.name
                    } ${isMirrored ? "(mirrored)" : ""}`}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select the Office"
                  variant="outlined"
                  margin="normal"
                  required
                />
              )}
            />
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid item>
          <Button onClick={onClose} color="secondary" type="submit" fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={
              (mode === "assign" && isAssignAllOfficeCategoryLoading) ||
              (mode === "remove" && isUnassignAllOfficeCategoryLoading)
            }
          >
            {(mode === "assign" && isAssignAllOfficeCategoryLoading) ||
            (mode === "remove" && isUnassignAllOfficeCategoryLoading) ? (
              <CircularProgress size={24} />
            ) : mode === "assign" ? (
              "Assign"
            ) : (
              "Remove"
            )}
          </Button>
        </Grid>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default AssignAllOfficeCategoryToLocalOfficeDialog;

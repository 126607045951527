export const defaultPermissions = [
  "can-chat",
  "can-post",
  "can-comment",
  "can-reply",
  "can-like",
  "is-full-member",
];
export const defaultViewerPermissions = [
  "can-post",
  "can-comment",
  "can-reply",
  "can-like",
];

import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";

const CustomSystemDetector = () => {
  const { updateCustomSystem } = useContext(AuthContext);

  // Access the current URL
  const currentURL = window.location.hostname.toLowerCase();
  // Check if the system is to be customized
  const isCustomSystem =
    !currentURL.includes("portal") && !currentURL.includes("localhost");
  const customSystemName = isCustomSystem ? currentURL.split(".")[0] : "";

  useEffect(() => {
    // Update context values
    updateCustomSystem(isCustomSystem, customSystemName);
  }, [updateCustomSystem, isCustomSystem, customSystemName]);

  // This component doesn't render anything directly
  return null;
};

export default CustomSystemDetector;

import React, { useState, useEffect } from "react";
import { useSignupBrokerageMutation } from "../../../services/invitation";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  TextField,
  Typography,
  Grid,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import jwtDecode from "jwt-decode";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const BrokerageSignupForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [invitationToken, setInvitationToken] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const [signupBrokerage, { isLoading }] = useSignupBrokerageMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const isValidPassword = (password) => {
    return password.length >= 8;
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    accountType: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    password: "",
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    setInvitationToken(token);

    const decodedToken = jwtDecode(token);
    if (decodedToken && decodedToken.brokerageEmail) {
      setFormData((prevState) => ({
        ...prevState,
      }));
    }
  }, [location]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;
    let newErrors = {
      firstName: "",
      lastName: "",
      password: "",
    };

    if (!formData.firstName) {
      newErrors.firstName = "First Name is required";
      isValid = false;
    }

    if (!formData.lastName) {
      newErrors.lastName = "Last Name is required";
      isValid = false;
    }

    if (!isValidPassword(formData?.password)) {
      newErrors.password = "Password must be at least 8 characters long";
      isValid = false;
    }

    if (!isValid) {
      setErrors(newErrors);
      return;
    }

    try {
      const result = await signupBrokerage({
        ...formData,
        invitationToken,
      }).unwrap();

      setNotification({ open: true, message: result?.msg });
      setTimeout(() => {
        navigate("/login");
      }, 2500);
    } catch (error) {
      if (error?.data && error?.data?.msg) {
        setNotification({ open: true, message: error?.data?.msg });
      }
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom>
        Brokerage Signup Form
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              error={Boolean(errors?.firstName)}
              helperText={errors?.firstName}
              variant="outlined"
              margin="normal"
              value={formData?.firstName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              error={Boolean(errors?.lastName)}
              helperText={errors?.lastName}
              variant="outlined"
              margin="normal"
              value={formData?.lastName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              type={showPassword ? "text" : "password"}
              label="Password"
              name="password"
              variant="outlined"
              margin="normal"
              error={Boolean(errors?.password)}
              helperText={errors?.password}
              value={formData?.password}
              onChange={handleChange}
              onCopy={(e) => e.preventDefault()}
              onPaste={(e) => e.preventDefault()}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    size="small"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : "Signup"}
            </Button>
          </Grid>
        </Grid>
      </form>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Container>
  );
};

export default BrokerageSignupForm;

import React, { useState } from "react";
import { Paper, Box, Tabs, Tab } from "@mui/material";
import { BackButton } from "../..";
import TeamPage from "./TeamPage";
import GroupsPage from "./group/GroupsPage";
import { TabPanel } from "../../helpers/utils";

function TeamAndGroupsPageTabs() {
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box component={Paper}
      sx={{ p: 2, width: "100%", maxWidth: 900, margin: "auto", mt: 2 }}
      className="tabs-paper"
    >
      <BackButton />
      <Paper className="tabs-paper">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="team and group tabs"
          variant="scrollable"
          scrollButtons="auto"
          className="tabs-paper"
        >
          <Tab
            label="Team Manager"
            id="simple-tab-0"
            aria-controls="simple-tabpanel-0"
          />
          <Tab
            label="Groups Manager"
            id="simple-tab-1"
            aria-controls="simple-tabpanel-1"
          />
        </Tabs>
        <TabPanel value={tabValue} index={0} className="tabs-paper">
          <TeamPage />
        </TabPanel>
        <TabPanel value={tabValue} index={1} className="tabs-paper">
          <GroupsPage />
        </TabPanel>
      </Paper>
    </Box>
  );
}

export default TeamAndGroupsPageTabs;

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  IconButton,
  Tooltip,
  Paper,
  Typography,
  TextField,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import PaymentIcon from "@mui/icons-material/Payment";
import dayjs from "dayjs";

// Utility function for sorting
const getComparator = (order, orderBy) => {
  return (a, b) => {
    const clientA =
      a.subscription?.tier?.brokerage?.officeName ||
      `${a.subscription?.account.firstName} ${a.subscription?.account.lastName}`;
    const clientB =
      b.subscription?.tier?.brokerage?.officeName ||
      `${b.subscription?.account.firstName} ${b.subscription?.account.lastName}`;

    if (orderBy === "invoiceNumber") {
      return (
        (a.invoiceNumber < b.invoiceNumber ? -1 : 1) *
        (order === "asc" ? 1 : -1)
      );
    }
    if (orderBy === "amount") {
      return (
        (a.totalAmount < b.totalAmount ? -1 : 1) * (order === "asc" ? 1 : -1)
      );
    }
    if (orderBy === "dateOfInvoice") {
      return (
        (a.dateOfInvoice < b.dateOfInvoice ? -1 : 1) *
        (order === "asc" ? 1 : -1)
      );
    }
    if (orderBy === "client") {
      return (clientA < clientB ? -1 : 1) * (order === "asc" ? 1 : -1);
    }
    return 0;
  };
};

const InvoiceTable = ({
  invoices,
  page,
  rowsPerPage,
  order,
  orderBy,
  onRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleEditClick,
  handleDeleteClick,
  handlePaymentClick,
}) => {
  const [hoveredDeleteId, setHoveredDeleteId] = React.useState(null);
  const [searchTerm, setSearchTerm] = React.useState("");

  const rowColour = "#ffffff";
  const rowColourAlt = "#f6f6f6";

  // Filtering logic
  const filteredInvoices = invoices.filter((invoice) => {
    const invoiceNumberMatch = invoice.invoiceNumber
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());
    const clientMatch = (
      invoice.subscription?.tier?.brokerage?.officeName ||
      `${invoice.subscription?.account.firstName} ${invoice.subscription?.account.lastName}`
    )
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const dateOfInvoiceMatch = invoice.dateOfInvoice
      ? dayjs(invoice.dateOfInvoice).format("YYYY-MM-DD").includes(searchTerm)
      : true;
    const amountMatch = invoice.totalAmount
      ? invoice.totalAmount.toFixed(2).includes(searchTerm)
      : true;
    const dueDateMatch = invoice.dueDate
      ? dayjs(invoice.dueDate).format("YYYY-MM-DD").includes(searchTerm)
      : true;

    return (
      invoiceNumberMatch ||
      clientMatch ||
      dateOfInvoiceMatch ||
      amountMatch ||
      dueDateMatch
    );
  });

  // Memoize sorted invoices
  const sortedInvoices = React.useMemo(
    () => filteredInvoices.sort(getComparator(order, orderBy)),
    [filteredInvoices, order, orderBy]
  );

  // Calculate total amount and item count
  const totalAmount = sortedInvoices.reduce(
    (sum, invoice) => sum + (invoice.totalAmount || 0),
    0
  );
  const itemCount = sortedInvoices.length;

  return (
    <Paper>
      <TextField
        label="Search Invoices"
        variant="outlined"
        size="small"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "invoiceNumber"}
                  direction={order}
                  onClick={() => onRequestSort("invoiceNumber")}
                >
                  Invoice Number
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "client"}
                  direction={order}
                  onClick={() => onRequestSort("client")}
                >
                  Client
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "dateOfInvoice"}
                  direction={order}
                  onClick={() => onRequestSort("dateOfInvoice")}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "amount"}
                  direction={order}
                  onClick={() => onRequestSort("amount")}
                >
                  Amount
                </TableSortLabel>
              </TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedInvoices.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9}>
                  <Typography variant="body2" align="center">
                    No invoices available
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              sortedInvoices
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((invoice, index) => (
                  <TableRow
                    key={invoice._id}
                    sx={{
                      backgroundColor:
                        index % 2 === 0 ? rowColour : rowColourAlt,
                    }}
                  >
                    <TableCell>{invoice.invoiceNumber}</TableCell>
                    <TableCell>
                      {invoice.subscription?.tier?.brokerage?.officeName ||
                        `${invoice.subscription?.account.firstName} ${invoice.subscription?.account.lastName}`}
                    </TableCell>
                    <TableCell>
                      {invoice.dateOfInvoice
                        ? dayjs(invoice.dateOfInvoice)
                            .utc()
                            .toISOString()
                            .split("T")[0]
                        : "N/A"}
                    </TableCell>
                    <TableCell>${invoice.totalAmount?.toFixed(2)}</TableCell>
                    <TableCell>
                      {invoice.dueDate
                        ? dayjs(invoice.dueDate)
                            .utc()
                            .toISOString()
                            .split("T")[0]
                        : "N/A"}
                    </TableCell>
                    <TableCell>{invoice.status}</TableCell>
                    <TableCell>{invoice.currency}</TableCell>
                    <TableCell>{invoice.notes || "N/A"}</TableCell>
                    <TableCell>
                      <Tooltip title="Edit">
                        <IconButton onClick={() => handleEditClick(invoice)}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => handleDeleteClick(invoice)}
                          onMouseEnter={() => setHoveredDeleteId(invoice._id)}
                          onMouseLeave={() => setHoveredDeleteId(null)}
                        >
                          <Delete
                            sx={{
                              color:
                                hoveredDeleteId === invoice._id
                                  ? "red"
                                  : "inherit",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Pay">
                        <IconButton onClick={() => handlePaymentClick(invoice)}>
                          <PaymentIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
            )}
            <TableRow>
              <TableCell colSpan={3} align="right">
                <Typography variant="subtitle1">Total Amount:</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1">
                  ${totalAmount.toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell colSpan={5} align="right">
                <Typography variant="subtitle1">
                  Number of Items: {itemCount}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sortedInvoices.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  );
};

export default InvoiceTable;

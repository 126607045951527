import DOMPurify from "dompurify";
import React from "react";
import ReactPlayer from "react-player";

export function VimeoPlayer({ videoId, extraSegment }) {
  const videoUrl = `https://vimeo.com/${videoId}${
    extraSegment ? `/${extraSegment}` : ""
  }`;

  return (
    <div
      className="player-wrapper"
      style={{
        position: "relative",
        paddingTop: "56.25%", // 16:9 Aspect Ratio
      }}
    >
      <ReactPlayer
        url={videoUrl}
        className="react-player"
        width="100%"
        height="100%"
        style={{ position: "absolute", top: "0", left: "0" }}
        controls
      />
    </div>
  );
}

export function replaceVimeoLinks(content) {
  // Regular expressions to match various Vimeo link formats with optional HTML tags around them
  const regexVimeo =
    /(?:<a[^>]*href=["'])?https:\/\/(?:player\.)?vimeo\.com\/(?:video\/)?(\d+)(?:\/([a-zA-Z0-9]+))?[^>]*>.*?[^<]*<\/a>|(?:<a[^>]*href=["'])?https:\/\/(?:player\.)?vimeo\.com\/(?:video\/)?(\d+)(?:\/([a-zA-Z0-9]+))?([^<]*<\/a>)?[^<\s]*/g;

  const sanitizedContent = DOMPurify.sanitize(content);
  let lastIndex = 0;
  let match;
  const parts = [];

  while ((match = regexVimeo.exec(sanitizedContent)) !== null) {
    const start = match.index;
    const end = regexVimeo.lastIndex;

    // Determine the matched video ID
    const videoId = match[1] || match[3];
    const extraSegment = match[2] || match[4] || "";

    // Push the text before the match
    if (start > lastIndex) {
      parts.push(sanitizedContent.substring(lastIndex, start));
    }
    // Push the VimeoPlayer component
    if (videoId) {
      parts.push(
        <VimeoPlayer
          key={videoId}
          videoId={videoId}
          extraSegment={extraSegment}
        />
      );
    }
    lastIndex = end;
  }

  // Push the remaining text after the last match
  if (lastIndex < sanitizedContent.length) {
    parts.push(sanitizedContent.substring(lastIndex));
  }

  return parts;
}

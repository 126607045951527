import React, { useContext, useState, useCallback } from "react";
import {
  useGetAllTeamRolesQuery,
  useDeleteTeamRoleMutation,
  useRemovePermissionFromRoleMutation,
} from "../../../../services/team-role";
import { AuthContext } from "../../../../context/AuthContext";
import {
  Paper,
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  DeleteForever,
  MoreVert,
  Delete,
  Edit,
  AssignmentTurnedIn,
} from "@mui/icons-material/";
import NotificationSnackbar from "../../../helpers/notification-snackbar";
import { ConfirmationDialog } from "../../../../components";
import CreateEditTeamRoleDialog from "./CreateEditTeamRoleDialog";
import AddTeamRolePermissionDialog from "./AddTeamRolePermissionDialog";
import AssignTeamRoleDialog from "./AssignTeamRoleDialog";

const TeamRoles = ({ teamId, teamMembers }) => {
  const { accountType } = useContext(AuthContext);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const {
    data: teamRoles,
    error,
    isLoading,
    refetch,
  } = useGetAllTeamRolesQuery(teamId, {
    skip: !teamId,
  });
  const [deleteTeamRole] = useDeleteTeamRoleMutation();
  const [removePermissionFromRole] = useRemovePermissionFromRoleMutation();
  const [teamRoleHeaderMenuAnchorEl, setTeamRoleHeaderMenuAnchorEl] = useState(
    {}
  );
  const [isAddTeamRoleOpen, setIsAddTeamRoleOpen] = useState(false);
  const [isAddTeamRolePermissionOpen, setIsAddTeamRolePermissionOpen] =
    useState(false);
  const [teamRoleIdToRemove, setTeamRoleIdToRemove] = useState(null);
  const [teamRolePermissionToRemove, setTeamRolePermissionToRemove] =
    useState(null);
  const [currentTeamRoleId, setCurrentTeamRoleId] = useState(null);
  const [preselectedTeamRole, setPreselectedTeamRole] = useState(null);
  const [dialogState, setDialogState] = useState({
    open: false,
    context: "",
  });
  const [dialogMode, setDialogMode] = useState("add");
  const [assignDialogMode, setAssignDialogMode] = useState("thisRoleToMember");
  const [editingTeamRole, setEditingTeamRole] = useState(null);
  const [openAssignTeamRoleDialog, setOpenAssignTeamRoleDialog] =
    useState(false);

  const handleAddTeamRoleOpen = () => {
    setDialogMode("add");
    setEditingTeamRole(null);
    setIsAddTeamRoleOpen(true);
  };

  const handleAddTeamRoleClose = () => {
    setIsAddTeamRoleOpen(false);
    setEditingTeamRole(null);
  };

  const handleEditTeamRoleOpen = useCallback(
    (teamRole, teamRoleId) => {
      setDialogMode("edit");
      setEditingTeamRole(teamRole);
      setCurrentTeamRoleId(teamRoleId);
      setIsAddTeamRoleOpen(true);
      setTeamRoleHeaderMenuAnchorEl({
        ...teamRoleHeaderMenuAnchorEl,
        [teamRoleId]: null,
      });
    },
    [teamRoleHeaderMenuAnchorEl]
  );

  const handleAddTeamRolePermissionOpen = (teamRoleId) => {
    setCurrentTeamRoleId(teamRoleId);
    setIsAddTeamRolePermissionOpen(true);
  };

  const handleAddTeamRolePermissionClose = () => {
    setIsAddTeamRolePermissionOpen(false);
    setCurrentTeamRoleId(null);
  };

  const handleRemoveTeamRoleOpen = (teamRoleId) => {
    setCurrentTeamRoleId(teamRoleId);
    setTeamRoleIdToRemove(teamRoleId);
    setDialogState({ open: true, context: "deleteTeamRole" });
    setTeamRoleHeaderMenuAnchorEl({
      ...teamRoleHeaderMenuAnchorEl,
      [teamRoleId]: null,
    });
  };

  const handleRemoveTeamRolePermissionOpen = (teamRoleId, permission) => {
    setCurrentTeamRoleId(teamRoleId);
    setTeamRolePermissionToRemove(permission);
    setDialogState({ open: true, context: "removeTeamRolePermission" });
    setTeamRoleHeaderMenuAnchorEl({
      ...teamRoleHeaderMenuAnchorEl,
      [teamRoleId]: null,
    });
  };

  const handleTeamRoleMenuOpen = (event, teamRoleId) => {
    event.stopPropagation();
    setTeamRoleHeaderMenuAnchorEl({
      ...teamRoleHeaderMenuAnchorEl,
      [teamRoleId]: event.currentTarget,
    });
    setCurrentTeamRoleId(teamRoleId);
  };

  const handleTeamRoleMenuClose = (teamRoleId) => {
    setTeamRoleHeaderMenuAnchorEl({
      ...teamRoleHeaderMenuAnchorEl,
      [teamRoleId]: null,
    });
  };

  const handleDialogConfirm = async () => {
    if (dialogState.context === "deleteTeamRole") {
      const result = await deleteTeamRole({
        teamRoleId: teamRoleIdToRemove,
      });

      if (result.data) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
      } else {
        setNotification({
          open: true,
          message: `Error deleting Team Role. ${result?.error?.data?.msg}`,
        });
      }
    } else if (dialogState.context === "removeTeamRolePermission") {
      const result = await removePermissionFromRole({
        teamRoleId: currentTeamRoleId,
        permission: teamRolePermissionToRemove,
      });
      if (result.data) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
      } else {
        setNotification({
          open: true,
          message: `Error deleting Team Role Permission: ${result?.error?.data?.msg}`,
        });
      }
    }
    setTimeout(() => {
      setDialogState({ ...dialogState, open: false });
      refetch();
    }, 1500);
  };

  const handleOpenAssignThisTeamRoleToMemberDialog = (teamRole, teamRoleId) => {
    setPreselectedTeamRole(teamRole);
    setAssignDialogMode("thisRoleToMember");
    setOpenAssignTeamRoleDialog(true);
    setTeamRoleHeaderMenuAnchorEl({
      ...teamRoleHeaderMenuAnchorEl,
      [teamRoleId]: null,
    });
  };

  const handleCloseAssignTeamRoleDialog = () => {
    setOpenAssignTeamRoleDialog(false);
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (error) return <div>Error: {error?.data?.msg}</div>;

  return (
    <Container component={Paper} sx={{ mt: 2, padding: "1rem 1rem" }}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          paddingBottom: "1rem",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Team Roles:
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleAddTeamRoleOpen(teamId)}
        >
          Add Team Role
        </Button>
      </Grid>
      {teamRoles &&
        teamRoles?.length > 0 &&
        teamRoles?.map((teamRole) => (
          <Card key={teamRole?._id}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    Team Role: {teamRole?.name}
                  </Typography>
                  <Tooltip title="Handle Team Role" placement="left">
                    <IconButton
                      className="menu-team-role-button"
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(event) => {
                        handleTeamRoleMenuOpen(event, teamRole?._id);
                      }}
                    >
                      <MoreVert />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id={`menu-team-role-${teamRole?._id}`}
                    anchorEl={teamRoleHeaderMenuAnchorEl[teamRole?._id]}
                    open={Boolean(teamRoleHeaderMenuAnchorEl[teamRole?._id])}
                    onClose={() => handleTeamRoleMenuClose(teamRole?._id)}
                  >
                    <MenuItem>
                      <Tooltip title="Edit Team Role" placement="left">
                        <IconButton
                          edge="end"
                          aria-label="edit-team-role"
                          onClick={() =>
                            handleEditTeamRoleOpen(teamRole, teamRole?._id)
                          }
                        >
                          <Edit />
                          <Typography ml={1}>Edit Role</Typography>
                        </IconButton>
                      </Tooltip>
                    </MenuItem>
                    <MenuItem>
                      <Tooltip
                        title="Assign Team Role to Member"
                        placement="left"
                      >
                        <IconButton
                          edge="end"
                          aria-label="assign-team-role-to-member"
                          onClick={() =>
                            handleOpenAssignThisTeamRoleToMemberDialog(
                              teamRole,
                              teamRole?._id
                            )
                          }
                        >
                          <AssignmentTurnedIn />
                          <Typography ml={1}>Assign to Member</Typography>
                        </IconButton>
                      </Tooltip>
                    </MenuItem>
                    <MenuItem>
                      <Tooltip title="Delete Team Role" placement="left">
                        <IconButton
                          edge="end"
                          aria-label="delete-role"
                          onClick={() =>
                            handleRemoveTeamRoleOpen(teamRole?._id)
                          }
                        >
                          <Delete />
                          <Typography ml={1}>Delete Team Role</Typography>
                        </IconButton>
                      </Tooltip>
                    </MenuItem>
                  </Menu>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Permissions:
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleAddTeamRolePermissionOpen(teamRole?._id)
                    }
                  >
                    Add Permission
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {teamRole?.permissions &&
                    teamRole?.permissions?.length > 0 &&
                    teamRole?.permissions?.map((perm, i) => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        key={`${perm}-${i}`}
                      >
                        <Typography variant="body1">{perm}</Typography>
                        {accountType === "reossuperadmin" ||
                          (accountType === "reosadmin" && (
                            <IconButton
                              aria-label="delete"
                              onClick={() =>
                                handleRemoveTeamRolePermissionOpen(
                                  teamRole?._id,
                                  perm
                                )
                              }
                            >
                              <DeleteForever />
                            </IconButton>
                          ))}
                      </Box>
                    ))}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      {!teamRoles && (
        <Typography variant="h6" sx={{ padding: "1rem 1rem" }} gutterBottom>
          No Team Roles Found
        </Typography>
      )}
      {teamRoles?.length === 0 && (
        <Typography variant="h6" gutterBottom sx={{ padding: "1rem 1rem" }}>
          No Team Roles created yet
        </Typography>
      )}
      {isLoading && (
        <div className="loading-spinner">
          <CircularProgress size={24} />
        </div>
      )}
      <CreateEditTeamRoleDialog
        open={isAddTeamRoleOpen}
        onClose={handleAddTeamRoleClose}
        teamId={teamId}
        teamRoleId={currentTeamRoleId}
        refetchRoles={refetch}
        action={dialogMode}
        initialRoleData={editingTeamRole}
      />
      <AddTeamRolePermissionDialog
        open={isAddTeamRolePermissionOpen}
        teamRoleId={currentTeamRoleId}
        onClose={handleAddTeamRolePermissionClose}
        refetchRoles={refetch}
      />
      <AssignTeamRoleDialog
        teamId={teamId}
        action={assignDialogMode}
        teamRoles={teamRoles}
        teamMembers={teamMembers}
        preselectedTeamRole={preselectedTeamRole}
        open={openAssignTeamRoleDialog}
        onClose={handleCloseAssignTeamRoleDialog}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
      <ConfirmationDialog
        open={dialogState.open}
        onClose={() => setDialogState({ ...dialogState, open: false })}
        onConfirm={handleDialogConfirm}
        title={
          dialogState.context === "deleteTeamRole"
            ? "Confirm Team Role Deletion"
            : "Confirm Permission Removing from Team Role"
        }
        description={
          dialogState.context === "deleteTeamRole"
            ? "Are you sure you want to delete this team role?"
            : "Are you sure you want to remove this permission from the team role?"
        }
      />
    </Container>
  );
};

export default TeamRoles;

import React, { useState } from "react";
import { Paper, Typography, Grid, IconButton, Box } from "@mui/material";
import {
  AccessTime,
  EventBusy,
  CalendarToday,
  Edit,
  RingVolume,
  House,
} from "@mui/icons-material/";
import { formatDateToDayMonthYear } from "../../../helpers/utils";
import CreateEditDutyScheduleForm from "./CreateEditDutyScheduleForm";

function DutyScheduleItemCard({ tier, dutySchedule }) {
  const [
    openCreateEditDutyScheduleDialog,
    setOpenCreateEditDutyScheduleDialog,
  ] = useState(false);

  const handleOpenEditDutyScheduleDialog = () => {
    setOpenCreateEditDutyScheduleDialog({
      open: true,
      mode: "edit",
      dutySchedule: dutySchedule,
    });
  };

  const handleCloseCreateEditDutyScheduleDialog = () => {
    setOpenCreateEditDutyScheduleDialog(false);
  };

  if (!dutySchedule) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 20,
          width: "100%",
          height: "100%",
        }}
      >
        <Typography variant="h5" gutterBottom>
          No Duty Schedules available
        </Typography>
      </Box>
    );
  }

  return (
    <Paper sx={{ p: "1rem", height: "100%", width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} className="data-section">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" className="data-header">
              Duty Title
            </Typography>
            <IconButton onClick={handleOpenEditDutyScheduleDialog}>
              <Edit />
              <Typography ml={1}>Update Duty Schedule</Typography>
            </IconButton>
          </Box>
          <div style={{ marginLeft: "20px" }}>
            <Typography component="div" variant="body1" className="data-item">
              {dutySchedule?.title}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} className="data-section">
          <Typography variant="h6" className="data-header">
            Duty Schedule Type
          </Typography>
          <div style={{ marginLeft: "20px" }}>
            <Typography component="div" variant="body1" className="data-item">
              {dutySchedule?.dutyScheduleType?.length > 0
                ? dutySchedule.dutyScheduleType.join(", ")
                : "No duty schedule type set."}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} className="data-section">
          <Typography variant="h6" className="data-header">
            Duty Schedule Location
          </Typography>
          <div style={{ marginLeft: "20px" }}>
            <Typography component="div" variant="body1" className="data-item">
              {dutySchedule?.dutyScheduleLocation
                ? dutySchedule?.dutyScheduleLocation
                : "No office locations set."}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} className="data-section">
          <Typography variant="h6" className="data-header">
            Daily Time Slots
          </Typography>
          <div style={{ marginLeft: "20px" }}>
            {Object.keys(dutySchedule?.dailyTimeSlots || {}).length > 0 ? (
              Object.keys(dutySchedule.dailyTimeSlots)?.map((day, index) => (
                <div key={index}>
                  <Typography variant="subtitle2" mt={2}>
                    {day}:
                  </Typography>
                  {dutySchedule.dailyTimeSlots[day].map((slot, slotIndex) => (
                    <Box
                      key={slotIndex}
                      sx={{
                        p: "0",
                        height: "100%",
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <Typography
                        component="div"
                        variant="body1"
                        className="data-item"
                        ml={1}
                      >
                        <AccessTime fontSize="small" /> {slot?.timeSlot}
                      </Typography>
                      <Typography
                        component="div"
                        variant="body1"
                        className="data-item"
                        ml={1}
                      >
                        {slot?.dutyType?.includes("Phone") ||
                        slot?.dutyType?.includes("Remote") ? (
                          <RingVolume />
                        ) : (
                          <House />
                        )}
                      </Typography>
                    </Box>
                  ))}
                </div>
              ))
            ) : (
              <Typography variant="body1" className="data-item">
                No daily time slots set.
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item xs={12} className="data-section">
          <Typography variant="h6" className="data-header">
            People in Office
          </Typography>
          <div style={{ marginLeft: "20px" }}>
            <Typography component="div" variant="body1" className="data-item">
              <strong>Min People in Office:</strong>{" "}
              {(dutySchedule && dutySchedule?.minPeopleInOffice) || 1}
            </Typography>
            <Typography component="div" variant="body1" className="data-item">
              <strong>Max People in Office:</strong>{" "}
              {(dutySchedule && dutySchedule?.maxPeopleInOffice) || "No limit"}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} className="data-section">
          <Typography variant="h6" className="data-header">
            Skipped Date-Time Slots
          </Typography>
          <div style={{ marginLeft: "20px" }}>
            {dutySchedule?.skipDateTimeSlots?.length > 0 ? (
              dutySchedule?.skipDateTimeSlots?.map((slot, index) => (
                <Typography
                  key={index}
                  component="div"
                  variant="body1"
                  className="data-item"
                >
                  <EventBusy fontSize="small" /> <strong>Start:</strong>{" "}
                  {formatDateToDayMonthYear(slot?.startDate)}{" "}
                  <strong>End:</strong>{" "}
                  {formatDateToDayMonthYear(slot?.endDate)}
                </Typography>
              ))
            ) : (
              <Typography variant="body1" className="data-item">
                No skipped date-time slots.
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item xs={12} className="data-section">
          <Typography variant="h6" className="data-header">
            Holidays
          </Typography>
          <div style={{ marginLeft: "20px" }}>
            {dutySchedule?.holidays?.length > 0 ? (
              dutySchedule?.holidays?.map((holiday, index) => (
                <Typography
                  key={index}
                  component="div"
                  variant="body1"
                  className="data-item"
                >
                  <CalendarToday fontSize="small" /> <strong>Date:</strong>{" "}
                  {formatDateToDayMonthYear(holiday?.date)}
                  {holiday?.description && (
                    <>
                      {" "}
                      <strong>Description:</strong> {holiday?.description}
                    </>
                  )}
                </Typography>
              ))
            ) : (
              <Typography variant="body1" className="data-item">
                No holidays set.
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item xs={12} className="data-section">
          <Typography variant="h6" className="data-header">
            Allow Duty Swapping
          </Typography>
          <div style={{ marginLeft: "20px" }}>
            <Typography component="div" variant="body1" className="data-item">
              {dutySchedule?.allowDutySwapping ? "Yes" : "No"}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <CreateEditDutyScheduleForm
        mode={openCreateEditDutyScheduleDialog.mode || "create"}
        tierId={tier?._id}
        dutyScheduleId={dutySchedule?._id}
        open={openCreateEditDutyScheduleDialog}
        onClose={handleCloseCreateEditDutyScheduleDialog}
        dutySchedule={openCreateEditDutyScheduleDialog.dutySchedule}
      />
    </Paper>
  );
}

export default DutyScheduleItemCard;

import React, { memo } from "react";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  TableSortLabel,
} from "@mui/material";
import { MoreVert, Verified, NotInterested } from "@mui/icons-material/";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../helpers/utils";

function MembersDutyPreferencesTable({
  selectedMembers = [],
  filteredMembers = [],
  sortField,
  sortDirection,
  handleSort,
  handleRowClick,
  handleMenuOpen,
  handleMenuClose,
  anchorEl,
  open,
  currentMemberClicked,
  setSelectedMembers,
  setSelectedMembersUserIds,
}) {
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper}>
      <Table className="user-table">
        <TableHead
          sx={{
            color: "var(--text-color)",
            backgroundColor: "var(--bg-color)",
          }}
        >
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={
                  selectedMembers.length > 0 &&
                  selectedMembers.length < filteredMembers.length
                }
                checked={selectedMembers.length === filteredMembers.length}
                onChange={(e) => {
                  e.stopPropagation();
                  if (selectedMembers.length === filteredMembers.length) {
                    setSelectedMembers([]);
                    setSelectedMembersUserIds([]);
                  } else {
                    const allMemberIds = filteredMembers.map(
                      (member) => member._id
                    );
                    const allUserIds = filteredMembers.map(
                      (member) => member.user && member.user._id
                    );
                    setSelectedMembers(allMemberIds);
                    setSelectedMembersUserIds(allUserIds);
                  }
                }}
              />
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortField === "user.firstName"}
                direction={sortDirection}
                onClick={() => handleSort("user.firstName")}
                title="Sort by First Name"
              >
                <sup>First</sup>
              </TableSortLabel>
              <TableSortLabel
                onClick={() => handleSort("user.lastName")}
                active={sortField === "user.lastName"}
                direction={sortDirection}
                title="Sort by Last Name"
              >
                <sup>Last</sup>
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ fontWeight: "bolder" }}>
              <TableSortLabel
                active={sortField === "user.email"}
                direction={sortDirection}
                onClick={() => handleSort("user.email")}
              >
                Email
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortField === "user.accountType"}
                direction={sortDirection}
                onClick={() => handleSort("user.accountType")}
              >
                Account Type
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortField === "willingToDoDuty"}
                direction={sortDirection}
                onClick={() => handleSort("willingToDoDuty")}
              >
                Willing To Do Duty
              </TableSortLabel>
            </TableCell>
            <TableCell>Actions </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(filteredMembers) &&
            filteredMembers?.map((member) => (
              <TableRow
                key={member?._id}
                onClick={(event) =>
                  handleRowClick(event, member?.user?._id, member?.user)
                }
                style={{ cursor: "pointer" }}
                className="table-row"
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedMembers.includes(member._id)}
                    onChange={(e) => {
                      e.stopPropagation();
                      setSelectedMembers((prev) => {
                        const isSelected = prev.includes(member._id);
                        if (isSelected) {
                          return prev.filter((id) => id !== member._id);
                        } else {
                          return [...prev, member._id];
                        }
                      });
                      setSelectedMembersUserIds((prev) => {
                        const isSelected = prev.includes(member.user._id);
                        if (isSelected) {
                          return prev.filter((id) => id !== member.user._id);
                        } else {
                          return [...prev, member.user._id];
                        }
                      });
                    }}
                    className="checkbox"
                  />
                </TableCell>
                <TableCell>
                  {capitalizeFirstLetter(member?.user?.firstName)}{" "}
                  {capitalizeFirstLetter(member?.user?.lastName)}
                </TableCell>
                <TableCell>{member?.user?.email}</TableCell>
                <TableCell>{member?.user?.accountType}</TableCell>
                <TableCell>
                  {member?.willingToDoDuty ? <Verified /> : <NotInterested />}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      className="menu-button"
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(event) =>
                        handleMenuOpen(event, member?.user?._id)
                      }
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleMenuClose}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();

                          navigate(`/member-profile/${currentMemberClicked}`);
                          handleMenuClose(e);
                        }}
                      >
                        <Typography
                          variant="body1"
                          className="view-details-button"
                        >
                          Open Profile
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default memo(MembersDutyPreferencesTable);

import React, { useState, useContext, memo } from "react";
import {
  useCreateTicketMutation,
  useGetTicketTypeEnumsQuery,
} from "../../services/ticket";
import { AuthContext } from "../../context/AuthContext";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import NotificationSnackbar from "../helpers/notification-snackbar";

function TicketDialog({ open, onClose }) {
  const { userId } = useContext(AuthContext);
  const [createTicket, { isLoading }] = useCreateTicketMutation();
  const { data: ticketTypes } = useGetTicketTypeEnumsQuery(userId, {
    skip: !userId,
  });
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const submitTicket = async (ticketData) => {
    await createTicket({ type, description });
    setTimeout(() => {
      setType("");
      setDescription("");
      onClose();
    }, 2500);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Submit a Ticket</DialogTitle>
      <DialogContent>
        <Grid item>
          <FormControl fullWidth margin="normal">
            <InputLabel id="ticket-type-label" sx={{ marginBottom: "10px" }}>
              Ticket Type
            </InputLabel>
            <Select
              labelId="ticket-type-label"
              id="ticketType"
              value={type}
              onChange={(e) => setType(e.target.value)}
              fullWidth
            >
              {ticketTypes &&
                ticketTypes?.map((ticketType, i) => (
                  <MenuItem key={`${ticketType}-${i}`} value={ticketType}>
                    {ticketType}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={10}
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={submitTicket} color="primary" disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
}

export default memo(TicketDialog);

import React, { useState, useEffect } from "react";
import {
  useCreateRoleMutation,
  useUpdateRoleMutation,
} from "../../../services/role";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const CreateEditRoleForm = ({
  open,
  onClose,
  role,
  refetchRoles,
  refetchRole,
  mode = "edit",
}) => {
  const [roleName, setRoleName] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [createRole, { isLoading: isCreating }] = useCreateRoleMutation();
  const [updateRole, { isLoading: isUpdating }] = useUpdateRoleMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (role) {
      setRoleName(role.name);
      setPermissions(role.permissions);
    }
  }, [role]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!roleName.trim() || permissions.some((perm) => !perm.action.trim())) {
      setNotification({
        open: true,
        message: "Please fill in all required fields.",
      });
      return;
    }

    let result;
    if (mode === "create") {
      result = await createRole({
        name: roleName,
        permissions,
      });
    } else if (mode === "edit" || mode === "editOne") {
      result = await updateRole({
        roleId: role?._id,
        name: roleName,
        permissions,
      });
    }
    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        if (mode === "editOne") {
          refetchRole?.();
        } else {
          refetchRoles?.();
        }
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error saving role: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleAddPermission = () => {
    setPermissions((prevPermissions) => [
      ...prevPermissions,
      { action: "", description: "" },
    ]);
  };

  const handlePermissionChange = (index, field, value) => {
    setPermissions((prevPermissions) =>
      prevPermissions.map((perm, i) =>
        i === index ? { ...perm, [field]: value } : perm
      )
    );
  };

  const handleRemovePermission = (index) => {
    setPermissions(permissions.filter((_, i) => i !== index));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      className="create-edit-dialog"
    >
      <DialogTitle>
        <Typography className="dialog-title">
          {mode === "create" ? "Create New Role" : "Edit Role"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} className="sub-items-list">
          <TextField
            className="sub-items-list-title"
            placeholder="Name"
            name="name"
            value={roleName}
            onChange={(event) => {
              setRoleName(event.target.value);
            }}
            fullWidth
            required
            margin="normal"
          />
          {permissions?.map((perm, index) => (
            <div key={index} className="sub-list-item">
              <TextField
                className="sub-list-item-subitem"
                label="Action"
                value={perm.action}
                fullWidth
                onChange={(e) =>
                  handlePermissionChange(index, "action", e.target.value)
                }
              />
              <TextField
                className="sub-list-item-subitem"
                label="Description"
                value={perm.description}
                fullWidth
                onChange={(e) =>
                  handlePermissionChange(index, "description", e.target.value)
                }
              />
              <Button onClick={() => handleRemovePermission(index)}>
                Remove
              </Button>
            </div>
          ))}
        </form>
        <Button onClick={handleAddPermission}>Add Permission</Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" type="submit" fullWidth>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
          disabled={isCreating || isUpdating}
        >
          {isCreating || isUpdating ? (
            <CircularProgress size={24} />
          ) : mode === "create" ? (
            "Create"
          ) : (
            "Save"
          )}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default CreateEditRoleForm;

import React from "react";
import { ListItem, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { truncateText } from "./sidebarHelpers";

function SidebarAdminItem({
  item,
  activePath,
  handleItemClick,
  isSmallDevice,
  isSmallestDevice,
  sidebarOpen,
}) {
  return (
    <Tooltip title={item?.title} placement="right">
      <ListItem
        button
        component={Link}
        className={`${activePath === item?.path ? "active" : "nav-link"} ${
          sidebarOpen ? "sidebar-open" : ""
        } ${item.title === "Forums" ? "forums" : ""}`}
        to={item?.path}
        onClick={() => handleItemClick(item?.path)}
      >
        <ListItemIcon sx={{ minWidth: "1.75rem" }} className="item-icon">
          {item?.icon}
        </ListItemIcon>
        {!isSmallDevice && sidebarOpen && (
          <ListItemText className="item-text" primary={item?.title} />
        )}
        {isSmallDevice && sidebarOpen && (
          <ListItemText className="item-text" primary={item?.title} />
        )}
        {isSmallDevice && isSmallestDevice && !sidebarOpen && (
          <ListItemText
            className="item-text truncated-text"
            primary={truncateText(item?.title, 3)}
          />
        )}
      </ListItem>
    </Tooltip>
  );
}

export default SidebarAdminItem;

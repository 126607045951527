import React from "react";
import { Dialog, IconButton, DialogContent } from "@mui/material";
import { Close } from "@mui/icons-material";
import PDFPreview from "./PDFPreview"; // Adjust import path as necessary
import ImagePreview from "./ImagePreview"; // Adjust import path as necessary

const PreviewModal = ({ open, onClose, src, isImage, isPDF }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <IconButton
        onClick={onClose}
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          margin: 16,
        }}
      >
        <Close />
      </IconButton>
      <DialogContent
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isImage ? (
          <ImagePreview
            src={src}
            alt="Preview"
            style={{ maxWidth: "100%", maxHeight: "80vh" }}
          />
        ) : isPDF ? (
          <PDFPreview
            src={src}
            style={{ maxWidth: "100%", maxHeight: "80vh" }}
          />
        ) : (
          <p>Unsupported file type</p>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PreviewModal;

import React, { useContext } from "react";
import { useGetAllErrorLogsQuery } from "../../../services/error-logs";
import { AuthContext } from "../../../context/AuthContext";
import { Container, Typography, Paper, Divider } from "@mui/material";
import ErrorLogsList from "./ErrorLogsList";

const ErrorLogsManagement = () => {
  const { accountType, userId } = useContext(AuthContext);
  const { data: errorLogs } = useGetAllErrorLogsQuery(accountType, {
    skip: !userId && accountType !== "reosadmin",
  });

  return (
    <Container className="management-page">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Error Logs Management
        </Typography>
        <Divider />
      </Paper>
      <ErrorLogsList errorLogs={errorLogs} />
    </Container>
  );
};

export default ErrorLogsManagement;

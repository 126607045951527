import React, { useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Tooltip,
  TextField,
  IconButton,
} from "@mui/material";
import { Send } from "@mui/icons-material/";
import { useCreateChatMutation } from "../../services/messages";
import NotificationSnackbar from "../helpers/notification-snackbar";

const MemberMessageForm = ({ open, onClose, member }) => {
  const { userId } = useContext(AuthContext);
  const memberId = member?.user || null;
  const [createChat] = useCreateChatMutation();
  const [selectedUserId, setSelectedUserId] = useState(memberId);
  const [message, setMessage] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleCreateChat = async () => {
    if (!message?.trim()) {
      setNotification({
        open: true,
        message: "Please fill the message text area.",
      });
      return;
    }

    if (selectedUserId) {
      const result = await createChat({
        senderId: userId,
        receiverId: selectedUserId,
        content: message,
      });
      if (result.data) {
        setNotification({
          open: true,
          message: `The message sent to member ${member?.firstName} ${member?.lastName}`,
        });
        setTimeout(() => {
          setSelectedUserId(null);
          setMessage("");
          onClose();
        }, 2000);
      } else {
        setNotification({
          open: true,
          message: `Error sending the message: ${result?.error?.data?.msg}`,
        });
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleCreateChat();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        Send message to member {member?.firstName} {member?.lastName}
      </DialogTitle>
      <DialogContent className="member-message">
        <div className="message-input">
          <TextField
            className="message-input-textarea"
            placeholder="New Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
            onKeyDown={handleKeyDown}
            multiline
            rows={4}
          />
          <Button onClick={onClose} className="message-cancel-button">
            Cancel
          </Button>
          <Tooltip title="Send Message">
            <IconButton
              className="message-send-button"
              onClick={handleCreateChat}
              disabled={!selectedUserId}
            >
              <Send />
            </IconButton>
          </Tooltip>
        </div>
      </DialogContent>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default MemberMessageForm;

import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  List,
  IconButton,
  Tooltip,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Send } from "@mui/icons-material/";
import {
  useGetMessagesByChatIdQuery,
  useMarkAsReadMessagesMutation,
  usePostMessageMutation,
} from "../../services/messages";
import MessageBlock from "./MessageBlock";
import { useSocket } from "../../context/SocketContext";
import { getFirstAndLastName } from "../../components/helpers/utils";

const ChatBlock = ({ chatId, userId, userName, isOnlyUser }) => {
  const [page, setPage] = useState(1);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const listRef = useRef(null);
  const [postMessage] = usePostMessageMutation();
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [markAsReadMessages] = useMarkAsReadMessagesMutation();
  const [initialLoad, setInitialLoad] = useState(true);
  const { socket } = useSocket();
  const { firstName, lastName } = getFirstAndLastName(userName);

  const {
    data: loadedMessages,
    isFetching,
    refetch,
  } = useGetMessagesByChatIdQuery({
    chatId,
    page,
    limit: 10,
  });
  const [isLoading, setIsLoading] = useState(false);

  const updateMessages = (newMessages) => {
    const nonDuplicateMessages = newMessages.filter(
      (newMsg) => !messages.some((msg) => msg._id === newMsg._id)
    );
    setMessages((prevMessages) => [...nonDuplicateMessages, ...prevMessages]);
  };

  const handleScroll = useCallback(
    async (event) => {
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

      if (scrollTop === 0 && !isFetching && !isLoading) {
        setIsLoading(true);
        try {
          setPage((prevPage) => prevPage + 1);
        } finally {
          setIsLoading(false);
        }
      }

      const isAtBottomNow = scrollHeight - scrollTop === clientHeight;
      if (isAtBottomNow !== isAtBottom) {
        setIsAtBottom(isAtBottomNow);
      }
    },
    [isFetching, isLoading, isAtBottom]
  );

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      const response = await postMessage({
        userId,
        senderId: userId,
        chatId: chatId,
        content: newMessage,
      });
      if (response?.data) {
        const sentMessage = {
          ...response.data,
          sender: {
            _id: userId,
            firstName: firstName,
            lastName: lastName,
          },
        };
        setMessages((prevMessages) => [...prevMessages, sentMessage]);
        scrollToBottom();
      }

      setNewMessage("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const scrollToBottom = useCallback(() => {
    requestAnimationFrame(() => {
      if (listRef.current) {
        listRef.current.scrollIntoView({ behavior: "smooth" });
        setIsAtBottom(true);
        markAsReadMessages({ chatId, userId });
      }
    });
  }, [chatId, userId, markAsReadMessages]);

  useEffect(() => {
    if (loadedMessages?.length) {
      if (initialLoad) {
        setMessages([...loadedMessages].reverse());
        setIsAtBottom(true);
        markAsReadMessages({ chatId, userId });
        setInitialLoad(false);
        scrollToBottom();
      } else {
        updateMessages([...loadedMessages].reverse());
      }
    } // eslint-disable-next-line
  }, [loadedMessages, initialLoad, scrollToBottom, markAsReadMessages]);

  useEffect(() => {
    const handleNewMessage = (data) => {
      if (data?.chatId === chatId) {
        const newMessageData = data?.message;
        if (!newMessageData) return;

        if (!messages.some((msg) => msg._id === newMessageData?._id)) {
          setMessages((prevMessages) => [...prevMessages, newMessageData]);

          if (isAtBottom) {
            scrollToBottom();
          }
        }
      }
    };

    const handleMessageDeleted = (deletedMessageId) => {
      setMessages((prevMessages) =>
        prevMessages.filter((msg) => msg._id !== deletedMessageId)
      );
    };

    socket.on("newMessage", handleNewMessage);
    socket.on("messageDeleted", handleMessageDeleted);

    return () => {
      socket.off("newMessage", handleNewMessage);
      socket.off("messageDeleted", handleMessageDeleted);
    };
  }, [chatId, userId, socket, messages, isAtBottom, scrollToBottom]);

  useEffect(() => {
    if (isAtBottom) {
      scrollToBottom();
    }
  }, [messages, isAtBottom, scrollToBottom]);

  useEffect(() => {
    let previousScrollHeight = 0;

    if (isLoading) {
      previousScrollHeight = listRef.current.scrollHeight;

      requestAnimationFrame(() => {
        const newHeight = listRef.current.scrollHeight - previousScrollHeight;

        listRef.current.scrollTop = listRef.current.scrollTop + newHeight;
      });
    }
  }, [messages, isLoading]);

  const groupMessagesByDate = (messages) => {
    return messages.reduce((groupedMessages, msg) => {
      const date = new Date(msg?.createdAt).toLocaleDateString();
      if (!groupedMessages[date]) {
        groupedMessages[date] = [];
      }
      groupedMessages[date].push(msg);
      return groupedMessages;
    }, {});
  };

  const renderMessages = () => {
    const groupedMessages = groupMessagesByDate(messages);
    return Object.entries(groupedMessages).map(([date, messagesForDate]) => (
      <React.Fragment key={date}>
        <div className="date-header">{date}</div>
        {messagesForDate.map((message, index, array) => (
          <MessageBlock
            key={message?._id}
            message={message}
            isCurrentUser={message?.sender?._id === userId}
            userId={userId}
            refetchMessages={refetch}
            ref={index === array?.length - 1 ? listRef : null}
          />
        ))}
      </React.Fragment>
    ));
  };

  return (
    <>
      <div
        className="message-container"
        ref={listRef}
        onScroll={(event) => {
          handleScroll(event);
        }}
      >
        {isLoading && (
          <div className="loading-spinner">
            <CircularProgress size={20} />
          </div>
        )}
        <List className="message-block">{renderMessages()}</List>
      </div>
      {!isOnlyUser && (
        <div className="message-input">
          <TextField
            className="message-input-textarea"
            placeholder="New Message"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            fullWidth
            onKeyDown={handleKeyDown}
            multiline
            rows={3}
          />
          <Tooltip title="Send Message">
            <IconButton
              className="message-input-button"
              onClick={handleSendMessage}
            >
              <Send />
            </IconButton>
          </Tooltip>
        </div>
      )}
      {isOnlyUser && (
        <div className="message-input">
          <Typography variant="subtitle1" sx={{ p: 5, textAlign: "center" }}>
            You are only one participant in this chat.
          </Typography>
        </div>
      )}
    </>
  );
};

export default ChatBlock;

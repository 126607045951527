import React from "react";
import { Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function CheckEmail() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Check Your Email
      </Typography>
      <Typography variant="body1" paragraph>
        If your email is in our system you will receive an email with a link to reset your password to your email address.
      </Typography>
      <Typography variant="body1" paragraph>
        Please check your email and follow the instructions to reset your password.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/login")}
      >
        Return to Login
      </Button>
    </div>
  );
}

export default CheckEmail;

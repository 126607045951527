import React, { useState } from "react";
import { titleCase } from "title-case";
import {
  useDeleteRoleFromUserMutation,
  useCreateInitialRoleWithPermissionsMutation,
} from "../../../services/role";
import {
  useRemoveTierFromRoleMutation,
  useRemoveViewerTierFromRoleMutation,
} from "../../../services/tier";
import {
  Paper,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { MoreVert, Delete, Edit } from "@mui/icons-material/";
import { ConfirmationDialog } from "../../../components";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import CreateEditRoleForm from "../role-management/CreateEditRoleForm";
import AssignTierToRoleDialog from "../tier-management/AssignTierToRoleDialog";
import AssignViewerTierToRoleDialog from "../tier-management/AssignViewerTierToRoleDialog";
import UpdateTierPermissionToRoleDialog from "../tier-management/UpdateTierPermissionToRoleDialog";

function RoleManager({ userId, userRole, accountType, refetch }) {
  const [createInitialRoleWithPermissions] =
    useCreateInitialRoleWithPermissionsMutation();
  const [deleteRoleFromUser] = useDeleteRoleFromUserMutation();
  const [removeTierFromRole] = useRemoveTierFromRoleMutation();
  const [removeViewerTierFromRole] = useRemoveViewerTierFromRoleMutation();
  const [roleHeaderMenuAnchorEl, setRoleHeaderMenuAnchorEl] = useState(null);
  const [tierHeaderMenuAnchorEl, setTierHeaderMenuAnchorEl] = useState(null);
  const [viewerTierHeaderMenuAnchorEl, setViewerTierHeaderMenuAnchorEl] =
    useState(null);
  const [userRoleId, setUserRoleId] = useState(null);
  const [tierIdToRemove, setTierIdToRemove] = useState(null);
  const [tierIdClicked, setTierIdClicked] = useState(null);
  const [viewerTierIdToRemove, setViewerTierIdToRemove] = useState(null);
  const [openCreateEditRoleDialog, setOpenCreateEditRoleDialog] =
    useState(false);
  const [openAssignTierToRoleDialog, setOpenAssignTierToRoleDialog] =
    useState(false);
  const [
    openUpdateTierPermissionForRoleDialog,
    setOpenUpdateTierPermissionForRoleDialog,
  ] = useState(false);
  const [
    openAssignViewerTierToRoleDialog,
    setOpenAssignViewerTierToRoleDialog,
  ] = useState(false);
  const [dialogState, setDialogState] = useState({
    open: false,
    context: "",
  });

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleCreateInitialRoleAndPermissionsClick = async () => {
    const result = await createInitialRoleWithPermissions(userId);
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `${result?.error?.data?.msg}`,
      });
    }
    refetch();
  };

  const handleDeleteRoleFromUserClick = async (roleId) => {
    setRoleHeaderMenuAnchorEl(null);
    setUserRoleId(roleId);
    setDialogState({ open: true, context: "deleteRole" });
  };

  const handleDialogConfirm = async () => {
    if (dialogState.context === "deleteRole") {
      const result = await deleteRoleFromUser({ roleId: userRoleId, userId });
      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
      } else {
        setNotification({
          open: true,
          message: `${result?.error?.data?.msg}`,
        });
      }
    } else if (dialogState.context === "deleteTier") {
      const result = await removeTierFromRole({
        roleId: userRole?._id,
        tierId: tierIdToRemove,
      });
      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
      } else {
        setNotification({
          open: true,
          message: `${result?.error?.data?.msg}`,
        });
      }
    } else if (dialogState.context === "deleteViewerTier") {
      const result = await removeViewerTierFromRole({
        roleId: userRole?._id,
        viewerTierId: viewerTierIdToRemove,
      });
      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
      } else {
        setNotification({
          open: true,
          message: `${result?.error?.data?.msg}`,
        });
      }
    }
    setTimeout(() => {
      setDialogState({ ...dialogState, open: false });
      refetch();
    }, 1500);
  };

  const handleDeleteTierFromRoleClick = async (tierId) => {
    setTierHeaderMenuAnchorEl(null);
    setTierIdToRemove(tierId);
    setDialogState({ open: true, context: "deleteTier" });
  };

  const handleDeleteViewerTierFromRoleClick = async (tierId) => {
    setViewerTierHeaderMenuAnchorEl(null);
    setViewerTierIdToRemove(tierId);
    setDialogState({ open: true, context: "deleteViewerTier" });
  };

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setRoleHeaderMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setRoleHeaderMenuAnchorEl(null);
  };

  const handleTierMenuOpen = (event) => {
    event.stopPropagation();
    setTierHeaderMenuAnchorEl(event.currentTarget);
  };

  const handleTierMenuClose = () => {
    setTierHeaderMenuAnchorEl(null);
  };

  const handleViewerTierMenuOpen = (event) => {
    event.stopPropagation();
    setViewerTierHeaderMenuAnchorEl(event.currentTarget);
  };

  const handleViewerTierMenuClose = () => {
    setViewerTierHeaderMenuAnchorEl(null);
  };

  const handleOpenCreateEditRoleDialog = () => {
    setRoleHeaderMenuAnchorEl(null);
    setOpenCreateEditRoleDialog(true);
  };

  const handleCloseCreateEditRoleDialog = () => {
    setOpenCreateEditRoleDialog(false);
  };

  const handleOpenAssignTierToRoleDialog = () => {
    setOpenAssignTierToRoleDialog(true);
  };

  const handleCloseAssignTierToRoleDialog = () => {
    setOpenAssignTierToRoleDialog(false);
  };

  const handleOpenUpdateTierPermissionForRoleDialog = (tierId) => {
    setTierIdClicked(tierId);
    setOpenUpdateTierPermissionForRoleDialog(true);
  };

  const handleCloseUpdateTierPermissionForRoleDialog = () => {
    setOpenUpdateTierPermissionForRoleDialog(false);
    setTierIdClicked(null);
  };

  const handleOpenAssignViewerTierToRoleDialog = () => {
    setOpenAssignViewerTierToRoleDialog(true);
  };

  const handleCloseAssignViewerTierToRoleDialog = () => {
    setOpenAssignViewerTierToRoleDialog(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };
  console.log("userRole", userRole);
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
        }}
      >
        <Typography variant="h6">Role:</Typography>
        <Button
          variant="outlined"
          onClick={handleCreateInitialRoleAndPermissionsClick}
        >
          Create Initial Role with Permissions
        </Button>
      </Box>
      {userRole && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 2,
            width: "100%",
          }}
        >
          <Grid container direction="column">
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  p: 2,
                  mb: 2,
                }}
              >
                <Typography variant="h4">
                  Account Type: {accountType}
                </Typography>
                <Tooltip title="Handle Role">
                  <IconButton
                    className="menu-button"
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleMenuOpen(event);
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="menu-role"
                  anchorEl={roleHeaderMenuAnchorEl}
                  open={Boolean(roleHeaderMenuAnchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem>
                    <Tooltip title="Edit Role">
                      <IconButton
                        edge="end"
                        aria-label="edit-role"
                        onClick={handleOpenCreateEditRoleDialog}
                      >
                        <Edit />
                        <Typography ml={1}>Edit Role</Typography>
                      </IconButton>
                    </Tooltip>
                  </MenuItem>
                  <MenuItem>
                    <Tooltip title="Delete Role">
                      <IconButton
                        edge="end"
                        aria-label="delete-role"
                        onClick={() =>
                          handleDeleteRoleFromUserClick(userRole?._id)
                        }
                      >
                        <Delete />
                        <Typography ml={1}>Delete Role</Typography>
                      </IconButton>
                    </Tooltip>
                  </MenuItem>
                </Menu>
              </Box>
            </Grid>
            <Grid item container direction="column">
              <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table className="user-table" sx={{ border: "solid 1px" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Typography variant="h5" gutterBottom>
                          <strong>Current Assigned Role: </strong>
                          {titleCase(userRole?.permissions[0]?.role)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                          Permissions
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userRole?.permissions?.map((perm) => (
                      <TableRow key={perm?._id}>
                        <TableCell sx={{ pl: 10 }}>{perm?.action}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper}>
                <Table className="user-table" sx={{ border: "solid 1px" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "bolder" }}
                            gutterBottom
                          >
                            User's Tiers:
                          </Typography>
                          <Tooltip title="Handle Tier">
                            <IconButton
                              className="menu-tier-button"
                              aria-label="more"
                              aria-controls="long-tier-menu"
                              aria-haspopup="true"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleTierMenuOpen(event);
                              }}
                            >
                              <MoreVert />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            id="menu-tier"
                            anchorEl={tierHeaderMenuAnchorEl}
                            open={Boolean(tierHeaderMenuAnchorEl)}
                            onClose={handleTierMenuClose}
                          >
                            <MenuItem>
                              <Tooltip title="Edit Tier">
                                <IconButton
                                  edge="end"
                                  aria-label="edit"
                                  onClick={handleOpenAssignTierToRoleDialog}
                                >
                                  <Edit />
                                  <Typography ml={1}>
                                    Assign Tier to Role
                                  </Typography>
                                </IconButton>
                              </Tooltip>
                            </MenuItem>
                          </Menu>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead className="table-header">
                    <TableRow>
                      <TableCell>Tier</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userRole?.tiers ? (
                      userRole?.tiers?.map((tier) => (
                        <TableRow key={tier?._id}>
                          <TableCell>
                            {`${titleCase(tier?.level)} ${
                              tier?.brokerage ? tier?.brokerage?.officeName : ""
                            } ${
                              tier?._id === tier?.brokerage?.leadBrokerageTier
                                ? " - All Offices"
                                : ""
                            } ${tier?._id === tier?.brokerage?.tier ? "" : ""}`}
                            {userRole?.tierPermissions &&
                              userRole?.tierPermissions
                                ?.filter((tp) => tp?.tier?._id === tier?._id)
                                ?.map(
                                  (tp) =>
                                    tp?.permissions &&
                                    tp?.permissions?.map((tpi) => (
                                      <span key={`perm-${tpi?._id}`}>
                                        <small>{` ${tpi?.perm};`}</small>
                                      </span>
                                    ))
                                )}
                          </TableCell>
                          <TableCell>{titleCase(tier?.features)}</TableCell>
                          <TableCell>
                            <Tooltip title="Update Tier Permission for Role">
                              <IconButton
                                edge="end"
                                aria-label="edit"
                                onClick={() =>
                                  handleOpenUpdateTierPermissionForRoleDialog(
                                    tier?._id
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete Tier">
                              <IconButton
                                edge="end"
                                aria-label="delete-tier"
                                onClick={() =>
                                  handleDeleteTierFromRoleClick(tier?._id)
                                }
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography variant="h4">
                            There are no tiers yet
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper}>
                <Table
                  className="user-table"
                  sx={{ border: "solid 1px", marginTop: "1rem" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{ fontWeight: "bolder" }}
                            gutterBottom
                          >
                            User's Viewer Tiers:
                          </Typography>
                          <Tooltip title="Handle Viewer Tier">
                            <IconButton
                              className="menu-tier-button"
                              aria-label="more"
                              aria-controls="long-tier-menu"
                              aria-haspopup="true"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleViewerTierMenuOpen(event);
                              }}
                            >
                              <MoreVert />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            id="menu-tier"
                            anchorEl={viewerTierHeaderMenuAnchorEl}
                            open={Boolean(viewerTierHeaderMenuAnchorEl)}
                            onClose={handleViewerTierMenuClose}
                          >
                            <MenuItem>
                              <Tooltip title="Assign Viewer Tier To Role">
                                <IconButton
                                  edge="end"
                                  aria-label="edit"
                                  onClick={
                                    handleOpenAssignViewerTierToRoleDialog
                                  }
                                >
                                  <Edit />
                                  <Typography ml={1}>
                                    Assign Viewer Tier to Role
                                  </Typography>
                                </IconButton>
                              </Tooltip>
                            </MenuItem>
                          </Menu>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead className="table-header">
                    <TableRow>
                      <TableCell>Viewer Tier</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userRole?.viewerTiers ? (
                      userRole?.viewerTiers?.map((tier) => (
                        <TableRow key={tier?._id}>
                          <TableCell>
                            {`${titleCase(tier?.level)} ${
                              tier?.brokerage ? tier?.brokerage?.officeName : ""
                            } ${
                              tier?._id === tier?.brokerage?.leadBrokerageTier
                                ? " - All Offices"
                                : ""
                            } ${tier?._id === tier?.brokerage?.tier ? "" : ""}`}
                            {userRole?.tierPermissions &&
                              userRole?.tierPermissions
                                ?.filter((tp) => tp?.tier?._id === tier?._id)
                                ?.map(
                                  (tp) =>
                                    tp?.permissions &&
                                    tp?.permissions?.map((tpi) => (
                                      <span key={`perm-${tpi?._id}`}>
                                        <small>{` ${tpi?.perm};`}</small>
                                      </span>
                                    ))
                                )}
                          </TableCell>
                          <TableCell>{titleCase(tier?.features)}</TableCell>
                          <TableCell>
                            <Tooltip title="Delete Viewer Tier">
                              <IconButton
                                edge="end"
                                aria-label="delete-tier"
                                onClick={() =>
                                  handleDeleteViewerTierFromRoleClick(tier?._id)
                                }
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography variant="h4">
                            There are no tiers yet
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      )}
      {!userRole && (
        <Typography variant="body1" sx={{ p: 2 }}>
          There is no role yet
        </Typography>
      )}
      <CreateEditRoleForm
        mode="editOne"
        refetchRole={refetch}
        role={userRole}
        open={openCreateEditRoleDialog}
        onClose={handleCloseCreateEditRoleDialog}
      />
      <AssignTierToRoleDialog
        preselectedRole={userRole}
        open={openAssignTierToRoleDialog}
        onClose={handleCloseAssignTierToRoleDialog}
      />
      <UpdateTierPermissionToRoleDialog
        roleId={userRole?._id}
        tierId={tierIdClicked}
        open={openUpdateTierPermissionForRoleDialog}
        onClose={handleCloseUpdateTierPermissionForRoleDialog}
        existingPermissions={
          userRole?.tierPermissions?.find(
            (tp) => tp?.tier?._id === tierIdClicked
          )?.permissions || []
        }
      />
      <AssignViewerTierToRoleDialog
        preselectedRole={userRole}
        open={openAssignViewerTierToRoleDialog}
        onClose={handleCloseAssignViewerTierToRoleDialog}
      />
      <ConfirmationDialog
        open={dialogState.open}
        onClose={() => setDialogState({ ...dialogState, open: false })}
        onConfirm={handleDialogConfirm}
        title={
          dialogState.context === "deleteRole"
            ? "Confirm Role Deletion"
            : dialogState.context === "deleteTier"
            ? "Confirm Tier Deletion"
            : dialogState.context === "deleteViewerTier"
            ? "Confirm Viewer Tier Deletion"
            : dialogState.context
        }
        description={
          dialogState.context === "deleteRole"
            ? "Confirm Role Deletion"
            : dialogState.context === "deleteTier"
            ? "Confirm Tier Deletion"
            : dialogState.context === "deleteViewerTier"
            ? "Confirm Viewer Tier Deletion"
            : dialogState.context
        }
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </div>
  );
}

export default RoleManager;

import React, { useState } from "react";
import {
  useFetchAllArchivedUsersQuery,
  useRestoreUserMutation,
  useDeleteUserMutation,
} from "../../../services/user";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  TableSortLabel,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import {
  MoreVert,
  RestoreFromTrash,
  DeleteForever,
} from "@mui/icons-material/";
import { ConfirmationDialog } from "../..";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../helpers/utils";
import NotificationSnackbar from "../../helpers/notification-snackbar";

function ArchiveUserList() {
  const { data: users, refetch } = useFetchAllArchivedUsersQuery();
  const navigate = useNavigate();
  const [restoreUser] = useRestoreUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userIdToRestore, setUserIdToRestore] = useState(null);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [currentUserClicked, setCurrentUserClick] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [sortField, setSortField] = useState("email");
  const [sortDirection, setSortDirection] = useState("asc");

  const [statusFilter, setStatusFilter] = useState("");
  const [accountTypeFilter, setAccountTypeFilter] = useState("");

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleRestoreUser = async (id) => {
    const result = await restoreUser(id);
    if (result.error) {
      setNotification({
        open: true,
        message: `Error restoring user. ${result?.error?.data?.msg}`,
      });
    } else {
      setNotification({ open: true, message: "User restored successfully." });
      refetch();
    }
  };

  const handleDeleteUser = async (id) => {
    const result = await deleteUser(id);
    if (result.error) {
      setNotification({
        open: true,
        message: `Error Deleting User. ${result?.error?.data?.msg}`,
      });
    } else {
      setNotification({ open: true, message: "User deleted successfully." });
      refetch();
    }
  };

  const handleRowClick = (event, userId) => {
    if (
      event.target.closest(".menu-button") ||
      event.target.closest(".view-details-button")
    ) {
      setCurrentUserClick(userId);
      return;
    }
    navigate(`/user/${userId}`);
  };

  const handleMenuOpen = (event, userId) => {
    setCurrentUserClick(userId);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRestoreClick = (event, userId) => {
    event.stopPropagation();
    setUserIdToRestore(userId);
    setActionType("restore");
    setDialogOpen(true);
  };

  const handleDeleteClick = (event, userId) => {
    event.stopPropagation();
    setUserIdToDelete(userId);
    setActionType("delete");
    setDialogOpen(true);
  };

  const handleDialogClose = (event) => {
    event && event.stopPropagation();
    setDialogOpen(false);
    setUserIdToRestore(null);
  };

  const handleDialogConfirm = (event) => {
    event && event.stopPropagation();
    if (actionType === "restore") {
      handleRestoreUser(userIdToRestore);
    } else if (actionType === "delete") {
      handleDeleteUser(userIdToDelete);
    }
    handleDialogClose();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  if (!users || users?.length === 0) {
    return (
      <Typography variant="h5" m="2rem 0.1rem 0.5rem 5rem">
        No archived users available
      </Typography>
    );
  }

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleAccountTypeFilterChange = (event) => {
    setAccountTypeFilter(event.target.value);
  };

  let filteredUsers = [...users];
  if (statusFilter) {
    filteredUsers = filteredUsers?.filter(
      (user) => user?.status === statusFilter
    );
  }
  if (accountTypeFilter) {
    filteredUsers = filteredUsers?.filter(
      (user) => user?.accountType === accountTypeFilter
    );
  }

  const sortedUsers = filteredUsers?.sort((a, b) => {
    if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
    if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
    return 0;
  });

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        mt={5}
      >
        <Typography variant="h4" component="h1">
          REOS Admin Dashboard
        </Typography>
      </Box>
      <Box sx={{ marginBottom: "10px", display: "flex", gap: "10px" }}>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          sx={{ minWidth: 120 }}
        >
          <InputLabel id="status-filter-label">Filter by status</InputLabel>
          <Select
            labelId="status-filter-label"
            value={statusFilter}
            onChange={handleStatusFilterChange}
            label="Filter by status"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          sx={{ minWidth: 120 }}
        >
          <InputLabel id="account-type-filter-label">
            Filter by account type
          </InputLabel>
          <Select
            labelId="account-type-filter-label"
            value={accountTypeFilter}
            onChange={handleAccountTypeFilterChange}
            label="Filter by account type"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="realtyadmin">RealtyOS Admin</MenuItem>
            <MenuItem value="realtor">Realtor</MenuItem>
            <MenuItem value="client">Client</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortField === "email"}
                  direction={sortDirection}
                  onClick={() => handleSort("email")}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "firstName"}
                  direction={sortDirection}
                  onClick={() => handleSort("firstName")}
                >
                  First-Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "lastName"}
                  direction={sortDirection}
                  onClick={() => handleSort("lastName")}
                >
                  Last Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "accountType"}
                  direction={sortDirection}
                  onClick={() => handleSort("accountType")}
                >
                  Account Type
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "status"}
                  direction={sortDirection}
                  onClick={() => handleSort("status")}
                >
                  Status
                </TableSortLabel>
              </TableCell>

              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(sortedUsers) &&
              sortedUsers?.map(
                (user) =>
                  user?.isArchived && (
                    <TableRow
                      key={user?._id}
                      onClick={(event) => handleRowClick(event, user?._id)}
                      style={{ cursor: "pointer" }}
                      className="table-row"
                    >
                      <TableCell>{user?.email}</TableCell>
                      <TableCell>
                        {capitalizeFirstLetter(user?.firstName)}
                      </TableCell>
                      <TableCell>
                        {capitalizeFirstLetter(user?.lastName)}
                      </TableCell>
                      <TableCell>{user?.accountType}</TableCell>
                      <TableCell>{user?.status}</TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            aria-label="delete"
                            onClick={(event) =>
                              handleRestoreClick(event, user?._id)
                            }
                          >
                            <RestoreFromTrash />
                          </IconButton>
                          <IconButton
                            className="menu-button"
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(event) =>
                              handleMenuOpen(event, user?._id)
                            }
                          >
                            <MoreVert />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            open={open && currentUserClicked === user._id}
                            onClose={handleMenuClose}
                          >
                            <MenuItem>
                              <Typography
                                variant="body1"
                                className="view-details-button"
                                onClick={() =>
                                  navigate(`/user/${currentUserClicked}`)
                                }
                              >
                                View Details
                              </Typography>
                            </MenuItem>
                            <MenuItem>
                              <IconButton
                                aria-label="delete"
                                onClick={(event) =>
                                  handleDeleteClick(event, user?._id)
                                }
                              >
                                <DeleteForever /> remove
                              </IconButton>
                            </MenuItem>
                          </Menu>
                          <ConfirmationDialog
                            open={dialogOpen}
                            onClose={handleDialogClose}
                            onConfirm={handleDialogConfirm}
                            title={
                              actionType === "restore"
                                ? "Confirm Restoring the User"
                                : "Confirm Deleting the User"
                            }
                            description={
                              actionType === "restore"
                                ? "Are you sure you want to restore this user?"
                                : "Are you sure you want to delete this user permanently?"
                            }
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
              )}
          </TableBody>
        </Table>
        <NotificationSnackbar
          open={notification.open}
          message={notification.message}
          onClose={handleClose}
        />
      </TableContainer>
    </>
  );
}

export default ArchiveUserList;

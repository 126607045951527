import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const teamApi = createApi({
  reducerPath: "team",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/teams/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Team"],
  endpoints: (builder) => ({
    createTeam: builder.mutation({
      query: ({ name, memberId, brokerageId }) => ({
        url: "create",
        method: "POST",
        body: { name, memberId, brokerageId },
      }),
      invalidatesTags: [{ type: "Team", id: "LIST" }],
    }),
    updateTeam: builder.mutation({
      query: ({ name, memberId, teamId, brokerageId }) => ({
        url: `update/${teamId}`,
        method: "PUT",
        body: { name, memberId, brokerageId },
      }),
      invalidatesTags: [{ type: "Team", id: "LIST" }],
    }),
    getAllTeams: builder.query({
      query: () => ({
        url: "all-teams",
        method: "GET",
      }),
      providesTags: [{ type: "Team", id: "LIST" }],
    }),
    getAllTeamMembers: builder.query({
      query: (teamId) => ({
        url: `all-team-members/${teamId}`,
        method: "GET",
      }),
      skip: (teamId) => !teamId,
      providesTags: [{ type: "Team", id: "LIST" }],
    }),
    getTeamById: builder.query({
      query: (teamId) => ({
        url: `get-one-by-id/${teamId}`,
        method: "GET",
      }),
      skip: (teamId) => !teamId,
      providesTags: (result, error, teamId) => [{ type: "Team", id: teamId }],
    }),
    getTeamByBrokerage: builder.query({
      query: (brokerageId) => ({
        url: `get-one-by-brokerage/${brokerageId}`,
        method: "GET",
      }),
      providesTags: [{ type: "Team", id: "LIST" }],
    }),
    getTeamByTier: builder.query({
      query: (tierId) => ({
        url: `get-one-by-tier/${tierId}`,
        method: "GET",
      }),
      providesTags: [{ type: "Team", id: "LIST" }],
    }),
    addExistingMember: builder.mutation({
      query: ({ teamId, memberId, roleId }) => ({
        url: "add-existing-member",
        method: "POST",
        body: { teamId, memberId, roleId },
      }),
    }),
    deleteMember: builder.mutation({
      query: ({ teamId, memberId }) => ({
        url: `remove-member`,
        method: "DELETE",
        body: { teamId, memberId },
      }),
    }),
    deleteTeam: builder.mutation({
      query: ({ teamId }) => ({
        url: `delete/${teamId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Team", id: "LIST" }],
    }),
    inviteNewMember: builder.mutation({
      query: ({ memberEmail, userId, teamId, tierId, teamRoleId }) => ({
        url: `invite-new-member/`,
        method: "POST",
        body: { memberEmail, userId, teamId, tierId, teamRoleId },
      }),
      invalidatesTags: ["Invitation"],
    }),
    signupNewMember: builder.mutation({
      query: (memberData) => ({
        url: `register-new-member-to-team/`,
        method: "POST",
        body: memberData,
      }),
    }),
  }),
});

export const {
  useGetTeamByIdQuery,
  useGetTeamByBrokerageQuery,
  useGetTeamByTierQuery,
  useGetAllTeamsQuery,
  useGetAllTeamMembersQuery,
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useAddExistingMemberMutation,
  useDeleteTeamMutation,
  useDeleteMemberMutation,
  useInviteNewMemberMutation,
  useSignupNewMemberMutation,
} = teamApi;

export default teamApi;
export { teamApi };

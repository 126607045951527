import React, { useState, useContext } from "react";
import { titleCase } from "title-case";
import { useMigratePostToNewCategoryMutation } from "../../services/migration";
import { useGetCategoriesFromUserRoleTiersCollectionQuery } from "../../services/category";
import { AuthContext } from "../../context/AuthContext";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Autocomplete,
} from "@mui/material";
import NotificationSnackbar from "../../components/helpers/notification-snackbar";

const MiragePostToNewCategoryForm = ({ open, onClose, post }) => {
  const { userId } = useContext(AuthContext);
  const [migratePostToNewCategory, { isLoading }] =
    useMigratePostToNewCategoryMutation();
  const { data: categories } =
    useGetCategoriesFromUserRoleTiersCollectionQuery(userId);

  const [newCategory, setNewCategory] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const excludedTypes = ["iframe", "files", "poll", "chat", "common"];

  const transformedCategories = (categories || []).reduce((acc, tier) => {
    const tierLabel = `${titleCase(tier.tierName)} - ${tier.tierBrokerageName}`;
    tier.categories.forEach((category) => {
      if (!excludedTypes.includes(category.type)) {
        acc.push({
          ...category,
          tierLabel,
        });
      }
    });
    return acc;
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      (newCategory && !newCategory?._id?.trim()) ||
      (post && !post?._id?.trim())
    ) {
      setNotification({
        open: true,
        message: "Please fill in all required fields.",
      });
      return;
    }
    const result = await migratePostToNewCategory({
      newCategoryId: newCategory?._id,
      postId: post?._id,
    });
    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        setNewCategory(null);
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error: ${result.error.data?.msg || "Unknown error"}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth className="edit-post">
      <DialogTitle>
        <Typography>Migrate Post To New Category </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid item>
            <Autocomplete
              value={newCategory}
              onChange={(event, newValue) => {
                setNewCategory(newValue);
              }}
              options={transformedCategories}
              groupBy={(option) => option.tierLabel}
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={(option, value) =>
                option?._id === value._id
              }
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a Category"
                  variant="outlined"
                  margin="normal"
                  required
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option._id}>
                  {option.name}
                </li>
              )}
            />
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid item>
          <Button onClick={onClose} color="secondary" type="submit" fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Migrate"}
          </Button>
        </Grid>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default MiragePostToNewCategoryForm;

import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { formatDate } from "../../../helpers/utils"; // Add this helper function if needed

const SubscriptionTable = ({
  subscriptions,
  page,
  rowsPerPage,
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleEditClick,
  handleDeleteClick,
}) => {
  const [hoveredDeleteId, setHoveredDeleteId] = useState(null);
  const rowColour = "#ffffff"; // Alternate row color
  const rowColourAlt = "#f6f6f6"; // Alternate row color

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Plan</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Billing Cycle</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptions
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((subscription, index) => (
              <TableRow
                key={`${subscription._id}${index}`}
                sx={{
                  backgroundColor: index % 2 === 0 ? rowColour : rowColourAlt, // Alternates row colors
                }}
              >
                <TableCell>
                  {subscription.tier?.brokerage?.officeName ||
                    subscription.account.firstName +
                      " " +
                      subscription.account.lastName}
                </TableCell>
                <TableCell>{subscription.description}</TableCell>
                <TableCell>{subscription.plan.name}</TableCell>
                <TableCell>
                  {subscription.startDate
                    ? formatDate(subscription.startDate, true) // true = noTime
                    : "N/A"}
                </TableCell>
                <TableCell>
                  {subscription.endDate
                    ? formatDate(subscription.endDate, true) // true = noTime
                    : "N/A"}
                </TableCell>
                <TableCell>{subscription.billingCycle}</TableCell>
                <TableCell>{subscription.status}</TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton onClick={() => handleEditClick(subscription)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => handleDeleteClick(subscription)}
                      onMouseEnter={() => setHoveredDeleteId(subscription._id)}
                      onMouseLeave={() => setHoveredDeleteId(null)}
                    >
                      <Delete
                        sx={{
                          color:
                            hoveredDeleteId === subscription._id
                              ? "red"
                              : "inherit",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={subscriptions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default SubscriptionTable;

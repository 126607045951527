import React from "react";
import { List } from "@mui/material";

function TierItem({ tier, handleItemClick, renderCategoryGroups }) {
  return (
    <React.Fragment key={tier?.tierId}>
      <List component="div" disablePadding>
        {Object.entries(tier?.groups)?.map(([groupName, categories]) =>
          renderCategoryGroups(
            tier?.tierId,
            groupName,
            categories,
            handleItemClick
          )
        )}
      </List>
    </React.Fragment>
  );
}

export default TierItem;

import React, { useState, useContext } from "react";
import { SidebarContext } from "../../../context/SidebarContext";
import { AuthContext } from "../../../context/AuthContext";
import { useGetMemberProfileQuery } from "../../../services/member";
import {
  Typography,
  IconButton,
  Tooltip,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import { MapsUgc, Edit, Assignment } from "@mui/icons-material/";
import { ProfilePageBadge, formatDate } from "../../helpers/utils";
import MemberProfileTabs from "../../members/member-profile-tabs/MemberProfileTabs";
import MemberMessageForm from "../../members/MemberMessageForm";
import MemberProfileEditForm from "../../members/MemberProfileEditForm";
import AssignBrokerageToMemberDialog from "../user-management/AssignDefaultBrokerageToMemberDialog";

const MemberProfile = ({ userMemberId }) => {
  const { userId, accountType, isAdmin } = useContext(AuthContext);
  const [isCreateChatOpen, setIsCreateChatOpen] = useState(false);
  const { sidebarOpen } = useContext(SidebarContext);
  const {
    data: member,
    isLoading,
    refetch,
  } = useGetMemberProfileQuery(userMemberId, {
    skip: !userMemberId,
  });
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [memberProfileEditFrom, setMemberProfileEditFrom] = useState(false);
  const [
    openAssignBrokerageToMemberDialog,
    setOpenAssignBrokerageToMemberDialog,
  ] = useState(false);

  const handleSaveSuccess = () => {
    setIsEditFormOpen(false);
    refetch();
  };

  const handleOpenAssignBrokerageToMemberDialog = () => {
    setOpenAssignBrokerageToMemberDialog(true);
  };

  const handleCloseAssignBrokerageToMemberDialog = () => {
    setOpenAssignBrokerageToMemberDialog(false);
  };

  const isAuthor = member?.user === userId;
  const isAuthorOrAdmin =
    isAuthor || accountType === "reosadmin" || accountType === "brokerage";

  if (isLoading)
    return (
      <div className="loading-spinner">
        <CircularProgress size={24} />
      </div>
    );

  return (
    <div className="member-profile-manager">
      <div className="member-profile-body">
        <div
          className={`${
            !sidebarOpen
              ? "member-profile-header sidebar-opened"
              : "member-profile-header"
          }`}
        >
          <div className="header-profile-image-block">
            {member?.isOnline && (
              <ProfilePageBadge
                variant="dot"
                color="success"
                invisible={!member?.isOnline}
                className="header-profile-avatar"
              >
                <CardMedia
                  component="img"
                  className="member-profile-image"
                  image={member?.image?.url}
                  alt={`${member?.firstName} ${member?.lastName}`}
                  sx={{ width: "7rem" }}
                />
                <span className="header-profile-avatar-span">Online</span>
              </ProfilePageBadge>
            )}
            {!member?.isOnline && (
              <>
                <CardMedia
                  component="img"
                  className="member-profile-image"
                  image={member?.image?.url}
                  alt={`${member?.firstName} ${member?.lastName}`}
                  sx={{ width: "7rem" }}
                />
                <span className="header-profile-avatar-offline-span">
                  Last seen: {formatDate(member?.lastOnlineTime)}
                </span>
              </>
            )}
          </div>
          <div className="header-profile-title">
            <Typography
              gutterBottom
              component="div"
              className="header-profile-title-name"
            >
              {member?.firstName} {member?.lastName}
            </Typography>
            <Typography
              gutterBottom
              component="div"
              className="header-profile-title-profile-title"
            >
              {member?.profileTitle || "No profile title"}
            </Typography>
            <div className="actions-block">
              <Tooltip title="Message Member">
                <IconButton
                  className="create-chat-button"
                  aria-label="create-chat"
                  onClick={() => setIsCreateChatOpen(true)}
                  size="large"
                >
                  <MapsUgc />
                </IconButton>
              </Tooltip>
              {isAuthorOrAdmin && (
                <Tooltip title="Edit Profile">
                  <IconButton
                    className="edit-profile-button"
                    aria-label="edit-profile"
                    onClick={() => {
                      setMemberProfileEditFrom(true);
                      setIsEditFormOpen(true);
                    }}
                    size="large"
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
              )}
              {isAdmin && (
                <Tooltip title="Assign Brokerage to current Member">
                  <IconButton
                    className="edit-profile-button"
                    aria-label="edit-profile"
                    onClick={handleOpenAssignBrokerageToMemberDialog}
                    size="large"
                  >
                    <Assignment />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <MemberProfileTabs member={member} />
      </div>
      <MemberMessageForm
        open={isCreateChatOpen}
        onClose={() => setIsCreateChatOpen(false)}
        member={member}
      />
      {
        <AssignBrokerageToMemberDialog
          preselectedMember={member}
          isMemberProfileOpened={true}
          open={openAssignBrokerageToMemberDialog}
          onClose={handleCloseAssignBrokerageToMemberDialog}
        />
      }
      {isEditFormOpen && (
        <MemberProfileEditForm
          userMemberId={userMemberId}
          open={isEditFormOpen}
          onClose={handleSaveSuccess}
          memberProfileEditFrom={memberProfileEditFrom}
        />
      )}
    </div>
  );
};

export default MemberProfile;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const invoiceApi = createApi({
  reducerPath: "invoice",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/billing/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Invoice"],
  endpoints: (builder) => ({
    // Invoice Endpoints
    fetchInvoices: builder.query({
      query: () => "invoices",
      providesTags: ["Invoice"],
    }),
    fetchInvoiceById: builder.query({
      query: (_id) => `invoices/${_id}`,
      providesTags: ["Invoice"],
    }),

    GetAllInvoicesByTierId: builder.query({
      query: (tierId) => `invoices/getInvoicesByTierId/${tierId}`,
      providesTags: ["Tier"],
    }),

    GetAllInvoicesByAccountId: builder.query({
      query: (accountId) => `invoices/getInvoicesByAccountId/${accountId}`,
      providesTags: ["Tier"],
    }),

    GetUserCountByTierId: builder.query({
      query: (_id) => `invoices/getCount/${_id}`,
      providesTags: ["Tier"],
    }),

    createInvoice: builder.mutation({
      query: (invoiceData) => ({
        url: "invoices",
        method: "POST",
        body: invoiceData,
      }),
      invalidatesTags: ["Invoice"],
    }),
    updateInvoice: builder.mutation({
      query: ({ _id, ...invoiceData }) => ({
        url: `invoices/${_id}`,
        method: "PUT",
        body: invoiceData,
      }),
      invalidatesTags: ["Invoice"],
    }),
    deleteInvoice: builder.mutation({
      query: (id) => ({
        url: `invoices/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Invoice"],
    }),
  }),
});

export const {
  // Invoice Hooks
  useFetchInvoicesQuery,
  useFetchInvoiceByIdQuery,
  useGetAllInvoicesByTierIdQuery,
  useGetAllInvoicesByAccountIdQuery,
  useGetUserCountByTierIdQuery,
  useCreateInvoiceMutation,
  useUpdateInvoiceMutation,
  useDeleteInvoiceMutation,
} = invoiceApi;

export default invoiceApi;

import React, { useState } from "react";
import { Typography, IconButton, Box } from "@mui/material";
import { Edit } from "@mui/icons-material";
import NotificationSnackbar from "../../../helpers/notification-snackbar";
import CreateEditDutyScheduleForm from "./CreateEditDutyScheduleForm";

function FlooorDutyManagerMenu({ tierId }) {
  const [
    openCreateEditDutyScheduleDialog,
    setOpenCreateEditDutyScheduleDialog,
  ] = useState(false);

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleOpenCreateEditDutyScheduleDialog = () => {
    setOpenCreateEditDutyScheduleDialog(true);
  };

  const handleCloseCreateEditDutyScheduleDialog = () => {
    setOpenCreateEditDutyScheduleDialog(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Box className="user-management-box">
      <Box>
        <IconButton
          onClick={() => {
            handleOpenCreateEditDutyScheduleDialog();
          }}
        >
          <Edit />
          <Typography ml={1}>Create New Duty Schedule</Typography>
        </IconButton>
      </Box>
      <CreateEditDutyScheduleForm
        mode={"create"}
        tierId={tierId}
        open={openCreateEditDutyScheduleDialog}
        onClose={handleCloseCreateEditDutyScheduleDialog}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Box>
  );
}
export default FlooorDutyManagerMenu;

import React, { useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import {
  useGetAllFreeRolesQuery,
  useAssignRoleToUserMutation,
} from "../../../services/role";
import { useFetchAllUsersQuery } from "../../../services/user";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const AssignRoleToUserDialog = ({ open, onClose }) => {
  const { accountType } = useContext(AuthContext);
  const { data: freeRoles } = useGetAllFreeRolesQuery();
  const { data: users } = useFetchAllUsersQuery(accountType);
  const [assignRoleToUser] = useAssignRoleToUserMutation();
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [inputrolevalue, setInputrolevalue] = useState("");
  const [inputuservalue, setInputuservalue] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await assignRoleToUser({
      roleId: selectedRoleId?._id,
      userId: selectedUserId?._id,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        onClose();
        setInputrolevalue();
        setInputuservalue();
        setSelectedRoleId(null);
        setSelectedUserId(null);
      }, 2000);
    } else {
      setNotification({
        open: true,
        message: `Error assigning Role to the User. ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Assign Role To User</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Autocomplete
                value={selectedRoleId}
                onChange={(event, newValue) => {
                  setSelectedRoleId(newValue);
                }}
                inputValue={inputrolevalue}
                onInputChange={(event, newInputValue) => {
                  setInputrolevalue(newInputValue);
                }}
                options={freeRoles || []}
                getOptionLabel={(option) => (option ? `${option?.name}` : "")}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Role"
                    variant="outlined"
                    margin="normal"
                    required
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                value={selectedUserId}
                onChange={(event, newValue) => {
                  setSelectedUserId(newValue);
                }}
                inputValue={inputuservalue}
                onInputChange={(event, newInputValue) => {
                  setInputuservalue(newInputValue);
                }}
                options={users || []}
                getOptionLabel={(option) =>
                  option
                    ? `${option?.firstName} ${option?.lastName} ${option?.email}`
                    : ""
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a User"
                    variant="outlined"
                    margin="normal"
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Assign Role To User
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default AssignRoleToUserDialog;

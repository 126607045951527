import React, { useContext, memo } from "react";
import { AppBar, Toolbar, Typography, ListItemIcon } from "@mui/material";
import { SidebarContext } from "../../context/SidebarContext";
import { BackButton } from "..";
import { categoryIcon } from "../sidebar/sidebarHelpers";

const PostPageNavbar = ({
  selectedCategory,
  selectedCategoryName,
  selectedIcon,
}) => {
  const { sidebarOpen } = useContext(SidebarContext);
  const postPageNavbarClass = `app-bar ${
    !sidebarOpen ? "sidebar-closed" : "sidebar-open"
  }`;

  return (
    <AppBar className={postPageNavbarClass}>
      <Toolbar className={postPageNavbarClass}>
        <BackButton />
        <ListItemIcon
          sx={{
            minWidth: "1.75rem",
            display: "flex",
            alignItems: "center",
            ml: 1,
            mr: 1,
            color: "var(--text-color)",
          }}
        >
          {categoryIcon(selectedIcon)}
        </ListItemIcon>
        {selectedCategoryName && (
          <Typography
            variant="h6"
            sx={{ flexGrow: 1 }}
            className="main-title"
          >
            {selectedCategoryName}
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default memo(PostPageNavbar);

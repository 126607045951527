import React, { useState, useEffect } from "react";
import { useGetDutyScheduleOfficeLocationsByTierQuery } from "../../../../services/duty-schedule";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

function MembersDutyPreferencesEmailService({
  selectedMembersUserIds = [],
  tierId,
  onSubmit,
}) {
  const { data: dutyScheduleOfficeLocations } =
    useGetDutyScheduleOfficeLocationsByTierQuery(tierId, {
      skip: !tierId,
    });

  const [formData, setFormData] = useState({
    emailSubject: "",
    selectedMembersUserIds: [],
    emailBody: "",
  });

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      selectedMembersUserIds,
    }));
  }, [selectedMembersUserIds]);

  const handleSubmit = () => {
    onSubmit(formData);
    setTimeout(() => {
      setFormData({
        emailSubject: "",
        selectedMembersUserIds: [],
        emailBody: "",
      });
    }, 1500);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography component="div">Send Custom Email</Typography>
      <Grid container spacing={2} style={{ marginTop: "20px" }}>
        <Grid item xs={12}>
          <TextField
            label="Email Subject"
            name="emailSubject"
            type="text"
            value={formData.emailSubject}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                emailSubject: e.target.value,
              }))
            }
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Members amount"
            name="selectedMembersUserIds"
            type="text"
            value={formData.selectedMembersUserIds?.length}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email Body"
            name="emailBody"
            type="text"
            value={formData.emailBody}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                emailBody: e.target.value,
              }))
            }
            required
            multiline
            rows={5}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={
              !selectedMembersUserIds?.length ||
              selectedMembersUserIds?.length === 0
            }
            fullWidth
          >
            Send Emails
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MembersDutyPreferencesEmailService;

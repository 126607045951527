import React, { useState } from "react";
import {
  useGetAllFeatureSettingsQuery,
  useDeleteFeatureSettingsMutation,
  useDeleteAllFeatureSettingsMutation,
} from "../../../services/feature-settings";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import CreateEditFeatureSettingsForm from "./CreateEditFeatureSettingsForm";
import { ConfirmationDialog } from "../..";

function FeatureSettingsList() {
  const {
    data: allFeatureSettings,
    isLoading: isFetchingFeatures,
    refetch,
  } = useGetAllFeatureSettingsQuery();
  const [deleteFeatureSettings, { isLoading: isDeleteFeatureSettingsLoading }] =
    useDeleteFeatureSettingsMutation();
  const [
    deleteAllFeatureSettings,
    { isLoading: isDeleteAllFeatureSettingsLoading },
  ] = useDeleteAllFeatureSettingsMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [openCreateEditDialog, setOpenCreateEditDialog] = useState(false);
  const [selectedFeatureSettings, setSelectedFeatureSettings] = useState(null);
  const [mode, setMode] = useState("create");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationDialogAction, setConfirmationDialogAction] =
    useState(null);
  const [confirmationDialogPayload, setConfirmationDialogPayload] =
    useState(null);

  const handleDeleteFeatureSettings = async (featureId) => {
    if (!featureId) {
      setNotification({
        open: true,
        message: "Feature Setting ID is missing.",
      });
      return;
    }

    setConfirmationDialogAction("deleteFeature");
    setConfirmationDialogPayload({ featureId });
    setConfirmationDialogOpen(true);
  };

  const handleDeleteAllFeatureSettings = async () => {
    if (!allFeatureSettings?.length) {
      setNotification({
        open: true,
        message: "There is nothing to delete.",
      });
      return;
    }

    setConfirmationDialogAction("deleteAllFeatures");
    setConfirmationDialogPayload(null);
    setConfirmationDialogOpen(true);
  };

  const handleOpenCreateEditDialog = (feature = null, mode = "create") => {
    setSelectedFeatureSettings(feature);
    setMode(mode);
    setOpenCreateEditDialog(true);
  };

  const handleCloseCreateEditDialog = () => {
    setOpenCreateEditDialog(false);
  };

  const handleConfirmDialog = async () => {
    if (
      confirmationDialogAction === "deleteFeature" &&
      confirmationDialogPayload?.featureId
    ) {
      const result = await deleteFeatureSettings(
        confirmationDialogPayload.featureId
      );
      if (result?.data) {
        setNotification({
          open: true,
          message: `${result.data.msg}`,
        });
        setTimeout(() => {
          refetch?.();
        }, 1500);
      } else {
        setNotification({
          open: true,
          message: `Error deleting Feature Settings: ${result.error.data.msg}`,
        });
      }
    }

    if (confirmationDialogAction === "deleteAllFeatures") {
      const result = await deleteAllFeatureSettings();
      if (result?.data) {
        setNotification({
          open: true,
          message: `${result.data.msg}`,
        });
        setTimeout(() => {
          refetch?.();
        }, 1500);
      } else {
        setNotification({
          open: true,
          message: `Error deleting all Feature Settings: ${result.error.data.msg}`,
        });
      }
    }

    setConfirmationDialogOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <div className="management-page-body">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenCreateEditDialog(null, "create")}
          >
            Create Feature Setting
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteAllFeatureSettings}
            disabled={
              isDeleteAllFeatureSettingsLoading ||
              isFetchingFeatures ||
              !allFeatureSettings ||
              !allFeatureSettings?.length === 0
            }
          >
            {isDeleteAllFeatureSettingsLoading ? (
              <CircularProgress size={24} />
            ) : (
              "Delete All Feature Settings"
            )}
          </Button>
        </Box>
      </Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Feature Name</TableCell>
              <TableCell>Is Active</TableCell>
              <TableCell>Environment</TableCell>
              <TableCell>User Roles</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetchingFeatures ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : allFeatureSettings?.length > 0 ? (
              allFeatureSettings?.map((feature) => (
                <TableRow key={feature?._id}>
                  <TableCell>{feature?.name}</TableCell>
                  <TableCell>{feature?.isActive ? "Yes" : "No"}</TableCell>
                  <TableCell>{feature?.environment.join(", ")}</TableCell>
                  <TableCell>{feature?.userRole.join(", ")}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleOpenCreateEditDialog(feature, "edit")
                      }
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDeleteFeatureSettings(feature?._id)}
                      disabled={isDeleteFeatureSettingsLoading}
                    >
                      {isDeleteFeatureSettingsLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Delete"
                      )}
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No Feature Settings Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider style={{ margin: "20px 0" }} />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
      <CreateEditFeatureSettingsForm
        open={openCreateEditDialog}
        onClose={handleCloseCreateEditDialog}
        featureSettings={selectedFeatureSettings}
        mode={mode}
      />
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        onConfirm={handleConfirmDialog}
        title={
          confirmationDialogAction === "deleteFeature"
            ? "Confirm Deleting Feature"
            : "Confirm Deleting All Features"
        }
        description={
          confirmationDialogAction === "deleteFeature"
            ? "Are you sure you want to delete this feature?"
            : "Are you sure you want to delete all features?"
        }
      />
    </div>
  );
}

export default FeatureSettingsList;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const invitationApi = createApi({
  reducerPath: "invitation",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/invitations/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Invitation"],
  endpoints: (builder) => ({
    getAllInvitation: builder.query({
      query: () => ({
        url: "get-all",
        method: "GET",
      }),
      providesTags: ["Invitation"],
    }),
    getAllInvitationsByTier: builder.query({
      query: (tierId) => ({
        url: `get-all-by-tier/${tierId}`,
        method: "GET",
      }),
      skip: (tierId) => !tierId,
      providesTags: ["Invitation"],
    }),
    getInvitationById: builder.query({
      query: (invitationId) => ({
        url: `get-one/${invitationId}`,
        method: "GET",
      }),
      providesTags: ["Invitation"],
    }),
    inviteBrokerage: builder.mutation({
      query: ({ senderId, brokerageEmail, realEstateBoardName }) => ({
        url: `invite-brokerage/`,
        method: "POST",
        body: { senderId, brokerageEmail, realEstateBoardName },
      }),
      invalidatesTags: ["Invitation"],
    }),
    signupBrokerage: builder.mutation({
      query: (brokerageData) => ({
        url: `register-brokerage/`,
        method: "POST",
        body: brokerageData,
      }),
      invalidatesTags: ["Invitation"],
    }),
    inviteBrokerageMember: builder.mutation({
      query: ({ memberEmail, userId, brokerageId, tierId }) => ({
        url: `invite-member-to-brokerage/`,
        method: "POST",
        body: { memberEmail, userId, brokerageId, tierId },
      }),
      invalidatesTags: ["Invitation"],
    }),
    signupBrokerageMember: builder.mutation({
      query: (memberData) => ({
        url: `register-brokerage-member/`,
        method: "POST",
        body: memberData,
      }),
    }),
    inviteNewMember: builder.mutation({
      query: ({ memberEmail, userId, tierId, userRoleNameEnumId }) => ({
        url: `invite-new-member/`,
        method: "POST",
        body: { memberEmail, userId, tierId, userRoleNameEnumId },
      }),
      invalidatesTags: ["Invitation"],
    }),
    signupNewMember: builder.mutation({
      query: (memberData) => ({
        url: `register-new-member/`,
        method: "POST",
        body: memberData,
      }),
    }),
    inviteMultipleMembers: builder.mutation({
      query: ({ members, tierId, sendEmailToEveryone }) => ({
        url: `invite-multiple-members/`,
        method: "POST",
        body: { members, tierId, sendEmailToEveryone },
      }),
      invalidatesTags: ["Invitation"],
    }),
    resendInvitationToBrokerageMember: builder.mutation({
      query: ({ tierId, userEmail }) => ({
        url: `resend-invitation-to-brokerage-member/`,
        method: "POST",
        body: { tierId, userEmail },
      }),
      invalidatesTags: ["Invitation"],
    }),
    resendInvitationToMultipleBrokerageMembers: builder.mutation({
      query: ({ tierId, members }) => ({
        url: `resend-invitation-to-multiple-brokerage-members/`,
        method: "POST",
        body: { tierId, members },
      }),
      invalidatesTags: ["Invitation"],
    }),
    setupNewMemberPassword: builder.mutation({
      query: ({ token, newPassword }) => ({
        url: `setup-new-member-password/${token}`,
        method: "POST",
        body: { newPassword },
      }),
    }),
    sendSetupPasswordEmailToAllTierMembers: builder.mutation({
      query: ({ tierId }) => ({
        url: `send-setup-password-email-to-all-members/${tierId}`,
        method: "POST",
      }),
    }),
    sendSetupPasswordEmailToMember: builder.mutation({
      query: ({ tierId, userId }) => ({
        url: `send-setup-password-email-to-member/${tierId}/${userId}`,
        method: "POST",
      }),
    }),
    removeInvitation: builder.mutation({
      query: (invitationId) => ({
        url: `delete/${invitationId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Invitation"],
    }),
  }),
});

export const {
  useGetInvitationByIdQuery,
  useGetAllInvitationQuery,
  useGetAllInvitationsByTierQuery,
  useInviteBrokerageMutation,
  useSignupBrokerageMutation,
  useInviteBrokerageMemberMutation,
  useSignupBrokerageMemberMutation,
  useInviteNewMemberMutation,
  useSignupNewMemberMutation,
  useInviteMultipleMembersMutation,
  useResendInvitationToBrokerageMemberMutation,
  useResendInvitationToMultipleBrokerageMembersMutation,
  useSetupNewMemberPasswordMutation,
  useSendSetupPasswordEmailToAllTierMembersMutation,
  useSendSetupPasswordEmailToMemberMutation,
  useRemoveInvitationMutation,
} = invitationApi;

export default invitationApi;

import React, { useState, memo } from "react";
import FlooorDutyManagerMenu from "./FlooorDutyManagerMenu";
import DutyScheduleTabs from "./DutyScheduleTabs";
import { Typography, Paper, Box } from "@mui/material";
import NotificationSnackbar from "../../../helpers/notification-snackbar";

const FlooorDutySchedulingManager = ({ tier }) => {
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <>
      <Paper
        elevation={2}
        sx={{
          p: 1,
          mb: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            p: 1,
          }}
        >
          <Typography variant="h5" fontWeight={700}>
            Flooor Duty Scheduling Manager
          </Typography>
          <FlooorDutyManagerMenu tierId={tier?._id} />
        </Box>
        <DutyScheduleTabs tier={tier} />
        <NotificationSnackbar
          open={notification.open}
          message={notification.message}
          onClose={handleClose}
        />
      </Paper>
    </>
  );
};

export default memo(FlooorDutySchedulingManager);

import React, { createContext, useState, useContext } from "react";

const ActiveChatContext = createContext({
  activeChatId: null,
  setActiveChatId: () => {},
});

export const useActiveChat = () => useContext(ActiveChatContext);

export const ActiveChatProvider = ({ children }) => {
  const [activeChatId, setActiveChatId] = useState(null);

  return (
    <ActiveChatContext.Provider value={{ activeChatId, setActiveChatId }}>
      {children}
    </ActiveChatContext.Provider>
  );
};

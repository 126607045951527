import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  useCreateTeamRoleMutation,
  useEditTeamRoleMutation,
} from "../../../../services/team-role";
import NotificationSnackbar from "../../../helpers/notification-snackbar";

const CreateEditTeamRoleDialog = ({
  open,
  onClose,
  teamId,
  teamRoleId,
  refetchRoles,
  action = "add",
  initialRoleData = null,
}) => {
  const isEditMode = action === "edit";
  const [roleName, setRoleName] = useState(
    isEditMode ? initialRoleData?.name : ""
  );
  const [permissions, setPermissions] = useState(
    isEditMode ? initialRoleData?.permissions.join(", ") : ""
  );
  const [createTeamRole, { isLoading: isCreating }] =
    useCreateTeamRoleMutation();
  const [editTeamRole, { isLoading: isEditing }] = useEditTeamRoleMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (isEditMode && initialRoleData) {
      setRoleName(initialRoleData?.name);
      setPermissions(initialRoleData?.permissions.join(", "));
    } else {
      setRoleName("");
      setPermissions("");
    }
  }, [initialRoleData, isEditMode, open]);

  const handleSubmit = async () => {
    if (!roleName?.trim()) {
      setNotification({
        open: true,
        message: "The Team Role Name is required.",
      });
      return;
    }
    if (isEditMode && !initialRoleData) {
      setNotification({
        open: true,
        message: "Attempted to edit a team role with mismatched data.",
      });
      return;
    }

    const permissionsArray = permissions
      .split(",")
      .map((perm) => perm.trim())
      .filter((perm) => perm);
    if (isEditMode) {
      const result = await editTeamRole({
        teamRoleId,
        name: roleName,
        permissions: permissionsArray,
      });
      if (result?.data) {
        setNotification({
          openNotification: true,
          message: `${result?.data?.msg}`,
        });
        setTimeout(() => {
          setRoleName("");
          setPermissions("");
          refetchRoles();
          onClose();
        }, 1500);
      } else {
        setNotification({
          openNotification: true,
          message: `Error adding the team role: ${result?.error?.data?.msg}`,
        });
        setRoleName("");
        setPermissions("");
      }
    } else {
      const result = await createTeamRole({
        teamId,
        name: roleName,
        permissions: permissionsArray,
      });

      if (result?.data) {
        setNotification({
          openNotification: true,
          message: `${result?.data?.msg}`,
        });
        setTimeout(() => {
          setRoleName("");
          setPermissions("");
          refetchRoles();
          onClose();
        }, 1500);
      } else {
        setNotification({
          openNotification: true,
          message: `Error adding the team role: ${result?.error?.data?.msg}`,
        });
        setRoleName("");
        setPermissions("");
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, openNotification: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {isEditMode ? "Edit Team Role" : "Add Team Role"}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Role Name"
          type="text"
          fullWidth
          variant="outlined"
          value={roleName}
          onChange={(e) => setRoleName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Permissions (comma separated)"
          type="text"
          fullWidth
          variant="outlined"
          value={permissions}
          onChange={(e) => setPermissions(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={isCreating || isEditing}>
          {isCreating || isEditing ? (
            <CircularProgress size={24} />
          ) : action === "add" ? (
            "Create"
          ) : (
            "Save"
          )}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default CreateEditTeamRoleDialog;

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRedirectShortToLongQuery } from "../redirect";

const RedirectHandler = () => {
  const { code } = useParams();
  const { data, error, isLoading } = useRedirectShortToLongQuery(code);

  useEffect(() => {
    if (data && data.longUrl) {
      window.location.href = data.longUrl;
    }
    if (error) {
      console.error("Error during redirection:", error);
    }
  }, [data, error]);

  if (isLoading) return <div>Redirecting...</div>;
  if (error) return <div>Error: URL not found</div>;

  return <div>Redirecting...</div>;
};

export default RedirectHandler;

import React, { useState, useMemo, useContext, memo } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useFetchAllUsersQuery } from "../../../services/user";
import {
  Select,
  InputLabel,
  Table,
  TextField,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  TableSortLabel,
  FormControl,
  Button,
  Tooltip,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material/";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../helpers/utils";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import AssignTierToMembersDialog from "./AssignTierToMembersDialog";

dayjs.extend(dayjsPluginUTC);

function MembersToManageTierList({ tiers }) {
  const { accountType } = useContext(AuthContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedTier, setSelectedTier] = useState(null);
  const [nameFilter, setNameFilter] = useState("");

  const { data: members } = useFetchAllUsersQuery(accountType, {
    skip: accountType !== "reosadmin",
  });
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [accountTypeFilter, setAccountTypeFilter] = useState("");
  const [brokerageFilter, setBrokerageFilter] = useState("");
  const [viewerTierFilter, setViewerTierFilter] = useState("");

  const [currentMemberClicked, setCurrentMemberClicked] = useState(null);
  const [sortField, setSortField] = useState("email");
  const [sortDirection, setSortDirection] = useState("asc");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [isAssignRemoveTierDialogOpen, setAssignRemoveTierDialogOpen] =
    useState(false);
  const [AssignRemoveTierDialogMode, setAssignRemoveTierDialogMode] =
    useState("assign");

  const handleRowClick = (event, memberUser) => {
    if (
      event.target.closest(".menu-button") ||
      event.target.closest(".view-details-button") ||
      event.target.closest(".select-checkbox")
    ) {
      setCurrentMemberClicked(memberUser);
      return;
    }
    // navigate(`/member-profile/${memberUser?._id}`);
  };

  const handleNameFilterChange = (event) => {
    setNameFilter(event.target.value);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    if (event) event.stopPropagation();
    setAnchorEl(null);
    setCurrentMemberClicked(null);
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  const handleFilterChange = (setter) => (event) => {
    setter(event.target.value);
    if (event.target.value === "") {
      setBrokerageFilter("");
    }
  };
  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleAccountTypeFilterChange = (event) => {
    setAccountTypeFilter(event.target.value);
  };

  const filteredUsers = members?.filter((user) => {
    const fullName = `${user?.firstName} ${user?.lastName}`.toLowerCase();
    return (
      (!nameFilter || fullName.includes(nameFilter.toLowerCase())) &&
      (!statusFilter || user?.status === statusFilter) &&
      (!accountTypeFilter || user?.accountType === accountTypeFilter) &&
      (!brokerageFilter ||
        user?.role?.tiers?.find(
          (tier) => tier && tier?._id === brokerageFilter
        )) &&
      (!viewerTierFilter ||
        user?.role?.viewerTiers?.find(
          (viewerTier) => viewerTier && viewerTier?._id === viewerTierFilter
        ))
    );
  });

  const sortedUsers = filteredUsers?.sort((a, b) => {
    if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
    if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
    return 0;
  });

  const isAllSelected = useMemo(
    () =>
      sortedUsers?.length > 0 &&
      sortedUsers?.every((member) => selectedMembers?.includes(member?._id)),
    [sortedUsers, selectedMembers]
  );

  const toggleSelectAll = () => {
    if (isAllSelected) {
      const filteredIds = sortedUsers?.map((member) => member?._id);
      setSelectedMembers(
        selectedMembers?.filter((id) => !filteredIds?.includes(id))
      );
    } else {
      const newSelections = sortedUsers?.map((member) => member?._id);
      setSelectedMembers([...new Set([...selectedMembers, ...newSelections])]);
    }
  };

  const handleCheckboxChange = (event, id) => {
    event.stopPropagation();
    setSelectedMembers((current) =>
      current.includes(id)
        ? current.filter((memberId) => memberId !== id)
        : [...current, id]
    );
  };

  const openAssignTierDialog = () => {
    setAssignRemoveTierDialogMode("assign");
    setAssignRemoveTierDialogOpen(true);
  };

  const openRemoveTierDialog = () => {
    setAssignRemoveTierDialogMode("remove");
    setAssignRemoveTierDialogOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <>
      <Box>
        <strong>Tier to assign/remove to members to/from:</strong>
        <Autocomplete
          value={selectedTier}
          onChange={(event, newValue) => {
            setSelectedTier(newValue);
          }}
          options={tiers || []}
          getOptionLabel={(option) =>
            option
              ? `${option?.level} ${
                  option?.brokerage || option?.team || option?.group
                    ? option?.brokerage?.officeName ||
                      option?.team?.name ||
                      option?.group?.name
                    : ""
                } ${
                  option?.level === "system" || option?.level === "global"
                    ? " - REACirc"
                    : ""
                } ${
                  option?._id === option?.brokerage?.leadBrokerageTier
                    ? " - (Lead Brokerage Office)"
                    : ""
                } ${
                  option?._id === option?.brokerage?.tier ? " - (Office)" : ""
                }`
              : ""
          }
          isOptionEqualToValue={(option, value) => option?._id === value._id}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a Tier"
              variant="outlined"
              margin="normal"
              required
            />
          )}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          m: 3,
        }}
      >
        <Tooltip
          title={
            selectedMembers.length === 0
              ? "Select members first"
              : "Assign Tier To Members"
          }
        >
          <span>
            <Button
              onClick={openAssignTierDialog}
              disabled={selectedMembers.length === 0 || !selectedTier}
            >
              Assign Tier To Members
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          title={
            selectedMembers.length === 0
              ? "Select members first"
              : "Remove Tier From Members"
          }
        >
          <span>
            <Button
              onClick={openRemoveTierDialog}
              disabled={selectedMembers.length === 0 || !selectedTier}
            >
              Remove Tier From Members
            </Button>
          </span>
        </Tooltip>
      </Box>
      <Box sx={{ marginBottom: "10px", display: "flex", gap: "10px" }}>
        <TextField
          label="Filter by Name"
          variant="outlined"
          size="small"
          fullWidth
          value={nameFilter}
          onChange={handleNameFilterChange}
        />
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          sx={{ minWidth: 120 }}
        >
          <InputLabel id="brokerage-filter-label">
            Filter by Tier (Office)
          </InputLabel>
          <Select
            labelId="brokerage-filter-label"
            value={brokerageFilter}
            onChange={handleFilterChange(setBrokerageFilter)}
            label="Filter by Tier (Office)"
          >
            <MenuItem value="">All</MenuItem>
            {tiers &&
              tiers?.map((tier) => (
                <MenuItem key={tier?._id} value={tier?._id}>
                  {`${tier?.level} ${
                    tier?.brokerage || tier?.team || tier?.group
                      ? tier?.brokerage?.officeName ||
                        tier?.team?.name ||
                        tier?.group?.name
                      : ""
                  } ${
                    tier?.level === "system" || tier?.level === "global"
                      ? " - REACirc"
                      : ""
                  } ${
                    tier?._id === tier?.brokerage?.leadBrokerageTier
                      ? " - (Lead Brokerage Office)"
                      : ""
                  } ${
                    tier?._id === tier?.brokerage?.tier ? " - (Office)" : ""
                  }`}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          sx={{ minWidth: 120 }}
        >
          <InputLabel id="viwerTier-filter-label">
            Filter by Viewer Tier (Office)
          </InputLabel>
          <Select
            labelId="viewerTier-filter-label"
            value={viewerTierFilter}
            onChange={handleFilterChange(setViewerTierFilter)}
            label="Filter by Viewer Tier (Office)"
          >
            <MenuItem value="">All</MenuItem>
            {tiers &&
              tiers?.map((tier) => (
                <MenuItem key={tier?._id} value={tier?._id}>
                  {`${tier?.level} ${
                    tier?.brokerage || tier?.team || tier?.group
                      ? tier?.brokerage?.officeName ||
                        tier?.team?.name ||
                        tier?.group?.name
                      : ""
                  } ${
                    tier?.level === "system" || tier?.level === "global"
                      ? " - REACirc"
                      : ""
                  } ${
                    tier?._id === tier?.brokerage?.leadBrokerageTier
                      ? " - (Lead Brokerage Office)"
                      : ""
                  } ${
                    tier?._id === tier?.brokerage?.tier ? " - (Office)" : ""
                  }`}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          sx={{ minWidth: 120 }}
        >
          <InputLabel id="status-filter-label">Filter by status</InputLabel>
          <Select
            labelId="status-filter-label"
            value={statusFilter}
            onChange={handleStatusFilterChange}
            label="Filter by status"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          sx={{ minWidth: 120 }}
        >
          <InputLabel id="account-type-filter-label">
            Filter by account type
          </InputLabel>
          <Select
            labelId="account-type-filter-label"
            value={accountTypeFilter}
            onChange={handleAccountTypeFilterChange}
            label="Filter by account type"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="reosadmin">REOS Admin</MenuItem>
            <MenuItem value="brokerage">Brokerage</MenuItem>
            <MenuItem value="member">Member</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          fontWeight: "bolder",
          textAlign: "center",
          marginBottom: "20px",
          width: "100%",
          gap: "10px",
        }}
      >
        # of Results: {filteredUsers?.length}
      </Box>
      <TableContainer component={Paper}>
        <Table className="user-table">
          <TableHead
            sx={{
              color: "var(--text-color)",
              backgroundColor: "var(--bg-color)",
            }}
          >
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedMembers?.length > 0 &&
                    selectedMembers?.length < members?.length
                  }
                  checked={isAllSelected}
                  onChange={toggleSelectAll}
                />
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "member.firstName"}
                  direction={sortDirection}
                  onClick={() => handleSort("member.firstName")}
                  title="Sort by First Name"
                >
                  <sup>First</sup>
                </TableSortLabel>
                <TableSortLabel
                  onClick={() => handleSort("member.lastName")}
                  active={sortField === "member.lastName"}
                  direction={sortDirection}
                  title="Sort by Last Name"
                >
                  <sup>Last</sup>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ fontWeight: "bolder" }}>
                <TableSortLabel
                  active={sortField === "member.email"}
                  direction={sortDirection}
                  onClick={() => handleSort("member.email")}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "member.accountType"}
                  direction={sortDirection}
                  onClick={() => handleSort("member.accountType")}
                >
                  Account Type
                </TableSortLabel>
              </TableCell>
              {/* <TableCell>
                <TableSortLabel
                  active={sortField === "member.status"}
                  direction={sortDirection}
                  onClick={() => handleSort("member.status")}
                >
                  Status
                </TableSortLabel>
              </TableCell> */}
              <TableCell>
                <TableSortLabel
                  active={sortField === "member.lastOnlineTime"}
                  direction={sortDirection}
                  onClick={() => handleSort("member.lastOnlineTime")}
                >
                  Last Online
                </TableSortLabel>
              </TableCell>
              <TableCell>Tiers</TableCell>
              <TableCell>Actions </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedUsers?.map((member) => (
              <TableRow
                key={member?._id}
                onClick={(event) => handleRowClick(event, member?._id, member)}
                style={{ cursor: "pointer" }}
                className="table-row"
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedMembers.includes(member?._id)}
                    className="select-checkbox"
                    onChange={(event) => {
                      event.stopPropagation();
                      handleCheckboxChange(event, member?._id);
                    }}
                  />
                </TableCell>
                <TableCell>
                  {capitalizeFirstLetter(member?.firstName)}{" "}
                  {capitalizeFirstLetter(member?.lastName)}
                </TableCell>
                <TableCell>{member?.email}</TableCell>
                <TableCell>{member?.accountType}</TableCell>
                <TableCell>
                  {member?.lastOnlineTime ? (
                    dayjs(member?.lastOnlineTime).fromNow()
                  ) : (
                    <span style={{ color: "red" }}>Never</span>
                  )}
                </TableCell>
                <TableCell>
                  {[
                    ...new Map(
                      [
                        ...(member?.role?.tiers || []),
                        ...(member?.role?.viewerTiers || []),
                      ]
                        .flatMap((tier) => {
                          const baseName =
                            tier.brokerage?.officeShortName ||
                            tier.team?.name ||
                            tier.group?.name ||
                            "";
                          const isViewerTier =
                            member?.role?.viewerTiers?.includes(tier);

                          if (tier.brokerage) {
                            const standard = `${tier.brokerage.officeShortName}`;
                            const lead = `${tier.brokerage.officeShortName} (All Offices)`;
                            return tier.brokerage.leadBrokerageTier
                              ? [
                                  [standard, isViewerTier],
                                  [lead, isViewerTier],
                                ]
                              : [[standard, isViewerTier]];
                          }
                          return [[baseName, isViewerTier]];
                        })
                        .filter(([key]) => key)
                    ).entries(),
                  ].map(([uniqueTier, isViewerTier], index) => (
                    <Typography sx={{ fontSize: "10px" }} key={index}>
                      {uniqueTier} {isViewerTier && "(Viewer Tier)"}
                    </Typography>
                  ))}
                </TableCell>

                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      className="menu-button"
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(event) => handleMenuOpen(event, member?._id)}
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleMenuClose}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <MenuItem>
                        <Typography
                          variant="body1"
                          className="view-details-button"
                          onClick={() =>
                            navigate(`/user/${currentMemberClicked}`)
                          }
                        >
                          View Details
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();

                          navigate(`/member-profile/${currentMemberClicked}`);
                          handleMenuClose(e);
                        }}
                      >
                        <Typography
                          variant="body1"
                          className="view-details-button"
                        >
                          Open Profile
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
      <AssignTierToMembersDialog
        selectedTier={selectedTier}
        open={isAssignRemoveTierDialogOpen}
        onClose={() => setAssignRemoveTierDialogOpen(false)}
        members={members}
        selectedMembers={selectedMembers}
        mode={AssignRemoveTierDialogMode}
      />
    </>
  );
}

export default memo(MembersToManageTierList);

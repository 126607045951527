import React, { useContext, useState, memo } from "react";
import {
  useGetGroupByIdQuery,
  useDeleteMemberMutation,
} from "../../../services/group";
import { AuthContext } from "../../../context/AuthContext";
import {
  Paper,
  TableContainer,
  Container,
  Typography,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Box,
  Card,
  CardContent,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { DeleteForever } from "@mui/icons-material/";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import AddExistingMember from "../../reosadmin/team-management//group/AddExistingMember";
import RemoveMemberDialog from "../../reosadmin/team-management//group/RemoveMemberDialog";
import AssignTierToGroupDialog from "../../reosadmin/team-management//group/AssignTierToGroupDialog";
import InviteNewMemberToGroupDialog from "../../reosadmin/team-management//group/InviteNewMemberToGroupDialog";
import GroupManagerTabs from "./GroupManagerTabs";

const GroupManager = ({ groupId }) => {
  const { userRole } = useContext(AuthContext);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const {
    data: group,
    error,
    isLoading,
    refetch,
  } = useGetGroupByIdQuery(groupId, {
    skip: !groupId,
  });

  const [deleteMember] = useDeleteMemberMutation();
  const [isAddMemberOpen, setIsAddMemberOpen] = useState(false);
  const [isInviteNewMemberOpen, setIsInviteNewMemberOpen] = useState(false);
  const [isRemoveMemberOpen, setIsRemoveMemberOpen] = useState(false);
  const [memberIdToRemove, setMemberIdToRemove] = useState(null);
  const [currentGroupId, setCurrentGroupId] = useState(null);
  const [openAssignTierToGroupDialog, setOpenAssignTierToGroupDialog] =
    useState(false);

  const handleAddMemberOpen = (groupId) => {
    setCurrentGroupId(groupId);
    setIsAddMemberOpen(true);
  };

  const handleAddMemberClose = () => {
    setIsAddMemberOpen(false);
  };

  const handleInviteNewMemberOpen = (groupId) => {
    setCurrentGroupId(groupId);
    setIsInviteNewMemberOpen(true);
  };

  const handleInviteNewMemberClose = () => {
    setIsInviteNewMemberOpen(false);
  };

  const handleRemoveMemberOpen = (groupId, memberId) => {
    setCurrentGroupId(groupId);
    setMemberIdToRemove(memberId);
    setIsRemoveMemberOpen(true);
  };

  const handleRemoveMemberClose = () => {
    setIsRemoveMemberOpen(false);
    setCurrentGroupId(null);
    setMemberIdToRemove(null);
  };

  const handleRemoveMemberConfirm = async () => {
    const result = await deleteMember({
      groupId: currentGroupId,
      memberId: memberIdToRemove,
    });

    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      handleRemoveMemberClose();
      refetch();
    } else {
      setNotification({
        open: true,
        message: `Error deleting Member. ${result?.error?.data?.msg}`,
      });
      refetch();
    }
  };

  const handleCloseAssignTierToGroupDialog = () => {
    setOpenAssignTierToGroupDialog(false);
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (isLoading)
    return (
      <div className="loading-spinner">
        <CircularProgress size={24} />
      </div>
    );

  if (error) return <div>Error: {error?.data?.msg}</div>;

  return (
    <Container>
      <Card>
        <CardContent>
          {!group ? (
            <Typography variant="h4">No Group Found</Typography>
          ) : (
            <>
              <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold" }}
                    className="groupDetailsCard__headerLabel"
                  >
                    Group Name:
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "light", ml: 1 }} // Adding margin-left for spacing
                    className="groupDetailsCard__headerValue"
                  >
                    {group?.name}
                  </Typography>
                </Box>

                <Typography
                  variant="subtitle1"
                  sx={{ mb: 2 }}
                  className="groupDetailsCard__lead"
                >
                  <strong>Group Lead:</strong>{" "}
                  {group?.groupLead?.member?.user?.firstName}{" "}
                  {group?.groupLead?.member?.user?.lastName} -{" "}
                  {group?.groupLead?.member?.user?.email}
                </Typography>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleInviteNewMemberOpen(group._id)}
                  >
                    Invite New Member
                  </Button>
                  {["reosadmin", "reossuperadmin"].includes(userRole) && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddMemberOpen(group._id)}
                    >
                      Add Existing Member
                    </Button>
                  )}
                </Box>
              </Box>

              <TableContainer component={Paper}>
                <Table className="user-table">
                  <TableHead>
                    <TableRow
                      sx={{
                        color: "var(--text-color)",
                        backgroundColor: "var(--bg-color)",
                      }}
                    >
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {group &&
                      group?.members?.length > 0 &&
                      group?.members?.map((groupMember) => (
                        <TableRow
                          key={groupMember?._id}
                          style={{ cursor: "pointer" }}
                          className="table-row"
                        >
                          <TableCell>
                            {groupMember?.member?.user?.firstName}{" "}
                            {groupMember?.member?.user?.lastName}
                          </TableCell>
                          <TableCell>
                            {groupMember?.member?.user?.email}
                          </TableCell>
                          <TableCell>{groupMember?.role?.name}</TableCell>
                          <TableCell>
                            {["reossuperadmin", "reosadmin"].includes(
                              userRole
                            ) && (
                              <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  handleRemoveMemberOpen(
                                    group?._id,
                                    groupMember?.member?._id
                                  )
                                }
                              >
                                <DeleteForever />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </CardContent>
      </Card>
      <GroupManagerTabs
        groupId={group?._id}
        groupTier={group?.tier}
        groupMembers={group?.members || []}
      />
      <InviteNewMemberToGroupDialog
        open={isInviteNewMemberOpen}
        groupId={groupId}
        groupName={group?.name}
        tierId={group?.tier?._id}
        onClose={handleInviteNewMemberClose}
        refetch={refetch}
      />
      <AddExistingMember
        open={isAddMemberOpen}
        groupId={currentGroupId}
        onClose={handleAddMemberClose}
        refetch={refetch}
      />
      <AssignTierToGroupDialog
        preselectedGroup={group}
        open={openAssignTierToGroupDialog}
        onClose={handleCloseAssignTierToGroupDialog}
      />
      <RemoveMemberDialog
        open={isRemoveMemberOpen}
        onClose={handleRemoveMemberClose}
        onConfirm={handleRemoveMemberConfirm}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Container>
  );
};

export default memo(GroupManager);

// src/components/ImagePreview.js
import React from "react";
import { Grid } from "@mui/material";

const ImagePreview = ({ src, alt }) => {
  if (!src) return null;

  return (
    <Grid item>
      <div
        style={{
          maxWidth: "100px",
          maxHeight: "100px",
          marginRight: "16px", // Space between the image and text
          backgroundColor: "#eeeeee", // Light gray background for visibility
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={src}
          alt={alt}
          style={{
            maxWidth: "100px",
            maxHeight: "100px",
            border: "1px solid #ccc", // Light border
            borderRadius: "4px", // Rounded corners
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow
            backgroundColor: "#f0f0f0", // Light gray background
          }}
        />
      </div>
    </Grid>
  );
};

export default ImagePreview;

import React, { memo, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import AnnouncementPostCard from "./AnnouncementPostCard";
import ForumPostCard from "./ForumPostCard";
import EventPostCard from "./EventPostCard";
import KnowledgeEducationPostCard from "./KnowledgeEducationPostCard";
import ListingPostCard from "./ListingPostCard";
import FilePostCard from "./FilePostCard";

const PostCard = ({
  post,
  categoryType,
  isPostList = false,
  isPostPage = false,
  isPostHomePageList = false,
  onDialogOpen,
  onMigratePostToNewCategoryDialogOpen,
  refetchPosts,
  currentUserId,
  onSelect,
  isPostsLoading,
  isPostsError,
  setNotification,
  isNewPost,
}) => {
  const { accountType } = useContext(AuthContext);

  const renderPostContent = () => {
    switch (post?.category?.type) {
      case "forum-posts":
        return (
          <ForumPostCard
            post={post}
            currentUserId={currentUserId}
            onDialogOpen={onDialogOpen}
            onMigratePostToNewCategoryDialogOpen={
              onMigratePostToNewCategoryDialogOpen
            }
            refetchPosts={refetchPosts}
            isPostsLoading={isPostsLoading}
            isPostsError={isPostsError}
            accountType={accountType}
            onSelect={onSelect}
            isPostList={isPostList}
            isPostPage={isPostPage}
            isPostHomePageList={isPostHomePageList}
            isNewPost={isNewPost}
          />
        );

      case "announcements":
        return (
          <AnnouncementPostCard
            post={post}
            currentUserId={currentUserId}
            onDialogOpen={onDialogOpen}
            onMigratePostToNewCategoryDialogOpen={
              onMigratePostToNewCategoryDialogOpen
            }
            refetchPosts={refetchPosts}
            isPostsLoading={isPostsLoading}
            isPostsError={isPostsError}
            accountType={accountType}
            onSelect={onSelect}
            isPostList={isPostList}
            isPostPage={isPostPage}
            isPostHomePageList={isPostHomePageList}
            isNewPost={isNewPost}
          />
        );

      case "knowledge-base":
        return (
          <KnowledgeEducationPostCard
            post={post}
            currentUserId={currentUserId}
            onDialogOpen={onDialogOpen}
            onMigratePostToNewCategoryDialogOpen={
              onMigratePostToNewCategoryDialogOpen
            }
            refetchPosts={refetchPosts}
            isPostsLoading={isPostsLoading}
            isPostsError={isPostsError}
            accountType={accountType}
            onSelect={onSelect}
            isPostList={isPostList}
            isPostPage={isPostPage}
            isPostHomePageList={isPostHomePageList}
            isNewPost={isNewPost}
          />
        );
      case "files":
        return (
          <FilePostCard
            post={post}
            currentUserId={currentUserId}
            onDialogOpen={onDialogOpen}
            onMigratePostToNewCategoryDialogOpen={
              onMigratePostToNewCategoryDialogOpen
            }
            refetchPosts={refetchPosts}
            isPostsLoading={isPostsLoading}
            isPostsError={isPostsError}
            accountType={accountType}
            setNotification={setNotification}
            onSelect={onSelect}
            isPostList={isPostList}
            isPostPage={isPostPage}
            isPostHomePageList={isPostHomePageList}
            isNewPost={isNewPost}
          />
        );

      case "listing":
        return (
          <ListingPostCard
            post={post}
            currentUserId={currentUserId}
            onDialogOpen={onDialogOpen}
            onMigratePostToNewCategoryDialogOpen={
              onMigratePostToNewCategoryDialogOpen
            }
            refetchPosts={refetchPosts}
            isPostsLoading={isPostsLoading}
            isPostsError={isPostsError}
            accountType={accountType}
            onSelect={onSelect}
            isPostList={isPostList}
            isPostPage={isPostPage}
            isPostHomePageList={isPostHomePageList}
            isNewPost={isNewPost}
          />
        );

      case "event":
        return (
          <EventPostCard
            post={post}
            currentUserId={currentUserId}
            onDialogOpen={onDialogOpen}
            onMigratePostToNewCategoryDialogOpen={
              onMigratePostToNewCategoryDialogOpen
            }
            refetchPosts={refetchPosts}
            isPostsLoading={isPostsLoading}
            isPostsError={isPostsError}
            accountType={accountType}
            onSelect={onSelect}
            isPostList={isPostList}
            categoryType={categoryType}
            isPostPage={isPostPage}
            isPostHomePageList={isPostHomePageList}
            isNewPost={isNewPost}
          />
        );

      case "educational-training":
        return (
          <KnowledgeEducationPostCard
            post={post}
            currentUserId={currentUserId}
            onDialogOpen={onDialogOpen}
            onMigratePostToNewCategoryDialogOpen={
              onMigratePostToNewCategoryDialogOpen
            }
            refetchPosts={refetchPosts}
            isPostsLoading={isPostsLoading}
            isPostsError={isPostsError}
            accountType={accountType}
            onSelect={onSelect}
            isPostList={isPostList}
            isPostPage={isPostPage}
            isPostHomePageList={isPostHomePageList}
            isNewPost={isNewPost}
          />
        );

      case "poll":
        return (
          <ForumPostCard
            post={post}
            currentUserId={currentUserId}
            onDialogOpen={onDialogOpen}
            onMigratePostToNewCategoryDialogOpen={
              onMigratePostToNewCategoryDialogOpen
            }
            refetchPosts={refetchPosts}
            isPostsLoading={isPostsLoading}
            isPostsError={isPostsError}
            accountType={accountType}
            onSelect={onSelect}
            isPostList={isPostList}
            isPostPage={isPostPage}
            isPostHomePageList={isPostHomePageList}
            isNewPost={isNewPost}
          />
        );

      default:
        return (
          <ForumPostCard
            post={post}
            currentUserId={currentUserId}
            onDialogOpen={onDialogOpen}
            onMigratePostToNewCategoryDialogOpen={
              onMigratePostToNewCategoryDialogOpen
            }
            refetchPosts={refetchPosts}
            isPostsLoading={isPostsLoading}
            isPostsError={isPostsError}
            accountType={accountType}
            onSelect={onSelect}
            isPostList={isPostList}
            isPostPage={isPostPage}
            isPostHomePageList={isPostHomePageList}
            isNewPost={isNewPost}
          />
        );
    }
  };
  return renderPostContent();
};

export default memo(PostCard);

import React, { useState, memo } from "react";
import { useGetAllTierMembersQuery } from "../../../services/member";
import { useSendSetupPasswordEmailToAllTierMembersMutation } from "../../../services/invitation";
import { useUpdateChatParticipantsForTierMutation } from "../../../services/tier";
import UserManagementMenu from "./UserManagmentMenu";
import { Container, Typography, Paper, Box } from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import MembersList from "./MembersList";
import InviteNewMemberToBrokerageDialog from "./InviteNewMemberToBrokerageDialog";
import InviteMultipleMembersDialog from "./InviteMultipleMembersDialog";
import InviteMembersCSVDialog from "./InviteMembersCSVDialog";
import { ConfirmationDialog } from "../..";

const MembersManager = ({ brokerageId, tier, brokerageName }) => {
  const { data: members } = useGetAllTierMembersQuery(
    { tierId: tier?._id },
    {
      skip: !tier?._id,
    }
  );

  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [sendSetupPasswordEmailToAllMembers] =
    useSendSetupPasswordEmailToAllTierMembersMutation();
  const [
    updateChatParticipantsForTier,
    { isLoading: isUpdateChatParticipantsLoading },
  ] = useUpdateChatParticipantsForTierMutation();

  const [notification, setNotification] = useState({
    openNotification: false,
    message: "",
  });
  const [openInviteNewMemberDialog, setOpenInviteNewMemberDialog] =
    useState(false);
  const [openInviteMultipleMembersDialog, setOpenInviteMultipleMembersDialog] =
    useState(false);
  const [openInviteMembersCSVDialog, setOpenInviteMembersCSVDialog] =
    useState(false);
  const [
    openUpdateChatParticipantsForTierConfirmationDialog,
    setOpenUpdateChatParticipantsForTierConfirmationDialog,
  ] = useState(false);

  const handleOpenInviteNewMemberDialog = () => {
    if (brokerageId) {
      setOpenInviteNewMemberDialog(true);
    } else {
      setNotification({
        openNotification: true,
        message: "Please choose a brokerage first.",
      });
    }
  };

  const handleCloseInviteNewMemberDialog = () => {
    setOpenInviteNewMemberDialog(false);
  };

  const handleOpenInviteMultipleMembersDialog = () => {
    if (brokerageId) {
      setOpenInviteMultipleMembersDialog(true);
    } else {
      setNotification({
        openNotification: true,
        message: "Please choose a brokerage first.",
      });
    }
  };
  const handleOpenInviteMembersCSVDialog = () => {
    if (brokerageId) {
      setOpenInviteMembersCSVDialog(true);
    } else {
      setNotification({
        openNotification: true,
        message: "Please choose a brokerage first.",
      });
    }
  };
  const handleOpenUpdateChatParticipantsForTierDialog = () => {
    setOpenUpdateChatParticipantsForTierConfirmationDialog(true);
  };
  const handleCloseInviteMultipleMembersDialog = () => {
    setOpenInviteMultipleMembersDialog(false);
  };
  const handleCloseInviteMembersCSVDialog = () => {
    setOpenInviteMembersCSVDialog(false);
  };
  const handleCloseUpdateChatParticipantsForTierDialog = () => {
    setOpenUpdateChatParticipantsForTierConfirmationDialog(false);
  };

  const handleSendSetupPasswordEmail = async () => {
    if (brokerageId) {
      setIsSendingEmail(false);
      const result = await sendSetupPasswordEmailToAllMembers({
        tierId: tier?._id,
      });
      if (result) {
        setNotification({
          openNotification: true,
          message: `${result?.data?.msg}`,
        });
      } else {
        setNotification({
          openNotification: true,
          message: `Error: ${result?.error?.data?.msg}`,
        });
      }
    } else {
      setNotification({
        openNotification: true,
        message: "Please choose a brokerage first.",
      });
    }
  };

  const handleUpdateChatParticipantsForTier = async () => {
    if (tier && tier?._id) {
      handleCloseUpdateChatParticipantsForTierDialog();
      const result = await updateChatParticipantsForTier({
        tierId: tier?._id,
      });
      if (result) {
        setNotification({
          openNotification: true,
          message: `${result?.data?.msg}`,
        });
      } else {
        setNotification({
          openNotification: true,
          message: `Error: ${result?.error?.data?.msg}`,
        });
      }
    } else {
      setNotification({
        openNotification: true,
        message: "Tier ID is not passed.",
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, openNotification: false });
  };

  return (
    <>
      <Container maxWidth="md">
        <Paper sx={{ p: 2, mb: 3, display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row", // Set to row to align items horizontally
              alignItems: "center", // Align items vertically in the center
              justifyContent: "space-between", // Add space between the elements
              width: "100%", // Take full width to spread items across
              p: 1, // Padding around the Box
            }}
          >
            <Typography variant="h5" fontWeight={700}>
              User Management
            </Typography>
            <UserManagementMenu
              handleOpenInviteNewMemberDialog={handleOpenInviteNewMemberDialog}
              handleOpenInviteMultipleMembersDialog={
                handleOpenInviteMultipleMembersDialog
              }
              handleSendSetupPasswordEmail={handleSendSetupPasswordEmail}
              isSendSetupPasswordEmailLoading={isSendingEmail}
              handleOpenInviteMembersCSVDialog={
                handleOpenInviteMembersCSVDialog
              }
              handleOpenUpdateChatParticipantsForTierDialog={
                handleOpenUpdateChatParticipantsForTierDialog
              }
              isUpdateChatParticipantsLoading={isUpdateChatParticipantsLoading}
            />
          </Box>
          <InviteNewMemberToBrokerageDialog
            tierId={tier?._id}
            brokerageId={brokerageId}
            open={openInviteNewMemberDialog}
            onClose={handleCloseInviteNewMemberDialog}
            brokerageName={brokerageName}
          />
          <InviteMultipleMembersDialog
            preselectedTier={tier}
            open={openInviteMultipleMembersDialog}
            onClose={handleCloseInviteMultipleMembersDialog}
          />
          <InviteMembersCSVDialog
            preselectedTier={tier}
            open={openInviteMembersCSVDialog}
            onClose={handleCloseInviteMembersCSVDialog}
          />
          <ConfirmationDialog
            open={openUpdateChatParticipantsForTierConfirmationDialog}
            onClose={handleCloseUpdateChatParticipantsForTierDialog}
            onConfirm={handleUpdateChatParticipantsForTier}
            title="Confirm Updating Chat Participants"
            description="Are you sure you want to Update Chat Participants for this Office?"
          />
          <NotificationSnackbar
            open={notification.openNotification}
            message={notification.message}
            onClose={handleClose}
          />
        </Paper>

        {Array.isArray(members) ? (
          <MembersList members={members} tierId={tier?._id} />
        ) : (
          <p>{members?.msg}</p>
        )}
      </Container>
    </>
  );
};

export default memo(MembersManager);

import React, { useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";

const SubscriptionPlanTable = ({
  subscriptionPlans,
  productCollections,
  order,
  orderBy,
  page,
  rowsPerPage,
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
  handleEditClick,
  handleDeleteClick,
}) => {
  const [hoveredDeleteId, setHoveredDeleteId] = useState(null);
  const rowColour = "#ffffff"; // Alternate row color
  const rowColourAlt = "#f6f6f6"; // Alternate row color

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === "name"}
                direction={orderBy === "name" ? order : "asc"}
                onClick={() => handleRequestSort("name")}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell>Client Type</TableCell>
            <TableCell>Product Collections</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Billing Cycle</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptionPlans
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((plan, index) => (
              <React.Fragment key={plan._id}>
                <TableRow
                  key={plan._id}
                  sx={{
                    backgroundColor: index % 2 === 0 ? rowColour : rowColourAlt,
                  }}
                >
                  <TableCell>{plan.name}</TableCell>
                  <TableCell>{plan.clientType}</TableCell>
                  <TableCell>
                    <>
                      <ul>
                        {plan.productCollections
                          .map((collectionId) =>
                            productCollections.find(
                              (col) => col._id === collectionId
                            )
                          )
                          .filter((collection) => collection) // Filter out any undefined collections
                          .map((collection, ind) => (
                            <li key={collection._id || ind}>
                              {collection.name}
                            </li>
                          ))}
                      </ul>
                    </>
                  </TableCell>
                  <TableCell>{plan.currency}</TableCell>
                  <TableCell>{plan.billingCycle}</TableCell>
                  <TableCell>{plan.status}</TableCell>
                  <TableCell rowSpan={2}>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => handleEditClick(plan)}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => handleDeleteClick(plan._id)}
                        color="error"
                        onMouseEnter={() => setHoveredDeleteId(plan._id)}
                        onMouseLeave={() => setHoveredDeleteId(null)}
                      >
                        <Delete
                          sx={{
                            color:
                              hoveredDeleteId === plan._id ? "red" : "inherit",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <TableRow
                  key={`${plan._id}-description`}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#ffffff" : "#f6f6f6",
                  }}
                >
                  <TableCell colSpan={6}>
                    <b>Description:</b> {plan.description}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={subscriptionPlans.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default SubscriptionPlanTable;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const widgetPostsApi = createApi({
  reducerPath: "widget-posts",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/widget-posts/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["WidgetPosts"],
  endpoints: (builder) => ({
    getAllEventPosts: builder.query({
      query: ({ userId, tierId, categoryType }) => {
        return {
          url: `all-event-posts/${tierId}`,
          method: "GET",
        };
      },
      skip: (tierId, userId, categoryType) =>
        !tierId ||
        tierId === "undefined" ||
        tierId === null ||
        !userId ||
        categoryType !== "event",
      providesTags: [{ type: "WidgetPosts", id: "LIST" }],
    }),
    getAllBusinessPosts: builder.query({
      query: ({ userId, tierId, categoryPath }) => {
        return {
          url: `all-business-tip-posts/${tierId}`,
          method: "GET",
        };
      },
      skip: (tierId, userId, categoryPath) =>
        !tierId ||
        tierId === "undefined" ||
        tierId === null ||
        !userId ||
        categoryPath !== "business-tips",
      providesTags: [{ type: "WidgetPosts", id: "LIST" }],
    }),
    getAllMarketingPosts: builder.query({
      query: ({ userId, tierId, categoryPath }) => {
        return {
          url: `all-marketing-posts/${tierId}`,
          method: "GET",
        };
      },
      skip: (tierId, userId, categoryPath) =>
        !tierId ||
        tierId === "undefined" ||
        tierId === null ||
        !userId ||
        categoryPath !== "marketing-tips",
      providesTags: [{ type: "WidgetPosts", id: "LIST" }],
    }),
    getAllNinjaPosts: builder.query({
      query: ({ userId, tierId, categoryPath }) => {
        return {
          url: `all-ninja-posts/${tierId}`,
          method: "GET",
        };
      },
      skip: (tierId, userId, categoryPath) =>
        !tierId ||
        tierId === "undefined" ||
        tierId === null ||
        !userId ||
        categoryPath !== "ninja-tip",
      providesTags: [{ type: "WidgetPosts", id: "LIST" }],
    }),

    getAllStrataPosts: builder.query({
      query: ({ userId, tierId, categoryPath }) => {
        return {
          url: `all-strata-posts/${tierId}`,
          method: "GET",
        };
      },
      skip: (tierId, userId, categoryPath) =>
        !tierId ||
        tierId === "undefined" ||
        tierId === null ||
        !userId ||
        categoryPath !== "strata-tip",
      providesTags: [{ type: "WidgetPosts", id: "LIST" }],
    }),

    getAllLegallySpeakingPosts: builder.query({
      query: ({ userId, tierId, categoryPath }) => {
        return {
          url: `all-legally-speaking-posts/${tierId}`,
          method: "GET",
        };
      },
      skip: (tierId, userId, categoryPath) =>
        !tierId ||
        tierId === "undefined" ||
        tierId === null ||
        !userId ||
        categoryPath !== "legally-speaking",
      providesTags: [{ type: "WidgetPosts", id: "LIST" }],
    }),
    getAllAnnouncementsPosts: builder.query({
      query: ({ userId, tierId, categoryType }) => {
        return {
          url: `all-announcements-posts/${tierId}`,
          method: "GET",
        };
      },
      skip: (tierId, userId, categoryType) =>
        !tierId ||
        tierId === "undefined" ||
        tierId === null ||
        !userId ||
        categoryType !== "announcements",
      providesTags: [{ type: "WidgetPosts", id: "LIST" }],
    }),
    getAllListingsPosts: builder.query({
      query: ({ userId, tierId, categoryType }) => {
        return {
          url: `all-listings-posts/${tierId}`,
          method: "GET",
        };
      },
      skip: (tierId, userId, categoryType) =>
        !tierId ||
        tierId === "undefined" ||
        tierId === null ||
        !userId ||
        categoryType !== "listing",
      providesTags: [{ type: "WidgetPosts", id: "LIST" }],
    }),
  }),
});

export const {
  useGetAllEventPostsQuery,
  useGetAllNinjaPostsQuery,
  useGetAllMarketingPostsQuery,
  useGetAllBusinessPostsQuery,
  useGetAllStrataPostsQuery,
  useGetAllLegallySpeakingPostsQuery,
  useGetAllAnnouncementsPostsQuery,
  useGetAllListingsPostsQuery,
} = widgetPostsApi;

export default widgetPostsApi;
export { widgetPostsApi };

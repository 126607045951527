import React, { useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import {
  useAddToLeadBrokerageMutation,
  useRemoveFromLeadBrokerageMutation,
  useGetAllLeadBrokerageProfilesQuery,
  useGetAllBrokerageProfilesQuery,
} from "../../../services/brokerage";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const AddRemoveAssignUnassignToFromLeadBrokerageDialog = ({
  open,
  onClose,
  action,
  selectedBrokerageObj,
}) => {
  const { userId, userRole, accountType } = useContext(AuthContext);
  const filteredByBrokerage = "brokerage";
  const { data: brokerages } = useGetAllBrokerageProfilesQuery(
    { accountType, filteredByBrokerage },
    {
      skip:
        !userId && (userRole !== "reosadmin" || userRole !== "reossuperadmin"),
    }
  );
  const { data: leadBrokerages } = useGetAllLeadBrokerageProfilesQuery(userId, {
    skip: !userId,
  });

  const [addToLeadBrokerage, { isLoading: isAddToLBLoading }] =
    useAddToLeadBrokerageMutation();
  const [removeFromLeadBrokerage, { isLoading: isRemoveFromLBLoading }] =
    useRemoveFromLeadBrokerageMutation();
  const [selectedBrokerage, setSelectedBrokerage] = useState(null);
  const [selectedLeadBrokerage, setSelectedLeadBrokerage] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedBrokerage?._id === selectedLeadBrokerage?._id) {
      setNotification({
        open: true,
        message: "Please, you have to choose different brokerages",
      });
      return;
    }

    let result;
    if (action === "addToLeadBrokerage") {
      result = await addToLeadBrokerage({
        brokerageId: selectedBrokerageObj
          ? selectedBrokerageObj?._id
          : selectedBrokerage?._id,
        leadBrokerageId: selectedLeadBrokerage?._id,
      });
    } else if (action === "removeFromLeadBrokerage") {
      result = await removeFromLeadBrokerage({
        brokerageId: selectedBrokerageObj
          ? selectedBrokerageObj?._id
          : selectedBrokerage?._id,
        leadBrokerageId: selectedLeadBrokerage?._id,
      });
    }

    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        onClose();
        setSelectedLeadBrokerage(null);
        setSelectedBrokerage(null);
      }, 2000);
    } else {
      setNotification({
        open: true,
        message: `Error: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Typography>
          {action === "addToLeadBrokerage"
            ? "Add To Lead Brokerage"
            : action === "removeFromLeadBrokerage"
            ? "Remove From Lead Brokerage"
            : ""}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} direction="column">
            <Grid item sx={{ padding: "2.5rem 0" }}>
              <Autocomplete
                value={selectedLeadBrokerage}
                onChange={(event, newValue) => {
                  setSelectedLeadBrokerage(newValue);
                }}
                options={leadBrokerages || []}
                getOptionLabel={(option) =>
                  option ? `${option?.officeName} ${option?.tier?.level}` : ""
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Lead Brokerage"
                    variant="outlined"
                    margin="normal"
                    required
                  />
                )}
              />
            </Grid>
            <Grid item sx={{ padding: "2.5rem 0" }}>
              {selectedBrokerageObj ? (
                <TextField
                  label="Selected Brokerage"
                  variant="outlined"
                  margin="normal"
                  value={`${selectedBrokerageObj?.officeName} ${selectedBrokerageObj?.tier?.level}`}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ) : (
                <Autocomplete
                  value={selectedBrokerage}
                  onChange={(event, newValue) => {
                    setSelectedBrokerage(newValue);
                  }}
                  options={brokerages || []}
                  getOptionLabel={(option) =>
                    option ? `${option?.officeName} ${option?.tier?.level}` : ""
                  }
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select a Brokerage"
                      variant="outlined"
                      margin="normal"
                      required
                    />
                  )}
                />
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={isAddToLBLoading || isRemoveFromLBLoading}
        >
          {isAddToLBLoading || isRemoveFromLBLoading ? (
            <CircularProgress size={24} />
          ) : action === "addToLeadBrokerage" ? (
            "Add To Lead Brokerage"
          ) : action === "removeFromLeadBrokerage" ? (
            "Remove From Lead Brokerage"
          ) : (
            ""
          )}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default AddRemoveAssignUnassignToFromLeadBrokerageDialog;

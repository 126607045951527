import React, { useEffect, useState, useContext, memo } from "react";
import {
  useGetAllUnacknowledgedAnnouncementsQuery,
  useAcknowledgeAnnouncementMutation,
} from "../../services/post";
import { useSocket } from "../../context/SocketContext";
import { AuthContext } from "../../context/AuthContext";
import { AnnouncementDialog } from "../";
import NotificationSnackbar from "./notification-snackbar";

const GlobalAnnouncement = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [currentAnnouncement, setCurrentAnnouncement] = useState(null);
  const { socket } = useSocket();
  const { userId, isTokenReady } = useContext(AuthContext);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [
    acknowledgeAnnouncement,
    { isLoading: isLoadingAcknowledge, refetch: refetchAnnouncements },
  ] = useAcknowledgeAnnouncementMutation();
  const { data: posts } = useGetAllUnacknowledgedAnnouncementsQuery(userId, {
    skip: !isTokenReady || !userId,
  });

  useEffect(() => {
    if (posts) {
      setAnnouncements(posts);
      setCurrentAnnouncement(posts[0]);
    }
  }, [posts, userId]);

  useEffect(() => {
    if (socket) {
      socket.on("announcement", (newAnnouncement) => {
        setAnnouncements((prev) => [...prev, newAnnouncement]);
      });
    }

    return () => {
      if (socket) {
        socket.off("announcement");
      }
    };
  }, [socket, userId]);

  const handleAcknowledge = async (postId) => {
    const result = await acknowledgeAnnouncement({
      userId,
      postId,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        if (!isLoadingAcknowledge) {
          const newQueue = announcements?.filter(
            (announcement) => announcement._id !== postId
          );
          setAnnouncements(newQueue);
          setCurrentAnnouncement(newQueue[0]);
          refetchAnnouncements?.();
        }
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `The error occured ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <>
      {currentAnnouncement && (
        <AnnouncementDialog
          open={Boolean(currentAnnouncement)}
          announcement={currentAnnouncement}
          onAcknowledge={handleAcknowledge}
          isPostPage={true}
        />
      )}
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
};

export default memo(GlobalAnnouncement);

import React, { useState, memo } from "react";
import { Typography, Menu, MenuItem } from "@mui/material";

function RSVPButton({ status, onStatusChange }, ref) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleRSVPClick = (event) => {
    event && event.stopPropagation && event.stopPropagation();
    if (status === "RSVP" || status === "Not Going") {
      onStatusChange("Going");
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = (event, newStatus) => {
    event && event.stopPropagation && event.stopPropagation();
    setAnchorEl(null);
    if (status !== newStatus) {
      onStatusChange(newStatus);
    }
  };

  return (
    <>
      <Typography onClick={handleRSVPClick} style={{ cursor: "pointer" }}>
        {status}
      </Typography>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleMenuClose(null, status)}
      >
        <MenuItem onClick={(event) => handleMenuClose(event, "Going")}>
          Going
        </MenuItem>
        <MenuItem onClick={(event) => handleMenuClose(event, "Not Going")}>
          Not Going
        </MenuItem>
      </Menu>
    </>
  );
}

export default memo(RSVPButton);

import React, { useState, useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import {
  useRegenerateDutyScheduleMutation,
  useCheckAvailableTimeSlotsQuery,
} from "../../../../services/duty-scheduling";
import { useSendDutyChangesSummaryEmailMutation } from "../../../../services/duty-emails";
import { useDeleteAllDutyByDutyScheduleMutation } from "../../../../services/duties";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import {
  BuildCircle,
  ForwardToInbox,
  Downloading,
  Clear,
} from "@mui/icons-material";
import NotificationSnackbar from "../../../helpers/notification-snackbar";
import AvailableTimeSlotsDialog from "./AvailableTimeSlotsDialog";
import RegenerateDutySchedulingDialog from "./RegenerateDutySchedulingDialog";
import DeleteAllDutyByDutyScheduleDialog from "./DeleteAllDutyByDutyScheduleDialog";
import { ConfirmationDialog } from "../../..";
import dayjs from "dayjs";

function DutyScheduleManagerMenu({ tierId, dutyScheduleId }) {
  const { userRole } = useContext(AuthContext);
  const isAdminRole = [
    "reossuperadmin",
    "reossupportadmin",
    "reosadmin",
    "brokeragesuperadmin",
    "teamsuperadmin",
  ].includes(userRole);

  const [openDialog, setOpenDialog] = useState(false);
  const [regenerateDialogOpen, setRegenerateDialogOpen] = useState(false);
  const [
    deleteAllDutyByDutyScheduleDialogOpen,
    setDeleteAllDutyByDutyScheduleDialogOpen,
  ] = useState(false);
  const [regenerateDutySchedule, { isLoading: isRegenerating }] =
    useRegenerateDutyScheduleMutation();
  const [
    deleteAllDutyByDutySchedule,
    { isLoading: isDeleteAllDutyByDutyScheduleLoading },
  ] = useDeleteAllDutyByDutyScheduleMutation();
  const [
    sendDutyChangesSummaryEmail,
    { isLoading: isSendDutyChangesSummaryEmailLoading },
  ] = useSendDutyChangesSummaryEmailMutation();
  const { data: availableSlots, isLoading: isFetchingSlots } =
    useCheckAvailableTimeSlotsQuery(
      { tierId, dutyScheduleId },
      {
        skip: !openDialog || !tierId || !dutyScheduleId,
      }
    );
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationDialogAction, setConfirmationDialogAction] =
    useState(null);

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleRegenerate = async (startTime, endTime) => {
    if (!tierId || !dutyScheduleId) {
      setNotification({
        open: true,
        message: "Tier or Duty Schedule ID is not provided.",
      });
      return;
    }

    const startDateToSend = startTime
      ? startTime.toISOString()
      : dayjs().toISOString();
    const endDateToSend = endTime
      ? endTime.toISOString()
      : dayjs().add(1, "month").toISOString();

    const result = await regenerateDutySchedule({
      tierId,
      dutyScheduleId,
      body: { startDate: startDateToSend, endDate: endDateToSend },
    });
    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}.`,
      });
    } else {
      setNotification({
        open: true,
        message: `Failed to regenerate, error: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleSendDutyChangesSummaryEmail = async () => {
    if (!tierId) {
      setNotification({
        open: true,
        message: "Tier ID is not provided.",
      });
      return;
    }

    const result = await sendDutyChangesSummaryEmail(tierId);
    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}.`,
      });
    } else {
      setNotification({
        open: true,
        message: `Failed to send the emails, error: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleFetchAvailableSlots = () => {
    if (!tierId || !dutyScheduleId) {
      setNotification({
        open: true,
        message: "Tier ID is not provided.",
      });
      return;
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenRegenerateDialog = () => {
    setRegenerateDialogOpen(true);
  };

  const handleOpenSendDutyChangesDialog = () => {
    setConfirmationDialogAction("sendEmail");
    setConfirmationDialogOpen(true);
  };

  const handleOpenFetchAvailableSlotsDialog = () => {
    setConfirmationDialogAction("fetchAvailableSlots");
    setConfirmationDialogOpen(true);
  };

  const handleConfirmDialog = async () => {
    if (confirmationDialogAction === "sendEmail") {
      await handleSendDutyChangesSummaryEmail();
    } else if (confirmationDialogAction === "fetchAvailableSlots") {
      handleFetchAvailableSlots();
    }
    setConfirmationDialogOpen(false);
  };

  const handleConfirmRegenerateDialog = async (startTime, endTime) => {
    await handleRegenerate(startTime, endTime);
    setRegenerateDialogOpen(false);
  };

  const handleOpenDeleteAllDutyByDutyScheduleDialog = () => {
    setDeleteAllDutyByDutyScheduleDialogOpen(true);
  };

  const handleConfirmDeletingAllDutiesDialog = async ({ deleteAll }) => {
    if (!dutyScheduleId) {
      setNotification({
        open: true,
        message: "Duty Schedule ID is required.",
      });
      return;
    }
    const result = await deleteAllDutyByDutySchedule({
      dutyScheduleId,
      deleteAll,
    });

    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        setDeleteAllDutyByDutyScheduleDialogOpen(false);
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error deleting all duties: ${
          result?.error?.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Box className="user-management-box">
      <Grid
        container
        spacing={12}
        className="user-management-list"
        justifyContent="space-between"
      >
        {isAdminRole && (
          <Grid item xs={3}>
            <IconButton
              onClick={handleOpenRegenerateDialog}
              className="user-management-item"
            >
              {isRegenerating ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  <BuildCircle className="icon" />
                  <Typography>Regenerate Schedule</Typography>
                </>
              )}
            </IconButton>
          </Grid>
        )}
        {isAdminRole && (
          <Grid item xs={3}>
            <IconButton
              onClick={handleOpenSendDutyChangesDialog}
              className="user-management-item"
            >
              {isSendDutyChangesSummaryEmailLoading ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  <ForwardToInbox className="icon" />
                  <Typography>Send Summary Email(s)</Typography>
                </>
              )}
            </IconButton>
          </Grid>
        )}
        <Grid item xs={3}>
          <Grid item xs={12}>
            <IconButton
              onClick={handleOpenFetchAvailableSlotsDialog}
              className="user-management-item"
            >
              {isFetchingSlots ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  <Downloading className="icon" />
                  <Typography>Fetch Available Slots</Typography>
                </>
              )}
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container xs={3}>
          <Grid item xs={12}>
            <IconButton
              onClick={handleOpenDeleteAllDutyByDutyScheduleDialog}
              className="user-management-item"
            >
              {isDeleteAllDutyByDutyScheduleLoading ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  <Clear className="icon" />
                  <Typography>Clear</Typography>
                </>
              )}
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {availableSlots && (
        <AvailableTimeSlotsDialog
          open={openDialog}
          availableSlots={availableSlots}
          tierId={tierId}
          dutyScheduleId={dutyScheduleId}
          handleCloseDialog={handleCloseDialog}
        />
      )}
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        onConfirm={handleConfirmDialog}
        title={
          confirmationDialogAction === "sendEmail"
            ? "Confirm Sending Emails"
            : confirmationDialogAction === "fetchAvailableSlots"
            ? "Confirm Fetching Available Slots"
            : "No Action"
        }
        description={
          confirmationDialogAction === "sendEmail"
            ? "Are you sure you want to send Summary Email(s)?"
            : confirmationDialogAction === "fetchAvailableSlots"
            ? "Are you sure you want to Fetch Available Slots?"
            : "No Action to confirm"
        }
      />
      <RegenerateDutySchedulingDialog
        open={regenerateDialogOpen}
        onClose={() => setRegenerateDialogOpen(false)}
        onConfirm={handleConfirmRegenerateDialog}
      />
      <DeleteAllDutyByDutyScheduleDialog
        open={deleteAllDutyByDutyScheduleDialogOpen}
        onClose={() => setDeleteAllDutyByDutyScheduleDialogOpen(false)}
        onConfirm={handleConfirmDeletingAllDutiesDialog}
        isLoading={isDeleteAllDutyByDutyScheduleLoading}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Box>
  );
}
export default DutyScheduleManagerMenu;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  TableSortLabel,
} from "@mui/material";
import {
  useGetAllTeamsQuery,
  useDeleteTeamMutation,
} from "../../../services/team";
import { MoreVert, DeleteForever, Edit } from "@mui/icons-material/";
import { ConfirmationDialog } from "../..";
import { useNavigate } from "react-router-dom";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import UpdateTeamDialog from "./UpdateTeamDialog";

function TeamsList() {
  const navigate = useNavigate();
  const [deleteTeam] = useDeleteTeamMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { data: teams, refetch } = useGetAllTeamsQuery();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [currentTeamClicked, setCurrentTeamClick] = useState(null);
  const [teamIdToDelete, setTeamIdToDelete] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [sortField, setSortField] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [teamIdToUpdate, setTeamIdToUpdate] = useState(null);

  const handleRowClick = (event, teamId) => {
    if (
      event.target.closest(".menu-button") ||
      event.target.closest(".view-details-button")
    ) {
      setCurrentTeamClick(teamId);
      return;
    }
    navigate(`/team/${teamId}`);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteTeamClick = (event, id) => {
    event && event.stopPropagation();
    setTeamIdToDelete(id);
    setConfirmationDialogOpen(true);
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleConfirmationDialogConfirm = async () => {
    const result = await deleteTeam({
      teamId: teamIdToDelete,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      refetch();
      handleConfirmationDialogClose();
    } else {
      setNotification({
        open: true,
        message: `Error deleting team. ${result?.data?.msg}`,
      });
      refetch();
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  const handleUpdateTeamClick = (event, id) => {
    event && event.stopPropagation();
    setTeamIdToUpdate(id);
    setUpdateDialogOpen(true);
  };

  if (!teams || teams?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 20,
        }}
      >
        <Typography variant="h5" gutterBottom>
          No teams available
        </Typography>
        <Link to="/team-management">Go to CreatetTeam</Link>
      </Box>
    );
  }

  const sortedTeams = [...teams].sort((a, b) => {
    if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
    if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
    return 0;
  });

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortField === "name"}
                  direction={sortDirection}
                  onClick={() => handleSort("name")}
                >
                  Team Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "teamUrlName"}
                  direction={sortDirection}
                  onClick={() => handleSort("teamUrlName")}
                >
                  Team URL Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "teamLead"}
                  direction={sortDirection}
                  onClick={() => handleSort("teamLead")}
                >
                  Team Lead
                </TableSortLabel>
              </TableCell>
              <TableCell>Members</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "createdAt"}
                  direction={sortDirection}
                  onClick={() => handleSort("createdAt")}
                >
                  Created At
                </TableSortLabel>
              </TableCell>
              <TableCell>Actions </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(sortedTeams) &&
              sortedTeams?.map((team) => (
                <TableRow
                  key={team?._id}
                  onClick={(event) => handleRowClick(event, team?._id)}
                  style={{ cursor: "pointer" }}
                  className="table-row"
                >
                  <TableCell>{team?.name}</TableCell>
                  <TableCell>{team?.teamUrlName}</TableCell>
                  <TableCell>{`${team?.teamLead?.member?.user?.firstName} ${team?.teamLead?.member?.user?.lastName}`}</TableCell>
                  <TableCell>{team?.members.length}</TableCell>
                  <TableCell>
                    {new Date(team?.createdAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        aria-label="delete"
                        onClick={(event) =>
                          handleDeleteTeamClick(event, team?._id)
                        }
                      >
                        <DeleteForever />
                      </IconButton>
                      <IconButton
                        aria-label="edit"
                        onClick={(event) =>
                          handleUpdateTeamClick(event, team?._id)
                        }
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        className="menu-button"
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleMenuOpen(event, team?._id)}
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                      >
                        <MenuItem>
                          <Typography
                            variant="body1"
                            className="view-details-button"
                            onClick={() =>
                              navigate(`/team/${currentTeamClicked}`)
                            }
                          >
                            View Details
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmationDialogConfirm}
        title="Confirm Deleting the Team"
        description="Are you sure you want to delete this Team permanently?"
      />
      <UpdateTeamDialog
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
        teamId={teamIdToUpdate}
        refetchTeams={refetch}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
}

export default TeamsList;

import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useGetAllLevelsBrokerageProfilesQuery } from "../../../services/brokerage";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import {
  useGetTeamByIdQuery,
  useUpdateTeamMutation,
} from "../../../services/team";
import { useFetchAllUsersQuery } from "../../../services/user";

const UpdateTeamDialog = ({ open, onClose, teamId, refetchTeams }) => {
  const { userId, userRole } = useContext(AuthContext);
  const { data: brokerages } = useGetAllLevelsBrokerageProfilesQuery(userId, {
    skip:
      !userId && (userRole !== "reosadmin" || userRole !== "reossuperadmin"),
  });

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [name, setName] = useState("");
  const [selectedBrokerageId, setSelectedBrokerageId] = useState("");
  const [selectedMemberId, setSelectedMemberId] = useState("");
  const { data: users } = useFetchAllUsersQuery();
  const { data: team, isLoading: isTeamLoading } = useGetTeamByIdQuery(teamId, {
    skip: !teamId,
  });
  const [updateTeam, { isLoading }] = useUpdateTeamMutation();

  useEffect(() => {
    if (team) {
      setName(team.name);
      setSelectedMemberId(
        team.teamLead && team.teamLead.member ? team.teamLead.member.user : ""
      );
      setSelectedBrokerageId(team.brokerage ? team.brokerage : "");
    }
  }, [team]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await updateTeam({
      teamId,
      name,
      memberId: selectedMemberId._id,
      brokerageId: selectedBrokerageId._id,
    });

    if (result?.data) {
      setNotification({ open: true, message: `${result?.data?.msg}` });
      refetchTeams?.();
      onClose();
    } else {
      setNotification({
        open: true,
        message: `Error updating team: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (isTeamLoading) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Update Team</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            label="Team Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
          />
          <Autocomplete
            value={selectedMemberId}
            onChange={(event, newValue) => {
              setSelectedMemberId(newValue);
            }}
            options={users || []}
            getOptionLabel={(option) =>
              option
                ? `${option?.firstName} ${option?.lastName} ${option?.email}`
                : ""
            }
            isOptionEqualToValue={(option, value) => option?._id === value._id}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Team Lead"
                variant="outlined"
                margin="normal"
                required
              />
            )}
          />
          <Autocomplete
            value={selectedBrokerageId}
            onChange={(event, newValue) => {
              setSelectedBrokerageId(newValue);
            }}
            options={brokerages || []}
            getOptionLabel={(option) =>
              option
                ? `${option.officeName} ${option.officeShortName} (${
                    option &&
                    option?.isLeadBrokerage &&
                    option?.leadBrokerageTier
                      ? "is lead brokerage"
                      : ""
                  })`
                : ""
            }
            isOptionEqualToValue={(option, value) => option?._id === value._id}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Brokerage"
                variant="outlined"
                margin="normal"
                required
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : "Update Team"}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default UpdateTeamDialog;

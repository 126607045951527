import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetTicketByIdQuery } from "../../../services/ticket";
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { BackButton } from "../..";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const TicketPage = () => {
  const { ticketId } = useParams();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const {
    data: ticket,
    error,
    isLoading,
  } = useGetTicketByIdQuery(ticketId, {
    skip: !ticketId,
  });

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (isLoading)
    return (
      <div className="loading-spinner">
        <CircularProgress size={24} />
      </div>
    );
  if (!ticket) {
    return (
      <Typography variant="h4" gutterBottom>
        No ticket Found
      </Typography>
    );
  }

  if (error) return <div>Error: {error?.data?.msg}</div>;

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <BackButton />
      </Box>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                ticket type: {ticket?.type || ""}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                ticket description: {ticket?.description || ""}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                ticket status: {ticket?.status || ""}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                ticket priority: {ticket?.priority || ""}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                ticket createdAt: {ticket?.createdAt || ""}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                ticket updatedAt: {ticket?.updatedAt || ""}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Container>
  );
};

export default TicketPage;

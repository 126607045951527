import React, { useState } from "react";
import InvitationDetailsInfo from "./InvitationDetailsInfo"; // Adjust the import path as necessary

import {
  useGetAllInvitationsByTierQuery,
  useRemoveInvitationMutation,
} from "../../../services/invitation";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  TextField,
  TableSortLabel,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { MoreVert, DeleteForever } from "@mui/icons-material/";
import { ConfirmationDialog } from "../..";
// import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../helpers/utils";

function InvitationList({ tierId }) {
  // const navigate = useNavigate();
  const { data: invitations = [] } = useGetAllInvitationsByTierQuery(tierId, {
    skip: !tierId,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [dialogOpen, setDeleteDialogOpen] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedInvitation, setSelectedInvitation] = useState(null);
  const [invitationIdToRemove, setInvitationIdToRemove] = useState(null);
  // const [, setCurrentInvitationClick] = useState(null);
  const [removeInvitation] = useRemoveInvitationMutation();
  const [sortField, setSortField] = useState("userEmail");
  const [sortDirection, setSortDirection] = useState("asc");
  const [statusFilter, setStatusFilter] = useState("");
  const [accountTypeFilter, setAccountTypeFilter] = useState("");
  const [textFilter, setTextFilter] = useState("");

  // Function to open the dialog
  const handleOpenDialog = (invitation) => {
    setSelectedInvitation(invitation);
    setDialogOpen(true);
    handleMenuClose(); // This will close the menu
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleTextFilterChange = (event) => {
    setTextFilter(event.target.value.toLowerCase());
  };

  if (!invitations || invitations.length === 0) {
    return <div>No invitations available</div>;
  }

  let filteredInvitations = invitations.filter((invitation) => {
    return (
      invitation.sender.firstName.toLowerCase().includes(textFilter) ||
      invitation.sender.lastName.toLowerCase().includes(textFilter) ||
      invitation.userEmail.toLowerCase().includes(textFilter) ||
      invitation.status.toLowerCase().includes(textFilter) ||
      invitation.accountType.toLowerCase().includes(textFilter)
    );
  });

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = (event, invitationId) => {
    event.stopPropagation();
    setInvitationIdToRemove(invitationId);
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = (event) => {
    event && event.stopPropagation();
    setDeleteDialogOpen(false);
    setInvitationIdToRemove(null);
  };

  const handleDialogConfirm = async (event) => {
    event && event.stopPropagation();
    await removeInvitation(invitationIdToRemove);
    handleDialogClose();
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  if (!invitations || invitations?.length === 0) {
    return <div>No invitations available</div>;
  }

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleAccountTypeFilterChange = (event) => {
    setAccountTypeFilter(event.target.value);
  };

  if (statusFilter) {
    filteredInvitations = filteredInvitations?.filter(
      (invitation) => invitation?.status === statusFilter
    );
  }
  if (accountTypeFilter) {
    filteredInvitations = filteredInvitations?.filter(
      (invitation) => invitation?.accountType === accountTypeFilter
    );
  }

  if (statusFilter) {
    filteredInvitations = filteredInvitations.filter(
      (invitation) => invitation.status === statusFilter
    );
  }

  if (accountTypeFilter) {
    filteredInvitations = filteredInvitations.filter(
      (invitation) => invitation.accountType === accountTypeFilter
    );
  }

  const sortedAndFilteredInvitations = filteredInvitations.sort((a, b) => {
    if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
    if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
    return 0;
  });

  return (
    <>
      <Box
        sx={{ mt: 0, mb: 2, display: "flex", gap: "5px" }}
        className="management-page"
      >
        <TextField
          label="Search Invitations"
          variant="outlined"
          size="small"
          sx={{ mt: 2 }}
          fullWidth
          value={textFilter}
          onChange={handleTextFilterChange}
        />

        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          sx={{ mt: 2, minWidth: 120 }}
        >
          <InputLabel id="status-filter-label">Filter by status</InputLabel>
          <Select
            labelId="status-filter-label"
            value={statusFilter}
            onChange={handleStatusFilterChange}
            label="Filter by status"
            sx={{ mt: 0 }}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="active">Accepted</MenuItem>
            <MenuItem value="active">Declined</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          sx={{ mt: 2, minWidth: 120 }}
        >
          <InputLabel id="account-type-filter-label">
            Filter by account type
          </InputLabel>
          <Select
            labelId="account-type-filter-label"
            value={accountTypeFilter}
            onChange={handleAccountTypeFilterChange}
            label="Filter by account type"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="realtyadmin">RealtyOS Admin</MenuItem>
            <MenuItem value="realtor">Realtor</MenuItem>
            <MenuItem value="client">Client</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <TableContainer component={Paper}>
        <Table className="user-table">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortField === "userEmail"}
                  direction={sortDirection}
                  onClick={() => handleSort("userEmail")}
                >
                  User Email
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "accountType"}
                  direction={sortDirection}
                  onClick={() => handleSort("accountType")}
                >
                  Account Type
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "tier.level"}
                  direction={sortDirection}
                  onClick={() => handleSort("tier.level")}
                >
                  Tier
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "status"}
                  direction={sortDirection}
                  onClick={() => handleSort("status")}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "sentAt"}
                  direction={sortDirection}
                  onClick={() => handleSort("sentAt")}
                >
                  Sent at
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "sender"}
                  direction={sortDirection}
                  onClick={() => handleSort("sender")}
                >
                  Sent By Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Actions </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {sortedAndFilteredInvitations?.map((invitation) => (
              <TableRow className="table-row" key={invitation?._id}>
                <TableCell>{invitation?.userEmail}</TableCell>
                <TableCell>{invitation?.accountType}</TableCell>
                <TableCell>{invitation?.tier?.level}</TableCell>
                <TableCell>{invitation?.status}</TableCell>
                <TableCell>
                  {new Date(invitation?.sentAt)?.toLocaleString()}
                </TableCell>
                <TableCell>
                  {capitalizeFirstLetter(invitation?.sender?.firstName)}{" "}
                  {capitalizeFirstLetter(invitation?.sender?.lastName)}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      aria-label="delete"
                      onClick={(event) =>
                        handleDeleteClick(event, invitation?._id)
                      }
                    >
                      <DeleteForever />
                    </IconButton>
                    <IconButton
                      className="menu-button"
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(event) =>
                        handleMenuOpen(event, invitation?._id)
                      }
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={() => handleOpenDialog(invitation)}>
                        <Typography
                          variant="body1"
                          className="view-details-button"
                        >
                          View Details
                        </Typography>
                      </MenuItem>
                    </Menu>
                    <ConfirmationDialog
                      open={dialogOpen}
                      onClose={handleDialogClose}
                      onConfirm={handleDialogConfirm}
                      title="Confirm Deleting"
                      description="Are you sure you want to delete this invitation?"
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog Component */}
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Invitation Details</DialogTitle>
        <DialogContent>
          {selectedInvitation && (
            <InvitationDetailsInfo invitation={selectedInvitation} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InvitationList;

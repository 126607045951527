import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetRoleByIdQuery } from "../../../services/role";
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { BackButton } from "../..";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const RoleItemPage = () => {
  const { roleId } = useParams();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const {
    data: role,
    error,
    isLoading,
  } = useGetRoleByIdQuery(roleId, {
    skip: !roleId,
  });

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (isLoading)
    return (
      <div className="loading-spinner">
        <CircularProgress size={24} />
      </div>
    );
  if (!role) {
    return (
      <Typography variant="h4" gutterBottom>
        No Role Found
      </Typography>
    );
  }

  if (error) return <div>Error: {error?.data?.msg}</div>;

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <BackButton />
      </Box>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h3" gutterBottom>
                Role Name: {role?.name || ""}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Role User:
                {` ${role?.user?.firstName || ""} ${role?.user?.lastName || ""}
                     ${role?.user?.email || ""}
                     `}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  p: 2,
                  width: "100%",
                }}
              >
                <Typography variant="h5" component="div">
                  Permissions:
                </Typography>
                {role?.permissions?.map((perm) => (
                  <ListItem
                    key={perm?._id}
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <ListItemText
                      className="item-text"
                      primary={perm?.action}
                    />
                    <Divider />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Container>
  );
};

export default RoleItemPage;

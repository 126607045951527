import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const dutiesApi = createApi({
  reducerPath: "duties",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/duties/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Duty", "DutyScheduling"],
  endpoints: (builder) => ({
    getAllDuties: builder.query({
      query: () => ({
        url: "get-all",
        method: "GET",
      }),
      providesTags: [
        { type: "Duty", id: "LIST" },
        { type: "DutyScheduling", id: "LIST" },
      ],
    }),
    getDutyById: builder.query({
      query: (dutyId) => ({
        url: `get-one-by-id/${dutyId}`,
        method: "GET",
      }),
      skip: (dutyId) => !dutyId,
      providesTags: [
        { type: "Duty", id: "LIST" },
        { type: "DutyScheduling", id: "LIST" },
      ],
    }),
    createDuty: builder.mutation({
      query: ({ dutyData }) => ({
        url: `create-one`,
        method: "POST",
        body: { dutyData },
      }),
      invalidatesTags: [
        { type: "Duty", id: "LIST" },
        { type: "DutyScheduling", id: "LIST" },
      ],
    }),
    updateDuty: builder.mutation({
      query: ({ dutyId, dutyData }) => ({
        url: `update-one/${dutyId}`,
        method: "PUT",
        body: { dutyData },
      }),
      invalidatesTags: [
        { type: "Duty", id: "LIST" },
        { type: "DutyScheduling", id: "LIST" },
      ],
    }),
    deleteDuty: builder.mutation({
      query: ({ dutyId }) => ({
        url: `delete-one/${dutyId}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "Duty", id: "LIST" },
        { type: "DutyScheduling", id: "LIST" },
      ],
    }),
    deleteAllDutyByTier: builder.mutation({
      query: ({ tierId }) => ({
        url: `delete-all-by-tier/${tierId}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "Duty", id: "LIST" },
        { type: "DutyScheduling", id: "LIST" },
      ],
    }),
    deleteAllDutyByDutySchedule: builder.mutation({
      query: ({ dutyScheduleId, deleteAll }) => ({
        url: `delete-all-by-duty-schedule/${dutyScheduleId}`,
        method: "DELETE",
        body: { deleteAll },
      }),
      invalidatesTags: [
        { type: "Duty", id: "LIST" },
        { type: "DutyScheduling", id: "LIST" },
      ],
    }),
    deleteAllDuties: builder.mutation({
      query: () => ({
        url: `delete-all`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "Duty", id: "LIST" },
        { type: "DutyScheduling", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetAllDutiesQuery,
  useGetDutyByIdQuery,
  useCreateDutyMutation,
  useUpdateDutyMutation,
  useDeleteDutyMutation,
  useDeleteAllDutyByTierMutation,
  useDeleteAllDutyByDutyScheduleMutation,
  useDeleteAllDutiesMutation,
} = dutiesApi;

export default dutiesApi;
export { dutiesApi };

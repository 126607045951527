import React from "react";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import {
  Leaderboard,
  BusinessCenter,
  Dashboard,
  Group,
  Campaign,
  Update,
  Cake,
  Moving,
  People,
  CalendarMonth,
  ListAlt,
  Forum,
  Poll,
  School,
  Chat,
  DirectionsCarFilled,
  Folder,
  Gavel,
  MenuBook,
  House,
  CastForEducation,
  Shield,
  AdsClick,
  Ballot,
  Plumbing,
  Apartment,
  LocalCafe,
  LocalGroceryStore,
  AttachMoney,
  Domain,
  PriorityHigh,
  // Message,
  // QuestionAnswer,
  // Article,
  // Scale,
  // Description,
  // AccountBalance,
  // Assignment,
  // Build,
  // Carpenter,
} from "@mui/icons-material";
import { NinjaIcon } from "./NinjaIcon";
import { RLPIcon } from "./RLPIcon";

export const categoryIcon = (icon) => {
  switch (icon) {
    case "dashboard":
      return <Dashboard />;
    case "group":
      return <Group />;
    case "campaign":
      return <Campaign />;
    case "update":
      return <Update />;
    case "birthday":
      return <Cake />;
    case "moving":
      return <Moving />;
    case "calendar-month":
      return <CalendarMonth />;
    case "list-alt":
      return <ListAlt />;
    case "forum":
      return <Forum />;
    case "people":
      return <People />;
    case "poll":
      return <Poll />;
    case "school":
      return <School />;
    case "domain":
      return <Domain />;
    case "rankings":
      return <Leaderboard />;
    case "ninja":
      return <NinjaIcon />;
    case "car":
      return <DirectionsCarFilled />;
    case "house":
      return <House />;
    case "gavel":
      return <Gavel />;
    case "legal":
      return <Ballot />;
    case "library-books":
      return <MenuBook />;
    case "training":
      return <CastForEducation />;
    case "folder":
      return <Folder />;
    case "shield":
      return <Shield />;
    case "marketing":
      return <AdsClick />;
    case "chat":
      return <Chat />;
    case "wants-needs":
      return <LocalGroceryStore />;
    case "services-and-trades":
      return <Plumbing />;
    case "water-cooler":
      return <LocalCafe />;
    case "money":
      return <AttachMoney />;
    case "building":
      return <Apartment />;
    case "business":
      return <BusinessCenter />;
    case "rlp":
      return <RLPIcon />;
    case "danger":
      return <PriorityHigh />;
    default:
      return <ListAlt />;
  }
};

export const truncateText = (text, length) => {
  if (text.length <= length) {
    return text;
  }
  return `${text.substring(0, length)}...`;
};

export const handleReacircGroupName = (name) => {
  if (!name) return;
  const specialNames = ["system", "global", "reacirc", "resources"];

  const lowerName = name?.toLowerCase();

  if (specialNames.includes(lowerName)) {
    return name?.toUpperCase();
  } else {
    return name?.charAt(0)?.toUpperCase() + name?.slice(1)?.toLowerCase();
  }
};

export const RotateIcon = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transition: theme.transitions.create("transform", {
    duration: "0.8s",
  }),
  transform: expand ? "rotate(0deg)" : "rotate(270deg)",
}));

import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useGetDutyByIdQuery } from "../../../../services/duties";
import { useConfirmAvailableDutyMutation } from "../../../../services/duty-scheduling";
import { CircularProgress, Box, Button } from "@mui/material";
import DutyCard from "./DutyCard";
import NotificationSnackbar from "../../../helpers/notification-snackbar";

const ConfirmSelectedDutyTimeSlot = () => {
  const { dutyId } = useParams();
  const [confirmAvailableDuty, { isLoading }] =
    useConfirmAvailableDutyMutation();
  const { data: duty } = useGetDutyByIdQuery(dutyId, {
    skip: !dutyId,
  });
  const navigate = useNavigate();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleConfirmAvailableDuty = async () => {
    if (!dutyId || !duty) {
      setNotification({
        open: true,
        message: "Duty or duty ID is not provided.",
      });
      return;
    }

    const result = await confirmAvailableDuty(dutyId);

    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}.`,
      });
      setTimeout(() => navigate("/home"), 2000);
    } else {
      setNotification({
        open: true,
        message: `Failed to confirm the Available Duty, error: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Box className="home-page">
      {duty ? <DutyCard duty={duty} /> : "No Duty has been found"}
      <Box display="flex" gap={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirmAvailableDuty}
          disabled={isLoading || !duty}
          fullWidth
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <>Confirm Available Duty</>
          )}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate("/home")}
        >
          Cancel
        </Button>
      </Box>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Box>
  );
};

export default ConfirmSelectedDutyTimeSlot;

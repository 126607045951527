import React, { useEffect, useRef } from "react";
import { Snackbar, Alert } from "@mui/material";

const GlobalNotification = ({
  basicNotifications,
  removeBasicNotification,
}) => {
  const timeoutRefs = useRef([]);

  useEffect(() => {
    basicNotifications.forEach((notification) => {
      const timeoutId = setTimeout(() => {
        removeBasicNotification(notification.id);
      }, 5000);

      timeoutRefs.current.push(timeoutId);
    });
    return () => {
      timeoutRefs.current.forEach((id) => clearTimeout(id));
    };
  }, [basicNotifications, removeBasicNotification]);

  return (
    <>
      {basicNotifications?.map((notification, index) => (
        <Snackbar
          key={notification?.id}
          open={true}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          style={{ bottom: `${index * 60}px` }}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            {notification?.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};

export default GlobalNotification;

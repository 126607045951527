import React, { useState, useMemo, useContext, memo } from "react";
import { AuthContext } from "../../../context/AuthContext";
import {
  useAssignDefaultBrokerageToManyMembersMutation,
  useAddBrokerageToManyMembersMutation,
} from "../../../services/member";
import { useFetchAllUsersQuery } from "../../../services/user";
import {
  useGetAllTiersQuery,
  useGetAllBrokerageLevelTiersQuery,
} from "../../../services/tier";
import {
  Select,
  InputLabel,
  Table,
  TextField,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  TableSortLabel,
  CircularProgress,
  Button,
  Tooltip,
  Checkbox,
  FormControl,
  Autocomplete,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material/";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../helpers/utils";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import NotificationSnackbar from "../../helpers/notification-snackbar";

dayjs.extend(dayjsPluginUTC);

function MembersToManageTierList() {
  const { userId, accountType } = useContext(AuthContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const filteredByBrokerage = "brokerage";
  const { data: members } = useFetchAllUsersQuery(accountType, {
    skip: accountType !== "reosadmin",
  });
  const { data: tiers } = useGetAllTiersQuery(accountType, {
    skip: !userId && accountType !== "reosadmin",
  });
  const { data: brokerageTiers } = useGetAllBrokerageLevelTiersQuery(
    { accountType, filteredByBrokerage },
    {
      skip: !userId && accountType !== "reosadmin",
    }
  );

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedTierBrokerage, setSelectedTierBrokerage] = useState(null);
  const [
    assignDefaultBrokerageToManyMembers,
    { isLoading: isAssignDefaultBrokerageToManyMembersLoading },
  ] = useAssignDefaultBrokerageToManyMembersMutation();
  const [
    addBrokerageToManyMembers,
    { isLoading: isAddBrokerageToManyMembersLoading },
  ] = useAddBrokerageToManyMembersMutation();

  const [statusFilter, setStatusFilter] = useState("");
  const [accountTypeFilter, setAccountTypeFilter] = useState("");
  const [brokerageFilter, setBrokerageFilter] = useState("");
  const [currentMemberClicked, setCurrentMemberClicked] = useState(null);
  const [sortField, setSortField] = useState("email");
  const [sortDirection, setSortDirection] = useState("asc");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleRowClick = (event, memberUser) => {
    if (
      event.target.closest(".menu-button") ||
      event.target.closest(".view-details-button") ||
      event.target.closest(".select-checkbox")
    ) {
      setCurrentMemberClicked(memberUser);
      return;
    }
    navigate(`/member-profile/${memberUser?._id}`);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    if (event) event.stopPropagation();
    setAnchorEl(null);
    setCurrentMemberClicked(null);
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  const handleFilterChange = (setter) => (event) => {
    setter(event.target.value);
    if (event.target.value === "") {
      setBrokerageFilter("");
    }
  };
  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleAccountTypeFilterChange = (event) => {
    setAccountTypeFilter(event.target.value);
  };

  const filteredUsers = members?.filter((user) => {
    return (
      (!statusFilter || user?.status === statusFilter) &&
      (!accountTypeFilter || user?.accountType === accountTypeFilter) &&
      (!brokerageFilter ||
        user?.role?.tiers?.find(
          (tier) => tier && tier?._id === brokerageFilter
        ))
    );
  });

  const sortedUsers = filteredUsers?.sort((a, b) => {
    if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
    if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
    return 0;
  });

  const isAllSelected = useMemo(
    () =>
      sortedUsers?.length > 0 &&
      sortedUsers?.every((member) => selectedMembers?.includes(member?._id)),
    [sortedUsers, selectedMembers]
  );

  const toggleSelectAll = () => {
    if (isAllSelected) {
      const filteredIds = sortedUsers?.map((member) => member?._id);
      setSelectedMembers(
        selectedMembers?.filter((id) => !filteredIds?.includes(id))
      );
    } else {
      const newSelections = sortedUsers?.map((member) => member?._id);
      setSelectedMembers([...new Set([...selectedMembers, ...newSelections])]);
    }
  };

  const handleCheckboxChange = (event, id) => {
    event.stopPropagation();
    setSelectedMembers((current) =>
      current.includes(id)
        ? current.filter((memberId) => memberId !== id)
        : [...current, id]
    );
  };

  const handleAssignDefaultBrokerageToManyMembers = async (event) => {
    const selectedMemberIds = members
      .filter((member) => selectedMembers.includes(member._id))
      .map((member) => member._id);

    if (!selectedMemberIds || !selectedTierBrokerage?.brokerage?._id) {
      setNotification({
        open: true,
        message: "There are no members or brokerage id.",
      });
      return;
    }
    const result = await assignDefaultBrokerageToManyMembers({
      brokerageId: selectedTierBrokerage?.brokerage?._id,
      memberIds: selectedMemberIds,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        handleMenuClose(event);
        setAnchorEl(null);
      }, 2500);
    } else {
      setNotification({
        open: true,
        message: `Failed: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleAddBrokerageToManyMembers = async (event) => {
    const selectedMemberIds = members
      .filter((member) => selectedMembers.includes(member._id))
      .map((member) => member._id);

    if (!selectedMemberIds || !selectedTierBrokerage?.brokerage?._id) {
      setNotification({
        open: true,
        message: "There are no members or brokerage id.",
      });
      return;
    }
    const result = await addBrokerageToManyMembers({
      brokerageId: selectedTierBrokerage?.brokerage?._id,
      memberIds: selectedMemberIds,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        handleMenuClose(event);
        setAnchorEl(null);
      }, 2500);
    } else {
      setNotification({
        open: true,
        message: `Failed: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <>
      <Box>
        <Autocomplete
          value={selectedTierBrokerage}
          onChange={(event, newValue) => {
            setSelectedTierBrokerage(newValue);
          }}
          options={brokerageTiers || []}
          getOptionLabel={(option) =>
            option
              ? `${option?.level} ${
                  option?.brokerage ? option?.brokerage?.officeName : ""
                } ${
                  option?._id === option?.brokerage?.leadBrokerageTier
                    ? " - (Lead Brokerage Office)"
                    : ""
                } ${
                  option?._id === option?.brokerage?.tier ? " - (Office)" : ""
                }`
              : ""
          }
          isOptionEqualToValue={(option, value) => option?._id === value._id}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a Tier Brokerage"
              variant="outlined"
              margin="normal"
              required
            />
          )}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          m: 3,
        }}
      >
        <Tooltip
          title={
            selectedMembers.length === 0
              ? "Select members first"
              : "Assign Tier To Members"
          }
        >
          <span>
            <Button
              onClick={handleAssignDefaultBrokerageToManyMembers}
              disabled={selectedMembers.length === 0}
            >
              {isAssignDefaultBrokerageToManyMembersLoading ? (
                <CircularProgress size={24} />
              ) : (
                "Assign Default Brokerage To Many Members"
              )}
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          title={
            selectedMembers.length === 0
              ? "Select members first"
              : "Assign Tier To Members"
          }
        >
          <span>
            <Button
              onClick={handleAddBrokerageToManyMembers}
              disabled={selectedMembers.length === 0}
            >
              {isAddBrokerageToManyMembersLoading ? (
                <CircularProgress size={24} />
              ) : (
                "Add Brokerage To Many Members"
              )}
            </Button>
          </span>
        </Tooltip>
      </Box>
      <Box sx={{ marginBottom: "10px", display: "flex", gap: "10px" }}>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          sx={{ minWidth: 120 }}
        >
          <InputLabel id="brokerage-filter-label">
            Filter by Tier (Office)
          </InputLabel>
          <Select
            labelId="brokerage-filter-label"
            value={brokerageFilter}
            onChange={handleFilterChange(setBrokerageFilter)}
            label="Filter by Tier (Office)"
          >
            <MenuItem value="">All</MenuItem>
            {tiers &&
              tiers.map((tier) => (
                <MenuItem key={tier?._id} value={tier?._id}>
                  {`${tier?.level} ${
                    tier?.brokerage || tier?.team || tier?.group
                      ? tier?.brokerage?.officeName ||
                        tier?.team?.name ||
                        tier?.group?.name
                      : ""
                  } ${
                    tier?.level === "system" || tier?.level === "global"
                      ? " - REACirc"
                      : ""
                  } ${
                    tier?._id === tier?.brokerage?.leadBrokerageTier
                      ? " - (Lead Brokerage Office)"
                      : ""
                  } ${
                    tier?._id === tier?.brokerage?.tier ? " - (Office)" : ""
                  }`}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          sx={{ minWidth: 120 }}
        >
          <InputLabel id="status-filter-label">Filter by status</InputLabel>
          <Select
            labelId="status-filter-label"
            value={statusFilter}
            onChange={handleStatusFilterChange}
            label="Filter by status"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          sx={{ minWidth: 120 }}
        >
          <InputLabel id="account-type-filter-label">
            Filter by account type
          </InputLabel>
          <Select
            labelId="account-type-filter-label"
            value={accountTypeFilter}
            onChange={handleAccountTypeFilterChange}
            label="Filter by account type"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="reosadmin">REOS Admin</MenuItem>
            <MenuItem value="brokerage">Brokerage</MenuItem>
            <MenuItem value="member">Member</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <TableContainer component={Paper}>
        <Table className="user-table">
          <TableHead
            sx={{
              color: "var(--text-color)",
              backgroundColor: "var(--bg-color)",
            }}
          >
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedMembers?.length > 0 &&
                    selectedMembers?.length < members?.length
                  }
                  checked={isAllSelected}
                  onChange={toggleSelectAll}
                />
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "user.firstName"}
                  direction={sortDirection}
                  onClick={() => handleSort("user.firstName")}
                  title="Sort by First Name"
                >
                  <sup>First</sup>
                </TableSortLabel>
                <TableSortLabel
                  onClick={() => handleSort("user.lastName")}
                  active={sortField === "user.lastName"}
                  direction={sortDirection}
                  title="Sort by Last Name"
                >
                  <sup>Last</sup>
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ fontWeight: "bolder" }}>
                <TableSortLabel
                  active={sortField === "user.email"}
                  direction={sortDirection}
                  onClick={() => handleSort("user.email")}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "user.accountType"}
                  direction={sortDirection}
                  onClick={() => handleSort("user.accountType")}
                >
                  Account Type
                </TableSortLabel>
              </TableCell>
              {/* <TableCell>
                <TableSortLabel
                  active={sortField === "user.status"}
                  direction={sortDirection}
                  onClick={() => handleSort("user.status")}
                >
                  Status
                </TableSortLabel>
              </TableCell> */}
              <TableCell>
                <TableSortLabel
                  active={sortField === "user.lastOnlineTime"}
                  direction={sortDirection}
                  onClick={() => handleSort("user.lastOnlineTime")}
                >
                  Last Online
                </TableSortLabel>
              </TableCell>

              <TableCell>Actions </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedUsers?.map((user) => (
              <TableRow
                key={user?._id}
                onClick={(event) => handleRowClick(event, user?._id, user)}
                style={{ cursor: "pointer" }}
                className="table-row"
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedMembers.includes(user?._id)}
                    className="select-checkbox"
                    onChange={(event) => {
                      event.stopPropagation();
                      handleCheckboxChange(event, user?._id);
                    }}
                  />
                </TableCell>
                <TableCell>
                  {capitalizeFirstLetter(user?.firstName)}{" "}
                  {capitalizeFirstLetter(user?.lastName)}
                </TableCell>
                <TableCell>{user?.email}</TableCell>
                <TableCell>{user?.accountType}</TableCell>
                <TableCell>
                  {user?.lastOnlineTime ? (
                    dayjs(user?.lastOnlineTime).fromNow()
                  ) : (
                    <span style={{ color: "red" }}>Never</span>
                  )}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      className="menu-button"
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(event) => handleMenuOpen(event, user?._id)}
                    >
                      <MoreVert />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleMenuClose}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();

                          navigate(`/member-profile/${currentMemberClicked}`);
                          handleMenuClose(e);
                        }}
                      >
                        <Typography
                          variant="body1"
                          className="view-details-button"
                        >
                          Open Profile
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
}

export default memo(MembersToManageTierList);

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const categoryApi = createApi({
  reducerPath: "category",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/categories/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Category"],
  endpoints: (builder) => ({
    createCategory: builder.mutation({
      query: ({
        userId,
        tierId,
        tierMirrorToId,
        categoryName,
        categoryPath,
        categoryIcon,
        categoryType,
        categoryDescription,
        categoryGroupId,
        iFrameURL,
        order,
        isWidgetable,
        isEvent,
        isMemberList,
        isHomePage,
        isChat,
        isFileSystem,
        isListings,
        allowTags,
        useWYSIWYG,
        hasFeaturePicture,
        allowAcknowledgements,
        allowAttachments,
        allowComments,
        allowLikes,
      }) => ({
        url: `create-category/${userId}`,
        method: "POST",
        body: {
          tierId,
          tierMirrorToId,
          categoryName,
          categoryPath,
          categoryIcon,
          categoryType,
          categoryDescription,
          categoryGroupId,
          iFrameURL,
          order,
          isWidgetable,
          isEvent,
          isMemberList,
          isHomePage,
          isChat,
          isFileSystem,
          isListings,
          allowTags,
          useWYSIWYG,
          hasFeaturePicture,
          allowAcknowledgements,
          allowAttachments,
          allowComments,
          allowLikes,
        },
      }),
      skip: (userId) => !userId,
      invalidatesTags: [{ type: "Category", id: "LIST" }],
    }),
    updateCategory: builder.mutation({
      query: ({
        categoryId,
        categoryName,
        categoryPath,
        categoryIcon,
        categoryType,
        categoryDescription,
        categoryGroupId,
        order,
        iFrameURL,
        isWidgetable,
        isEvent,
        isMemberList,
        isHomePage,
        isChat,
        isFileSystem,
        isListings,
        allowTags,
        useWYSIWYG,
        hasFeaturePicture,
        allowAcknowledgements,
        allowAttachments,
        allowComments,
        allowLikes,
      }) => ({
        url: `update-category/${categoryId}`,
        method: "PUT",
        body: {
          categoryName,
          categoryPath,
          categoryIcon,
          categoryType,
          categoryDescription,
          categoryGroupId,
          iFrameURL,
          order,
          isWidgetable,
          isEvent,
          isMemberList,
          isHomePage,
          isChat,
          isFileSystem,
          isListings,
          allowTags,
          useWYSIWYG,
          hasFeaturePicture,
          allowAcknowledgements,
          allowAttachments,
          allowComments,
          allowLikes,
        },
      }),
      invalidatesTags: [{ type: "Category", id: "LIST" }],
    }),
    createBrokerageInitialCategory: builder.mutation({
      query: ({ tierId, userId }) => ({
        url: `create-brokerage-initialize-category-and-types/${tierId}/${userId}`,
        method: "POST",
      }),
      skip: (tierId) => !tierId,
      invalidatesTags: [{ type: "Category", id: "LIST" }],
    }),
    createGroupInitialCategory: builder.mutation({
      query: ({ tierId, userId }) => ({
        url: `create-group-initialize-category-and-types/${tierId}/${userId}`,
        method: "POST",
      }),
      skip: (tierId) => !tierId,
      invalidatesTags: [{ type: "Category", id: "LIST" }],
    }),
    getCategoryById: builder.query({
      query: (categoryId) => ({
        url: `get-category-by-id/${categoryId}`,
        method: "GET",
      }),
      skip: (categoryId) => !categoryId,
      providesTags: (result, error, categoryId) => [
        { type: "Category", id: categoryId },
      ],
    }),
    getAllCategoriesByBrokerage: builder.query({
      query: (brokerageId) => ({
        url: `all-categories-by-brokerage/${brokerageId}`,
        method: "GET",
      }),
      skip: (brokerageId) => !brokerageId,
      providesTags: [{ type: "Category", id: "LIST" }],
    }),
    getCategoriesByUserRole: builder.query({
      query: (userId) => ({
        url: "get-categories-by-user-role",
        method: "GET",
      }),
      skip: (userId) => !userId,
      providesTags: [{ type: "Category", id: "LIST" }],
    }),
    getCategoriesFromUserRoleTiersCollection: builder.query({
      query: (userId) => ({
        url: "get-categories-from-user-role-tiers-collection",
        method: "GET",
      }),
      skip: (userId) => !userId,
      providesTags: [{ type: "Category", id: "LIST" }],
    }),
    getAllOfficeCategoriesFromUserRoleTiersCollection: builder.query({
      query: (userId) => ({
        url: "get-alloffice-categories-from-user-role-tiers-collection",
        method: "GET",
      }),
      skip: (userId) => !userId,
      providesTags: [{ type: "Category", id: "LIST" }],
    }),
    getGlobalCategories: builder.query({
      query: (userId) => ({
        url: "get-global-categories",
        method: "GET",
      }),
      skip: (userId) => !userId,
      providesTags: [{ type: "Category", id: "LIST" }],
    }),
    getCategoryTypeEnum: builder.query({
      query: (userId) => ({
        url: "get-category-type-enum",
        method: "GET",
      }),
      skip: (userId) => !userId,
      providesTags: [{ type: "Category", id: "LIST" }],
    }),
    getCategoriesByTier: builder.query({
      query: ({ tierId }) => ({
        url: `categories-by-tier/${tierId}`,
        method: "GET",
      }),
      skip: (tierId) => !tierId,
      providesTags: [{ type: "Category", id: "LIST" }],
    }),
    getAllCategories: builder.query({
      query: () => ({
        url: "all-categories",
        method: "GET",
      }),
      providesTags: [{ type: "Category", id: "LIST" }],
    }),
    assignBrokerageToCategory: builder.mutation({
      query: ({ brokerageId, categoryId }) => ({
        url: "assign-brokerage-to-member",
        method: "POST",
        body: { brokerageId, categoryId },
      }),
      invalidatesTags: [{ type: "Category", id: "LIST" }],
    }),
    assignDefaultOrderToCategories: builder.mutation({
      query: ({ tierId }) => ({
        url: `assign-default-order-to-categories/${tierId}`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Category", id: "LIST" }],
    }),
    assignAllOfficeCategoryToLocalOffice: builder.mutation({
      query: ({ tierId, categoryId }) => ({
        url: `assign-alloffices-category-to-local-office`,
        method: "POST",
        body: { tierId, categoryId },
      }),
      invalidatesTags: [{ type: "Category", id: "LIST" }],
    }),
    unassignAllOfficeCategoryFromLocalOffice: builder.mutation({
      query: ({ tierId, categoryId }) => ({
        url: `unassign-alloffices-category-from-local-office`,
        method: "POST",
        body: { tierId, categoryId },
      }),
      invalidatesTags: [{ type: "Category", id: "LIST" }],
    }),
    assignAllOfficeCategoriesToLocalOffice: builder.mutation({
      query: ({ tierId, categoryIds }) => ({
        url: `assign-alloffices-categories-to-local-office`,
        method: "POST",
        body: { tierId, categoryIds },
      }),
      invalidatesTags: [{ type: "Category", id: "LIST" }],
    }),
    unassignAllOfficeCategoriesFromLocalOffice: builder.mutation({
      query: ({ tierId, categoryIds }) => ({
        url: `unassign-alloffices-categories-from-local-office`,
        method: "POST",
        body: { tierId, categoryIds },
      }),
      invalidatesTags: [{ type: "Category", id: "LIST" }],
    }),
    assignGlobalCategoryToLocalOffice: builder.mutation({
      query: ({ tierId, categoryId }) => ({
        url: `assign-global-category-to-local-office`,
        method: "POST",
        body: { tierId, categoryId },
      }),
      invalidatesTags: [{ type: "Category", id: "LIST" }],
    }),
    unassignGlobalCategoryFromLocalOffice: builder.mutation({
      query: ({ tierId, categoryId }) => ({
        url: `unassign-global-category-from-local-office`,
        method: "POST",
        body: { tierId, categoryId },
      }),
      invalidatesTags: [{ type: "Category", id: "LIST" }],
    }),
    fixingPutAllCategoriesToTierCategoriesCollection: builder.mutation({
      query: ({ tierId }) => ({
        url: `fixing-put-all-categories-to-tier-categories-collection`,
        method: "POST",
        body: { tierId },
      }),
      invalidatesTags: [{ type: "Category", id: "LIST" }],
    }),
    deleteCategory: builder.mutation({
      query: ({ userId, categoryId }) => ({
        url: `delete-category/${userId}/${categoryId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Category", id: "LIST" }],
    }),
    deleteAllCategories: builder.mutation({
      query: ({ userId, categoryIds }) => ({
        url: `delete-all-categories/${userId}`,
        method: "DELETE",
        body: { categoryIds },
      }),
      invalidatesTags: [{ type: "Category", id: "LIST" }],
    }),
  }),
});

export const {
  useCreateCategoryMutation,
  useCreateGroupInitialCategoryMutation,
  useGetCategoryByIdQuery,
  useGetAllCategoriesQuery,
  useGetAllCategoriesByBrokerageQuery,
  useGetCategoriesByUserRoleQuery,
  useGetCategoriesFromUserRoleTiersCollectionQuery,
  useGetAllOfficeCategoriesFromUserRoleTiersCollectionQuery,
  useGetGlobalCategoriesQuery,
  useGetCategoryTypeEnumQuery,
  useGetCategoriesByTierQuery,
  useUpdateCategoryMutation,
  useAssignBrokerageToCategoryMutation,
  useAssignDefaultOrderToCategoriesMutation,
  useAssignAllOfficeCategoryToLocalOfficeMutation,
  useUnassignAllOfficeCategoryFromLocalOfficeMutation,
  useAssignAllOfficeCategoriesToLocalOfficeMutation,
  useUnassignAllOfficeCategoriesFromLocalOfficeMutation,
  useAssignGlobalCategoryToLocalOfficeMutation,
  useUnassignGlobalCategoryFromLocalOfficeMutation,
  useFixingPutAllCategoriesToTierCategoriesCollectionMutation,
  useDeleteCategoryMutation,
  useDeleteAllCategoriesMutation,
} = categoryApi;

export default categoryApi;
export { categoryApi };

import React, { useState } from "react";
import {
  TextField,
  IconButton,
  Grid,
  Typography,
  InputAdornment,
} from "@mui/material";
import {
  Save,
  Edit,
  Close,
  Download,
  Delete,
  FolderOpen,
  InsertDriveFile,
  Visibility,
} from "@mui/icons-material";

import { useGeneratePresignedUrlQuery } from "../../services/file.js"; // Adjust import path as necessary
import ImagePreview from "./ImagePreview"; // Adjust import path as necessary
import PreviewModal from "./PreviewModal"; // Adjust import path as necessary

const FileItem = ({
  file,
  handleRename,
  handleDownload,
  handleDelete,
  handleDirectoryClick,
  isAdmin,
  isDownloading,
  isDeleting,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [fileName, setFileName] = useState(file.name);
  const [fileDescription, setFileDescription] = useState(file.description);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isImage = (fileName) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".webp"];
    const dotIndex = fileName.lastIndexOf(".");
    if (dotIndex === -1) return false;
    const extension = fileName.substring(dotIndex).toLowerCase();
    return imageExtensions.includes(extension);
  };

  const isPDF = (fileName) => {
    return fileName.toLowerCase().endsWith(".pdf");
  };

  const { data: presignedUrl } = useGeneratePresignedUrlQuery(
    file.storageLocation,
    {
      skip: !file.storageLocation || (!isImage(file.name) && !isPDF(file.name)),
    }
  );

  const toggleEditing = () => {
    setIsEditing(!isEditing);
    if (isEditing) {
      handleRename(file._id, fileName, fileDescription);
    }
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setFileName(file.name);
    setFileDescription(file.description);
  };

  return (
    <>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          {isImage(file.name) ? (
            <ImagePreview
              src={presignedUrl?.url}
              alt={file.name}
              style={{ width: 150, height: 150, objectFit: "cover" }} // Adjust size as needed
            />
          ) : isPDF(file.name) ? (
            <IconButton
              onClick={() => setIsModalOpen(true)}
              disabled={!presignedUrl?.url}
            >
              <Visibility />
            </IconButton>
          ) : (
            <IconButton
              onClick={() =>
                file.type === "directory"
                  ? handleDirectoryClick(file._id, file.name)
                  : null
              }
              style={{
                cursor: file.type === "directory" ? "pointer" : "default",
              }}
              disabled={file.type !== "directory"}
            >
              {file.type === "directory" ? <FolderOpen /> : <InsertDriveFile />}
            </IconButton>
          )}
        </Grid>
        <Grid item xs>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs>
              {isEditing ? (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="File Name"
                        value={fileName}
                        onChange={(e) => setFileName(e.target.value)}
                        autoFocus
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={cancelEditing}>
                                <Close />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="File Description"
                        value={fileDescription}
                        onChange={(e) => setFileDescription(e.target.value)}
                        sx={{
                          "& .MuiInputBase-input": {
                            fontSize: "10px",
                            padding: "10px",
                          },
                          "& .MuiInputLabel-root": {
                            fontSize: "12px",
                          },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "primary.main",
                            },
                            "&:hover fieldset": {
                              borderColor: "primary.dark",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "secondary.main",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Typography variant="body1" noWrap>
                    {file.name}
                  </Typography>
                  <Typography variant="caption">{file.description}</Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {isAdmin && (
            <>
              <IconButton onClick={() => toggleEditing(file._id)}>
                {isEditing ? <Save /> : <Edit />}
              </IconButton>
            </>
          )}
          <IconButton
            onClick={() => handleDownload(file)}
            disabled={isDownloading}
          >
            <Download />
          </IconButton>
          {isAdmin && (
            <>
              <IconButton
                onClick={() => handleDelete(file._id)}
                disabled={isDeleting}
              >
                <Delete />
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>

      {presignedUrl?.url && (
        <PreviewModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          src={presignedUrl.url}
          isImage={isImage(file.name)}
          isPDF={isPDF(file.name)}
        />
      )}
    </>
  );
};

export default FileItem;

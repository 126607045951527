import React, { useState } from "react";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { TabPanel } from "../../../helpers/utils";
import CategoryFloorDutyScheduling from "./CategoryFloorDutyScheduling";
import DutyScheduleItemCard from "./DutyScheduleItemCard";
import DutyScheduleManagerMenu from "./DutyScheduleManagerMenu";

function DutyScheduleManager({ tier, dutySchedule }) {
  const [dutyManagerTabValue, setDutyManagerTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setDutyManagerTabValue(newValue);
  };

  if (!dutySchedule) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 20,
          width: "100%",
          height: "100%",
        }}
      >
        <Typography variant="h5" gutterBottom>
          No Duty Schedules available
        </Typography>
      </Box>
    );
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DutyScheduleManagerMenu
        tierId={tier?._id}
        dutyScheduleId={dutySchedule?._id}
      />
      <Tabs
        value={dutyManagerTabValue}
        onChange={handleTabChange}
        aria-label="duty management tabs"
        variant="scrollable"
        scrollButtons="auto"
        className="tabs-paper"
      >
        <Tab
          label="Floor Duty Scheduling"
          id="simple-tab-0"
          aria-controls="simple-tabpanel-0"
        />
        <Tab
          label="Duty Schedule Settings"
          id="simple-tab-1"
          aria-controls="simple-tabpanel-1"
        />
      </Tabs>
      <TabPanel value={dutyManagerTabValue} index={0} className="tabs-paper">
        <CategoryFloorDutyScheduling
          tierId={tier?._id}
          dutyScheduleId={dutySchedule?._id}
        />
      </TabPanel>
      <TabPanel value={dutyManagerTabValue} index={1} className="tabs-paper">
        <DutyScheduleItemCard tier={tier} dutySchedule={dutySchedule} />
      </TabPanel>
    </div>
  );
}

export default DutyScheduleManager;

import React, { useState, useEffect, memo, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Paper, Tabs, Tab, Typography } from "@mui/material";
import BillingList from "./BillingList";
import BillingSetup from "./BillingSetup";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51PkRsc1zSF6d4NVL7a1UOTPfEvy8wIDGsMXKxl1sEUhvEbPZJk1leMbhK5nB2BxsaN2i0L3ouObsClBrMOLfdrgI00bGYbmPJS"
);

import { TabPanel } from "../../helpers/utils";

function BillingTabs({ tier }) {
  const tierId = tier?._id;
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const initialTab =
    parseInt(queryParams.get("billtab")) || 0;
  const [billtabValue, setBilltabValue] = useState(initialTab);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    params.set("billtab", billtabValue);
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [billtabValue, navigate, location.pathname, location.search]);

  const handleTabChange = (event, newValue) => {
    setBilltabValue(newValue);
  };
  return (
    <Elements stripe={stripePromise}>
      <Paper className="tabs-paper-brokerage-ecosys">
        <Typography gutterBottom className="main-title">
          Billing Management{" "}
        </Typography>
        <Tabs
          value={billtabValue}
          onChange={handleTabChange}
          aria-label="invoices tabs"
          variant="scrollable"
          scrollButtons="auto"
          className="tabs-paper"
        >
          <Tab
            label="Billing History"
            id="invoices-tab-0"
            aria-controls="invoices-tabpanel-0"
          />
          <Tab
            label="Billing Setup"
            id="invoices-tab-1"
            aria-controls="invoices-tabpanel-1"
          />
        </Tabs>
        <TabPanel value={billtabValue} index={0} className="billing-tabs-paper">
          <BillingList tierId={tierId} />
        </TabPanel>
        <TabPanel value={billtabValue} index={1} className="billing-tabs-paper">
          <BillingSetup tierId={tierId} />
        </TabPanel>
      </Paper>
    </Elements>
  );
}

export default memo(BillingTabs);

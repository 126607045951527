import React, { useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import {
  Container,
  Typography,
  Paper,
  List,
  ListItem,
  Divider,
  Box,
  Grid,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import TeamsList from "./TeamsList";
import CreateTeamDialog from "./CreateTeamDialog";

const TeamManagement = () => {
  const { accountType } = useContext(AuthContext);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [openCreateTeamDialog, setOpenCreateTeamDialog] = useState(false);
  const handleOpenCreateTeamDialog = () => {
    setOpenCreateTeamDialog(true);
  };

  const handleCloseCreateTeamDialog = () => {
    setOpenCreateTeamDialog(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (accountType !== "reosadmin") {
    return (
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        You are not allowed for this page
      </Typography>
    );
  }

  return (
    <Container className="management-page">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Team Management
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          Actions
        </Typography>
        <Divider />
        <Grid container>
          <Grid item xs={6} sx={{ p: 2 }}>
            <div>
              <Typography variant="h5" mb={1}>
                Teams:
              </Typography>
            </div>
            <Box sx={{ display: "flex", flexDirection: "column", p: 0.5 }}>
              <List style={{ width: "100%", marginRight: "0.1rem" }}>
                <ListItem
                  onClick={handleOpenCreateTeamDialog}
                  className="user-management-item"
                >
                  Create The Team
                </ListItem>
                <Divider />
              </List>
            </Box>
          </Grid>
        </Grid>
        <CreateTeamDialog
          open={openCreateTeamDialog}
          onClose={handleCloseCreateTeamDialog}
        />
        <NotificationSnackbar
          open={notification.open}
          message={notification.message}
          onClose={handleClose}
        />
      </Paper>
      <TeamsList />
    </Container>
  );
};

export default TeamManagement;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const userApi = createApi({
  reducerPath: "user",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/users/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: (newUserData) => ({
        url: "create",
        method: "POST",
        body: newUserData,
      }),
      invalidatesTags: [{ type: "User", id: "ACTIVE_LIST" }],
    }),
    fetchAllUsers: builder.query({
      query: (accountType) => {
        const token = localStorage.getItem("token");
        if (!token) {
          return new Request("");
        }

        return {
          url: `all`,
          method: "GET",
        };
      },
      skip: (accountType) =>
        accountType !== "reosadmin" || accountType !== "reossuperadmin",
      providesTags: [
        { type: "User", id: "LIST" },
        { type: "User", id: "ACTIVE_LIST" },
      ],
    }),
    fetchAllUsersByPagination: builder.query({
      query: ({
        accountType,
        page = 1,
        limit = 20,
        statusFilter = "",
        accountTypeFilter = "",
        lastOnlineFilter = "",
        brokerageFilter = "",
        textFilter = "",
      }) => {
        const token = localStorage.getItem("token");
        if (!token) {
          return new Request("");
        }
        const queryParams = `page=${page}&limit=${limit}`;

        return {
          url: `all-by-pagination?${queryParams}`,
          method: "GET",
          params: {
            statusFilter,
            accountTypeFilter,
            lastOnlineFilter,
            brokerageFilter,
            textFilter,
          },
        };
      },
      skip: (accountType) =>
        accountType !== "reosadmin" || accountType !== "reossuperadmin",
      providesTags: [
        { type: "User", id: "LIST" },
        { type: "User", id: "ACTIVE_LIST" },
      ],
    }),
    getAllUsersGroupByAccountType: builder.query({
      query: (accountType) => ({
        url: "all-grouped-by-account-type",
        method: "GET",
      }),
      skip: (accountType) => accountType !== "reosadmin",
      providesTags: [
        { type: "User", id: "LIST" },
        { type: "User", id: "ACTIVE_LIST" },
      ],
    }),
    fetchAllArchivedUsers: builder.query({
      query: () => ({
        url: "all-archived",
        method: "GET",
      }),
      providesTags: [{ type: "User", id: "ARCHIVED_LIST" }],
    }),
    fetchUserDetails: builder.query({
      query: (userId) => ({
        url: `details/${userId}`,
        method: "GET",
      }),
      providesTags: [
        { type: "User", id: "LIST" },
        { type: "User", id: "ACTIVE_LIST" },
        { type: "Tier", id: "LIST" },
        { type: "Role", id: "LIST" },
      ],
    }),
    updateUserName: builder.mutation({
      query: ({
        userId,
        firstName,
        lastName,
        isEmailPrivate,
        emailPublic,
      }) => ({
        url: `update-user-name/${userId}`,
        method: "PUT",
        body: { firstName, lastName, isEmailPrivate, emailPublic },
      }),
      invalidatesTags: [{ type: "User", id: "ACTIVE_LIST" }],
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `delete/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "User", id: "ACTIVE_LIST" }],
    }),
    archiveUser: builder.mutation({
      query: (userId) => ({
        url: `archive/${userId}`,
        method: "POST",
      }),
      invalidatesTags: [
        { type: "User", id: "ACTIVE_LIST" },
        { type: "User", id: "ARCHIVED_LIST" },
      ],
    }),
    restoreUser: builder.mutation({
      query: (userId) => ({
        url: `restore/${userId}`,
        method: "POST",
      }),
      invalidatesTags: [
        { type: "User", id: "ACTIVE_LIST" },
        { type: "User", id: "ARCHIVED_LIST" },
      ],
    }),
    updateEmail: builder.mutation({
      query: ({ userId, newEmail }) => ({
        url: `update-email/${userId}`,
        method: "PUT",
        body: { newEmail },
      }),
      invalidatesTags: [
        { type: "User", id: "ACTIVE_LIST" },
        { type: "User", id: "LIST" },
      ],
    }),
    updateImage: builder.mutation({
      query: ({ userId, file }) => {
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: `update-image/${userId}`,
          method: "PUT",
          body: formData,
        };
      },
    }),
    updateUserStatus: builder.mutation({
      query: ({ userId, isOnline, lastOnlineTime }) => ({
        url: `/update-status/${userId}`,
        method: "PUT",
        body: { isOnline, lastOnlineTime },
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
  }),
});

export const {
  useFetchAllUsersQuery,
  useFetchAllUsersByPaginationQuery,
  useGetAllUsersGroupByAccountTypeQuery,
  useFetchAllArchivedUsersQuery,
  useFetchUserDetailsQuery,
  useUpdateUserNameMutation,
  useUpdateEmailMutation,
  useUpdateImageMutation,
  useDeleteUserMutation,
  useCreateUserMutation,
  useArchiveUserMutation,
  useRestoreUserMutation,
  useUpdateUserStatusMutation,
} = userApi;

export default userApi;
export { userApi };

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const eventTypeApi = createApi({
  reducerPath: "event-type",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/event-types/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["EventType"],
  endpoints: (builder) => ({
    createEventType: builder.mutation({
      query: ({ name, description }) => ({
        url: `create-one`,
        method: "POST",
        body: { name, description },
      }),
      invalidatesTags: ["EventType"],
    }),
    getEventTypes: builder.query({
      query: ({ userId }) => ({
        url: "get-all",
        method: "GET",
      }),
      skip: (userId) => !userId,
      providesTags: ["EventType"],
    }),
    updateEventType: builder.mutation({
      query: ({ eventTypeId, name, description }) => ({
        url: `update-one/${eventTypeId}`,
        method: "PUT",
        body: { name, description },
      }),
      invalidatesTags: ["EventType"],
    }),
    deleteEventType: builder.mutation({
      query: ({ eventTypeId }) => ({
        url: `delete-one/${eventTypeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["EventType"],
    }),
  }),
});

export const {
  useCreateEventTypeMutation,
  useGetEventTypesQuery,
  useUpdateEventTypeMutation,
  useDeleteEventTypeMutation,
} = eventTypeApi;

export default eventTypeApi;
export { eventTypeApi };

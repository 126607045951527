import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useUploadFileMutation } from "../../services/file";
import { FormControlLabel, Switch, Button, Typography } from "@mui/material";

const FileUploader = ({ userId, tierId, currentPath, fullPath }) => {
  const [files, setFiles] = useState([]);
  const [upload, setUpload] = useState({
    progress: 0,
    uploading: false,
    error: "",
  });
  const [isCreatingDirectory, setIsCreatingDirectory] = useState(false);
  const [directoryName, setDirectoryName] = useState("");

  const [uploadFile] = useUploadFileMutation();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      // console.log(acceptedFiles); // Check the structure of incoming files

      const filesWithPreviews = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      // console.log("Dropped files:", filesWithPreviews); // Debugging to check the structure
      setFiles(filesWithPreviews);
    },
    multiple: true,
    disabled: upload.uploading,
  });

  // Clean up memory when component unmounts
  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const handleUpload = async () => {
    if (isCreatingDirectory && !directoryName.trim()) {
      setUpload({ ...upload, error: "Please enter a directory name." });
      return;
    }

    if (!isCreatingDirectory && files.length === 0) {
      setUpload({ ...upload, error: "Please select files to upload." });
      return;
    }

    const formData = new FormData();
    if (!isCreatingDirectory) {
      files.forEach((file) => {
        formData.append("files", file);
      });
    }
    
    formData.append("userId", userId);
    formData.append("tierId", tierId);
    formData.append("parentDirectoryId", currentPath);
    formData.append(
      "fullPath",
      isCreatingDirectory ? `${fullPath}/${directoryName}` : fullPath
    );
    formData.append("isDirectory", isCreatingDirectory);
    if (isCreatingDirectory) {
      formData.append("directoryName", directoryName);
    }

    try {
      setUpload({ ...upload, uploading: true, error: "" });
      await uploadFile(formData).unwrap();
      resetState();
    } catch (err) {
      setUpload({ uploading: false, error: "Error uploading." });
      console.error("Upload error:", err);
    }
  };

  const resetState = () => {
    setFiles([]);
    setIsCreatingDirectory(false);
    setDirectoryName("");
    setUpload({ progress: 0, uploading: false, error: "" });
  };

  // const removeFile = (name) => {
  //   setFiles(files.filter((file) => file.file.name !== name));
  // };

  return (
    <div
      className={`drag-drop-container ${
        isDragActive && !isCreatingDirectory ? "active" : ""
      }`}
    >
      {!isCreatingDirectory && (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Typography
            variant="h6"
            sx={{ textAlign: "center", mt: "20px", mb: "20px" }}
          >
            {isDragActive
              ? "Drop the files here ..."
              : "Drag 'n' drop some files here, or click to select files"}
          </Typography>
        </div>
      )}

      {isCreatingDirectory && (
        <Typography
          variant="h6"
          sx={{ textAlign: "center", mt: "20px", mb: "20px" }}
        >
          Create a New Directory
        </Typography>
      )}

      {isCreatingDirectory ? (
        <input
          type="text"
          className="input-directory"
          placeholder="Enter directory name"
          value={directoryName}
          onChange={(e) => setDirectoryName(e.target.value)}
          disabled={upload.uploading}
        />
      ) : (
        files.map((file, index) => (
          <div key={index} className="file-preview-container">
            <img
              src={file.preview}
              alt="preview"
              className="file-preview-image"
            />
            <Typography variant="body2">
              {file.name} - {file.size} bytes
            </Typography>
            <Button onClick={() => setFiles(files.filter((f) => f !== file))}>
              Remove
            </Button>
          </div>
        ))
      )}

      {upload.error && <Typography color="error">{upload.error}</Typography>}

      <button
        className="upload-button"
        onClick={handleUpload}
        disabled={
          upload.uploading ||
          (isCreatingDirectory ? !directoryName : files.length === 0)
        }
      >
        {upload.uploading
          ? `Processing ${upload.progress}%`
          : isCreatingDirectory
          ? "Create Directory"
          : "Upload Files"}
      </button>

      {upload.uploading && (
        <div className="progress-bar">
          <div
            className="progress-bar-value"
            style={{ width: `${upload.progress}%` }}
          >
            {upload.progress}%
          </div>
        </div>
      )}

      <div className="control-section">
        <FormControlLabel
          control={
            <Switch
              checked={isCreatingDirectory}
              onChange={(e) => setIsCreatingDirectory(e.target.checked)}
            />
          }
          label={
            isCreatingDirectory
              ? "Switch to Upload Files"
              : "Switch to Create Directory"
          }
        />
      </div>
    </div>
  );
};

export default FileUploader;

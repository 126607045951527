import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const dutyEmailsApi = createApi({
  reducerPath: "duty-emails",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/duty-emails/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["DutyEmails"],
  endpoints: (builder) => ({
    sendDutyChangesSummaryEmail: builder.mutation({
      query: (tierId) => ({
        url: `send-duty-changes-summary/${tierId}`,
        method: "POST",
      }),
      skip: (tierId) => !tierId,
      invalidatesTags: ["DutyEmails"],
    }),
    sendAvailableTimeSlots: builder.mutation({
      query: ({ tierId, dutyScheduleId, availableSlots }) => ({
        url: `send-available-time-slots/${tierId}/${dutyScheduleId}`,
        method: "POST",
        body: { availableSlots },
      }),
      skip: (tierId, dutyScheduleId) => !tierId || !dutyScheduleId,
      invalidatesTags: ["DutyEmails"],
    }),
    sendCustomEmailNotification: builder.mutation({
      query: ({ formData }) => ({
        url: `send-custom-email-notification`,
        method: "POST",
        body: { formData },
      }),
      invalidatesTags: ["DutyEmails"],
    }),
  }),
});

export const {
  useSendDutyChangesSummaryEmailMutation,
  useSendAvailableTimeSlotsMutation,
  useSendCustomEmailNotificationMutation,
} = dutyEmailsApi;

export default dutyEmailsApi;
export { dutyEmailsApi };

import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import SettingsManagementEmptyTab from "./SettingsManagementEmptyTab";
import { TabPanel } from "../../helpers/utils";

const SettingsTabsForms = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="edit-profile-data-form">
      <Tabs
        value={value}
        onChange={handleChange}
        className="edit-profile-data-tabs-header"
        aria-label="settings tabs"
        variant="scrollable"
        scrollButtons="auto"
        // className="tabs-paper"
      >
        <Tab
          label="Account Settings Empty Tab"
          id="simple-tab-0"
          aria-controls="simple-tabpanel-0"
        />
        <Tab
          label="Notification Settings Empty Tab"
          id="simple-tab-0"
          aria-controls="simple-tabpanel-0"
        />
        <Tab
          label="Email & Text Settings Empty Tab"
          id="simple-tab-0"
          aria-controls="simple-tabpanel-0"
        />
      </Tabs>
      <div className="edit-profile-data-tabs-body">
        <TabPanel value={value} index={0}>
          <SettingsManagementEmptyTab />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SettingsManagementEmptyTab />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SettingsManagementEmptyTab />
        </TabPanel>
      </div>
    </div>
  );
};

export default SettingsTabsForms;

import React from "react";
import { styled } from "@mui/material/styles";
import { Badge, Box, Chip } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { format } from "date-fns";
import timezone from "dayjs/plugin/timezone";
import imageCompression from "browser-image-compression";

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDateLocal = (dateString, formatting = null) => {
  if (!dateString) return "";

  const date = new Date(dateString);
  let options;

  switch (formatting) {
    case "full":
      options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZoneName: "short",
      };
      break;
    case "shortday":
      options = {
        weekday: "short", // 3-letter abbreviation of the day
        year: "numeric",
        month: "short", // 3-letter abbreviation of the month
        day: "numeric", // Numeric day of the month
      };
      break;
    default:
      options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
  }

  const formatter = new Intl.DateTimeFormat("en-US", options);
  return formatter.format(date);
};

export function formatDateSelector(date) {
  if (!date) return "";
  const d = new Date(date);
  return format(d, "yyyy-MM-dd'T'HH:mm");
}

export function formatDateToDayMonthYear(date) {
  if (!date) return "";
  return dayjs(date).format("dddd, MMMM D, YYYY");
}

export function formatDateToTime(date) {
  if (!date) return "";
  return dayjs(date).format("HH:mm");
}

export function capitalizeFirstLetter(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

export function camelCaseToTitleCase(camelCase) {
  if (camelCase === undefined || camelCase === "") {
    return camelCase;
  }

  camelCase = camelCase.trim();
  let newText = "";
  for (let i = 0; i < camelCase.length; i++) {
    if (
      /[A-Z]/.test(camelCase[i]) &&
      i !== 0 &&
      /[a-z]/.test(camelCase[i - 1])
    ) {
      newText += " ";
    }
    newText += camelCase[i];
  }
  return newText.charAt(0).toUpperCase() + newText.slice(1);
}

export const formatUserName = (userName) => {
  if (!userName) return "";
  const names = userName.split(" ");
  if (names.length > 1) {
    return `${names[0]} ${names[1].charAt(0)}.`;
  }
  return userName;
};

export const getFirstAndLastName = (userName) => {
  const nameParts = userName.split(" ");
  const firstName = nameParts[0];
  const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : "";
  return { firstName, lastName };
};

export const formatDate = (dateString, noTime = false) => {
  if (!dateString) return "";

  const date = new Date(dateString);

  if (noTime) {
    return date.toLocaleDateString(); // Only date, no time
  }

  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`; // Date and time
};

export const getInitialsFromUserName = (userName) => {
  if (!userName) return "";
  const names = userName.split(" ");
  if (names.length === 1) {
    return names[0]?.charAt(0)?.toUpperCase();
  }

  return `${names[0].charAt(0)}${names[names.length - 1].charAt(0)}`;
};

export const transformNotificationMessage = (message) => {
  const userPattern = /member @([A-Za-z ]+) ([0-9a-fA-F]{24})/;
  const postPattern = /post @([0-9a-fA-F]{24})/;

  return message
    ?.replace(userPattern, "member @$1")
    .replace(postPattern, "@Post");
};

export function linkify(inputText) {
  const urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
  return inputText?.replace(urlRegex, function (url) {
    let displayUrl = url;
    if (url?.length > 30) {
      displayUrl = url?.substring(0, 30) + "...";
    }
    return `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color: inherit;">${displayUrl}</a>`;
  });
}

export const convertBlobToFile = (blob, fileName) => {
  const file = new File([blob], fileName, {
    type: "image/jpeg",
    lastModified: new Date().getTime(),
  });
  return file;
};

export const getCroppedImageBlob = async (croppedAreaPixels, imageSrc) => {
  if (!imageSrc || !imageSrc.startsWith("blob:")) return;

  const image = new Image();
  image.src = imageSrc;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = croppedAreaPixels.width;
  canvas.height = croppedAreaPixels.height;

  await new Promise((resolve) => {
    image.onload = resolve;
  });

  ctx.drawImage(
    image,
    croppedAreaPixels.x,
    croppedAreaPixels.y,
    croppedAreaPixels.width,
    croppedAreaPixels.height,
    0,
    0,
    croppedAreaPixels.width,
    croppedAreaPixels.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, "image/jpeg");
  });
};

export const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],

    ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
    ["link", "image", "video", "formula"],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [{ indent: "-1" }, { indent: "+1" }],
    ["clean"], // remove formatting button
  ],
};

export const generateUniqueFileName = (prefix = "cropped_image") => {
  const timestamp = new Date().getTime();
  const randomSuffix = Math.floor(Math.random() * 10000);
  return `${prefix}_${timestamp}_${randomSuffix}.jpg`;
};

export const countCommentsAndReplies = (comments) => {
  let totalCount = 0;

  comments.forEach((comment) => {
    if (!comment) return;
    totalCount++;
    totalCount += comment.replies ? comment.replies.length : 0;
  });

  return totalCount;
};

export const ProfileDrawerBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 5,
    top: 75,
    padding: "0",
    width: 15,
    height: 15,
    borderRadius: "50%",
  },
}));

export const ProfilePageBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 20,
    top: 75,
    padding: "0",
    width: 15,
    height: 15,
    borderRadius: "50%",
  },
}));

export const AvatarStyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 5,
    top: 20,
    padding: "0",
    width: 15,
    height: 15,
    borderRadius: "50%",
  },
}));

export const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 0,
    top: 0,
    padding: "0",
    width: 15,
    height: 15,
    borderRadius: "50%",
  },
}));

export const CategoryItemStyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 12.5,
    top: 19,
    padding: "0.65rem 0",
    margin: 0,
    width: 5,
    height: 15,
    borderRadius: "1.5rem",
    color: "var(--sidebar-item-badge-color)",
    background: "var(--sidebar-item-badge-bg)",
  },
}));

export const TierTabItemStyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -5,
    top: -5,
    padding: "0.65rem 0",
    margin: 0,
    width: 5,
    height: 5,
    borderRadius: "1.5rem",
    color: "var(--sidebar-item-badge-color)",
    background: "var(--sidebar-tier-tab-item-badge-bg)",
  },
}));

export const formatFileName = (filename) => {
  return filename.replace(/\s+/g, "_").toLowerCase();
};

export const formatDateOfBirth = (day, month) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return `${monthNames[month - 1]} ${day}`;
};

export const timezones = [
  "America/New_York",
  "America/Chicago",
  "America/Denver",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Honolulu",
  "America/Toronto",
  "America/Vancouver",
  "America/Mexico_City",
  "America/Bogota",
  "America/Caracas",
  "America/Sao_Paulo",
  "America/Argentina/Buenos_Aires",
  "America/Lima",
  "America/Santiago",
  "America/Panama",
  "America/Costa_Rica",
  "America/Havana",
  "America/Jamaica",
];

export function formatMoney(number, decimals = 2) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: decimals, // Ensures that there are always two decimal places
    maximumFractionDigits: decimals, // Optional: use it if you want to restrict the fraction digits to two
  });
  return formatter.format(number);
}

export const calculateYearsLicensed = (yearLicensed) => {
  if (!yearLicensed) return "Not specified";

  const currentYear = new Date().getFullYear();
  const yearsLicensed = currentYear - yearLicensed;

  return yearsLicensed + (yearsLicensed === 1 ? " year" : " years");
};

/**
 * Groups categories by their type.
 * @param {Array} categories - The array of categories to be grouped.
 * @returns {Object} The categories grouped by their type.
 */
export const groupCategoriesByGroup = (categories) => {
  return categories.reduce((acc, category) => {
    const categoryGroup = category?.group?.name;
    if (!acc[categoryGroup]) {
      acc[categoryGroup] = [];
    }
    acc[categoryGroup].push(category);
    return acc;
  }, {});
};
// Function to truncate text to a specified length
export const truncate = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + "...";
};

export function convertHtmlToPlainText(htmlContent) {
  // Regular expression to match HTML tags, &nbsp; entities, and newline characters
  const regex = /<[^>]*>|&nbsp;|\r?\n|\r/g;
  // Replace HTML tags with an empty string, &nbsp; with a space, and newline characters with a space
  return htmlContent.replace(regex, (match) => {
    if (match === "&nbsp;") {
      return " ";
    } else if (match === "\n" || match === "\r\n" || match === "\r") {
      return "<br>";
    } else {
      return "";
    }
  });
}

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

// Function to format a 10-digit phone number into (xxx) xxx-xxxx format
export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return "";
  }

  // Remove any non-numeric characters
  const cleanNumber = phoneNumber.replace(/[^\d]/g, "");

  // Function to format 10-digit numbers
  function formatTenDigitNumber(number) {
    return `(${number.substring(0, 3)}) ${number.substring(
      3,
      6
    )}-${number.substring(6)}`;
  }

  // Handle US/Canada and possibly other 10-digit numbers with country code
  switch (cleanNumber.length) {
    case 10:
      return formatTenDigitNumber(cleanNumber);
    case 11:
      if (cleanNumber.startsWith("1")) {
        return formatTenDigitNumber(cleanNumber.substring(1));
      }
      break;
    // Add cases for other specific country formats if needed
    default:
      // Handle unexpected cases or throw an error
      console.error("Invalid phone number length: " + cleanNumber.length);
      return phoneNumber; // Or some other default action or error handling
  }

  // Return the original number if it does not meet any known format patterns
  return phoneNumber;
}

export function addImageClassToContent(htmlContent) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");
  doc.querySelectorAll("img").forEach((img) => {
    img.classList.add("post-content-image");
  });
  return doc.body.innerHTML;
}

export const formattedUrl = (linkUrl) =>
  linkUrl?.startsWith("http://") || linkUrl?.startsWith("https://")
    ? linkUrl
    : `https://${linkUrl}`;

export function sliceMiddleContent(htmlContent, length, startPos = 99) {
  // Create a temporary element to parse HTML
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent;

  // Use textContent to get the text of the content, stripping out HTML tags
  const content = tempDiv.textContent || tempDiv.innerText || "";

  if (content.length <= length) {
    return content; // Return full content if it's shorter than the desired length
  }

  let startIndex;

  // Decide where to start slicing from
  if (startPos === 0) {
    startIndex = 0; // Start from the beginning
  } else {
    // Calculate middle start index as default or based on startPos if not 99
    const middleIndex = Math.floor(content.length / 2); // Calculate the middle point
    startIndex =
      startPos === 99 ? middleIndex - Math.floor(length / 2) : startPos;
    startIndex = Math.max(startIndex, 0); // Ensure start index is not negative
  }

  // Adjust start index to the nearest space to avoid cutting words
  if (startIndex > 0 && startPos !== 0) {
    const leftSpace = content.lastIndexOf(" ", startIndex + 1);
    startIndex = leftSpace === -1 ? startIndex : leftSpace + 1;
  }

  let endIndex = startIndex + length; // Set the end index based on the start index and desired length
  endIndex = Math.min(endIndex, content.length); // Ensure end index does not exceed content length

  // Adjust end index to the nearest space to avoid cutting words
  if (endIndex < content.length) {
    const rightSpace = content.indexOf(" ", endIndex);
    endIndex = rightSpace === -1 ? endIndex : rightSpace;
  }

  // Format output to include ellipsis if cut at the start or end
  return (
    (startIndex > 0 ? "... " : "") +
    content.slice(startIndex, endIndex) +
    (endIndex < content.length ? " ..." : "")
  );
}

export const PostCardStyledChipForOfficeLabel = styled(Chip)(({ theme }) => ({
  height: "auto",
  width: "100%",
  margin: 0,
  padding: 0,
  "& .MuiChip-label": {
    margin: 0,
    padding: 0,
    display: "block",
    whiteSpace: "normal",
    color: "var(--post-card-tier-office-label-color)",
    backgroundColor: "var(--post-card-tier-office-label-bg)",
    width: "100%",
  },
}));

export const compressImagesInContent = async (content) => {
  if (!content) return;

  const parser = new DOMParser();
  const serializer = new XMLSerializer();
  const doc = parser.parseFromString(content, "text/html");
  const images = doc.querySelectorAll("img");

  for (let img of images) {
    const src = img.getAttribute("src");
    if (src && src.startsWith("data:image")) {
      const base64Data = src.split(",")[1];
      const blob = await fetch(`data:image/jpeg;base64,${base64Data}`).then(
        (res) => res.blob()
      );

      const options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 734,
        useWebWorker: true,
        fileType: "image/webp",
      };

      const compressedBlob = await imageCompression(blob, options);

      const compressedBase64 = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(compressedBlob);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });

      img.setAttribute("src", compressedBase64);
    }
  }

  return serializer.serializeToString(doc);
};

export const calculateContentLength = (content) => {
  const div = document.createElement("div");
  div.innerHTML = content;

  let textLength = div.textContent.length;

  const images = div.querySelectorAll("img");
  images.forEach((img) => {
    const src = img.getAttribute("src");
    if (src) {
      textLength += src.length;
    }
  });

  return textLength;
};

export const generateGoogleDriveViewerUrl = (fileUrl) => {
  return `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(
    fileUrl
  )}`;
};

export const truncateChatMessage = (message, maxLength = 100) => {
  if (!message || message.length === 0) return;

  if (message.length > maxLength) {
    return `${message.substring(0, maxLength)}...`;
  }
  return message;
};

export const titleCase = (str) => {
  if (typeof str !== "string") return "";
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Collapse,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ExpandMore } from "@mui/icons-material";
import { truncateText, RotateIcon } from "./sidebarHelpers";

function GroupItem({
  sidebarOpen,
  openCategoryTree,
  tierId,
  groupName,
  categories,
  handleItemClick,
  isOpen,
  handleCategoryGroupClick,
  renderCategories,
}) {
  const theme = useTheme();
  const isSmallestDevice = useMediaQuery(theme.breakpoints.up("xs"));
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <React.Fragment key={groupName}>
      <Tooltip title={groupName} placement="right">
        <ListItem
          button
          onClick={() => handleCategoryGroupClick(tierId, groupName)}
          className="group-item"
        >
          {!isSmallDevice && sidebarOpen && (
            <ListItemText
              primary={groupName}
              className="item-text category-type-list"
            />
          )}
          {isSmallDevice && sidebarOpen && (
            <ListItemText
              primary={groupName}
              className="item-text category-type-list"
            />
          )}
          {isSmallDevice && isSmallestDevice && !sidebarOpen && (
            <ListItemText
              className="item-text truncated-text"
              primary={truncateText(groupName, 1)}
            />
          )}
          <RotateIcon expand={openCategoryTree[tierId]?.groups[groupName]}>
            <ExpandMore />
          </RotateIcon>
        </ListItem>
      </Tooltip>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {categories?.map((category) =>
            renderCategories(category, handleItemClick)
          )}
        </List>
      </Collapse>
    </React.Fragment>
  );
}

export default GroupItem;

import React, { useState, useContext, useEffect } from "react";
import {
  useFetchUserDetailsQuery,
  useUpdateImageMutation,
  useUpdateUserNameMutation,
} from "../../services/user";
import { useChangePasswordMutation } from "../../services/password";
import { AuthContext } from "../../context/AuthContext";
import {
  TextField,
  Typography,
  CircularProgress,
  Button,
  Avatar,
  Tooltip,
  IconButton,
} from "@mui/material";
import PasswordChangeDialog from "./PasswordChangeDialog";
import EmailChangeDialog from "./EmailChangeDialog";
import NotificationSnackbar from "../helpers/notification-snackbar";

function UserAccount() {
  const { accountType, email, userId, updateUserImage } =
    useContext(AuthContext);
  // eslint-disable-next-line
  const [changePassword] = useChangePasswordMutation();
  // eslint-disable-next-line
  const [oldPassword, setOldPassword] = useState("");
  // eslint-disable-next-line
  const [newPassword, setNewPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [updateUserName] = useUpdateUserNameMutation();

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const {
    data: userDetails,
    isLoading,
    isError,
    error,
    refetch,
  } = useFetchUserDetailsQuery(userId);
  const [updateImage, { isLoading: isUpdatingImage }] =
    useUpdateImageMutation();

  useEffect(() => {
    if (userDetails) {
      setFirstName(userDetails.firstName || "");
      setLastName(userDetails.lastName || "");
    }
  }, [userDetails]);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const response = await updateImage({ userId, file });
        if (response.error) {
          throw response.error;
        }
        if (response?.data) {
          console.log(response);
          setNotification({
            open: true,
            message: "Image updated successfully!",
          });
          refetch();
          setTimeout(() => {
            updateUserImage(response?.data?.image?.url);
          }, 100);
        }
      } catch (error) {
        setNotification({
          open: true,
          message: `Failed to update image: ${error?.data?.msg}`,
        });
      }
    }
  };

  const handleSaveUserName = async () => {
    const result = await updateUserName({
      userId: userDetails?._id,
      firstName,
      lastName,
    });
    if (result.data) {
      setNotification({
        open: true,
        message: "Name saved successfully.",
      });
      setTimeout(() => {
        refetch();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error saving changes: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const initials =
    (userDetails?.firstName?.[0] || "") + (userDetails?.lastName?.[0] || "");

  const [openDialog, setOpenDialog] = useState(false);
  const [openEmailChangeDialog, setOpenEmailChangeDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenEmailChangeDialog = () => {
    setOpenEmailChangeDialog(true);
  };

  const handleCloseEmailChangeDialog = () => {
    setOpenEmailChangeDialog(false);
  };

  const handleChangePassword = async (oldPassword, newPassword) => {
    if (!userId) {
      setNotification({
        open: true,
        message: `There is no user data`,
      });
      return;
    }
    const result = await changePassword({
      userId,
      oldPassword,
      newPassword,
    });
    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setOldPassword("");
      setNewPassword("");
      setOpenDialog(false);
    } else {
      setNotification({
        open: true,
        message: `Failed to update password: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleEmailChangeCancel = () => {
    handleCloseEmailChangeDialog();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  useEffect(() => {
    if (isError) {
      const errorMessage = error?.data?.msg || "An unexpected error occurred";
      console.log(errorMessage);
      setNotification({
        open: true,
        message: errorMessage,
      });
    }
    refetch();
  }, [isError, error, refetch]);

  if (isLoading || isUpdatingImage) return <CircularProgress />;

  return (
    <div className="user-account-profile-edit-form">
      {!isError && (
        <>
          <div className="profile-edit-form-header">
            <div
              className="profile-edit-form-image-block"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="upload-image-file"
                type="file"
                onChange={handleImageUpload}
              />
              <label htmlFor="upload-image-file">
                <Tooltip title="Upload Photo" arrow>
                  <IconButton color="primary" component="span">
                    <Avatar
                      alt={initials}
                      src={userDetails?.image?.url}
                      sx={{ width: 100, height: 100 }}
                    >
                      {!userDetails?.image?.url && initials}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </label>
              <Button
                variant="text"
                component="span"
                onClick={() =>
                  document.getElementById("upload-image-file").click()
                }
              >
                {userDetails?.image?.url ? "Change Photo" : "Add Photo"}
              </Button>
            </div>

            <div className="header-profile-title">
              <Typography
                variant="h4"
                gutterBottom
                className="profile-edit-form-title"
              >
                {accountType?.toUpperCase()} User Profile
              </Typography>

              <div className="header-profile-title-name">
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="First Name"
                  name="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                  className="header-profile-title-name-item"
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Last Name"
                  name="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                  className="header-profile-title-name-item"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveUserName}
                  className="actions-button"
                >
                  Save Name
                </Button>
              </div>
            </div>
          </div>
          <div className="profile-edit-form-fields">
            <Typography variant="h6">
              <strong>Email:</strong> {email || userDetails?.email}
            </Typography>
            <Typography variant="h6">
              <strong>Private Email?</strong> {userDetails?.isEmailPrivate}
            </Typography>
            {userDetails?.isEmailPrivate && (
              <Typography variant="h6">
                <strong>Public Email</strong> {userDetails?.emailPublic}
              </Typography>
            )}

            <Typography variant="h6">
              <strong>Account Type:</strong>
              {accountType || userDetails?.accountType}
            </Typography>
            <Typography variant="h6">
              <strong>First Name:</strong> {userDetails?.firstName}
            </Typography>
            <Typography variant="h6">
              <strong>Last Name:</strong> {userDetails?.lastName}
            </Typography>
            <Typography variant="h6">
              <strong>Status:</strong>
              {userDetails?.isArchived ? "archived" : "active"}
            </Typography>
          </div>
          <div className="profile-edit-form-actions">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleOpenEmailChangeDialog}
              style={{ marginRight: "10px" }}
            >
              Change Email
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleOpenDialog}
            >
              Change Password
            </Button>
          </div>
          <PasswordChangeDialog
            open={openDialog}
            onClose={handleCloseDialog}
            onConfirm={handleChangePassword}
          />
          <EmailChangeDialog
            open={openEmailChangeDialog}
            onClose={handleCloseEmailChangeDialog}
            userId={userId}
            onCancel={handleEmailChangeCancel}
          />
        </>
      )}
      {isError && (
        <Typography color="error">Error fetching user details</Typography>
      )}
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </div>
  );
}

export default UserAccount;

import React, { useState, useContext, useRef } from "react";
import dayjs from "dayjs";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  TableSortLabel,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { MoreVert, DeleteForever } from "@mui/icons-material/";
import { ConfirmationDialog } from "../..";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { useGetAllBrokerageLevelTiersQuery } from "../../../services/tier";
import { titleCase } from "title-case";
import useInfiniteScrollToBottom from "../../../services/custom-hooks/useInfiniteScrollToBottom";

function UserList({
  users = [],
  onArchiveUser,
  fetchNextPage,
  hasMore,
  statusFilter,
  accountTypeFilter,
  lastOnlineFilter,
  brokerageFilter,
  handleStatusFilterChange,
  handleAccountTypeFilterChange,
  handleLastOnlineFilterChange,
  handleBrokerageFilterChange,
}) {
  const navigate = useNavigate();
  const tableContainerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userIdToArchive, setUserIdToArchive] = useState(null);
  const [currentUserClicked, setCurrentUserClick] = useState(null);
  const [sortField, setSortField] = useState("email");
  const [sortDirection, setSortDirection] = useState("asc");
  const { userId, userRole, accountType } = useContext(AuthContext);
  const filteredByBrokerage = "brokerage";

  const { data: tiers } = useGetAllBrokerageLevelTiersQuery(
    { accountType, filteredByBrokerage },
    {
      skip:
        !userId && (userRole !== "reosadmin" || userRole !== "reossuperadmin"),
    }
  );

  useInfiniteScrollToBottom(tableContainerRef, fetchNextPage, hasMore);

  const handleRowClick = (event, userId) => {
    if (
      event.target.closest(".menu-button") ||
      event.target.closest(".view-details-button")
    ) {
      setCurrentUserClick(userId);
      return;
    }
    navigate(`/user/${userId}`);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleArchiveClick = (event, userId) => {
    event.stopPropagation();
    setUserIdToArchive(userId);
    setDialogOpen(true);
  };

  const handleDialogClose = (event) => {
    event && event.stopPropagation();
    setDialogOpen(false);
    setUserIdToArchive(null);
  };

  const handleDialogConfirm = (event) => {
    event && event.stopPropagation();
    onArchiveUser(userIdToArchive);
    handleDialogClose();
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  const sortedUsers = [...users]?.sort((a, b) => {
    if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
    if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
    return 0;
  });

  const renderBrokerages = (tiers) => {
    // Check if tiers is an array and has elements
    if (!Array.isArray(tiers) || tiers.length === 0) {
      return <div>No Brokerages Listed</div>; // Return this if no brokerages are found
    }

    return (
      <div className="brokerage-list">
        {tiers &&
          tiers
            ?.slice(0, 3)
            .map((tier /* display only the first 3 items */) => (
              <div
                key={tier?._id}
                style={{
                  color: brokerageFilter
                    ? tier?.brokerage?._id === brokerageFilter
                      ? ""
                      : "yellowgreen"
                    : undefined,
                }}
              >
                {tier?.brokerage?.officeName}
              </div>
            ))}
      </div>
    );
  };
  const tiersIds = tiers?.map((t) => t?._id);

  return (
    <>
      <Box sx={{ marginBottom: "10px", display: "flex", gap: "10px" }}>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          sx={{ minWidth: 120 }}
        >
          <InputLabel id="brokerage-filter-label">
            Filter by brokerage
          </InputLabel>
          <Select
            labelId="brokerage-filter-label"
            value={brokerageFilter}
            onChange={handleBrokerageFilterChange}
            label="Filter by brokerage"
          >
            <MenuItem value="">All</MenuItem>
            {tiers &&
              tiers?.map((tier) => (
                <MenuItem key={tier?._id} value={tier?._id}>
                  {`${tier?.level} ${
                    tier?.brokerage ? tier?.brokerage?.officeName : ""
                  } ${
                    tier?._id === tier?.brokerage?.leadBrokerageTier
                      ? " - (Lead Brokerage Office)"
                      : ""
                  } ${
                    tier?._id === tier?.brokerage?.tier ? " - (Office)" : ""
                  }`}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          sx={{ minWidth: 120 }}
        >
          <InputLabel id="status-filter-label">Filter by status</InputLabel>
          <Select
            labelId="status-filter-label"
            value={statusFilter}
            onChange={handleStatusFilterChange}
            label="Filter by status"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          sx={{ minWidth: 120 }}
        >
          <InputLabel id="account-type-filter-label">
            Filter by account type
          </InputLabel>
          <Select
            labelId="account-type-filter-label"
            value={accountTypeFilter}
            onChange={handleAccountTypeFilterChange}
            label="Filter by account type"
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="reosadmin">REOS Admin</MenuItem>
            <MenuItem value="brokerage">Brokerage</MenuItem>
            <MenuItem value="member">Member</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          sx={{
            minWidth: 200,
            flexGrow: 0,
            flexShrink: 0,
            ml: 0,
          }}
        >
          <InputLabel sx={{ mt: "5px" }} id="time-filter-label">
            Online Since
          </InputLabel>
          <Select
            value={lastOnlineFilter}
            onChange={handleLastOnlineFilterChange}
            label="Last Online"
          >
            <MenuItem value="now">Online Now</MenuItem>
            <MenuItem value="15m">Last 15 Minutes</MenuItem>
            <MenuItem value="30m">Last 30 Minutes</MenuItem>
            <MenuItem value="1h">Last Hour</MenuItem>
            <MenuItem value="4h">Last 4 Hours</MenuItem>
            <MenuItem value="12h">Last 12 Hours</MenuItem>
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="week">This Week</MenuItem>
            <MenuItem value="month">This Month</MenuItem>
            <MenuItem value="ever">Show All</MenuItem>
            <MenuItem value="never">Never Logged In</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          fontWeight: "bolder",
          textAlign: "center",
          marginBottom: "20px",
          width: "100%",
          gap: "10px",
        }}
      >
        # of Results: {sortedUsers?.length}
      </Box>
      <TableContainer
        component={Paper}
        className="users-table-paper"
        ref={tableContainerRef}
      >
        <Table density="compact" className="user-table-compact">
          <TableHead>
            <TableRow
              sx={{
                color: "var(--text-color)",
                backgroundColor: "var(--bg-color)",
              }}
            >
              <TableCell>
                <TableSortLabel
                  active={sortField === "firstName"}
                  direction={sortDirection}
                  onClick={() => handleSort("firstName")}
                >
                  First
                </TableSortLabel>
                <TableSortLabel
                  active={sortField === "lastName"}
                  direction={sortDirection}
                  onClick={() => handleSort("lastName")}
                >
                  Last
                </TableSortLabel>
                <TableSortLabel
                  active={sortField === "email"}
                  direction={sortDirection}
                  onClick={() => handleSort("email")}
                >
                  Email
                </TableSortLabel>
              </TableCell>

              <TableCell>
                <TableSortLabel
                  active={sortField === "accountType"}
                  direction={sortDirection}
                  onClick={() => handleSort("accountType")}
                >
                  Account Type
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "status"}
                  direction={sortDirection}
                  onClick={() => handleSort("status")}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "lastOnlineTime"}
                  direction={sortDirection}
                  onClick={() => handleSort("lastOnlineTime")}
                >
                  Last Online
                </TableSortLabel>
              </TableCell>
              <TableCell>Brokerage(s)</TableCell>
              <TableCell>Actions </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(sortedUsers) ? (
              sortedUsers?.map(
                (user) =>
                  !user?.isArchived && (
                    <TableRow
                      key={user?._id}
                      onClick={(event) => handleRowClick(event, user?._id)}
                      //style={{ cursor: "pointer" }}
                      sx={{ m: 0, p: 0 }}
                      className="table-row"
                    >
                      <TableCell sx={{ pl: 1, pr: 0, whiteSpace: "nowrap" }}>
                        {`${titleCase(user?.firstName)} ${titleCase(
                          user?.lastName
                        )}`}
                        <br />
                        {user?.email}
                      </TableCell>
                      <TableCell sx={{ pl: 1, pr: 0, whiteSpace: "nowrap" }}>
                        {user?.accountType}
                      </TableCell>
                      <TableCell sx={{ pl: 1, pr: 0, whiteSpace: "nowrap" }}>
                        {user?.status}
                      </TableCell>
                      <TableCell sx={{ pl: 1, pr: 0 }}>
                        {user.lastOnlineTime ? (
                          dayjs(user.lastOnlineTime).fromNow()
                        ) : (
                          <span style={{ color: "red" }}>Never</span>
                        )}
                      </TableCell>

                      <TableCell sx={{ pl: 1, pr: 0 }}>
                        {renderBrokerages(user?.role?.tiers)}
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            aria-label="delete"
                            onClick={(event) =>
                              handleArchiveClick(event, user?._id)
                            }
                          >
                            <DeleteForever />
                          </IconButton>
                          <IconButton
                            className="menu-button"
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(event) =>
                              handleMenuOpen(event, user?._id)
                            }
                          >
                            <MoreVert />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose}
                          >
                            <MenuItem>
                              <Typography
                                variant="body1"
                                className="view-details-button"
                                onClick={() =>
                                  navigate(`/user/${currentUserClicked}`)
                                }
                              >
                                View Details
                              </Typography>
                            </MenuItem>
                          </Menu>
                          <ConfirmationDialog
                            open={dialogOpen}
                            onClose={handleDialogClose}
                            onConfirm={handleDialogConfirm}
                            title="Confirm Archivation"
                            description="Are you sure you want to archive this user?"
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
              )
            ) : (
              <Typography variant="h5" m="2rem 0.1rem 0.5rem 5rem">
                No users available
              </Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default UserList;

const categoryIconList = [
  "dashboard",
  "group",
  "campaign",
  "update",
  "birthday",
  "moving",
  "calendar-month",
  "list-alt",
  "forum",
  "people",
  "poll",
  "school",
  "domain",
  "rankings",
  "ninja",
  "car",
  "house",
  "gavel",
  "legal",
  "library-books",
  "training",
  "folder",
  "shield",
  "marketing",
  "chat",
  "wants-needs",
  "services-and-trades",
  "water-cooler",
  "money",
  "building",
  "business",
  "rlp",
  "danger",
];

export default categoryIconList;

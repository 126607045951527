import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Tabs, Tab } from "@mui/material";
import UserAccount from "./UserAccount";
import EditMemberProfile from "./EditMemberProfile";
import { TabPanel } from "../helpers/utils";

const EditProfileDataForm = () => {
  const { userId, accountType } = useContext(AuthContext);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="edit-profile-data-form">
      <Tabs
        value={value}
        onChange={handleChange}
        className="edit-profile-data-tabs-header"
      >
        {accountType !== "reosadmin" && <Tab label="Member  Profile" />}
        <Tab label="User  Account  Profile" />
      </Tabs>
      <div className="edit-profile-data-tabs-body">
        {accountType !== "reosadmin" && (
          <TabPanel value={value} index={0}>
            <EditMemberProfile userMemberId={userId} />
          </TabPanel>
        )}
        <TabPanel value={value} index={accountType !== "reosadmin" ? 1 : 0}>
          <UserAccount />
        </TabPanel>
      </div>
    </div>
  );
};

export default EditProfileDataForm;

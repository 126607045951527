import React, { useState, useEffect, useContext, useRef, memo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, CircularProgress } from "@mui/material";
import { AuthContext } from "../../../context/AuthContext";
import {
  useGetAllPostsByTierOrCategoryQuery,
  useDeletePostMutation,
} from "../../../services/post";
import PostCard from "../../posts/PostCard";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import { ConfirmationDialog } from "../..";
import useInfiniteScroll from "../../../services/custom-hooks/useInfiniteScrollToBottom";

function TierPosts({ selectedTierId }) {
  const loaderRef = useRef(null);
  const { userId, accountType } = useContext(AuthContext);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [allPosts, setAllPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [shouldPaginate, setShouldPaginate] = useState(false);

  useEffect(() => {
    setShouldPaginate(true);
  }, []);

  useEffect(() => {
    setAllPosts([]);
    setPage(1);
  }, [selectedTierId]);

  const {
    data: dataPosts,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetAllPostsByTierOrCategoryQuery(
    {
      tierId: selectedTierId,
      page,
      limit: 10,
      shouldPaginate: shouldPaginate.toString(),
    },
    { skip: !selectedTierId || !userId || accountType !== "reosadmin" }
  );

  useEffect(() => {
    if (dataPosts?.posts) {
      setAllPosts((prev) => [...prev, ...dataPosts.posts]);
      setHasMore(dataPosts.posts.length > 0);
    }
  }, [dataPosts]);

  useInfiniteScroll(
    loaderRef,
    () => {
      if (!isFetching) {
        setPage((prevPage) => prevPage + 1);
      }
    },
    hasMore
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const [postIdToDelete, setPostIdToDelete] = useState(null);
  const [deletePost] = useDeletePostMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleDeletePost = async () => {
    const result = await deletePost({
      postId: postIdToDelete,
      tierId: selectedTierId,
    });
    handleDialogClose();

    if (result.error) {
      setNotification({
        open: true,
        message: `Error deleting post. ${result?.error?.data?.msg}`,
      });
    } else {
      setNotification({ open: true, message: "Post deleted successfully." });
      refetch();
    }
  };

  const handleDialogOpen = (postId) => {
    setDialogOpen(true);
    setPostIdToDelete(postId);
  };

  const handleDialogClose = (event) => {
    event && event.stopPropagation();
    setDialogOpen(false);
    setPostIdToDelete(null);
  };

  if (isLoading)
    return (
      <div className="loading-spinner">
        <CircularProgress size={24} />
      </div>
    );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handlePostSelect = (postId) => {
    navigate(`/post/${postId}`);
  };

  if (allPosts?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 20,
        }}
      >
        <Typography variant="h6" m="2rem 0.1rem 0.5rem 0.5rem">
          There are no posts yet
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box className="home-page" ref={loaderRef}>
        <div className="posts-list" mt={2}>
          {allPosts &&
            allPosts.length > 0 &&
            allPosts?.map((post) => (
              <PostCard
                key={post?._id}
                post={post}
                currentUserId={userId}
                onDialogOpen={handleDialogOpen}
                refetchPosts={refetch}
                isPostsLoading={isLoading}
                isPostsError={isError}
                accountType={accountType}
                onSelect={() => handlePostSelect(post?._id)}
                isPostList={true}
              />
            ))}
          {(isLoading || isFetching) && (
            <div className="loading-spinner">
              <CircularProgress size={20} />
            </div>
          )}
        </div>
        <ConfirmationDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          onConfirm={handleDeletePost}
          title="Confirm Deleting"
          description="Are you sure you want to delete this post?"
        />
        <NotificationSnackbar
          open={notification.open}
          message={notification.message}
          onClose={handleClose}
        />
      </Box>
    </>
  );
}

export default memo(TierPosts);

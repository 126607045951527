import React, { useState, useContext } from "react";
import { titleCase } from "title-case";
import {
  useGetAllTiersQuery,
  useMigrateCategoryToNewTierMutation,
} from "../../../services/tier";
import { AuthContext } from "../../../context/AuthContext";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Autocomplete,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const MigrateCategoryForm = ({ open, onClose, category }) => {
  const { accountType } = useContext(AuthContext);
  const [migrsteCategoryToNewTier, { isLoading }] =
    useMigrateCategoryToNewTierMutation();
  const { data: tiers } = useGetAllTiersQuery(accountType, {
    skip: accountType !== "reosadmin",
  });

  const [newTier, setNewTier] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      (newTier && !newTier?._id?.trim()) ||
      (category && !category?._id?.trim())
    ) {
      setNotification({
        open: true,
        message: "Please fill in all required fields.",
      });
      return;
    }
    const result = await migrsteCategoryToNewTier({
      newTierId: newTier?._id,
      categoryId: category?._id,
    });
    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        setNewTier(null);
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error: ${result.error.data?.msg || "Unknown error"}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth className="edit-post">
      <DialogTitle>
        <Typography>Migrate Category To New Tier </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid item>
            <Autocomplete
              value={newTier}
              onChange={(event, newValue) => {
                setNewTier(newValue);
              }}
              options={(tiers || []).slice().sort((a, b) => {
                // Assuming you want to sort based on the brokerage, team, or group name
                const nameA = `${
                  a?.brokerage?.officeName || a?.team?.name || a?.group?.name
                }`.toLowerCase();
                const nameB = `${
                  b?.brokerage?.officeName || b?.team?.name || b?.group?.name
                }`.toLowerCase();
                return nameA.localeCompare(nameB);
              })}
              getOptionLabel={(option) =>
                option
                  ? `${titleCase(option?.level)}${
                      (option?.brokerage || option?.team || option?.group) && // Check if next word is printed
                      (option?.brokerage?.officeName ||
                        option?.team?.name ||
                        option?.group?.name) // Check if next word is present
                        ? ` - ${
                            option?.brokerage?.officeName ||
                            option?.team?.name ||
                            option?.group?.name
                          }` // Add "-" if next word is printed
                        : ""
                    } ${
                      option?.level === "system" || option?.level === "global"
                        ? " - REACirc"
                        : ""
                    } ${
                      option?._id === option?.brokerage?.leadBrokerageTier
                        ? " - (Lead Brokerage Office)"
                        : ""
                    } ${
                      option?._id === option?.brokerage?.tier
                        ? " - (Office)"
                        : ""
                    }`
                  : ""
              }
              isOptionEqualToValue={(option, value) =>
                option?._id === value._id
              }
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a Tier"
                  variant="outlined"
                  margin="normal"
                  required
                />
              )}
            />
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid item>
          <Button onClick={onClose} color="secondary" type="submit" fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Migrate"}
          </Button>
        </Grid>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default MigrateCategoryForm;

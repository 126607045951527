import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MemberProfilePageContext } from "../../context/MemberProfilePageContext";
import { TierContext } from "../../context/TierContext";
import MemberProfile from "./MemberProfile";
import MemberProfilePageNavbar from "./MemberProfilePageNavbar";

const MemberProfilePage = () => {
  const { userId } = useParams();
  const { setMemberProfilePageActive } = useContext(MemberProfilePageContext);
  const { currentTierId } = useContext(TierContext);

  useEffect(() => {
    setMemberProfilePageActive(true);

    return () => {
      setMemberProfilePageActive(false);
    };
  }, [setMemberProfilePageActive]);

  if (!userId) return;

  return (
    <div className="member-profile-page-container">
      <MemberProfilePageNavbar />
      <MemberProfile userMemberId={userId} tierId={currentTierId} />
    </div>
  );
};

export default MemberProfilePage;

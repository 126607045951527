import React from "react";

const EnvironmentBanner = () => {
  const isLocal =
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1" ||
    window.location.hostname === "nsc" ||
    window.location.hostname === "rlp";
  const isDev = !window.location.hostname.includes(".reacirc.com");

  return (
    <>
      {isLocal ? (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            backgroundColor: "rgba(255, 0, 0, 0.45)",
            marginLeft: "15px",
            marginTop: "15px",
            color: "white",
            padding: "3px 6px",
            borderRadius: "5px",
            width: "150px",
            textAlign: "center",
            fontWeight: 700,
            transform: "rotate(-10deg)",
            transformOrigin: "top left",
            zIndex: "999",
          }}
        >
          Local Dev
        </div>
      ) : null}
      {isDev && !isLocal ? (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            backgroundColor: "rgba(0, 255, 0, 0.45)",
            marginLeft: "15px",
            marginTop: "15px",
            color: "white",
            padding: "3px 6px",
            borderRadius: "5px",
            width: "150px",
            textAlign: "center",
            fontWeight: 700,
            transform: "rotate(-10deg)",
            transformOrigin: "top left",
            zIndex: "999",
          }}
        >
          DEV
        </div>
      ) : null}
    </>
  );
};

export default EnvironmentBanner;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const groupApi = createApi({
  reducerPath: "group",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/groups/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Group"],
  endpoints: (builder) => ({
    createGroup: builder.mutation({
      query: ({ name, memberId, teamId }) => ({
        url: "create",
        method: "POST",
        body: { name, memberId, teamId },
      }),
      invalidatesTags: [{ type: "Group", id: "LIST" }],
    }),
    updateGroup: builder.mutation({
      query: ({ name, memberId, teamId, groupId }) => ({
        url: `update/${groupId}`,
        method: "PUT",
        body: { name, memberId, teamId },
      }),
      invalidatesTags: [{ type: "Group", id: "LIST" }],
    }),
    getAllGroups: builder.query({
      query: (teamId) => ({
        url: `all-groups/${teamId}`,
        method: "GET",
      }),
      skip: (teamId) => !teamId,
      providesTags: [{ type: "Group", id: "LIST" }],
    }),
    getAllGroupMembers: builder.query({
      query: (groupId) => ({
        url: `all-group-members/${groupId}`,
        method: "GET",
      }),
      skip: (groupId) => !groupId,
      providesTags: [{ type: "Group", id: "LIST" }],
    }),
    getGroupById: builder.query({
      query: (groupId) => ({
        url: `get-one-by-id/${groupId}`,
        method: "GET",
      }),
      providesTags: (result, error, groupId) => [
        { type: "Group", id: groupId },
      ],
    }),
    addExistingMember: builder.mutation({
      query: ({ groupId, memberId, roleId }) => ({
        url: "add-existing-member",
        method: "POST",
        body: { groupId, memberId, roleId },
      }),
    }),
    deleteMember: builder.mutation({
      query: ({ groupId, memberId }) => ({
        url: `remove-member`,
        method: "DELETE",
        body: { groupId, memberId },
      }),
      invalidatesTags: [{ type: "Group", id: "LIST" }],
    }),
    deleteGroup: builder.mutation({
      query: ({ groupId }) => ({
        url: `delete/${groupId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Group", id: "LIST" }],
    }),
    inviteNewMember: builder.mutation({
      query: ({ memberEmail, userId, groupId, tierId, groupRoleId }) => ({
        url: `invite-new-member/`,
        method: "POST",
        body: { memberEmail, userId, groupId, tierId, groupRoleId },
      }),
      invalidatesTags: ["Invitation"],
    }),
    signupNewMember: builder.mutation({
      query: (memberData) => ({
        url: `register-new-member-to-group/`,
        method: "POST",
        body: memberData,
      }),
    }),
  }),
});

export const {
  useGetGroupByIdQuery,
  useGetAllGroupsQuery,
  useGetAllGroupMembersQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useAddExistingMemberMutation,
  useDeleteGroupMutation,
  useDeleteMemberMutation,
  useInviteNewMemberMutation,
  useSignupNewMemberMutation,
} = groupApi;

export default groupApi;
export { groupApi };

import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import ConfirmationDialog from "../helpers/ConfirmationDialog";
import FileItem from "./FileItem";

import {
  Button,
  Grid,
  List,
  ListItem,
  Typography,
  CircularProgress,
  Paper,
  Switch,
  Breadcrumbs,
  Link,
  FormControlLabel,
} from "@mui/material";

import Alert from "@mui/material/Alert";
import { styled } from "@mui/material/styles";

import {
  useGetAllFilesQuery,
  useDeleteFileMutation,
  useDownloadFileMutation,
  useRenameFileMutation,
} from "../../services/file";
import { AuthContext } from "../../context/AuthContext";
import { TierContext } from "../../context/TierContext";

const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

const FileList = ({
  currentPath,
  setCurrentPath,
  pathStack,
  setPathStack,
  setNotification,
  render,
  isAdmin,
}) => {
  const { currentTierId } = useContext(TierContext);
  const { userId } = useContext(AuthContext);
  const [viewMode, setViewMode] = useState("list");
  const [allFiles, setAllFiles] = useState([]);
  const [deleteFile, { isLoading: isDeleting }] = useDeleteFileMutation();
  const [downloadFile, { isLoading: isDownloading }] =
    useDownloadFileMutation();
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [fileIdToDelete, setFileIdToDelete] = useState(null);

  const [renameFile] = useRenameFileMutation();
  const [editingFileId, setEditingFileId] = useState(null);
  const [newFileName, setNewFileName] = useState("");
  const [newFileDescription, setNewFileDescription] = useState("");
  const [renameTrigger, setRenameTrigger] = useState(false);
  const [showInterface, setShowInterface] = useState(false);

  const {
    data: files,
    error,
    isLoading,
    isFetching,
  } = useGetAllFilesQuery(
    { tierId: currentTierId, userId: userId },
    {
      skip: !currentTierId || currentTierId === "undefined",
      dependencies: [currentPath, pathStack, allFiles],
    }
  );

  useEffect(() => {
    if (files) {
      setAllFiles(files);
    }
  }, [files]);

  const filteredFiles = useMemo(() => {
    return allFiles.filter(
      (file) =>
        file.parentDirectoryId === (currentPath === "" ? null : currentPath)
    );
  }, [allFiles, currentPath]);

  const handleRenameDialogClose = (event) => {
    event && event.stopPropagation();
    setRenameDialogOpen(false);
  };

  const handleGoUp = () => {
    const newPath =
      pathStack.length > 1 ? pathStack[pathStack.length - 2].path : "";
    setCurrentPath(newPath);
    setPathStack(pathStack.slice(0, -1));
  };

  const handleDirectoryClick = (directoryId, directoryName) => {
    setPathStack([...pathStack, { path: directoryId, name: directoryName }]);
    setCurrentPath(directoryId);
  };
  const handleDelete = (fileId) => {
    setFileIdToDelete(fileId);
    setDeleteDialogOpen(true);
  };

  const executeDelete = async () => {
    if (fileIdToDelete) {
      try {
        await deleteFile(fileIdToDelete).unwrap();
        setAllFiles((prevFiles) =>
          prevFiles.filter((file) => file._id !== fileIdToDelete)
        );
        setDeleteDialogOpen(false); // Close the dialog upon successful deletion
        setFileIdToDelete(null); // Reset the fileIdToDelete state
        setNotification({
          open: true,
          message: "File deleted successfully.",
        });
      } catch (error) {
        console.error("Failed to delete the file:", error);
        setNotification({
          open: true,
          message: "Failed to delete the file.",
        });
      }
      setDeleteDialogOpen(false);
    }
  };

  const getExtension = (filename) => {
    const lastDot = filename.lastIndexOf(".");
    if (lastDot === -1) return "";
    return filename.substring(lastDot + 1);
  };

  const handleRename = (fileId, newName, newDescription) => {
    setEditingFileId(fileId);
    setNewFileName(newName);
    setNewFileDescription(newDescription);
    setRenameTrigger(true); // Set the trigger for the effect
  };
  // Inside your component
  const executeRename = useCallback(async () => {
    // Wrap with useCallback
    try {
      await renameFile({
        fileId: editingFileId,
        newFileName,
        newFileDescription,
      }).unwrap();
      setAllFiles((prev) =>
        prev.map((file) =>
          file._id === editingFileId
            ? { ...file, name: newFileName, description: newFileDescription }
            : file
        )
      );
      setNotification({ open: true, message: "Rename successful!" });
      setRenameDialogOpen(false);
    } catch (error) {
      console.error("Failed to rename the file:", error);
      setNotification({ open: true, message: "Failed to rename the file." });
    }
  }, [renameFile, editingFileId, newFileName, newFileDescription, setNotification]); // Declare dependencies

  // Use useEffect to react to changes in relevant state
  useEffect(() => {
    if (renameTrigger && editingFileId && newFileName) {
      const originalFile = allFiles.find((file) => file._id === editingFileId);
      if (!originalFile) {
        console.error("File not found.");
        setRenameTrigger(false);
        return;
      }

      const originalExtension = getExtension(originalFile.name);
      const newExtension = getExtension(newFileName);

      if (originalExtension !== newExtension) {
        setRenameDialogOpen(true);
      } else {
        executeRename();
      }
      setRenameTrigger(false); // Reset the trigger
    }
  }, [renameTrigger, allFiles, editingFileId, executeRename, newFileName]); // Depend only on renameTrigger

  const handleDownload = async (file) => {
    try {
      // console.log(file);
      const result = await downloadFile(file._id).unwrap();
      const downloadUrl = result.url; // Assuming the URL is returned in this property
      // Option 1: Redirect to the URL directly
      window.location.href = downloadUrl;
    } catch (error) {
      console.error("Download failed:", error);
      setNotification({
        open: true,
        message: "Failed to download the file.",
      });
    }
  };

  const handleDeleteDialogClose = (event) => {
    event && event.stopPropagation();
    setDeleteDialogOpen(false);
    setFileIdToDelete(null); // Reset the fileIdToDelete state
  };

  const DirectoryBreadcrumbs = () => {
    const handleClick = (index) => {
      const newPath =
        index === -1 ? { path: "", name: "Home" } : pathStack[index];
      setCurrentPath(newPath.path);
      setPathStack(pathStack.slice(0, index + 1));
    };

    return (
      <Breadcrumbs sx={{ mt: 2 }} aria-label="breadcrumb">
        {pathStack.length > 0 ? (
          <Link component="button" onClick={() => handleClick(-1)}>
            Home
          </Link>
        ) : (
          <Typography color="textPrimary">Home</Typography>
        )}
        {pathStack.map((entry, index) =>
          index === pathStack.length - 1 ? (
            <Typography key={index} color="textPrimary">
              {entry.name}
            </Typography>
          ) : (
            <Link
              key={index}
              component="button"
              onClick={() => handleClick(index)}
            >
              {entry.name}
            </Link>
          )
        )}
      </Breadcrumbs>
    );
  };

  const ParentDirectoryItem = () =>
    currentPath && (
      <ListItem button onClick={handleGoUp}>
        <Typography variant="body1">.. (Go up one level)</Typography>
      </ListItem>
    );

  if (isLoading || isFetching) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  if (error) {
    console.error("Error fetching files:", error);
    return <div>Error loading files. Please try again later.</div>;
  }

  if (isLoading) {
    return <div>Loading files...</div>;
  }

  if (isFetching) {
    return <div>Updating files...</div>;
  }

  if (!files) {
    return <div>No files to display</div>;
  }

  return (
    <>
      <Container>
        <Typography variant="h5" gutterBottom>
          Stored Files -{" "}
          {pathStack.length > 0 ? pathStack[pathStack.length - 1].name : "Home"}
        </Typography>
        {isAdmin && (
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              {showInterface && render && (
                <div style={{ width: "100%" }}>
                  {render({ refetch: () => setAllFiles(files) })}
                </div>
              )}
            </div>
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center", // Align items to the center for a balanced look
                  marginTop: 20, // Increase margin for better vertical spacing
                  backgroundColor: "var(--bg-color)",
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => setShowInterface(!showInterface)}
                  style={{ margin: 10 }} // Apply margin all around for consistent spacing
                >
                  {!showInterface
                    ? "Upload/Create Directory"
                    : "Hide File Operations"}
                </Button>

                {false && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={viewMode === "list"}
                        onChange={() =>
                          setViewMode(viewMode === "grid" ? "list" : "grid")
                        }
                      />
                    }
                    label="Toggle View"
                    style={{ margin: 10 }} // Apply margin all around for consistent spacing
                  />
                )}
              </div>
            </>
          </div>
        )}

        <DirectoryBreadcrumbs />
        {viewMode === "grid" ? (
          <Grid container spacing={2}>
            {currentPath && <ParentDirectoryItem />}
            {filteredFiles.map((file) => (
              <Grid item xs={12} sm={6} md={4} key={file._id}>
                <FileItem
                  file={file}
                  setEditingFileId={setEditingFileId}
                  setNewFileDescription={setNewFileDescription}
                  setNewFileName={setNewFileName}
                  handleRename={handleRename}
                  handleDownload={handleDownload}
                  handleDelete={handleDelete}
                  handleDirectoryClick={handleDirectoryClick}
                  isDownloading={isDownloading}
                  isDeleting={isDeleting}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <List>
            {currentPath && <ParentDirectoryItem />}
            {filteredFiles.map((file) => (
              <ListItem key={file._id} divider>
                <FileItem
                  file={file}
                  isAdmin={isAdmin}
                  setEditingFileId={setEditingFileId}
                  setNewFileDescription={setNewFileDescription}
                  setNewFileName={setNewFileName}
                  handleRename={handleRename}
                  handleDownload={handleDownload}
                  handleDelete={handleDelete}
                  handleDirectoryClick={handleDirectoryClick}
                  isDownloading={isDownloading}
                  isDeleting={isDeleting}
                />
              </ListItem>
            ))}
          </List>
        )}
        {filteredFiles.length === 0 && (
          <Grid container justifyContent="center" alignItems="center">
            <Alert severity="info">No files found.</Alert>
          </Grid>
        )}
      </Container>
      <ConfirmationDialog
        open={renameDialogOpen}
        onClose={handleRenameDialogClose}
        onConfirm={executeRename}
        title="Confirm Renaming"
        description="Extensions do not match - are you sure you want to rename?"
      />
      <ConfirmationDialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onConfirm={executeDelete}
        title="Confirm Delete"
        description="Are you sure you want to delete?"
      />
    </>
  );
};

export default FileList;

import React, { createContext, useState, useContext } from "react";

export const BrokerageEcoSystemContext = createContext({
  brokerageEcoSysId: null,
  setBrokerageEcoSysId: () => {},
});

export const useBrokerageEcoSystemContext = () =>
  useContext(BrokerageEcoSystemContext);

export const BrokerageEcoSystemProvider = ({ children }) => {
  const [brokerageEcoSysId, setBrokerageEcoSysId] = useState("");

  return (
    <BrokerageEcoSystemContext.Provider
      value={{ brokerageEcoSysId, setBrokerageEcoSysId }}
    >
      {children}
    </BrokerageEcoSystemContext.Provider>
  );
};

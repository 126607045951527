import React, { useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import {
  TextField,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { useAssignUserToBrokerageMutation } from "../../../services/brokerage";
import { useFetchAllUsersQuery } from "../../../services/user";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const BrokerageAssignUserForm = ({ brokerage, open, onClose }) => {
  const { accountType } = useContext(AuthContext);

  const [assignUserToBrokerage, { isLoading }] =
    useAssignUserToBrokerageMutation();
  const { data: users } = useFetchAllUsersQuery(accountType, {
    skip: accountType !== "reosadmin",
  });
  const [selectedUser, setSelectedUser] = useState(null);

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleSubmit = async () => {
    if (brokerage?.tier?.level !== "brokerage" && !selectedUser?._id.trim()) {
      setNotification({
        open: true,
        message: "The tier isn't Brokerage or no user chosen.",
      });
      return;
    }

    const result = await assignUserToBrokerage({
      userId: selectedUser?._id,
      brokerageId: brokerage?._id,
    });
    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error: ${result.error.data?.msg || "Unknown error"}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Typography>Assign User To Brokerage</Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Autocomplete
            value={selectedUser}
            onChange={(event, newValue) => {
              setSelectedUser(newValue);
            }}
            options={users || []}
            getOptionLabel={(option) =>
              option
                ? `${option?.firstName} ${option?.lastName} ${option?.email}`
                : ""
            }
            isOptionEqualToValue={(option, value) => option?._id === value._id}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a User"
                variant="outlined"
                margin="normal"
                required
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          className="actions-button"
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : "Assign User"}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default BrokerageAssignUserForm;

import React, { useContext, memo } from "react";
import { AppBar, Toolbar } from "@mui/material";
import { SidebarContext } from "../../context/SidebarContext";
import { BackButton } from "..";

const MemberProfilePageNavbar = ({ selectedMemberProfile }) => {
  const { sidebarOpen } = useContext(SidebarContext);
  const memberProfilePageNavbarClass = `app-bar ${
    !sidebarOpen ? "sidebar-closed" : "sidebar-open"
  }`;

  return (
    <AppBar className={memberProfilePageNavbarClass}>
      <Toolbar className={memberProfilePageNavbarClass}>
        <BackButton />
      </Toolbar>
    </AppBar>
  );
};

export default memo(MemberProfilePageNavbar);

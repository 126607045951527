import React, { useState, useContext, memo } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import EditIcon from "@mui/icons-material/Edit"; // For a pencil/edit icon
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Badge,
  Divider,
} from "@mui/material";
import {
  MoreVert,
  Edit,
  DeleteForever,
  ThumbUpAlt,
  ThumbUpOffAlt,
} from "@mui/icons-material/";
import {
  useLikeReplyMutation,
  useUnlikeReplyMutation,
} from "../../services/post";
import { linkify, AvatarStyledBadge } from "../helpers/utils";
import EditReply from "./EditReply";
import NotificationSnackbar from "../helpers/notification-snackbar";

const ReplyCard = ({
  reply,
  comment,
  onMemberClick,
  refetchPosts,
  categoryPath = "",
  tierId,
}) => {
  const { userId, accountType } = useContext(AuthContext);
  const currentUserId = userId;
  const [likeReply] = useLikeReplyMutation();
  const [unlikeReply] = useUnlikeReplyMutation();
  const [replyMenuAnchorEl, setReplyMenuAnchorEl] = useState({});
  const [openEditReplyDialog, setOpenEditReplyDialog] = useState(false);

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const isLikedByCurrentUser = reply?.likes?.some(
    (like) => like.author?._id === currentUserId
  );

  const handleMenuOpen = (event, replyId) => {
    event.stopPropagation();
    setReplyMenuAnchorEl({
      ...replyMenuAnchorEl,
      [replyId]: event.currentTarget,
    });
  };

  const handleMenuClose = (replyId) => {
    setReplyMenuAnchorEl({ ...replyMenuAnchorEl, [replyId]: null });
  };

  const handleLikeReply = async () => {
    const result = await likeReply({
      userId: userId,
      replyId: reply?._id,
      tierId,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `The error occured ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleUnlikeReply = async () => {
    const result = await unlikeReply({
      userId: currentUserId,
      replyId: reply?._id,
    });
    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
    } else {
      setNotification({
        open: true,
        message: `The error occured ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleOpenEditReplyDialog = (event) => {
    event.stopPropagation();
    setOpenEditReplyDialog(true);
  };

  const handleCloseEditReplyDialog = () => {
    setOpenEditReplyDialog(false);
    handleMenuClose(reply?._id);
  };

  const isAuthor = reply?.author?._id === currentUserId;
  const isAuthorOrAdmin = isAuthor || accountType === "reosadmin";

  const additionalLikes =
    reply?.likes?.length > 2 ? reply?.likes?.length - 2 : 0;

  const renderLikeAvatars = () => {
    const avatarsToShow =
      reply?.likes &&
      reply?.likes?.slice(0, 2)?.map((like, index) => (
        <Tooltip key={index} title="Member Profile link">
          <Avatar
            src={like?.author?.image?.url}
            alt={`${like?.author?.firstName} ${like?.author?.lastName}`}
            sx={{
              width: 24,
              height: 24,
              marginRight: -0.75,
              cursor: "pointer",
            }}
          />
        </Tooltip>
      ));

    return <div className="likes-avatars">{avatarsToShow}</div>;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Card
      sx={{ marginBottom: 2 }}
      key={reply?._id}
      className="post-comment-card reply-card"
    >
      <CardHeader
        className="post-comment-card-header"
        avatar={
          <Tooltip title="Member Profile link">
            <AvatarStyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
              color="success"
              invisible={!reply?.author?.isOnline}
            >
              <Avatar
                className="avatar"
                src={reply?.author?.image?.url}
                onClick={(event) => {
                  event.stopPropagation();
                  onMemberClick?.(reply?.author?._id);
                }}
              >
                R
              </Avatar>
            </AvatarStyledBadge>
          </Tooltip>
        }
        title={
          <div className="comment-header-block">
            <Tooltip title="Member Profile link">
              <Link
                onClick={(event) => {
                  event.stopPropagation();
                  onMemberClick?.(reply?.author?._id);
                }}
                className="avatar-name"
              >
                {reply?.author?.firstName} {reply?.author?.lastName}
              </Link>
            </Tooltip>
            <Typography className="additional-text">
              {" commented "}
              {reply?.author?._id === comment?.author?._id
                ? " - by Author"
                : ""}
            </Typography>
          </div>
        }
        subheader={
          <Typography className="date-text">
            {new Date(reply?.createdAt).toLocaleString()}
          </Typography>
        }
        action={
          <>
            <IconButton
              className="menu-button"
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(event) => handleMenuOpen(event, reply?._id)}
            >
              <MoreVert />
            </IconButton>
            <Menu
              id={`menu-${reply?._id}`}
              anchorEl={replyMenuAnchorEl[reply?._id]}
              open={Boolean(replyMenuAnchorEl[reply?._id])}
              onClose={() => handleMenuClose(reply?._id)}
            >
              {isAuthorOrAdmin && (
                <MenuItem
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOpenEditReplyDialog(event);
                  }}
                >
                  <Edit />
                  <Typography ml={1}>Edit Reply</Typography>
                </MenuItem>
              )}
              {accountType === "reosadmin" && (
                <MenuItem
                // onClick={(event) => {
                //   event.stopPropagation();
                //   onDialogOpen(event, comment?._id);
                // }}
                >
                  <DeleteForever />
                  <Typography ml={1}>Delete Reply</Typography>
                </MenuItem>
              )}
              {!(accountType === "reosadmin") && !isAuthorOrAdmin && (
                <MenuItem>
                  <Typography ml={1}>No actions</Typography>
                </MenuItem>
              )}
            </Menu>
          </>
        }
      />
      <CardContent className="reply-content">
        <Tooltip title="Follow the link">
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: linkify(reply?.content),
            }}
            className="replaced-link"
          />
        </Tooltip>
        {reply?.lastEdited && (
          <Typography variant="caption" color="textSecondary" textAlign="right">
            <EditIcon sx={{ verticalAlign: "middle", mr: 0.5 }} />
            Last edited on {new Date(reply?.lastEdited)?.toLocaleString()}
          </Typography>
        )}
        {categoryPath !== "legally-speaking" && (
          <div className="likes-block">
            <Tooltip
              title={additionalLikes > 0 ? `+ ${additionalLikes} more` : ""}
            >
              <Badge
                badgeContent={additionalLikes > 0 ? `+${additionalLikes}` : ""}
                className="likes-block-avatars"
              >
                {renderLikeAvatars()}
              </Badge>
            </Tooltip>
          </div>
        )}
      </CardContent>
      <Divider />
      {categoryPath !== "legally-speaking" && (
        <CardActions className="comment-card-actions-block">
          <div className="comment-card-actions">
            <div className="likes-block">
              <Tooltip title={isLikedByCurrentUser ? "Unlike it" : "Like it"}>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    isLikedByCurrentUser
                      ? handleUnlikeReply()
                      : handleLikeReply();
                  }}
                >
                  {isLikedByCurrentUser ? <ThumbUpAlt /> : <ThumbUpOffAlt />}
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </CardActions>
      )}
      <EditReply
        refetchPosts={refetchPosts}
        reply={reply}
        open={openEditReplyDialog}
        onClose={() => {
          handleCloseEditReplyDialog();
          handleMenuClose(reply?._id);
        }}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Card>
  );
};

export default memo(ReplyCard);

import React, { useState, forwardRef } from "react";
import { IconButton, Tooltip, Menu, MenuItem } from "@mui/material";
import { useDeleteMessageMutation } from "../../services/messages";
import { MoreHoriz, DeleteForever } from "@mui/icons-material/";
import { ConfirmationDialog } from "../../components";
import NotificationSnackbar from "../helpers/notification-snackbar";

const MessageBlock = forwardRef(
  ({ message, isCurrentUser, refetchMessages }, ref) => {
    const [deleteMessage] = useDeleteMessageMutation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedMessageId, setSelectedMessageId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [notification, setNotification] = useState({
      open: false,
      message: "",
    });

    const dateTime = new Date(message?.createdAt);
    const formattedTime = dateTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    const alignmentClass = isCurrentUser
      ? "message-block-left"
      : "message-block-right";

    const handleDeleteMessageClick = async (event, messageId) => {
      event.stopPropagation();
      setSelectedMessageId(messageId);
      setDialogOpen(true);
    };

    const handleDialogClose = (event) => {
      event && event.stopPropagation();
      setDialogOpen(false);
      setSelectedMessageId(null);
    };

    const handleDialogConfirm = async (event) => {
      event && event.stopPropagation();
      const result = await deleteMessage({ messageId: selectedMessageId });
      if (result?.data) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        handleDialogClose();
        setTimeout(() => {
          refetchMessages?.();
        }, 1000);
      } else if (result?.error) {
        setNotification({
          open: true,
          message: `Error: ${
            result?.error?.data?.msg || "Failed to delete message"
          }`,
        });
      }
    };

    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setNotification({ ...notification, open: false });
    };

    return (
      <div className={alignmentClass} ref={ref}>
        <div
          className={`message-block-body ${
            isCurrentUser
              ? "message-block-body-sender"
              : "message-block-body-receiver"
          }`}
        >
          <div className="message-block-header">
            <small className="message-block-time">{formattedTime}</small>
            <Tooltip title="Handle Message">
              <IconButton
                className="menu-button"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(event) => handleMenuOpen(event, message?._id)}
              >
                <MoreHoriz />
              </IconButton>
            </Tooltip>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
            >
              <MenuItem>
                <Tooltip title="Delete Message">
                  <IconButton
                    className="delete-button"
                    aria-label="delete"
                    onClick={(event) => {
                      handleDeleteMessageClick(event, message?._id);
                      handleMenuClose();
                    }}
                  >
                    <DeleteForever />
                  </IconButton>
                </Tooltip>
              </MenuItem>
            </Menu>
          </div>
          <div>{message?.content}</div>
        </div>
        <ConfirmationDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          onConfirm={handleDialogConfirm}
          title="Confirm Message Removing"
          description="Are you sure you want to delete this message?"
        />
        <NotificationSnackbar
          open={notification.open}
          message={notification.message}
          onClose={handleClose}
        />
      </div>
    );
  }
);

export default MessageBlock;

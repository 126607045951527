import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const errorLogsApi = createApi({
  reducerPath: "error-log",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/error-logs/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["ErrorLog"],
  endpoints: (builder) => ({
    getAllErrorLogs: builder.query({
      query: (accountType) => {
        return {
          url: `get-all`,
          method: "GET",
        };
      },
      skip: (accountType) => accountType !== "reosadmin",
      providesTags: [{ type: "ErrorLog", id: "LIST" }],
    }),
  }),
});

export const { useGetAllErrorLogsQuery } = errorLogsApi;

export default errorLogsApi;
export { errorLogsApi };

import React, { useState } from "react";
import { useGetAllBrokerageProfilesQuery } from "../../../services/brokerage";
import {
  useGetAllMembersQuery,
  useAssignDefaultBrokerageToMemberMutation,
} from "../../../services/member";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const AssignDefaultBrokerageToMemberDialog = ({
  open,
  onClose,
  preselectedMember,
  isMemberProfileOpened = false,
}) => {
  const { data: members } = useGetAllMembersQuery();
  const { data: brokerages } = useGetAllBrokerageProfilesQuery();
  const [assignDefaultBrokerageToMember] =
    useAssignDefaultBrokerageToMemberMutation();
  const [selectedBrokerageId, setSelectedBrokerageId] = useState(null);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [inputbrokeragevalue, setInputbrokeragevalue] = useState("");
  const [inputmembervalue, setInputmembervalue] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const result = await assignDefaultBrokerageToMember({
      brokerageId: selectedBrokerageId?._id,
      memberId: preselectedMember
        ? preselectedMember?.user
        : selectedMemberId?.user?._id,
    });

    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        onClose();
        setInputbrokeragevalue();
        setInputmembervalue();
        setSelectedBrokerageId(null);
        setSelectedMemberId(null);
      }, 2000);
    } else {
      setNotification({
        open: true,
        message: `Error: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Assign Member to Brokerage</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} direction="column">
            <Grid item>
              {preselectedMember ? (
                <TextField
                  label="Selected Member"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={`${preselectedMember?.firstName} ${preselectedMember?.lastName} (${preselectedMember?.email})`}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ) : (
                <Autocomplete
                  value={selectedMemberId}
                  onChange={(event, newValue) => {
                    setSelectedMemberId(newValue);
                  }}
                  inputValue={inputmembervalue}
                  onInputChange={(event, newInputValue) => {
                    setInputmembervalue(newInputValue);
                  }}
                  options={members || []}
                  getOptionLabel={(option) =>
                    option
                      ? `${option?.user?.firstName} ${option?.user?.lastName} (${option?.user?.email})`
                      : ""
                  }
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select a Member"
                      variant="outlined"
                      margin="normal"
                      required
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item>
              <Autocomplete
                value={selectedBrokerageId}
                onChange={(event, newValue) => {
                  setSelectedBrokerageId(newValue);
                }}
                inputValue={inputbrokeragevalue}
                onInputChange={(event, newInputValue) => {
                  setInputbrokeragevalue(newInputValue);
                }}
                options={brokerages || []}
                getOptionLabel={(option) =>
                  option ? `${option?.officeName}` : ""
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Brokerage"
                    variant="outlined"
                    margin="normal"
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={
            !selectedBrokerageId ||
            (isMemberProfileOpened && !preselectedMember)
          }
        >
          Assign Member to Brokerage
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default AssignDefaultBrokerageToMemberDialog;

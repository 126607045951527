import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const brokerageApi = createApi({
  reducerPath: "brokerage",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/brokerages/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Brokerage"],
  endpoints: (builder) => ({
    getAllLevelsBrokerageProfiles: builder.query({
      query: (userId) => ({
        url: "all-levels-brokerage-profiles",
        method: "GET",
      }),
      skip: (userId) => !userId,
    }),
    getAllBrokerageProfiles: builder.query({
      query: (userId) => ({
        url: "brokerage-profiles",
        method: "GET",
      }),
      skip: (userId) => !userId,
    }),
    getAllLeadBrokerageProfiles: builder.query({
      query: (userId) => ({
        url: "lead-brokerage-profiles",
        method: "GET",
      }),
      skip: (userId) => !userId,
    }),
    getBrokerageProfileById: builder.query({
      query: (brokerageId) => ({
        url: `brokerage-profile-by-id/${brokerageId}`,
        method: "GET",
      }),
      skip: (brokerageId) => !brokerageId,
      providesTags: (result, error, brokerageId) => [
        { type: "Brokerage", id: brokerageId },
      ],
    }),
    createBrokerage: builder.mutation({
      query: ({ userId, leadBrokerage, nestedBrokerages, brokerageData }) => ({
        url: `create-brokerage`,
        method: "POST",
        body: {
          userId,
          leadBrokerage,
          nestedBrokerages,
          brokerageData,
        },
      }),
      invalidatesTags: ["Brokerage"],
    }),
    
    updateBrokerage: builder.mutation({
      query: ({
        brokerageId,
        leadBrokerage,
        nestedBrokerages,
        brokerageData,
      }) => ({
        url: `update-brokerage/${brokerageId}`,
        method: "PUT",
        body: {
          leadBrokerage,
          nestedBrokerages,
          brokerageData,
        },
      }),
      invalidatesTags: ["Brokerage"],
    }),

    updateBrokerageStatus: builder.mutation({
      query: ({ brokerageId, status }) => ({
        url: `update-brokerage-status/${brokerageId}`,
        method: "PUT",
        body: { status },
      }),
      invalidatesTags: ["Brokerage"],
    }),
    uploadImage: builder.mutation({
      query: ({ brokerageId, brokerageImage }) => {
        const formData = new FormData();
        formData.append("file", brokerageImage);
        return {
          url: `update-image/${brokerageId}`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: ["Brokerage"],
    }),
    deleteBrokerage: builder.mutation({
      query: ({ brokerageId }) => ({
        url: `delete-one/${brokerageId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Brokerage"],
    }),
    createInitialLeadBrokerageCategories: builder.mutation({
      query: ({ leadBrokerageId }) => ({
        url: `create-initial-lead-brokerage-categories/${leadBrokerageId}`,
        method: "POST",
      }),
      invalidatesTags: ["Brokerage"],
    }),
    createBrokerageInitialCategory: builder.mutation({
      query: ({ tierId }) => ({
        url: `create-brokerage-initialize-category-and-types/${tierId}`,
        method: "POST",
      }),
      skip: (tierId) => !tierId,
      invalidatesTags: [{ type: "Category", id: "LIST" }],
    }),
    addRegBrokerageToLeadBrokerageItSelf: builder.mutation({
      query: ({ brokerageId, leadBrokerageId }) => ({
        url: `add-reg-brokerage-to-lead-itself/${brokerageId}/${leadBrokerageId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Brokerage"],
    }),
    addToLeadBrokerage: builder.mutation({
      query: ({ brokerageId, leadBrokerageId }) => ({
        url: `add-to-lead/${brokerageId}/${leadBrokerageId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Brokerage"],
    }),
    removeFromLeadBrokerage: builder.mutation({
      query: ({ brokerageId, leadBrokerageId }) => ({
        url: `remove-from-lead/${brokerageId}/${leadBrokerageId}`,
        method: "PUT",
      }),
      invalidatesTags: ["Brokerage"],
    }),
    makeBrokerageLead: builder.mutation({
      query: ({ brokerageId }) => ({
        url: `make-brokerage-lead/${brokerageId}`,
        method: "POST",
      }),
      invalidatesTags: ["Brokerage"],
    }),
    demoteBrokerageLead: builder.mutation({
      query: ({ brokerageId }) => ({
        url: `demote-brokerage-lead/${brokerageId}`,
        method: "POST",
      }),
      invalidatesTags: ["Brokerage"],
    }),
    assignUserToBrokerage: builder.mutation({
      query: ({ userId, brokerageId }) => ({
        url: `assign-user-to-brokerage`,
        method: "POST",
        body: {
          userId,
          brokerageId,
        },
      }),
      invalidatesTags: ["Brokerage"],
    }),
    syncLeadBrokerageData: builder.mutation({
      query: ({ leadBrokerageId }) => ({
        url: `sync-lead-brokerage-data/${leadBrokerageId}`,
        method: "POST",
      }),
      invalidatesTags: ["Brokerage"],
    }),
  }),
});

export const {
  useGetAllLevelsBrokerageProfilesQuery,
  useGetAllBrokerageProfilesQuery,
  useGetAllLeadBrokerageProfilesQuery,
  useGetBrokerageProfileByIdQuery,
  useCreateBrokerageMutation,
  useUpdateBrokerageMutation,
  useUpdateBrokerageStatusMutation,
  useUploadImageMutation,
  useDeleteBrokerageMutation,
  useCreateInitialLeadBrokerageCategoriesMutation,
  useCreateBrokerageInitialCategoryMutation,
  useAddToLeadBrokerageMutation,
  useAddRegBrokerageToLeadBrokerageItSelfMutation,
  useRemoveFromLeadBrokerageMutation,
  useMakeBrokerageLeadMutation,
  useDemoteBrokerageLeadMutation,
  useAssignUserToBrokerageMutation,
  useSyncLeadBrokerageDataMutation,
} = brokerageApi;

export default brokerageApi;
export { brokerageApi };

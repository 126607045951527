import React, { useState, useEffect } from "react";
import {
  useCreateTierMutation,
  useUpdateTierMutation,
  useGetTierTypeEnumsQuery,
  useGetTierLevelEnumsQuery,
  useGetTierFeaturesEnumsQuery,
} from "../../../services/tier";
import { useGetAllBrokerageProfilesQuery } from "../../../services/brokerage";
import { useGetAllReosAdminProfilesQuery } from "../../../services/reosadmin";
import { useGetAllTeamsQuery } from "../../../services/team";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const CreateEditTierForm = ({
  open,
  onClose,
  tier,
  refetchTiers,
  mode = "edit",
}) => {
  const [tierName, setTierName] = useState("");
  const [tierShortName, setTierShortName] = useState("");
  const [tierType, setTierType] = useState("");
  const [tierLevel, setTierLevel] = useState("");
  const [tierFeature, setTierFeature] = useState("");
  const [createTier, { isLoading: isCreating }] = useCreateTierMutation();
  const [updateTier, { isLoading: isUpdating }] = useUpdateTierMutation();
  const { data: tierTypes } = useGetTierTypeEnumsQuery();
  const { data: tierLevels } = useGetTierLevelEnumsQuery();
  const { data: tierFeatures } = useGetTierFeaturesEnumsQuery();
  const { data: reosadmins } = useGetAllReosAdminProfilesQuery();
  const { data: brokerages } = useGetAllBrokerageProfilesQuery();
  const { data: teams } = useGetAllTeamsQuery();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [brokerageId, setBrokerageId] = useState("");
  const [inputbrokeragevalue, setInputbrokeragevalue] = useState("");

  const [reosadminId, setReosadminId] = useState("");
  const [inputreosadminvalue, setInputreosadminvalue] = useState("");

  const [teamId, setTeamId] = useState("");
  const [inputteamvalue, setInputteamvalue] = useState("");

  useEffect(() => {
    if (tier) {
      setTierName(tier.name);
      setTierShortName(tier.shortName);
      setTierType(tier.type);
      setTierLevel(tier.level);
      setTierFeature(tier.features);
    }
  }, [tier]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!tierLevel?.trim() || !tierFeature?.trim()) {
      setNotification({
        open: true,
        message: "Please fill in all required fields.",
      });
      return;
    }

    let result;
    if (mode === "create") {
      result = await createTier({
        name: tierName || tierLevel,
        shortName: tierShortName || tierName || tierLevel,
        type: tierType,
        level: tierLevel,
        features: tierFeature,
        brokerageId: brokerageId?._id,
        reosadminId: reosadminId?._id,
        teamId: teamId?._id,
      });
    } else if (mode === "edit") {
      result = await updateTier({
        tierId: tier?._id,
        name: tierName || tierLevel,
        shortName: tierShortName || tierName || tierLevel,
        type: tierType,
        level: tierLevel,
        features: tierFeature,
        brokerageId: brokerageId?._id,
        reosadminId: reosadminId?._id,
        teamId: teamId?._id,
      });
    }
    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        refetchTiers?.();
        onClose();
        if (mode === "create") {
          setTierName("");
          setTierShortName("");
          setTierType("");
          setTierLevel("");
          setTierFeature("");
          setBrokerageId("");
          setReosadminId("");
          setTeamId("");
          setInputbrokeragevalue();
          setInputreosadminvalue();
          setInputteamvalue();
        }
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error saving tier: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth className="edit-post">
      <DialogTitle>
        <Typography>
          {`${mode === "create" ? "Create New Tier" : "Edit Tier"}${
            tier && tier.name ? " - " + tier.name : ""
          }${
            tier && tier.brokerage ? " - " + tier?.brokerage?.officeName : ""
          }${
            tier &&
            tier.brokerage &&
            tier.brokerage.isLeadBrokerage &&
            tier.brokerage.leadBrokerageTier
              ? " - Lead Brokerage Office"
              : ""
          }`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid item>
            <TextField
              fullWidth
              required
              label="Tier Name"
              variant="outlined"
              margin="normal"
              placeholder="Tier Name"
              value={tierName}
              onChange={(e) => setTierName(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              label="Tier Short Name"
              variant="outlined"
              margin="normal"
              placeholder="Tier Short Name"
              value={tierShortName}
              onChange={(e) => setTierShortName(e.target.value)}
            />
          </Grid>
          <Grid item>
            <FormControl fullWidth margin="normal">
              <InputLabel id="tier-level-label">Type</InputLabel>
              <Select
                labelId="tier-level-label"
                id="tierLevelId"
                value={tierType}
                onChange={(e) => setTierType(e.target.value)}
                fullWidth
              >
                {tierTypes &&
                  tierTypes?.map((tierTypeItem, i) => (
                    <MenuItem key={`${tierTypeItem}-${i}`} value={tierTypeItem}>
                      {tierTypeItem}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth margin="normal">
              <InputLabel id="tier-level-label">Level</InputLabel>
              <Select
                labelId="tier-level-label"
                id="tierLevelId"
                value={tierLevel}
                onChange={(e) => setTierLevel(e.target.value)}
                fullWidth
              >
                {tierLevels &&
                  tierLevels?.map((tierLevelItem, i) => (
                    <MenuItem
                      key={`${tierLevelItem}-${i}`}
                      value={tierLevelItem}
                    >
                      {tierLevelItem}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth margin="normal">
              <InputLabel id="tier-feature-label">Feature</InputLabel>
              <Select
                labelId="tier-feature-label"
                id="roleId"
                value={tierFeature}
                onChange={(e) => setTierFeature(e.target.value)}
                fullWidth
              >
                {tierFeatures &&
                  tierFeatures?.map((tierFeatureItem, i) => (
                    <MenuItem
                      key={`${tierFeatureItem}-${i}`}
                      value={tierFeatureItem}
                    >
                      {tierFeatureItem}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Autocomplete
              value={reosadminId}
              onChange={(event, newValue) => {
                setReosadminId(newValue);
              }}
              inputValue={inputreosadminvalue}
              onInputChange={(event, newInputValue) => {
                setInputreosadminvalue(newInputValue);
              }}
              options={reosadmins || []}
              getOptionLabel={(option) =>
                option
                  ? `${option?.user?.firstName} ${option?.user?.lastName} (${option?.user?.email})`
                  : ""
              }
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a REOS Admin"
                  variant="outlined"
                  margin="normal"
                  required
                />
              )}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              value={brokerageId}
              onChange={(event, newValue) => {
                setBrokerageId(newValue);
              }}
              inputValue={inputbrokeragevalue}
              onInputChange={(event, newInputValue) => {
                setInputbrokeragevalue(newInputValue);
              }}
              options={brokerages || []}
              getOptionLabel={(option) =>
                option
                  ? `${option?.officeName}`
                  : ""
              }
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a Brokerage"
                  variant="outlined"
                  margin="normal"
                  required
                />
              )}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              value={teamId}
              onChange={(event, newValue) => {
                setTeamId(newValue);
              }}
              inputValue={inputteamvalue}
              onInputChange={(event, newInputValue) => {
                setInputteamvalue(newInputValue);
              }}
              options={teams || []}
              getOptionLabel={(option) => (option ? `${option?.name}` : "")}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a Team"
                  variant="outlined"
                  margin="normal"
                  required
                />
              )}
            />
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" type="submit" fullWidth>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
          disabled={isCreating || isUpdating}
        >
          {isCreating || isUpdating ? (
            <CircularProgress size={24} />
          ) : mode === "create" ? (
            "Create"
          ) : (
            "Save"
          )}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default CreateEditTierForm;

import React, { useState, useMemo } from "react";
import { titleCase } from "title-case";
import { useGetAllRolesQuery } from "../../../services/role";
import {
  useAssignViewerTierToRoleMutation,
  useGetAllTiersQuery,
} from "../../../services/tier";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import { defaultViewerPermissions } from "./DefaultPermissions";
import { Delete } from "@mui/icons-material";

const AssignViewerTierToRoleDialog = ({ open, onClose, preselectedRole }) => {
  const { data: tiers } = useGetAllTiersQuery();
  const { data: roles } = useGetAllRolesQuery();
  const [assignViewerTierToRole] = useAssignViewerTierToRoleMutation();
  const [selectedTierId, setSelectedTierId] = useState(null);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const [inputtiervalue, setInputtiervalue] = useState("");
  const [inputrolevalue, setInputrolevalue] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [newPermission, setNewPermission] = useState("");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!(selectedRoleId || preselectedRole) || !selectedTierId) {
      setNotification({
        open: true,
        message: "There are no role or tier id.",
      });
      return;
    }

    if (permissions && permissions?.length === 0) {
      setNotification({
        open: true,
        message: "There are no permissions.",
      });
      return;
    }

    const result = await assignViewerTierToRole({
      roleId: preselectedRole ? preselectedRole?._id : selectedRoleId?._id,
      viewerTierId: selectedTierId?._id,
      permissions,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        onClose();
        setInputrolevalue();
        setInputtiervalue();
        setSelectedRoleId(null);
        setSelectedTierId(null);
        setPermissions([]);
      }, 2000);
    } else {
      setNotification({
        open: true,
        message: `Error assigning Role to the User. ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const sortOptions = (a, b) => {
    // First, compare by level
    const levelA = a.level.toUpperCase(); // case-insensitive comparison
    const levelB = b.level.toUpperCase();
    if (levelA !== levelB) {
      return levelA.localeCompare(levelB);
    }

    // If levels are the same, then sort by name
    const nameA = (
      a.brokerage?.officeName ||
      a.team?.name ||
      a.group?.name ||
      ""
    ).toUpperCase();
    const nameB = (
      b.brokerage?.officeName ||
      b.team?.name ||
      b.group?.name ||
      ""
    ).toUpperCase();
    return nameA.localeCompare(nameB);
  };
  const sortedOptions = useMemo(() => {
    return [...(tiers || [])].sort(sortOptions);
  }, [tiers]);

  const handleAddPermission = () => {
    if (newPermission && !permissions.includes(newPermission)) {
      setPermissions([...permissions, newPermission]);
      setNewPermission("");
    }
  };

  const handleRemovePermission = (permission) => {
    setPermissions(permissions.filter((perm) => perm !== permission));
  };

  const toggleDefaultPermissions = () => {
    const currentPermissions = new Set(permissions);
    if (
      defaultViewerPermissions.every((perm) => currentPermissions.has(perm))
    ) {
      setPermissions(
        permissions.filter((perm) => !defaultViewerPermissions.includes(perm))
      );
    } else {
      setPermissions([
        ...new Set([...permissions, ...defaultViewerPermissions]),
      ]);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Assign Tier to Role</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} direction="column">
            <Grid item>
              {preselectedRole ? (
                <TextField
                  label="Selected Role"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={`${titleCase(preselectedRole?.name)} - ${
                    preselectedRole?.user?.firstName
                  } ${preselectedRole?.user?.lastName}`}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ) : (
                <Autocomplete
                  value={selectedRoleId}
                  onChange={(event, newValue) => {
                    setSelectedRoleId(newValue);
                  }}
                  inputValue={inputrolevalue}
                  onInputChange={(event, newInputValue) => {
                    setInputrolevalue(newInputValue);
                  }}
                  options={roles || []}
                  getOptionLabel={(option) =>
                    option
                      ? `${option?.name} ${
                          option?.user ? option?.user?.firstName : ""
                        } ${option?.user ? option?.user?.lastName : ""} (${
                          option?.user ? option?.user?.email : ""
                        })`
                      : ""
                  }
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select a Role"
                      variant="outlined"
                      margin="normal"
                      required
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item>
              <Autocomplete
                value={selectedTierId}
                onChange={(event, newValue) => {
                  setSelectedTierId(newValue);
                }}
                inputValue={inputtiervalue}
                onInputChange={(event, newInputValue) => {
                  setInputtiervalue(newInputValue);
                }}
                options={sortedOptions || []}
                getOptionLabel={(option) =>
                  option
                    ? `${titleCase(option?.level)} ${
                        option?.brokerage || option?.team || option?.group
                          ? option?.brokerage?.officeName ||
                            option?.team?.name ||
                            option?.group?.name
                          : ""
                      } ${
                        option?.level === "system" || option?.level === "global"
                          ? " - REACirc"
                          : ""
                      } ${
                        option?._id === option?.brokerage?.leadBrokerageTier
                          ? " - (All Offices)"
                          : ""
                      } ${
                        option?._id === option?.brokerage?.tier
                          ? " - (Office)"
                          : ""
                      }`
                    : ""
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Viewer Tier"
                    variant="outlined"
                    margin="normal"
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="New Permission"
                variant="outlined"
                value={newPermission}
                onChange={(e) => setNewPermission(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && handleAddPermission()}
                fullWidth
              />
              <Button
                onClick={handleAddPermission}
                color="primary"
                style={{ marginTop: "8px" }}
              >
                Add Permission
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={toggleDefaultPermissions}
                color="secondary"
                style={{ marginBottom: "8px" }}
              >
                Toggle Default Permissions
              </Button>
            </Grid>
            <Grid item xs={12}>
              <List dense>
                {permissions?.map((perm, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={perm} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleRemovePermission(perm)}
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Assign Viewer Tier to Role
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default AssignViewerTierToRoleDialog;

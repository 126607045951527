import React, { useState, useEffect, useContext, memo } from "react";
import { useGetAllPostsQuery } from "../../../../services/post";
import { AuthContext } from "../../../../context/AuthContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Box,
  CircularProgress,
  Typography,
  Pagination,
} from "@mui/material";

function AllPostsList() {
  const { accountType, userId } = useContext(AuthContext);
  const [sortField, setSortField] = useState("accessCount");
  const [sortDirection, setSortDirection] = useState("desc");
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [posts, setPosts] = useState([]);

  const { data, isLoading } = useGetAllPostsQuery(
    { page, limit },
    { skip: accountType !== "reosadmin" || !userId }
  );

  useEffect(() => {
    if (data?.posts) {
      setPosts(data.posts);
    }
  }, [data]);

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (isLoading && page === 1) {
    return <CircularProgress />;
  }

  if (!posts || posts.length === 0) {
    return <Typography>No posts available.</Typography>;
  }

  const sortedPosts = [...posts].sort((a, b) => {
    if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
    if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
    return 0;
  });

  return (
    <>
      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={Math.ceil(data.totalPosts / limit)}
          page={page}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortField === "accessCount"}
                  direction={sortDirection}
                  onClick={() => handleSort("accessCount")}
                >
                  Access Count
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "accessedBy"}
                  direction={sortDirection}
                  onClick={() => handleSort("accessedBy")}
                >
                  Accessed By
                </TableSortLabel>
              </TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Author </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "createdAt"}
                  direction={sortDirection}
                  onClick={() => handleSort("createdAt")}
                >
                  Created At
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedPosts.map((post) => (
              <TableRow key={post._id}>
                <TableCell>{post.accessCount}</TableCell>
                <TableCell>
                  {post.accessedBy
                    .map((user) => user?.firstName + " " + user?.lastName)
                    .join(", ")}
                </TableCell>
                <TableCell>{post?.postTitle}</TableCell>
                <TableCell>
                  {post?.author?.firstName + " " + post?.author?.lastName}
                </TableCell>
                <TableCell>
                  {new Date(post?.createdAt).toLocaleDateString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={Math.ceil(data.totalPosts / limit)}
          page={page}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
        />
      </Box>
    </>
  );
}

export default memo(AllPostsList);

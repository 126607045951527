import React, { useContext, useState, useCallback } from "react";
import {
  useGetAllGroupRolesQuery,
  useDeleteGroupRoleMutation,
  useRemovePermissionFromRoleMutation,
} from "../../../../../services/group-role";
import { AuthContext } from "../../../../../context/AuthContext";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  DeleteForever,
  MoreVert,
  Delete,
  Edit,
  AssignmentTurnedIn,
} from "@mui/icons-material/";
import NotificationSnackbar from "../../../../helpers/notification-snackbar";
import { ConfirmationDialog } from "../../../../../components";
import CreateEditGroupRoleDialog from "./CreateEditGroupRoleDialog";
import AddGroupRolePermissionDialog from "./AddGroupRolePermissionDialog";
import AssignGroupRoleDialog from "./AssignGroupRoleDialog";

const GroupRoles = ({ groupId, groupMembers }) => {
  const { userRole } = useContext(AuthContext);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const {
    data: groupRoles,
    error,
    isLoading,
    refetch,
  } = useGetAllGroupRolesQuery(groupId, {
    skip: !groupId,
  });
  const [deleteGroupRole] = useDeleteGroupRoleMutation();
  const [removePermissionFromRole] = useRemovePermissionFromRoleMutation();
  const [groupRoleHeaderMenuAnchorEl, setGroupRoleHeaderMenuAnchorEl] =
    useState({});
  const [isAddGroupRoleOpen, setIsAddGroupRoleOpen] = useState(false);
  const [isAddGroupRolePermissionOpen, setIsAddGroupRolePermissionOpen] =
    useState(false);
  const [groupRoleIdToRemove, setGroupRoleIdToRemove] = useState(null);
  const [groupRolePermissionToRemove, setGroupRolePermissionToRemove] =
    useState(null);
  const [currentGroupRoleId, setCurrentGroupRoleId] = useState(null);
  const [preselectedGroupRole, setPreselectedGroupRole] = useState(null);
  const [dialogState, setDialogState] = useState({
    open: false,
    context: "",
  });
  const [dialogMode, setDialogMode] = useState("add");
  const [assignDialogMode, setAssignDialogMode] = useState("thisRoleToMember");
  const [editingGroupRole, setEditingGroupRole] = useState(null);
  const [openAssignGroupRoleDialog, setOpenAssignGroupRoleDialog] =
    useState(false);

  const handleAddGroupRoleOpen = () => {
    setDialogMode("add");
    setEditingGroupRole(null);
    setIsAddGroupRoleOpen(true);
  };

  const handleAddGroupRoleClose = () => {
    setIsAddGroupRoleOpen(false);
    setEditingGroupRole(null);
  };

  const handleEditGroupRoleOpen = useCallback(
    (groupRole, groupRoleId) => {
      setDialogMode("edit");
      setEditingGroupRole(groupRole);
      setCurrentGroupRoleId(groupRoleId);
      setIsAddGroupRoleOpen(true);
      setGroupRoleHeaderMenuAnchorEl({
        ...groupRoleHeaderMenuAnchorEl,
        [groupRoleId]: null,
      });
    },
    [groupRoleHeaderMenuAnchorEl]
  );

  const handleAddGroupRolePermissionOpen = (groupRoleId) => {
    setCurrentGroupRoleId(groupRoleId);
    setIsAddGroupRolePermissionOpen(true);
  };

  const handleAddGroupRolePermissionClose = () => {
    setIsAddGroupRolePermissionOpen(false);
    setCurrentGroupRoleId(null);
  };

  const handleRemoveGroupRoleOpen = (groupRoleId) => {
    setCurrentGroupRoleId(groupRoleId);
    setGroupRoleIdToRemove(groupRoleId);
    setDialogState({ open: true, context: "deleteGroupRole" });
    setGroupRoleHeaderMenuAnchorEl({
      ...groupRoleHeaderMenuAnchorEl,
      [groupRoleId]: null,
    });
  };

  const handleRemoveGroupRolePermissionOpen = (groupRoleId, permission) => {
    setCurrentGroupRoleId(groupRoleId);
    setGroupRolePermissionToRemove(permission);
    setDialogState({ open: true, context: "removeGroupRolePermission" });
    setGroupRoleHeaderMenuAnchorEl({
      ...groupRoleHeaderMenuAnchorEl,
      [groupRoleId]: null,
    });
  };

  const handleGroupRoleMenuOpen = (event, groupRoleId) => {
    event.stopPropagation();
    setGroupRoleHeaderMenuAnchorEl({
      ...groupRoleHeaderMenuAnchorEl,
      [groupRoleId]: event.currentTarget,
    });
    setCurrentGroupRoleId(groupRoleId);
  };

  const handleGroupRoleMenuClose = (groupRoleId) => {
    setGroupRoleHeaderMenuAnchorEl({
      ...groupRoleHeaderMenuAnchorEl,
      [groupRoleId]: null,
    });
  };

  const handleDialogConfirm = async () => {
    if (dialogState.context === "deleteGroupRole") {
      const result = await deleteGroupRole({
        groupRoleId: groupRoleIdToRemove,
      });

      if (result.data) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
      } else {
        setNotification({
          open: true,
          message: `Error deleting Group Role. ${result?.error?.data?.msg}`,
        });
      }
    } else if (dialogState.context === "removeGroupRolePermission") {
      const result = await removePermissionFromRole({
        groupRoleId: currentGroupRoleId,
        permission: groupRolePermissionToRemove,
      });
      if (result.data) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
      } else {
        setNotification({
          open: true,
          message: `Error deleting Group Role Permission: ${result?.error?.data?.msg}`,
        });
      }
    }
    setTimeout(() => {
      setDialogState({ ...dialogState, open: false });
      refetch();
    }, 1500);
  };

  const handleOpenAssignThisGroupRoleToMemberDialog = (
    groupRole,
    groupRoleId
  ) => {
    setPreselectedGroupRole(groupRole);
    setAssignDialogMode("thisRoleToMember");
    setOpenAssignGroupRoleDialog(true);
    setGroupRoleHeaderMenuAnchorEl({
      ...groupRoleHeaderMenuAnchorEl,
      [groupRoleId]: null,
    });
  };

  const handleCloseAssignGroupRoleDialog = () => {
    setOpenAssignGroupRoleDialog(false);
  };

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (error) return <div>Error: {error?.data?.msg}</div>;

  return (
    <Container sx={{ padding: "1rem 1rem" }}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          paddingBottom: "1rem",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Group Roles:
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleAddGroupRoleOpen(groupId)}
        >
          Add Group Role
        </Button>
      </Grid>
      {groupRoles &&
        groupRoles?.length > 0 &&
        groupRoles?.map((groupRole) => (
          <Card sx={{ mb: 3 }} key={groupRole?._id}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold" }}
                      className="groupDetailsCard__roleLabel"
                    >
                      Group Role:
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "light", ml: 1 }} // Adding margin-left for spacing
                      className="groupDetailsCard__roleValue"
                    >
                      {groupRole?.name}
                    </Typography>
                  </Box>

                  <Tooltip title="Handle group Role" placement="left">
                    <IconButton
                      className="menu-group-role-button"
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(event) => {
                        handleGroupRoleMenuOpen(event, groupRole?._id);
                      }}
                    >
                      <MoreVert />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id={`menu-group-role-${groupRole?._id}`}
                    anchorEl={groupRoleHeaderMenuAnchorEl[groupRole?._id]}
                    open={Boolean(groupRoleHeaderMenuAnchorEl[groupRole?._id])}
                    onClose={() => handleGroupRoleMenuClose(groupRole?._id)}
                  >
                    <MenuItem>
                      <Tooltip title="Edit Group Role" placement="left">
                        <IconButton
                          edge="end"
                          aria-label="edit-group-role"
                          onClick={() =>
                            handleEditGroupRoleOpen(groupRole, groupRole?._id)
                          }
                        >
                          <Edit />
                          <Typography ml={1}>Edit Role</Typography>
                        </IconButton>
                      </Tooltip>
                    </MenuItem>
                    <MenuItem>
                      <Tooltip
                        title="Assign Group Role to Member"
                        placement="left"
                      >
                        <IconButton
                          edge="end"
                          aria-label="assign-group-role-to-member"
                          onClick={() =>
                            handleOpenAssignThisGroupRoleToMemberDialog(
                              groupRole,
                              groupRole?._id
                            )
                          }
                        >
                          <AssignmentTurnedIn />
                          <Typography ml={1}>Assign to Member</Typography>
                        </IconButton>
                      </Tooltip>
                    </MenuItem>
                    <MenuItem>
                      <Tooltip title="Delete Group Role" placement="left">
                        <IconButton
                          edge="end"
                          aria-label="delete-role"
                          onClick={() =>
                            handleRemoveGroupRoleOpen(groupRole?._id)
                          }
                        >
                          <Delete />
                          <Typography ml={1}>Delete Group Role</Typography>
                        </IconButton>
                      </Tooltip>
                    </MenuItem>
                  </Menu>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" fontWeight={700} gutterBottom>
                    Permissions:
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleAddGroupRolePermissionOpen(groupRole?._id)
                    }
                  >
                    Add Permission
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {groupRole?.permissions &&
                  groupRole?.permissions?.length > 0 ? (
                    <Table className="user-table">
                      <TableBody>
                        {groupRole.permissions.map((perm, i) => (
                          <TableRow className="table-row" key={`${perm}-${i}`}>
                            <TableCell>{perm}</TableCell>
                            {(userRole === "reossuperadmin" ||
                              userRole === "reosadmin") && (
                              <TableCell>
                                <IconButton
                                  aria-label="delete"
                                  onClick={() =>
                                    handleRemoveGroupRolePermissionOpen(
                                      groupRole?._id,
                                      perm
                                    )
                                  }
                                >
                                  <DeleteForever />
                                </IconButton>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <Typography>No permissions assigned.</Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      {!groupRoles && (
        <Typography variant="h4" sx={{ padding: "1rem 1rem" }} gutterBottom>
          No Group Roles Found
        </Typography>
      )}
      {groupRoles?.length === 0 && (
        <Typography variant="h4" gutterBottom sx={{ padding: "1rem 1rem" }}>
          No Group Roles created yet
        </Typography>
      )}
      {isLoading && (
        <div className="loading-spinner">
          <CircularProgress size={24} />
        </div>
      )}
      <CreateEditGroupRoleDialog
        open={isAddGroupRoleOpen}
        onClose={handleAddGroupRoleClose}
        groupId={groupId}
        groupRoleId={currentGroupRoleId}
        refetchRoles={refetch}
        action={dialogMode}
        initialRoleData={editingGroupRole}
      />
      <AddGroupRolePermissionDialog
        open={isAddGroupRolePermissionOpen}
        groupRoleId={currentGroupRoleId}
        onClose={handleAddGroupRolePermissionClose}
        refetchRoles={refetch}
      />
      <AssignGroupRoleDialog
        groupId={groupId}
        action={assignDialogMode}
        groupRoles={groupRoles}
        groupMembers={groupMembers}
        preselectedGroupRole={preselectedGroupRole}
        open={openAssignGroupRoleDialog}
        onClose={handleCloseAssignGroupRoleDialog}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
      <ConfirmationDialog
        open={dialogState.open}
        onClose={() => setDialogState({ ...dialogState, open: false })}
        onConfirm={handleDialogConfirm}
        title={
          dialogState.context === "deleteGroupRole"
            ? "Confirm Group Role Deletion"
            : "Confirm Permission Removing from Group Role"
        }
        description={
          dialogState.context === "deleteGroupRole"
            ? "Are you sure you want to delete this Group role?"
            : "Are you sure you want to remove this permission from the Group role?"
        }
      />
    </Container>
  );
};

export default GroupRoles;

import React, { useState } from "react";
import { useDeleteDutyMutation } from "../../../../services/duties";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { House, RingVolume, Delete } from "@mui/icons-material/";
import NotificationSnackbar from "../../../helpers/notification-snackbar";
import { ConfirmationDialog } from "../../..";

const SelectedDutyDialog = ({ selectedEvent, handleCloseDialog, refetch }) => {
  const [deleteDuty, { isLoading: isDeleteDutyLoading }] =
    useDeleteDutyMutation();
  const [deleteDutyDialogOpen, setDeleteDutyDialogOpen] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleOpenDeleteDutyDialog = () => {
    setDeleteDutyDialogOpen(true);
  };

  const handleConfirmDeletingAllDutiesDialog = async () => {
    if (!selectedEvent?._id) {
      setNotification({
        open: true,
        message: "Duty Schedule ID is required.",
      });
      return;
    }
    const result = await deleteDuty({
      dutyId: selectedEvent?._id,
    });

    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        setDeleteDutyDialogOpen(false);
        handleCloseDialog();
        refetch?.();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error deleting duty: ${
          result?.error?.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (!selectedEvent) {
    return;
  }

  return (
    <Dialog
      open={!!selectedEvent}
      onClose={handleCloseDialog}
      aria-labelledby="duty-dialog-title"
      aria-describedby="duty-dialog-description"
    >
      <DialogTitle id="duty-dialog-title">
        <Grid
          container
          spacing={0}
          sx={{
            disply: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item xs={8}>
            <Typography
              id="duty-dialog-description"
              variant="body1"
              gutterBottom
            >
              <strong>Floor Duty Details</strong> {selectedEvent?.dutyType}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            {selectedEvent?.dutyType?.includes("Phone") ||
            selectedEvent?.dutyType?.includes("Remote") ? (
              <RingVolume />
            ) : (
              <House />
            )}
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="Delete Duty">
              <IconButton
                onClick={handleOpenDeleteDutyDialog}
                className="user-management-item"
              >
                {isDeleteDutyLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <Delete className="icon" />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Typography id="duty-dialog-description" variant="body1" gutterBottom>
          <strong>Title:</strong> {selectedEvent?.title}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Start:</strong>{" "}
          {new Date(selectedEvent.start).toLocaleString()}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>End:</strong> {new Date(selectedEvent?.end).toLocaleString()}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Close
        </Button>
      </DialogActions>
      <ConfirmationDialog
        open={deleteDutyDialogOpen}
        onClose={() => setDeleteDutyDialogOpen(false)}
        onConfirm={handleConfirmDeletingAllDutiesDialog}
        title={`Confirm Deleting ${selectedEvent?.title}`}
        description={"Are you sure you want to delete the current Duty?"}
      />

      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default SelectedDutyDialog;

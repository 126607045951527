import React, { useState } from "react";
import { useSendAvailableTimeSlotsMutation } from "../../../../services/duty-emails";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Card,
  CardContent,
  Divider,
  Grid,
  CircularProgress,
} from "@mui/material";
import NotificationSnackbar from "../../../helpers/notification-snackbar";

const AvailableTimeSlotsDialog = ({
  open,
  availableSlots,
  tierId,
  dutyScheduleId,
  handleCloseDialog,
}) => {
  const [sendAvailableTimeSlots, { isLoading: isSendAvailableTimeSlots }] =
    useSendAvailableTimeSlotsMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleSendAvailableTimeSlotsEmail = async () => {
    if (
      !tierId ||
      !dutyScheduleId ||
      !availableSlots ||
      !availableSlots.length
    ) {
      setNotification({
        open: true,
        message: "Tier ID or time slots are not provided.",
      });
      return;
    }

    const result = await sendAvailableTimeSlots({
      tierId,
      dutyScheduleId,
      availableSlots,
    });
    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}.`,
      });
      setTimeout(() => {
        handleCloseDialog();
      }, 2500);
    } else {
      setNotification({
        open: true,
        message: `Failed to send the emails, error: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (!availableSlots) {
    return;
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      aria-labelledby="available-time-slots-dialog-title"
      aria-describedby="available-time-slots-dialog-description"
    >
      <DialogTitle
        id="available-time-slots-dialog-title"
        sx={{ backgroundColor: "#1976d2", color: "white" }}
      >
        Available Time Slots
      </DialogTitle>
      <DialogContent dividers>
        {availableSlots.map((slot, index) => (
          <React.Fragment key={slot._id}>
            <Card variant="outlined" sx={{ mb: 2 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      {slot?.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" gutterBottom>
                      <strong>Start:</strong>{" "}
                      {new Date(slot.start).toLocaleString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" gutterBottom>
                      <strong>End:</strong>{" "}
                      {new Date(slot?.end).toLocaleString()}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {index < availableSlots.length - 1 && <Divider />}
          </React.Fragment>
        ))}
        {(!availableSlots ||
          availableSlots.length === 0 ||
          !availableSlots.length) && (
          <Typography variant="h6" gutterBottom>
            No Available Duty Time Slots have been found
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} variant="outlined" color="primary">
          Close
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleSendAvailableTimeSlotsEmail}
          disabled={
            isSendAvailableTimeSlots ||
            availableSlots.length === 0 ||
            !availableSlots.length
          }
        >
          {isSendAvailableTimeSlots ? (
            <CircularProgress size={24} />
          ) : (
            "Send Emails"
          )}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default AvailableTimeSlotsDialog;

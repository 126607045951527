import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Box,
  TableSortLabel,
} from "@mui/material";
import {
  useGetAllGroupsQuery,
  useDeleteGroupMutation,
} from "../../../../services/group";
import { MoreVert, DeleteForever } from "@mui/icons-material/";
import { ConfirmationDialog } from "../../..";
import { useNavigate } from "react-router-dom";
import NotificationSnackbar from "../../../helpers/notification-snackbar";

function GroupsList({ teamId = "" }) {
  const navigate = useNavigate();
  const [deleteGroup] = useDeleteGroupMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { data: groups, refetch } = useGetAllGroupsQuery(teamId, {
    skip: !teamId || teamId === "undefined" || teamId === "",
  });
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [currentGroupClicked, setCurrentGroupClick] = useState(null);
  const [groupIdToDelete, setGroupIdToDelete] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [sortField, setSortField] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleRowClick = (event, groupId) => {
    if (
      event.target.closest(".menu-button") ||
      event.target.closest(".view-details-button")
    ) {
      setCurrentGroupClick(groupId);
      return;
    }
    navigate(`/group/${groupId}`);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteGroupClick = (event, id) => {
    event && event.stopPropagation();
    setGroupIdToDelete(id);
    setConfirmationDialogOpen(true);
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleConfirmationDialogConfirm = async () => {
    const result = await deleteGroup({
      groupId: groupIdToDelete,
    });
    if (result) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      refetch();
      handleConfirmationDialogClose();
    } else {
      setNotification({
        open: true,
        message: `Error deleting group. ${result?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  if (!groups || groups?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 20,
        }}
      >
        <Typography variant="h5" gutterBottom textAlign="center">
          {`No groups available, 
            use the Create Group Button above
            to create the first one
          `}
        </Typography>
      </Box>
    );
  }

  const sortedGroups = [...groups]?.sort((a, b) => {
    if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1;
    if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1;
    return 0;
  });

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortField === "name"}
                  direction={sortDirection}
                  onClick={() => handleSort("name")}
                >
                  Group Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "groupUrlName"}
                  direction={sortDirection}
                  onClick={() => handleSort("groupUrlName")}
                >
                  Group URL Name
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "groupLead"}
                  direction={sortDirection}
                  onClick={() => handleSort("groupLead")}
                >
                  Group Lead
                </TableSortLabel>
              </TableCell>
              <TableCell>Members</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortField === "createdAt"}
                  direction={sortDirection}
                  onClick={() => handleSort("createdAt")}
                >
                  Created At
                </TableSortLabel>
              </TableCell>
              <TableCell>Actions </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(sortedGroups) &&
              sortedGroups?.map((group) => (
                <TableRow
                  key={group?._id}
                  onClick={(event) => handleRowClick(event, group?._id)}
                  style={{ cursor: "pointer" }}
                  className="table-row"
                >
                  <TableCell>{group?.name}</TableCell>
                  <TableCell>{group?.groupUrlName}</TableCell>
                  <TableCell>{`${group?.groupLead?.member?.user?.firstName} ${group?.groupLead?.member?.user?.lastName}`}</TableCell>
                  <TableCell>{group?.members.length}</TableCell>
                  <TableCell>
                    {new Date(group?.createdAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        aria-label="delete"
                        onClick={(event) =>
                          handleDeleteGroupClick(event, group?._id)
                        }
                      >
                        <DeleteForever />
                      </IconButton>
                      <IconButton
                        className="menu-button"
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleMenuOpen(event, group?._id)}
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                      >
                        <MenuItem>
                          <Typography
                            variant="body1"
                            className="view-details-button"
                            onClick={() =>
                              navigate(`/group/${currentGroupClicked}`)
                            }
                          >
                            View Details
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmationDialogConfirm}
        title="Confirm Deleting the Group"
        description="Are you sure you want to delete this Group permanently?"
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
}

export default GroupsList;

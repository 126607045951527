import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const memberApi = createApi({
  reducerPath: "member",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/members/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Member"],
  endpoints: (builder) => ({
    getMemberProfile: builder.query({
      query: (userId) => ({
        url: `member-profile/${userId}`,
        method: "GET",
      }),
      skip: (userId) => !userId,
      providesTags: (result, error, userId) => [{ type: "Member", id: userId }],
    }),
    getAllMembers: builder.query({
      query: () => ({
        url: "all",
        method: "GET",
      }),
      providesTags: [
        { type: "User", id: "LIST" },
        { type: "User", id: "ACTIVE_LIST" },
        { type: "Member", id: "LIST" },
      ],
    }),
    getAllFreeMembers: builder.query({
      query: () => ({
        url: "all-free",
        method: "GET",
      }),
      providesTags: [
        { type: "User", id: "LIST" },
        { type: "User", id: "ACTIVE_LIST" },
        { type: "Member", id: "LIST" },
      ],
    }),
    getAllMembersGroupedByTier: builder.query({
      query: () => ({
        url: "all-members-grouped-by-tier",
        method: "GET",
      }),
      providesTags: [
        { type: "User", id: "LIST" },
        { type: "User", id: "ACTIVE_LIST" },
        { type: "Member", id: "LIST" },
      ],
    }),
    getAllBrokerageMembers: builder.query({
      query: ({ userId, brokerageId }) => ({
        url: `all-brokerage-members/${userId}/${brokerageId}`,
        method: "GET",
      }),
      skip: (brokerageId) => !brokerageId,
      providesTags: [
        { type: "User", id: "LIST" },
        { type: "User", id: "ACTIVE_LIST" },
        { type: "Member", id: "LIST" },
      ],
    }),
    getAllTierMembers: builder.query({
      query: ({ tierId }) => ({
        url: `all-tier-members/${tierId}`,
        method: "GET",
      }),
      skip: (tierId) => !tierId,
      providesTags: [
        { type: "User", id: "LIST" },
        { type: "User", id: "ACTIVE_LIST" },
        { type: "Member", id: "LIST" },
      ],
    }),
    getAllTierMembersByBrokerage: builder.query({
      query: (brokerageId) => ({
        url: `all-tier-members-by-brokerage/${brokerageId}`,
        method: "GET",
      }),
      skip: (brokerageId) => !brokerageId,
      providesTags: [
        { type: "User", id: "LIST" },
        { type: "User", id: "ACTIVE_LIST" },
        { type: "Member", id: "LIST" },
      ],
    }),
    getMemberPosts: builder.query({
      query: ({ memberId, tierId }) =>
        `get-all-posts-by-member/${memberId}/${tierId}`,
      skip: (memberId) => !memberId,
      providesTags: [
        { type: "User", id: "LIST" },
        { type: "User", id: "ACTIVE_LIST" },
        { type: "Member", id: "LIST" },
      ],
    }),
    getMemberComments: builder.query({
      query: ({ memberId, tierId }) =>
        `get-all-comments-by-member/${memberId}/${tierId}`,
      skip: (memberId) => !memberId,
      providesTags: [
        { type: "User", id: "LIST" },
        { type: "User", id: "ACTIVE_LIST" },
        { type: "Member", id: "LIST" },
      ],
    }),
    updateMemberProfile: builder.mutation({
      query: ({ userId, updateData }) => ({
        url: `update-member-profile/${userId}`,
        method: "PUT",
        body: updateData,
      }),
      invalidatesTags: [{ type: "Member", id: "LIST" }],
    }),
    assignDefaultBrokerageToMember: builder.mutation({
      query: ({ brokerageId, memberId }) => ({
        url: "assign-default-brokerage-to-member",
        method: "POST",
        body: { brokerageId, memberId },
      }),
      invalidatesTags: [{ type: "Member", id: "LIST" }],
    }),
    assignDefaultBrokerageToManyMembers: builder.mutation({
      query: ({ brokerageId, memberIds }) => ({
        url: "assign-default-brokerage-to-many-members",
        method: "POST",
        body: { brokerageId, memberIds },
      }),
      invalidatesTags: [{ type: "Member", id: "LIST" }],
    }),
    addBrokerageToManyMembers: builder.mutation({
      query: ({ brokerageId, memberIds }) => ({
        url: "add-brokerage-to-many-members",
        method: "POST",
        body: { brokerageId, memberIds },
      }),
      invalidatesTags: [{ type: "Member", id: "LIST" }],
    }),
    getDutySchedule: builder.query({
      query: (tierId) => ({
        url: `duty-schedule/${tierId}`,
        method: "GET",
      }),
      providesTags: [{ type: "DutySchedule", id: "LIST" }],
    }),
  }),
});

export const {
  useGetMemberProfileQuery,
  useGetAllBrokerageMembersQuery,
  useGetMemberPostsQuery,
  useGetMemberCommentsQuery,
  useUpdateMemberProfileMutation,
  useAssignDefaultBrokerageToMemberMutation,
  useAssignDefaultBrokerageToManyMembersMutation,
  useAddBrokerageToManyMembersMutation,
  useGetAllMembersQuery,
  useGetAllFreeMembersQuery,
  useGetAllMembersGroupedByTierQuery,
  useGetAllTierMembersQuery,
  useGetAllTierMembersByBrokerageQuery,
  useGetDutyScheduleQuery,
} = memberApi;

export default memberApi;
export { memberApi };

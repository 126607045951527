import React, { useState } from "react";
import { Container, Typography, Paper, Box } from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import CategoriesList from "../../reosadmin/category-management/CategoriesList";
import CategoryGroupsList from "../../reosadmin/category-management/CategoryGroupsList";
import CreateEditCategoryForm from "../../reosadmin/category-management/CreateEditCategoryForm";
import CreateEditCategoryGroupForm from "../../reosadmin/category-management/CreateEditCategoryGroupForm";
import CategoryManagementMenu from "./CategoryManagementMenu";
import AssignAllOfficeCategoryToLocalOfficeDialog from "./AssignAllOfficeCategoryToLocalOfficeDialog";
import AssignGlobalCategoryToLocalOfficeDialog from "./AssignGlobalCategoryToLocalOfficeDialog";

const CategoryManager = ({ tier, isLBTab = false }) => {
  const [notification, setNotification] = useState({
    openNotification: false,
    message: "",
  });
  const [openCreateCategoryDialog, setOpenCreateCategoryDialog] =
    useState(false);
  const [openCreateCategoryGroupDialog, setOpenCreateCategoryGroupDialog] =
    useState(false);
  const [
    openAssignAllOfficeCategoryToLocalOfficeDialog,
    setOpenAssignAllOfficeCategoryToLocalOfficeDialog,
  ] = useState(false);
  const [assignAllOfficeCategoryMode, setAssignAllOfficeCategoryMode] =
    useState("");
  const [assignGlobalCategoryMode, setAssignGlobalCategoryMode] = useState("");
  const [currentCategory, setCurrentCategory] = useState(null);
  const [
    openAssignGlobalCategoryToLocalOfficeDialog,
    setOpenAssignGlobalCategoryToLocalOfficeDialog,
  ] = useState(false);

  const handleOpenCreateCategoryDialog = () => {
    setOpenCreateCategoryDialog(true);
  };

  const handleCloseCreateCategoryDialog = () => {
    setOpenCreateCategoryDialog(false);
  };

  const handleOpenCreateCategoryGroupDialog = () => {
    setOpenCreateCategoryGroupDialog(true);
  };

  const handleCloseCreateCategoryGroupDialog = () => {
    setOpenCreateCategoryGroupDialog(false);
  };

  const handleOpenAssignAllOfficeCategoryToLocalOfficeDialog = (category) => {
    setAssignAllOfficeCategoryMode("assign");
    setCurrentCategory(category);
    setOpenAssignAllOfficeCategoryToLocalOfficeDialog(true);
  };

  const handleCloseAssignAllOfficeCategoryToLocalOfficeDialog = () => {
    setOpenAssignAllOfficeCategoryToLocalOfficeDialog(false);
    setAssignAllOfficeCategoryMode("");
    setCurrentCategory(null);
  };

  const handleOpenRemoveAllOfficeCategoryToLocalOfficeDialog = (category) => {
    setAssignAllOfficeCategoryMode("remove");
    setCurrentCategory(category);
    setOpenAssignAllOfficeCategoryToLocalOfficeDialog(true);
  };

  const handleOpenAssignGlobalCategoryMirroringDialog = () => {
    setAssignGlobalCategoryMode("assign");
    setOpenAssignGlobalCategoryToLocalOfficeDialog(true);
  };

  const handleOpenRemoveGlobalCategoryMirroringDialog = () => {
    setAssignGlobalCategoryMode("remove");
    setOpenAssignGlobalCategoryToLocalOfficeDialog(true);
  };

  const handleCloseGlobalCategoryMirroringDialog = () => {
    setOpenAssignGlobalCategoryToLocalOfficeDialog(false);
    setAssignGlobalCategoryMode("");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, openNotification: false });
  };

  return (
    <Container maxWidth="md">
      <Paper sx={{ p: 2, mb: 3, display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row", // Set to row to align items horizontally
            alignItems: "center", // Align items vertically in the center
            justifyContent: "space-between", // Add space between the elements
            width: "100%", // Take full width to spread items across
            p: 1, // Padding around the Box
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: 700, width: "50%" }}
            fontWeight={700}
          >
            Brokerage Categories Management
          </Typography>
          <CategoryManagementMenu
            handleOpenCreateCategoryDialog={handleOpenCreateCategoryDialog}
            handleOpenCreateCategoryGroupDialog={
              handleOpenCreateCategoryGroupDialog
            }
            handleOpenAssignGlobalCategoryMirroringDialog={
              handleOpenAssignGlobalCategoryMirroringDialog
            }
            handleOpenRemoveGlobalCategoryMirroringDialog={
              handleOpenRemoveGlobalCategoryMirroringDialog
            }
            tier={tier}
            isHasCategories={tier?.categories?.length > 0}
            isLBTab={isLBTab}
          />
        </Box>
        <CreateEditCategoryForm
          mode="create"
          isLBTab={isLBTab}
          selectedTier={tier}
          open={openCreateCategoryDialog}
          onClose={handleCloseCreateCategoryDialog}
        />
        <CreateEditCategoryGroupForm
          mode="create"
          selectedTier={tier}
          open={openCreateCategoryGroupDialog}
          onClose={handleCloseCreateCategoryGroupDialog}
        />
        <AssignAllOfficeCategoryToLocalOfficeDialog
          mode={assignAllOfficeCategoryMode}
          open={openAssignAllOfficeCategoryToLocalOfficeDialog}
          preselectedCategory={currentCategory}
          onClose={handleCloseAssignAllOfficeCategoryToLocalOfficeDialog}
        />
        <AssignGlobalCategoryToLocalOfficeDialog
          mode={assignGlobalCategoryMode}
          open={openAssignGlobalCategoryToLocalOfficeDialog}
          preselectedCategory={currentCategory}
          onClose={handleCloseGlobalCategoryMirroringDialog}
        />
        <NotificationSnackbar
          open={notification.openNotification}
          message={notification.message}
          onClose={handleClose}
        />
      </Paper>
      <CategoriesList
        selectedTier={tier}
        isLBTab={isLBTab}
        handleOpenAssignAllOfficeCategoryToLocalOfficeDialog={
          handleOpenAssignAllOfficeCategoryToLocalOfficeDialog
        }
        handleOpenRemoveAllOfficeCategoryToLocalOfficeDialog={
          handleOpenRemoveAllOfficeCategoryToLocalOfficeDialog
        }
      />
      <CategoryGroupsList selectedTier={tier} />
    </Container>
  );
};

export default CategoryManager;

import React, { useState } from "react";
import {
  useDeleteBrokerageMutation,
  useUpdateBrokerageStatusMutation,
} from "../../../services/brokerage";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Divider,
  Box,
  TableSortLabel,
  CircularProgress,
} from "@mui/material";
import { MoreVert, DeleteForever, Edit } from "@mui/icons-material/";
import { ConfirmationDialog } from "../..";
import { useNavigate } from "react-router-dom";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import BrokerageEditDrawer from "../../brokerage/brokerage-ecosytem/BrokerageEditDrawer";
import AddRemoveAssignUnassignToFromLeadBrokerageDialog from "./AddRemoveAssignUnassignToFromLeadBrokerageDialog";

function BrokeragesList({ brokerages, refetchBrokerages }) {
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [deleteBrokerage] = useDeleteBrokerageMutation();
  const [updateBrokerageStatus, { isUpdateBrokerageStatusLoading }] =
    useUpdateBrokerageStatusMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [currentBrokerage, setCurrentBrokerage] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [sortField, setSortField] = useState("officeName");
  const [sortDirection, setSortDirection] = useState("asc");
  const [action, setAction] = useState("");
  const [isBrokerageEditDrawerOpen, setIsBrokerageEditDrawerOpen] =
    useState(false);
  const [isLeadBrokerageDialogOpen, setIsLeadBrokerageDialogOpen] =
    useState(false);

  const handleRowClick = (event, brokerageItem) => {
    if (
      event.target.closest(".menu-button") ||
      event.target.closest(".view-details-button")
    ) {
      setCurrentBrokerage(brokerageItem);

      return;
    }
    navigate(`/brokerage-card/${brokerageItem?._id}`);
  };

  const handleMenuOpen = (event, brokerageItem) => {
    event.stopPropagation();
    setCurrentBrokerage(brokerageItem);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleBrokerageEditDrawerOpen = (event, brokerage) => {
    event.stopPropagation();
    setCurrentBrokerage(brokerage);
    setIsBrokerageEditDrawerOpen(true);
    setMenuAnchorEl(null);
  };

  const handleBrokerageEditDrawerClose = () => {
    setIsBrokerageEditDrawerOpen(false);
  };

  const handleDeleteBrokerageClick = (event, brokerageId) => {
    event.stopPropagation();
    setCurrentBrokerage({ ...currentBrokerage, _id: brokerageId });
    setConfirmationDialogOpen(true);
  };
  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleConfirmationDialogConfirm = async () => {
    if (currentBrokerage?._id) {
      const result = await deleteBrokerage({
        brokerageId: currentBrokerage._id,
      });

      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        refetchBrokerages?.();
        setConfirmationDialogOpen(false);
      } else {
        setNotification({
          open: true,
          message: `Error deleting Brokerage. ${result?.error?.data?.msg}`,
        });
      }
    }
  };

  const handleUpdateBrokerageStatus = async () => {
    if (!currentBrokerage) {
      setNotification({
        open: true,
        message: "The brokerage id is not provided",
      });
      return;
    }
    const newStatus =
      currentBrokerage?.status === "active" ? "pending" : "active";
    if (currentBrokerage?._id) {
      const result = await updateBrokerageStatus({
        brokerageId: currentBrokerage._id,
        status: newStatus,
      });

      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        refetchBrokerages?.();
        setMenuAnchorEl(null);
      } else {
        setNotification({
          open: true,
          message: `Error: ${result?.error?.data?.msg}`,
        });
      }
    }
  };

  const handleLeadBrokerageDialogOpen = (brokerage, actionType) => {
    setCurrentBrokerage(brokerage);
    setAction(actionType);
    setIsLeadBrokerageDialogOpen(true);
  };

  const handleLeadBrokerageDialogClose = () => {
    setIsLeadBrokerageDialogOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  if (!brokerages || brokerages?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 20,
        }}
      >
        <Typography variant="h5" gutterBottom>
          No brokerages available
        </Typography>
        <Link to="/brokerage-management">Go to Create Brokerage</Link>
      </Box>
    );
  }

  return (
    <>
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "var(--table-header-bg)" }}>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "officeName"}
                    direction={sortDirection}
                    onClick={() => handleSort("officeName")}
                  >
                    Office Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "status"}
                    direction={sortDirection}
                    onClick={() => handleSort("status")}
                  >
                    Status
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "officeName"}
                    direction={sortDirection}
                    onClick={() => handleSort("officeName")}
                  >
                    Office Short Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "realEstateBoard.rebName"}
                    direction={sortDirection}
                    onClick={() => handleSort("realEstateBoard.rebName")}
                  >
                    Real Estate Board
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "leadBrokerage"}
                    direction={sortDirection}
                    onClick={() => handleSort("leadBrokerage")}
                  >
                    Lead Brokerage
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "tier.level"}
                    direction={sortDirection}
                    onClick={() => handleSort("tier.level")}
                  >
                    Tier Level
                  </TableSortLabel>
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(brokerages) &&
                brokerages?.map((brokerage) => (
                  <TableRow
                    key={brokerage?._id}
                    onClick={(event) => handleRowClick(event, brokerage)}
                    style={{ cursor: "pointer" }}
                    className="table-row"
                  >
                    <TableCell>{brokerage?.officeName || ""}</TableCell>
                    <TableCell>{brokerage?.status || ""}</TableCell>
                    <TableCell>{brokerage?.officeShortName || ""}</TableCell>
                    <TableCell>{brokerage?.realEstateBoard?.rebName}</TableCell>
                    <TableCell>
                      {`${
                        brokerage && brokerage?.leadBrokerage
                          ? brokerage?.leadBrokerage?.officeName
                          : brokerage &&
                            brokerage?.isLeadBrokerage &&
                            brokerage?.leadBrokerageTier
                          ? "is lead brokerage itself"
                          : ""
                      } `}
                    </TableCell>
                    <TableCell>{brokerage?.tier?.level}</TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          aria-label="delete"
                          onClick={(event) =>
                            handleDeleteBrokerageClick(event, brokerage?._id)
                          }
                        >
                          <DeleteForever />
                        </IconButton>
                        <IconButton
                          className="menu-button"
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(event) => handleMenuOpen(event, brokerage)}
                        >
                          <MoreVert />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          anchorEl={menuAnchorEl}
                          open={Boolean(menuAnchorEl)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem
                            onClick={(event) =>
                              handleBrokerageEditDrawerOpen(
                                event,
                                currentBrokerage
                              )
                            }
                          >
                            <Edit />
                            <Typography ml={1}>Edit Brokerage</Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={(event) => {
                              event.stopPropagation();
                              handleLeadBrokerageDialogOpen(
                                brokerage,
                                "addToLeadBrokerage"
                              );
                            }}
                          >
                            <Typography ml={1}>
                              Add To Lead Brokerage
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={(event) => {
                              event.stopPropagation();
                              handleLeadBrokerageDialogOpen(
                                brokerage,
                                "removeFromLeadBrokerage"
                              );
                            }}
                          >
                            <Typography ml={1}>
                              Remove From Lead Brokerage
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={(event) => {
                              event.stopPropagation();
                              handleUpdateBrokerageStatus();
                            }}
                          >
                            <Typography ml={1}>
                              {isUpdateBrokerageStatusLoading ? (
                                <CircularProgress size={24} />
                              ) : (
                                `Update Brokerage Status to: ${
                                  currentBrokerage?.status === "active"
                                    ? "pending"
                                    : currentBrokerage?.status === "pending"
                                    ? "active"
                                    : "no status"
                                }`
                              )}
                            </Typography>
                          </MenuItem>
                          <MenuItem>
                            <Typography
                              variant="body1"
                              className="view-details-button"
                              onClick={() =>
                                navigate(
                                  `/brokerage-card/${currentBrokerage?._id}`
                                )
                              }
                            >
                              View Details
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider style={{ margin: "20px 0" }} />
      </div>
      <BrokerageEditDrawer
        brokerage={currentBrokerage}
        refetchBrokerages={refetchBrokerages}
        open={isBrokerageEditDrawerOpen}
        onClose={handleBrokerageEditDrawerClose}
        mode="edit"
      />
      <AddRemoveAssignUnassignToFromLeadBrokerageDialog
        open={isLeadBrokerageDialogOpen}
        onClose={handleLeadBrokerageDialogClose}
        action={action}
      />
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmationDialogConfirm}
        title="Confirm Deleting the Brokerage"
        description="Are you sure you want to delete this Brokerage permanently?"
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
}

export default BrokeragesList;

import React, { useState } from "react";
import { Container, Typography, Paper, Divider } from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import PostManagementTabs from "./PostManagementTabs";

const PostManagement = () => {
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Container className="management-page">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Post's Management
        </Typography>
        <Divider />
        <PostManagementTabs />
        <NotificationSnackbar
          open={notification.open}
          message={notification.message}
          onClose={handleClose}
        />
      </Paper>
    </Container>
  );
};

export default PostManagement;

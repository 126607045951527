import React, { useState } from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControl,
  CircularProgress,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import NotificationSnackbar from "../../../helpers/notification-snackbar";

function DeleteAllDutyByDutyScheduleDialog({
  open,
  onClose,
  onConfirm,
  isLoading,
}) {
  const [deleteAll, setDeleteAll] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleSubmit = () => {
    onConfirm({ deleteAll });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ backgroundColor: "#1976d2", color: "white" }}>
        Clear Schedule
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ marginTop: "1rem" }}>
              What would you like to clear?
            </Typography>
            <FormControl component="fieldset" style={{ marginTop: "15px" }}>
              <RadioGroup
                row
                value={deleteAll ? "yes" : "no"}
                onChange={(e) => setDeleteAll(e.target.value === "yes")}
                required
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="DELETE ALL"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="Clear Remaining Schedule"
                />
              </RadioGroup>
            </FormControl>
            {deleteAll && (
              <Typography
                component="div"
                style={{ color: "red", marginTop: "15px" }}
                sx={{ wordWrap: "break-word" }}
              >
                <span style={{ fontWeight: "bold" }}>Delete ALL</span> will
                clear the schedule including ALL entries prior to today's date.
              </Typography>
            )}
            {!deleteAll && (
              <Typography
                component="div"
                style={{ color: "orange", marginTop: "15px" }}
                sx={{ wordWrap: "break-word" }}
              >
                <span style={{ fontWeight: "bold" }}>Clear Remaining Schedule</span> will
                delete all entries from today onwards, including future months.
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : (deleteAll ? "Delete ALL Entries" : "Delete Entries")}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
}

export default DeleteAllDutyByDutyScheduleDialog;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const redirectApi = createApi({
  reducerPath: "redirect",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/R/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Redirect"],
  endpoints: (builder) => ({
    redirectShortToLong: builder.query({
      query: (code) => `/${code}`,
    }),
  }),
});

export const { useRedirectShortToLongQuery } = redirectApi;

export default redirectApi;

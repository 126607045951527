import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useMakeStripePaymentMutation } from "../../../../services/billing/payment";

const CreditCardPaymentDialog = ({ open, onClose, paymentData, brokerageId, onSuccess }) => {
  const [makeStripePayment] = useMakeStripePaymentMutation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(""); // New state for payment status

  const handleStripePayment = async () => {
    setLoading(true);
    setError("");
    setPaymentStatus(""); // Reset payment status
    console.log("pay", paymentData);
    try {
      if (!paymentData.paymentMethod) {
        throw new Error("Payment method is required.");
      }
      console.log(paymentData);
      // Call your API to create a payment intent and confirm the payment
      const response = await makeStripePayment({
        brokerageId: brokerageId,
        amount: paymentData.amount,
        currency: paymentData.currency || "cad",
        paymentMethod: paymentData.paymentMethodId, // Ensure this is included
      }).unwrap();

      if (response.success) {
        setPaymentDetails(response.paymentDetails);
        setPaymentStatus("success");
        onSuccess(paymentData, response.paymentDetails); // Pass the client secret from the response
      } else {
        // Handle API-side validation errors
        setPaymentStatus("failed");
        setError(response.message || "An unknown error occurred.");
      }
    } catch (err) {
      // Debugging: Log the error
      console.error("Payment Error:", err);

      // Handle network or unexpected errors
      setPaymentStatus("failed");
      setError(`Error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    resetPaymentState(); // Reset state when closing the dialog

    setPaymentDetails(null);
    setPaymentStatus("");
  };

  // Reset payment state when dialog opens or paymentData changes
  useEffect(() => {
    if (open) {
      resetPaymentState();
    }
  }, [open, paymentData]);

  const resetPaymentState = () => {
    setPaymentDetails(null);
    setPaymentStatus("");
    setError("");
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Processing Payment</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : paymentStatus === "success" ? (
          <>
            <Typography variant="body1">Payment succeeded!</Typography>
            <Typography variant="body2">
              Payment Intent ID: {paymentDetails?.paymentIntentId}
            </Typography>
            <Typography variant="body2">
              Amount: {paymentDetails?.amount / 100}{" "}
              {paymentDetails?.currency.toUpperCase()}
            </Typography>
            <Typography variant="body2">
              Payment Method ID: {paymentDetails?.paymentMethodId}
            </Typography>
            <Typography variant="body2">
              Client Secret: {paymentDetails?.clientSecret}
            </Typography>
          </>
        ) : paymentStatus === "failed" ? (
          <Typography color="error">
            {error || "Payment failed. Please try again."}
          </Typography>
        ) : (
          <Typography variant="body1">
            Please wait while we process your payment...
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {paymentStatus === "" && (
          <Button onClick={handleStripePayment} disabled={loading}>
            Confirm Payment
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CreditCardPaymentDialog;

import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useConfirmSwapMutation } from "../../../../services/duty-scheduling";
import { CircularProgress, Typography, Box, Button } from "@mui/material";

const ConfirmSwapPage = () => {
  const { sourceDutyId, targetDutyId } = useParams();
  const [confirmSwap] = useConfirmSwapMutation();
  const [status, setStatus] = useState("idle");
  const navigate = useNavigate();

  const handleConfirmSwap = async () => {
    setStatus("loading");
    try {
      await confirmSwap({ sourceDutyId, targetDutyId }).unwrap();
      setStatus("success");
      setTimeout(() => navigate("/home"), 2000);
    } catch (error) {
      setStatus("failure");
      console.error("Swap confirmation failed:", error);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      {status === "loading" ? (
        <CircularProgress />
      ) : status === "idle" ? (
        <Box display="flex" gap={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmSwap}
          >
            Confirm Swap
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate("/home")}
          >
            Cancel
          </Button>
        </Box>
      ) : status === "success" ? (
        <Typography variant="h5">
          The swap has been confirmed successfully.
        </Typography>
      ) : (
        <Typography variant="h5" color="error">
          The swap confirmation failed. Please try again or contact support.
        </Typography>
      )}
    </Box>
  );
};

export default ConfirmSwapPage;

import React, { useState, useEffect, useContext, memo } from "react";
import {
  Tooltip,
  List,
  IconButton,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { Menu } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { AuthContext } from "../../context/AuthContext";
import { TierContext } from "../../context/TierContext";
import { SidebarContext } from "../../context/SidebarContext";
import { useGetCategoriesByUserRoleQuery } from "../../services/category";
import SidebarAdminItem from "./SidebarAdminItem";
import TierItem from "./TierItem";
import CategoryItem from "./CategoryItem";
import GroupItem from "./GroupItem";
import sidebarCategories from "./SidebarManagementCategories";

function Sidebar() {
  const theme = useTheme();
  const isSmallestDevice = useMediaQuery(theme.breakpoints.up("xs"));
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const { sidebarOpen, setSidebarOpen } = useContext(SidebarContext);
  const {
    selectTierId,
    currentTierId,
    openTiers,
    openCategoryTree,
    toggleTierOpenState,
    toggleGroupOpenState,
  } = useContext(TierContext);
  const { isTokenReady, accountType, teamUrlName, userId, brokerageTierId } =
    useContext(AuthContext);
  const location = useLocation();
  const [activePath, setActivePath] = useState(location.pathname);
  const { data: categoriesByRole, refetch: refetchCategoriesByRole } =
    useGetCategoriesByUserRoleQuery(userId, {
      skip: !isTokenReady,
    });

  useEffect(() => {
    if (
      accountType !== "reosadmin" &&
      categoriesByRole &&
      !currentTierId &&
      isTokenReady
    ) {
      let defaultTier;
      if (brokerageTierId) {
        defaultTier = categoriesByRole.find(
          (tier) => tier.tierId === brokerageTierId
        );
      }

      if (!defaultTier) {
        defaultTier =
          categoriesByRole.find(
            (tier) => tier.tierName !== "system" && tier.tierName !== "global"
          ) || categoriesByRole[0];
      }

      if (defaultTier) {
        let homeCategory = Object.values(defaultTier.groups || {})
          .flatMap((group) => group)
          .find((category) => category.path === "home");

        if (!homeCategory) {
          homeCategory = Object.values(defaultTier.groups || {})
            .flatMap((group) => group)
            .find((category) => category.type === "announcements");
        }

        if (!homeCategory) {
          homeCategory = Object.values(defaultTier.groups || {})
            .flatMap((group) => group)
            .find((category) => category.type.includes("common"));
        }

        if (homeCategory) {
          selectTierId(homeCategory.tier._id);
        }
      }
    }
  }, [
    categoriesByRole,
    selectTierId,
    accountType,
    currentTierId,
    isTokenReady,
    brokerageTierId,
  ]);

  useEffect(() => {
    if (isTokenReady) {
      refetchCategoriesByRole();
    }
  }, [isTokenReady, refetchCategoriesByRole]);

  useEffect(() => {
    if (isSmallDevice) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
  }, [isSmallDevice, setSidebarOpen]);

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location.pathname]);

  const handleDrawerToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleItemClick = (path, category) => {
    setActivePath(path);
    if (isSmallDevice) {
      setSidebarOpen(false);
    }
  };

  const renderSidebarAdminItems = (items) => {
    if (items && items?.length > 0) {
      return items?.map((item, index) => (
        <div key={index}>
          <SidebarAdminItem
            item={item}
            activePath={activePath}
            handleItemClick={handleItemClick}
            isSmallDevice={isSmallDevice}
            isSmallestDevice={isSmallestDevice}
            sidebarOpen={sidebarOpen}
          />
        </div>
      ));
    }
  };

  const handleCategoryGroupClick = (tierId, groupName) => {
    toggleGroupOpenState(tierId, groupName);
  };

  const handleTierClick = (tierId) => {
    toggleTierOpenState(tierId);
  };

  const renderCategories = (category, handleItemClick) => {
    const isActive =
      (category?.path === "home" && activePath === "/home") ||
      (category?.path === "members" && activePath === "/members") ||
      (category?.path === "file-share" && activePath === "/files") ||
      activePath === `/category/${category?.path}/${category?._id}`;

    return (
      <CategoryItem
        key={category?._id}
        category={category}
        handleItemClick={handleItemClick}
        isActive={isActive}
        isSmallDevice={isSmallDevice}
        isSmallestDevice={isSmallestDevice}
        sidebarOpen={sidebarOpen}
      />
    );
  };

  const renderCategoryGroups = (
    tierId,
    groupName,
    categories,
    handleItemClick
  ) => {
    return (
      <GroupItem
        key={`${tierId}-${groupName}`}
        sidebarOpen={sidebarOpen}
        openCategoryTree={openCategoryTree}
        tierId={tierId}
        groupName={groupName}
        categories={categories}
        handleItemClick={handleItemClick}
        isOpen={openCategoryTree[tierId]?.groups[groupName]}
        handleCategoryGroupClick={handleCategoryGroupClick}
        renderCategories={renderCategories}
      />
    );
  };

  const renderSidebarCategories = (tier, handleItemClick) => {
    if (!tier) {
      return (
        <div className="loading-spinner">
          <CircularProgress size={24} />
        </div>
      );
    }

    return (
      <TierItem
        key={`${tier?.tierId}-${tier?.tierName}`}
        sidebarOpen={sidebarOpen}
        openTiers={openTiers}
        tier={tier}
        handleItemClick={handleItemClick}
        handleTierClick={handleTierClick}
        renderCategoryGroups={renderCategoryGroups}
      />
    );
  };

  const selectedTier = categoriesByRole?.find(
    (tier) => tier?.tierId === currentTierId
  );

  const sidebarClass = `sidebar ${
    (!isSmallDevice && !sidebarOpen) || (isSmallDevice && !sidebarOpen)
      ? "sidebar-closed"
      : ""
  }`;

  return (
    <div className={sidebarClass}>
      <Tooltip
        title={sidebarOpen ? "Close Sidebar" : "Open Sidebar"}
        placement="right"
      >
        <IconButton
          className="menu-button sidebar-menu-button"
          onClick={handleDrawerToggle}
        >
          <Menu />
        </IconButton>
      </Tooltip>
      <List className="sidebar-list-body">
        {accountType === "reosadmin" &&
          renderSidebarAdminItems(sidebarCategories.admin)}
        {accountType === "brokerage" &&
          renderSidebarAdminItems(sidebarCategories.brokerage)}
        {accountType !== "brokerage" &&
          accountType !== "reosadmin" &&
          renderSidebarAdminItems(sidebarCategories?.main)}
        {accountType === "member" &&
          teamUrlName &&
          renderSidebarAdminItems(sidebarCategories.team)}
        {categoriesByRole &&
          renderSidebarCategories(selectedTier, handleItemClick)}
        {accountType !== "reosadmin" && !selectedTier && (
          <div className="loading-spinner">
            <CircularProgress size={24} />
          </div>
        )}
      </List>
    </div>
  );
}

export default memo(Sidebar);

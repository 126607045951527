import React, { useState, useContext, useMemo } from "react";
import {
  useDeleteEventTypeMutation,
  useGetEventTypesQuery,
} from "../../../../services/event-type";
import { AuthContext } from "../../../../context/AuthContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Divider,
  Box,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import { MoreVert, DeleteForever, Edit, Add } from "@mui/icons-material/";
import { ConfirmationDialog } from "../../..";
import NotificationSnackbar from "../../../helpers/notification-snackbar";
import CreateEditEventTypeDialog from "./CreateEditEventTypeDialog";

function EventTypesList() {
  const [deleteEventType] = useDeleteEventTypeMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { accountType, userId } = useContext(AuthContext);
  const { data: eventTypes, refetch } = useGetEventTypesQuery(
    { accountType, userId },
    {
      skip: !userId || accountType !== "reosadmin",
    }
  );
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [currentEventType, setCurrentEventType] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [sortField, setSortField] = useState("name");
  const [sortDirection, setSortDirection] = useState("asc");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState("");

  const handleRowClick = (event, eventTypeItem) => {
    if (
      event.target.closest(".menu-button") ||
      event.target.closest(".view-details-button")
    ) {
      setCurrentEventType(eventTypeItem);
      return;
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenCreateDialog = () => {
    setDialogMode("create");
    setDialogOpen(true);
  };

  const handleOpenEditDialog = (event) => {
    event.stopPropagation();
    setDialogMode("edit");
    setDialogOpen(true);
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setDialogMode("");
    setDialogOpen(false);
  };

  const handleDeleteEventTypeClick = (event, eventTypeId) => {
    event.stopPropagation();
    setCurrentEventType({ ...currentEventType, _id: eventTypeId });
    setConfirmationDialogOpen(true);
  };
  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleConfirmationDialogConfirm = async () => {
    if (currentEventType?._id) {
      const result = await deleteEventType({
        eventTypeId: currentEventType._id,
      });

      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        refetch?.();
        setConfirmationDialogOpen(false);
      } else {
        setNotification({
          open: true,
          message: `Error: ${result?.error?.data?.msg}`,
        });
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleSort = (field) => {
    setSortDirection((prev) =>
      field === sortField && prev === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  const sortedEventTypes = useMemo(() => {
    if (!Array.isArray(eventTypes)) return [];
    return [...eventTypes].sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];
      if (sortDirection === "asc") {
        return aValue < bValue ? -1 : 1;
      }
      return aValue > bValue ? -1 : 1;
    });
  }, [eventTypes, sortField, sortDirection]);

  return (
    <>
      <div>
        <Box sx={{ p: 2 }}>
          <Tooltip title="Create new Event Type">
            <IconButton onClick={handleOpenCreateDialog}>
              <Add />
              <Typography ml={1}>Create new Event Type</Typography>
            </IconButton>
          </Tooltip>
        </Box>
        {(!eventTypes || eventTypes?.length === 0) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              pt: 20,
            }}
          >
            <Typography variant="h5" gutterBottom>
              No Event Types available
            </Typography>
          </Box>
        )}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortField === "name"}
                    direction={sortDirection}
                    onClick={() => handleSort("name")}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedEventTypes?.map((eventType) => (
                <TableRow
                  key={eventType?._id}
                  onClick={(event) => handleRowClick(event, eventType)}
                  style={{ cursor: "pointer" }}
                  className="table-row"
                >
                  <TableCell>{eventType?.name}</TableCell>
                  <TableCell>{eventType?.description}</TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        aria-label="delete"
                        onClick={(event) =>
                          handleDeleteEventTypeClick(event, eventType?._id)
                        }
                      >
                        <DeleteForever />
                      </IconButton>
                      <IconButton
                        className="menu-button"
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(event) =>
                          handleMenuOpen(event, eventType?._id)
                        }
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                      >
                        <MenuItem
                          onClick={(event) => {
                            event.stopPropagation();
                            handleOpenEditDialog(event);
                          }}
                        >
                          <Edit />
                          <Typography ml={1}>Edit Event Type</Typography>
                        </MenuItem>
                      </Menu>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider style={{ margin: "20px 0" }} />
      </div>
      <CreateEditEventTypeDialog
        dialogMode={dialogMode}
        eventType={currentEventType}
        open={dialogOpen}
        onClose={handleCloseDialog}
      />
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmationDialogConfirm}
        title="Confirm Deleting the Event Type"
        description="Are you sure you want to delete this Event Type permanently?"
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
}

export default EventTypesList;

import React from "react";
import { Link } from "react-router-dom";

function NotificationMessage({ message, onLinkClick }) {
  // Updated patterns to include 'member @' and 'post @' in the capturing groups
  const userPattern = /(member @)(.*?)([0-9a-fA-F]{24})/;
  const postPattern = /(post @)([0-9a-fA-F]{24})/;

  const parts = message?.split(
    /(member @.* [0-9a-fA-F]{24}|post @[0-9a-fA-F]{24})/
  );

  return (
    <span>
      {parts?.map((part, index) => {
        if (userPattern?.test(part)) {
          const [, memberText, name, userId] = part?.match(userPattern);
          
          const adjustedPrefix = memberText.replace("member ", "");

          return (
            <React.Fragment key={index}>
              {adjustedPrefix}
              <Link
                to={`/member-profile/${userId}`}
                className="notification-link"
                onClick={onLinkClick}
              >
                {name}
              </Link>
            </React.Fragment>
          );
        } else if (postPattern?.test(part)) {
          const [, prefix, postId] = part.match(postPattern);

          // Remove the word "post " from the prefix
          const adjustedPrefix = prefix.replace("post ", "");
          return (
            <React.Fragment key={index}>
              {adjustedPrefix}
              <Link
                to={`/post/${postId}`}
                className="notification-link"
                onClick={onLinkClick}
              >
                {"Post"}
              </Link>
            </React.Fragment>
          );
        } else {
          return <React.Fragment key={index}>{part}</React.Fragment>;
        }
      })}
    </span>
  );
}

export default NotificationMessage;

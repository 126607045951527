import React, { useState, useEffect, memo } from "react";
import parse from "html-react-parser";
import { Tooltip, Button } from "@mui/material";
import { sliceMiddleContent } from "../helpers/utils";
import { replaceVimeoLinks } from "../helpers/VimeoUtils";
import { replaceGoogleSheetsLinks } from "../helpers/GoogleSheetsUtils";

function PostContent({
  content,
  isExpanded = false,
  onExpandClick,
  isPostPage = false,
  isAnnouncementWidget = false,
  isLegallySpeakingWidget = false,
  isListingWidget = false,
  isMarketingWidget = false,
  isBusinessWidget = false,
  isStrataWidget = false,
  showOpenButton = true,
  announcementId,
  postId,
  listingId,
  onSelect,
}) {
  const [expanded, setExpanded] = useState(isExpanded);

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  const isWidget =
    isAnnouncementWidget ||
    isLegallySpeakingWidget ||
    isMarketingWidget ||
    isBusinessWidget ||
    isStrataWidget;

  const previewContentLength = isAnnouncementWidget
    ? 200
    : isLegallySpeakingWidget
    ? 150
    : 200;

  const shouldShowButton = content?.length > previewContentLength;

  const handleExpandClick = (event) => {
    setExpanded(!expanded);
    if (isAnnouncementWidget) {
      onSelect(announcementId);
    } else if (isListingWidget) {
      onSelect(listingId);
    } else if (isLegallySpeakingWidget) {
      onSelect(postId);
    } else {
      onExpandClick(event);
    }
  };

  const renderContent = () => {
    let displayContent = content;

    if (
      !expanded &&
      !isPostPage &&
      !isListingWidget &&
      isWidget &&
      content?.length > previewContentLength
    ) {
      displayContent = sliceMiddleContent(content, previewContentLength);
    }

    // Replace Vimeo links with VimeoPlayer components
    let replacedContent = replaceVimeoLinks(displayContent);

    // Replace Google Sheets links with iframe components and get the flag
    const { parts: googleSheetsParts, hasGoogleSheetsLink } =
      replaceGoogleSheetsLinks(replacedContent);

    if (hasGoogleSheetsLink) {
      replacedContent = googleSheetsParts;
    } else if (
      !expanded &&
      !isPostPage &&
      !isListingWidget &&
      content?.length > previewContentLength
    ) {
      displayContent = sliceMiddleContent(content, previewContentLength);
      replacedContent = replaceVimeoLinks(displayContent);
      const { parts: googleSheetsParts } =
        replaceGoogleSheetsLinks(replacedContent);
      replacedContent = googleSheetsParts;
    }

    return (
      <div>
        {replacedContent.map((part, index) => (
          <React.Fragment key={index}>
            {typeof part === "string" ? parse(part) : part}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <div
      className={`post-content ${isWidget ? "widget-mode" : ""}`}
      style={{ padding: 0, margin: 0 }}
    >
      <Tooltip
        sx={{ padding: 0, margin: 0 }}
        title=""
        className={`post-content-tooltip ${isWidget ? "widget-mode-mini" : ""}`}
      >
        {renderContent()}
      </Tooltip>

      {showOpenButton && shouldShowButton && !expanded && !isPostPage && (
        <div style={{ width: "100%" }} className="widget-mode">
          <Button
            sx={{ marginTop: "10px" }}
            className="widget-mode"
            variant="outlined"
            color="success"
            onClick={(event) => handleExpandClick(event)}
          >
            {isWidget ? "Open..." : "Expand..."}
          </Button>
        </div>
      )}

      {expanded && (
        <div style={{ width: "100%" }} className="widget-mode">
          <Button
            sx={{ marginTop: "10px" }}
            className="widget-mode"
            color="success"
            variant="outlined"
            onClick={() => setExpanded(false)}
          >
            Collapse...
          </Button>
        </div>
      )}
    </div>
  );
}

export default memo(PostContent);

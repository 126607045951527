import React from "react";
import { useGetMemberCommentsQuery } from "../../../services/member";
import { Grid, Typography, CircularProgress } from "@mui/material";
import CommentCard from "../../posts/CommentCard";

const MemberCommentsTab = ({ member, tierId }) => {
  const memberId = member?.user || null;

  const {
    data: memberComments,
    isLoading,
    error,
    isError,
    refetch,
  } = useGetMemberCommentsQuery(
    { memberId, tierId },
    {
      skip: !memberId,
    }
  );

  if (isLoading)
    return (
      <div className="loading-spinner">
        <CircularProgress size={24} />
      </div>
    );

  if (!memberComments)
    return (
      <Typography variant="h6" m="2rem 0.1rem 0.5rem 5rem">
        There are no comments yet
      </Typography>
    );

  if (error) return <p>Error loading posts</p>;

  return (
    <div className="member-comments-tab">
      <Grid container className="member-comments-tab-body">
        {memberComments &&
          memberComments?.length > 0 &&
          memberComments?.map((comment) => (
            <CommentCard
              key={comment?._id}
              comment={comment}
              post={comment?.post}
              refetchPosts={refetch}
              isPostsError={isError}
              isPostsLoading={isLoading}
            />
          ))}
      </Grid>
    </div>
  );
};

export default MemberCommentsTab;

import React, { useState, memo, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Divider,
  Box,
  CircularProgress,
} from "@mui/material";
import {
  useGetAllCategorySettingsQuery,
  useDeleteCategorySettingsMutation,
} from "../../../services/category-settings";
import { MoreVert, DeleteForever, Edit } from "@mui/icons-material/";
import { ConfirmationDialog } from "../..";
import { useNavigate } from "react-router-dom";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import CreateEditCategorySettingsForm from "./CreateEditCategorySettingsForm";

function CategorySettingsList() {
  const { userId } = useContext(AuthContext);
  const navigate = useNavigate();
  const [deleteCategorySettings] = useDeleteCategorySettingsMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {
    data: categorySettings,
    isLoading,
    refetch,
  } = useGetAllCategorySettingsQuery({
    skip: !userId,
  });
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [currentCategorySettingsClicked, setCurrentCategorySettingsClicked] =
    useState(null);
  const [currentCategorySettings, setCurrentCategorySettings] = useState(null);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [openEditCategorySettingsDialog, setOpenEditCategorySettingsDialog] =
    useState(false);

  const handleRowClick = (
    event,
    categorySettingsItemId,
    categorySettingsItem
  ) => {
    if (
      event.target.closest(".menu-button") ||
      event.target.closest(".view-details-button")
    ) {
      setCurrentCategorySettingsClicked(categorySettingsItemId);
      setCurrentCategorySettings(categorySettingsItem);
      return;
    }
    navigate(`/category-settings/${categorySettingsItemId}`);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenEditCategorySettingsDialog = (event) => {
    event.stopPropagation();
    setOpenEditCategorySettingsDialog(true);
    setAnchorEl(null);
  };

  const handleCloseEditCategorySettingsDialog = () => {
    setOpenEditCategorySettingsDialog(false);
  };

  const handleDeleteCategorySettingsClick = (event, categorySettingsId) => {
    event.stopPropagation();
    setCurrentCategorySettings({
      ...currentCategorySettings,
      _id: categorySettingsId,
    });
    setConfirmationDialogOpen(true);
  };
  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleConfirmationDialogConfirm = async () => {
    if (currentCategorySettings?._id) {
      const result = await deleteCategorySettings({
        categorySettingsId: currentCategorySettings._id,
      });

      if (result) {
        setNotification({
          open: true,
          message: `${result?.data?.msg}`,
        });
        refetch();
        setConfirmationDialogOpen(false);
      } else {
        setNotification({
          open: true,
          message: `Error deleting Category Settings. ${result?.error?.data?.msg}`,
        });
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  if (isLoading) {
    return (
      <CircularProgress style={{ display: "block", margin: "20px auto" }} />
    );
  }

  if (!categorySettings || categorySettings.length === 0) {
    return (
      <Typography
        variant="h6"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        No Category Settings available
      </Typography>
    );
  }

  return (
    <>
      <Box>
        <Typography
          variant="h6"
          style={{ textAlign: "center", marginBottom: "1rem" }}
        >
          Category Settings List
        </Typography>
        <Divider
          variant="fullWidth"
          style={{ textAlign: "center", marginBottom: "1rem" }}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table className="user-table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "var(--header-bg)" }}>
              <TableCell sx={{ fontWeight: 800 }}>Category Type</TableCell>
              <TableCell>isREOSOnly</TableCell>
              <TableCell>isWidgetable</TableCell>
              <TableCell>isEvent</TableCell>
              <TableCell>isMemberList</TableCell>
              <TableCell>isHomePage</TableCell>
              <TableCell>isChat</TableCell>
              <TableCell>isFileSystem</TableCell>
              <TableCell>isListings</TableCell>
              <TableCell>allowTags</TableCell>
              <TableCell>useWYSIWYG</TableCell>
              <TableCell>hasFeaturePicture</TableCell>
              <TableCell>allowAcknowledgements</TableCell>
              <TableCell>allowAttachments</TableCell>
              <TableCell>allowComments</TableCell>
              <TableCell>allowLikes</TableCell>
              <TableCell>Actions </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(categorySettings) &&
              categorySettings?.map((catSettingsItem, index) => (
                <TableRow
                  key={catSettingsItem?._id}
                  onClick={(event) =>
                    handleRowClick(event, catSettingsItem?._id, catSettingsItem)
                  }
                  style={{ cursor: "pointer" }}
                  className="table-row"
                >
                  <TableCell>{catSettingsItem?.categoryType}</TableCell>
                  <TableCell>
                    {catSettingsItem?.isREOSOnly?.is
                      ? "Yes"
                      : catSettingsItem?.isREOSOnly?.isNot
                      ? "No"
                      : "Optional"}
                  </TableCell>
                  <TableCell>
                    {catSettingsItem?.isWidgetable?.is
                      ? "Yes"
                      : catSettingsItem?.isWidgetable?.isNot
                      ? "No"
                      : "Optional"}
                  </TableCell>
                  <TableCell>
                    {catSettingsItem?.isEvent?.is
                      ? "Yes"
                      : catSettingsItem?.isEvent?.isNot
                      ? "No"
                      : "Optional"}
                  </TableCell>
                  <TableCell>
                    {catSettingsItem?.isMemberList?.is
                      ? "Yes"
                      : catSettingsItem?.isMemberList?.isNot
                      ? "No"
                      : "Optional"}
                  </TableCell>
                  <TableCell>
                    {catSettingsItem?.isHomePage?.is
                      ? "Yes"
                      : catSettingsItem?.isHomePage?.isNot
                      ? "No"
                      : "Optional"}
                  </TableCell>
                  <TableCell>
                    {catSettingsItem?.isChat?.is
                      ? "Yes"
                      : catSettingsItem?.isChat?.isNot
                      ? "No"
                      : "Optional"}
                  </TableCell>
                  <TableCell>
                    {catSettingsItem?.isFileSystem?.is
                      ? "Yes"
                      : catSettingsItem?.isFileSystem?.isNot
                      ? "No"
                      : "Optional"}
                  </TableCell>
                  <TableCell>
                    {catSettingsItem?.isListings?.is
                      ? "Yes"
                      : catSettingsItem?.isListings?.isNot
                      ? "No"
                      : "Optional"}
                  </TableCell>
                  <TableCell>
                    {catSettingsItem?.allowTags?.is
                      ? "Yes"
                      : catSettingsItem?.allowTags?.isNot
                      ? "No"
                      : "Optional"}
                  </TableCell>
                  <TableCell>
                    {catSettingsItem?.useWYSIWYG?.is
                      ? "Yes"
                      : catSettingsItem?.useWYSIWYG?.isNot
                      ? "No"
                      : "Optional"}
                  </TableCell>
                  <TableCell>
                    {catSettingsItem?.hasFeaturePicture?.is
                      ? "Yes"
                      : catSettingsItem?.hasFeaturePicture?.isNot
                      ? "No"
                      : "Optional"}
                  </TableCell>
                  <TableCell>
                    {catSettingsItem?.allowAcknowledgements?.is
                      ? "Yes"
                      : catSettingsItem?.allowAcknowledgements?.isNot
                      ? "No"
                      : "Optional"}
                  </TableCell>
                  <TableCell>
                    {catSettingsItem?.allowAttachments?.is
                      ? "Yes"
                      : catSettingsItem?.allowAttachments?.isNot
                      ? "No"
                      : "Optional"}
                  </TableCell>
                  <TableCell>
                    {catSettingsItem?.allowComments?.is
                      ? "Yes"
                      : catSettingsItem?.allowComments?.isNot
                      ? "No"
                      : "Optional"}
                  </TableCell>
                  <TableCell>
                    {catSettingsItem?.allowLikes?.is
                      ? "Yes"
                      : catSettingsItem?.allowLikes?.isNot
                      ? "No"
                      : "Optional"}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        aria-label="delete"
                        onClick={(event) =>
                          handleDeleteCategorySettingsClick(
                            event,
                            catSettingsItem?._id
                          )
                        }
                      >
                        <DeleteForever />
                      </IconButton>
                      <IconButton
                        className="menu-button"
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(event) =>
                          handleMenuOpen(event, catSettingsItem?._id)
                        }
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                      >
                        <MenuItem
                          onClick={(event) => {
                            event.stopPropagation();
                            handleOpenEditCategorySettingsDialog(event);
                          }}
                        >
                          <Edit />
                          <Typography ml={1}>Edit Category Settings</Typography>
                        </MenuItem>
                        <MenuItem>
                          <Typography
                            variant="body1"
                            className="view-details-button"
                            onClick={() =>
                              navigate(
                                `/category-settings/${currentCategorySettingsClicked}`
                              )
                            }
                          >
                            View Details
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider style={{ margin: "20px 0" }} />
      <CreateEditCategorySettingsForm
        mode="edit"
        categorySettings={currentCategorySettings}
        open={openEditCategorySettingsDialog}
        onClose={handleCloseEditCategorySettingsDialog}
      />
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        onConfirm={handleConfirmationDialogConfirm}
        title="Confirm Deleting the Category Settings"
        description="Are you sure you want to delete this Category Settings permanently?"
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </>
  );
}

export default memo(CategorySettingsList);

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const categoryGroupsApi = createApi({
  reducerPath: "category-group",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/category-groups/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagGroups: ["CategoryGroup"],
  endpoints: (builder) => ({
    createCategoryGroup: builder.mutation({
      query: ({
        tierId,
        categoryGroupName,
        categoryGroupPath,
        categoryGroupIcon,
      }) => ({
        url: `create-category-group/`,
        method: "POST",
        body: {
          tierId,
          categoryGroupName,
          categoryGroupPath,
          categoryGroupIcon,
        },
      }),
      invalidatesTags: [{ type: "CategoryGroup", id: "LIST" }],
    }),
    updateCategoryGroup: builder.mutation({
      query: ({
        categoryGroupId,
        categoryGroupName,
        categoryGroupPath,
        categoryGroupIcon,
      }) => ({
        url: `update-category-group/${categoryGroupId}`,
        method: "PUT",
        body: {
          categoryGroupName,
          categoryGroupPath,
          categoryGroupIcon,
        },
      }),
      invalidatesTags: [{ type: "CategoryGroup", id: "LIST" }],
    }),
    getAllCategoryGroups: builder.query({
      query: () => ({
        url: `all-category-groups/`,
        method: "GET",
      }),
      providesTags: [{ type: "CategoryGroup", id: "LIST" }],
    }),
    getCategoryGroupById: builder.query({
      query: (categoryGroupId) => ({
        url: `get-category-group-by-id/${categoryGroupId}/`,
        method: "GET",
      }),
      skip: (categoryGroupId) => !categoryGroupId,
      providesTags: [{ type: "CategoryGroup", id: "LIST" }],
    }),
    getAllCategoryGroupsByTier: builder.query({
      query: (tierId) => ({
        url: `all-category-groups-by-tier/${tierId}`,
        method: "GET",
      }),
      skip: (tierId) => !tierId,
      providesTags: [{ type: "CategoryGroup", id: "LIST" }],
    }),
    deleteCategoryGroup: builder.mutation({
      query: ({ userId, categoryGroupId }) => ({
        url: `delete-category-group/${userId}/${categoryGroupId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "CategoryGroup", id: "LIST" }],
    }),
  }),
});

export const {
  useCreateCategoryGroupMutation,
  useGetAllCategoryGroupsQuery,
  useGetCategoryGroupByIdQuery,
  useGetAllCategoryGroupsByTierQuery,
  useUpdateCategoryGroupMutation,
  useDeleteCategoryGroupMutation,
} = categoryGroupsApi;

export default categoryGroupsApi;
export { categoryGroupsApi };

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const tierPermissionApi = createApi({
  reducerPath: "tier-permission",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/tier-permissions/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["TierPermission"],
  endpoints: (builder) => ({
    getAllTierPermissions: builder.query({
      query: (accountType) => {
        return {
          url: `get-all`,
          method: "GET",
        };
      },
      skip: (accountType) => accountType !== "reosadmin",
      providesTags: [{ type: "TierPermission", id: "LIST" }],
    }),
    getAllTierPermissionItemsByUserRole: builder.query({
      query: ({ roleId, tierId }) => {
        return {
          url: `get-all-tierpermission-items-by-user-role/${roleId}/${tierId}`,
          method: "GET",
        };
      },
      skip: (roleId, tierId) => !roleId || !tierId,
      providesTags: [{ type: "TierPermission", id: "LIST" }],
    }),
    checkTierPermissionForUserRole: builder.query({
      query: ({ tierId, userId, permission }) => {
        return {
          url: `check-tier-permission-for-user-role/${tierId}/${userId}/${permission}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "TierPermission", id: "LIST" }],
    }),
    updateTierPermission: builder.mutation({
      query: ({ tierPermId, permissions }) => ({
        url: `update/${tierPermId}`,
        method: "PUT",
        body: { permissions },
      }),
      invalidatesTags: (result, error, { tierPermId }) => [
        { type: "TierPermission", id: tierPermId },
      ],
    }),
    updateTierPermissionToRole: builder.mutation({
      query: ({ tierId, roleId, permissions }) => ({
        url: `update-tier-permission-to-role`,
        method: "PUT",
        body: { tierId, roleId, permissions },
      }),
      invalidatesTags: [
        { type: "Tier", id: "LIST" },
        { type: "Role", id: "LIST" },
        { type: "TierPermission", id: "LIST" },
      ],
    }),
    removeTierPermission: builder.mutation({
      query: ({ tierPermId }) => ({
        url: `delete-one/${tierPermId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "TierPermission", id: "LIST" }],
    }),
  }),
});

export const {
  useGetAllTierPermissionsQuery,
  useGetAllTierPermissionItemsByUserRoleQuery,
  useCheckTierPermissionForUserRoleQuery,
  useUpdateTierPermissionMutation,
  useUpdateTierPermissionToRoleMutation,
  useRemoveTierPermissionMutation,
} = tierPermissionApi;

export default tierPermissionApi;
export { tierPermissionApi };

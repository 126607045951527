import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";

const DutyConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  day,
  memberData,
  daysEvents,
  timeSlots = [],
  dutyTypes = [],
}) => {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(timeSlots[0] || "");
  const [selectedDutyType, setSelectedDutyType] = useState(dutyTypes[0] || "");

  useEffect(() => {
    if (timeSlots.length > 0 && !selectedTimeSlot) {
      setSelectedTimeSlot(timeSlots[0]);
    }
  }, [timeSlots]);

  useEffect(() => {
    if (dutyTypes.length > 0 && !selectedDutyType) {
      setSelectedDutyType(dutyTypes[0]);
    }
  }, [dutyTypes]);

  const handleConfirm = () => {
    onConfirm(selectedTimeSlot, selectedDutyType);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          backgroundColor: "#1976d2",
          color: "white",
          marginBottom: "1rem",
        }}
      >
        Confirm Assignment
      </DialogTitle>
      <DialogContent>
        <Typography variant="h7" gutterBottom>
          Assign{" "}
          {`${memberData?.user?.firstName} ${memberData?.user?.lastName}`} for{" "}
          {day} at
        </Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel>Time Slot</InputLabel>
          <Select
            value={selectedTimeSlot || ""}
            label="Time Slot"
            onChange={(e) => setSelectedTimeSlot(e.target.value)}
          >
            {timeSlots &&
              timeSlots?.length > 0 &&
              timeSlots?.map((slot, index) => (
                <MenuItem key={index} value={slot}>
                  {slot}
                  {(() => {
                    const [startTime] = slot.split(" - ");
                    const matchingEvent = daysEvents.find((event) => {
                      const eventStartTime = new Date(
                        event.start
                      ).toLocaleTimeString([], {
                        hour: "numeric",
                        minute: "2-digit",
                      });

                      return eventStartTime === startTime;
                    });
                    if (matchingEvent) {
                      return ` - Currently assigned to ${matchingEvent.title}`;
                    }
                    return "";
                  })()}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>Duty Type</InputLabel>
          <Select
            value={selectedDutyType || ""}
            label="Duty Type"
            onChange={(e) => setSelectedDutyType(e.target.value)}
          >
            {dutyTypes &&
              dutyTypes?.length > 0 &&
              dutyTypes?.map((type, index) => (
                <MenuItem key={index} value={type}>
                  {type}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {selectedTimeSlot && daysEvents.some(event => {
          const [startTime] = selectedTimeSlot.split(" - ");
          const eventStartTime = new Date(event.start).toLocaleTimeString([], {
            hour: "numeric",
            minute: "2-digit",
          });
          return eventStartTime === startTime;
        }) && (
          <Typography color="error" variant="body2" sx={{ mt: 2 }}>
            Notice: This time slot is currently assigned. Confirming will replace the existing assignment with {memberData?.user?.firstName} {memberData?.user?.lastName}.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          {selectedTimeSlot &&
          daysEvents.some((event) => {
            const [startTime] = selectedTimeSlot.split(" - ");
            const eventStartTime = new Date(event.start).toLocaleTimeString(
              [],
              {
                hour: "numeric",
                minute: "2-digit",
              }
            );
            return eventStartTime === startTime;
          })
            ? `Assign Slot to ${memberData?.user?.firstName} ${memberData?.user?.lastName}`
            : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DutyConfirmationDialog;

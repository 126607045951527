import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const featureSettingsApi = createApi({
  reducerPath: "feature-settings",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/feature-settings/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["FeatureSettings"],
  endpoints: (builder) => ({
    getAllFeatureSettings: builder.query({
      query: () => ({
        url: "get-all",
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({
                type: "FeatureSettings",
                id: _id,
              })),
              { type: "FeatureSettings", id: "LIST" },
            ]
          : [{ type: "FeatureSettings", id: "LIST" }],
    }),
    getFeatureSettingsById: builder.query({
      query: (featureSettingsId) => ({
        url: `get-one-by-id/${featureSettingsId}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "FeatureSettings", id }],
    }),
    createFeatureSettings: builder.mutation({
      query: (featureSettingsData) => ({
        url: `create-one`,
        method: "POST",
        body: featureSettingsData,
      }),
      invalidatesTags: [{ type: "FeatureSettings", id: "LIST" }],
    }),
    updateFeatureSettings: builder.mutation({
      query: ({ featureSettingsId, featureSettingsData }) => ({
        url: `update-one/${featureSettingsId}`,
        method: "PUT",
        body: featureSettingsData,
      }),
      invalidatesTags: (result, error, { featureSettingsId }) => [
        { type: "FeatureSettings", id: featureSettingsId },
        { type: "FeatureSettings", id: "LIST" },
      ],
    }),
    deleteFeatureSettings: builder.mutation({
      query: (featureSettingsId) => ({
        url: `delete-one/${featureSettingsId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, featureSettingsId) => [
        { type: "FeatureSettings", id: featureSettingsId },
        { type: "FeatureSettings", id: "LIST" },
      ],
    }),
    deleteAllFeatureSettings: builder.mutation({
      query: () => ({
        url: `delete-all`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "FeatureSettings", id: "LIST" }],
    }),
    isFeatureActive: builder.query({
      query: (featureName) => ({
        url: `is-active/${featureName}`,
        method: "GET",
      }),
      skip: (featureName) => !featureName,
      providesTags: (result, error, id) => [{ type: "FeatureSettings", id }],
    }),
  }),
});

export const {
  useGetAllFeatureSettingsQuery,
  useGetFeatureSettingsByIdQuery,
  useCreateFeatureSettingsMutation,
  useUpdateFeatureSettingsMutation,
  useDeleteFeatureSettingsMutation,
  useDeleteAllFeatureSettingsMutation,
  useIsFeatureActiveQuery,
} = featureSettingsApi;

export default featureSettingsApi;
export { featureSettingsApi };

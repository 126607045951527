import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetupNewMemberPasswordMutation } from "../../services/invitation";
import {
  Container,
  Typography,
  TextField,
  Button,
  Snackbar,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function SetupNewMemberPassword() {
  const [newPassword, setNewPassword] = useState("");
  const { token } = useParams();
  const navigate = useNavigate();
  const [setupNewMemberPassword] = useSetupNewMemberPasswordMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await setupNewMemberPassword({ token, newPassword });

    if (result.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        navigate("/login");
      }, 2500);
    } else {
      if (
        result?.error?.data?.msg ===
        "Token expired. A new password reset link has been sent to your email."
      ) {
        setNotification({
          open: true,
          message: `Error: ${result?.error?.data?.msg}`,
        });
      } else {
        setNotification({
          open: true,
          message: `Error: ${result?.error?.data?.msg}`,
        });
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: (theme) => theme.spacing(3),
      }}
    >
      <Typography component="h1" variant="h5">
        Reset Password
      </Typography>
      <form
        onSubmit={handleSubmit}
        sx={{
          width: "100%",
          marginTop: (theme) => theme.spacing(1),
        }}
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="newPassword"
          label="New Password"
          type={showPassword ? "text" : "password"}
          id="newPassword"
          autoComplete="current-password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
                size="small"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{
            margin: (theme) => theme.spacing(3, 0, 2),
          }}
        >
          Reset Password
        </Button>
      </form>
      <Snackbar
        open={notification.open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={notification.message}
        action={
          <Button color="secondary" size="small" onClick={handleClose}>
            Close
          </Button>
        }
      />
    </Container>
  );
}

export default SetupNewMemberPassword;

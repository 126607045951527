import React, { useContext } from "react";
import { Typography, Box } from "@mui/material";
import { AuthContext } from "../../../../context/AuthContext";

const CustomEvent = ({ event }) => {
  const { userId } = useContext(AuthContext);
  const isUserDuty = event.member?.user?._id === userId;
  const isAvailableDuty = event && !event.member;

  const now = new Date();
  const isPastEvent = event.end < now;

  const backgroundColor = isPastEvent
    ? "var(--color-past-event)"
    : isUserDuty
    ? "var(--color-user-duty)"
    : isAvailableDuty
    ? "var(--color-available-duty)"
    : event.dutyType === "Remote" || event.dutyType === "Phone"
    ? "var(--color-phone-duty)" // Orange for phone duties
    : event.dutyType === "Office"
    ? "var(--color-office-duty)"
    : "var(--color-office-duty)";

  return (
    <Box
      style={{
        backgroundColor,
        borderRadius: "4px",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="body1"
        component="div"
        sx={{
          fontSize: "0.70rem",
          textWrap: "wrap",
          fontWeight: "bold",
          height: "100%",
          whiteSpace: "normal",
          textAlign: "center",
        }}
      >
        {event.title}
      </Typography>
    </Box>
  );
};

export default CustomEvent;

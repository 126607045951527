import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Typography, Snackbar } from "@mui/material";
import { useRequestPasswordForgotMutation } from "../../services/password";

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [requestPasswordForgotAction] = useRequestPasswordForgotMutation();
  const [notification, setNotification] = useState({
    message: "",
    severity: "info",
  });

  const handleRequestReset = async (e) => {
    e.preventDefault();
    try {
      await requestPasswordForgotAction(email.trim()).unwrap();
      navigate("/check-email");
    } catch (err) {
      console.error("Failed to request password reset:", err);

      navigate("/check-email");
      // even if no email has been found still proceed to the check-email page so that bots can't scrap for emails with wrong ones.

      // setNotification({
      //   message: err?.data?.msg || "An error occurred",
      //   severity: "error",
      // });
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography variant="h4">Forgot Password</Typography>
      <form onSubmit={handleRequestReset}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) =>
            setEmail(e.target.value.toLowerCase().replace(/\s+/g, ""))
          }
        />
        <Button type="submit" fullWidth variant="contained" color="primary">
          Request Password Reset
        </Button>
      </form>
      <Snackbar
        open={Boolean(notification.message)}
        autoHideDuration={3000}
        message={notification?.message || "An error occurred"}
        onClose={() => setNotification({ message: "", severity: "info" })}
      />
    </div>
  );
}

export default ForgotPassword;

import React, { useState } from "react";
import { useGetAllCategoryTypesByTierQuery } from "../../../services/category-type";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Container,
  Typography,
  Paper,
  List,
  ListItem,
  Box,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import CategoriesList from "../../reosadmin/category-management/CategoriesList";
import CreateEditCategoryForm from "../../reosadmin/category-management/CreateEditCategoryForm";

const GroupCategoryManager = ({ tier }) => {
  const { data: categoryTypes } = useGetAllCategoryTypesByTierQuery(tier?._id, {
    skip: !tier?._id,
  });

  const [notification, setNotification] = useState({
    openNotification: false,
    message: "",
  });
  const [openCreateCategoryDialog, setOpenCreateCategoryDialog] =
    useState(false);

  const handleOpenCreateCategoryDialog = () => {
    setOpenCreateCategoryDialog(true);
  };

  const handleCloseCreateCategoryDialog = () => {
    setOpenCreateCategoryDialog(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, openNotification: false });
  };

  return (
    <Container maxWidth="md">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row", // Set to row to align items horizontally
            alignItems: "center", // Align items vertically in the center
            justifyContent: "space-between", // Add space between the elements
            width: "100%", // Take full width to spread items across
            p: 1, // Padding around the Box
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: 700, width: "50%" }}
            fontWeight={700}
          >
            Group categories management
          </Typography>
          <Box className="user-management-box">
            <List className="user-management-list">
              <ListItem
                button
                onClick={handleOpenCreateCategoryDialog}
                className="user-management-item"
              >
                <AddCircleOutlineIcon className="icon" /> Create New Category
              </ListItem>
            </List>
          </Box>
        </Box>
        <NotificationSnackbar
          open={notification.openNotification}
          message={notification.message}
          onClose={handleClose}
        />
      </Paper>
      <Paper sx={{ p: 2, mb: 2 }}>
        <CategoriesList selectedTier={tier} />
      </Paper>
      <CreateEditCategoryForm
        mode="create"
        selectedTier={tier}
        categoryTypes={categoryTypes}
        open={openCreateCategoryDialog}
        onClose={handleCloseCreateCategoryDialog}
      />
    </Container>
  );
};

export default GroupCategoryManager;

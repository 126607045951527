import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import {
  useCreateFeatureSettingsMutation,
  useUpdateFeatureSettingsMutation,
} from "../../../services/feature-settings";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const environments = ["local", "development", "production"];
const roles = ["reosadmin", "brokerage", "member"];

function CreateEditFeatureSettingsForm({
  open,
  onClose,
  featureSettings,
  mode,
}) {
  const [name, setName] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [environment, setEnvironment] = useState([]);
  const [userRole, setUserRole] = useState([]);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [createFeatureSettings, { isLoading: isCreating }] =
    useCreateFeatureSettingsMutation();
  const [updateFeatureSettings, { isLoading: isUpdating }] =
    useUpdateFeatureSettingsMutation();

  const formatName = (name) => {
    return name.trim().toLowerCase().replace(/\s+/g, "-");
  };

  useEffect(() => {
    if (mode === "edit" && featureSettings) {
      setName(featureSettings.name || "");
      setIsActive(featureSettings.isActive || false);
      setEnvironment(featureSettings.environment || []);
      setUserRole(featureSettings.userRole || []);
    } else {
      setName("");
      setIsActive(false);
      setEnvironment([]);
      setUserRole([]);
    }
  }, [featureSettings, mode]);

  const handleSave = async () => {
    if (
      mode === "create" &&
      (!name || !environment?.length || !userRole?.length)
    ) {
      setNotification({
        open: true,
        message: "Please fill in all required fields.",
      });
      return;
    }
    if (
      mode === "edit" &&
      (!featureSettings._id ||
        !name ||
        !environment?.length ||
        !userRole?.length)
    ) {
      setNotification({
        open: true,
        message: "Please fill in all required fields.",
      });
      return;
    }

    const formattedName = formatName(name);

    const featureSettingsData = {
      name: formattedName,
      isActive,
      environment,
      userRole,
    };
    let result;

    if (mode === "edit") {
      result = await updateFeatureSettings({
        featureSettingsId: featureSettings._id,
        featureSettingsData,
      });
    } else if (mode === "create") {
      result = await createFeatureSettings(featureSettingsData);
    }

    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        if (mode === "create") {
          setName("");
          setIsActive(false);
          setEnvironment([]);
          setUserRole([]);
        }
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error saving feature settings: ${
          result?.error?.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {mode === "edit" ? "Edit Feature Settings" : "Create Feature Settings"}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          <TextField
            label="Feature Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
              />
            }
            label="Is Active"
          />
          <TextField
            select
            label="Environment"
            value={environment}
            onChange={(e) => setEnvironment(e.target.value)}
            fullWidth
            SelectProps={{
              multiple: true,
            }}
          >
            {environments?.map((env) => (
              <MenuItem key={env} value={env}>
                {env}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="User Role"
            value={userRole}
            onChange={(e) => setUserRole(e.target.value)}
            fullWidth
            SelectProps={{
              multiple: true,
            }}
          >
            {roles?.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isCreating || isUpdating}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={isCreating || isUpdating}
        >
          {isCreating || isUpdating ? <CircularProgress size={24} /> : "Save"}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
}

export default CreateEditFeatureSettingsForm;

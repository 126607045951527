import React, { useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import {
  useAssignAllOfficeCategoriesToLocalOfficeMutation,
  useUnassignAllOfficeCategoriesFromLocalOfficeMutation,
} from "../../../services/category";
import { useGetTiersFromUserRoleTiersCollectionQuery } from "../../../services/tier";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import { titleCase } from "title-case";

const AssignAllOfficeCategoiesToLocalOfficeDialog = ({
  open,
  onClose,
  selectedCategories,
  mode = "assign",
}) => {
  const { userId } = useContext(AuthContext);
  const { data: tiers } = useGetTiersFromUserRoleTiersCollectionQuery(userId, {
    skip: !userId,
  });

  const [
    assignAllOfficeCategoriesToLocalOffice,
    { isLoading: isAssignAllOfficeCategoriesLoading },
  ] = useAssignAllOfficeCategoriesToLocalOfficeMutation();
  const [
    unassignAllOfficeCategoriesToLocalOffice,
    { isLoading: isUnassignAllOfficeCategoriesLoading },
  ] = useUnassignAllOfficeCategoriesFromLocalOfficeMutation();

  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [selectedTier, setSelectedTier] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedCategories.length || !selectedTier?._id) {
      setNotification({
        open: true,
        message: "There are no selected categories or tier id.",
      });
      return;
    }

    let result;
    if (mode === "assign") {
      result = await assignAllOfficeCategoriesToLocalOffice({
        tierId: selectedTier?._id,
        categoryIds: selectedCategories,
      });
    } else if (mode === "remove") {
      result = await unassignAllOfficeCategoriesToLocalOffice({
        tierId: selectedTier?._id,
        categoryIds: selectedCategories,
      });
    }

    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        if (mode === "assign") {
          setSelectedTier(null);
        }
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error: ${result?.error?.data?.msg || "Unknown error"}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth className="edit-post">
      <DialogTitle>
        <Typography sx={{ fontWeight: 700, fontSize: "1.5rem" }}>
          {mode === "assign"
            ? "Assign All Office Categories To Local Office"
            : "Unnassign All Office Categories From Local Office"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid item>
            <TextField
              label="Selected Categories"
              variant="outlined"
              margin="normal"
              fullWidth
              value={selectedCategories.length || []}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              value={selectedTier}
              onChange={(event, newValue) => {
                setSelectedTier(newValue);
              }}
              options={tiers || []}
              getOptionLabel={(option) =>
                option
                  ? `${titleCase(option?.level)}${
                      (option?.brokerage || option?.team || option?.group) &&
                      (option?.brokerage?.officeName ||
                        option?.team?.name ||
                        option?.group?.name)
                        ? ` - ${
                            option?.brokerage?.officeName ||
                            option?.team?.name ||
                            option?.group?.name
                          }`
                        : ""
                    } ${
                      option?.level === "system" || option?.level === "global"
                        ? " - REACirc"
                        : ""
                    } ${
                      option?._id === option?.brokerage?.leadBrokerageTier
                        ? " - (Lead Brokerage Office)"
                        : ""
                    } ${
                      option?._id === option?.brokerage?.tier
                        ? " - (Office)"
                        : ""
                    }`
                  : ""
              }
              isOptionEqualToValue={(option, value) =>
                option?._id === value._id
              }
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select the Office"
                  variant="outlined"
                  margin="normal"
                  required
                />
              )}
            />
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid item>
          <Button onClick={onClose} color="secondary" type="submit" fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={
              (mode === "assign" && isAssignAllOfficeCategoriesLoading) ||
              (mode === "remove" && isUnassignAllOfficeCategoriesLoading)
            }
          >
            {(mode === "assign" && isAssignAllOfficeCategoriesLoading) ||
            (mode === "remove" && isUnassignAllOfficeCategoriesLoading) ? (
              <CircularProgress size={24} />
            ) : mode === "assign" ? (
              "Mirror To "
            ) : (
              "Unmirror to "
            )}
          </Button>
        </Grid>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default AssignAllOfficeCategoiesToLocalOfficeDialog;

import React, { useState, memo } from "react";
import { Grid, Button, IconButton, MenuItem, TextField } from "@mui/material";
import { Delete } from "@mui/icons-material";

const generateTimeOptions = (start, end, step) => {
  const times = [];
  for (let i = start; i <= end; i += step) {
    const hour = Math.floor(i / 60)
      .toString()
      .padStart(2, "0");
    const minute = (i % 60).toString().padStart(2, "0");
    times.push(`${hour}:${minute}`);
  }
  return times;
};

const timeOptions = generateTimeOptions(0, 1440, 15);

const TimeSlotSelector = ({ onAddTimeSlot, onRemoveTimeSlot, timeSlots }) => {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const handleAddTimeSlot = () => {
    if (startTime && endTime) {
      onAddTimeSlot({ startTime, endTime });
      setStartTime("");
      setEndTime("");
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <TextField
            select
            label="Start Time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            fullWidth
            helperText="Select the start time"
          >
            {timeOptions.map((time) => (
              <MenuItem key={time} value={time}>
                {time}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={5}>
          <TextField
            select
            label="End Time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            fullWidth
            helperText="Select the end time"
          >
            {timeOptions.map((time) => (
              <MenuItem key={time} value={time}>
                {time}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddTimeSlot}
          >
            Add
          </Button>
        </Grid>
      </Grid>
      <ul>
        {timeSlots?.map((slot, index) => (
          <li key={index}>
            {`${slot?.startTime} - ${slot?.endTime}`}
            <IconButton
              aria-label="delete"
              onClick={() => onRemoveTimeSlot(index)}
              color="secondary"
            >
              <Delete />
            </IconButton>
          </li>
        ))}
      </ul>
    </>
  );
};

export default memo(TimeSlotSelector);

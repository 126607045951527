import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const subscriptionPlanApi = createApi({
  reducerPath: "subscriptionPlan",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/billing/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["SubscriptionPlan"],
  endpoints: (builder) => ({
    // SubscriptionPlan Endpoints
    fetchSubscriptionPlans: builder.query({
      query: () => "subscription-plans",
      providesTags: ["SubscriptionPlan"],
    }),
    fetchSubscriptionPlanById: builder.query({
      query: (_id) => `subscription-plans/${_id}`,
      providesTags: ["SubscriptionPlan"],
    }),
    createSubscriptionPlan: builder.mutation({
      query: (subscriptionPlanData) => ({
        url: "subscription-plans",
        method: "POST",
        body: subscriptionPlanData,
      }),
      invalidatesTags: ["SubscriptionPlan"],
    }),
    updateSubscriptionPlan: builder.mutation({
      query: ({ _id, ...subscriptionPlanData }) => ({
        url: `subscription-plans/${_id}`,
        method: "PUT",
        body: subscriptionPlanData,
      }),
      invalidatesTags: (result, error, { _id }) => ["SubscriptionPlan"],
    }),
    deleteSubscriptionPlan: builder.mutation({
      query: (_id) => ({
        url: `subscription-plans/${_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SubscriptionPlan"],
    }),
  }),
});
export const {
  // SubscriptionPlan Hooks
  useFetchSubscriptionPlansQuery,
  useFetchSubscriptionPlanByIdQuery,
  useCreateSubscriptionPlanMutation,
  useUpdateSubscriptionPlanMutation,
  useDeleteSubscriptionPlanMutation,
} = subscriptionPlanApi;

export default subscriptionPlanApi;

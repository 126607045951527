import React, { useState } from "react";
import { useUpdateEmailMutation } from "../../services/user";
import Draggable from "react-draggable";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  CircularProgress,
  Button,
  Paper,
} from "@mui/material";
import NotificationSnackbar from "../helpers/notification-snackbar";

function PaperComponent(props) {
  return (
    <Draggable
      handle=".draggable-handle"
      cancel={
        '[class*="MuiDialogContent-root"], [class*="MuiDialogActions-root"]'
      }
    >
      <Paper {...props} />
    </Draggable>
  );
}

function EmailChangeDialog({ onCancel, open, onClose, userId }) {
  const [newEmail, setNewEmail] = useState("");
  const [updateEmail, { isLoading }] = useUpdateEmailMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleEmailChange = async () => {
    const result = await updateEmail({ userId, newEmail });
    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        onClose();
        setNewEmail("");
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Failed to update email: ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperComponent={PaperComponent}
    >
      <DialogTitle className="draggable-handle">Change Email</DialogTitle>
      <DialogContent>
        <DialogContentText>Please enter your new Email.</DialogContentText>
        <TextField
          variant="outlined"
          margin="normal"
          type="email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
          placeholder="Enter new email"
          fullWidth
          style={{ marginBottom: "10px" }}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onCancel}
          style={{ marginLeft: "10px" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleEmailChange}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : "Update Email"}
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
}

export default EmailChangeDialog;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import { useLoginUserWithTokenMutation } from "../../services/auth";
import NotificationSnackbar from "../../components/helpers/notification-snackbar";

const LoginWithToken = () => {
  const { handleLogin } = useContext(AuthContext);
  const navigate = useNavigate();
  let location = useLocation();
  const [loginUserWithToken, { isLoading }] = useLoginUserWithTokenMutation();
  let newPathname = location.pathname.replace("/login-via-token", "/");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get("token");
    let linkToGo = decodeURIComponent(urlParams.get("linkToGo") || "");

    const login = async () => {
      try {
        const data = await loginUserWithToken({ token, linkToGo }).unwrap();
        if (data.msg === "Password not set") {
          navigate(`/setup-password/${data.setupPasswordToken}`, {
            replace: true,
          });
        } else {
          handleLogin(data);
          setTimeout(() => {
            if (linkToGo) {
              navigate(newPathname + linkToGo, { replace: true });
            } else {
              navigate(newPathname, { replace: true });
            }
          }, 1000);
        }
      } catch (error) {
        if (error.data?.msg === "Password not set") {
          navigate(`/setup-password/${error.data.setupPasswordToken}`, {
            replace: true,
          });
        } else if (error.data?.msg.includes("Token expired")) {
          setNotification({
            open: true,
            message: `${error?.data?.msg}`,
          });
          setTimeout(() => {
            navigate("/login");
          }, 4000);
        } else {
          navigate("/login");
        }
      }
    };

    if (token && newPathname) {
      login();
    }
  }, [location.search, handleLogin, newPathname, navigate, loginUserWithToken]);

  return (
    <>
      {isLoading ? <CircularProgress /> : null}
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
        notificationDuration={4000}
      />
    </>
  );
};

export default LoginWithToken;

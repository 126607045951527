import React from "react";
import {
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";

const FeatureSetting = ({
  settingName,
  settingValue,
  handleCheckboxChange,
}) => (
  <Grid item xs={12} mt={2}>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          color: "var(--text-color)",
          bgcolor: "var(--bg-color)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mr: "1rem",
        }}
        variant="subtitle"
      >
        {settingName}:
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={settingValue.is}
            onChange={(event) => handleCheckboxChange(event, settingName)}
            name="is"
            color="primary"
          />
        }
        label="Is"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={settingValue.isNot}
            onChange={(event) => handleCheckboxChange(event, settingName)}
            name="isNot"
            color="primary"
          />
        }
        label="Is Not"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={settingValue.optional}
            onChange={(event) => handleCheckboxChange(event, settingName)}
            name="optional"
            color="primary"
          />
        }
        label="Optional"
      />
    </Box>
  </Grid>
);

export default FeatureSetting;

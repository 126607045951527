import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  FormHelperText,
  IconButton,
  Paper,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function PaperComponent(props) {
  return (
    <Draggable
      handle=".draggable-handle"
      cancel={
        '[class*="MuiDialogContent-root"], [class*="MuiDialogActions-root"]'
      }
    >
      <Paper {...props} />
    </Draggable>
  );
}

function PasswordChangeDialog({ open, onClose, onConfirm }) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    setPasswordsMatch(newPassword === confirmNewPassword);
  }, [newPassword, confirmNewPassword]);

  const handleConfirm = () => {
    if (newPassword === confirmNewPassword) {
      onConfirm(oldPassword, newPassword);
      setOldPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    } else {
      alert("New passwords do not match");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperComponent={PaperComponent}
    >
      <DialogTitle className="draggable-handle">Change Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter your old password and your new password.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Old Password"
          type={showOldPassword ? "text" : "password"}
          fullWidth
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowOldPassword(!showOldPassword)}
                edge="end"
                size="small"
              >
                {showOldPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
          }}
        />
        <TextField
          margin="dense"
          label="New Password"
          type={showNewPassword ? "text" : "password"}
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowNewPassword(!showNewPassword)}
                edge="end"
                size="small"
              >
                {showNewPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
          }}
        />
        <TextField
          margin="dense"
          label="Confirm New Password"
          type={showConfirmPassword ? "text" : "password"}
          fullWidth
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                edge="end"
                size="small"
              >
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
          }}
        />
        {!passwordsMatch && confirmNewPassword !== "" && (
          <FormHelperText error>Passwords do not match</FormHelperText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          disabled={!passwordsMatch}
        >
          Change Password
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PasswordChangeDialog;

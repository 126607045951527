import React, { useState, useContext } from "react";
import { useGetTicketsQuery } from "../../../services/ticket";
import { AuthContext } from "../../../context/AuthContext";
import {
  Container,
  Typography,
  Paper,
  List,
  ListItem,
  Divider,
  Grid,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";
import TicketsList from "./TicketsList";
import CreateEditTicketDialog from "./CreateEditTicketDialog";

const SupportManagement = () => {
  const { accountType, userId } = useContext(AuthContext);
  const { data: tickets, refetch } = useGetTicketsQuery(
    { accountType, userId },
    {
      skip: !userId || accountType !== "reosadmin",
    }
  );
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const [openCreateTicketDialog, setOpenCreateTicketDialog] = useState(false);
  const handleOpenCreateTicketDialog = () => {
    setOpenCreateTicketDialog(true);
  };

  const handleCloseCreateTicketDialog = () => {
    setOpenCreateTicketDialog(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Container className="management-page">
      <Paper sx={{ p: 3, mb: 5, display: "flex", flexDirection: "column" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Support Ticket Management
        </Typography>
        <Divider />
        <Grid container>
          <Grid item xs={6} sx={{ p: 2 }}>
            <List style={{ width: "100%", marginRight: "0.1rem" }}>
              <ListItem
                onClick={handleOpenCreateTicketDialog}
                className="user-management-item"
              >
                Create new Ticket
              </ListItem>
              <Divider />
            </List>
          </Grid>
        </Grid>
        <CreateEditTicketDialog
          mode="create"
          open={openCreateTicketDialog}
          onClose={handleCloseCreateTicketDialog}
        />
        <NotificationSnackbar
          open={notification.open}
          message={notification.message}
          onClose={handleClose}
        />
      </Paper>
      <TicketsList tickets={tickets} refetchTickets={refetch} />
    </Container>
  );
};

export default SupportManagement;

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetInvitationByIdQuery } from "../../../services/invitation";
import { Paper, Box, CircularProgress, Tabs, Tab } from "@mui/material";
import { BackButton } from "../..";
import InvitationDetailsInfo from "./InvitationDetailsInfo";
import { TabPanel } from "../../helpers/utils";

function Invitation() {
  const { invitationId } = useParams();
  const {
    data: invitation,
    isLoading,
    isError,
    error,
  } = useGetInvitationByIdQuery(invitationId);
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (isLoading)
    return (
      <div className="loading-spinner">
        <CircularProgress size={24} />
      </div>
    );
  if (isError || !invitation)
    return <div>Error loading invitation details {error?.data?.msg}</div>;

  return (
    <Box sx={{ p: 2, width: "100%", maxWidth: 600, margin: "auto", mt: 2 }}>
      <BackButton />
      <Paper sx={{ p: 2 }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="invitation profile tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label="Invitation Details"
            id="simple-tab-0"
            aria-controls="simple-tabpanel-0"
          />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          {invitation && <InvitationDetailsInfo invitation={invitation} />}
        </TabPanel>
      </Paper>
    </Box>
  );
}

export default Invitation;

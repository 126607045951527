import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const roleApi = createApi({
  reducerPath: "role",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/roles/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Role"],
  endpoints: (builder) => ({
    createInitialRoleWithPermissions: builder.mutation({
      query: (userId) => ({
        url: `create-initial-role-with-permissions/${userId}`,
        method: "PUT",
      }),
      invalidatesTags: [
        { type: "User", id: "ACTIVE_LIST" },
        { type: "Role", id: "LIST" },
      ],
    }),
    createRole: builder.mutation({
      query: ({ name, permissions }) => ({
        url: "create-one",
        method: "POST",
        body: { name, permissions },
      }),
      invalidatesTags: [{ type: "Role", id: "LIST" }],
    }),
    updateRole: builder.mutation({
      query: ({ roleId, name, permissions }) => ({
        url: `update-one/${roleId}`,
        method: "PUT",
        body: { name, permissions },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Role", id }],
    }),
    getAllRoles: builder.query({
      query: () => ({
        url: "get-all",
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Role", id: _id })),
              { type: "Role", id: "LIST" },
            ]
          : [{ type: "Role", id: "LIST" }],
    }),
    getAllFreeRoles: builder.query({
      query: () => ({
        url: "get-all-free-roles",
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Role", id: _id })),
              { type: "Role", id: "LIST" },
            ]
          : [{ type: "Role", id: "LIST" }],
    }),
    getRoleById: builder.query({
      query: (roleId) => ({
        url: `get-one-by-id/${roleId}`,
        method: "GET",
      }),
      skip: (roleId) => !roleId,
      providesTags: (result, error, roleId) => [{ type: "Role", id: roleId }],
    }),
    assignRoleToUser: builder.mutation({
      query: ({ userId, roleId }) => ({
        url: "assign-one-to-user/",
        method: "POST",
        body: { userId, roleId },
      }),
      invalidatesTags: [{ type: "Role", id: "LIST" }],
    }),
    updateUserRole: builder.mutation({
      query: ({ newRoleId }) => ({
        url: `update-user-role`,
        method: "PUT",
        body: { newRoleId },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Role", id }],
    }),
    deleteRole: builder.mutation({
      query: ({ roleId }) => ({
        url: `delete-one/${roleId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Role", id }],
    }),
    deleteRoleFromUser: builder.mutation({
      query: ({ roleId, userId }) => ({
        url: `delete-role-from-user/${roleId}/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "Role", id }],
    }),
  }),
});

export const {
  useCreateInitialRoleWithPermissionsMutation,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useGetAllRolesQuery,
  useGetAllFreeRolesQuery,
  useGetRoleByIdQuery,
  useAssignRoleToUserMutation,
  useUpdateUserRoleMutation,
  useDeleteRoleMutation,
  useDeleteRoleFromUserMutation,
} = roleApi;

export default roleApi;
export { roleApi };

import React, { useContext, memo } from "react";
import { categoryIcon } from "./sidebar/sidebarHelpers";

import { ListItemIcon, AppBar, Toolbar, Typography, Box } from "@mui/material";
import { SidebarContext } from "../context/SidebarContext";

const Navbar = ({ handleSortMethodSelect, navBarName }) => {
  const { sidebarOpen } = useContext(SidebarContext);

  const homePageNavbarClass = `home-page app-bar ${
    !sidebarOpen ? "sidebar-closed" : "sidebar-open"
  }`;

  return (
    <AppBar className={homePageNavbarClass}>
      <Toolbar className={homePageNavbarClass}>
        <Typography
          variant="h6"
          className="appbar-title"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <ListItemIcon
            sx={{
              minWidth: "1.75rem",
              display: "flex",
              alignItems: "center",
              ml: 1,
              mr: 1,
            }}
          >
            {categoryIcon("folder")}
          </ListItemIcon>
          {navBarName}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />

        <Box className="app-bar-actions"></Box>
      </Toolbar>
    </AppBar>
  );
};


export default memo(Navbar);
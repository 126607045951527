import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const productCollectionApi = createApi({
  reducerPath: "productCollection",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/billing/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["ProductCollection"],
  endpoints: (builder) => ({
    // ProductCollection Endpoints
    fetchProductCollections: builder.query({
      query: () => "product-collections",
      providesTags: ["ProductCollection"],
    }),
    fetchProductCollectionById: builder.query({
      query: (id) => `product-collections/${id}`,
      providesTags: ["ProductCollection"],
    }),
    createProductCollection: builder.mutation({
      query: (productCollectionData) => ({
        url: "product-collections",
        method: "POST",
        body: productCollectionData,
      }),
      invalidatesTags: ["ProductCollection"],
    }),
    updateProductCollection: builder.mutation({
      query: ({ id, ...productCollectionData }) => ({
        url: `product-collections/${id}`,
        method: "PUT",
        body: productCollectionData,
      }),
      invalidatesTags: ["ProductCollection"],
    }),
    deleteProductCollection: builder.mutation({
      query: (id) => ({
        url: `product-collections/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProductCollection"],
    }),
  }),
});

export const {
  // ProductCollection Hooks
  useFetchProductCollectionsQuery,
  useFetchProductCollectionByIdQuery,
  useCreateProductCollectionMutation,
  useUpdateProductCollectionMutation,
  useDeleteProductCollectionMutation,
} = productCollectionApi;

export default productCollectionApi;

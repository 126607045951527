import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const dutyScheduleApi = createApi({
  reducerPath: "duty-schedule",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/duty-schedule/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("accept", "application/json");
      return headers;
    },
  }),
  tagTypes: ["DutySchedule"],
  endpoints: (builder) => ({
    getAllDutySchedule: builder.query({
      query: () => ({
        url: "get-all",
        method: "GET",
      }),
      providesTags: [{ type: "DutySchedule", id: "LIST" }],
    }),
    getAllDutyScheduleByTier: builder.query({
      query: (tierId) => ({
        url: `get-all-by-tier/${tierId}`,
        method: "GET",
      }),
      skip: (tierId) => !tierId,
      providesTags: [{ type: "DutySchedule", id: "LIST" }],
    }),
    getDutyScheduleById: builder.query({
      query: (dutyScheduleId) => ({
        url: `get-one-by-id/${dutyScheduleId}`,
        method: "GET",
      }),
      skip: (dutyScheduleId) => !dutyScheduleId,
      providesTags: [{ type: "DutySchedule", id: "LIST" }],
    }),
    getDutyScheduleTypeEnum: builder.query({
      query: () => ({
        url: "get-duty-schedule-type-enum",
        method: "GET",
      }),
      providesTags: [{ type: "DutySchedule", id: "LIST" }],
    }),
    getDutyScheduleOfficeLocationsByUserRole: builder.query({
      query: (userId) => ({
        url: `get-duty-schedule-office-locations-by-user/${userId}`,
        method: "GET",
      }),
      skip: (userId) => !userId,
      providesTags: [{ type: "DutySchedule", id: "LIST" }],
    }),
    getDutyScheduleOfficeLocationsByTier: builder.query({
      query: (tierId) => ({
        url: `get-duty-schedule-office-locations-by-tier/${tierId}`,
        method: "GET",
      }),
      skip: (tierId) => !tierId,
      providesTags: [{ type: "DutySchedule", id: "LIST" }],
    }),
    createDutySchedule: builder.mutation({
      query: ({ tierId, dutyScheduleData, startDate, endDate }) => ({
        url: `create-one/${tierId}`,
        method: "POST",
        body: { dutyScheduleData, startDate, endDate },
      }),
      invalidatesTags: [{ type: "DutySchedule", id: "LIST" }],
    }),
    updateMemberDutyPreferences: builder.mutation({
      query: ({ userIds, updateData }) => ({
        url: `update-member-duty-preferences`,
        method: "PUT",
        body: { userIds, updateData },
      }),
      invalidatesTags: [{ type: "DutySchedule", id: "LIST" }],
    }),
    setupMembersDefaultDutyPreferences: builder.mutation({
      query: ({ tierId, body }) => ({
        url: `setup-members-default-duty-preferences/${tierId}`,
        method: "POST",
        body: { body },
      }),
      invalidatesTags: [{ type: "DutySchedule", id: "LIST" }],
    }),
    updateDutySchedule: builder.mutation({
      query: ({ dutyScheduleId, dutyScheduleData }) => ({
        url: `update-one/${dutyScheduleId}`,
        method: "PUT",
        body: { dutyScheduleData },
      }),
      invalidatesTags: [{ type: "DutySchedule", id: "LIST" }],
    }),
    deleteDutySchedule: builder.mutation({
      query: ({ dutyScheduleId }) => ({
        url: `delete-one/${dutyScheduleId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "DutySchedule", id: "LIST" }],
    }),
    deleteAllDutySchedule: builder.mutation({
      query: () => ({
        url: `delete-all`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "DutySchedule", id: "LIST" }],
    }),
    getAllDutyChanges: builder.query({
      query: () => ({
        url: `get-all-duty-changes`,
        method: "GET",
      }),
      providesTags: [{ type: "DutySchedule", id: "LIST" }],
    }),
    getAllDutyChangesByTier: builder.query({
      query: (tierId) => ({
        url: `get-all-duty-changes-by-tier/${tierId}`,
        method: "GET",
      }),
      skip: (tierId) => !tierId,
      providesTags: [{ type: "DutySchedule", id: "LIST" }],
    }),
    clearAllDutyChangesLog: builder.mutation({
      query: () => ({
        url: `clear-all-duty-changes`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "DutySchedule", id: "LIST" }],
    }),
    clearAllDutyChangesLogByTier: builder.mutation({
      query: (tierId) => ({
        url: `clear-all-duty-changes-by-tier/${tierId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "DutySchedule", id: "LIST" }],
    }),
  }),
});

export const {
  useGetAllDutyScheduleQuery,
  useGetAllDutyScheduleByTierQuery,
  useGetDutyScheduleByIdQuery,
  useGetDutyScheduleTypeEnumQuery,
  useGetDutyScheduleOfficeLocationsByUserRoleQuery,
  useGetDutyScheduleOfficeLocationsByTierQuery,
  useUpdateMemberDutyPreferencesMutation,
  useSetupMembersDefaultDutyPreferencesMutation,
  useCreateDutyScheduleMutation,
  useUpdateDutyScheduleMutation,
  useDeleteDutyScheduleMutation,
  useDeleteAllDutyScheduleMutation,
  useGetAllDutyChangesQuery,
  useGetAllDutyChangesByTierQuery,
  useClearAllDutyChangesLogMutation,
  useClearAllDutyChangesLogByTierMutation,
} = dutyScheduleApi;

export default dutyScheduleApi;
export { dutyScheduleApi };

import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  DialogContent,
  TextField,
  Box,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
} from "@mui/material";
import dayjs from "dayjs";

const PaymentForm = forwardRef(
  (
    { onClose, onSubmit, invoice, onPaymentMethodChange, handleSnackbar },
    ref
  ) => {
    const [amount, setAmount] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("");
    const [transactionDate, setTransactionDate] = useState(
      dayjs().format("YYYY-MM-DD")
    );
    const [currency, setCurrency] = useState("CAD");
    const [notes, setNotes] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("pending");
    const [confirmationNumber, setConfirmationNumber] = useState("");

    useEffect(() => {
      if (invoice && invoice.totalAmount) {
        setAmount(invoice.totalAmount);
      }
    }, [invoice]);

    useEffect(() => {
      if (onPaymentMethodChange) {
        onPaymentMethodChange(paymentMethod);
      }
    }, [paymentMethod, onPaymentMethodChange]);

    const handleSubmit = () => {
      
      const stripePaymentMethodId = invoice.subscription.account
        ? invoice.subscription.account.stripePaymentMethodId
        : invoice.subscription.tier.brokerage.stripePaymentMethodId;
      const stripeCustomerId = invoice.subscription.account
        ? invoice.subscription.account.stripeCustomerId
        : invoice.subscription.tier.brokerage.stripeCustomerId;

      onSubmit({
        amount,
        transactionDate,
        invoice: invoice,
        customerId: stripeCustomerId,
        paymentMethod,
        paymentMethodId: stripePaymentMethodId,
        currency,
        notes,
        paymentStatus,
        confirmationNumber:
          paymentMethod === "credit_card" ? "" : confirmationNumber,
      });
    };

    const resetForm = () => {
      // Reset form fields
      setAmount("");
      setPaymentMethod("");
      setTransactionDate(dayjs().format("YYYY-MM-DD"));
      setCurrency("CAD");
      setNotes("");
      setPaymentStatus("pending");
      setConfirmationNumber("");
      onClose();
    };

    useImperativeHandle(ref, () => ({
      submit: () => {
        handleSubmit();
      },
    }));

    // Display a loading spinner if invoice is not loaded
    if (!invoice) {
      return (
        <DialogContent>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
          >
            <CircularProgress />
          </Box>
        </DialogContent>
      );
    }

    return (
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 1 }}>
          <b>Name:</b>{" "}
          {invoice.subscription?.account
            ? `${invoice.subscription?.account?.firstName} ${invoice.subscription?.account?.lastName}`
            : invoice.subscription?.tier?.brokerage?.officeName}
          <br />
          <b>Invoice:</b> {invoice.invoiceNumber}
          <br />
          <b>Total Invoice Amount:</b> ${invoice.totalAmount}
        </Typography>
        <TextField
          margin="dense"
          label="Transaction Date"
          type="date"
          fullWidth
          value={transactionDate}
          onChange={(e) => setTransactionDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <Box display="flex" gap={2} mt={2}>
          <TextField
            autoFocus
            margin="dense"
            label="Payment Amount"
            type="number"
            fullWidth
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Currency</InputLabel>
            <Select
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              label="Currency"
            >
              <MenuItem value="CAD">CAD</MenuItem>
              <MenuItem value="USD">USD</MenuItem>
              <MenuItem value="EUR">EUR</MenuItem>
              {/* Add other currencies as needed */}
            </Select>
          </FormControl>
        </Box>
        <FormControl fullWidth margin="dense">
          <InputLabel>Payment Method</InputLabel>
          <Select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            label="Payment Method"
          >
            <MenuItem value="credit_card">Credit Card</MenuItem>
            <MenuItem value="paypal">PayPal</MenuItem>
            <MenuItem value="bank_transfer">Bank Transfer</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          label={
            paymentMethod === "credit_card"
              ? "Confirmation # will be provided by Stripe"
              : "Confirmation Number"
          }
          fullWidth
          value={confirmationNumber}
          onChange={(e) => setConfirmationNumber(e.target.value)}
          disabled={paymentMethod === "credit_card"}
        />
        <TextField
          margin="dense"
          label="Notes"
          multiline
          rows={4}
          fullWidth
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Payment Status</InputLabel>
          <Select
            value={paymentStatus}
            onChange={(e) => setPaymentStatus(e.target.value)}
            label="Payment Status"
          >
            <MenuItem value="paid">Paid</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="failed">Failed</MenuItem>
            <MenuItem value="refunded">Refunded</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
    );
  }
);

export default PaymentForm;

import React from "react";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PeopleIcon from "@mui/icons-material/People";
import { List, ListItem, Typography, Box } from "@mui/material";

function GroupManagementMenu({
  team,
  handleOpenCreateTeamDialog,
  handleOpenCreateGroupDialog,
}) {
  return (
    <Box className="user-management-box">
      <List className="user-management-list" sx={{ width: "100%" }}>
        {!team && (
          <>
            <ListItem sx={{ justifyContent: "flex-end", width: "100%" }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  mb: 0,
                  width: "100%",
                  textAlign: "right",
                }}
                variant="h7"
                gutterBottom
              >
                No Team(s) Found
              </Typography>
            </ListItem>
            <ListItem
              sx={{ justifyContent: "flex-end", width: "100%" }}
              button
              onClick={handleOpenCreateTeamDialog}
              className="user-management-item"
            >
              <PeopleIcon className="icon" />
              Create The Team
            </ListItem>
          </>
        )}
        <ListItem
          button
          onClick={handleOpenCreateGroupDialog}
          className="user-management-item"
          sx={{}}
        >
          <GroupAddIcon className="icon" />
          Create a New Group
        </ListItem>
      </List>
    </Box>
  );
}

export default GroupManagementMenu;

import React, { useState, useEffect, useContext } from "react";
import {
  useCreateCategoryGroupMutation,
  useUpdateCategoryGroupMutation,
} from "../../../services/category-group";
import { AuthContext } from "../../../context/AuthContext";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import NotificationSnackbar from "../../helpers/notification-snackbar";

const CreateEditCategoryGroupForm = ({
  selectedTier,
  open,
  onClose,
  categoryGroup,
  refetchCategoryGroup,
  mode = "edit",
}) => {
  const { userId, userRole } = useContext(AuthContext);
  const isREOSAdminRole = ["reosadmin", "reossuperadmin"].includes(userRole);

  const [createCategoryGroup, { isLoading: isCreateLoading }] =
    useCreateCategoryGroupMutation();
  const [updateCategoryGroup, { isLoading }] = useUpdateCategoryGroupMutation();

  const [categoryGroupName, setCategoryGroupName] = useState(
    categoryGroup?.name || ""
  );
  const [categoryGroupPath, setCategoryGroupPath] = useState(
    categoryGroup?.path || ""
  );
  const [categoryGroupIcon] = useState(categoryGroup?.icon || "");
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (categoryGroup) {
      setCategoryGroupName(categoryGroup?.name || "");
      setCategoryGroupPath(categoryGroup?.path || "");
    }
  }, [categoryGroup]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (mode !== "edit" && !selectedTier) {
      setNotification({
        open: true,
        message: "Please choose the tier level first.",
      });
      return;
    }
    if (!categoryGroupName?.trim() || !categoryGroupPath.trim()) {
      setNotification({
        open: true,
        message: "Please fill in all required fields.",
      });
      return;
    }
    let result;
    if (mode === "create") {
      result = await createCategoryGroup({
        userId,
        tierId: selectedTier?._id,
        categoryGroupName,
        categoryGroupPath,
        categoryGroupIcon,
      });
    } else if (mode === "edit") {
      result = await updateCategoryGroup({
        categoryGroupId: categoryGroup?._id,
        categoryGroupName,
        categoryGroupPath,
        categoryGroupIcon,
      });
    }
    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        if (mode === "create") {
          setCategoryGroupName("");
          setCategoryGroupPath("");
        }
        refetchCategoryGroup?.();
        onClose();
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error saving category Group: ${
          result.error.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth className="edit-post">
      <DialogTitle>
        <Typography component="div" variant="h5">
          {mode === "create"
            ? "Create New Category Group"
            : "Edit Category Group"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid item>
            <TextField
              placeholder="Name"
              label="Name"
              name="name"
              value={categoryGroupName}
              onChange={(event) => {
                const newCategoryGroupName = event.target.value;
                setCategoryGroupName(newCategoryGroupName);
                const formattedPath = newCategoryGroupName
                  .toLowerCase()
                  .replace(/\s+/g, "-")
                  .trim();
                setCategoryGroupPath(formattedPath);
              }}
              fullWidth
              required
              margin="normal"
            />
          </Grid>
          {isREOSAdminRole && (
            <Grid item>
              <TextField
                placeholder="Path"
                name="path"
                label="Path"
                value={categoryGroupPath}
                fullWidth
                required
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Grid item>
          <Button onClick={onClose} color="secondary" type="submit" fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={
              mode === "create"
                ? isCreateLoading
                : mode === "edit"
                ? isLoading
                : isLoading
            }
          >
            {isLoading ? (
              <CircularProgress size={24} /> ? (
                mode === "create"
              ) : (
                "Create"
              )
            ) : (
              "Save"
            )}
          </Button>
        </Grid>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default CreateEditCategoryGroupForm;

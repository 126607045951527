import React, { useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  Badge,
} from "@mui/material";
import { MoreHoriz, DeleteForever } from "@mui/icons-material/";
import {
  getInitialsFromUserName,
  AvatarStyledBadge,
  truncateChatMessage,
} from "../helpers/utils";
import NotificationSnackbar from "../helpers/notification-snackbar";

const ChatCard = ({ chat, onChatClick, onDeleteChatClick }) => {
  const { userId, userName } = useContext(AuthContext);
  const [chatMenuAnchorEl, setChatMenuAnchorEl] = useState({});
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });

  const handleMenuOpen = (event, chatId) => {
    event.stopPropagation();
    setChatMenuAnchorEl({
      ...chatMenuAnchorEl,
      [chatId]: event.currentTarget,
    });
  };

  const handleMenuClose = (chatId) => {
    setChatMenuAnchorEl({ ...chatMenuAnchorEl, [chatId]: null });
  };

  const handleDeleteClick = (event, chatId) => {
    event.stopPropagation();
    onDeleteChatClick(chatId);
  };

  const renderLastMessage = (chat) => {
    const lastMessage = chat?.messages[0];
    return lastMessage ? (
      <>
        <Typography variant="caption" sx={{ color: "var(--text-color)" }}>
          {truncateChatMessage(lastMessage.content, 75)}
        </Typography>
      </>
    ) : null;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Card
      sx={{ marginBottom: 2 }}
      key={chat?._id}
      className={`chat-item ${
        chat?.unreadCount > 0 ? "chat-item-unread" : "chat-item-read"
      }`}
      onClick={(event) => onChatClick(event, chat?._id)}
    >
      <CardHeader
        className="chat-card-header"
        avatar={
          <Tooltip title="Member Profile link">
            <AvatarStyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
              color="success"
              invisible={
                !chat?.participants?.find((p) => p?._id !== userId)?.isOnline
              }
            >
              <Avatar
                className="avatar"
                alt={userName}
                src={
                  chat?.participants?.find((p) => p?._id !== userId)?.image
                    ?.url || ""
                }
              >
                {getInitialsFromUserName(
                  chat?.participants?.find((p) => p?._id !== userId)?.firstName
                ) || "N/A"}
                {getInitialsFromUserName(
                  chat?.participants?.find((p) => p?._id !== userId)?.lastName
                ) || ""}
              </Avatar>
            </AvatarStyledBadge>
          </Tooltip>
        }
        title={
          <div className="chat-card-header-title">
            <Typography className="additional-text">
              {`Chat with ${
                chat?.participants?.find((p) => p?._id !== userId)?.firstName ||
                "User Removed Chat"
              } ${new Date(chat?.updatedAt).toLocaleTimeString()} ${new Date(
                chat?.updatedAt
              ).toLocaleDateString()}`}
            </Typography>
            {chat?.unreadCount > 0 && (
              <Badge
                badgeContent={chat?.unreadCount}
                color="error"
                className="chat-card-header-title-badge"
              />
            )}
          </div>
        }
        subheader={renderLastMessage(chat)}
        action={
          <div className="chat-card-header-actions">
            <Tooltip title="Handle Chat">
              <IconButton
                className="menu-button"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(event) => {
                  event.stopPropagation();
                  handleMenuOpen(event, chat?._id);
                }}
              >
                <MoreHoriz />
              </IconButton>
            </Tooltip>
            <Menu
              id={`menu-${chat?._id}`}
              anchorEl={chatMenuAnchorEl[chat?._id]}
              open={Boolean(chatMenuAnchorEl[chat?._id])}
              onClose={() => handleMenuClose(chat?._id)}
            >
              <MenuItem>
                <Tooltip title="Delete Chat">
                  <IconButton
                    className="delete-chat-button"
                    aria-label="delete"
                    onClick={(event) => handleDeleteClick(event, chat?._id)}
                  >
                    <DeleteForever />
                    <Typography ml={1}>Delete Chat</Typography>
                  </IconButton>
                </Tooltip>
              </MenuItem>
            </Menu>
          </div>
        }
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Card>
  );
};

export default ChatCard;

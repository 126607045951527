import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  TextField,
  Paper,
  Link,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  CircularProgress,
  Typography,
  Tab,
} from "@mui/material";
import { Edit, Delete, Add } from "@mui/icons-material";
import { formatMoney } from "../../../helpers/utils";
import {
  useFetchTransactionsQuery,
  useDeleteTransactionMutation,
  useCreateTransactionMutation,
} from "../../../../services/billing/transactions";
import TransactionForm from "./TransactionForm";
import { AuthContext } from "../../../../context/AuthContext";
import ConfirmationDialog from "../../../helpers/ConfirmationDialog";
import InvoiceForm from "../invoice/InvoiceForm";

// Payment method labels
const paymentMethodLabels = {
  credit_card: "Credit Card",
  paypal: "PayPal",
  bank_transfer: "Bank Transfer",
  other: "Other",
};

// Utility function for sorting
const getComparator = (order, orderBy) => {
  return (a, b) => {
    const getStringValue = (value) => {
      return typeof value === "string" ? value : String(value || "");
    };

    const getValue = (obj, key) => {
      if (key === "invoiceNumber") {
        return getStringValue(obj.invoice?.invoiceNumber || "");
      }
      return getStringValue(obj[key] || "");
    };

    if (orderBy === "clientName") {
      const getClientName = (transaction) => {
        const firstName =
          transaction.invoice?.subscription?.account?.firstName || "";
        const lastName =
          transaction.invoice?.subscription?.account?.lastName || "";
        const officeName =
          transaction.invoice?.subscription?.tier?.brokerage?.officeName || "";
        return (firstName + " " + lastName).trim() || officeName.trim() || "";
      };

      const nameA = getClientName(a);
      const nameB = getClientName(b);

      return order === "desc"
        ? nameB.localeCompare(nameA)
        : nameA.localeCompare(nameB);
    }

    if (orderBy === "amount") {
      const valueA = parseFloat(getValue(a, orderBy)) || 0;
      const valueB = parseFloat(getValue(b, orderBy)) || 0;

      return order === "desc" ? valueB - valueA : valueA - valueB;
    }

    if (
      ["createdAt", "invoiceNumber", "paymentMethod", "paymentStatus"].includes(
        orderBy
      )
    ) {
      const valueA = getValue(a, orderBy);
      const valueB = getValue(b, orderBy);

      return order === "desc"
        ? valueB.localeCompare(valueA)
        : valueA.localeCompare(valueB);
    }

    return 0;
  };
};



const Transactions = () => {
  const {
    data: transactions,
    error: fetchError,
    isLoading,
  } = useFetchTransactionsQuery();

  const [deleteTransaction] = useDeleteTransactionMutation();
  const [createTransaction] = useCreateTransactionMutation();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filter, setFilter] = useState("");
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [transactionIdToDelete, setTransactionIdToDelete] = useState(null);
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);
  const [invoice, setInvoice] = useState(null);

  const rowColour = "#ffffff";
  const rowColourAlt = "#f6f6f6";

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredTransactions = transactions?.filter((transaction) => {
    const amount = transaction.amount?.toString() || "";
    const paymentMethod = transaction.paymentMethod?.toLowerCase() || "";
    const paymentStatus = transaction.paymentStatus?.toLowerCase() || "";
    const notes = transaction.notes?.toLowerCase() || "";
    const account = transaction.invoice?.subscription?.account;
    const brokerage = transaction.invoice?.subscription?.tier?.brokerage;

    const accountName =
      (account?.firstName || "") + " " + (account?.lastName || "");
    const officeName = brokerage?.officeName || "";
    const invoiceNumber =
      transaction.invoice?.invoiceNumber?.toLowerCase() || "";

    return (
      amount.includes(filter.toLowerCase()) ||
      paymentMethod.includes(filter.toLowerCase()) ||
      paymentStatus.includes(filter.toLowerCase()) ||
      notes.includes(filter.toLowerCase()) ||
      accountName.toLowerCase().includes(filter.toLowerCase()) ||
      officeName.toLowerCase().includes(filter.toLowerCase()) ||
      invoiceNumber.includes(filter.toLowerCase())
    );
  });

  const sortedTransactions = filteredTransactions
    ?.sort(getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleEditClick = (transaction) => {
    setSelectedTransaction(transaction);
    setIsFormOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteTransaction(transactionIdToDelete).unwrap();
      setIsConfirmOpen(false);
      setTransactionIdToDelete(null);
    } catch (error) {
      setSnackbarMessage("Error deleting transaction. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const handleCancelDelete = () => {
    setIsConfirmOpen(false);
    setTransactionIdToDelete(null);
  };

  const handleCreateTransaction = async (newTransaction) => {
    try {
      await createTransaction(newTransaction).unwrap();
      setIsFormOpen(false);
    } catch (error) {
      setSnackbarMessage("Error creating transaction. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleConfirmationDialogClose = () => {
    setIsConfirmOpen(false);
  };

  const handleDeleteClick = (transaction) => {
    setTransactionIdToDelete(transaction._id);
    setIsConfirmOpen(true);
  };
  const handleOpenInvoice = (invoice) => {
    setInvoice(invoice);
    setIsInvoiceOpen(true);
  };

  return (
    <Paper className="transaction-list">
      <div>
        {/* <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={handleOpenCreateForm}
      >
        Add New Transaction
      </Button> */}
        <TextField
          label="Search"
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={(e) => setFilter(e.target.value)}
        />
        {isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {fetchError && (
          <Typography
            color="error"
            align="center"
            style={{ marginTop: "20px" }}
          >
            Error fetching transactions. Please try again later.
          </Typography>
        )}
        {filteredTransactions && !isLoading && !fetchError && (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "clientName"}
                        direction={orderBy === "clientName" ? order : "asc"}
                        onClick={() => handleRequestSort("clientName")}
                      >
                        Client Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "amount"}
                        direction={orderBy === "amount" ? order : "asc"}
                        onClick={() => handleRequestSort("amount")}
                      >
                        Amount
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "paymentMethod"}
                        direction={orderBy === "paymentMethod" ? order : "asc"}
                        onClick={() => handleRequestSort("paymentMethod")}
                      >
                        Payment Method
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "paymentStatus"}
                        direction={orderBy === "paymentStatus" ? order : "asc"}
                        onClick={() => handleRequestSort("paymentStatus")}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "invoiceNumber"}
                        direction={orderBy === "invoiceNumber" ? order : "asc"}
                        onClick={() => handleRequestSort("invoiceNumber")}
                      >
                        Invoice Number
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "createdAt"}
                        direction={orderBy === "createdAt" ? order : "asc"}
                        onClick={() => handleRequestSort("createdAt")}
                      >
                        Transaction Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedTransactions?.map((transaction, index) => (
                    <TableRow
                      key={transaction._id}
                      sx={{
                        backgroundColor:
                          index % 2 === 0 ? rowColour : rowColourAlt,
                      }}
                    >
                      <TableCell>
                        {transaction.invoice?.subscription.tier
                          ? transaction.invoice?.subscription.tier?.brokerage
                              .officeName
                          : transaction.invoice?.subscription.account
                              .firstName +
                            " " +
                            transaction.invoice?.subscription.account.lastName}
                      </TableCell>
                      <TableCell>${formatMoney(transaction.amount)}</TableCell>
                      <TableCell>
                        {paymentMethodLabels[transaction.paymentMethod] ||
                          transaction.paymentMethod}
                      </TableCell>
                      <TableCell>{transaction.paymentStatus}</TableCell>
                      <TableCell>
                        <Link
                          onClick={() => handleOpenInvoice(transaction.invoice)}
                        >
                          {transaction.invoice?.invoiceNumber}
                        </Link>
                      </TableCell>

                      <TableCell>
                        {new Date(transaction.createdAt).toLocaleString(
                          "en-US"
                        )}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() => handleEditClick(transaction)}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() => handleDeleteClick(transaction)}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredTransactions?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
        {isFormOpen && (
          <TransactionForm
            open={isFormOpen}
            onClose={() => setIsFormOpen(false)}
            transaction={selectedTransaction}
            onCreate={handleCreateTransaction}
          />
        )}
        <Dialog open={isConfirmOpen} onClose={handleCancelDelete}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this transaction?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelDelete} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isInvoiceOpen}
          onClose={() => setIsInvoiceOpen(false)}
          maxWidth="lg"
          PaperProps={{
            sx: {
              width: "60%",
              maxWidth: "none", // This prevents the dialog from being limited to a small max width
            },
          }}
        >
          <DialogTitle>Invoice Details</DialogTitle>
          <DialogContent>
            <InvoiceForm invoice={invoice} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsInvoiceOpen(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <ConfirmationDialog
          open={isConfirmOpen}
          onClose={handleConfirmationDialogClose}
          onConfirm={handleConfirmDelete}
          title="Delete Transaction"
          description="Are you sure you want to delete this transaction?"
        />

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
        />
      </div>
    </Paper>
  );
};

export default Transactions;

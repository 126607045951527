import React, { useState, useContext, memo } from "react";
import { styled } from "@mui/material/styles";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { addImageClassToContent } from "../helpers/utils";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  Divider,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  TextField,
  Chip,
  Box,
  Button,
  Badge,
} from "@mui/material";
import {
  MoreVert,
  DeleteForever,
  ExpandMore,
  Edit,
  Send,
  Comment,
  Visibility,
} from "@mui/icons-material/";
import { useCommentOnPostMutation } from "../../services/post";
import PostContent from "./PostContent";
import EditPost from "./EditPost";
import CommentCard from "./CommentCard";
import PostVideos from "./PostVideos";
import NotificationSnackbar from "../helpers/notification-snackbar";
import MemberProfileDrawer from "../members/MemberProfileDrawer";
import { countCommentsAndReplies, AvatarStyledBadge } from "../helpers/utils";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import Attachments from "./Attachments";
dayjs.extend(dayjsPluginUTC);

const ExpandComments = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  transform: expand ? "rotate(180deg)" : "rotate(0deg)",
}));

const KnowledgeEducationPostCard = ({
  post,
  categoryType,
  isPostList = false,
  isPostPage = false,
  isPostHomePageList = false,
  onDialogOpen,
  onMigratePostToNewCategoryDialogOpen,
  refetchPosts,
  currentUserId,
  onSelect,
  isPostsLoading,
  isPostsError,
  isNewPost,
}) => {
  const { accountType, userImage, userName } = useContext(AuthContext);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState({});
  const [expandedPosts, setExpandedPosts] = useState({});
  const [openEditPostDialog, setOpenEditPostDialog] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const [newComment, setNewComment] = useState("");
  const [isComment, setIsComment] = useState("");
  const [commentOnPost] = useCommentOnPostMutation();
  const [expandedComments, setExpandedComments] = useState(false);

  const totalCommentsCount =
    post?.comments && countCommentsAndReplies(post?.comments);

  const handleMenuOpen = (event, postId) => {
    event.stopPropagation();
    setMenuAnchorEl({ ...menuAnchorEl, [postId]: event.currentTarget });
  };

  const handleMenuClose = (postId) => {
    setMenuAnchorEl({ ...menuAnchorEl, [postId]: null });
  };

  const handleExpandClick = (postId) => {
    setExpandedPosts({
      ...expandedPosts,
      [postId]: !expandedPosts[postId],
    });
  };

  const handleOpenEditPostDialog = (event) => {
    event.stopPropagation();
    setIsEditing(true);
    setOpenEditPostDialog(true);
  };

  const handleCloseEditPostDialog = () => {
    setOpenEditPostDialog(false);
    handleMenuClose(post?._id);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleMemberClick = (memberId) => {
    setSelectedMemberId(memberId);
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setSelectedMemberId(null);
  };

  const handleExpandCommentsClick = () => {
    setExpandedComments(!expandedComments);
  };

  const handleCommentInputClick = (event) => {
    event.stopPropagation();
  };

  const handleCommentSubmit = async (event) => {
    event && event?.stopPropagation();
    const result = await commentOnPost({
      userId: currentUserId,
      postId: post?._id,
      content: newComment,
      tierId: post?.tier?._id,
    });

    if (result) {
      setNotification({
        open: true,
        message: `Post commented successfully`,
      });
      setNewComment("");
      setTimeout(() => {
        if (!isPostsError && !isPostsLoading) {
          refetchPosts?.();
        }
      }, 1500);
    } else {
      setNotification({
        open: true,
        message: `Error submitting comment ${result?.error?.data?.msg}`,
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleCommentSubmit();
    }
  };

  const isAdmin = accountType === "reosadmin" || accountType === "brokerage";
  const isAuthor = post?.author?._id === currentUserId;
  const isAuthorOrAdmin = isAuthor || isAdmin;
  const isAllOffices =
    post?.tier &&
    post?.tier?.brokerage &&
    post?.tier?.brokerage?.leadBrokerageTier &&
    post?.tier?._id === post?.tier?.brokerage?.leadBrokerageTier?._id;

  return (
    <Card
      className={`post-card ${
        isPostList && categoryType !== "event" ? "margin-bottom" : ""
      }`}
    >
      {isNewPost && <Box className="post-card-new-post-label">NEW POST</Box>}
      {isPostHomePageList &&
        post?.tier &&
        post?.tier?.brokerage?.officeName && (
          <Box className="post-card-tier-label">
            {`${post?.tier?.brokerage?.officeName}${
              isAllOffices ? " - all offices" : ""
            }` || "Unknown Office"}
          </Box>
        )}
      {isPostList && (
        <CardHeader
          className="post-card-header"
          title={
            <div className="card-header-title">
              <Tooltip title="Member Profile link">
                <Link
                  onClick={(event) => {
                    event.stopPropagation();
                    handleMemberClick(post?.author?._id);
                  }}
                  className="avatar-name"
                >
                  {post?.author?.firstName || ""} {post?.author?.lastName || ""}
                </Link>
              </Tooltip>
              <Typography className="category-text">
                {" posted in "}
                {post?.category?.name || ""}
              </Typography>
            </div>
          }
          subheader={
            <Typography className="date-text">
              {new Date(post?.createdAt).toLocaleString() || ""}
            </Typography>
          }
        />
      )}
      {((!isPostList && isAdmin) || (!isPostList && isAuthorOrAdmin)) && (
        <CardHeader
          className="post-card-header"
          avatar={
            <Tooltip title="Member Profile link">
              <AvatarStyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
                color="success"
                invisible={!post?.author?.isOnline}
                width="5px"
                height="5px"
              >
                <Avatar
                  className="avatar"
                  src={post?.author?.image?.url || ""}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleMemberClick(post?.author?._id);
                  }}
                >
                  R
                </Avatar>
              </AvatarStyledBadge>
            </Tooltip>
          }
          action={
            <>
              <IconButton
                className="menu-button"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(event) => handleMenuOpen(event, post?._id)}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id={`menu-${post?._id}`}
                anchorEl={menuAnchorEl[post?._id]}
                open={Boolean(menuAnchorEl[post?._id])}
                onClose={() => handleMenuClose(post?._id)}
              >
                {isAuthorOrAdmin && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      handleOpenEditPostDialog(event);
                    }}
                  >
                    <Edit />
                    <Typography ml={1}>Edit Post</Typography>
                  </MenuItem>
                )}
                {isAuthorOrAdmin && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      onMigratePostToNewCategoryDialogOpen(post);
                      handleMenuClose(post?._id);
                    }}
                  >
                    <Edit />
                    <Typography ml={1}>Migrate Post To New Category</Typography>
                  </MenuItem>
                )}
                {isAuthorOrAdmin && (
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      onDialogOpen(post?._id);
                    }}
                  >
                    <DeleteForever />
                    <Typography ml={1}>Delete Post</Typography>
                  </MenuItem>
                )}
                {!(accountType === "reosadmin") && !isAuthorOrAdmin && (
                  <MenuItem>
                    <Typography ml={1}>No actions</Typography>
                  </MenuItem>
                )}
              </Menu>
            </>
          }
          title={
            <div className="card-header-title">
              <Tooltip title="Member Profile link">
                <Link
                  onClick={(event) => {
                    event.stopPropagation();
                    handleMemberClick(post?.author?._id);
                  }}
                  className="avatar-name"
                >
                  {post?.author?.firstName || ""} {post?.author?.lastName || ""}
                </Link>
              </Tooltip>
              <Typography className="category-text">
                {" posted in "}
                {post?.category?.name || ""}
              </Typography>
            </div>
          }
          subheader={
            <Typography className="date-text">
              {new Date(post?.createdAt).toLocaleString() || ""}
            </Typography>
          }
        />
      )}
      <CardHeader
        className="post-card-header"
        title={
          <div className="post-card-title-wrap">
            <Typography className="post-card-title">
              {post?.postTitle || ""}
            </Typography>
            {post?.accessCount > 0 && (
              <Tooltip
                title={`Post Viewed ${post?.accessCount} time${
                  post?.accessCount === 1 ? "" : "s"
                }`}
              >
                <Badge badgeContent={post?.accessCount} color="primary">
                  <Visibility />
                </Badge>
              </Tooltip>
            )}
          </div>
        }
        sx={{ cursor: "pointer" }}
        onClick={(event) => {
          if (isPostList && !isDrawerOpen && !isEditing) {
            onSelect(event);
          }
        }}
      />
      <CardContent className="custom-card-content">
        {post?.videos && post.videos.length > 0 && (
          <PostVideos videos={post.videos} isPostPage={isPostPage} />
        )}
        {post?.attachments && post.attachments.length > 0 && (
          <Attachments attachments={post.attachments} />
        )}
        {post?.content && (
          <PostContent
            content={addImageClassToContent(post?.content || "")}
            isExpanded={expandedPosts[post?._id]}
            onExpandClick={(event) => {
              event.stopPropagation();
              handleExpandClick(post?._id);
            }}
            isPostPage={isPostPage}
          />
        )}

        {/* Only include attachment at bottom if the content is longer than 500 characters and not the list*/}

        {!isPostList &&
          post?.content.length > 750 &&
          post?.attachments &&
          post.attachments.length > 0 && (
            <Attachments attachments={post.attachments} />
          )}
        {!isPostList && post?.lastEdited && (
          <Typography variant="caption" color="textSecondary" textAlign="right">
            <EditIcon sx={{ verticalAlign: "middle", mr: 0.5 }} />
            Last edited on {new Date(post?.lastEdited)?.toLocaleString()}
          </Typography>
        )}
        <div className="tag-box">
          {post?.tags && (
            <Box
              sx={{
                mt: 2,
                mb: 2,
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
              }}
            >
              {Array.isArray(post.tags)
                ? post.tags.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag}
                      variant="outlined"
                      sx={{
                        backgroundColor: "background.default", // Using theme's background color
                        color: "text.primary", // Using theme's text color
                      }}
                    />
                  ))
                : post.tags.split(",").map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag.trim()}
                      variant="outlined"
                      sx={{
                        backgroundColor: "background.default", // Using theme's background color
                        color: "text.primary", // Using theme's text color
                      }}
                    />
                  ))}
            </Box>
          )}
        </div>
        <div>
          {isPostList && (
            <Button
              sx={{
                marginTop: "10px",
                backgroundColor: "#4caf50",
                color: "var(--home-page-text-color);",
              }}
              className="open-button-mode"
              variant="outlined"
              color="success"
              onClick={(event) => {
                if (!isDrawerOpen && !isEditing) {
                  onSelect(event);
                }
              }}
            >
              Open...
            </Button>
          )}
        </div>
      </CardContent>
      <Divider />
      {post && post?.category?.path === "legally-speaking" && (
        <>
          <CardActions className="post-card-actions-block">
            <div className="post-card-actions">
              <div className="likes-block">
                <Tooltip title={"Comment the Post"}>
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      setIsComment(!isComment);
                    }}
                  >
                    <Comment />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="comments-block">
                {post?.comments && post?.comments?.length > 0 ? (
                  <>
                    <span className="comments-block-span">{`${
                      post?.comments &&
                      post?.comments?.length > 0 &&
                      (totalCommentsCount === 1
                        ? "1 comment"
                        : `${totalCommentsCount} comments`)
                    }`}</span>
                    <ExpandComments
                      expand={expandedComments}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleExpandCommentsClick();
                      }}
                      aria-expanded={expandedComments}
                      aria-label="show more"
                    >
                      <ExpandMore />
                    </ExpandComments>
                  </>
                ) : (
                  <span className="comments-block-span">no comments</span>
                )}
              </div>
            </div>
          </CardActions>
          <Collapse in={expandedComments} timeout="auto" unmountOnExit>
            <CardContent>
              {post?.comments && post?.comments?.length > 0 ? (
                post?.comments?.map((comment) => (
                  <CommentCard
                    key={comment?._id}
                    comment={comment}
                    post={post}
                    onMemberClick={handleMemberClick}
                    refetchPosts={refetchPosts}
                    isPostsError={isPostsError}
                    isPostsLoading={isPostsLoading}
                    categoryPath={post?.category?.path}
                  />
                ))
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No comments yet
                </Typography>
              )}
              {expandedComments && (
                <div
                  className="post-comment-block"
                  onClick={handleCommentInputClick}
                >
                  <Avatar
                    height="100"
                    width="100"
                    src={userImage || ""}
                    alt={userName || "UN"}
                  />
                  <div className="comment-input">
                    <TextField
                      className="comment-input-textarea"
                      placeholder="New Comment"
                      value={newComment}
                      onChange={(e) => {
                        e.stopPropagation();
                        setNewComment(e.target.value);
                      }}
                      fullWidth
                      onKeyDown={handleKeyDown}
                      multiline
                      rows={3}
                      onClick={handleCommentInputClick}
                    />
                    <Tooltip title="Comment Post">
                      <IconButton
                        className="comment-input-button"
                        onClick={handleCommentSubmit}
                      >
                        <Send />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              )}
            </CardContent>
          </Collapse>
          {isComment && !expandedComments && (
            <div
              className="post-comment-block"
              onClick={handleCommentInputClick}
            >
              <Avatar
                height="100"
                width="100"
                src={userImage || ""}
                alt={userName || "UN"}
              />
              <div className="comment-input">
                <TextField
                  className="comment-input-textarea"
                  placeholder="New Comment"
                  value={newComment}
                  onChange={(e) => {
                    e.stopPropagation();
                    setNewComment(e.target.value);
                  }}
                  fullWidth
                  onKeyDown={handleKeyDown}
                  multiline
                  rows={3}
                  onClick={handleCommentInputClick}
                />
                <Tooltip title="Comment Post">
                  <IconButton
                    className="comment-input-button"
                    onClick={handleCommentSubmit}
                  >
                    <Send />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          )}
        </>
      )}
      <EditPost
        refetchPosts={refetchPosts}
        post={post}
        open={openEditPostDialog}
        onClose={() => {
          handleCloseEditPostDialog();
          handleMenuClose(post?._id);
        }}
      />
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
      <MemberProfileDrawer
        userId={selectedMemberId}
        open={isDrawerOpen}
        onClose={handleDrawerClose}
      />
    </Card>
  );
};

export default memo(KnowledgeEducationPostCard);

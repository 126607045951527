import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateGroupMutation } from "../../../../services/group";
import { useFetchAllUsersQuery } from "../../../../services/user";
import { AuthContext } from "../../../../context/AuthContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import NotificationSnackbar from "../../../helpers/notification-snackbar";

const CreateGroupDialog = ({ open, onClose, teamId }) => {
  const { accountType } = useContext(AuthContext);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [groupUrlName, setGroupUrlName] = useState("");
  const [createGroup] = useCreateGroupMutation();
  const [notification, setNotification] = useState({
    open: false,
    message: "",
  });
  const { data: users } = useFetchAllUsersQuery(accountType, {
    skip: accountType !== "reosadmin",
  });
  const [selectedMemberId, setSelectedMemberId] = useState("");
  const [inputmembervalue, setInputmembervalue] = useState("");

  const isValidUrlName = (urlName) => {
    const pattern = /^[a-z0-9]+(?:-[a-z0-9]+)*$/i;
    return pattern.test(urlName);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isValidUrlName(groupUrlName)) {
      setNotification({
        open: true,
        message:
          "Invalid URL name. Please use only alphanumeric characters and hyphens.",
      });
      return;
    }

    const result = await createGroup({
      name,
      groupUrlName,
      teamId,
      memberId: selectedMemberId?.member?._id,
    });
    if (result?.data) {
      setNotification({
        open: true,
        message: `${result?.data?.msg}`,
      });
      setTimeout(() => {
        setGroupUrlName("");
        setName("");
        setSelectedMemberId("");
        onClose();
        navigate(`/group/${result?.data?.newGroup?._id}`);
      }, 1000);
    } else {
      setNotification({
        open: true,
        message: `Error creating group: ${
          result?.error?.data?.msg || "Unknown error"
        }`,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create Group</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Autocomplete
            value={selectedMemberId}
            onChange={(event, newValue) => {
              setSelectedMemberId(newValue);
            }}
            inputValue={inputmembervalue}
            onInputChange={(event, newInputValue) => {
              setInputmembervalue(newInputValue);
            }}
            options={users || []}
            getOptionLabel={(option) =>
              option
                ? `${option?.firstName} ${option?.lastName} (${option?.email})`
                : ""
            }
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Group Lead"
                variant="outlined"
                margin="normal"
                required
              />
            )}
          />
          <TextField
            variant="outlined"
            margin="normal"
            label="Group Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
          />
          <TextField
            variant="outlined"
            margin="normal"
            label=" Group Url Name"
            value={groupUrlName}
            onChange={(e) => setGroupUrlName(e.target.value)}
            fullWidth
            required
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Create Group
        </Button>
      </DialogActions>
      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        onClose={handleClose}
      />
    </Dialog>
  );
};

export default CreateGroupDialog;
